import { faSync } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress } from "@mui/joy";
import { useApiCore, useDataRoom } from "contexts";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import { type DataRoomTableColumnBase } from "models";
import { extractDatasetFromTabularResult } from "utils/apicore";
import useComputationNodeSchema from "./useComputationNodeSchema";

interface SchemaButtonProps {
  computeNodeId: string;
  isOutdated: boolean;
  onDetermined: (columns: DataRoomTableColumnBase[]) => void;
}

const SchemaButton: React.FC<SchemaButtonProps> = ({
  computeNodeId,
  onDetermined,
  isOutdated,
}) => {
  const { store } = useApiCore();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { isPublished } = useDataRoom();
  const { loading, retrieveSchema } = useComputationNodeSchema({
    computeNodeId,
  });
  const retrieveComputationFormat = () => {
    retrieveSchema()
      .then(async (format) => {
        if (format) {
          const bytes = store.get(format);
          const [, tableSchema] = await extractDatasetFromTabularResult(bytes);
          const { namedColumns } = tableSchema || {};
          if (namedColumns) {
            onDetermined?.(
              namedColumns.map((c) => ({
                columnType: {
                  nullable: c.columnType?.nullable!,
                  primitiveType: c.columnType?.primitiveType!,
                },
                name: c.name || "",
              }))
            );
          } else {
            throw new Error(
              "The data source computation result did not include a schema."
            );
          }
        } else {
          throw new Error(
            "The data source computation result could not be retrieved."
          );
        }
      })
      .catch((error) => {
        enqueueSnackbar(
          ...(isPublished
            ? mapErrorToGeneralSnackbar(
                error,
                `The data source schema could not be determined.`
              )
            : mapDraftDataRoomErrorToSnackbar(
                error,
                `The data source schema could not be determined.`
              ))
        );
      });
  };
  return (
    <Button
      data-testid="determine_sql_schema_btn"
      disabled={loading}
      onClick={retrieveComputationFormat}
      startDecorator={
        loading ? (
          <CircularProgress sx={{ "--CircularProgress-size": "18px" }} />
        ) : (
          <FontAwesomeIcon fixedWidth={true} icon={faSync} />
        )
      }
      sx={{ marginLeft: ".5rem" }}
    >
      {isOutdated
        ? loading
          ? "Updating schema"
          : "Update schema"
        : loading
          ? "Determining schema"
          : "Determine schema"}
    </Button>
  );
};

export default SchemaButton;
