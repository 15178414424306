import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Modal,
  ModalDialog,
} from "@mui/joy";
import saveAs from "file-saver";
import { useEffect } from "react";
import { useApiCore } from "contexts";
import { useMediaDataRoom } from "features/mediaDataRoom/contexts";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import useModelPerformanceReportGeneration from "./useModelPerformanceReportGeneration";

interface PerformanceReportDownloadDialogProps {
  dataRoomId: string;
  open: boolean;
  onCancel: () => void;
}

const PerformanceReportDownloadDialog: React.FC<
  PerformanceReportDownloadDialogProps
> = ({ dataRoomId, open, onCancel }) => {
  const { store } = useApiCore();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { driverAttestationHash } = useMediaDataRoom();
  const { mutateAsync: generatePerformanceReport } =
    useModelPerformanceReportGeneration({
      dataRoomId,
      driverAttestationHash,
    });
  useEffect(() => {
    if (open) {
      generatePerformanceReport()
        .then((report) => {
          if (!report) {
            throw Error("Performance report is empty");
          }
          const file = new File(
            [JSON.stringify(report, null, 2)],
            `Performance_report_${new Date().toISOString()}.json`,
            {
              type: "application/octet-stream;charset=utf-8",
            }
          );
          if (!open) {
            return;
          }
          saveAs(file);
          onCancel();
        })
        .catch((error) => {
          if (!open) {
            return;
          }
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              error,
              "Unable to fetch performance report"
            )
          );
          onCancel();
        });
    }
  }, [open, generatePerformanceReport, onCancel, enqueueSnackbar, store]);
  return (
    <Modal onClose={onCancel} open={open}>
      <ModalDialog>
        <DialogTitle>Preparing performance report</DialogTitle>
        <Divider />
        <DialogContent sx={{ display: "flex", justifyContent: "center" }}>
          <CircularProgress sx={{ "--CircularProgress-size": "32px" }} />
        </DialogContent>
        <Divider />
        <DialogActions>
          <Button onClick={onCancel}>Close</Button>
        </DialogActions>
      </ModalDialog>
    </Modal>
  );
};
PerformanceReportDownloadDialog.displayName = "PerformanceReportDownloadDialog";

export default PerformanceReportDownloadDialog;
