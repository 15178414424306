import { DqLoader } from "@decentriq/components";
import { useOwnOrganizationUserEmailsQuery } from "@decentriq/graphql/dist/hooks";
import { faInfoCircle as fasInfoCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Alert,
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Input,
  Modal,
  ModalDialog,
  Option,
  Select,
  Stack,
} from "@mui/joy";
import { memo, useCallback, useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { useDatasetPermission } from "features/datasets/contexts";
import {
  createShareDatasetValidationResolver,
  type DatasetPermission,
  type DatasetPermissionFormValues,
  type DatasetPermissionRole,
  datasetPermissionRoleOptions,
  datasetPermissionRolePresentation,
} from "features/datasets/models";

interface DatasetEditPermissionDialogProps {
  onCancel: () => void;
  open: boolean;
  permission: DatasetPermission;
}

const DatasetEditPermissionDialog = memo<DatasetEditPermissionDialogProps>(
  ({ onCancel, open, permission }) => {
    const {
      changePermission,
      permissions,
      isPermissionsDataLoading,
      isEditingPermisison,
    } = useDatasetPermission();
    const {
      data: organisationUsersData,
      loading: isOrganisationUsersDataLoading,
    } = useOwnOrganizationUserEmailsQuery();
    const organisationUsers = useMemo(
      () => organisationUsersData?.myself?.organization?.users?.nodes ?? [],
      [organisationUsersData?.myself?.organization?.users?.nodes]
    );
    const { control, reset, handleSubmit } =
      useForm<DatasetPermissionFormValues>({
        defaultValues: permission,
        mode: "onChange",
        reValidateMode: "onChange",
        resolver: useCallback(
          () =>
            createShareDatasetValidationResolver(
              organisationUsers,
              permissions,
              true
            ),
          [organisationUsers, permissions]
        )(),
      });
    const handleCancel = useCallback(() => {
      reset();
      onCancel();
    }, [onCancel, reset]);
    const handleEditPermission = useCallback(
      (permission: DatasetPermissionFormValues) => {
        changePermission(permission);
        reset();
        onCancel();
      },
      [changePermission, onCancel, reset]
    );
    if (isOrganisationUsersDataLoading || isPermissionsDataLoading) {
      return (
        <Modal onClose={onCancel} open={open}>
          <ModalDialog role="dialog">
            <DialogTitle>Edit dataset permission</DialogTitle>
            <DialogContent>
              <Stack alignItems="center" padding={3}>
                <DqLoader />
              </Stack>
            </DialogContent>
          </ModalDialog>
        </Modal>
      );
    }
    return (
      <Modal
        onClose={isEditingPermisison ? undefined : handleCancel}
        open={open}
      >
        <form onSubmit={handleSubmit(handleEditPermission)}>
          <ModalDialog role="dialog">
            <DialogTitle>Edit dataset permission</DialogTitle>
            <Divider />
            <DialogContent>
              <Stack>
                <Box>
                  <FormLabel>Email</FormLabel>
                  <Input
                    defaultValue={permission.id}
                    name="id"
                    placeholder="e.g. user@example.com"
                    readOnly={true}
                  />
                </Box>
                <Controller
                  control={control}
                  name="role"
                  render={({ field, fieldState: { error } }) => {
                    return (
                      <FormControl>
                        <FormLabel>Access</FormLabel>
                        <Select
                          {...field}
                          autoFocus={true}
                          onChange={(event, value) => field.onChange(value)}
                          placeholder="e.g. Owner"
                          renderValue={(option) =>
                            option?.value
                              ? datasetPermissionRolePresentation[option.value]
                              : ""
                          }
                        >
                          {datasetPermissionRoleOptions.map((role) => (
                            <Option key={role} value={role}>
                              {datasetPermissionRolePresentation[role]}
                            </Option>
                          ))}
                        </Select>
                        <FormHelperText>{error?.message}</FormHelperText>
                      </FormControl>
                    );
                  }}
                />
                <Alert
                  color="neutral"
                  startDecorator={<FontAwesomeIcon icon={fasInfoCircle} />}
                  variant="soft"
                >
                  The owner will have full access, including the ability to edit
                  and grant permissions to others. This access will be permanent
                  and can only be revoked by deleting the dataset.
                </Alert>
              </Stack>
            </DialogContent>
            <Divider />
            <DialogActions>
              <Button onClick={isEditingPermisison ? undefined : handleCancel}>
                Cancel
              </Button>
              <Button
                color="primary"
                disabled={isEditingPermisison}
                loading={isEditingPermisison}
                type="submit"
                variant="solid"
              >
                Save edit
              </Button>
            </DialogActions>
          </ModalDialog>
        </form>
      </Modal>
    );
  }
);

DatasetEditPermissionDialog.displayName = "DatasetEditPermissionDialog";

export default DatasetEditPermissionDialog;
