import { ApolloError } from "@apollo/client";
import { data_science } from "@decentriq/core";
import { type TestDataset } from "@decentriq/core/dist/session";
import {
  type ComputeJob,
  ComputeJobAutoFetching,
  ComputeJobPurpose,
  CreateComputeJobDocument,
  type MutationRunComputationArgs,
  PublishedComputeNodeJobDocument,
  type PublishedComputeNodeJobQuery,
} from "@decentriq/graphql/dist/types";
import * as forge from "node-forge";
import { type ApiCoreContextValue } from "contexts";
import { type PublishedDataNodeTypeNames } from "models";
import { parseDataRoomComputationError } from "utils";
import { getNodeById, parseErrorMessage } from "wrappers/ApolloWrapper/helpers";
import { type LocalResolverContext } from "wrappers/ApolloWrapper/models";

export const makeRunComputationResolver =
  (
    client: ApiCoreContextValue["client"],
    sessionManager: ApiCoreContextValue["sessionManager"],
    store: ApiCoreContextValue["store"]
  ) =>
  async (
    _obj: null,
    args: MutationRunComputationArgs,
    context: LocalResolverContext,
    _info: any
  ): Promise<Partial<ComputeJob>> => {
    const {
      dcrHash,
      driverAttestationHash,
      computeNodeId,
      dataRoomId,
      autoFetching,
    } = args.input;
    const sdkSession = await sessionManager.get({
      driverAttestationHash,
    });
    const dataScienceDataRoom =
      await sdkSession.retrieveDataScienceDataRoom(dcrHash);
    const wrapper = data_science.createDataScienceDataRoomWrapper(
      dcrHash,
      dataScienceDataRoom!,
      sdkSession
    );
    const computeNode = getNodeById(context, computeNodeId, args);
    try {
      let dataScienceJobId;
      const testDatasetsInput = args.input.testDatasets;
      const testing = !!testDatasetsInput;
      if (testing) {
        const testDatasets = new Map<string, TestDataset>();
        for (const dataset of testDatasetsInput) {
          testDatasets.set(dataset.leafNodeId, {
            key: store.pop(dataset.encryptionKey)!,
            manifestHash: dataset.manifestHash,
          });
        }
        dataScienceJobId = await wrapper.createJob([computeNodeId], {
          dryRun: {
            testDatasets,
          },
        });
      } else {
        dataScienceJobId = await wrapper.createJob([computeNodeId]);
      }
      const encodedDataScienceJobHandle = forge.util.binary.base64.encode(
        new TextEncoder().encode(JSON.stringify(dataScienceJobId))
      );
      const result = await context.client.mutate({
        mutation: CreateComputeJobDocument,
        variables: {
          input: {
            computeNodeId: {
              published: {
                computeNodeId,
                publishedDataRoomId: dataRoomId,
              },
            },
            dataRoomHash: dcrHash,
            driverAttestationHash,
            enclaveComputeJobHandleBase64: encodedDataScienceJobHandle,
            enclaveComputeJobId: dataScienceJobId.jobId,
            purpose: testing
              ? ComputeJobPurpose.Test
              : ComputeJobPurpose.Standard,
          },
        },
      });
      const computeJob = result.data?.computeJob.create.record;
      if (computeJob) {
        context.client.writeQuery<PublishedComputeNodeJobQuery>({
          data: {
            publishedNode: {
              __typename: computeNode?.__typename as PublishedDataNodeTypeNames,
              commitId: null,
              dcrHash,
              driverAttestationHash,
              id: computeNodeId,
              job: {
                __typename: "ComputeJob",
                autoFetching: autoFetching
                  ? testing
                    ? ComputeJobAutoFetching.TestResult
                    : ComputeJobAutoFetching.Result
                  : ComputeJobAutoFetching.None,
                createdAt: computeJob.createdAt,
                dataRoomHash: computeJob.dataRoomHash,
                driverAttestationHash,
                enclaveComputeJobId: computeJob.enclaveComputeJobId,
                id: computeJob.id,
                purpose: testing
                  ? ComputeJobPurpose.Test
                  : ComputeJobPurpose.Standard,
                status: computeJob.status,
              },
            },
          },
          query: PublishedComputeNodeJobDocument,
        });
        return computeJob;
      } else {
        throw new ApolloError({
          errorMessage: "Failed to create job",
          graphQLErrors: result.errors,
        });
      }
    } catch (error) {
      throw parseDataRoomComputationError(
        parseErrorMessage(context.cache, error, args),
        computeNode?.name
      );
    }
  };
