import { useAuth0 } from "@auth0/auth0-react";
import { type EnclaveSpecification, proto } from "@decentriq/core";
import { type data_science_data_room } from "ddc";
import * as forge from "node-forge";
import { useCallback } from "react";
import { v4 as uuidv4 } from "uuid";
import { useApiCore } from "contexts";
import { type SchemaType } from "features/measurementDcr/components/CreateMeasurementDcr/models";
import {
  getAttributionScript,
  getBrandChartsScript,
  getPublisherChartsScript,
} from "features/measurementDcr/components/CreateMeasurementDcr/scripts";
import { getLatestEnclaveSpecsPerType } from "utils/apicore";
import { slugify } from "utils/dataRoom";

interface GetDcrConfigsProps {
  measurementConfiguration: SchemaType["measurementConfiguration"];
  publishers: SchemaType["publishers"];
  // Debug participants have access to all data and comptue nodes.
  // Should only be used for debugging purposes.
  debugParticipants?: string[];
  advertiserUserEmails: string[];
  title: string;
  enclaveRootCertificatePem: string;
  latestEnclaveSpecifications: Map<string, EnclaveSpecification>;
  ownerEmail: string;
}

const getDcrConfig = ({
  measurementConfiguration,
  publishers,
  debugParticipants,
  advertiserUserEmails,
  title,
  enclaveRootCertificatePem,
  latestEnclaveSpecifications,
  ownerEmail,
}: GetDcrConfigsProps): data_science_data_room.DataScienceDataRoom => {
  const getDataNodesForPublisher = (
    publisherSlug: string
  ): data_science_data_room.NodeV9[] => [
    {
      id: uuidv4(),
      kind: {
        leaf: {
          isRequired: true,
          kind: {
            table: {
              columns: [
                {
                  dataFormat: {
                    dataType: "string",
                    isNullable: false,
                  },
                  name: "pub_user_id",
                  validation: {
                    allowNull: false,
                    formatType: "STRING",
                    name: "pub_user_id",
                  },
                },
                {
                  dataFormat: {
                    dataType: "string",
                    isNullable: false,
                  },
                  name: "matching_id",
                  validation: {
                    allowNull: false,
                    formatType: "STRING",
                    name: "matching_id",
                  },
                },
              ],
              validationNode: {
                pythonSpecificationId: "decentriq.python-ml-worker-32-64",
                staticContentSpecificationId: "decentriq.driver",
                validation: {
                  allowEmpty: true,
                },
              },
            },
          },
        },
      },
      name: `${publisherSlug}_match`,
    },
    {
      id: uuidv4(),
      kind: {
        leaf: {
          isRequired: true,
          kind: {
            table: {
              columns: [
                {
                  dataFormat: {
                    dataType: "string",
                    isNullable: false,
                  },
                  name: "pub_user_id",
                  validation: {
                    allowNull: false,
                    formatType: "STRING",
                    name: "pub_user_id",
                  },
                },
                {
                  dataFormat: {
                    dataType: "string",
                    isNullable: false,
                  },
                  name: "campaign_id",
                  validation: {
                    allowNull: false,
                    formatType: "STRING",
                    name: "campaign_id",
                  },
                },
                {
                  dataFormat: {
                    dataType: "integer",
                    isNullable: false,
                  },
                  name: "timestamp",
                  validation: {
                    allowNull: false,
                    formatType: "INTEGER",
                    name: "timestamp",
                  },
                },
                {
                  dataFormat: {
                    dataType: "integer",
                    isNullable: true,
                  },
                  name: "click_flag",
                  validation: {
                    allowNull: true,
                    formatType: "INTEGER",
                    name: "click_flag",
                  },
                },
              ],
              validationNode: {
                pythonSpecificationId: "decentriq.python-ml-worker-32-64",
                staticContentSpecificationId: "decentriq.driver",
                validation: {
                  allowEmpty: true,
                },
              },
            },
          },
        },
      },
      name: `${publisherSlug}_exposure`,
    },
  ];
  const getComputeNodesForPublisher = ({
    publisherSlug,
    attributionComputeNodeId,
  }: {
    publisherSlug: string;
    attributionComputeNodeId: string;
  }): data_science_data_room.NodeV9[] => [
    {
      id: uuidv4(),
      kind: {
        computation: {
          kind: {
            scripting: {
              additionalScripts: [],
              dependencies: [attributionComputeNodeId],
              enableLogsOnError: true,
              enableLogsOnSuccess: true,
              mainScript: {
                content: getPublisherChartsScript({ publisherSlug }),
                name: "Main Script",
              },
              output: "/output",
              scriptingLanguage: { python: {} },
              scriptingSpecificationId: "decentriq.python-ml-worker-32-64",
              staticContentSpecificationId: "decentriq.driver",
            },
          },
        },
      },
      name: `${publisherSlug}_charts`,
    },
  ];
  const getBrandDataNodes = (): data_science_data_room.NodeV9[] => [
    {
      id: uuidv4(),
      kind: {
        leaf: {
          isRequired: true,
          kind: {
            table: {
              columns: [
                {
                  dataFormat: {
                    dataType: "string",
                    isNullable: true,
                  },
                  name: "brand_user_id",
                  validation: {
                    allowNull: true,
                    formatType: "STRING",
                    name: "brand_user_id",
                  },
                },
                {
                  dataFormat: {
                    dataType: "string",
                    isNullable: false,
                  },
                  name: "matching_id",
                  validation: {
                    allowNull: false,
                    formatType: "STRING",
                    name: "matching_id",
                  },
                },
              ],
              validationNode: {
                pythonSpecificationId: "decentriq.python-ml-worker-32-64",
                staticContentSpecificationId: "decentriq.driver",
                validation: {
                  allowEmpty: true,
                },
              },
            },
          },
        },
      },
      name: "brand_match",
    },
    {
      id: uuidv4(),
      kind: {
        leaf: {
          isRequired: true,
          kind: {
            table: {
              columns: [
                {
                  dataFormat: {
                    dataType: "string",
                    isNullable: false,
                  },
                  name: "brand_user_id",
                  validation: {
                    allowNull: false,
                    formatType: "STRING",
                    name: "brand_user_id",
                  },
                },
                {
                  dataFormat: {
                    dataType: "string",
                    isNullable: false,
                  },
                  name: "product_category",
                  validation: {
                    allowNull: false,
                    formatType: "STRING",
                    name: "product_category",
                  },
                },
                {
                  dataFormat: {
                    dataType: "integer",
                    isNullable: false,
                  },
                  name: "timestamp",
                  validation: {
                    allowNull: false,
                    formatType: "INTEGER",
                    name: "timestamp",
                  },
                },
                {
                  dataFormat: {
                    dataType: "float",
                    isNullable: false,
                  },
                  name: "value",
                  validation: {
                    allowNull: false,
                    formatType: "FLOAT",
                    name: "value",
                  },
                },
              ],
              validationNode: {
                pythonSpecificationId: "decentriq.python-ml-worker-32-64",
                staticContentSpecificationId: "decentriq.driver",
                validation: {
                  allowEmpty: true,
                },
              },
            },
          },
        },
      },
      name: "brand_conversions",
    },
  ];
  const getBrandComputeNodes = ({
    dataNodeIds,
    publisherSlugs,
  }: {
    dataNodeIds: string[];
    publisherSlugs: string[];
  }): data_science_data_room.NodeV9[] => {
    const attributionComputeNodeId = uuidv4();
    return [
      {
        id: attributionComputeNodeId,
        kind: {
          computation: {
            kind: {
              scripting: {
                additionalScripts: [],
                dependencies: dataNodeIds,
                enableLogsOnError: true,
                enableLogsOnSuccess: true,
                mainScript: {
                  content: getAttributionScript({
                    config: {
                      attributionRule: measurementConfiguration.attributionRule,
                      clickLookbackWindowDays:
                        measurementConfiguration.clickLookbackWindow,
                      viewLookbackWindow:
                        measurementConfiguration.viewLookbackWindow,
                    },
                    publisherSlugs,
                  }),
                  name: "Main Script",
                },
                output: "/output",
                scriptingLanguage: { python: {} },
                scriptingSpecificationId: "decentriq.python-ml-worker-32-64",
                staticContentSpecificationId: "decentriq.driver",
              },
            },
          },
        },
        name: "attribution",
      },
      {
        id: uuidv4(),
        kind: {
          computation: {
            kind: {
              scripting: {
                additionalScripts: [],
                dependencies: [attributionComputeNodeId],
                enableLogsOnError: true,
                enableLogsOnSuccess: true,
                mainScript: {
                  content: getBrandChartsScript(),
                  name: "Main Script",
                },
                output: "/output",
                scriptingLanguage: { python: {} },
                scriptingSpecificationId: "decentriq.python-ml-worker-32-64",
                staticContentSpecificationId: "decentriq.driver",
              },
            },
          },
        },
        name: "brand_charts",
      },
    ];
  };
  const publisherSlugByPublisher: Map<string, string> = new Map(
    publishers.map((publisher) => [publisher.name, slugify(publisher.name)])
  );
  const publisherDataNodesByPublisher: Map<
    string,
    data_science_data_room.NodeV9[]
  > = new Map(
    publishers.map((publisher) => [
      publisher.name,
      getDataNodesForPublisher(slugify(publisher.name)),
    ])
  );
  const brandDataNodes = getBrandDataNodes();
  const brandComputeNodes = getBrandComputeNodes({
    dataNodeIds: [
      ...Array.from(publisherDataNodesByPublisher.values())
        .flat()
        .map(({ id }) => id),
      ...brandDataNodes.map(({ id }) => id),
    ],
    publisherSlugs: Array.from(publisherSlugByPublisher.values()),
  });
  const attributionComputeNodeId = brandComputeNodes.find(
    (node) => node.name === "attribution"
  )!.id;
  const publisherComputeNodesByPublisher: Map<
    string,
    data_science_data_room.NodeV9[]
  > = new Map(
    publishers.map((publisher) => [
      publisher.name,
      getComputeNodesForPublisher({
        attributionComputeNodeId,
        publisherSlug: publisherSlugByPublisher.get(publisher.name)!,
      }),
    ])
  );
  const publisherParticipants: data_science_data_room.Participant[] =
    publishers.reduce((acc, publisher) => {
      const newParticipants = publisher.emails.map(
        (email): data_science_data_room.Participant => ({
          permissions: [
            ...(email === ownerEmail ? [{ manager: {} }] : []),
            ...publisherDataNodesByPublisher
              .get(publisher.name)!
              .map(({ id }) => ({
                dataOwner: {
                  nodeId: id,
                },
              })),
            ...publisherComputeNodesByPublisher
              .get(publisher.name)!
              .map(({ id }) => ({
                analyst: {
                  nodeId: id,
                },
              })),
          ],
          user: email,
        })
      );
      acc.push(...newParticipants);
      return acc;
    }, [] as data_science_data_room.Participant[]);
  const brandParticipants: data_science_data_room.Participant[] =
    advertiserUserEmails.map((email) => ({
      permissions: [
        ...(email === ownerEmail ? [{ manager: {} }] : []),
        ...brandDataNodes.map(({ id }) => ({
          dataOwner: {
            nodeId: id,
          },
        })),
        ...brandComputeNodes.map(({ id }) => ({
          analyst: {
            nodeId: id,
          },
        })),
      ],
      user: email,
    }));
  const nodes: data_science_data_room.NodeV9[] = [
    ...Array.from(publisherDataNodesByPublisher.values()).flat(),
    ...brandDataNodes,
    ...Array.from(publisherComputeNodesByPublisher.values()).flat(),
    ...brandComputeNodes,
  ];
  let participants = [...publisherParticipants, ...brandParticipants];
  if (debugParticipants) {
    // Remove them from the list of participants, and re-add them with permissions granted for all nodes
    participants = participants.filter(
      ({ user }) => !debugParticipants.includes(user)
    );
    debugParticipants.forEach((user) => {
      participants.push({
        permissions: [
          ...nodes.map((node) => {
            if ("computation" in node.kind) {
              return {
                analyst: {
                  nodeId: node.id,
                },
              };
            } else {
              return {
                dataOwner: {
                  nodeId: node.id,
                },
              };
            }
          }),
          ...(user === ownerEmail ? [{ manager: {} }] : []),
        ],
        user,
      });
    });
  }
  const enclaveSpecifications: data_science_data_room.EnclaveSpecification[] =
    Array.from(latestEnclaveSpecifications.keys()).map((key) => {
      const spec = latestEnclaveSpecifications.get(key)!;
      return {
        attestationProtoBase64: forge.util.binary.base64.encode(
          proto.attestation.AttestationSpecification.encodeDelimited(
            spec.proto
          ).finish()
        ),
        id: key,
        name: spec.name,
        workerProtocol: spec.workerProtocols[0],
      };
    });
  return {
    v9: {
      interactive: {
        commits: [],
        enableAutomergeFeature: true,
        initialConfiguration: {
          dcrSecretIdBase64: null,
          description: "",
          enableAirlock: true,
          enableAllowEmptyFilesInValidation: true,
          enableDevelopment: true,
          enablePostWorker: true,
          enableSafePythonWorkerStacktrace: true,
          enableServersideWasmValidation: true,
          enableSqliteWorker: true,
          enableTestDatasets: true,
          enclaveRootCertificatePem,
          enclaveSpecifications,
          id: uuidv4(),
          nodes,
          participants,
          title,
        },
      },
    },
  };
};

const useMeasurement = () => {
  const { user: { email: currentUserEmail = "" } = {} } = useAuth0();
  const { client: sdkClient, sessionManager } = useApiCore();
  const createBaseConfiguration = useCallback(
    async (
      configByUser: SchemaType
    ): Promise<data_science_data_room.DataScienceDataRoom> => {
      const {
        publishers,
        advertiserUserEmails,
        measurementConfiguration,
        dataRoomName,
      } = configByUser;
      const rootCertificatePem = await sdkClient.decentriqCaRootCertificate();
      const enclaveSpecifications = await sdkClient.getEnclaveSpecifications(
        sessionManager.includeMrsigner
      );
      const latestEnclaveSpecifications = getLatestEnclaveSpecsPerType(
        enclaveSpecifications
      );
      return getDcrConfig({
        advertiserUserEmails,
        // debugParticipants: [currentUserEmail],
        enclaveRootCertificatePem: new TextDecoder().decode(rootCertificatePem),
        latestEnclaveSpecifications,
        measurementConfiguration,
        ownerEmail: currentUserEmail,
        publishers,
        title: dataRoomName,
      });
    },
    [currentUserEmail, sdkClient, sessionManager.includeMrsigner]
  );
  const createMeasurement = useCallback(
    async ({
      payload,
    }: {
      payload: SchemaType;
    }): Promise<{
      configuration: data_science_data_room.DataScienceDataRoom;
      description: string;
      name: string;
    }> => {
      const configByUser: SchemaType = payload;
      const generatedConfig = await createBaseConfiguration(configByUser);
      return {
        configuration: generatedConfig,
        description: "",
        name: configByUser.dataRoomName,
      };
    },
    [createBaseConfiguration]
  );
  const publish = useCallback(
    async ({ payload }: { payload: SchemaType }): Promise<string> => {
      const config = await createMeasurement({ payload });
      const session = await sessionManager.get();
      return await session.publishDataScienceDataRoom(config.configuration, {
        dataRoomPurpose: proto.metering.CreateDcrPurpose.STANDARD,
        requirePassword: false,
        showOrganizationLogo: false,
      });
    },
    [createMeasurement, sessionManager]
  );
  return {
    createMeasurement,
    publish,
  };
};

export default useMeasurement;
