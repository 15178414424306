import { useApolloClient } from "@apollo/client";
import {
  useDeleteTestModePublicationMutation,
  usePublishDatasetMutation,
  useSetTestModePublicationMutation,
  useUnpublishDatasetMutation,
} from "@decentriq/graphql/dist/hooks";
import {
  DatasetByHashDocument,
  type PublishedDataset,
  PublishedNodeIdDocument,
  type PublishedNodeIdQuery,
} from "@decentriq/graphql/dist/types";
import { type Key } from "@decentriq/utils";
import { useCallback } from "react";
import { useApiCore, usePublishedDataRoom } from "contexts";
import useCommonDataNodeActions from "features/dataNodes/hooks/useCommonDataNodeActions";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";

interface CommittedDataNodesHookPayload {
  dataRoomId: string;
}

const useCommittedDataNodeActions = ({
  dataRoomId,
}: CommittedDataNodesHookPayload) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const apolloClient = useApolloClient();
  const { driverAttestationHash, testing } = usePublishedDataRoom();
  const { store } = useApiCore();
  const [unpublishDatasetMutation] = useUnpublishDatasetMutation({
    onCompleted: () => {
      enqueueSnackbar("Your dataset has been deprovisioned.");
    },
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(error, `Failed to deprovision dataset`)
      );
    },
  });
  const [deleteTestModePublicationMutation] =
    useDeleteTestModePublicationMutation({
      onCompleted: () => {
        enqueueSnackbar("Your dataset has been deleted.");
      },
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(error, `Failed to delete dataset`)
        );
      },
    });
  const _onCommittedNodeDatasetDeprovision = useCallback(
    async ({ dataNodeId }: { dataNodeId: string }) => {
      const node = apolloClient.cache.readQuery<PublishedNodeIdQuery>({
        query: PublishedNodeIdDocument,
        variables: {
          commitId: null,
          dcrHash: dataRoomId,
          driverAttestationHash,
          nodeId: dataNodeId,
        },
      });
      await unpublishDatasetMutation({
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              dataset: (existing = {}) => {
                return null;
              },
            },
            id: cache.identify({
              __typename: node?.publishedNode?.__typename,
              commitId: null,
              dcrHash: dataRoomId,
              driverAttestationHash,
              id: dataNodeId,
            }),
          });
        },
        variables: {
          input: {
            dataNodeId,
            dcrHash: dataRoomId,
            driverAttestationHash,
          },
        },
      });
    },
    [unpublishDatasetMutation, apolloClient, dataRoomId, driverAttestationHash]
  );
  const _onDeleteTestModePublicationDeprovision = useCallback(
    async ({ dataNodeId }: { dataNodeId: string }) => {
      const node = apolloClient.cache.readQuery<PublishedNodeIdQuery>({
        query: PublishedNodeIdDocument,
        variables: {
          commitId: null,
          dcrHash: dataRoomId,
          driverAttestationHash,
          nodeId: dataNodeId,
        },
      });
      await deleteTestModePublicationMutation({
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              testDataset: (existing = {}) => {
                return null;
              },
            },
            id: cache.identify({
              __typename: node?.publishedNode?.__typename,
              commitId: null,
              dcrHash: dataRoomId,
              driverAttestationHash,
              id: dataNodeId,
            }),
          });
        },
        variables: {
          nodeId: {
            published: {
              computeNodeId: dataNodeId,
              publishedDataRoomId: dataRoomId,
            },
          },
        },
      });
    },
    [
      deleteTestModePublicationMutation,
      apolloClient,
      dataRoomId,
      driverAttestationHash,
    ]
  );
  const onDatasetDeprovision = useCallback(
    async ({ dataNodeId }: { dataNodeId: string }) => {
      if (testing) {
        await _onDeleteTestModePublicationDeprovision({ dataNodeId });
        return;
      }
      await _onCommittedNodeDatasetDeprovision({ dataNodeId });
    },
    [
      _onDeleteTestModePublicationDeprovision,
      _onCommittedNodeDatasetDeprovision,
      testing,
    ]
  );
  const [publishDatasetMutation] = usePublishDatasetMutation();
  const [setTestModePublicationMutation] = useSetTestModePublicationMutation();
  const _onConnectDataset = useCallback(
    async ({
      key,
      dataNodeId,
      manifestHash,
    }: {
      key: Key;
      dataNodeId: string;
      manifestHash: string;
    }) => {
      const encryptionKeyReference = store.push(key);
      const node = apolloClient.cache.readQuery<PublishedNodeIdQuery>({
        query: PublishedNodeIdDocument,
        variables: {
          commitId: null,
          dcrHash: dataRoomId,
          driverAttestationHash,
          nodeId: dataNodeId,
        },
      });
      await publishDatasetMutation({
        refetchQueries: [
          {
            fetchPolicy: "network-only",
            query: DatasetByHashDocument,
            variables: { manifestHash },
          },
        ],
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              dataset: (existing = {}) => {
                return data?.publishDataset?.dataset || null;
              },
            },
            id: cache.identify({
              __typename: node?.publishedNode.__typename,
              commitId: null,
              dcrHash: dataRoomId,
              driverAttestationHash,
              id: dataNodeId,
            }),
          });
        },
        variables: {
          input: {
            dcrHash: dataRoomId,
            driverAttestationHash,
            encryptionKey: encryptionKeyReference,
            leafNodeId: dataNodeId,
            manifestHash,
          },
        },
      });
    },
    [
      store,
      publishDatasetMutation,
      dataRoomId,
      driverAttestationHash,
      apolloClient,
    ]
  );
  const _onSetTestModePublication = useCallback(
    async ({
      dataNodeId,
      datasetId,
      manifestHash,
    }: {
      key: Key;
      dataNodeId: string;
      manifestHash: string;
      datasetId: string;
    }) => {
      const publishedNode = apolloClient.cache.readQuery<PublishedNodeIdQuery>({
        query: PublishedNodeIdDocument,
        variables: {
          commitId: null,
          dcrHash: dataRoomId,
          driverAttestationHash,
          nodeId: dataNodeId,
        },
      });
      return setTestModePublicationMutation({
        refetchQueries: [
          {
            fetchPolicy: "network-only",
            query: DatasetByHashDocument,
            variables: { manifestHash },
          },
        ],
        update: (cache, { data }) => {
          cache.modify({
            fields: {
              testDataset: (existing = {}) => {
                let publishedDataset: PublishedDataset | null = null;
                if (data?.testModePublication?.set?.record.dataset) {
                  const {
                    manifestHash: datasetHash,
                    createdAt: timestamp,
                    owner: { email: user },
                  } = data.testModePublication.set.record.dataset;
                  publishedDataset = {
                    datasetHash,
                    leafId: dataNodeId,
                    timestamp,
                    user,
                  };
                }
                return publishedDataset;
              },
            },
            id: cache.identify({
              __typename: publishedNode?.publishedNode.__typename,
              commitId: null,
              dcrHash: dataRoomId,
              driverAttestationHash,
              id: dataNodeId,
            }),
          });
        },
        variables: {
          input: {
            datasetId,
            leafNodeId: {
              published: {
                computeNodeId: dataNodeId,
                publishedDataRoomId: dataRoomId,
              },
            },
          },
        },
      });
    },
    [
      setTestModePublicationMutation,
      apolloClient,
      dataRoomId,
      driverAttestationHash,
    ]
  );
  const onConnectDataset = useCallback(
    async (args: { key: Key; dataNodeId: string; manifestHash: string }) => {
      if (!testing) {
        await _onConnectDataset(args);
        return;
      }
      const { data } = await apolloClient.query({
        query: DatasetByHashDocument,
        variables: {
          manifestHash: args.manifestHash,
        },
      });
      const datasetId = data?.datasetByManifestHash?.id;
      if (!datasetId) {
        throw Error("Can't find dataset");
      }
      await _onSetTestModePublication({
        ...args,
        datasetId,
      });
    },
    [apolloClient, _onSetTestModePublication, testing, _onConnectDataset]
  );
  const onAfterIngestData = useCallback(
    async ({
      key,
      dataNodeId,
      datasetId,
      manifestHash,
    }: {
      key: Key;
      dataNodeId: string;
      manifestHash: string;
      datasetId: string;
    }) => {
      if (testing) {
        await _onSetTestModePublication({
          dataNodeId,
          datasetId,
          key,
          manifestHash,
        });
        return;
      }
      await _onConnectDataset({ dataNodeId, key, manifestHash });
    },
    [_onSetTestModePublication, _onConnectDataset, testing]
  );
  const {
    activeDataRoomUpload,
    addOrUpdateUploading,
    currentUserEmail,
    dataNodeForIngestion,
    handleAwaitedDataUploadingResult,
    handleConnectFromKeychain,
    handleDataDeprovision,
    handleIngestData,
    handleUploadClose,
    isUploadFailed,
    isUploadSuccessful,
    isUploadValidating,
    removeUploading,
    resetUploadings,
    setActiveDataRoomUpload,
    setDataNodeForIngestion,
    uploadings,
  } = useCommonDataNodeActions({
    onAfterIngestData,
    onConnectDataset,
    onDatasetDeprovision,
  });
  return {
    activeDataRoomUpload,
    addOrUpdateUploading,
    currentUserEmail,
    dataNodeForIngestion,
    handleAwaitedDataUploadingResult,
    handleConnectFromKeychain,
    handleDataDeprovision,
    handleIngestData,
    handleUploadClose,
    isUploadFailed,
    isUploadSuccessful,
    isUploadValidating,
    removeUploading,
    resetUploadings,
    setActiveDataRoomUpload,
    setDataNodeForIngestion,
    uploadings,
  };
};

export default useCommittedDataNodeActions;
