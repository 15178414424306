import { testIds } from "@decentriq/utils";
import { CircularProgress, Tab, TabList, TabPanel, Tabs } from "@mui/joy";
import { memo } from "react";
import { Link, Navigate, Outlet } from "react-router-dom";
import { DataRoomAuditLogs } from "containers";
import {
  MediaDataRoomData,
  MediaDataRoomInsights,
  MediaDataRoomOverlapInsightsWrapper,
  MediaDataRoomTab,
  OverlapInsightsExecutionContext,
  useMediaDataRoom,
  useMediaDataRoomInsightsData,
} from "features/mediaDataRoom";
import { AudiencesWrapper } from "features/mediaDataRoom/wrappers";

interface MediaDataRoomTabsProps {
  activeTab: MediaDataRoomTab;
  baseUrl: string;
}

const MediaDataRoomTabs: React.FC<MediaDataRoomTabsProps> = ({
  activeTab,
  baseUrl,
}) => {
  const {
    isAdvertiser,
    isAgency,
    isObserver,
    isPublisher,
    loading: dataRoomLoading,
    dataRoomId,
    driverAttestationHash,
    supportedFeatures: {
      enableInsights,
      enableLookalike,
      enableRemarketing,
      enableRuleBasedAudiences,
    },
  } = useMediaDataRoom();
  const { dataLabDataLoading } = useMediaDataRoomInsightsData();
  const loading = dataRoomLoading || dataLabDataLoading;
  if (loading) {
    return (
      <CircularProgress
        sx={{ "--CircularProgress-size": "2.5rem", margin: "auto" }}
      />
    );
  }
  const isInsightsTabEnabled =
    enableInsights && (isObserver || isPublisher || isAdvertiser || isAgency);
  const isActivationTabEnabled =
    (enableLookalike || enableRemarketing || enableRuleBasedAudiences) &&
    (isObserver || isPublisher || isAdvertiser || isAgency);
  if (
    (activeTab === MediaDataRoomTab.insights && !enableInsights) ||
    (activeTab === MediaDataRoomTab.activation && !isActivationTabEnabled)
  ) {
    return <Navigate to={`${baseUrl}/${MediaDataRoomTab.data}`} />;
  }
  return (
    <Tabs
      key={dataRoomId}
      sx={{ flex: 1, overflow: "hidden" }}
      value={activeTab}
    >
      <TabList>
        <Tab
          component={Link}
          data-testid={testIds.containers.mediaDataRoom.data}
          to={`${baseUrl}/${MediaDataRoomTab.data}`}
          value={MediaDataRoomTab.data}
        >
          Data
        </Tab>
        {isInsightsTabEnabled && (
          <Tab
            component={Link}
            data-testid={testIds.containers.mediaDataRoom.insights}
            to={`${baseUrl}/${MediaDataRoomTab.insights}`}
            value={MediaDataRoomTab.insights}
          >
            Insights
          </Tab>
        )}
        {isActivationTabEnabled && (
          <Tab
            component={Link}
            data-testid={testIds.containers.mediaDataRoom.activation}
            to={`${baseUrl}/${MediaDataRoomTab.activation}`}
            value={MediaDataRoomTab.activation}
          >
            Audiences
          </Tab>
        )}
        <Tab
          component={Link}
          data-testid={testIds.containers.mediaDataRoom.auditLog}
          to={`${baseUrl}/${MediaDataRoomTab.auditLog}`}
          value={MediaDataRoomTab.auditLog}
        >
          Audit log
        </Tab>
      </TabList>
      <TabPanel sx={{ overflow: "auto" }} value={MediaDataRoomTab.data}>
        <MediaDataRoomData />
      </TabPanel>
      {isInsightsTabEnabled && (
        <TabPanel sx={{ overflow: "auto" }} value={MediaDataRoomTab.insights}>
          {/*  flexShrink={0}
              height="100%"
              minHeight="100%"
              sx={{ backgroundColor: "common.white", p: 2 }} */}
          <MediaDataRoomOverlapInsightsWrapper
            executionContext={OverlapInsightsExecutionContext.insightsTab}
          >
            <MediaDataRoomInsights />
          </MediaDataRoomOverlapInsightsWrapper>
        </TabPanel>
      )}
      {isActivationTabEnabled && (
        <TabPanel sx={{ overflow: "auto" }} value={MediaDataRoomTab.activation}>
          <AudiencesWrapper>
            <Outlet />
          </AudiencesWrapper>
        </TabPanel>
      )}
      <TabPanel sx={{ overflow: "auto" }} value={MediaDataRoomTab.auditLog}>
        <DataRoomAuditLogs
          dcrHash={dataRoomId}
          driverAttestationHash={driverAttestationHash}
        />
      </TabPanel>
    </Tabs>
  );
};

MediaDataRoomTabs.displayName = "MediaDataRoomTabs";

export default memo(MediaDataRoomTabs);
