import { useAuth0 } from "@auth0/auth0-react";
import { useSubmittedRequestComputeNodeActionsQuery } from "@decentriq/graphql/dist/hooks";
import { ComputeJobStatus } from "@decentriq/graphql/dist/types";
import {
  faDownload,
  faFileChartColumn,
  faFileMagnifyingGlass,
  faPlay,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress } from "@mui/joy";
import { useEffect } from "react";
import { Actions } from "components";
import {
  useComputeNodesVars,
  usePublishedDataRoom,
  useRequest,
} from "contexts";
import { useJobPolling } from "features/computeNode/hooks";
import {
  ComputeNodeTypeNames,
  PublishedComputeNodeTypeNames,
  type PublishedComputeNodeTypes,
} from "models";
import { useComputeNodeDownload } from "../hooks";
import useComputeNodePreview from "../hooks/useComputeNodePreview/useComputeNodePreview";
import useSubmittedRequestComputeNodeRun from "../hooks/useSubmittedRequestComputeNodeRun/useSubmittedRequestComputeNodeRun";
import JobLastRunLabel from "../JobLastRunLabel";

interface SubmittedRequestComputeNodeActionsProps {
  computeNodeId: string;
}

const SubmittedRequestComputeNodeActions: React.FC<
  SubmittedRequestComputeNodeActionsProps
> = ({ computeNodeId }) => {
  const { user } = useAuth0();
  const currentUserEmail = user?.email;
  const {
    dataRoomId: dcrHash,
    driverAttestationHash,
    isStopped,
    isDeactivated,
  } = usePublishedDataRoom();
  const { openSdgQualityReportDialog } = useComputeNodesVars();
  const { commitId, requestId } = useRequest();
  const { data: actionsData, loading: actionsDataLoading } =
    useSubmittedRequestComputeNodeActionsQuery({
      // temp fix to resolve published node data
      returnPartialData: true,
      variables: {
        commitId,
        computeNodeId,
        dcrHash,
        driverAttestationHash,
        requestId,
      },
    });
  const computationType = actionsData?.publishedNode?.__typename as
    | ComputeNodeTypeNames
    | undefined;
  const { loading: computeNodeRunLoading, runSubmittedRequestComputeNode } =
    useSubmittedRequestComputeNodeRun({
      commitId: commitId!,
      computationType,
      computeNodeId,
    });
  const job = (
    actionsData?.publishedNode as PublishedComputeNodeTypes | undefined
  )?.job;
  const status = job?.status;
  const isJobRunning = status === ComputeJobStatus.Running;
  const isJobFailed = status === ComputeJobStatus.Failed;
  const isJobCompleted = status === ComputeJobStatus.Succeded;
  const isRunningAllowed =
    actionsData?.submittedDataRoomRequest?.signers?.nodes?.some(
      (s) => s.userEmail === currentUserEmail
    ) && actionsData?.submittedDataRoomRequest?.signers?.nodes?.length === 1;
  const isAutoFetchingJob = Boolean(job?.autoFetching);
  const hasSdgQualityReport =
    isJobCompleted &&
    actionsData?.publishedNode.__typename ===
      PublishedComputeNodeTypeNames.PublishedSyntheticNode;
  const { previewResult, loading: previewResultLoading } =
    useComputeNodePreview({
      jobId: job?.id || "",
    });
  const { downloadResult, loading: downloadResultLoading } =
    useComputeNodeDownload({
      computationType,
      computeNodeId,
      dataRoomHash: job?.dataRoomHash,
      driverAttestationHash: job?.driverAttestationHash || "",
      jobId: job?.id || "",
      name: actionsData?.publishedNode?.name || "",
    });
  const isComputeNodeRunning = computeNodeRunLoading || isJobRunning;
  const { startJobPolling } = useJobPolling({
    autoFetching: job?.autoFetching,
    computeNodeId,
    dataRoomHash: job?.dataRoomHash,
    driverAttestationHash: job?.driverAttestationHash,
    enclaveJobId: job?.enclaveComputeJobId,
    jobId: job?.id,
  });
  useEffect(() => {
    if (isJobRunning) {
      startJobPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  if (
    computationType === ComputeNodeTypeNames.PublishedPreviewNode ||
    (actionsDataLoading && !actionsData) ||
    !isRunningAllowed ||
    isStopped ||
    isDeactivated
  ) {
    return <></>;
  }
  return (
    <>
      <JobLastRunLabel
        isJobCompleted={isJobCompleted && !isComputeNodeRunning}
        runAt={job?.createdAt}
      />
      <Actions
        actions={{
          buttons: [
            {
              component: Button,
              disabled: isComputeNodeRunning,
              icon:
                isComputeNodeRunning && !isJobFailed ? (
                  <CircularProgress
                    sx={{ "--CircularProgress-size": "18px" }}
                  />
                ) : (
                  <FontAwesomeIcon fixedWidth={true} icon={faPlay} />
                ),
              name: isJobCompleted ? undefined : "Run",
              onClick: runSubmittedRequestComputeNode,
              tooltip: isJobCompleted ? "Re-run" : "Run",
              tooltipPlacement: "top",
              type: "Run",
            },
            {
              disabled: previewResultLoading,
              hidden:
                !isJobCompleted || isAutoFetchingJob || isComputeNodeRunning,
              icon: faFileMagnifyingGlass,
              loading: previewResultLoading,
              onClick: previewResult,
              tooltipPlacement: "top",
              tooltipTitle: "Preview",
              type: "Preview",
            },
            {
              hidden:
                !isJobCompleted || !hasSdgQualityReport || isComputeNodeRunning,
              icon: faFileChartColumn,
              onClick: () => openSdgQualityReportDialog(computeNodeId),
              tooltipPlacement: "top",
              tooltipTitle: "Quality report",
              type: "Quality report",
            },
            {
              disabled: downloadResultLoading,
              hidden: !isJobCompleted || isComputeNodeRunning,
              icon: faDownload,
              loading: downloadResultLoading,
              onClick: downloadResult,
              tooltipPlacement: "top",
              tooltipTitle: (
                <div>
                  <div>Download</div>
                  <div>
                    After you run an export process, you can download output
                    files into a file for use in another application.
                  </div>
                </div>
              ),
              type: "Download",
            },
          ],
        }}
      />
    </>
  );
};

export default SubmittedRequestComputeNodeActions;
