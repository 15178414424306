import {
  type ApolloClient,
  type NormalizedCacheObject,
  useApolloClient,
} from "@apollo/client";
import {
  CreateLookalikeMediaComputeJobDocument,
  type CreateLookalikeMediaComputeJobMutation,
  type CreateLookalikeMediaComputeJobMutationVariables,
  GetLookalikeMediaComputeJobDocument,
  type GetLookalikeMediaComputeJobQuery,
  type GetLookalikeMediaComputeJobQueryVariables,
} from "@decentriq/graphql/dist/types";
import { useSafeState } from "ahooks";
import format from "date-fns/format";
import { type media_insights_request } from "ddc";
import saveAs from "file-saver";
import { loadAsync } from "jszip";
import { type SnackbarKey } from "notistack";
import { useCallback, useState } from "react";
import { useApiCore } from "contexts";
import {
  type ActivationType,
  type Audience,
  useMediaInsightsDcrData,
  usePublishedMediaInsightsDcr,
} from "features/MediaInsightsDcr";
import { getMediaInsightsAudienceCacheKey } from "features/MediaInsightsDcr/utils";
import { mapMediaDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { parseMediaDataRoomError } from "utils";
import { computeCacheKeyString } from "wrappers/ApolloWrapper/resolvers/LruCache";

interface DownloadAudienceHookPayload {
  activationType: ActivationType;
  activatedAudiences: Audience[];
  audienceType: string;
  reach: number;
}

interface DownloadAudienceHookResult {
  loading: boolean;
  downloadAudience: () => Promise<void>;
}

const useDownloadAudience = ({
  activationType,
  activatedAudiences,
  audienceType,
  reach,
}: DownloadAudienceHookPayload): DownloadAudienceHookResult => {
  const { dataRoomId, driverAttestationHash, isPublisher } =
    usePublishedMediaInsightsDcr();
  const { enqueueSnackbar, closeSnackbar } = useDataRoomSnackbar();
  const { client, sessionManager } = useApiCore();
  const apolloClient = useApolloClient() as ApolloClient<NormalizedCacheObject>;
  const [loading, setLoading] = useSafeState(false);
  const setErrorSnackbarId = useState<SnackbarKey | undefined>()[1];
  const { advertiserDatasetHash, publisherDatasetsHashes } =
    useMediaInsightsDcrData();

  const jobType = "MEDIA_INSIGHTS_LOOKALIKE_AUDIENCE";
  const audienceDataKey = isPublisher
    ? "getAudienceUserList"
    : "getAudienceUserListForAdvertiser";

  const handleAudienceDownload = useCallback(async () => {
    setErrorSnackbarId((snackbarId) => {
      if (snackbarId) {
        closeSnackbar(snackbarId);
      }
      return undefined;
    });
    setLoading(true);
    try {
      const cacheKey = await getMediaInsightsAudienceCacheKey({
        activatedAudiences,
        activationType,
        advertiserDatasetHash,
        audienceType,
        dataRoomId,
        publisherDatasetsHashes,
        reach,
      });
      if (cacheKey == null) {
        throw new Error(
          "Getting lookalike audience requires both the publisher and advertiser dataset uploaded  and non-empty activated audiences config published"
        );
      }
      try {
        const session = await sessionManager.get({
          driverAttestationHash,
        });

        // Get the job. The difference from the original code is that the published datasets aren't retrieved yet again. The cache already contains them.
        const cacheKeyString = computeCacheKeyString(cacheKey);

        const getJobResult = await apolloClient.query<
          GetLookalikeMediaComputeJobQuery,
          GetLookalikeMediaComputeJobQueryVariables
        >({
          query: GetLookalikeMediaComputeJobDocument,
          variables: {
            input: {
              cacheKey: cacheKeyString,
              jobType,
              publishedDataRoomId: dataRoomId,
            },
          },
        });
        const existingJob = getJobResult.data.mediaComputeJob;

        let result: Uint8Array;
        if (existingJob) {
          result = await session.getComputationResult(
            {
              computeNodeId: existingJob.computeNodeName,
              jobId: existingJob.jobIdHex,
            },
            { interval: 1 }
          );
        } else {
          const scopeId = await client.ensureDcrDataScope(cacheKey.dataRoomId);
          const request: media_insights_request.MediaInsightsRequest = {
            [audienceDataKey]: {
              dataRoomIdHex: cacheKey.dataRoomId,
              requestedAudience: {
                activation_type: cacheKey.activationType,
                audience_type: cacheKey.audienceType,
                reach: cacheKey.reach,
              },
              scopeIdHex: scopeId,
            },
          };
          const response = await session.sendMediaInsightsRequest(request);
          if (!(audienceDataKey in response)) {
            throw new Error(`Expected ingested ${audienceDataKey} in response`);
          }
          const computeNodeName = response[audienceDataKey].computeNodeName;
          const jobIdHex = response[audienceDataKey].jobIdHex;
          await apolloClient.mutate<
            CreateLookalikeMediaComputeJobMutation,
            CreateLookalikeMediaComputeJobMutationVariables
          >({
            mutation: CreateLookalikeMediaComputeJobDocument,
            variables: {
              input: {
                cacheKey: cacheKeyString,
                computeNodeName,
                jobIdHex,
                jobType,
                publishedDataRoomId: dataRoomId,
              },
            },
          });
          result = await session.getComputationResult(
            { computeNodeId: computeNodeName, jobId: jobIdHex },
            { interval: 1 }
          );
        }
        const zip = await loadAsync(result);
        const audienceUsersFile = zip.file("audience_users.csv");
        if (audienceUsersFile === null) {
          throw new Error("audience_users.csv not found in zip");
        }
        const audienceUsersCsv = await audienceUsersFile.async("string");
        if (!audienceUsersCsv) {
          throw new Error("Audience is empty");
        }
        const reachPart = reach ? `-${reach}%` : "";
        const activationTypeDisplay =
          activationType === "retarget" ? "remarketing" : activationType;
        const fileName = `Advertiser_${activationTypeDisplay}_${audienceType}${reachPart}_${format(
          new Date(),
          "dd_MM_yyyy HH_mm"
        )}.csv`;
        const file = new File([audienceUsersCsv], fileName, {
          type: "application/octet-stream;charset=utf-8",
        });
        saveAs(file);
      } catch (error) {
        setErrorSnackbarId(
          enqueueSnackbar(
            ...mapMediaDataRoomErrorToSnackbar(
              parseMediaDataRoomError(error),
              "Unable to download audience"
            )
          )
        );
      }
    } finally {
      setLoading(false);
    }
  }, [
    activatedAudiences,
    activationType,
    advertiserDatasetHash,
    apolloClient,
    audienceDataKey,
    audienceType,
    client,
    closeSnackbar,
    dataRoomId,
    driverAttestationHash,
    enqueueSnackbar,
    publisherDatasetsHashes,
    reach,
    sessionManager,
    setErrorSnackbarId,
    setLoading,
  ]);

  return {
    downloadAudience: handleAudienceDownload,
    loading,
  };
};

export default useDownloadAudience;
