import {
  useCreateRequestFromPlaygroundMutation,
  useDeletePlaygroundMutation,
  useDevComputeNodeActionsQuery,
} from "@decentriq/graphql/dist/hooks";
import {
  ComputeJobAutoFetching,
  ComputeJobPurpose,
  ComputeJobStatus,
} from "@decentriq/graphql/dist/types";
import {
  faArrowUpRightAndArrowDownLeftFromCenter,
  faDownload,
  faFileChartColumn,
  faFileMagnifyingGlass,
  faPaperPlane,
  faPlay,
  faTrashCan,
} from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, CircularProgress } from "@mui/joy";
import { grey } from "@mui/material/colors";
import { useEffect, useMemo } from "react";
import { Actions } from "components";
import { useComputeNodesVars, usePublishedDataRoom } from "contexts";
import useDependsOnPreviewNode from "features/computeNode/components/ComputeNodeToolbar/useDependsOnPreviewNode";
import useFetchComputeJobResultSize from "features/computeNode/components/ComputeNodeToolbar/useFetchComputeJobResultSize";
import { useJobPolling } from "features/computeNode/hooks";
import { formatSize } from "features/datasets";
import { useDevComputeNode } from "features/devComputeNodes/contexts/devComputeNode";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import { type ComputeNodeTypeNames, type DraftComputeNodeTypes } from "models";
import {
  useComputeNodeDownload,
  useComputeNodePreview,
  useDevComputeNodeRun,
} from "../hooks";
import JobLastRunLabel from "../JobLastRunLabel";

interface DevComputeNodeActionsProps {
  computeNodeId: string;
}

const DevComputeNodeActions: React.FC<DevComputeNodeActionsProps> = ({
  computeNodeId,
}) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { openEditorDialog, openSdgQualityReportDialog } =
    useComputeNodesVars();
  const { playgroundId } = useDevComputeNode();
  const {
    enableRequests,
    switchToRequests,
    isStopped,
    isDeactivated,
    testing,
  } = usePublishedDataRoom();
  const { data: actionsData, loading: actionsDataLoading } =
    useDevComputeNodeActionsQuery({
      variables: {
        computeNodeId,
      },
    });
  const [
    createRequestFromPlayground,
    { loading: creatingRequestFromPlayground },
  ] = useCreateRequestFromPlaygroundMutation({
    onCompleted: () => {
      switchToRequests();
    },
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "A request for this computation could not be created."
        )
      );
    },
    update: (cache) => {
      cache.evict({
        id: cache.identify({
          __typename: "DataRoomPlayground",
          id: playgroundId,
        }),
      });
      cache.gc();
    },
    variables: { playgroundId },
  });
  const [deletePlayground, { loading: deletingPlayground }] =
    useDeletePlaygroundMutation({
      onError: (error) => {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "The development computation could not be deleted."
          )
        );
      },
      update: (cache) => {
        cache.evict({
          id: cache.identify({
            __typename: "DataRoomPlayground",
            id: playgroundId,
          }),
        });
        cache.gc();
      },
      variables: { id: playgroundId },
    });
  const computationType = actionsData?.draftNode?.__typename as
    | ComputeNodeTypeNames
    | undefined;
  const { loading: computeNodeRunLoading, runDevComputeNode } =
    useDevComputeNodeRun({ computationType, computeNodeId, playgroundId });
  const { dependsOnPreviewNode } = useDependsOnPreviewNode({ computeNodeId });
  const { resultSize, fetchComputeJobResultSizeLoading } =
    useFetchComputeJobResultSize({
      computeNodeId,
      skip: !dependsOnPreviewNode,
    });
  const nodeJob = (actionsData?.draftNode as DraftComputeNodeTypes | undefined)
    ?.job;
  const job =
    (testing && nodeJob?.purpose === ComputeJobPurpose.Standard) ||
    (!testing && nodeJob?.purpose === ComputeJobPurpose.Test)
      ? undefined
      : nodeJob;
  const status = job?.status;
  const isJobRunning = status === ComputeJobStatus.Running;
  const isJobFailed = status === ComputeJobStatus.Failed;
  const isJobCompleted = status === ComputeJobStatus.Succeded;
  const isAutoFetchingJob =
    job?.autoFetching && job?.autoFetching !== ComputeJobAutoFetching.None;
  const hasSdgQualityReport =
    isJobCompleted &&
    actionsData?.draftNode.__typename === "DraftSyntheticNode";
  const { previewResult, loading: previewResultLoading } =
    useComputeNodePreview({
      jobId: job?.id || "",
    });
  const { downloadResult, loading: downloadResultLoading } =
    useComputeNodeDownload({
      computationType,
      computeNodeId,
      dataRoomHash: job?.dataRoomHash,
      driverAttestationHash: job?.driverAttestationHash || "",
      jobId: job?.id || "",
      name: actionsData?.draftNode?.name || "",
    });
  const isComputeNodeRunning =
    computeNodeRunLoading || isJobRunning || fetchComputeJobResultSizeLoading;
  const { startJobPolling } = useJobPolling({
    autoFetching: job?.autoFetching,
    computeNodeId,
    dataRoomHash: job?.dataRoomHash,
    driverAttestationHash: job?.driverAttestationHash,
    enclaveJobId: job?.enclaveComputeJobId,
    jobId: job?.id,
  });
  const isPreviewNode = useMemo(() => {
    return actionsData?.draftNode?.__typename === "DraftPreviewNode";
  }, [actionsData]);
  useEffect(() => {
    if (isJobRunning) {
      startJobPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  if (actionsDataLoading && !actionsData) {
    return <></>;
  }
  return (
    <>
      <JobLastRunLabel
        isJobCompleted={isJobCompleted && !isComputeNodeRunning}
        resultSize={resultSize}
        runAt={job?.createdAt}
      />
      <Actions
        actions={{
          buttons: [
            {
              color: testing ? "secondary" : "primary",
              component: Button,
              disabled: isComputeNodeRunning,
              hidden: isStopped || isDeactivated || isPreviewNode,
              icon:
                isComputeNodeRunning && !isJobFailed ? (
                  <CircularProgress
                    sx={{ "--CircularProgress-size": "18px" }}
                  />
                ) : (
                  <FontAwesomeIcon fixedWidth={true} icon={faPlay} />
                ),
              name: testing ? "Run test" : "Run",
              onClick: runDevComputeNode,
              tooltip: isJobCompleted
                ? `Re-run${testing ? " test" : ""}`
                : `Run${testing ? " test" : ""}`,
              tooltipPlacement: "top",
              type: "Run",
            },
            {
              disabled: previewResultLoading,
              hidden:
                !isJobCompleted || isAutoFetchingJob || isComputeNodeRunning,
              icon: faFileMagnifyingGlass,
              loading: previewResultLoading,
              onClick: previewResult,
              tooltipPlacement: "top",
              tooltipTitle: (
                <div>
                  <div>Preview</div>
                  {resultSize && (
                    <div>
                      Note: this will consume {formatSize(resultSize)} from the
                      total Airlock quota.
                    </div>
                  )}
                </div>
              ),
              type: "Preview",
            },
            {
              hidden:
                !isJobCompleted ||
                !hasSdgQualityReport ||
                isStopped ||
                isDeactivated ||
                isComputeNodeRunning,
              icon: faFileChartColumn,
              onClick: () => openSdgQualityReportDialog(computeNodeId),
              tooltipPlacement: "top",
              tooltipTitle: "Quality report",
              type: "Quality report",
            },
            {
              disabled: downloadResultLoading,
              hidden:
                dependsOnPreviewNode ||
                !isJobCompleted ||
                isStopped ||
                isDeactivated ||
                isComputeNodeRunning,
              icon: faDownload,
              loading: downloadResultLoading,
              onClick: downloadResult,
              tooltipPlacement: "top",
              tooltipTitle: (
                <div>
                  <div>Download</div>
                  <div>
                    After you run an export process, you can download output
                    files into a file for use in another application.
                  </div>
                  {resultSize && (
                    <p>
                      Note: this will consume {formatSize(resultSize)} from the
                      total preview quota.
                    </p>
                  )}
                </div>
              ),
              type: "Download",
            },
            {
              hidden: isPreviewNode,
              icon: faArrowUpRightAndArrowDownLeftFromCenter,
              iconColor: grey[600],
              isRed: false,
              name: "Open in popup",
              onClick: () => openEditorDialog(computeNodeId),
              tooltipTitle: "Open in popup",
              type: "Open in popup",
            },
            {
              disabled: isComputeNodeRunning || deletingPlayground,
              icon: faTrashCan,
              isRed: false,
              loading: deletingPlayground,
              name: "Delete",
              onClick: deletePlayground,
              tooltipTitle: "Delete",
              type: "Delete",
            },
            {
              color: "primary",
              component: Button,
              disabled: isComputeNodeRunning || creatingRequestFromPlayground,
              hidden: testing || !enableRequests || isStopped || isDeactivated,
              icon: <FontAwesomeIcon icon={faPaperPlane} />,
              loading: creatingRequestFromPlayground,
              name: "Create request",
              onClick: createRequestFromPlayground,
              style: { marginLeft: "8px" },
              type: "Create request",
              variant: "soft",
            },
          ],
        }}
      />
    </>
  );
};

export default DevComputeNodeActions;
