// @ts-nocheck
import { memo, useEffect, useMemo } from "react";
import { SelectionsProvider, useSelections } from "contexts";
import { useSortedSelections } from "hooks";

const defaultItems = [];
const defaultSelectedItems = [];

interface SelectionsWrapperProps {
  children?: React.ReactNode;
  stopPropagation?: boolean;
}

const SelectionsWrapper: React.FC<SelectionsWrapperProps> = memo(
  ({
    children,
    ids: items = defaultItems,
    selectedIds: selectedItems = defaultSelectedItems,
    onSelect = () => {},
    stopPropagation = false,
  }) => {
    // Parent selections
    const {
      all: parentAll,
      selected: parentSelected,
      select: parentSelect,
      toggle: parentToggle,
      setSelected: parentSetSelected,
      unSelect: parentUnSelect,
      unSelectSome: parentUnSelectSome,
      selectSome: parentSelectSome,
      toggleSome: parentToggleSome,
    } = useSelections();
    const hasParent = Array.isArray(parentAll);
    // Current selections
    const selectItems = useMemo(
      () => (hasParent ? parentSelected : selectedItems),
      [hasParent, parentSelected, selectedItems]
    );
    const selections = useSortedSelections(items, selectItems);
    const {
      all,
      allSelected,
      areSelected,
      isSelected,
      noneSelected,
      partiallySelected,
      select,
      selectAll,
      selected,
      selectSome,
      setAll,
      setSelected,
      toggle,
      toggleAll,
      toggleSome,
      unSelect,
      unSelectAll,
      unSelectSome,
    } = selections;
    useEffect(() => onSelect(selected), [selected, onSelect]);
    // Update current based on parent
    useEffect(() => {
      if (Array.isArray(parentSelected)) {
        setSelected(() => all.filter((id) => parentSelected.includes(id)));
      }
    }, [all, parentSelected, setSelected]);
    return (
      <SelectionsProvider
        value={
          stopPropagation
            ? selections
            : {
                all,
                allSelected,
                areSelected,
                isSelected,
                noneSelected,
                partiallySelected,
                select: hasParent ? parentSelect : select,
                selectAll: hasParent
                  ? parentSelectSome.bind(null, all)
                  : selectAll,
                selectSome: hasParent ? parentSelectSome : selectSome,
                selected,
                // setAll: hasParent ? parentSetAll : setAll,
                setAll,
                setSelected: hasParent ? parentSetSelected : setSelected,
                toggle: hasParent ? parentToggle : toggle,
                toggleAll: hasParent
                  ? allSelected
                    ? parentUnSelectSome.bind(null, all)
                    : parentSelectSome.bind(null, all)
                  : toggleAll,
                toggleSome: hasParent ? parentToggleSome : toggleSome,
                unSelect: hasParent ? parentUnSelect : unSelect,
                unSelectAll: hasParent
                  ? parentUnSelectSome.bind(null, all)
                  : unSelectAll,
                unSelectSome: hasParent ? parentUnSelectSome : unSelectSome,
              }
        }
      >
        {children}
      </SelectionsProvider>
    );
  }
);

SelectionsWrapper.displayName = "SelectionsWrapper";

export default SelectionsWrapper;
