import {
  useCreateDraftRawLeafNodeMutation,
  useDeleteDraftRawLeafNodeMutation,
  useUpdateDraftRawLeafNodeMutation,
  useUpdateDraftRawLeafNodeNameMutation,
} from "@decentriq/graphql/dist/hooks";
import { useMemo } from "react";
import { v4 as uuidv4 } from "uuid";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import { type DataRoomData, type DataRoomDataDefinition } from "models";
import { DataRoomDocument } from "types";

interface RawNodeActionsHookParams {
  dataRoomId: string;
}

export const useRawNodeAcitons = ({ dataRoomId }: RawNodeActionsHookParams) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  /// mutations declarations
  const [createDraftRawLeafNode] = useCreateDraftRawLeafNodeMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(error, "File could not be created.")
      );
    },
    refetchQueries: [DataRoomDocument, "DraftDataRoomDataNodes"],
  });
  const [deleteDraftRawLeafNode] = useDeleteDraftRawLeafNodeMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(error, "File could not be deleted.")
      );
    },
    refetchQueries: [DataRoomDocument, "DraftDataRoomDataNodes"],
  });
  const [updateDraftRawLeafNode] = useUpdateDraftRawLeafNodeMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(error, "File could not be updated.")
      );
    },
    refetchQueries: [DataRoomDocument, "DraftDataRoomDataNodes"],
  });
  const [updateDraftRawLeafNodeName] = useUpdateDraftRawLeafNodeNameMutation({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(error, "File could not be updated.")
      );
    },
    refetchQueries: [DataRoomDocument, "DraftDataRoomDataNodes"],
  });
  /// handlers
  return useMemo(
    () => ({
      handleCreate: async ({ name }: DataRoomDataDefinition) => {
        const result = await createDraftRawLeafNode({
          variables: {
            input: {
              draftDataRoomId: dataRoomId,
              id: uuidv4(),
              isRequired: true,
              name,
            },
          },
        });
        return result.data?.dataNode?.create?.id;
      },
      handleDelete: ({ id }: DataRoomData) =>
        deleteDraftRawLeafNode({
          variables: {
            id,
          },
        }),
      handleDuplicate: ({ isRequired, name }: DataRoomData) =>
        createDraftRawLeafNode({
          variables: {
            input: {
              draftDataRoomId: dataRoomId,
              id: uuidv4(),
              isRequired,
              name,
            },
          },
        }),
      handleUpdateConstraint: async (node: {
        id: string;
        isRequired: boolean;
      }) => {
        await updateDraftRawLeafNode({
          variables: {
            input: {
              ...node,
            },
          },
        });
      },
      handleUpdateName: (node: { id: string; name: string }) =>
        updateDraftRawLeafNodeName({
          optimisticResponse: {
            __typename: "Mutation",
            draftRawLeafNode: {
              update: {
                __typename: "DraftRawLeafNode",
                ...node,
              },
            },
          },
          variables: {
            input: {
              ...node,
            },
          },
        }),
    }),
    [
      dataRoomId,
      createDraftRawLeafNode,
      deleteDraftRawLeafNode,
      updateDraftRawLeafNode,
      updateDraftRawLeafNodeName,
    ]
  );
};
