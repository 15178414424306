import { InfoTooltip } from "@decentriq/components";
import { faArrowRight } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, Button, Grid, LinearProgress, Link, Typography } from "@mui/joy";
import { memo } from "react";
import { AudienceQualityStatisticsLoading } from "features/mediaDataRoom/components";
import { useLookalikeAdvertiserAudienceGenerator } from "features/mediaDataRoom/containers/LookalikeAdvertiserAudienceGenerator";

interface PositiveCurveScoreBarProps {
  withChartButton?: boolean;
}

const PositiveCurveScoreBar: React.FC<PositiveCurveScoreBarProps> = ({
  withChartButton = true,
}) => {
  const { openAdvertiserAudienceQualityDrawer, selectedAudienceStatistics } =
    useLookalikeAdvertiserAudienceGenerator();
  const {
    recall,
    computeState: { loading: isStatisticsComputationLoading },
  } = selectedAudienceStatistics!;
  return (
    <Grid container={true} pl={0.5} pr={0.5} xs={12}>
      <Grid xs={12}>
        <Typography display="inline-flex" level="body-sm" textColor="inherit">
          ROC Curve
          <InfoTooltip
            tooltip={
              <Typography>
                <Link
                  href="https://en.wikipedia.org/wiki/Precision_and_recall"
                  rel="noreferrer"
                  target="_blank"
                >
                  Recall
                </Link>{" "}
                is measured with a holdout experiment using existing customers
                from the seed audience. For example, If the Recall score is 46%
                and the “exclude seed audience” option is not selected, the
                lookalike audience will include about 46% of the seed audience.
              </Typography>
            }
          />
        </Typography>
        <Typography fontWeight={400} level="body-sm">
          <Link
            href="https://en.wikipedia.org/wiki/Precision_and_recall"
            rel="noreferrer"
            target="_blank"
          >
            Recall
          </Link>{" "}
          is the percentage of the existing customers from the seed audience
          that will also be in this lookalike audience.
        </Typography>
      </Grid>
      <Grid alignItems="center" container={true} mt={1} xs={12}>
        <Grid justifyContent="flex-start" xs={8}>
          {isStatisticsComputationLoading ? (
            <AudienceQualityStatisticsLoading direction="row" />
          ) : (
            <Box alignItems="center" display="flex" mr={1} width="100%">
              <LinearProgress
                color="secondary"
                determinate={true}
                sx={({ radius }) => ({
                  borderRadius: radius["md"],
                  height: "12px",
                  mr: 1,
                  width: "100%",
                })}
                value={recall?.tpr ? recall?.tpr * 100 : 0}
              />
              <Typography level="body-sm">
                {recall?.tpr ? `${(recall?.tpr * 100).toFixed(1)}%` : "-%"}
              </Typography>
            </Box>
          )}
        </Grid>
        {withChartButton && (
          <Grid container={true} justifyContent="flex-end" xs={4}>
            <Button
              disabled={isStatisticsComputationLoading}
              onClick={openAdvertiserAudienceQualityDrawer}
              startDecorator={<FontAwesomeIcon icon={faArrowRight} />}
            >
              See graph
            </Button>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

PositiveCurveScoreBar.displayName = "PositiveCurveScoreBar";

export default memo(PositiveCurveScoreBar);
