import {
  usePublishedDataRoomPasswordRequirementsQuery,
  usePublishedDataRoomQuery,
} from "@decentriq/graphql/dist/hooks";
import { PublishedDataRoomSource } from "@decentriq/graphql/dist/types";
import { CircularProgress } from "@mui/joy";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  Navigate,
  useNavigate,
  useResolvedPath,
  useRoutes,
} from "react-router-dom";
import { Loading, Mainbar, PublishedDataRoomErrorReport } from "components";
import PublishedDataRoomTabs from "containers/PublishedDataRoomTabs/PublishedDataRoomTabs";
import {
  type PublishedDataRoomContextValue,
  PublishedDataRoomProvider,
} from "contexts";
import { mapErrorToGeneralSnackbar, useDataRoomSnackbar } from "hooks";
import { PublishedDataRoomTab, publishedDataRoomTabs } from "models";
import DataRoomExpertView from "../DataRoomExpertView/DataRoomExpertView";

interface PublishedDataRoomProps {
  dataRoomId: string;
  setAllowTestMode: (allowTestMode: boolean) => void;
}

const defaultTab = PublishedDataRoomTab.overview;

const PublishedDataRoom: React.FC<PublishedDataRoomProps> = ({
  dataRoomId,
  setAllowTestMode,
}) => {
  const navigate = useNavigate();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const [permittedOnly, setPermittedOnly] = useState<boolean>(true);
  const handlePermittedOnlyChange = useCallback(
    () => setPermittedOnly((state) => !state),
    [setPermittedOnly]
  );
  const resolvedPath = useResolvedPath("");
  const url = resolvedPath.pathname;
  const switchToRequests = useCallback(
    () => navigate(`${url}/${PublishedDataRoomTab.requests}`),
    [navigate, url]
  );
  const switchToOverview = useCallback(
    () => navigate(`${url}/${PublishedDataRoomTab.overview}`),
    [navigate, url]
  );
  const switchToDev = useCallback(
    () => navigate(`${url}/${PublishedDataRoomTab.development}`),
    [navigate, url]
  );
  const { data, loading, error } = usePublishedDataRoomQuery({
    onError: (error) => {
      if (
        !error.message.includes(
          "DcrSecretPolicy is set, but no corresponding DcrSecretClaim was found"
        )
      ) {
        enqueueSnackbar(
          ...mapErrorToGeneralSnackbar(
            error,
            "Published data clean room could not be retrieved. Please try again by refreshing the page."
          )
        );
      }
    },
    variables: {
      dataRoomId: dataRoomId,
    },
  });
  const enableInteractivity = Boolean(
    data?.publishedDataRoom?.enableInteractivity
  );
  const ownerEmail = data?.publishedDataRoom?.owner?.email;
  const isStopped = data?.publishedDataRoom?.isStopped || false;
  const isDeactivated = !!data?.publishedDataRoom?.deactivatedAt || false;
  const testing = data?.publishedDataRoom?.testing || false;
  const driverAttestationHash = data?.publishedDataRoom?.driverAttestationHash;
  const requestsTotalCount =
    data?.publishedDataRoom?.submittedRequests?.nodes?.length || 0;
  const enableAutomergeFeature =
    data?.publishedDataRoom?.enableAutomergeFeature || false;
  const enableAirlock = data?.publishedDataRoom?.enableAirlock || false;
  const createdAt = data?.publishedDataRoom?.createdAt;
  const enableTestDatasets = data?.publishedDataRoom?.enableTestDatasets;
  useEffect(() => {
    if (enableTestDatasets !== undefined) {
      setAllowTestMode(enableTestDatasets);
    }
  }, [setAllowTestMode, enableTestDatasets]);
  useEffect(() => {
    if (testing || isStopped || isDeactivated) {
      setPermittedOnly(false);
    }
  }, [testing, isStopped, isDeactivated, setPermittedOnly]);
  const tabs = useMemo(
    () => [
      {
        element: <Navigate to={`${url}/${defaultTab}`} />,
        path: "/",
      },
      ...publishedDataRoomTabs.map((tab) => ({
        element: (
          <PublishedDataRoomTabs
            activeTab={tab}
            baseUrl={url}
            createdAt={createdAt}
            handlePermittedOnlyChange={handlePermittedOnlyChange}
            ownerEmail={ownerEmail}
            permittedOnly={permittedOnly}
            requestsTotalCount={requestsTotalCount}
          />
        ),
        path: tab,
      })),
      {
        element: <Navigate to={`${url}/${defaultTab}`} />,
        path: "*",
      },
    ],
    [
      url,
      requestsTotalCount,
      ownerEmail,
      createdAt,
      permittedOnly,
      handlePermittedOnlyChange,
    ]
  );
  const activeTab = useRoutes(tabs);
  const publishedDataRoomContextValue = useMemo<PublishedDataRoomContextValue>(
    () => ({
      dataRoomId: dataRoomId || "",
      dcrHash: dataRoomId || "",
      driverAttestationHash: driverAttestationHash || "",
      enableAirlock,
      enableAutomergeFeature,
      enableRequests: enableInteractivity || false,
      isDeactivated,
      isStopped,
      switchToDev,
      switchToOverview,
      switchToRequests,
      testing,
    }),
    [
      dataRoomId,
      driverAttestationHash,
      enableAirlock,
      enableAutomergeFeature,
      enableInteractivity,
      isDeactivated,
      isStopped,
      switchToDev,
      switchToOverview,
      switchToRequests,
      testing,
    ]
  );
  if (loading) {
    return (
      <CircularProgress
        sx={{ "--CircularProgress-size": "2.5rem", margin: "auto" }}
      />
    );
  } else if (error) {
    return <Navigate replace={true} to="/datarooms" />;
  } else if (
    data &&
    data.publishedDataRoom.source === PublishedDataRoomSource.Sdk
  ) {
    return <DataRoomExpertView dataRoomId={dataRoomId} />;
  } else if (data) {
    return (
      <PublishedDataRoomProvider value={publishedDataRoomContextValue}>
        <Mainbar />
        {activeTab}
      </PublishedDataRoomProvider>
    );
  } else {
    return <Navigate replace={true} to="/datarooms" />;
  }
};

// NOTE:
// Instead of this workflow the "Unlocker" should be rendered
// when `publishedDataRoom` query yields an error, perhaps even with `ErrorBoundary`
const PublishedDataRoomWithErrorReport: React.FC<PublishedDataRoomProps> = ({
  dataRoomId,
  setAllowTestMode,
}) => {
  const { data, loading } = usePublishedDataRoomPasswordRequirementsQuery({
    variables: { id: dataRoomId },
  });
  const driverAttestationHash =
    data?.publishedDataRoom.driverAttestationHash ?? "";
  const createdAt = data?.publishedDataRoom.createdAt;
  return loading ? (
    <Loading />
  ) : (
    <PublishedDataRoomErrorReport
      createdAt={createdAt}
      dataRoomId={dataRoomId}
      driverAttestationHash={driverAttestationHash}
    >
      <PublishedDataRoom
        dataRoomId={dataRoomId}
        setAllowTestMode={setAllowTestMode}
      />
    </PublishedDataRoomErrorReport>
  );
};

export default PublishedDataRoomWithErrorReport;
