import {
  type data_science_commit,
  type data_science_data_room,
  proto,
  type types,
} from "@decentriq/core";
import {
  ColumnDataType,
  type CompleteDraftDataRoomQuery,
  type CompletePublishedDataRoomQuery,
  type CompleteSubmittedDataRoomRequestQuery,
  type DraftNode,
  type DraftNodeConnection,
  type DraftTableLeafNodeColumn,
  type PublishedNodeConnection,
  type PublishedTableLeafNodeColumn,
  ScriptingLanguage,
  SyntheticMaskType,
  type SyntheticNodeColumn,
  TableColumnFormatType,
  TableColumnHashingAlgorithm,
  WorkerTypeShortName,
} from "@decentriq/graphql/dist/types";
import * as forge from "node-forge";
import { get_dependencies } from "sql-deps-extraction";
import {
  type ComputationType,
  type PublishedComputeNodeTypeNames,
  type PublishedDataNodeTypeNames,
} from "models";
import { toHex } from ".";

/** Add all commits to the DCR */
export type FlattenDcrFn = (
  dcr: data_science_data_room.DataScienceDataRoom
) => data_science_data_room.DataScienceDataRoom;

/** Translate a HL commit into a submitted request */
export type TranslateCommitFn = (
  submittedDataRoomRequestId: string,
  dcr: data_science_data_room.DataScienceDataRoom,
  dcrCommit: data_science_commit.DataScienceCommit,
  driverAttestationHash: string,
  dcrId: string,
  commitId: string
) => CompleteSubmittedDataRoomRequestQuery["submittedDataRoomRequest"];

/** Translate a HL DCR into a PublishedDataRoom */
export type TranslateDcrFn = (
  dcr: data_science_data_room.DataScienceDataRoom,
  driverAttestationHash: string,
  dcrId: string,
  isStopped: boolean,
  publishedDatasetByNode: Map<string, proto.gcg.IPublishedDataset>
) => CompletePublishedDataRoomQuery["publishedDataRoom"];

export type BuildDcrFn = (
  draftDataRoom: CompleteDraftDataRoomQuery["draftDataRoom"],
  rootCertificatePem: Uint8Array,
  availableSpecs: Map<string, types.EnclaveSpecification>,
  isDraftMode: boolean,
  dcrSecretIdBase64?: string
) => data_science_data_room.DataScienceDataRoom;

export type BuildCommitFn = (
  draftNode: DraftNode,
  analysts: Array<string>,
  dataRoomId: string,
  historyPin: string,
  availableSpecs: Map<string, types.EnclaveSpecification>,
  usedSpecs: string[],
  nodeNamesMap: Map<string, string>,
  isDevMode: boolean
) => data_science_commit.DataScienceCommit;

export type RebaseCommitFn = (
  versionedCommit: data_science_commit.DataScienceCommit,
  newHistoryPin: string,
  usedSpecIds: string[]
) => void;

export type ChangeCommitExecutionPermissionsFn = (
  commit: data_science_commit.DataScienceCommit,
  user: string
) => void;

export enum EnclaveSpecName {
  SQL = "decentriq.sql-worker",
  SQLITE = "decentriq.python-ml-worker-32-64",
  GCG = "decentriq.driver",
  PYTHON = "decentriq.python-ml-worker-32-64",
  POST = "decentriq.post-worker",
  R = "decentriq.r-ml-worker-32-32",
  SYNTHETIC_DATA = "decentriq.python-synth-data-worker-32-64",
  S3 = "decentriq.s3-sink-worker",
}

export const containerComputationTypeToEnclaveType = new Map<
  ComputationType,
  EnclaveSpecName
>([
  ["python", EnclaveSpecName.PYTHON],
  ["r", EnclaveSpecName.R],
  ["synthetic_data", EnclaveSpecName.SYNTHETIC_DATA],
]);

export type PublishedMaskedColumn = Omit<
  Omit<Omit<SyntheticNodeColumn, "id">, "createdAt">,
  "updatedAt"
>;

export function draftToDataScienceDataType(
  dataType: ColumnDataType
): data_science_data_room.ColumnDataType {
  switch (dataType) {
    case ColumnDataType.Text: {
      return "string";
    }
    case ColumnDataType.Int: {
      return "integer";
    }
    case ColumnDataType.Float: {
      return "float";
    }
    default: {
      throw new Error(`Unsupported data type for column '${dataType}'`);
    }
  }
}

export function dataScienceToPublishedDataType(
  dataType: data_science_data_room.ColumnDataType
): ColumnDataType {
  switch (dataType) {
    case "string": {
      return ColumnDataType.Text;
    }
    case "integer": {
      return ColumnDataType.Int;
    }
    case "float": {
      return ColumnDataType.Float;
    }
    default: {
      throw new Error("Unsupported data type");
    }
  }
}

export function draftDataRoomToDataScienceDataRoomColumn(
  column: DraftTableLeafNodeColumn
): data_science_data_room.TableLeafNodeColumn {
  return {
    dataFormat: {
      dataType: draftToDataScienceDataType(column.dataType),
      isNullable: column.isNullable,
    },
    name: column.name,
  };
}

export function dataScienceDataRoomToPublishedDataRoomColumn(
  column: data_science_data_room.TableLeafNodeColumn
): PublishedTableLeafNodeColumn {
  return {
    __typename: "PublishedTableLeafNodeColumn",
    dataType: dataScienceToPublishedDataType(column.dataFormat.dataType),
    formatType: null,
    hashWith: null,
    isNullable: column.dataFormat.isNullable,
    name: column.name,
  };
}

function dataScienceToPublishedSyntheticMaskType(
  maskType: data_science_data_room.MaskType
): SyntheticMaskType {
  switch (maskType) {
    case "address": {
      return SyntheticMaskType.Address;
    }
    case "date": {
      return SyntheticMaskType.Date;
    }
    case "email": {
      return SyntheticMaskType.Email;
    }
    case "genericNumber": {
      return SyntheticMaskType.GenericNumber;
    }
    case "genericString": {
      return SyntheticMaskType.GenericString;
    }
    case "iban": {
      return SyntheticMaskType.Iban;
    }
    case "name": {
      return SyntheticMaskType.Name;
    }
    case "phoneNumber": {
      return SyntheticMaskType.PhoneNumber;
    }
    case "postcode": {
      return SyntheticMaskType.Postcode;
    }
    case "socialSecurityNumber": {
      return SyntheticMaskType.SocialSecurityNumber;
    }
    case "timestamp": {
      return SyntheticMaskType.Timestamp;
    }
    default: {
      throw new Error("Unsupported mask type");
    }
  }
}

function draftToDataScienceSyntheticMaskType(
  maskType: SyntheticMaskType
): data_science_data_room.MaskType {
  switch (maskType) {
    case SyntheticMaskType.Address: {
      return "address";
    }
    case SyntheticMaskType.Date: {
      return "date";
    }
    case SyntheticMaskType.Email: {
      return "email";
    }
    case SyntheticMaskType.GenericNumber: {
      return "genericNumber";
    }
    case SyntheticMaskType.GenericString: {
      return "genericString";
    }
    case SyntheticMaskType.Iban: {
      return "iban";
    }
    case SyntheticMaskType.Name: {
      return "name";
    }
    case SyntheticMaskType.PhoneNumber: {
      return "phoneNumber";
    }
    case SyntheticMaskType.Postcode: {
      return "postcode";
    }
    case SyntheticMaskType.SocialSecurityNumber: {
      return "socialSecurityNumber";
    }
    case SyntheticMaskType.Timestamp: {
      return "timestamp";
    }
    default: {
      throw new Error("Unsupported mask type");
    }
  }
}

export function dataScienceToPublishedScriptingLanguageV9(
  scriptingLanguage: data_science_data_room.ScriptingLanguageV9
): ScriptingLanguage {
  if (scriptingLanguage == "r") {
    return ScriptingLanguage.R;
  } else if ("python" in scriptingLanguage) {
    return ScriptingLanguage.Python;
  } else {
    throw new Error("Unsupported scripting language");
  }
}

export function dataScienceToPublishedScriptingLanguage(
  scriptingLanguage: data_science_data_room.ScriptingLanguage
): ScriptingLanguage {
  switch (scriptingLanguage) {
    case "python": {
      return ScriptingLanguage.Python;
    }
    case "r": {
      return ScriptingLanguage.R;
    }
    default: {
      throw new Error("Unsupported scripting language");
    }
  }
}

export function draftToDataScienceScriptingLanguageV9(
  scriptingLanguage: ScriptingLanguage
): data_science_data_room.ScriptingLanguageV9 {
  switch (scriptingLanguage) {
    case ScriptingLanguage.Python: {
      return {
        python: {},
      };
    }
    case ScriptingLanguage.R: {
      return "r";
    }
    default: {
      throw new Error(`Unknown scripting language '${scriptingLanguage}'`);
    }
  }
}

export function draftToDataScienceScriptingLanguage(
  scriptingLanguage: ScriptingLanguage
): data_science_data_room.ScriptingLanguage {
  switch (scriptingLanguage) {
    case ScriptingLanguage.Python: {
      return "python";
    }
    case ScriptingLanguage.R: {
      return "r";
    }
    default: {
      throw new Error(`Unknown scripting language '${scriptingLanguage}'`);
    }
  }
}

export function draftDataRoomConnectionToDraftNodeId(
  connection: PublishedNodeConnection | DraftNodeConnection
): string {
  if (connection.__typename === "PublishedNodeConnection") {
    return connection.computeNodeId;
  }
  if (connection.__typename === "DraftNodeConnection") {
    return connection.draftNode.id;
  }
  throw new Error(`Unknown connection type '${connection.__typename}`);
}

export class EnclaveSpecificationManager {
  addedSpecifications: Map<string, data_science_data_room.EnclaveSpecification>;

  constructor(
    public availableSpecs: Map<string, types.EnclaveSpecification>,
    private alreadyAddedSpecs: Array<string>
  ) {
    this.addedSpecifications = new Map();
  }

  getEnclaveSpecificationId(enclaveType: EnclaveSpecName): string {
    // check if the enclave specification was already added
    if (this.alreadyAddedSpecs.includes(enclaveType)) {
      return enclaveType;
    }

    const addedSpec = this.addedSpecifications.get(enclaveType);
    if (addedSpec) {
      return addedSpec.id;
    } else {
      const spec = this.availableSpecs.get(enclaveType);
      if (!spec) {
        throw new Error(
          `No available enclave specification for type '${enclaveType}'`
        );
      }
      const attestationProtoBase64 = forge.util.binary.base64.encode(
        proto.attestation.AttestationSpecification.encodeDelimited(
          spec.proto
        ).finish()
      );
      const specification: data_science_data_room.EnclaveSpecification = {
        attestationProtoBase64,
        id: spec.name,
        workerProtocol: spec.workerProtocols[0],
      };
      this.addedSpecifications.set(enclaveType, specification);
      return specification.id;
    }
  }

  getAddedEnclaveSpecifications(): data_science_data_room.EnclaveSpecification[] {
    return Array.from(this.addedSpecifications.values());
  }
}

export function translateDataScienceNodeToPublishedNode(
  node: data_science_data_room.Node,
  driverAttestationHash: string,
  dcrId: string,
  commitId: string | null,
  nodeTypeNamesMap: Map<
    string,
    {
      name: string;
      __typename: PublishedComputeNodeTypeNames | PublishedDataNodeTypeNames;
    }
  >,
  nodePermissions: NodePermissionsType,
  publishedDatasetByNode: Map<string, proto.gcg.IPublishedDataset> | undefined
): CompletePublishedDataRoomQuery["publishedDataRoom"]["publishedNodes"][number] {
  const nodeKind = node.kind;
  if ("leaf" in nodeKind) {
    const leafNodeRequired = nodeKind.leaf.isRequired;
    const leafNodeKind = nodeKind.leaf.kind;
    let dataset;
    if (publishedDatasetByNode) {
      const publishedDataset =
        publishedDatasetByNode && publishedDatasetByNode.get(node.id);
      if (publishedDataset) {
        dataset = {
          datasetHash: toHex(publishedDataset.datasetHash!),
          leafId: publishedDataset.leafId,
          timestamp: publishedDataset.timestamp,
          user: publishedDataset.user,
        };
      }
    }

    if ("table" in leafNodeKind) {
      const tableNode = leafNodeKind.table;
      return {
        __typename: "PublishedTableLeafNode",
        columns: tableNode.columns.map(
          dataScienceDataRoomToPublishedDataRoomColumn
        ),
        commitId,
        dataRoomId: undefined,
        dataset: dataset !== undefined ? dataset : null,
        dcrHash: dcrId,
        driverAttestationHash,
        id: node.id,
        isRequired: leafNodeRequired,
        name: node.name,
        permissions: nodePermissions,
      };
    } else if ("raw" in leafNodeKind) {
      return {
        __typename: "PublishedRawLeafNode",
        commitId,
        dataRoomId: undefined,
        dataset: dataset !== undefined ? dataset : null,
        dcrHash: dcrId,
        driverAttestationHash,
        id: node.id,
        isRequired: leafNodeRequired,
        name: node.name,
        permissions: nodePermissions,
      };
    } else {
      throw new Error("Unknown leaf node kind");
    }
  } else if ("computation" in nodeKind) {
    const computationNodeKind = nodeKind.computation.kind;
    if ("sql" in computationNodeKind) {
      const sqlNode = computationNodeKind.sql;
      let privacyFilter;
      if (sqlNode.privacyFilter) {
        privacyFilter = {
          isEnabled: true,
          minimumRows: sqlNode.privacyFilter.minimumRowsCount,
        };
      } else {
        privacyFilter = {
          isEnabled: false,
          minimumRows: 0,
        };
      }
      return {
        __typename: "PublishedSqlNode",
        commitId,
        computationType: WorkerTypeShortName.Sql,
        dataRoomId: undefined,
        dcrHash: dcrId,
        dependencies: [],
        driverAttestationHash,
        id: node.id,
        name: node.name,
        permissions: nodePermissions,
        privacyFilter,
        statement: sqlNode.statement,
      };
    } else if ("sqlite" in computationNodeKind) {
      const sqliteNode = computationNodeKind.sqlite;
      let privacyFilter;
      if (sqliteNode.privacyFilter) {
        privacyFilter = {
          isEnabled: true,
          minimumRows: sqliteNode.privacyFilter.minimumRowsCount,
        };
      } else {
        privacyFilter = {
          isEnabled: false,
          minimumRows: 0,
        };
      }
      return {
        __typename: "PublishedSqliteNode",
        commitId,
        computationType: WorkerTypeShortName.Sqlite,
        dataRoomId: undefined,
        dcrHash: dcrId,
        dependencies: [],
        driverAttestationHash,
        id: node.id,
        name: node.name,
        permissions: nodePermissions,
        privacyFilter,
        statement: sqliteNode.statement,
      };
    } else if ("match" in computationNodeKind) {
      const matchNode = computationNodeKind.match;
      return {
        __typename: "PublishedMatchNode",
        commitId,
        computationType: WorkerTypeShortName.Match,
        config: JSON.parse(matchNode.config || null),
        dataRoomId: undefined,
        dcrHash: dcrId,
        dependencies: [],
        driverAttestationHash,
        id: node.id,
        name: node.name,
        permissions: nodePermissions,
      };
    } else if ("scripting" in computationNodeKind) {
      const scriptingNode = computationNodeKind.scripting;
      const scripts = [
        {
          content: scriptingNode.mainScript.content,
          isMainScript: true,
          name: scriptingNode.mainScript.name,
        },
        ...scriptingNode.additionalScripts.map((script) => ({
          content: script.content,
          isMainScript: false,
          name: script.name,
        })),
      ];
      const scriptingLanguage = dataScienceToPublishedScriptingLanguage(
        scriptingNode.scriptingLanguage
      );
      return {
        __typename: "PublishedScriptingNode",
        commitId,
        computationType:
          scriptingLanguage === ScriptingLanguage.Python
            ? WorkerTypeShortName.Python
            : WorkerTypeShortName.R,
        dataRoomId: undefined,
        dcrHash: dcrId,
        dependencies: scriptingNode.dependencies.map((node_id) => ({
          __typename: nodeTypeNamesMap.get(node_id)!.__typename,
          commitId,
          dcrHash: dcrId,
          driverAttestationHash,
          id: node_id,
          name: nodeTypeNamesMap.get(node_id)!.name,
        })),
        driverAttestationHash,
        id: node.id,
        name: node.name,
        output: scriptingNode.output,
        permissions: nodePermissions,
        scriptingLanguage,
        scripts,
      };
    } else if ("s3Sink" in computationNodeKind) {
      const s3SinkNode = computationNodeKind.s3Sink;
      return {
        __typename: "PublishedS3SinkNode",
        commitId,
        computationType: WorkerTypeShortName.S3,
        credentialsDependency: {
          __typename: nodeTypeNamesMap.get(s3SinkNode.credentialsDependencyId)!
            .__typename,
          commitId,
          dcrHash: dcrId,
          driverAttestationHash,
          id: s3SinkNode.credentialsDependencyId,
          name: nodeTypeNamesMap.get(s3SinkNode.credentialsDependencyId)!.name,
        },
        dataRoomId: undefined,
        dcrHash: dcrId,
        driverAttestationHash,
        endpoint: s3SinkNode.endpoint,
        id: node.id,
        name: node.name,
        permissions: nodePermissions,
        region: s3SinkNode.region,
        uploadDependency: {
          __typename: nodeTypeNamesMap.get(s3SinkNode.uploadDependencyId)!
            .__typename,
          commitId,
          dcrHash: dcrId,
          driverAttestationHash,
          id: s3SinkNode.uploadDependencyId,
          name: nodeTypeNamesMap.get(s3SinkNode.uploadDependencyId)!.name,
        },
      };
    } else if ("syntheticData" in computationNodeKind) {
      const syntheticDataNode = computationNodeKind.syntheticData;
      return {
        __typename: "PublishedSyntheticNode",
        accuracy: syntheticDataNode.epsilon,
        columns: syntheticDataNode.columns.map(
          dataScienceToPublishedMaskedColumn
        ),
        commitId,
        computationType: WorkerTypeShortName.Synthetic,
        dataRoomId: undefined,
        dcrHash: dcrId,
        dependency: {
          __typename: nodeTypeNamesMap.get(syntheticDataNode.dependency)!
            .__typename,
          commitId,
          dcrHash: dcrId,
          driverAttestationHash,
          id: syntheticDataNode.dependency,
          name: nodeTypeNamesMap.get(syntheticDataNode.dependency)!.name,
        },
        driverAttestationHash,
        id: node.id,
        includeReportWithStats: syntheticDataNode.outputOriginalDataStatistics,
        name: node.name,
        permissions: nodePermissions,
      };
    } else {
      throw new Error(
        `Unknown computation node kind:\n${JSON.stringify(
          computationNodeKind,
          null,
          2
        )}`
      );
    }
  } else {
    throw new Error("Unknown node kind");
  }
}

export type NodePermissionsType = NonNullable<
  CompletePublishedDataRoomQuery["publishedDataRoom"]["publishedNodes"][number]["permissions"]
>;

export function draftToDataScienceMaskedColumn(
  column: Omit<Omit<Omit<SyntheticNodeColumn, "id">, "createdAt">, "updatedAt">
): data_science_data_room.SyntheticNodeColumn {
  return {
    dataFormat: {
      dataType: draftToDataScienceDataType(column.dataType),
      isNullable: false,
    },
    index: column.index,
    maskType: draftToDataScienceSyntheticMaskType(column.maskType),
    name: column.name,
    shouldMaskColumn: column.shouldMaskColumn,
  };
}

export function dataScienceToPublishedMaskedColumn(
  column: data_science_data_room.SyntheticNodeColumn
): PublishedMaskedColumn {
  return {
    dataType: dataScienceToPublishedDataType(column.dataFormat.dataType),
    index: column.index,
    maskType: dataScienceToPublishedSyntheticMaskType(column.maskType),
    name: column.name || "",
    shouldMaskColumn: column.shouldMaskColumn,
  };
}

export function dataScienceToPublishedTypename(
  node:
    | data_science_data_room.Node
    | data_science_data_room.NodeV2
    | data_science_data_room.NodeV6
    | data_science_data_room.NodeV9
): CompletePublishedDataRoomQuery["publishedDataRoom"]["publishedNodes"][number]["__typename"] {
  const nodeKind = node.kind;
  if ("leaf" in nodeKind) {
    const leafNodeKind = nodeKind.leaf.kind;
    if ("table" in leafNodeKind) {
      return "PublishedTableLeafNode";
    } else if ("raw" in leafNodeKind) {
      return "PublishedRawLeafNode";
    } else {
      throw new Error("Unknown leaf node kind");
    }
  } else if ("computation" in nodeKind) {
    const computationNodeKind = nodeKind.computation.kind;
    if ("sql" in computationNodeKind) {
      return "PublishedSqlNode";
    } else if ("sqlite" in computationNodeKind) {
      return "PublishedSqliteNode";
    } else if ("match" in computationNodeKind) {
      return "PublishedMatchNode";
    } else if ("scripting" in computationNodeKind) {
      return "PublishedScriptingNode";
    } else if ("s3Sink" in computationNodeKind) {
      return "PublishedS3SinkNode";
    } else if ("syntheticData" in computationNodeKind) {
      return "PublishedSyntheticNode";
    } else {
      throw new Error(
        `Unknown computation node kind:\n${JSON.stringify(
          computationNodeKind,
          null,
          2
        )}`
      );
    }
  } else {
    throw new Error("Unknown node kind");
  }
}

export type PermissionsType = NonNullable<
  CompletePublishedDataRoomQuery["publishedDataRoom"]["participants"][number]
>["permissions"];

export function translateDraftNodeToDataScienceNode(
  node: CompleteDraftDataRoomQuery["draftDataRoom"]["draftNodes"]["nodes"][number],
  enclaveSpecificationsManager: EnclaveSpecificationManager,
  nodeNamesMap: Map<string, string>,
  isDraftMode: boolean
): data_science_data_room.Node {
  try {
    switch (node.__typename) {
      case "DraftRawLeafNode": {
        return {
          id: node.id,
          kind: {
            leaf: {
              isRequired: node.isRequired,
              kind: {
                raw: {},
              },
            },
          },
          name: node.name,
        };
      }
      case "DraftTableLeafNode": {
        const sqlSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.SQL
          );
        let sortedColumns = node.columns.nodes;
        if (!sortedColumns.length) {
          throw new Error("No table columns provided");
        }
        if (node.columnsOrder.length > 0) {
          const columnsOrderMap = new Map(
            node.columnsOrder.map((id, index) => {
              return [id, index];
            })
          );
          sortedColumns = sortedColumns
            .slice()
            .sort(
              (a, b) =>
                (columnsOrderMap.get(a.id) || 0) -
                (columnsOrderMap.get(b.id) || 0)
            );
        }
        return {
          id: node.id,
          kind: {
            leaf: {
              isRequired: node.isRequired,
              kind: {
                table: {
                  columns: sortedColumns.map(
                    draftDataRoomToDataScienceDataRoomColumn
                  ),
                  sqlSpecificationId: sqlSpecId,
                },
              },
            },
          },
          name: node.name,
        };
      }
      case "DraftSqlNode": {
        const sqlSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.SQL
          );
        let tableDependencies: Array<string>;
        try {
          tableDependencies = JSON.parse(get_dependencies(node.statement!));
        } catch (err) {
          throw new Error(`Invalid SQL statement, '${err}'`);
        }

        const privacyFilter:
          | data_science_data_room.SqlNodePrivacyFilter
          | undefined = node.privacyFilter?.isEnabled
          ? {
              minimumRowsCount: node.privacyFilter.minimumRows,
            }
          : undefined;
        return {
          id: node.id,
          kind: {
            computation: {
              kind: {
                sql: {
                  dependencies: tableDependencies.map((tableName) => {
                    const nodeId = nodeNamesMap.get(tableName);
                    if (nodeId === undefined) {
                      throw new Error(
                        `Invalid SQL statement table, no matching data source with table name '${tableName}'`
                      );
                    }
                    return {
                      nodeId,
                      tableName,
                    };
                  }),
                  privacyFilter,
                  specificationId: sqlSpecId,
                  statement: node.statement!,
                },
              },
            },
          },
          name: node.name,
        };
      }
      case "DraftSqliteNode": {
        const sqliteSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.SQLITE
          );
        let tableDependencies: Array<string>;
        try {
          tableDependencies = JSON.parse(get_dependencies(node.statement!));
        } catch (err) {
          throw new Error(`Invalid SQL statement, '${err}'`);
        }
        return {
          id: node.id,
          kind: {
            computation: {
              kind: {
                sqlite: {
                  dependencies: tableDependencies.map((tableName) => {
                    const nodeId = nodeNamesMap.get(tableName);
                    if (nodeId === undefined) {
                      throw new Error(
                        `Invalid SQL statement table, no matching data source with table name '${tableName}'`
                      );
                    }
                    return {
                      nodeId,
                      tableName,
                    };
                  }),
                  enableLogsOnError: isDraftMode,
                  enableLogsOnSuccess: isDraftMode,
                  sqliteSpecificationId: sqliteSpecId,
                  statement: node.statement!,
                  staticContentSpecificationId: "decentriq.driver",
                },
              },
            },
          },
          name: node.name,
        };
      }
      case "DraftMatchNode": {
        const matchSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.PYTHON
          );
        return {
          id: node.id,
          kind: {
            computation: {
              kind: {
                match: {
                  config: JSON.stringify(node.config),
                  dependencies: node.dependencies.nodes.map((dep) =>
                    draftDataRoomConnectionToDraftNodeId(
                      dep as DraftNodeConnection | PublishedNodeConnection
                    )
                  ),
                  enableLogsOnError: true,
                  enableLogsOnSuccess: true,
                  output: "/output",
                  specificationId: matchSpecId,
                  staticContentSpecificationId: "decentriq.driver",
                },
              },
            },
          },
          name: node.name,
        };
      }
      case "DraftScriptingNode": {
        let scriptingSpecId;
        switch (node.scriptingLanguage) {
          case ScriptingLanguage.Python: {
            scriptingSpecId =
              enclaveSpecificationsManager.getEnclaveSpecificationId(
                EnclaveSpecName.PYTHON
              );
            break;
          }
          case ScriptingLanguage.R: {
            scriptingSpecId =
              enclaveSpecificationsManager.getEnclaveSpecificationId(
                EnclaveSpecName.R
              );
            break;
          }
          default: {
            throw new Error(
              `Unsupported scripting language '${node.scriptingLanguage}'`
            );
          }
        }
        const staticContentSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.GCG
          );
        const mainScript = node.scripts.nodes.find(
          (script) => script.isMainScript
        );
        if (mainScript === undefined) {
          throw new Error("No main script found");
        }
        if (mainScript.name === undefined || mainScript.name === null) {
          throw new Error("Main script name is undefined");
        }
        if (!mainScript.content) {
          throw new Error(`Main script '${mainScript.name}' is empty`);
        }
        const additionalScripts = node.scripts.nodes
          .filter((script) => !script.isMainScript)
          .map((script) => {
            if (script.name === undefined || script.name === null) {
              throw new Error("Script name is undefined");
            }
            if (!script.content) {
              throw new Error(`Script '${script.name}' is empty`);
            }
            return {
              content: script.content,
              name: script.name,
            };
          });
        if (!node.output) {
          throw new Error("Output location is empty");
        }
        return {
          id: node.id,
          kind: {
            computation: {
              kind: {
                scripting: {
                  additionalScripts: additionalScripts,
                  dependencies: node.dependencies.nodes.map((dep) =>
                    draftDataRoomConnectionToDraftNodeId(
                      dep as DraftNodeConnection | PublishedNodeConnection
                    )
                  ),
                  enableLogsOnError: isDraftMode,
                  enableLogsOnSuccess: isDraftMode,
                  mainScript: {
                    content: mainScript.content,
                    name: mainScript.name,
                  },
                  output: node.output,
                  scriptingLanguage: draftToDataScienceScriptingLanguage(
                    node.scriptingLanguage
                  ),
                  scriptingSpecificationId: scriptingSpecId,
                  staticContentSpecificationId: staticContentSpecId,
                },
              },
            },
          },
          name: node.name,
        };
      }
      case "DraftS3SinkNode": {
        const s3SpecId = enclaveSpecificationsManager.getEnclaveSpecificationId(
          EnclaveSpecName.S3
        );
        if (!node.endpoint) {
          throw new Error("Empty endpoint");
        }
        if (!node.region) {
          throw new Error("Empty region");
        }
        if (!node.credentialsDependency) {
          throw new Error("No credentials dependecy provided");
        }
        if (!node.uploadDependency) {
          throw new Error("No upload dependecy provided");
        }
        return {
          id: node.id,
          kind: {
            computation: {
              kind: {
                s3Sink: {
                  credentialsDependencyId: draftDataRoomConnectionToDraftNodeId(
                    node.credentialsDependency as
                      | DraftNodeConnection
                      | PublishedNodeConnection
                  ),
                  endpoint: node.endpoint,
                  region: node.region,
                  specificationId: s3SpecId,
                  uploadDependencyId: draftDataRoomConnectionToDraftNodeId(
                    node.uploadDependency as
                      | DraftNodeConnection
                      | PublishedNodeConnection
                  ),
                },
              },
            },
          },
          name: node.name,
        };
      }
      case "DraftSyntheticNode": {
        const syntheticSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.SYNTHETIC_DATA
          );
        const staticContentSpecId =
          enclaveSpecificationsManager.getEnclaveSpecificationId(
            EnclaveSpecName.GCG
          );
        if (!node.dependency) {
          throw new Error("Dependency not selected");
        }
        return {
          id: node.id,
          kind: {
            computation: {
              kind: {
                syntheticData: {
                  columns: node.columns.nodes.map(
                    draftToDataScienceMaskedColumn
                  ),
                  dependency: draftDataRoomConnectionToDraftNodeId(
                    node.dependency as
                      | DraftNodeConnection
                      | PublishedNodeConnection
                  ),
                  enableLogsOnError: isDraftMode,
                  enableLogsOnSuccess: isDraftMode,
                  epsilon: node.accuracy,
                  outputOriginalDataStatistics: node.includeReportWithStats,
                  staticContentSpecificationId: staticContentSpecId,
                  synthSpecificationId: syntheticSpecId,
                },
              },
            },
          },
          name: node.name,
        };
      }
      // TODO: add post node
      default: {
        throw new Error(`Unknown node type ${node.__typename}`);
      }
    }
  } catch (err) {
    const message = err instanceof Error ? err.message : String(err);
    throw new BuildError(message, {
      nodeId: node.id,
      nodeName: node?.name,
    });
  }
}

export interface BuildErrorExtraInfo {
  nodeId?: string;
  nodeName?: string;
}
export class BuildError extends Error {
  extraInfo?: BuildErrorExtraInfo;

  constructor(message: string, extraInfo?: BuildErrorExtraInfo) {
    super(message);
    this.extraInfo = extraInfo;
    Object.setPrototypeOf(this, BuildError.prototype);
  }
}

export function draftToHighLevelHashingAlgorithm(
  algorithm: TableColumnHashingAlgorithm
): data_science_data_room.HashingAlgorithm {
  switch (algorithm) {
    case TableColumnHashingAlgorithm.Sha256Hex:
      return "SHA256_HEX";
  }
}

export function draftToHighLevelFormatType(
  formatType: TableColumnFormatType
): data_science_data_room.FormatType {
  switch (formatType) {
    case TableColumnFormatType.DateIso8601:
      return "DATE_ISO8601";
    case TableColumnFormatType.Email:
      return "EMAIL";
    case TableColumnFormatType.Float:
      return "FLOAT";
    case TableColumnFormatType.HashSha256Hex:
      return "HASH_SHA256_HEX";
    case TableColumnFormatType.Integer:
      return "INTEGER";
    case TableColumnFormatType.PhoneNumberE164:
      return "PHONE_NUMBER_E164";
    case TableColumnFormatType.String:
      return "STRING";
  }
}

export function draftDataTypeToHighLevelFormatType(
  dataType: ColumnDataType
): data_science_data_room.FormatType {
  switch (dataType) {
    case ColumnDataType.Int:
      return "INTEGER";
    case ColumnDataType.Text:
      return "STRING";
    case ColumnDataType.Float:
      return "FLOAT";
  }
}
