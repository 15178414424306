import {
  type MatchNodeNameEditMutationOptions,
  type PostNodeNameEditMutationOptions,
  type PreviewNodeNameEditMutationOptions,
  type S3NodeNameEditMutationOptions,
  type ScriptingNodeNameEditMutationOptions,
  type SqliteNodeNameEditMutationOptions,
  type SqlNodeNameEditMutationOptions,
  type SyntheticNodeNameEditMutationOptions,
  useMatchNodeNameEditMutation,
  usePostNodeNameEditMutation,
  usePreviewNodeNameEditMutation,
  useS3NodeNameEditMutation,
  useScriptingNodeNameEditMutation,
  useSqliteNodeNameEditMutation,
  useSqlNodeNameEditMutation,
  useSyntheticNodeNameEditMutation,
} from "@decentriq/graphql/dist/hooks";
import { ComputeNodeTypeNames } from "models";
import { logDebug } from "utils";

type ComputeNodeEditNameMutationOptions =
  | MatchNodeNameEditMutationOptions
  | PostNodeNameEditMutationOptions
  | PreviewNodeNameEditMutationOptions
  | S3NodeNameEditMutationOptions
  | ScriptingNodeNameEditMutationOptions
  | SqliteNodeNameEditMutationOptions
  | SqlNodeNameEditMutationOptions
  | SyntheticNodeNameEditMutationOptions;

const useComputeNodeNameEditMutation = (
  typename: ComputeNodeTypeNames,
  mutationOptions: ComputeNodeEditNameMutationOptions = {}
) => {
  const setMatchNodeNameMutation = useMatchNodeNameEditMutation(
    mutationOptions as MatchNodeNameEditMutationOptions
  );
  const setPostNodeNameMutation = usePostNodeNameEditMutation(
    mutationOptions as PostNodeNameEditMutationOptions
  );
  const setPreviewNodeNameMutation = usePreviewNodeNameEditMutation(
    mutationOptions as PreviewNodeNameEditMutationOptions
  );
  const setS3NodeNameMutation = useS3NodeNameEditMutation(
    mutationOptions as S3NodeNameEditMutationOptions
  );
  const setScriptingNodeNameMutation = useScriptingNodeNameEditMutation(
    mutationOptions as ScriptingNodeNameEditMutationOptions
  );
  const setSqlNodeNameMutation = useSqlNodeNameEditMutation(
    mutationOptions as SqlNodeNameEditMutationOptions
  );
  const setSqliteNodeNameMutation = useSqliteNodeNameEditMutation(
    mutationOptions as SqliteNodeNameEditMutationOptions
  );
  const setSyntheticNodeNameMutation = useSyntheticNodeNameEditMutation(
    mutationOptions as SyntheticNodeNameEditMutationOptions
  );
  switch (typename) {
    case ComputeNodeTypeNames.DraftMatchNode:
      return setMatchNodeNameMutation;
    case ComputeNodeTypeNames.DraftSqlNode:
      return setSqlNodeNameMutation;
    case ComputeNodeTypeNames.DraftSqliteNode:
      return setSqliteNodeNameMutation;
    case ComputeNodeTypeNames.DraftPostNode:
      return setPostNodeNameMutation;
    case ComputeNodeTypeNames.DraftPreviewNode:
      return setPreviewNodeNameMutation;
    case ComputeNodeTypeNames.DraftScriptingNode:
      return setScriptingNodeNameMutation;
    case ComputeNodeTypeNames.DraftS3SinkNode:
      return setS3NodeNameMutation;
    case ComputeNodeTypeNames.DraftSyntheticNode:
      return setSyntheticNodeNameMutation;
    default:
      logDebug(`No mutation for updating name for "${typename}" found`);
      return [() => {}, {}] as [Function, object];
  }
};

export default useComputeNodeNameEditMutation;
