import {
  useAddScriptingNodeDependencyMutation,
  useRemoveScriptingNodeDependencyMutation,
} from "@decentriq/graphql/dist/hooks";
import { ScriptingLanguage } from "@decentriq/graphql/dist/types";
import { testIds } from "@decentriq/utils";
import { faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  Checkbox,
  FormControl,
  FormLabel,
  List,
  ListDivider,
  ListItem,
  ListItemDecorator,
  Option,
  Select,
  type SelectOption,
  Stack,
  Tooltip,
  Typography,
} from "@mui/joy";
import groupBy from "lodash/groupBy";
import xor from "lodash/xor";
import { Fragment, memo, useCallback } from "react";
import { useComputeNodesVars, useDataRoom } from "contexts";
import { mapDraftDataRoomErrorToSnackbar, useDataRoomSnackbar } from "hooks";
import {
  type ScriptingInputNodeSnapshot,
  scriptingNodeGroupLabelsMap,
} from "models";
import useScriptingComputeNode from "../../useScriptingComputeNode";
import useScriptingNodeInputs from "../../useScriptingNodeInputs/useScriptingNodeInputs";
import OutputFolderEditor from "../OutputFolderEditor/OutputFolderEditor";

const SHOW_PYTHON_OUTPUT = false;
const SHOW_R_OUTPUT = false;

const scriptingInputsEditorConfig = new Map<ScriptingLanguage, React.ReactNode>(
  [
    [
      ScriptingLanguage.Python,
      <span>
        Tables, files and the results of other computations selected in the
        dropdown can be loaded as files.
        <br />
        <br />
        To read tabular results, e.g. TABLE, SQL, MATCHING, SYNTHETIC:
        <br />
        <code>
          df_table =
          decentriq_util.read_tabular_data("/input/table_or_computation_name")
        </code>
        <br />
        <br />
        To read any file or unstructured data, use the absolute paths:
        <br />
        <code>f = open("/input/computation_name/file.json", "r")</code>
        <br />
        <br />
        Hint: Copy snippets for each input file to the clipboard directly from
        the file browser.
        <br />
      </span>,
    ],
    [
      ScriptingLanguage.R,
      <span>
        Tables, files and the results of other computations selected in the
        dropdown can be loaded as files.
        <br />
        Use the absolute paths, for example:
        <br />
        <code>"/input/table_name/dataset.csv"</code>
      </span>,
    ],
  ]
);

interface InputsEditorProps {
  computeNodeId: string;
  readOnly?: boolean;
}

const InputsEditor = memo<InputsEditorProps>(({ computeNodeId, readOnly }) => {
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const { dataRoomId } = useDataRoom();
  // Fetch scripting compute node
  const { scriptingLanguage, dependencies } =
    useScriptingComputeNode(computeNodeId);
  const { executionContext } = useComputeNodesVars();
  const isInteractivityContext =
    executionContext === "development" || executionContext === "requests";
  const scriptingNodeAvailableInputs = useScriptingNodeInputs(computeNodeId);
  const groupedInputList = groupBy(scriptingNodeAvailableInputs, "typename");
  const scriptingNodeDependenciesIds = dependencies?.map((node) => node.id);
  const availableDataTooltip = scriptingInputsEditorConfig.get(
    scriptingLanguage!
  );
  // Changing dependencies
  const [addScriptingNodeDependencyMutation, { loading: adding }] =
    useAddScriptingNodeDependencyMutation();
  const addScriptingNodeDependency = useCallback(
    async (dependencyId: string) => {
      try {
        return addScriptingNodeDependencyMutation({
          variables: {
            computeNodeId,
            dependencyId: isInteractivityContext
              ? {
                  published: {
                    computeNodeId: dependencyId,
                    publishedDataRoomId: dataRoomId,
                  },
                }
              : { draft: dependencyId },
          },
        });
      } catch (error) {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "The script input data could not be added."
          )
        );
        throw error;
      }
    },
    [
      enqueueSnackbar,
      computeNodeId,
      addScriptingNodeDependencyMutation,
      isInteractivityContext,
      dataRoomId,
    ]
  );
  const [removeScriptingNodeDependencyMutation, { loading: removing }] =
    useRemoveScriptingNodeDependencyMutation();
  const removeScriptingNodeDependency = useCallback(
    async (dependencyId: string) => {
      try {
        return removeScriptingNodeDependencyMutation({
          variables: {
            computeNodeId,
            dependencyId: isInteractivityContext
              ? {
                  published: {
                    computeNodeId: dependencyId,
                    publishedDataRoomId: dataRoomId,
                  },
                }
              : { draft: dependencyId },
          },
        });
      } catch (error) {
        enqueueSnackbar(
          ...mapDraftDataRoomErrorToSnackbar(
            error,
            "The script input data could not be removed."
          )
        );
        throw error;
      }
    },
    [
      enqueueSnackbar,
      dataRoomId,
      computeNodeId,
      removeScriptingNodeDependencyMutation,
      isInteractivityContext,
    ]
  );
  const updateDependency = useCallback(
    (dependencyId: string) => {
      if (scriptingNodeDependenciesIds?.includes(dependencyId)) {
        removeScriptingNodeDependency(dependencyId);
      } else {
        scriptingNodeDependenciesIds?.push(dependencyId);
        addScriptingNodeDependency(dependencyId);
      }
    },
    [
      removeScriptingNodeDependency,
      scriptingNodeDependenciesIds,
      addScriptingNodeDependency,
    ]
  );
  const loading = adding || removing;
  const disabled = readOnly || loading;
  return (
    <Stack direction="column" sx={{ p: 1 }}>
      <FormControl>
        <FormLabel sx={{ display: "flex" }}>
          Available data (read-only):
          <Tooltip title={availableDataTooltip}>
            <FontAwesomeIcon icon={faInfoCircle} />
          </Tooltip>
        </FormLabel>
        <Select
          data-testid={testIds.computeNode.computeNodeCreator.inputEditorLabel}
          multiple={true}
          onChange={(event, value) => {
            if (!disabled) {
              const effectiveValue =
                typeof value === "string" ? value.split(",") : value;
              const diff = xor(
                effectiveValue,
                scriptingNodeDependenciesIds || []
              );
              const focusedValue = diff[0];
              if (focusedValue) {
                updateDependency(diff[0]);
              }
            }
          }}
          placeholder="Select input files"
          renderValue={(selectedIdOptions: SelectOption<string>[]) => {
            const selectedNodes = scriptingNodeAvailableInputs.filter(
              ({ computeNodeId }) =>
                selectedIdOptions
                  .map(({ value }) => value)
                  .includes(computeNodeId)
            );
            return selectedNodes.length === 1
              ? selectedNodes[0]?.label
              : `${selectedNodes.length} input files`;
          }}
          slotProps={{
            listbox: {
              component: "div",
              sx: {
                "--ListItem-radius": "0px",
                maxHeight: 240,
                overflow: "auto",
              },
            },
          }}
          value={scriptingNodeDependenciesIds || []}
        >
          {Object.keys(groupedInputList).map((groupKey, index) => [
            <Fragment key={groupKey}>
              {index !== 0 && <ListDivider role="none" />}
              <List
                aria-labelledby={`select-group-${name}`}
                sx={{ "--ListItemDecorator-size": "28px" }}
              >
                <ListItem id={`select-group-${name}`} sticky={true}>
                  <Typography level="body-xs" textTransform="uppercase">
                    {
                      scriptingNodeGroupLabelsMap[
                        groupKey as ScriptingInputNodeSnapshot["typename"]
                      ]
                    }{" "}
                    ({groupedInputList[groupKey].length})
                  </Typography>
                </ListItem>
                {groupedInputList[groupKey].map(
                  ({ computeNodeId, name, allowedForCurrent }) => (
                    <Option
                      data-testid={`${testIds.computeNode.computeNodeCreator.selectHelper}${name}`}
                      disabled={!allowedForCurrent}
                      key={`${groupKey}-${computeNodeId}`}
                      value={computeNodeId}
                    >
                      <ListItemDecorator>
                        <Checkbox
                          checked={scriptingNodeDependenciesIds?.includes(
                            computeNodeId
                          )}
                          disabled={readOnly || !allowedForCurrent}
                        />
                      </ListItemDecorator>
                      {`${name} ${
                        allowedForCurrent
                          ? ""
                          : "(To run on this data, create a request)"
                      }`}
                    </Option>
                  )
                )}
              </List>
            </Fragment>,
          ])}
        </Select>
      </FormControl>
      {(SHOW_PYTHON_OUTPUT || SHOW_R_OUTPUT) && (
        <OutputFolderEditor computeNodeId={computeNodeId} />
      )}
    </Stack>
  );
});

InputsEditor.displayName = "InputsEditor";

export default InputsEditor;
