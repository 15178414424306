import { testIds } from "@decentriq/utils";
import { faTrashAlt } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconButton, Tooltip } from "@mui/joy";
import { useBoolean } from "ahooks";
import { memo, useCallback, useState } from "react";
import { usePublisherPortal } from "features/publisherPortal/contexts";
import {
  CommonSnackbarOrigin,
  mapErrorToGeneralSnackbar,
  useGeneralSnackbar,
} from "hooks";
import ActivationConfigurationDeleteDialog from "../ActivationConfigurationDeleteDialog/ActivationConfigurationDeleteDialog";

interface DeleteActivationConfigurationButtonProps {
  id: string;
  name: string;
}

const DeleteActivationConfigurationButton: React.FC<
  DeleteActivationConfigurationButtonProps
> = ({ id, name }) => {
  const [isDialogOpen, { setTrue: openDialog, setFalse: closeDialog }] =
    useBoolean(false);
  const [deleting, setDeleting] = useState(false);
  const { deleteActivationConfiguration } = usePublisherPortal();
  const { enqueueSnackbar } = useGeneralSnackbar({
    origin: CommonSnackbarOrigin.PUBLISHER_PORTAL,
  });
  const handleDelete = useCallback(async () => {
    try {
      setDeleting(true);
      await deleteActivationConfiguration(id);
    } catch (error) {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(error, "Cannot delete DCR configuration")
      );
    } finally {
      setDeleting(false);
    }
  }, [deleteActivationConfiguration, enqueueSnackbar, setDeleting, id]);
  return (
    <>
      <Tooltip title="Delete">
        <IconButton
          color="danger"
          data-testid={
            testIds.publisherPortal.activationConfigurations.recordDeleteButton
          }
          onClick={openDialog}
        >
          <FontAwesomeIcon fixedWidth={true} icon={faTrashAlt} />
        </IconButton>
      </Tooltip>
      <ActivationConfigurationDeleteDialog
        loading={deleting}
        name={name}
        onCancel={closeDialog}
        onConfirm={handleDelete}
        open={isDialogOpen}
      />
    </>
  );
};

DeleteActivationConfigurationButton.displayName =
  "DeleteActivationConfigurationButton";

export default memo(DeleteActivationConfigurationButton);
