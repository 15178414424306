import { useAuth0 } from "@auth0/auth0-react";
import {
  useDraftDataRoomDataNodesQuery,
  usePublishedDataRoomDataNodesQuery,
} from "@decentriq/graphql/dist/hooks";
import { type PublishedDataset } from "@decentriq/graphql/dist/types";
import { useMemo } from "react";
import { useDataRoom } from "contexts";
import { DataNodeConstructorMode } from "features/dataNodes/models";
import {
  mapDraftDataRoomErrorToSnackbar,
  mapErrorToGeneralSnackbar,
  useDataRoomSnackbar,
} from "hooks";
import {
  castPrimitiveTypeToFormatType,
  type DataRoomData,
  type DataRoomDataFile,
  type DataRoomDataTable,
  type DataRoomTableColumn,
} from "models";
import { type PublishedRawLeafNode, type PublishedTableLeafNode } from "types";

interface DataNodesHookPayload {
  dataRoomId: string;
  mode: DataNodeConstructorMode;
  permittedOnly?: boolean;
}

export const useDataNodes = ({
  dataRoomId,
  mode,
  permittedOnly,
}: DataNodesHookPayload) => {
  const { user } = useAuth0();
  const currentUserEmail = user!.email!;
  const { isPublished } = useDataRoom();
  const { enqueueSnackbar } = useDataRoomSnackbar();
  const {
    data: publishedData,
    loading: isPublishedLoading,
    error: publishedError,
  } = usePublishedDataRoomDataNodesQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapErrorToGeneralSnackbar(
          error,
          "Table or file could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: !isPublished,
    variables: { dataRoomId },
  });
  const {
    data: draftData,
    loading: isDraftLoading,
    error: draftError,
  } = useDraftDataRoomDataNodesQuery({
    onError: (error) => {
      enqueueSnackbar(
        ...mapDraftDataRoomErrorToSnackbar(
          error,
          "Table or file could not be retrieved. Please try again by refreshing the page."
        )
      );
    },
    skip: isPublished,
    variables: {
      dataRoomId,
    },
  });
  const dataNodes: DataRoomData[] = isPublished
    ? (publishedData?.publishedDataRoom?.publishedNodes || [])
        ?.filter(
          ({ __typename }) =>
            __typename === "PublishedRawLeafNode" ||
            __typename === "PublishedTableLeafNode"
        )
        ?.map((nodeData) => {
          let node: DataRoomData;
          switch (nodeData.__typename) {
            case "PublishedRawLeafNode":
              node = {
                dataType: "unstructured",
                dataset: nodeData.dataset,
                hasPermission: nodeData.permissions.some(
                  (p) => p.participant.userEmail === currentUserEmail
                ),
                id: nodeData.id,
                isRequired: nodeData.isRequired,
                name: nodeData.name,
                participants: nodeData.permissions.map((p) => ({
                  userEmail: p.participant.userEmail,
                })),
                testDataset: nodeData.testDataset,
              } as DataRoomDataFile;
              break;
            case "PublishedTableLeafNode":
              node = {
                columns: (nodeData?.columns || []).map((c) => ({
                  formatType: c.formatType,
                  hashWith: c.hashWith,
                  id: c.name,
                  name: c.name,
                  nullable: c.isNullable,
                  primitiveType: c.dataType,
                  tableNodeId: nodeData.id,
                })),
                columnsOrder: [],
                dataType: "table",
                dataset: nodeData.dataset,
                hasPermission: nodeData.permissions.some(
                  (p) => p.participant.userEmail === currentUserEmail
                ),
                id: nodeData.id,
                isRequired: nodeData.isRequired,
                name: nodeData.name,
                participants: nodeData.permissions.map((p) => ({
                  userEmail: p.participant.userEmail,
                })),
                testDataset: nodeData.testDataset,
                uniqueColumnIds: nodeData.uniqueColumnIds || [],
              } as DataRoomDataTable;
              break;
          }
          return node!;
        })
        .filter(
          (n) =>
            mode !== DataNodeConstructorMode.ACTION ||
            !permittedOnly ||
            n.hasPermission
        ) || []
    : (draftData?.draftDataRoom?.draftNodes?.nodes || [])
        ?.filter(
          ({ __typename }) =>
            __typename === "DraftRawLeafNode" ||
            __typename === "DraftTableLeafNode"
        )
        ?.map((nodeData) => {
          const participants =
            (draftData?.draftDataRoom.participants.nodes || [])
              .filter((node) =>
                node.permissions.nodes.some((n) => n.node.id === nodeData.id)
              )
              .map((node) => {
                const { userEmail } = node;
                return {
                  userEmail,
                };
              }) || [];
          let node: DataRoomData;
          switch (nodeData.__typename) {
            case "DraftRawLeafNode":
              node = {
                dataType: "unstructured",
                id: nodeData.id,
                isRequired: nodeData.isRequired,
                name: nodeData.name,
                participants,
                testDataset: nodeData.testModePublication
                  ? ({
                      datasetHash:
                        nodeData.testModePublication.dataset.manifestHash,
                      leafId: nodeData.id,
                      timestamp: nodeData.testModePublication.dataset.createdAt,
                      user: nodeData.testModePublication.dataset.owner.email,
                    } as PublishedDataset)
                  : undefined,
              } as DataRoomDataFile;
              break;
            case "DraftTableLeafNode": {
              const columns: DataRoomTableColumn[] = (
                nodeData?.columns?.nodes || []
              ).map((c) => ({
                formatType:
                  c.formatType || castPrimitiveTypeToFormatType(c.dataType),
                hashWith: c.hashWith || undefined,
                id: c.id,
                name: c.name,
                nullable: c.isNullable,
                primitiveType: c.dataType,
                tableNodeId: nodeData.id,
              }));
              const columnsOrder =
                nodeData?.columnsOrder || columns.map((c) => c.id);
              node = {
                columns,
                columnsOrder,
                dataType: "table",
                id: nodeData.id,
                isRequired: nodeData.isRequired,
                name: nodeData.name,
                participants,
                testDataset: nodeData.testModePublication
                  ? ({
                      datasetHash:
                        nodeData.testModePublication.dataset.manifestHash,
                      leafId: nodeData.id,
                      timestamp: nodeData.testModePublication.dataset.createdAt,
                      user: nodeData.testModePublication.dataset.owner.email,
                    } as PublishedDataset)
                  : undefined,
                uniqueColumnIds: nodeData.uniqueColumnIds || [],
              } as DataRoomDataTable;
              break;
            }
          }
          return node!;
        }) || [];
  const dataNodesOrder = useMemo(
    () =>
      isPublished
        ? (publishedData?.publishedDataRoom?.publishedNodes || [])
            ?.filter(
              (x) =>
                x.__typename === "PublishedRawLeafNode" ||
                x.__typename === "PublishedTableLeafNode"
            )
            .map(
              (x) => (x as PublishedRawLeafNode | PublishedTableLeafNode).id
            ) || []
        : (draftData?.draftDataRoom?.dataNodesOrder || [])?.filter(
            (x): x is string => x !== null
          ) || [],
    [publishedData, draftData, isPublished]
  );

  return {
    dataNodes,
    dataNodesOrder,
    error: draftError || publishedError,
    hasData: !!dataNodes && dataNodes.length > 0,
    loading: isDraftLoading || isPublishedLoading,
  };
};
