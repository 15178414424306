import { useCopyToClipboard } from "hooks";
import { dataRoomPathBuilder, type DataRoomTypeNames } from "models";

interface usePublishedDataRoomCopyLinkArgs {
  id: string;
  __typename: DataRoomTypeNames;
}

interface usePublishedDataRoomCopyLinkResult {
  publishedDataRoomCopyLink: () => Promise<boolean>;
}

const usePublishedDataRoomCopyLink = ({
  id: dataRoomId,
  __typename,
}: usePublishedDataRoomCopyLinkArgs): usePublishedDataRoomCopyLinkResult => {
  const [, copyToClipboard] = useCopyToClipboard();
  const publishedDataRoomCopyLink = () =>
    copyToClipboard(
      `${window.location.origin}${dataRoomPathBuilder(dataRoomId, __typename)}`
    );
  return { publishedDataRoomCopyLink };
};

export default usePublishedDataRoomCopyLink;
