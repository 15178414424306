import { data_connector_job } from "@decentriq/core";
import { useDataConnectorJobQuery } from "@decentriq/graphql/dist/hooks";
import { useSnackbar } from "notistack";
import { useCallback, useEffect, useState } from "react";
import { useApiCore } from "contexts";

type MicrosoftUploadJobData = {
  job_id: string;
  id: number;
  last_modified: string;
  member_id: number;
  upload_url: string;
};

type ConnectionTooltipData = {
  memberId: string | undefined;
  microsoftDspMember: string | null | undefined;
  segmentCode: string | undefined;
  segmentShortName: string | undefined;
  microsoftUploadJob: MicrosoftUploadJobData | null;
  isMicrosoftUploadJobLoading: boolean;
};

const useMicrosoftConnectionConfigurationData = (
  connectionId: string
): ConnectionTooltipData => {
  const [microsoftUploadJob, setMicrosoftUploadJob] =
    useState<MicrosoftUploadJobData | null>(null);
  const [isMicrosoftUploadJobLoading, setMicrosoftUploadJobLoading] =
    useState<boolean>(false);

  const { data: { dataConnectorJob } = {} } = useDataConnectorJobQuery({
    skip: !connectionId,
    variables: { id: connectionId },
  });

  const { client, isMigrated } = useApiCore();
  const { enqueueSnackbar } = useSnackbar();

  const { memberId, microsoftDspMember, segmentCode, segmentShortName } =
    dataConnectorJob?.config?.targetConfig || {};

  const getMicrosoftConnectorJobResult = useCallback(async () => {
    if (!dataConnectorJob?.id) return;
    const enclaveSpecifications =
      await client.getEnclaveSpecifications(isMigrated);
    setMicrosoftUploadJobLoading(true);
    try {
      const result = await data_connector_job.getDataConnectorJobResult({
        client,
        dataConnectorJobId: dataConnectorJob?.id,
        options: {
          additionalEnclaveSpecs: enclaveSpecifications,
        },
      });
      const decodedResult = new TextDecoder().decode(result as Uint8Array);
      const microsoftConnectorUploadJob =
        JSON.parse(decodedResult)?.bssUploadJob;
      setMicrosoftUploadJob(microsoftConnectorUploadJob);
    } catch (e) {
      enqueueSnackbar("Cannot fetch connector result");
    } finally {
      setMicrosoftUploadJobLoading(false);
    }
  }, [client, dataConnectorJob?.id, enqueueSnackbar, isMigrated]);

  useEffect(() => {
    getMicrosoftConnectorJobResult();
  }, [getMicrosoftConnectorJobResult]);

  return {
    isMicrosoftUploadJobLoading,
    memberId,
    microsoftDspMember,
    microsoftUploadJob,
    segmentCode,
    segmentShortName,
  };
};

export default useMicrosoftConnectionConfigurationData;
