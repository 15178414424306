import {
  Box,
  styled,
  Tab,
  TabList,
  TabPanel,
  Tabs,
  type TabsProps,
} from "@mui/joy";
import { memo, useCallback, useState } from "react";
import { useDatasetPermission } from "features/datasets/contexts";
import { useDatasetWithSchema } from "features/datasets/hooks";
import {
  DatasetInfoTabPanel,
  DatasetPermissionsTabPanel,
  DatasetSchemaTabPanel,
} from "./components";

type DatasetTab = "info" | "permissions" | "schema";

interface DatasetTabsProps {
  manifestHash: string;
  skipFetching: boolean;
}

const DatasetTabPanel = styled(TabPanel)({
  "&:not([hidden])": {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    overflow: "auto",
  },
  paddingLeft: 0,
  paddingRight: 0,
});

const DatasetTabs = memo<DatasetTabsProps>(({ manifestHash, skipFetching }) => {
  const { dataset, schema, loading } = useDatasetWithSchema({
    manifestHash,
    skip: skipFetching,
  });
  const [selectedTab, setSelectedTab] = useState<DatasetTab>("info");
  const { hasMigratedKeychain } = useDatasetPermission();
  const handleChange = useCallback<Exclude<TabsProps["onChange"], undefined>>(
    (_, value) => setSelectedTab(value as DatasetTab),
    [setSelectedTab]
  );
  const hasSchema = Boolean(schema);
  return (
    <Tabs onChange={handleChange} value={selectedTab}>
      <Box>
        <TabList disableUnderline={true}>
          <Tab value="info">Details</Tab>
          {hasMigratedKeychain && <Tab value="permissions">Permissions</Tab>}
          {hasSchema && <Tab value="schema">Schema</Tab>}
        </TabList>
        <Box display="flex" flex={1} flexDirection="column" overflow="auto">
          <DatasetTabPanel keepMounted={false} value="info">
            <DatasetInfoTabPanel
              dataset={dataset}
              loading={loading}
              manifestHash={manifestHash}
              schema={schema}
            />
          </DatasetTabPanel>
          {hasMigratedKeychain && (
            <DatasetTabPanel keepMounted={false} value="permissions">
              <DatasetPermissionsTabPanel />
            </DatasetTabPanel>
          )}
          {hasSchema && (
            <DatasetTabPanel keepMounted={false} value="schema">
              <DatasetSchemaTabPanel loading={loading} schema={schema!} />
            </DatasetTabPanel>
          )}
        </Box>
      </Box>
    </Tabs>
  );
});

DatasetTabs.displayName = "DatasetTabs";

export default DatasetTabs;
