import { useAuth0 } from "@auth0/auth0-react";
import { usePublishedDataRoomActionsQuery } from "@decentriq/graphql/dist/hooks";
import {
  faBan,
  faClone,
  faCopy,
  faEye,
  faEyeSlash,
  faFileDownload,
  faLink,
  faStar,
} from "@fortawesome/pro-light-svg-icons";
import { faStar as fasStar } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Box, IconButton } from "@mui/joy";
import { Portal, Skeleton } from "@mui/material";
import { useBoolean } from "ahooks";
import { format, isValid, parseISO } from "date-fns";
import { saveAs } from "file-saver";
import { useSnackbar } from "notistack";
import { memo, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Actions, DataRoomStopDialog } from "components";
import { DataRoomActionTypes } from "features/dataRoom";
import {
  mapErrorToGeneralSnackbar,
  useCopyToClipboard,
  useDataRoomSnackbar,
} from "hooks";
import { DataRoomTypeNames } from "models";
import {
  usePublishedDataRoomAsJson,
  usePublishedDataRoomCopyLink,
  usePublishedDataRoomDuplicate,
  usePublishedDataRoomIsFavorite,
  usePublishedDataRoomIsHidden,
  usePublishedDataRoomStop,
} from "./hooks";

interface PublishedDataRoomActionsProps {
  id: string;
  [key: string]: any;
}

const PublishedDataRoomActions: React.FC<PublishedDataRoomActionsProps> = memo(
  ({
    id: dataRoomId,
    actions: actionsFunc = (actions: any) => actions,
    inline = false,
    moreIcon,
  }) => {
    const { user } = useAuth0();
    const currentUserEmail = user?.email;
    const { data, loading: isDataRoomLoading } =
      usePublishedDataRoomActionsQuery({
        variables: { id: dataRoomId },
      });
    const { publishedDataRoom } = data || {};
    const {
      name,
      owner,
      createdAt,
      updatedAt,
      isStopped,
      id,
      driverAttestationHash,
    } = publishedDataRoom || {};
    const { email: createdByEmail, email: updatedByEmail } = owner || {};
    const isOwner = createdByEmail === currentUserEmail;
    const createdAtDate = parseISO(createdAt);
    const updatedAtDate = parseISO(updatedAt);
    const { enqueueSnackbar } = useSnackbar();
    const { enqueueSnackbar: enqueueDataRoomSnackbar } = useDataRoomSnackbar();
    const navigate = useNavigate();
    // Copy link to data clean room
    const { publishedDataRoomCopyLink } = usePublishedDataRoomCopyLink({
      __typename: DataRoomTypeNames.PublishedDataRoom,
      id: dataRoomId,
    });
    const dataRoomCopyLink = () =>
      publishedDataRoomCopyLink()
        .then((wasCopied) => {
          if (wasCopied) {
            enqueueSnackbar(
              <Box sx={{ alignItems: "center", display: "flex" }}>
                <FontAwesomeIcon
                  fixedWidth={true}
                  icon={faCopy}
                  style={{ marginRight: "0.5rem" }}
                />
                Copied to clipboard
              </Box>
            );
          }
        })
        .catch((error) => {
          enqueueDataRoomSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              "Data clean room link could not be generated."
            )
          );
        });
    // Favorite/unfavorite data clean room
    const {
      publishedDataRoomIsFavorite: isFavorite,
      publishedDataRoomIsFavoriteLoading: isDataRoomIsFavoriteLoading,
      setPublishedDataRoomIsFavorite: setIsFavorite,
      setPublishedDataRoomIsFavoriteLoading: isSetDataRoomIsFavoriteLoading,
    } = usePublishedDataRoomIsFavorite({
      __typename: DataRoomTypeNames.PublishedDataRoom,
      id: dataRoomId,
      skip: isDataRoomLoading,
    });
    const toggleDataRoomIsFavorite = () =>
      setIsFavorite(!isFavorite)
        .then(() => {
          enqueueDataRoomSnackbar(
            `Data clean room was ${
              !isFavorite ? "added to" : "removed from"
            } Favorites`
          );
        })
        .catch((error) => {
          enqueueDataRoomSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              `Data clean room could not be ${
                !isFavorite ? "added to" : "removed from"
              } Favorites`
            )
          );
        });
    // Archive/restore (hide/unhide) data clean room
    const {
      isHidden,
      loading: isDataRoomIsHiddenLoading,
      setIsHidden,
      setting: isSetDataRoomIsHiddenLoading,
    } = usePublishedDataRoomIsHidden({
      __typename: DataRoomTypeNames.PublishedDataRoom,
      id: dataRoomId,
      skip: isDataRoomLoading,
    });

    const toggleDataRoomIsHidden = () =>
      setIsHidden(!isHidden)
        .then(() => {
          if (!isHidden) {
            navigate("/datarooms");
          }
          enqueueDataRoomSnackbar(
            `Data clean room was ${!isHidden ? "hidden" : "unhidden"}`
          );
        })
        .catch((error) => {
          enqueueDataRoomSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              `Data clean room could not be ${
                !isHidden ? "hidden" : "unhidden"
              }.`
            )
          );
        });
    // Export data clean room
    const {
      publishedDataRoomAsJson,
      publishedDataRoomAsJsonLoading: isDataRoomAsJsonLoading,
    } = usePublishedDataRoomAsJson({ driverAttestationHash, id: dataRoomId });
    const dataRoomExportToJson = () =>
      publishedDataRoomAsJson()
        .then((json) => {
          if (json) {
            const fileBits: BlobPart[] = [json];
            const fileName = `${name}.json`;
            const options = { type: "application/octet-stream;charset=utf-8" };
            const file = new File(fileBits, fileName, options);
            saveAs(file);
            enqueueDataRoomSnackbar("Data clean room was exported");
          } else {
            throw new TypeError("'json' is undefined.");
          }
        })
        .catch((error) => {
          enqueueDataRoomSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              `Data clean room definition could not be exported.`
            )
          );
        });
    // Duplicate data clean room
    const {
      publishedDataRoomDuplicate,
      publishedDataRoomDuplicateLoading: isDataRoomDuplicateLoading,
    } = usePublishedDataRoomDuplicate({
      driverAttestationHash,
      id: dataRoomId,
    });
    const dataRoomDuplicate = () =>
      publishedDataRoomDuplicate()
        .then((dataRoomId) => {
          if (dataRoomId) {
            enqueueDataRoomSnackbar("Data clean room was duplicated");
            navigate(`/datarooms/d/${dataRoomId}`);
          } else {
            throw new TypeError("'dataRoomId' is undefined.");
          }
        })
        .catch((error) => {
          enqueueDataRoomSnackbar(
            ...mapErrorToGeneralSnackbar(
              error,
              `Data clean room could not be duplicated.`
            )
          );
        });
    // Stop data clean room
    const [
      isDataRoomStopDialogOpen,
      { setTrue: openDataRoomStopDialog, setFalse: closeDataRoomStopDialog },
    ] = useBoolean(false);
    const { isDataRoomStopLoading, stopDataRoomMutation } =
      usePublishedDataRoomStop({
        __typename: DataRoomTypeNames.PublishedDataRoom,
        dataRoomId,
      });
    const dataRoomStop = useCallback(() => {
      stopDataRoomMutation()
        .then(() => {
          enqueueDataRoomSnackbar("Data clean room was stopped");
        })
        .catch((error) => {
          enqueueDataRoomSnackbar(
            ...mapErrorToGeneralSnackbar(error, "Can't stop data clean room")
          );
        })
        .finally(() => closeDataRoomStopDialog());
    }, [
      stopDataRoomMutation,
      enqueueDataRoomSnackbar,
      closeDataRoomStopDialog,
    ]);
    // Copy mrenclave
    const [, hashCopyToClipboard] = useCopyToClipboard();
    const copyHashToClipboard = useCallback(
      (hash: string, message: string) => {
        if (hash) {
          hashCopyToClipboard(hash);
          enqueueSnackbar(
            <Box sx={{ alignItems: "center", display: "flex" }}>
              <FontAwesomeIcon
                fixedWidth={true}
                icon={faCopy}
                style={{ marginRight: "0.5rem" }}
              />
              {message}
            </Box>
          );
        }
      },
      [hashCopyToClipboard, enqueueSnackbar]
    );
    // Actions
    const actions = actionsFunc({
      buttons: [
        {
          component: IconButton,
          disabled: isDataRoomLoading || isDataRoomIsFavoriteLoading,
          hidden: false,
          icon: isFavorite ? fasStar : faStar,
          loading:
            isDataRoomIsFavoriteLoading || isSetDataRoomIsFavoriteLoading,
          onClick: toggleDataRoomIsFavorite,
          square: true,
          tooltipPlacement: "bottom-end",
          tooltipTitle: isFavorite
            ? "Remove from Favorites"
            : "Add to Favorites",
          type: DataRoomActionTypes.ToggleFavorite,
        },
        {
          component: IconButton,
          disabled: isDataRoomLoading || isDataRoomIsHiddenLoading,
          hidden: !isHidden,
          hover: {
            icon: faEye,
            isRed: false,
            onClick: toggleDataRoomIsHidden,
            tooltipTitle: "Unhide",
          },
          icon: faEyeSlash,
          isRed: false,
          loading: isDataRoomIsHiddenLoading || isSetDataRoomIsHiddenLoading,
          onClick: toggleDataRoomIsHidden,
          square: true,
          tooltipPlacement: "bottom-end",
          tooltipTitle: "Hidden",
          type: DataRoomActionTypes.RestoreOrDelete,
        },
      ],
      menuLists: [
        [
          {
            disabled: isDataRoomLoading || isDataRoomIsFavoriteLoading,
            hidden: false,
            icon: isFavorite ? fasStar : faStar,
            loading:
              isDataRoomIsFavoriteLoading || isSetDataRoomIsFavoriteLoading,
            name: isFavorite ? "Remove from favorites" : "Add to favorites",
            onClick: toggleDataRoomIsFavorite,
            type: DataRoomActionTypes.ToggleFavorite,
          },
          {
            disabled: false,
            icon: faLink,
            name: "Copy link",
            onClick: dataRoomCopyLink,
            type: DataRoomActionTypes.CopyLink,
          },
          {
            disabled: isDataRoomLoading,
            icon: faClone,
            loading: isDataRoomDuplicateLoading,
            name: "Duplicate",
            onClick: dataRoomDuplicate,
            type: DataRoomActionTypes.Duplicate,
          },
          {
            disabled: isDataRoomLoading || isDataRoomIsHiddenLoading,
            icon: isHidden ? faEye : faEyeSlash,
            isRed: false,
            loading: isDataRoomIsHiddenLoading || isSetDataRoomIsHiddenLoading,
            name: isHidden ? "Unhide" : "Hide",
            onClick: toggleDataRoomIsHidden,
            type: DataRoomActionTypes.HideRestoreOrDelete,
          },
          {
            disabled: false,
            hidden: isStopped || !isOwner,
            icon: faBan,
            isRed: true,
            loading: isDataRoomStopLoading,
            name: "Stop data clean room",
            onClick: openDataRoomStopDialog,
            tooltipTitle: "Stop",
            type: DataRoomActionTypes.Stop,
          },
        ],
        [
          {
            disabled: isDataRoomLoading,
            icon: faFileDownload,
            loading: isDataRoomAsJsonLoading,
            name: "Export to JSON",
            onClick: dataRoomExportToJson,
            type: DataRoomActionTypes.Export,
          },
        ],
        [
          {
            name: (
              <div
                style={{
                  lineHeight: "1.1875rem",
                  opacity: 0.75,
                  overflow: "auto",
                  whiteSpace: "pre",
                }}
              >
                {isValid(createdAtDate) ? (
                  <div>
                    <div>
                      <strong>Created by</strong>
                    </div>
                    <div>{createdByEmail}</div>
                    <div>on {format(createdAtDate, "dd-MM-yyyy HH:mm:ss")}</div>
                  </div>
                ) : null}
                {isValid(updatedAtDate) ? (
                  <div>
                    <div style={{ marginTop: 4 }}>
                      <strong>Published by</strong>
                    </div>
                    <div>{updatedByEmail}</div>
                    <div>on {format(updatedAtDate, "dd-MM-yyyy HH:mm:ss")}</div>
                  </div>
                ) : null}
                {id ? (
                  <>
                    <div style={{ marginTop: 4 }}>
                      <strong>Data clean room ID</strong>
                    </div>
                    <div
                      style={{
                        alignItems: "center",
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          maxWidth: 100,
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                        }}
                        title={id}
                      >
                        {id}
                      </div>
                      <div
                        onClick={() =>
                          copyHashToClipboard(
                            id,
                            "Data clean room ID copied to clipboard"
                          )
                        }
                        style={{
                          alignItems: "center",
                          cursor: "pointer",
                          display: "flex",
                        }}
                      >
                        <FontAwesomeIcon
                          fixedWidth={true}
                          icon={faCopy}
                          style={{
                            marginRight: "0.25rem",
                          }}
                        />{" "}
                        Copy
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
            ),
            type: DataRoomActionTypes.Details,
          },
        ],
      ],
    });
    const actionsTypes = [actions.buttons || [], actions.menuLists || []]
      .flat(Infinity)
      .map(({ type }) => type);
    return isDataRoomLoading ? (
      <Skeleton
        height={inline ? 24 : 32}
        variant="rectangular"
        width={inline ? 24 : 32}
      />
    ) : (
      <Actions
        actions={actions}
        inline={inline}
        moreIcon={moreIcon}
        moreTooltipTitle=""
      >
        <Portal>
          {actionsTypes.includes(DataRoomActionTypes.Stop) ? (
            <DataRoomStopDialog
              loading={isDataRoomStopLoading}
              name={name}
              onCancel={closeDataRoomStopDialog}
              onConfirm={dataRoomStop}
              open={isDataRoomStopDialogOpen}
            />
          ) : null}
        </Portal>
      </Actions>
    );
  }
);

export default PublishedDataRoomActions;
