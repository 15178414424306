import { type TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";

export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = {
  [K in keyof T]: T[K];
};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]?: Maybe<T[SubKey]>;
};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {
  [SubKey in K]: Maybe<T[SubKey]>;
};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A floating point number that requires more precision than IEEE 754 binary 64 */
  BigFloat: any;
  /**
   * A signed eight-byte integer. The upper big integer values are greater than the
   * max value for a JavaScript number. Therefore all big integers will be output as
   * strings and not numbers.
   */
  BigInt: any;
  /** A location in a connection that can be used for resuming pagination. */
  Cursor: any;
  /**
   * A point in time as described by the [ISO
   * 8601](https://en.wikipedia.org/wiki/ISO_8601) standard. May or may not include a timezone.
   */
  Datetime: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** A universally unique identifier as defined by [RFC 4122](https://tools.ietf.org/html/rfc4122). */
  UUID: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};

export type AttestationSpecificationAsElement = Node & {
  __typename?: "AttestationSpecificationAsElement";
  attestationSpecificationId: Scalars["UUID"];
  config: Scalars["JSON"];
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  /** Reads a single `Modification` that is related to this `AttestationSpecificationAsElement`. */
  modification?: Maybe<Modification>;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type AttestationSpecificationAsElementAggregates = {
  __typename?: "AttestationSpecificationAsElementAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AttestationSpecificationAsElementDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** The fields on `attestationSpecificationAsElement` to look up the row to connect. */
export type AttestationSpecificationAsElementAttestationSpecificationAsElementPkeyConnect =
  {
    attestationSpecificationId: Scalars["UUID"];
  };

/** The fields on `attestationSpecificationAsElement` to look up the row to delete. */
export type AttestationSpecificationAsElementAttestationSpecificationAsElementPkeyDelete =
  {
    attestationSpecificationId: Scalars["UUID"];
  };

/**
 * A condition to be used against `AttestationSpecificationAsElement` object types.
 * All fields are tested for equality and combined with a logical ‘and.’
 */
export type AttestationSpecificationAsElementCondition = {
  /** Checks for equality with the object’s `attestationSpecificationId` field. */
  attestationSpecificationId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type AttestationSpecificationAsElementDistinct = {
  __typename?: "AttestationSpecificationAsElementDistinct";
  /** Distinct `attestationSpecificationId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  attestationSpecificationId: Array<Scalars["UUID"]>;
  /** Distinct `config` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  config: Array<Scalars["JSON"]>;
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type AttestationSpecificationAsElementDistinctCountAggregates = {
  __typename?: "AttestationSpecificationAsElementDistinctCountAggregates";
  /** Distinct count of attestationSpecificationId across the matching connection */
  attestationSpecificationId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `AttestationSpecificationAsElement` object types. All fields are combined with a logical ‘and.’ */
export type AttestationSpecificationAsElementFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AttestationSpecificationAsElementFilter>>;
  /** Filter by the object’s `attestationSpecificationId` field. */
  attestationSpecificationId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `modification` relation. */
  modification?: InputMaybe<ModificationFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AttestationSpecificationAsElementFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AttestationSpecificationAsElementFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type AttestationSpecificationAsElementGroup = {
  __typename?: "AttestationSpecificationAsElementGroup";
  /**
   * Reads and enables pagination through a set of `AttestationSpecificationAsElement` of this `AttestationSpecificationAsElementGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `AttestationSpecificationAsElementGroup`. Overriding these may result in the wrong set of `AttestationSpecificationAsElement` returned.
   */
  attestationSpecificationAsElements?: Maybe<AttestationSpecificationAsElementsConnection>;
  /** A list of _all_ `AttestationSpecificationAsElement` primary keys that belong to the `AttestationSpecificationAsElementGroup`. */
  attestationSpecificationIds?: Maybe<Array<Scalars["UUID"]>>;
  /** `AttestationSpecificationAsElementFilter` that was used to implicitly filter `attestationSpecificationAsElements`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** The count of _all_ `AttestationSpecificationAsElement` you could get from the `AttestationSpecificationAsElementGroup`. */
  totalCount: Scalars["Int"];
};

export type AttestationSpecificationAsElementGroupAttestationSpecificationAsElementsArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]>;
    before?: InputMaybe<Scalars["Cursor"]>;
    condition?: InputMaybe<AttestationSpecificationAsElementCondition>;
    filter?: InputMaybe<AttestationSpecificationAsElementFilter>;
    first?: InputMaybe<Scalars["Int"]>;
    last?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    orderBy?: InputMaybe<Array<AttestationSpecificationAsElementsOrderBy>>;
  };

/** Grouping methods for `AttestationSpecificationAsElement` for usage during aggregation. */
export enum AttestationSpecificationAsElementGroupBy {
  Config = "CONFIG",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  ModificationByAttestationSpecificationIdConfig = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_CONFIG",
  ModificationByAttestationSpecificationIdConfigurationId = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_CONFIGURATION_ID",
  ModificationByAttestationSpecificationIdCreatedAt = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_CREATED_AT",
  ModificationByAttestationSpecificationIdCreatedByEmail = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_CREATED_BY_EMAIL",
  ModificationByAttestationSpecificationIdElementId = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_ELEMENT_ID",
  ModificationByAttestationSpecificationIdElementType = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_ELEMENT_TYPE",
  ModificationByAttestationSpecificationIdModification = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_MODIFICATION",
  ModificationByAttestationSpecificationIdModificationId = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_MODIFICATION_ID",
  ModificationByAttestationSpecificationIdUpdatedAt = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_UPDATED_AT",
  ModificationByAttestationSpecificationIdUpdatedByEmail = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_UPDATED_BY_EMAIL",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type AttestationSpecificationAsElementHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AttestationSpecificationAsElementHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `AttestationSpecificationAsElement` aggregates. */
export type AttestationSpecificationAsElementHavingInput = {
  AND?: InputMaybe<Array<AttestationSpecificationAsElementHavingInput>>;
  OR?: InputMaybe<Array<AttestationSpecificationAsElementHavingInput>>;
  average?: InputMaybe<AttestationSpecificationAsElementHavingAverageInput>;
  distinctCount?: InputMaybe<AttestationSpecificationAsElementHavingDistinctCountInput>;
  max?: InputMaybe<AttestationSpecificationAsElementHavingMaxInput>;
  min?: InputMaybe<AttestationSpecificationAsElementHavingMinInput>;
  stddevPopulation?: InputMaybe<AttestationSpecificationAsElementHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<AttestationSpecificationAsElementHavingStddevSampleInput>;
  sum?: InputMaybe<AttestationSpecificationAsElementHavingSumInput>;
  variancePopulation?: InputMaybe<AttestationSpecificationAsElementHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<AttestationSpecificationAsElementHavingVarianceSampleInput>;
};

export type AttestationSpecificationAsElementHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AttestationSpecificationAsElementHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AttestationSpecificationAsElementHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AttestationSpecificationAsElementHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AttestationSpecificationAsElementHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AttestationSpecificationAsElementHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AttestationSpecificationAsElementHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** The `attestationSpecificationAsElement` to be created by this mutation. */
export type AttestationSpecificationAsElementIdFkeyAttestationSpecificationAsElementCreateInput =
  {
    attestationSpecificationId?: InputMaybe<Scalars["UUID"]>;
    config: Scalars["JSON"];
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    modification?: InputMaybe<AttestationSpecificationAsElementIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** Input for the nested mutation of `modification` in the `AttestationSpecificationAsElementInput` mutation. */
export type AttestationSpecificationAsElementIdFkeyInput = {
  /** The primary key(s) for `modification` for the far side of the relationship. */
  connectByElementId?: InputMaybe<ModificationModificationElementIdKeyConnect>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  connectById?: InputMaybe<ModificationNodeIdConnect>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  connectByModificationId?: InputMaybe<ModificationModificationPkeyConnect>;
  /** A `ModificationInput` object that will be created and connected to this object. */
  create?: InputMaybe<AttestationSpecificationAsElementIdFkeyModificationCreateInput>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  deleteByElementId?: InputMaybe<ModificationModificationElementIdKeyDelete>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  deleteById?: InputMaybe<ModificationNodeIdDelete>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  deleteByModificationId?: InputMaybe<ModificationModificationPkeyDelete>;
  /** The primary key(s) and patch data for `modification` for the far side of the relationship. */
  updateByElementId?: InputMaybe<ModificationOnAttestationSpecificationAsElementForAttestationSpecificationAsElementIdFkeyUsingModificationElementIdKeyUpdate>;
  /** The primary key(s) and patch data for `modification` for the far side of the relationship. */
  updateById?: InputMaybe<AttestationSpecificationAsElementOnAttestationSpecificationAsElementForAttestationSpecificationAsElementIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `modification` for the far side of the relationship. */
  updateByModificationId?: InputMaybe<ModificationOnAttestationSpecificationAsElementForAttestationSpecificationAsElementIdFkeyUsingModificationPkeyUpdate>;
};

/** Input for the nested mutation of `attestationSpecificationAsElement` in the `ModificationInput` mutation. */
export type AttestationSpecificationAsElementIdFkeyInverseInput = {
  /** The primary key(s) for `attestationSpecificationAsElement` for the far side of the relationship. */
  connectByAttestationSpecificationId?: InputMaybe<AttestationSpecificationAsElementAttestationSpecificationAsElementPkeyConnect>;
  /** The primary key(s) for `attestationSpecificationAsElement` for the far side of the relationship. */
  connectById?: InputMaybe<AttestationSpecificationAsElementNodeIdConnect>;
  /** A `AttestationSpecificationAsElementInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AttestationSpecificationAsElementIdFkeyAttestationSpecificationAsElementCreateInput>
  >;
  /** The primary key(s) for `attestationSpecificationAsElement` for the far side of the relationship. */
  deleteByAttestationSpecificationId?: InputMaybe<AttestationSpecificationAsElementAttestationSpecificationAsElementPkeyDelete>;
  /** The primary key(s) for `attestationSpecificationAsElement` for the far side of the relationship. */
  deleteById?: InputMaybe<AttestationSpecificationAsElementNodeIdDelete>;
  /** Flag indicating whether all other `attestationSpecificationAsElement` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `attestationSpecificationAsElement` for the far side of the relationship. */
  updateByAttestationSpecificationId?: InputMaybe<AttestationSpecificationAsElementOnAttestationSpecificationAsElementForAttestationSpecificationAsElementIdFkeyUsingAttestationSpecificationAsElementPkeyUpdate>;
  /** The primary key(s) and patch data for `attestationSpecificationAsElement` for the far side of the relationship. */
  updateById?: InputMaybe<ModificationOnAttestationSpecificationAsElementForAttestationSpecificationAsElementIdFkeyNodeIdUpdate>;
};

/** The `modification` to be created by this mutation. */
export type AttestationSpecificationAsElementIdFkeyModificationCreateInput = {
  attestationSpecificationAsElement?: InputMaybe<AttestationSpecificationAsElementIdFkeyInverseInput>;
  authenticationMethodAsElement?: InputMaybe<AuthenticationMethodAsElementIdFkeyInverseInput>;
  computeNodeAsElement?: InputMaybe<ComputeNodeAsElementIdFkeyInverseInput>;
  config?: InputMaybe<Scalars["JSON"]>;
  configuration?: InputMaybe<ModificationConfigurationIdFkeyInput>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  elementId: Scalars["UUID"];
  elementType: ElementTypeType;
  modification: ModificationType;
  modificationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userPermissionAsElement?: InputMaybe<UserPermissionAsElementIdFkeyInverseInput>;
};

/** An input for mutations affecting `AttestationSpecificationAsElement` */
export type AttestationSpecificationAsElementInput = {
  attestationSpecificationId?: InputMaybe<Scalars["UUID"]>;
  config: Scalars["JSON"];
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  modification?: InputMaybe<AttestationSpecificationAsElementIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AttestationSpecificationAsElementNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `attestationSpecificationAsElement` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type AttestationSpecificationAsElementNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `attestationSpecificationAsElement` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type AttestationSpecificationAsElementOnAttestationSpecificationAsElementForAttestationSpecificationAsElementIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `modification` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `modification` being updated. */
    patch: ModificationPatch;
  };

/** The fields on `attestationSpecificationAsElement` to look up the row to update. */
export type AttestationSpecificationAsElementOnAttestationSpecificationAsElementForAttestationSpecificationAsElementIdFkeyUsingAttestationSpecificationAsElementPkeyUpdate =
  {
    attestationSpecificationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `attestationSpecificationAsElement` being updated. */
    patch: UpdateAttestationSpecificationAsElementOnAttestationSpecificationAsElementForAttestationSpecificationAsElementIdFkeyPatch;
  };

/** Represents an update to a `AttestationSpecificationAsElement`. Fields that are set will be updated. */
export type AttestationSpecificationAsElementPatch = {
  attestationSpecificationId?: InputMaybe<Scalars["UUID"]>;
  config?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  modification?: InputMaybe<AttestationSpecificationAsElementIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** A connection to a list of `AttestationSpecificationAsElement` values. */
export type AttestationSpecificationAsElementsConnection = {
  __typename?: "AttestationSpecificationAsElementsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AttestationSpecificationAsElementAggregates>;
  /** A list of _all_ `AttestationSpecificationAsElement` primary keys. */
  attestationSpecificationIds?: Maybe<Array<Scalars["UUID"]>>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<AttestationSpecificationAsElementDistinct>;
  /** A list of edges which contains the `AttestationSpecificationAsElement` and cursor to aid in pagination. */
  edges: Array<AttestationSpecificationAsElementsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AttestationSpecificationAsElementAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<AttestationSpecificationAsElementGroup>>;
  /** A list of `AttestationSpecificationAsElement` objects. */
  nodes: Array<Maybe<AttestationSpecificationAsElement>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AttestationSpecificationAsElement` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `AttestationSpecificationAsElement` values. */
export type AttestationSpecificationAsElementsConnectionGroupedAggregatesArgs =
  {
    groupBy: Array<AttestationSpecificationAsElementGroupBy>;
    having?: InputMaybe<AttestationSpecificationAsElementHavingInput>;
  };

/** A connection to a list of `AttestationSpecificationAsElement` values. */
export type AttestationSpecificationAsElementsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<AttestationSpecificationAsElementGroupBy>;
  having?: InputMaybe<AttestationSpecificationAsElementHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `AttestationSpecificationAsElement` edge in the connection. */
export type AttestationSpecificationAsElementsEdge = {
  __typename?: "AttestationSpecificationAsElementsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `AttestationSpecificationAsElement` at the end of the edge. */
  node?: Maybe<AttestationSpecificationAsElement>;
};

/** Methods to use when ordering `AttestationSpecificationAsElement`. */
export enum AttestationSpecificationAsElementsOrderBy {
  AttestationSpecificationIdAsc = "ATTESTATION_SPECIFICATION_ID_ASC",
  AttestationSpecificationIdDesc = "ATTESTATION_SPECIFICATION_ID_DESC",
  ConfigAsc = "CONFIG_ASC",
  ConfigDesc = "CONFIG_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  ModificationByAttestationSpecificationIdConfigurationIdAsc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_CONFIGURATION_ID_ASC",
  ModificationByAttestationSpecificationIdConfigurationIdDesc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_CONFIGURATION_ID_DESC",
  ModificationByAttestationSpecificationIdConfigAsc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_CONFIG_ASC",
  ModificationByAttestationSpecificationIdConfigDesc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_CONFIG_DESC",
  ModificationByAttestationSpecificationIdCreatedAtAsc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_CREATED_AT_ASC",
  ModificationByAttestationSpecificationIdCreatedAtDesc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_CREATED_AT_DESC",
  ModificationByAttestationSpecificationIdCreatedByEmailAsc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_CREATED_BY_EMAIL_ASC",
  ModificationByAttestationSpecificationIdCreatedByEmailDesc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_CREATED_BY_EMAIL_DESC",
  ModificationByAttestationSpecificationIdElementIdAsc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_ELEMENT_ID_ASC",
  ModificationByAttestationSpecificationIdElementIdDesc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_ELEMENT_ID_DESC",
  ModificationByAttestationSpecificationIdElementTypeAsc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_ELEMENT_TYPE_ASC",
  ModificationByAttestationSpecificationIdElementTypeDesc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_ELEMENT_TYPE_DESC",
  ModificationByAttestationSpecificationIdModificationAsc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_MODIFICATION_ASC",
  ModificationByAttestationSpecificationIdModificationDesc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_MODIFICATION_DESC",
  ModificationByAttestationSpecificationIdModificationIdAsc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_MODIFICATION_ID_ASC",
  ModificationByAttestationSpecificationIdModificationIdDesc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_MODIFICATION_ID_DESC",
  ModificationByAttestationSpecificationIdUpdatedAtAsc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_UPDATED_AT_ASC",
  ModificationByAttestationSpecificationIdUpdatedAtDesc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_UPDATED_AT_DESC",
  ModificationByAttestationSpecificationIdUpdatedByEmailAsc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_UPDATED_BY_EMAIL_ASC",
  ModificationByAttestationSpecificationIdUpdatedByEmailDesc = "MODIFICATION_BY_ATTESTATION_SPECIFICATION_ID_UPDATED_BY_EMAIL_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

export type AuthenticationMethodAsElement = Node & {
  __typename?: "AuthenticationMethodAsElement";
  authenticationMethodId: Scalars["UUID"];
  config: Scalars["JSON"];
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  /** Reads a single `Modification` that is related to this `AuthenticationMethodAsElement`. */
  modification?: Maybe<Modification>;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type AuthenticationMethodAsElementAggregates = {
  __typename?: "AuthenticationMethodAsElementAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<AuthenticationMethodAsElementDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** The fields on `authenticationMethodAsElement` to look up the row to connect. */
export type AuthenticationMethodAsElementAuthenticationMethodAsElementPkeyConnect =
  {
    authenticationMethodId: Scalars["UUID"];
  };

/** The fields on `authenticationMethodAsElement` to look up the row to delete. */
export type AuthenticationMethodAsElementAuthenticationMethodAsElementPkeyDelete =
  {
    authenticationMethodId: Scalars["UUID"];
  };

/**
 * A condition to be used against `AuthenticationMethodAsElement` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type AuthenticationMethodAsElementCondition = {
  /** Checks for equality with the object’s `authenticationMethodId` field. */
  authenticationMethodId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type AuthenticationMethodAsElementDistinct = {
  __typename?: "AuthenticationMethodAsElementDistinct";
  /** Distinct `authenticationMethodId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  authenticationMethodId: Array<Scalars["UUID"]>;
  /** Distinct `config` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  config: Array<Scalars["JSON"]>;
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type AuthenticationMethodAsElementDistinctCountAggregates = {
  __typename?: "AuthenticationMethodAsElementDistinctCountAggregates";
  /** Distinct count of authenticationMethodId across the matching connection */
  authenticationMethodId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `AuthenticationMethodAsElement` object types. All fields are combined with a logical ‘and.’ */
export type AuthenticationMethodAsElementFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<AuthenticationMethodAsElementFilter>>;
  /** Filter by the object’s `authenticationMethodId` field. */
  authenticationMethodId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `modification` relation. */
  modification?: InputMaybe<ModificationFilter>;
  /** Negates the expression. */
  not?: InputMaybe<AuthenticationMethodAsElementFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<AuthenticationMethodAsElementFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type AuthenticationMethodAsElementGroup = {
  __typename?: "AuthenticationMethodAsElementGroup";
  /**
   * Reads and enables pagination through a set of `AuthenticationMethodAsElement` of this `AuthenticationMethodAsElementGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `AuthenticationMethodAsElementGroup`. Overriding these may result in the wrong set of `AuthenticationMethodAsElement` returned.
   */
  authenticationMethodAsElements?: Maybe<AuthenticationMethodAsElementsConnection>;
  /** A list of _all_ `AuthenticationMethodAsElement` primary keys that belong to the `AuthenticationMethodAsElementGroup`. */
  authenticationMethodIds?: Maybe<Array<Scalars["UUID"]>>;
  /** `AuthenticationMethodAsElementFilter` that was used to implicitly filter `authenticationMethodAsElements`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** The count of _all_ `AuthenticationMethodAsElement` you could get from the `AuthenticationMethodAsElementGroup`. */
  totalCount: Scalars["Int"];
};

export type AuthenticationMethodAsElementGroupAuthenticationMethodAsElementsArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]>;
    before?: InputMaybe<Scalars["Cursor"]>;
    condition?: InputMaybe<AuthenticationMethodAsElementCondition>;
    filter?: InputMaybe<AuthenticationMethodAsElementFilter>;
    first?: InputMaybe<Scalars["Int"]>;
    last?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    orderBy?: InputMaybe<Array<AuthenticationMethodAsElementsOrderBy>>;
  };

/** Grouping methods for `AuthenticationMethodAsElement` for usage during aggregation. */
export enum AuthenticationMethodAsElementGroupBy {
  Config = "CONFIG",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  ModificationByAuthenticationMethodIdConfig = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_CONFIG",
  ModificationByAuthenticationMethodIdConfigurationId = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_CONFIGURATION_ID",
  ModificationByAuthenticationMethodIdCreatedAt = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_CREATED_AT",
  ModificationByAuthenticationMethodIdCreatedByEmail = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_CREATED_BY_EMAIL",
  ModificationByAuthenticationMethodIdElementId = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_ELEMENT_ID",
  ModificationByAuthenticationMethodIdElementType = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_ELEMENT_TYPE",
  ModificationByAuthenticationMethodIdModification = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_MODIFICATION",
  ModificationByAuthenticationMethodIdModificationId = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_MODIFICATION_ID",
  ModificationByAuthenticationMethodIdUpdatedAt = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_UPDATED_AT",
  ModificationByAuthenticationMethodIdUpdatedByEmail = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_UPDATED_BY_EMAIL",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type AuthenticationMethodAsElementHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuthenticationMethodAsElementHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `AuthenticationMethodAsElement` aggregates. */
export type AuthenticationMethodAsElementHavingInput = {
  AND?: InputMaybe<Array<AuthenticationMethodAsElementHavingInput>>;
  OR?: InputMaybe<Array<AuthenticationMethodAsElementHavingInput>>;
  average?: InputMaybe<AuthenticationMethodAsElementHavingAverageInput>;
  distinctCount?: InputMaybe<AuthenticationMethodAsElementHavingDistinctCountInput>;
  max?: InputMaybe<AuthenticationMethodAsElementHavingMaxInput>;
  min?: InputMaybe<AuthenticationMethodAsElementHavingMinInput>;
  stddevPopulation?: InputMaybe<AuthenticationMethodAsElementHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<AuthenticationMethodAsElementHavingStddevSampleInput>;
  sum?: InputMaybe<AuthenticationMethodAsElementHavingSumInput>;
  variancePopulation?: InputMaybe<AuthenticationMethodAsElementHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<AuthenticationMethodAsElementHavingVarianceSampleInput>;
};

export type AuthenticationMethodAsElementHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuthenticationMethodAsElementHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuthenticationMethodAsElementHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuthenticationMethodAsElementHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuthenticationMethodAsElementHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuthenticationMethodAsElementHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type AuthenticationMethodAsElementHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** The `authenticationMethodAsElement` to be created by this mutation. */
export type AuthenticationMethodAsElementIdFkeyAuthenticationMethodAsElementCreateInput =
  {
    authenticationMethodId?: InputMaybe<Scalars["UUID"]>;
    config: Scalars["JSON"];
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    modification?: InputMaybe<AuthenticationMethodAsElementIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** Input for the nested mutation of `modification` in the `AuthenticationMethodAsElementInput` mutation. */
export type AuthenticationMethodAsElementIdFkeyInput = {
  /** The primary key(s) for `modification` for the far side of the relationship. */
  connectByElementId?: InputMaybe<ModificationModificationElementIdKeyConnect>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  connectById?: InputMaybe<ModificationNodeIdConnect>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  connectByModificationId?: InputMaybe<ModificationModificationPkeyConnect>;
  /** A `ModificationInput` object that will be created and connected to this object. */
  create?: InputMaybe<AuthenticationMethodAsElementIdFkeyModificationCreateInput>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  deleteByElementId?: InputMaybe<ModificationModificationElementIdKeyDelete>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  deleteById?: InputMaybe<ModificationNodeIdDelete>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  deleteByModificationId?: InputMaybe<ModificationModificationPkeyDelete>;
  /** The primary key(s) and patch data for `modification` for the far side of the relationship. */
  updateByElementId?: InputMaybe<ModificationOnAuthenticationMethodAsElementForAuthenticationMethodAsElementIdFkeyUsingModificationElementIdKeyUpdate>;
  /** The primary key(s) and patch data for `modification` for the far side of the relationship. */
  updateById?: InputMaybe<AuthenticationMethodAsElementOnAuthenticationMethodAsElementForAuthenticationMethodAsElementIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `modification` for the far side of the relationship. */
  updateByModificationId?: InputMaybe<ModificationOnAuthenticationMethodAsElementForAuthenticationMethodAsElementIdFkeyUsingModificationPkeyUpdate>;
};

/** Input for the nested mutation of `authenticationMethodAsElement` in the `ModificationInput` mutation. */
export type AuthenticationMethodAsElementIdFkeyInverseInput = {
  /** The primary key(s) for `authenticationMethodAsElement` for the far side of the relationship. */
  connectByAuthenticationMethodId?: InputMaybe<AuthenticationMethodAsElementAuthenticationMethodAsElementPkeyConnect>;
  /** The primary key(s) for `authenticationMethodAsElement` for the far side of the relationship. */
  connectById?: InputMaybe<AuthenticationMethodAsElementNodeIdConnect>;
  /** A `AuthenticationMethodAsElementInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<AuthenticationMethodAsElementIdFkeyAuthenticationMethodAsElementCreateInput>
  >;
  /** The primary key(s) for `authenticationMethodAsElement` for the far side of the relationship. */
  deleteByAuthenticationMethodId?: InputMaybe<AuthenticationMethodAsElementAuthenticationMethodAsElementPkeyDelete>;
  /** The primary key(s) for `authenticationMethodAsElement` for the far side of the relationship. */
  deleteById?: InputMaybe<AuthenticationMethodAsElementNodeIdDelete>;
  /** Flag indicating whether all other `authenticationMethodAsElement` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `authenticationMethodAsElement` for the far side of the relationship. */
  updateByAuthenticationMethodId?: InputMaybe<AuthenticationMethodAsElementOnAuthenticationMethodAsElementForAuthenticationMethodAsElementIdFkeyUsingAuthenticationMethodAsElementPkeyUpdate>;
  /** The primary key(s) and patch data for `authenticationMethodAsElement` for the far side of the relationship. */
  updateById?: InputMaybe<ModificationOnAuthenticationMethodAsElementForAuthenticationMethodAsElementIdFkeyNodeIdUpdate>;
};

/** The `modification` to be created by this mutation. */
export type AuthenticationMethodAsElementIdFkeyModificationCreateInput = {
  attestationSpecificationAsElement?: InputMaybe<AttestationSpecificationAsElementIdFkeyInverseInput>;
  authenticationMethodAsElement?: InputMaybe<AuthenticationMethodAsElementIdFkeyInverseInput>;
  computeNodeAsElement?: InputMaybe<ComputeNodeAsElementIdFkeyInverseInput>;
  config?: InputMaybe<Scalars["JSON"]>;
  configuration?: InputMaybe<ModificationConfigurationIdFkeyInput>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  elementId: Scalars["UUID"];
  elementType: ElementTypeType;
  modification: ModificationType;
  modificationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userPermissionAsElement?: InputMaybe<UserPermissionAsElementIdFkeyInverseInput>;
};

/** An input for mutations affecting `AuthenticationMethodAsElement` */
export type AuthenticationMethodAsElementInput = {
  authenticationMethodId?: InputMaybe<Scalars["UUID"]>;
  config: Scalars["JSON"];
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  modification?: InputMaybe<AuthenticationMethodAsElementIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type AuthenticationMethodAsElementNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `authenticationMethodAsElement` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type AuthenticationMethodAsElementNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `authenticationMethodAsElement` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type AuthenticationMethodAsElementOnAuthenticationMethodAsElementForAuthenticationMethodAsElementIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `modification` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `modification` being updated. */
    patch: ModificationPatch;
  };

/** The fields on `authenticationMethodAsElement` to look up the row to update. */
export type AuthenticationMethodAsElementOnAuthenticationMethodAsElementForAuthenticationMethodAsElementIdFkeyUsingAuthenticationMethodAsElementPkeyUpdate =
  {
    authenticationMethodId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `authenticationMethodAsElement` being updated. */
    patch: UpdateAuthenticationMethodAsElementOnAuthenticationMethodAsElementForAuthenticationMethodAsElementIdFkeyPatch;
  };

/** Represents an update to a `AuthenticationMethodAsElement`. Fields that are set will be updated. */
export type AuthenticationMethodAsElementPatch = {
  authenticationMethodId?: InputMaybe<Scalars["UUID"]>;
  config?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  modification?: InputMaybe<AuthenticationMethodAsElementIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** A connection to a list of `AuthenticationMethodAsElement` values. */
export type AuthenticationMethodAsElementsConnection = {
  __typename?: "AuthenticationMethodAsElementsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<AuthenticationMethodAsElementAggregates>;
  /** A list of _all_ `AuthenticationMethodAsElement` primary keys. */
  authenticationMethodIds?: Maybe<Array<Scalars["UUID"]>>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<AuthenticationMethodAsElementDistinct>;
  /** A list of edges which contains the `AuthenticationMethodAsElement` and cursor to aid in pagination. */
  edges: Array<AuthenticationMethodAsElementsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<AuthenticationMethodAsElementAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<AuthenticationMethodAsElementGroup>>;
  /** A list of `AuthenticationMethodAsElement` objects. */
  nodes: Array<Maybe<AuthenticationMethodAsElement>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `AuthenticationMethodAsElement` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `AuthenticationMethodAsElement` values. */
export type AuthenticationMethodAsElementsConnectionGroupedAggregatesArgs = {
  groupBy: Array<AuthenticationMethodAsElementGroupBy>;
  having?: InputMaybe<AuthenticationMethodAsElementHavingInput>;
};

/** A connection to a list of `AuthenticationMethodAsElement` values. */
export type AuthenticationMethodAsElementsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<AuthenticationMethodAsElementGroupBy>;
  having?: InputMaybe<AuthenticationMethodAsElementHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `AuthenticationMethodAsElement` edge in the connection. */
export type AuthenticationMethodAsElementsEdge = {
  __typename?: "AuthenticationMethodAsElementsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `AuthenticationMethodAsElement` at the end of the edge. */
  node?: Maybe<AuthenticationMethodAsElement>;
};

/** Methods to use when ordering `AuthenticationMethodAsElement`. */
export enum AuthenticationMethodAsElementsOrderBy {
  AuthenticationMethodIdAsc = "AUTHENTICATION_METHOD_ID_ASC",
  AuthenticationMethodIdDesc = "AUTHENTICATION_METHOD_ID_DESC",
  ConfigAsc = "CONFIG_ASC",
  ConfigDesc = "CONFIG_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  ModificationByAuthenticationMethodIdConfigurationIdAsc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_CONFIGURATION_ID_ASC",
  ModificationByAuthenticationMethodIdConfigurationIdDesc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_CONFIGURATION_ID_DESC",
  ModificationByAuthenticationMethodIdConfigAsc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_CONFIG_ASC",
  ModificationByAuthenticationMethodIdConfigDesc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_CONFIG_DESC",
  ModificationByAuthenticationMethodIdCreatedAtAsc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_CREATED_AT_ASC",
  ModificationByAuthenticationMethodIdCreatedAtDesc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_CREATED_AT_DESC",
  ModificationByAuthenticationMethodIdCreatedByEmailAsc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_CREATED_BY_EMAIL_ASC",
  ModificationByAuthenticationMethodIdCreatedByEmailDesc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_CREATED_BY_EMAIL_DESC",
  ModificationByAuthenticationMethodIdElementIdAsc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_ELEMENT_ID_ASC",
  ModificationByAuthenticationMethodIdElementIdDesc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_ELEMENT_ID_DESC",
  ModificationByAuthenticationMethodIdElementTypeAsc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_ELEMENT_TYPE_ASC",
  ModificationByAuthenticationMethodIdElementTypeDesc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_ELEMENT_TYPE_DESC",
  ModificationByAuthenticationMethodIdModificationAsc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_MODIFICATION_ASC",
  ModificationByAuthenticationMethodIdModificationDesc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_MODIFICATION_DESC",
  ModificationByAuthenticationMethodIdModificationIdAsc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_MODIFICATION_ID_ASC",
  ModificationByAuthenticationMethodIdModificationIdDesc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_MODIFICATION_ID_DESC",
  ModificationByAuthenticationMethodIdUpdatedAtAsc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_UPDATED_AT_ASC",
  ModificationByAuthenticationMethodIdUpdatedAtDesc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_UPDATED_AT_DESC",
  ModificationByAuthenticationMethodIdUpdatedByEmailAsc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_UPDATED_BY_EMAIL_ASC",
  ModificationByAuthenticationMethodIdUpdatedByEmailDesc = "MODIFICATION_BY_AUTHENTICATION_METHOD_ID_UPDATED_BY_EMAIL_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

/** A filter to be used against BigFloat fields. All fields are combined with a logical ‘and.’ */
export type BigFloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["BigFloat"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["BigFloat"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["BigFloat"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["BigFloat"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["BigFloat"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["BigFloat"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["BigFloat"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["BigFloat"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["BigFloat"]>>;
};

/** A filter to be used against BigInt fields. All fields are combined with a logical ‘and.’ */
export type BigIntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["BigInt"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["BigInt"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["BigInt"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["BigInt"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["BigInt"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["BigInt"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["BigInt"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["BigInt"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["BigInt"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["BigInt"]>>;
};

/** A filter to be used against Boolean fields. All fields are combined with a logical ‘and.’ */
export type BooleanFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Boolean"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Boolean"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Boolean"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Boolean"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Boolean"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Boolean"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Boolean"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Boolean"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Boolean"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Boolean"]>>;
};

export type ChangePasswordInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type ChangePasswordPayload = {
  __typename?: "ChangePasswordPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

export type ComputeJob = {
  __typename?: "ComputeJob";
  computeNode: PublishedNode;
  createdAt: Scalars["Datetime"];
  enclaveJobId: Scalars["String"];
  id: Scalars["ID"];
  user: User;
};

export type ComputeNode = Node & {
  __typename?: "ComputeNode";
  computationType?: Maybe<Scalars["String"]>;
  computeNodeId: Scalars["UUID"];
  computeNodeType: ComputeNodeTypeType;
  config: Scalars["JSON"];
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** Reads a single `DataRoom` that is related to this `ComputeNode`. */
  dataRoom?: Maybe<DataRoom>;
  dataRoomId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `DatasetLink`. */
  datasetLinks: DatasetLinksConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  index: Scalars["Int"];
  isExpanded?: Maybe<Scalars["Boolean"]>;
  isPopped?: Maybe<Scalars["Boolean"]>;
  isSaving?: Maybe<Scalars["Boolean"]>;
  job?: Maybe<Job>;
  /** Reads and enables pagination through a set of `Job`. */
  jobs: JobsConnection;
  nodeName: Scalars["String"];
  /** Reads and enables pagination through a set of `Permission`. */
  permissions: PermissionsConnection;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
  /** Reads and enables pagination through a set of `UserPermission`. */
  userPermissions: UserPermissionsConnection;
};

export type ComputeNodeDatasetLinksArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DatasetLinkCondition>;
  filter?: InputMaybe<DatasetLinkFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DatasetLinksOrderBy>>;
};

export type ComputeNodeJobsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<JobCondition>;
  filter?: InputMaybe<JobFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<JobsOrderBy>>;
};

export type ComputeNodePermissionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<PermissionCondition>;
  filter?: InputMaybe<PermissionFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PermissionsOrderBy>>;
};

export type ComputeNodeUserPermissionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<UserPermissionCondition>;
  filter?: InputMaybe<UserPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserPermissionsOrderBy>>;
};

export type ComputeNodeAggregates = {
  __typename?: "ComputeNodeAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ComputeNodeAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ComputeNodeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ComputeNodeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ComputeNodeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ComputeNodeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ComputeNodeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ComputeNodeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ComputeNodeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ComputeNodeVarianceSampleAggregates>;
};

/** A filter to be used against aggregates of `ComputeNode` object types. */
export type ComputeNodeAggregatesFilter = {
  /** Mean average aggregate over matching `ComputeNode` objects. */
  average?: InputMaybe<ComputeNodeAverageAggregateFilter>;
  /** Distinct count aggregate over matching `ComputeNode` objects. */
  distinctCount?: InputMaybe<ComputeNodeDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `ComputeNode` object to be included within the aggregate. */
  filter?: InputMaybe<ComputeNodeFilter>;
  /** Maximum aggregate over matching `ComputeNode` objects. */
  max?: InputMaybe<ComputeNodeMaxAggregateFilter>;
  /** Minimum aggregate over matching `ComputeNode` objects. */
  min?: InputMaybe<ComputeNodeMinAggregateFilter>;
  /** Population standard deviation aggregate over matching `ComputeNode` objects. */
  stddevPopulation?: InputMaybe<ComputeNodeStddevPopulationAggregateFilter>;
  /** Sample standard deviation aggregate over matching `ComputeNode` objects. */
  stddevSample?: InputMaybe<ComputeNodeStddevSampleAggregateFilter>;
  /** Sum aggregate over matching `ComputeNode` objects. */
  sum?: InputMaybe<ComputeNodeSumAggregateFilter>;
  /** Population variance aggregate over matching `ComputeNode` objects. */
  variancePopulation?: InputMaybe<ComputeNodeVariancePopulationAggregateFilter>;
  /** Sample variance aggregate over matching `ComputeNode` objects. */
  varianceSample?: InputMaybe<ComputeNodeVarianceSampleAggregateFilter>;
};

export type ComputeNodeAsElement = Node & {
  __typename?: "ComputeNodeAsElement";
  computeNodeId: Scalars["UUID"];
  computeNodeType: ComputeNodeTypeType;
  config: Scalars["JSON"];
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  /** Reads a single `Modification` that is related to this `ComputeNodeAsElement`. */
  modification?: Maybe<Modification>;
  nodeName: Scalars["String"];
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type ComputeNodeAsElementAggregates = {
  __typename?: "ComputeNodeAsElementAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ComputeNodeAsElementDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** The fields on `computeNodeAsElement` to look up the row to connect. */
export type ComputeNodeAsElementComputeNodeAsElementPkeyConnect = {
  computeNodeId: Scalars["UUID"];
};

/** The fields on `computeNodeAsElement` to look up the row to delete. */
export type ComputeNodeAsElementComputeNodeAsElementPkeyDelete = {
  computeNodeId: Scalars["UUID"];
};

/**
 * A condition to be used against `ComputeNodeAsElement` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type ComputeNodeAsElementCondition = {
  /** Checks for equality with the object’s `computeNodeId` field. */
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `computeNodeType` field. */
  computeNodeType?: InputMaybe<ComputeNodeTypeType>;
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `nodeName` field. */
  nodeName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type ComputeNodeAsElementDistinct = {
  __typename?: "ComputeNodeAsElementDistinct";
  /** Distinct `computeNodeId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  computeNodeId: Array<Scalars["UUID"]>;
  /** Distinct `computeNodeType` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  computeNodeType: Array<ComputeNodeTypeType>;
  /** Distinct `config` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  config: Array<Scalars["JSON"]>;
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `nodeName` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  nodeName: Array<Scalars["String"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type ComputeNodeAsElementDistinctCountAggregates = {
  __typename?: "ComputeNodeAsElementDistinctCountAggregates";
  /** Distinct count of computeNodeId across the matching connection */
  computeNodeId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of computeNodeType across the matching connection */
  computeNodeType?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of nodeName across the matching connection */
  nodeName?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `ComputeNodeAsElement` object types. All fields are combined with a logical ‘and.’ */
export type ComputeNodeAsElementFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ComputeNodeAsElementFilter>>;
  /** Filter by the object’s `computeNodeId` field. */
  computeNodeId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `computeNodeType` field. */
  computeNodeType?: InputMaybe<ComputeNodeTypeTypeFilter>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `modification` relation. */
  modification?: InputMaybe<ModificationFilter>;
  /** Filter by the object’s `nodeName` field. */
  nodeName?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ComputeNodeAsElementFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ComputeNodeAsElementFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type ComputeNodeAsElementGroup = {
  __typename?: "ComputeNodeAsElementGroup";
  /**
   * Reads and enables pagination through a set of `ComputeNodeAsElement` of this `ComputeNodeAsElementGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `ComputeNodeAsElementGroup`. Overriding these may result in the wrong set of `ComputeNodeAsElement` returned.
   */
  computeNodeAsElements?: Maybe<ComputeNodeAsElementsConnection>;
  /** A list of _all_ `ComputeNodeAsElement` primary keys that belong to the `ComputeNodeAsElementGroup`. */
  computeNodeIds?: Maybe<Array<Scalars["UUID"]>>;
  /** `ComputeNodeAsElementFilter` that was used to implicitly filter `computeNodeAsElements`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** The count of _all_ `ComputeNodeAsElement` you could get from the `ComputeNodeAsElementGroup`. */
  totalCount: Scalars["Int"];
};

export type ComputeNodeAsElementGroupComputeNodeAsElementsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ComputeNodeAsElementCondition>;
  filter?: InputMaybe<ComputeNodeAsElementFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ComputeNodeAsElementsOrderBy>>;
};

/** Grouping methods for `ComputeNodeAsElement` for usage during aggregation. */
export enum ComputeNodeAsElementGroupBy {
  ComputeNodeType = "COMPUTE_NODE_TYPE",
  Config = "CONFIG",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  ModificationByComputeNodeIdConfig = "MODIFICATION_BY_COMPUTE_NODE_ID_CONFIG",
  ModificationByComputeNodeIdConfigurationId = "MODIFICATION_BY_COMPUTE_NODE_ID_CONFIGURATION_ID",
  ModificationByComputeNodeIdCreatedAt = "MODIFICATION_BY_COMPUTE_NODE_ID_CREATED_AT",
  ModificationByComputeNodeIdCreatedByEmail = "MODIFICATION_BY_COMPUTE_NODE_ID_CREATED_BY_EMAIL",
  ModificationByComputeNodeIdElementId = "MODIFICATION_BY_COMPUTE_NODE_ID_ELEMENT_ID",
  ModificationByComputeNodeIdElementType = "MODIFICATION_BY_COMPUTE_NODE_ID_ELEMENT_TYPE",
  ModificationByComputeNodeIdModification = "MODIFICATION_BY_COMPUTE_NODE_ID_MODIFICATION",
  ModificationByComputeNodeIdModificationId = "MODIFICATION_BY_COMPUTE_NODE_ID_MODIFICATION_ID",
  ModificationByComputeNodeIdUpdatedAt = "MODIFICATION_BY_COMPUTE_NODE_ID_UPDATED_AT",
  ModificationByComputeNodeIdUpdatedByEmail = "MODIFICATION_BY_COMPUTE_NODE_ID_UPDATED_BY_EMAIL",
  NodeName = "NODE_NAME",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type ComputeNodeAsElementHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComputeNodeAsElementHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ComputeNodeAsElement` aggregates. */
export type ComputeNodeAsElementHavingInput = {
  AND?: InputMaybe<Array<ComputeNodeAsElementHavingInput>>;
  OR?: InputMaybe<Array<ComputeNodeAsElementHavingInput>>;
  average?: InputMaybe<ComputeNodeAsElementHavingAverageInput>;
  distinctCount?: InputMaybe<ComputeNodeAsElementHavingDistinctCountInput>;
  max?: InputMaybe<ComputeNodeAsElementHavingMaxInput>;
  min?: InputMaybe<ComputeNodeAsElementHavingMinInput>;
  stddevPopulation?: InputMaybe<ComputeNodeAsElementHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ComputeNodeAsElementHavingStddevSampleInput>;
  sum?: InputMaybe<ComputeNodeAsElementHavingSumInput>;
  variancePopulation?: InputMaybe<ComputeNodeAsElementHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ComputeNodeAsElementHavingVarianceSampleInput>;
};

export type ComputeNodeAsElementHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComputeNodeAsElementHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComputeNodeAsElementHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComputeNodeAsElementHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComputeNodeAsElementHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComputeNodeAsElementHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComputeNodeAsElementHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** The `computeNodeAsElement` to be created by this mutation. */
export type ComputeNodeAsElementIdFkeyComputeNodeAsElementCreateInput = {
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  computeNodeType: ComputeNodeTypeType;
  config: Scalars["JSON"];
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  modification?: InputMaybe<ComputeNodeAsElementIdFkeyInput>;
  nodeName: Scalars["String"];
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** Input for the nested mutation of `modification` in the `ComputeNodeAsElementInput` mutation. */
export type ComputeNodeAsElementIdFkeyInput = {
  /** The primary key(s) for `modification` for the far side of the relationship. */
  connectByElementId?: InputMaybe<ModificationModificationElementIdKeyConnect>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  connectById?: InputMaybe<ModificationNodeIdConnect>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  connectByModificationId?: InputMaybe<ModificationModificationPkeyConnect>;
  /** A `ModificationInput` object that will be created and connected to this object. */
  create?: InputMaybe<ComputeNodeAsElementIdFkeyModificationCreateInput>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  deleteByElementId?: InputMaybe<ModificationModificationElementIdKeyDelete>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  deleteById?: InputMaybe<ModificationNodeIdDelete>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  deleteByModificationId?: InputMaybe<ModificationModificationPkeyDelete>;
  /** The primary key(s) and patch data for `modification` for the far side of the relationship. */
  updateByElementId?: InputMaybe<ModificationOnComputeNodeAsElementForComputeNodeAsElementIdFkeyUsingModificationElementIdKeyUpdate>;
  /** The primary key(s) and patch data for `modification` for the far side of the relationship. */
  updateById?: InputMaybe<ComputeNodeAsElementOnComputeNodeAsElementForComputeNodeAsElementIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `modification` for the far side of the relationship. */
  updateByModificationId?: InputMaybe<ModificationOnComputeNodeAsElementForComputeNodeAsElementIdFkeyUsingModificationPkeyUpdate>;
};

/** Input for the nested mutation of `computeNodeAsElement` in the `ModificationInput` mutation. */
export type ComputeNodeAsElementIdFkeyInverseInput = {
  /** The primary key(s) for `computeNodeAsElement` for the far side of the relationship. */
  connectByComputeNodeId?: InputMaybe<ComputeNodeAsElementComputeNodeAsElementPkeyConnect>;
  /** The primary key(s) for `computeNodeAsElement` for the far side of the relationship. */
  connectById?: InputMaybe<ComputeNodeAsElementNodeIdConnect>;
  /** A `ComputeNodeAsElementInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ComputeNodeAsElementIdFkeyComputeNodeAsElementCreateInput>
  >;
  /** The primary key(s) for `computeNodeAsElement` for the far side of the relationship. */
  deleteByComputeNodeId?: InputMaybe<ComputeNodeAsElementComputeNodeAsElementPkeyDelete>;
  /** The primary key(s) for `computeNodeAsElement` for the far side of the relationship. */
  deleteById?: InputMaybe<ComputeNodeAsElementNodeIdDelete>;
  /** Flag indicating whether all other `computeNodeAsElement` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `computeNodeAsElement` for the far side of the relationship. */
  updateByComputeNodeId?: InputMaybe<ComputeNodeAsElementOnComputeNodeAsElementForComputeNodeAsElementIdFkeyUsingComputeNodeAsElementPkeyUpdate>;
  /** The primary key(s) and patch data for `computeNodeAsElement` for the far side of the relationship. */
  updateById?: InputMaybe<ModificationOnComputeNodeAsElementForComputeNodeAsElementIdFkeyNodeIdUpdate>;
};

/** The `modification` to be created by this mutation. */
export type ComputeNodeAsElementIdFkeyModificationCreateInput = {
  attestationSpecificationAsElement?: InputMaybe<AttestationSpecificationAsElementIdFkeyInverseInput>;
  authenticationMethodAsElement?: InputMaybe<AuthenticationMethodAsElementIdFkeyInverseInput>;
  computeNodeAsElement?: InputMaybe<ComputeNodeAsElementIdFkeyInverseInput>;
  config?: InputMaybe<Scalars["JSON"]>;
  configuration?: InputMaybe<ModificationConfigurationIdFkeyInput>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  elementId: Scalars["UUID"];
  elementType: ElementTypeType;
  modification: ModificationType;
  modificationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userPermissionAsElement?: InputMaybe<UserPermissionAsElementIdFkeyInverseInput>;
};

/** An input for mutations affecting `ComputeNodeAsElement` */
export type ComputeNodeAsElementInput = {
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  computeNodeType: ComputeNodeTypeType;
  config: Scalars["JSON"];
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  modification?: InputMaybe<ComputeNodeAsElementIdFkeyInput>;
  nodeName: Scalars["String"];
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ComputeNodeAsElementNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `computeNodeAsElement` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type ComputeNodeAsElementNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `computeNodeAsElement` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type ComputeNodeAsElementOnComputeNodeAsElementForComputeNodeAsElementIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `modification` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `modification` being updated. */
    patch: ModificationPatch;
  };

/** The fields on `computeNodeAsElement` to look up the row to update. */
export type ComputeNodeAsElementOnComputeNodeAsElementForComputeNodeAsElementIdFkeyUsingComputeNodeAsElementPkeyUpdate =
  {
    computeNodeId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `computeNodeAsElement` being updated. */
    patch: UpdateComputeNodeAsElementOnComputeNodeAsElementForComputeNodeAsElementIdFkeyPatch;
  };

/** Represents an update to a `ComputeNodeAsElement`. Fields that are set will be updated. */
export type ComputeNodeAsElementPatch = {
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  computeNodeType?: InputMaybe<ComputeNodeTypeType>;
  config?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  modification?: InputMaybe<ComputeNodeAsElementIdFkeyInput>;
  nodeName?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** A connection to a list of `ComputeNodeAsElement` values. */
export type ComputeNodeAsElementsConnection = {
  __typename?: "ComputeNodeAsElementsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ComputeNodeAsElementAggregates>;
  /** A list of _all_ `ComputeNodeAsElement` primary keys. */
  computeNodeIds?: Maybe<Array<Scalars["UUID"]>>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<ComputeNodeAsElementDistinct>;
  /** A list of edges which contains the `ComputeNodeAsElement` and cursor to aid in pagination. */
  edges: Array<ComputeNodeAsElementsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ComputeNodeAsElementAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<ComputeNodeAsElementGroup>>;
  /** A list of `ComputeNodeAsElement` objects. */
  nodes: Array<Maybe<ComputeNodeAsElement>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ComputeNodeAsElement` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `ComputeNodeAsElement` values. */
export type ComputeNodeAsElementsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ComputeNodeAsElementGroupBy>;
  having?: InputMaybe<ComputeNodeAsElementHavingInput>;
};

/** A connection to a list of `ComputeNodeAsElement` values. */
export type ComputeNodeAsElementsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<ComputeNodeAsElementGroupBy>;
  having?: InputMaybe<ComputeNodeAsElementHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `ComputeNodeAsElement` edge in the connection. */
export type ComputeNodeAsElementsEdge = {
  __typename?: "ComputeNodeAsElementsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `ComputeNodeAsElement` at the end of the edge. */
  node?: Maybe<ComputeNodeAsElement>;
};

/** Methods to use when ordering `ComputeNodeAsElement`. */
export enum ComputeNodeAsElementsOrderBy {
  ComputeNodeIdAsc = "COMPUTE_NODE_ID_ASC",
  ComputeNodeIdDesc = "COMPUTE_NODE_ID_DESC",
  ComputeNodeTypeAsc = "COMPUTE_NODE_TYPE_ASC",
  ComputeNodeTypeDesc = "COMPUTE_NODE_TYPE_DESC",
  ConfigAsc = "CONFIG_ASC",
  ConfigDesc = "CONFIG_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  ModificationByComputeNodeIdConfigurationIdAsc = "MODIFICATION_BY_COMPUTE_NODE_ID_CONFIGURATION_ID_ASC",
  ModificationByComputeNodeIdConfigurationIdDesc = "MODIFICATION_BY_COMPUTE_NODE_ID_CONFIGURATION_ID_DESC",
  ModificationByComputeNodeIdConfigAsc = "MODIFICATION_BY_COMPUTE_NODE_ID_CONFIG_ASC",
  ModificationByComputeNodeIdConfigDesc = "MODIFICATION_BY_COMPUTE_NODE_ID_CONFIG_DESC",
  ModificationByComputeNodeIdCreatedAtAsc = "MODIFICATION_BY_COMPUTE_NODE_ID_CREATED_AT_ASC",
  ModificationByComputeNodeIdCreatedAtDesc = "MODIFICATION_BY_COMPUTE_NODE_ID_CREATED_AT_DESC",
  ModificationByComputeNodeIdCreatedByEmailAsc = "MODIFICATION_BY_COMPUTE_NODE_ID_CREATED_BY_EMAIL_ASC",
  ModificationByComputeNodeIdCreatedByEmailDesc = "MODIFICATION_BY_COMPUTE_NODE_ID_CREATED_BY_EMAIL_DESC",
  ModificationByComputeNodeIdElementIdAsc = "MODIFICATION_BY_COMPUTE_NODE_ID_ELEMENT_ID_ASC",
  ModificationByComputeNodeIdElementIdDesc = "MODIFICATION_BY_COMPUTE_NODE_ID_ELEMENT_ID_DESC",
  ModificationByComputeNodeIdElementTypeAsc = "MODIFICATION_BY_COMPUTE_NODE_ID_ELEMENT_TYPE_ASC",
  ModificationByComputeNodeIdElementTypeDesc = "MODIFICATION_BY_COMPUTE_NODE_ID_ELEMENT_TYPE_DESC",
  ModificationByComputeNodeIdModificationAsc = "MODIFICATION_BY_COMPUTE_NODE_ID_MODIFICATION_ASC",
  ModificationByComputeNodeIdModificationDesc = "MODIFICATION_BY_COMPUTE_NODE_ID_MODIFICATION_DESC",
  ModificationByComputeNodeIdModificationIdAsc = "MODIFICATION_BY_COMPUTE_NODE_ID_MODIFICATION_ID_ASC",
  ModificationByComputeNodeIdModificationIdDesc = "MODIFICATION_BY_COMPUTE_NODE_ID_MODIFICATION_ID_DESC",
  ModificationByComputeNodeIdUpdatedAtAsc = "MODIFICATION_BY_COMPUTE_NODE_ID_UPDATED_AT_ASC",
  ModificationByComputeNodeIdUpdatedAtDesc = "MODIFICATION_BY_COMPUTE_NODE_ID_UPDATED_AT_DESC",
  ModificationByComputeNodeIdUpdatedByEmailAsc = "MODIFICATION_BY_COMPUTE_NODE_ID_UPDATED_BY_EMAIL_ASC",
  ModificationByComputeNodeIdUpdatedByEmailDesc = "MODIFICATION_BY_COMPUTE_NODE_ID_UPDATED_BY_EMAIL_DESC",
  Natural = "NATURAL",
  NodeNameAsc = "NODE_NAME_ASC",
  NodeNameDesc = "NODE_NAME_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

export type ComputeNodeAverageAggregateFilter = {
  index?: InputMaybe<BigFloatFilter>;
};

export type ComputeNodeAverageAggregates = {
  __typename?: "ComputeNodeAverageAggregates";
  /** Mean average of index across the matching connection */
  index?: Maybe<Scalars["BigFloat"]>;
};

/** The fields on `computeNode` to look up the row to connect. */
export type ComputeNodeComputeNodePkeyConnect = {
  computeNodeId: Scalars["UUID"];
};

/** The fields on `computeNode` to look up the row to delete. */
export type ComputeNodeComputeNodePkeyDelete = {
  computeNodeId: Scalars["UUID"];
};

/**
 * A condition to be used against `ComputeNode` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ComputeNodeCondition = {
  /** Checks for equality with the object’s `computationType` field. */
  computationType?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `computeNodeId` field. */
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `computeNodeType` field. */
  computeNodeType?: InputMaybe<ComputeNodeTypeType>;
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `index` field. */
  index?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `nodeName` field. */
  nodeName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The `computeNode` to be created by this mutation. */
export type ComputeNodeDataRoomIdFkeyComputeNodeCreateInput = {
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  computeNodeType: ComputeNodeTypeType;
  config: Scalars["JSON"];
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<ComputeNodeDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0InverseInput>;
  index?: InputMaybe<Scalars["Int"]>;
  jobs?: InputMaybe<JobComputeNodeIdFkeyInverseInput>;
  nodeName: Scalars["String"];
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The `dataRoom` to be created by this mutation. */
export type ComputeNodeDataRoomIdFkeyDataRoomCreateInput = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** Input for the nested mutation of `dataRoom` in the `ComputeNodeInput` mutation. */
export type ComputeNodeDataRoomIdFkeyInput = {
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomHash?: InputMaybe<DataRoomDataRoomDataRoomHashKeyConnect>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomId?: InputMaybe<DataRoomDataRoomPkeyConnect>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectById?: InputMaybe<DataRoomNodeIdConnect>;
  /** A `DataRoomInput` object that will be created and connected to this object. */
  create?: InputMaybe<ComputeNodeDataRoomIdFkeyDataRoomCreateInput>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomHash?: InputMaybe<DataRoomDataRoomDataRoomHashKeyDelete>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomId?: InputMaybe<DataRoomDataRoomPkeyDelete>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteById?: InputMaybe<DataRoomNodeIdDelete>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomHash?: InputMaybe<DataRoomOnComputeNodeForComputeNodeDataRoomIdFkeyUsingDataRoomDataRoomHashKeyUpdate>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomId?: InputMaybe<DataRoomOnComputeNodeForComputeNodeDataRoomIdFkeyUsingDataRoomPkeyUpdate>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateById?: InputMaybe<ComputeNodeOnComputeNodeForComputeNodeDataRoomIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `computeNode` in the `DataRoomInput` mutation. */
export type ComputeNodeDataRoomIdFkeyInverseInput = {
  /** The primary key(s) for `computeNode` for the far side of the relationship. */
  connectByComputeNodeId?: InputMaybe<Array<ComputeNodeComputeNodePkeyConnect>>;
  /** The primary key(s) for `computeNode` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ComputeNodeNodeIdConnect>>;
  /** A `ComputeNodeInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ComputeNodeDataRoomIdFkeyComputeNodeCreateInput>>;
  /** The primary key(s) for `computeNode` for the far side of the relationship. */
  deleteByComputeNodeId?: InputMaybe<Array<ComputeNodeComputeNodePkeyDelete>>;
  /** The primary key(s) for `computeNode` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ComputeNodeNodeIdDelete>>;
  /** Flag indicating whether all other `computeNode` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `computeNode` for the far side of the relationship. */
  updateByComputeNodeId?: InputMaybe<
    Array<ComputeNodeOnComputeNodeForComputeNodeDataRoomIdFkeyUsingComputeNodePkeyUpdate>
  >;
  /** The primary key(s) and patch data for `computeNode` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DataRoomOnComputeNodeForComputeNodeDataRoomIdFkeyNodeIdUpdate>
  >;
};

export type ComputeNodeDistinct = {
  __typename?: "ComputeNodeDistinct";
  /** Distinct `computeNodeId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  computeNodeId: Array<Scalars["UUID"]>;
  /** Distinct `computeNodeType` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  computeNodeType: Array<ComputeNodeTypeType>;
  /** Distinct `config` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  config: Array<Scalars["JSON"]>;
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `dataRoomId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  dataRoomId: Array<Scalars["UUID"]>;
  /** Distinct `index` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  index: Array<Scalars["Int"]>;
  /** Distinct `nodeName` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  nodeName: Array<Scalars["String"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type ComputeNodeDistinctCountAggregateFilter = {
  computationType?: InputMaybe<StringFilter>;
  computeNodeId?: InputMaybe<BigIntFilter>;
  computeNodeType?: InputMaybe<BigIntFilter>;
  config?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  createdByEmail?: InputMaybe<BigIntFilter>;
  dataRoomId?: InputMaybe<BigIntFilter>;
  index?: InputMaybe<BigIntFilter>;
  nodeName?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  updatedByEmail?: InputMaybe<BigIntFilter>;
};

export type ComputeNodeDistinctCountAggregates = {
  __typename?: "ComputeNodeDistinctCountAggregates";
  /** Distinct count of this field across the matching connection. */
  computationType?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of computeNodeId across the matching connection */
  computeNodeId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of computeNodeType across the matching connection */
  computeNodeType?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of dataRoomId across the matching connection */
  dataRoomId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of index across the matching connection */
  index?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of this field across the matching connection. */
  job?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of nodeName across the matching connection */
  nodeName?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `ComputeNode` object types. All fields are combined with a logical ‘and.’ */
export type ComputeNodeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ComputeNodeFilter>>;
  /** Filter by the object’s `computationType` field. */
  computationType?: InputMaybe<StringFilter>;
  /** Filter by the object’s `computeNodeId` field. */
  computeNodeId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `computeNodeType` field. */
  computeNodeType?: InputMaybe<ComputeNodeTypeTypeFilter>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dataRoom` relation. */
  dataRoom?: InputMaybe<DataRoomFilter>;
  /** Filter by the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `datasetLinks` relation. */
  datasetLinks?: InputMaybe<ComputeNodeToManyDatasetLinkFilter>;
  /** Some related `datasetLinks` exist. */
  datasetLinksExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `index` field. */
  index?: InputMaybe<IntFilter>;
  /** Filter by the object’s `jobs` relation. */
  jobs?: InputMaybe<ComputeNodeToManyJobFilter>;
  /** Some related `jobs` exist. */
  jobsExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `nodeName` field. */
  nodeName?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ComputeNodeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ComputeNodeFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type ComputeNodeGroup = {
  __typename?: "ComputeNodeGroup";
  /** A list of _all_ `ComputeNode` primary keys that belong to the `ComputeNodeGroup`. */
  computeNodeIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `ComputeNode` of this `ComputeNodeGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `ComputeNodeGroup`. Overriding these may result in the wrong set of `ComputeNode` returned.
   */
  computeNodes?: Maybe<ComputeNodesConnection>;
  /** `ComputeNodeFilter` that was used to implicitly filter `computeNodes`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** The count of _all_ `ComputeNode` you could get from the `ComputeNodeGroup`. */
  totalCount: Scalars["Int"];
};

export type ComputeNodeGroupComputeNodesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ComputeNodeCondition>;
  filter?: InputMaybe<ComputeNodeFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ComputeNodesOrderBy>>;
};

/** Grouping methods for `ComputeNode` for usage during aggregation. */
export enum ComputeNodeGroupBy {
  ComputeNodeType = "COMPUTE_NODE_TYPE",
  Config = "CONFIG",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  DataRoomByDataRoomIdCreatedAt = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT",
  DataRoomByDataRoomIdCreatedByEmail = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL",
  DataRoomByDataRoomIdDataRoomHash = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH",
  DataRoomByDataRoomIdDataRoomId = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID",
  DataRoomByDataRoomIdDescription = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION",
  DataRoomByDataRoomIdEnableDevelopmentTab = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB",
  DataRoomByDataRoomIdGovernanceProtocol = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL",
  DataRoomByDataRoomIdMrenclave = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE",
  DataRoomByDataRoomIdName = "DATA_ROOM_BY_DATA_ROOM_ID_NAME",
  DataRoomByDataRoomIdOwnerEmail = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL",
  DataRoomByDataRoomIdSource = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE",
  DataRoomByDataRoomIdUpdatedAt = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT",
  DataRoomByDataRoomIdUpdatedByEmail = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL",
  DataRoomId = "DATA_ROOM_ID",
  Index = "INDEX",
  NodeName = "NODE_NAME",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type ComputeNodeHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  index?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComputeNodeHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  index?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `ComputeNode` aggregates. */
export type ComputeNodeHavingInput = {
  AND?: InputMaybe<Array<ComputeNodeHavingInput>>;
  OR?: InputMaybe<Array<ComputeNodeHavingInput>>;
  average?: InputMaybe<ComputeNodeHavingAverageInput>;
  distinctCount?: InputMaybe<ComputeNodeHavingDistinctCountInput>;
  max?: InputMaybe<ComputeNodeHavingMaxInput>;
  min?: InputMaybe<ComputeNodeHavingMinInput>;
  stddevPopulation?: InputMaybe<ComputeNodeHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ComputeNodeHavingStddevSampleInput>;
  sum?: InputMaybe<ComputeNodeHavingSumInput>;
  variancePopulation?: InputMaybe<ComputeNodeHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ComputeNodeHavingVarianceSampleInput>;
};

export type ComputeNodeHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  index?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComputeNodeHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  index?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComputeNodeHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  index?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComputeNodeHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  index?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComputeNodeHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  index?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComputeNodeHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  index?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ComputeNodeHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  index?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `ComputeNode` */
export type ComputeNodeInput = {
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  computeNodeType: ComputeNodeTypeType;
  config: Scalars["JSON"];
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<ComputeNodeDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0InverseInput>;
  index?: InputMaybe<Scalars["Int"]>;
  jobs?: InputMaybe<JobComputeNodeIdFkeyInverseInput>;
  nodeName: Scalars["String"];
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type ComputeNodeMaxAggregateFilter = {
  index?: InputMaybe<IntFilter>;
};

export type ComputeNodeMaxAggregates = {
  __typename?: "ComputeNodeMaxAggregates";
  /** Maximum of index across the matching connection */
  index?: Maybe<Scalars["Int"]>;
};

export type ComputeNodeMinAggregateFilter = {
  index?: InputMaybe<IntFilter>;
};

export type ComputeNodeMinAggregates = {
  __typename?: "ComputeNodeMinAggregates";
  /** Minimum of index across the matching connection */
  index?: Maybe<Scalars["Int"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ComputeNodeNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `computeNode` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type ComputeNodeNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `computeNode` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type ComputeNodeOnComputeNodeForComputeNodeDataRoomIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `dataRoom` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `dataRoom` being updated. */
  patch: DataRoomPatch;
};

/** The fields on `computeNode` to look up the row to update. */
export type ComputeNodeOnComputeNodeForComputeNodeDataRoomIdFkeyUsingComputeNodePkeyUpdate =
  {
    computeNodeId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `computeNode` being updated. */
    patch: UpdateComputeNodeOnComputeNodeForComputeNodeDataRoomIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ComputeNodeOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey0NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `datasetLink` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `datasetLink` being updated. */
    patch: DatasetLinkPatch;
  };

/** The fields on `computeNode` to look up the row to update. */
export type ComputeNodeOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey0UsingComputeNodePkeyUpdate =
  {
    computeNodeId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `computeNode` being updated. */
    patch: UpdateComputeNodeOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey0Patch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ComputeNodeOnJobForJobComputeNodeIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `job` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `job` being updated. */
  patch: JobPatch;
};

/** The fields on `computeNode` to look up the row to update. */
export type ComputeNodeOnJobForJobComputeNodeIdFkeyUsingComputeNodePkeyUpdate =
  {
    computeNodeId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `computeNode` being updated. */
    patch: UpdateComputeNodeOnJobForJobComputeNodeIdFkeyPatch;
  };

/** Represents an update to a `ComputeNode`. Fields that are set will be updated. */
export type ComputeNodePatch = {
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  computeNodeType?: InputMaybe<ComputeNodeTypeType>;
  config?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<ComputeNodeDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0InverseInput>;
  index?: InputMaybe<Scalars["Int"]>;
  jobs?: InputMaybe<JobComputeNodeIdFkeyInverseInput>;
  nodeName?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type ComputeNodeStddevPopulationAggregateFilter = {
  index?: InputMaybe<BigFloatFilter>;
};

export type ComputeNodeStddevPopulationAggregates = {
  __typename?: "ComputeNodeStddevPopulationAggregates";
  /** Population standard deviation of index across the matching connection */
  index?: Maybe<Scalars["BigFloat"]>;
};

export type ComputeNodeStddevSampleAggregateFilter = {
  index?: InputMaybe<BigFloatFilter>;
};

export type ComputeNodeStddevSampleAggregates = {
  __typename?: "ComputeNodeStddevSampleAggregates";
  /** Sample standard deviation of index across the matching connection */
  index?: Maybe<Scalars["BigFloat"]>;
};

export type ComputeNodeSumAggregateFilter = {
  index?: InputMaybe<BigIntFilter>;
};

export type ComputeNodeSumAggregates = {
  __typename?: "ComputeNodeSumAggregates";
  /** Sum of index across the matching connection */
  index: Scalars["BigInt"];
};

/** A filter to be used against many `DatasetLink` object types. All fields are combined with a logical ‘and.’ */
export type ComputeNodeToManyDatasetLinkFilter = {
  /** Aggregates across related `DatasetLink` match the filter criteria. */
  aggregates?: InputMaybe<DatasetLinkAggregatesFilter>;
  /** Every related `DatasetLink` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetLinkFilter>;
  /** No related `DatasetLink` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetLinkFilter>;
  /** Some related `DatasetLink` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetLinkFilter>;
};

/** A filter to be used against many `Job` object types. All fields are combined with a logical ‘and.’ */
export type ComputeNodeToManyJobFilter = {
  /** Aggregates across related `Job` match the filter criteria. */
  aggregates?: InputMaybe<JobAggregatesFilter>;
  /** Every related `Job` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<JobFilter>;
  /** No related `Job` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<JobFilter>;
  /** Some related `Job` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<JobFilter>;
};

export enum ComputeNodeTypeType {
  Branch = "BRANCH",
  Leaf = "LEAF",
}

/** A filter to be used against ComputeNodeTypeType fields. All fields are combined with a logical ‘and.’ */
export type ComputeNodeTypeTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ComputeNodeTypeType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ComputeNodeTypeType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ComputeNodeTypeType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ComputeNodeTypeType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ComputeNodeTypeType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ComputeNodeTypeType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ComputeNodeTypeType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ComputeNodeTypeType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ComputeNodeTypeType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ComputeNodeTypeType>>;
};

export type ComputeNodeVariancePopulationAggregateFilter = {
  index?: InputMaybe<BigFloatFilter>;
};

export type ComputeNodeVariancePopulationAggregates = {
  __typename?: "ComputeNodeVariancePopulationAggregates";
  /** Population variance of index across the matching connection */
  index?: Maybe<Scalars["BigFloat"]>;
};

export type ComputeNodeVarianceSampleAggregateFilter = {
  index?: InputMaybe<BigFloatFilter>;
};

export type ComputeNodeVarianceSampleAggregates = {
  __typename?: "ComputeNodeVarianceSampleAggregates";
  /** Sample variance of index across the matching connection */
  index?: Maybe<Scalars["BigFloat"]>;
};

/** A connection to a list of `ComputeNode` values. */
export type ComputeNodesConnection = {
  __typename?: "ComputeNodesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ComputeNodeAggregates>;
  /** A list of _all_ `ComputeNode` primary keys. */
  computeNodeIds?: Maybe<Array<Scalars["UUID"]>>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<ComputeNodeDistinct>;
  /** A list of edges which contains the `ComputeNode` and cursor to aid in pagination. */
  edges: Array<ComputeNodesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ComputeNodeAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<ComputeNodeGroup>>;
  /** A list of `ComputeNode` objects. */
  nodes: Array<Maybe<ComputeNode>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `ComputeNode` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `ComputeNode` values. */
export type ComputeNodesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ComputeNodeGroupBy>;
  having?: InputMaybe<ComputeNodeHavingInput>;
};

/** A connection to a list of `ComputeNode` values. */
export type ComputeNodesConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<ComputeNodeGroupBy>;
  having?: InputMaybe<ComputeNodeHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `ComputeNode` edge in the connection. */
export type ComputeNodesEdge = {
  __typename?: "ComputeNodesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `ComputeNode` at the end of the edge. */
  node?: Maybe<ComputeNode>;
};

/** Methods to use when ordering `ComputeNode`. */
export enum ComputeNodesOrderBy {
  ComputationTypeAsc = "COMPUTATION_TYPE_ASC",
  ComputationTypeDesc = "COMPUTATION_TYPE_DESC",
  ComputeNodeIdAsc = "COMPUTE_NODE_ID_ASC",
  ComputeNodeIdDesc = "COMPUTE_NODE_ID_DESC",
  ComputeNodeTypeAsc = "COMPUTE_NODE_TYPE_ASC",
  ComputeNodeTypeDesc = "COMPUTE_NODE_TYPE_DESC",
  ConfigAsc = "CONFIG_ASC",
  ConfigDesc = "CONFIG_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  DatasetLinksAverageAllowStatisticsSharingAsc = "DATASET_LINKS_AVERAGE_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksAverageAllowStatisticsSharingDesc = "DATASET_LINKS_AVERAGE_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksAverageComputeNodeIdAsc = "DATASET_LINKS_AVERAGE_COMPUTE_NODE_ID_ASC",
  DatasetLinksAverageComputeNodeIdDesc = "DATASET_LINKS_AVERAGE_COMPUTE_NODE_ID_DESC",
  DatasetLinksAverageCreatedAtAsc = "DATASET_LINKS_AVERAGE_CREATED_AT_ASC",
  DatasetLinksAverageCreatedAtDesc = "DATASET_LINKS_AVERAGE_CREATED_AT_DESC",
  DatasetLinksAverageCreatedByEmailAsc = "DATASET_LINKS_AVERAGE_CREATED_BY_EMAIL_ASC",
  DatasetLinksAverageCreatedByEmailDesc = "DATASET_LINKS_AVERAGE_CREATED_BY_EMAIL_DESC",
  DatasetLinksAverageDatasetHashAsc = "DATASET_LINKS_AVERAGE_DATASET_HASH_ASC",
  DatasetLinksAverageDatasetHashDesc = "DATASET_LINKS_AVERAGE_DATASET_HASH_DESC",
  DatasetLinksAverageDatasetLinkIdAsc = "DATASET_LINKS_AVERAGE_DATASET_LINK_ID_ASC",
  DatasetLinksAverageDatasetLinkIdDesc = "DATASET_LINKS_AVERAGE_DATASET_LINK_ID_DESC",
  DatasetLinksAverageUpdatedAtAsc = "DATASET_LINKS_AVERAGE_UPDATED_AT_ASC",
  DatasetLinksAverageUpdatedAtDesc = "DATASET_LINKS_AVERAGE_UPDATED_AT_DESC",
  DatasetLinksAverageUpdatedByEmailAsc = "DATASET_LINKS_AVERAGE_UPDATED_BY_EMAIL_ASC",
  DatasetLinksAverageUpdatedByEmailDesc = "DATASET_LINKS_AVERAGE_UPDATED_BY_EMAIL_DESC",
  DatasetLinksByComputeNodeIdCountAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_COUNT_ASC",
  DatasetLinksByComputeNodeIdCountDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_COUNT_DESC",
  DatasetLinksByComputeNodeIdMaxAllowStatisticsSharingAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksByComputeNodeIdMaxAllowStatisticsSharingDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksByComputeNodeIdMaxComputeNodeIdAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_COMPUTE_NODE_ID_ASC",
  DatasetLinksByComputeNodeIdMaxComputeNodeIdDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_COMPUTE_NODE_ID_DESC",
  DatasetLinksByComputeNodeIdMaxCreatedAtAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_CREATED_AT_ASC",
  DatasetLinksByComputeNodeIdMaxCreatedAtDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_CREATED_AT_DESC",
  DatasetLinksByComputeNodeIdMaxCreatedByEmailAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_CREATED_BY_EMAIL_ASC",
  DatasetLinksByComputeNodeIdMaxCreatedByEmailDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_CREATED_BY_EMAIL_DESC",
  DatasetLinksByComputeNodeIdMaxDatasetHashAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_DATASET_HASH_ASC",
  DatasetLinksByComputeNodeIdMaxDatasetHashDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_DATASET_HASH_DESC",
  DatasetLinksByComputeNodeIdMaxDatasetLinkIdAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_DATASET_LINK_ID_ASC",
  DatasetLinksByComputeNodeIdMaxDatasetLinkIdDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_DATASET_LINK_ID_DESC",
  DatasetLinksByComputeNodeIdMaxUpdatedAtAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_UPDATED_AT_ASC",
  DatasetLinksByComputeNodeIdMaxUpdatedAtDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_UPDATED_AT_DESC",
  DatasetLinksByComputeNodeIdMaxUpdatedByEmailAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_UPDATED_BY_EMAIL_ASC",
  DatasetLinksByComputeNodeIdMaxUpdatedByEmailDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MAX_UPDATED_BY_EMAIL_DESC",
  DatasetLinksByComputeNodeIdMinAllowStatisticsSharingAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksByComputeNodeIdMinAllowStatisticsSharingDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksByComputeNodeIdMinComputeNodeIdAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_COMPUTE_NODE_ID_ASC",
  DatasetLinksByComputeNodeIdMinComputeNodeIdDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_COMPUTE_NODE_ID_DESC",
  DatasetLinksByComputeNodeIdMinCreatedAtAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_CREATED_AT_ASC",
  DatasetLinksByComputeNodeIdMinCreatedAtDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_CREATED_AT_DESC",
  DatasetLinksByComputeNodeIdMinCreatedByEmailAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_CREATED_BY_EMAIL_ASC",
  DatasetLinksByComputeNodeIdMinCreatedByEmailDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_CREATED_BY_EMAIL_DESC",
  DatasetLinksByComputeNodeIdMinDatasetHashAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_DATASET_HASH_ASC",
  DatasetLinksByComputeNodeIdMinDatasetHashDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_DATASET_HASH_DESC",
  DatasetLinksByComputeNodeIdMinDatasetLinkIdAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_DATASET_LINK_ID_ASC",
  DatasetLinksByComputeNodeIdMinDatasetLinkIdDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_DATASET_LINK_ID_DESC",
  DatasetLinksByComputeNodeIdMinUpdatedAtAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_UPDATED_AT_ASC",
  DatasetLinksByComputeNodeIdMinUpdatedAtDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_UPDATED_AT_DESC",
  DatasetLinksByComputeNodeIdMinUpdatedByEmailAsc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_UPDATED_BY_EMAIL_ASC",
  DatasetLinksByComputeNodeIdMinUpdatedByEmailDesc = "DATASET_LINKS_BY_COMPUTE_NODE_ID_MIN_UPDATED_BY_EMAIL_DESC",
  DatasetLinksCountAsc = "DATASET_LINKS_COUNT_ASC",
  DatasetLinksCountDesc = "DATASET_LINKS_COUNT_DESC",
  DatasetLinksDistinctCountAllowStatisticsSharingAsc = "DATASET_LINKS_DISTINCT_COUNT_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksDistinctCountAllowStatisticsSharingDesc = "DATASET_LINKS_DISTINCT_COUNT_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksDistinctCountComputeNodeIdAsc = "DATASET_LINKS_DISTINCT_COUNT_COMPUTE_NODE_ID_ASC",
  DatasetLinksDistinctCountComputeNodeIdDesc = "DATASET_LINKS_DISTINCT_COUNT_COMPUTE_NODE_ID_DESC",
  DatasetLinksDistinctCountCreatedAtAsc = "DATASET_LINKS_DISTINCT_COUNT_CREATED_AT_ASC",
  DatasetLinksDistinctCountCreatedAtDesc = "DATASET_LINKS_DISTINCT_COUNT_CREATED_AT_DESC",
  DatasetLinksDistinctCountCreatedByEmailAsc = "DATASET_LINKS_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  DatasetLinksDistinctCountCreatedByEmailDesc = "DATASET_LINKS_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  DatasetLinksDistinctCountDatasetHashAsc = "DATASET_LINKS_DISTINCT_COUNT_DATASET_HASH_ASC",
  DatasetLinksDistinctCountDatasetHashDesc = "DATASET_LINKS_DISTINCT_COUNT_DATASET_HASH_DESC",
  DatasetLinksDistinctCountDatasetLinkIdAsc = "DATASET_LINKS_DISTINCT_COUNT_DATASET_LINK_ID_ASC",
  DatasetLinksDistinctCountDatasetLinkIdDesc = "DATASET_LINKS_DISTINCT_COUNT_DATASET_LINK_ID_DESC",
  DatasetLinksDistinctCountUpdatedAtAsc = "DATASET_LINKS_DISTINCT_COUNT_UPDATED_AT_ASC",
  DatasetLinksDistinctCountUpdatedAtDesc = "DATASET_LINKS_DISTINCT_COUNT_UPDATED_AT_DESC",
  DatasetLinksDistinctCountUpdatedByEmailAsc = "DATASET_LINKS_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  DatasetLinksDistinctCountUpdatedByEmailDesc = "DATASET_LINKS_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  DatasetLinksMaxAllowStatisticsSharingAsc = "DATASET_LINKS_MAX_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksMaxAllowStatisticsSharingDesc = "DATASET_LINKS_MAX_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksMaxComputeNodeIdAsc = "DATASET_LINKS_MAX_COMPUTE_NODE_ID_ASC",
  DatasetLinksMaxComputeNodeIdDesc = "DATASET_LINKS_MAX_COMPUTE_NODE_ID_DESC",
  DatasetLinksMaxCreatedAtAsc = "DATASET_LINKS_MAX_CREATED_AT_ASC",
  DatasetLinksMaxCreatedAtDesc = "DATASET_LINKS_MAX_CREATED_AT_DESC",
  DatasetLinksMaxCreatedByEmailAsc = "DATASET_LINKS_MAX_CREATED_BY_EMAIL_ASC",
  DatasetLinksMaxCreatedByEmailDesc = "DATASET_LINKS_MAX_CREATED_BY_EMAIL_DESC",
  DatasetLinksMaxDatasetHashAsc = "DATASET_LINKS_MAX_DATASET_HASH_ASC",
  DatasetLinksMaxDatasetHashDesc = "DATASET_LINKS_MAX_DATASET_HASH_DESC",
  DatasetLinksMaxDatasetLinkIdAsc = "DATASET_LINKS_MAX_DATASET_LINK_ID_ASC",
  DatasetLinksMaxDatasetLinkIdDesc = "DATASET_LINKS_MAX_DATASET_LINK_ID_DESC",
  DatasetLinksMaxUpdatedAtAsc = "DATASET_LINKS_MAX_UPDATED_AT_ASC",
  DatasetLinksMaxUpdatedAtDesc = "DATASET_LINKS_MAX_UPDATED_AT_DESC",
  DatasetLinksMaxUpdatedByEmailAsc = "DATASET_LINKS_MAX_UPDATED_BY_EMAIL_ASC",
  DatasetLinksMaxUpdatedByEmailDesc = "DATASET_LINKS_MAX_UPDATED_BY_EMAIL_DESC",
  DatasetLinksMinAllowStatisticsSharingAsc = "DATASET_LINKS_MIN_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksMinAllowStatisticsSharingDesc = "DATASET_LINKS_MIN_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksMinComputeNodeIdAsc = "DATASET_LINKS_MIN_COMPUTE_NODE_ID_ASC",
  DatasetLinksMinComputeNodeIdDesc = "DATASET_LINKS_MIN_COMPUTE_NODE_ID_DESC",
  DatasetLinksMinCreatedAtAsc = "DATASET_LINKS_MIN_CREATED_AT_ASC",
  DatasetLinksMinCreatedAtDesc = "DATASET_LINKS_MIN_CREATED_AT_DESC",
  DatasetLinksMinCreatedByEmailAsc = "DATASET_LINKS_MIN_CREATED_BY_EMAIL_ASC",
  DatasetLinksMinCreatedByEmailDesc = "DATASET_LINKS_MIN_CREATED_BY_EMAIL_DESC",
  DatasetLinksMinDatasetHashAsc = "DATASET_LINKS_MIN_DATASET_HASH_ASC",
  DatasetLinksMinDatasetHashDesc = "DATASET_LINKS_MIN_DATASET_HASH_DESC",
  DatasetLinksMinDatasetLinkIdAsc = "DATASET_LINKS_MIN_DATASET_LINK_ID_ASC",
  DatasetLinksMinDatasetLinkIdDesc = "DATASET_LINKS_MIN_DATASET_LINK_ID_DESC",
  DatasetLinksMinUpdatedAtAsc = "DATASET_LINKS_MIN_UPDATED_AT_ASC",
  DatasetLinksMinUpdatedAtDesc = "DATASET_LINKS_MIN_UPDATED_AT_DESC",
  DatasetLinksMinUpdatedByEmailAsc = "DATASET_LINKS_MIN_UPDATED_BY_EMAIL_ASC",
  DatasetLinksMinUpdatedByEmailDesc = "DATASET_LINKS_MIN_UPDATED_BY_EMAIL_DESC",
  DatasetLinksStddevPopulationAllowStatisticsSharingAsc = "DATASET_LINKS_STDDEV_POPULATION_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksStddevPopulationAllowStatisticsSharingDesc = "DATASET_LINKS_STDDEV_POPULATION_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksStddevPopulationComputeNodeIdAsc = "DATASET_LINKS_STDDEV_POPULATION_COMPUTE_NODE_ID_ASC",
  DatasetLinksStddevPopulationComputeNodeIdDesc = "DATASET_LINKS_STDDEV_POPULATION_COMPUTE_NODE_ID_DESC",
  DatasetLinksStddevPopulationCreatedAtAsc = "DATASET_LINKS_STDDEV_POPULATION_CREATED_AT_ASC",
  DatasetLinksStddevPopulationCreatedAtDesc = "DATASET_LINKS_STDDEV_POPULATION_CREATED_AT_DESC",
  DatasetLinksStddevPopulationCreatedByEmailAsc = "DATASET_LINKS_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  DatasetLinksStddevPopulationCreatedByEmailDesc = "DATASET_LINKS_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  DatasetLinksStddevPopulationDatasetHashAsc = "DATASET_LINKS_STDDEV_POPULATION_DATASET_HASH_ASC",
  DatasetLinksStddevPopulationDatasetHashDesc = "DATASET_LINKS_STDDEV_POPULATION_DATASET_HASH_DESC",
  DatasetLinksStddevPopulationDatasetLinkIdAsc = "DATASET_LINKS_STDDEV_POPULATION_DATASET_LINK_ID_ASC",
  DatasetLinksStddevPopulationDatasetLinkIdDesc = "DATASET_LINKS_STDDEV_POPULATION_DATASET_LINK_ID_DESC",
  DatasetLinksStddevPopulationUpdatedAtAsc = "DATASET_LINKS_STDDEV_POPULATION_UPDATED_AT_ASC",
  DatasetLinksStddevPopulationUpdatedAtDesc = "DATASET_LINKS_STDDEV_POPULATION_UPDATED_AT_DESC",
  DatasetLinksStddevPopulationUpdatedByEmailAsc = "DATASET_LINKS_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  DatasetLinksStddevPopulationUpdatedByEmailDesc = "DATASET_LINKS_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  DatasetLinksStddevSampleAllowStatisticsSharingAsc = "DATASET_LINKS_STDDEV_SAMPLE_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksStddevSampleAllowStatisticsSharingDesc = "DATASET_LINKS_STDDEV_SAMPLE_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksStddevSampleComputeNodeIdAsc = "DATASET_LINKS_STDDEV_SAMPLE_COMPUTE_NODE_ID_ASC",
  DatasetLinksStddevSampleComputeNodeIdDesc = "DATASET_LINKS_STDDEV_SAMPLE_COMPUTE_NODE_ID_DESC",
  DatasetLinksStddevSampleCreatedAtAsc = "DATASET_LINKS_STDDEV_SAMPLE_CREATED_AT_ASC",
  DatasetLinksStddevSampleCreatedAtDesc = "DATASET_LINKS_STDDEV_SAMPLE_CREATED_AT_DESC",
  DatasetLinksStddevSampleCreatedByEmailAsc = "DATASET_LINKS_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  DatasetLinksStddevSampleCreatedByEmailDesc = "DATASET_LINKS_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  DatasetLinksStddevSampleDatasetHashAsc = "DATASET_LINKS_STDDEV_SAMPLE_DATASET_HASH_ASC",
  DatasetLinksStddevSampleDatasetHashDesc = "DATASET_LINKS_STDDEV_SAMPLE_DATASET_HASH_DESC",
  DatasetLinksStddevSampleDatasetLinkIdAsc = "DATASET_LINKS_STDDEV_SAMPLE_DATASET_LINK_ID_ASC",
  DatasetLinksStddevSampleDatasetLinkIdDesc = "DATASET_LINKS_STDDEV_SAMPLE_DATASET_LINK_ID_DESC",
  DatasetLinksStddevSampleUpdatedAtAsc = "DATASET_LINKS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  DatasetLinksStddevSampleUpdatedAtDesc = "DATASET_LINKS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  DatasetLinksStddevSampleUpdatedByEmailAsc = "DATASET_LINKS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  DatasetLinksStddevSampleUpdatedByEmailDesc = "DATASET_LINKS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  DatasetLinksSumAllowStatisticsSharingAsc = "DATASET_LINKS_SUM_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksSumAllowStatisticsSharingDesc = "DATASET_LINKS_SUM_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksSumComputeNodeIdAsc = "DATASET_LINKS_SUM_COMPUTE_NODE_ID_ASC",
  DatasetLinksSumComputeNodeIdDesc = "DATASET_LINKS_SUM_COMPUTE_NODE_ID_DESC",
  DatasetLinksSumCreatedAtAsc = "DATASET_LINKS_SUM_CREATED_AT_ASC",
  DatasetLinksSumCreatedAtDesc = "DATASET_LINKS_SUM_CREATED_AT_DESC",
  DatasetLinksSumCreatedByEmailAsc = "DATASET_LINKS_SUM_CREATED_BY_EMAIL_ASC",
  DatasetLinksSumCreatedByEmailDesc = "DATASET_LINKS_SUM_CREATED_BY_EMAIL_DESC",
  DatasetLinksSumDatasetHashAsc = "DATASET_LINKS_SUM_DATASET_HASH_ASC",
  DatasetLinksSumDatasetHashDesc = "DATASET_LINKS_SUM_DATASET_HASH_DESC",
  DatasetLinksSumDatasetLinkIdAsc = "DATASET_LINKS_SUM_DATASET_LINK_ID_ASC",
  DatasetLinksSumDatasetLinkIdDesc = "DATASET_LINKS_SUM_DATASET_LINK_ID_DESC",
  DatasetLinksSumUpdatedAtAsc = "DATASET_LINKS_SUM_UPDATED_AT_ASC",
  DatasetLinksSumUpdatedAtDesc = "DATASET_LINKS_SUM_UPDATED_AT_DESC",
  DatasetLinksSumUpdatedByEmailAsc = "DATASET_LINKS_SUM_UPDATED_BY_EMAIL_ASC",
  DatasetLinksSumUpdatedByEmailDesc = "DATASET_LINKS_SUM_UPDATED_BY_EMAIL_DESC",
  DatasetLinksVariancePopulationAllowStatisticsSharingAsc = "DATASET_LINKS_VARIANCE_POPULATION_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksVariancePopulationAllowStatisticsSharingDesc = "DATASET_LINKS_VARIANCE_POPULATION_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksVariancePopulationComputeNodeIdAsc = "DATASET_LINKS_VARIANCE_POPULATION_COMPUTE_NODE_ID_ASC",
  DatasetLinksVariancePopulationComputeNodeIdDesc = "DATASET_LINKS_VARIANCE_POPULATION_COMPUTE_NODE_ID_DESC",
  DatasetLinksVariancePopulationCreatedAtAsc = "DATASET_LINKS_VARIANCE_POPULATION_CREATED_AT_ASC",
  DatasetLinksVariancePopulationCreatedAtDesc = "DATASET_LINKS_VARIANCE_POPULATION_CREATED_AT_DESC",
  DatasetLinksVariancePopulationCreatedByEmailAsc = "DATASET_LINKS_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  DatasetLinksVariancePopulationCreatedByEmailDesc = "DATASET_LINKS_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  DatasetLinksVariancePopulationDatasetHashAsc = "DATASET_LINKS_VARIANCE_POPULATION_DATASET_HASH_ASC",
  DatasetLinksVariancePopulationDatasetHashDesc = "DATASET_LINKS_VARIANCE_POPULATION_DATASET_HASH_DESC",
  DatasetLinksVariancePopulationDatasetLinkIdAsc = "DATASET_LINKS_VARIANCE_POPULATION_DATASET_LINK_ID_ASC",
  DatasetLinksVariancePopulationDatasetLinkIdDesc = "DATASET_LINKS_VARIANCE_POPULATION_DATASET_LINK_ID_DESC",
  DatasetLinksVariancePopulationUpdatedAtAsc = "DATASET_LINKS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  DatasetLinksVariancePopulationUpdatedAtDesc = "DATASET_LINKS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  DatasetLinksVariancePopulationUpdatedByEmailAsc = "DATASET_LINKS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  DatasetLinksVariancePopulationUpdatedByEmailDesc = "DATASET_LINKS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  DatasetLinksVarianceSampleAllowStatisticsSharingAsc = "DATASET_LINKS_VARIANCE_SAMPLE_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksVarianceSampleAllowStatisticsSharingDesc = "DATASET_LINKS_VARIANCE_SAMPLE_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksVarianceSampleComputeNodeIdAsc = "DATASET_LINKS_VARIANCE_SAMPLE_COMPUTE_NODE_ID_ASC",
  DatasetLinksVarianceSampleComputeNodeIdDesc = "DATASET_LINKS_VARIANCE_SAMPLE_COMPUTE_NODE_ID_DESC",
  DatasetLinksVarianceSampleCreatedAtAsc = "DATASET_LINKS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  DatasetLinksVarianceSampleCreatedAtDesc = "DATASET_LINKS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  DatasetLinksVarianceSampleCreatedByEmailAsc = "DATASET_LINKS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  DatasetLinksVarianceSampleCreatedByEmailDesc = "DATASET_LINKS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  DatasetLinksVarianceSampleDatasetHashAsc = "DATASET_LINKS_VARIANCE_SAMPLE_DATASET_HASH_ASC",
  DatasetLinksVarianceSampleDatasetHashDesc = "DATASET_LINKS_VARIANCE_SAMPLE_DATASET_HASH_DESC",
  DatasetLinksVarianceSampleDatasetLinkIdAsc = "DATASET_LINKS_VARIANCE_SAMPLE_DATASET_LINK_ID_ASC",
  DatasetLinksVarianceSampleDatasetLinkIdDesc = "DATASET_LINKS_VARIANCE_SAMPLE_DATASET_LINK_ID_DESC",
  DatasetLinksVarianceSampleUpdatedAtAsc = "DATASET_LINKS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  DatasetLinksVarianceSampleUpdatedAtDesc = "DATASET_LINKS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  DatasetLinksVarianceSampleUpdatedByEmailAsc = "DATASET_LINKS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  DatasetLinksVarianceSampleUpdatedByEmailDesc = "DATASET_LINKS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  DataRoomByDataRoomIdCreatedAtAsc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT_ASC",
  DataRoomByDataRoomIdCreatedAtDesc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT_DESC",
  DataRoomByDataRoomIdCreatedByEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_ASC",
  DataRoomByDataRoomIdCreatedByEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_DESC",
  DataRoomByDataRoomIdDataRoomHashAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH_ASC",
  DataRoomByDataRoomIdDataRoomHashDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH_DESC",
  DataRoomByDataRoomIdDataRoomIdAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID_ASC",
  DataRoomByDataRoomIdDataRoomIdDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID_DESC",
  DataRoomByDataRoomIdDescriptionAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION_ASC",
  DataRoomByDataRoomIdDescriptionDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION_DESC",
  DataRoomByDataRoomIdEnableDevelopmentTabAsc = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomByDataRoomIdEnableDevelopmentTabDesc = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomByDataRoomIdGovernanceProtocolAsc = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL_ASC",
  DataRoomByDataRoomIdGovernanceProtocolDesc = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL_DESC",
  DataRoomByDataRoomIdMrenclaveAsc = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE_ASC",
  DataRoomByDataRoomIdMrenclaveDesc = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE_DESC",
  DataRoomByDataRoomIdNameAsc = "DATA_ROOM_BY_DATA_ROOM_ID_NAME_ASC",
  DataRoomByDataRoomIdNameDesc = "DATA_ROOM_BY_DATA_ROOM_ID_NAME_DESC",
  DataRoomByDataRoomIdOwnerEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL_ASC",
  DataRoomByDataRoomIdOwnerEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL_DESC",
  DataRoomByDataRoomIdSourceAsc = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE_ASC",
  DataRoomByDataRoomIdSourceDesc = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE_DESC",
  DataRoomByDataRoomIdUpdatedAtAsc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT_ASC",
  DataRoomByDataRoomIdUpdatedAtDesc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT_DESC",
  DataRoomByDataRoomIdUpdatedByEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_ASC",
  DataRoomByDataRoomIdUpdatedByEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_DESC",
  DataRoomIdAsc = "DATA_ROOM_ID_ASC",
  DataRoomIdDesc = "DATA_ROOM_ID_DESC",
  IndexAsc = "INDEX_ASC",
  IndexDesc = "INDEX_DESC",
  JobsAverageComputeNodeIdAsc = "JOBS_AVERAGE_COMPUTE_NODE_ID_ASC",
  JobsAverageComputeNodeIdDesc = "JOBS_AVERAGE_COMPUTE_NODE_ID_DESC",
  JobsAverageCreatedAtAsc = "JOBS_AVERAGE_CREATED_AT_ASC",
  JobsAverageCreatedAtDesc = "JOBS_AVERAGE_CREATED_AT_DESC",
  JobsAverageCreatedByEmailAsc = "JOBS_AVERAGE_CREATED_BY_EMAIL_ASC",
  JobsAverageCreatedByEmailDesc = "JOBS_AVERAGE_CREATED_BY_EMAIL_DESC",
  JobsAverageJobIdAsc = "JOBS_AVERAGE_JOB_ID_ASC",
  JobsAverageJobIdDesc = "JOBS_AVERAGE_JOB_ID_DESC",
  JobsAverageJobStartedAtAsc = "JOBS_AVERAGE_JOB_STARTED_AT_ASC",
  JobsAverageJobStartedAtDesc = "JOBS_AVERAGE_JOB_STARTED_AT_DESC",
  JobsAverageOwnerEmailAsc = "JOBS_AVERAGE_OWNER_EMAIL_ASC",
  JobsAverageOwnerEmailDesc = "JOBS_AVERAGE_OWNER_EMAIL_DESC",
  JobsAverageUpdatedAtAsc = "JOBS_AVERAGE_UPDATED_AT_ASC",
  JobsAverageUpdatedAtDesc = "JOBS_AVERAGE_UPDATED_AT_DESC",
  JobsAverageUpdatedByEmailAsc = "JOBS_AVERAGE_UPDATED_BY_EMAIL_ASC",
  JobsAverageUpdatedByEmailDesc = "JOBS_AVERAGE_UPDATED_BY_EMAIL_DESC",
  JobsByComputeNodeIdCountAsc = "JOBS_BY_COMPUTE_NODE_ID_COUNT_ASC",
  JobsByComputeNodeIdCountDesc = "JOBS_BY_COMPUTE_NODE_ID_COUNT_DESC",
  JobsByComputeNodeIdMaxComputeNodeIdAsc = "JOBS_BY_COMPUTE_NODE_ID_MAX_COMPUTE_NODE_ID_ASC",
  JobsByComputeNodeIdMaxComputeNodeIdDesc = "JOBS_BY_COMPUTE_NODE_ID_MAX_COMPUTE_NODE_ID_DESC",
  JobsByComputeNodeIdMaxCreatedAtAsc = "JOBS_BY_COMPUTE_NODE_ID_MAX_CREATED_AT_ASC",
  JobsByComputeNodeIdMaxCreatedAtDesc = "JOBS_BY_COMPUTE_NODE_ID_MAX_CREATED_AT_DESC",
  JobsByComputeNodeIdMaxCreatedByEmailAsc = "JOBS_BY_COMPUTE_NODE_ID_MAX_CREATED_BY_EMAIL_ASC",
  JobsByComputeNodeIdMaxCreatedByEmailDesc = "JOBS_BY_COMPUTE_NODE_ID_MAX_CREATED_BY_EMAIL_DESC",
  JobsByComputeNodeIdMaxJobIdAsc = "JOBS_BY_COMPUTE_NODE_ID_MAX_JOB_ID_ASC",
  JobsByComputeNodeIdMaxJobIdDesc = "JOBS_BY_COMPUTE_NODE_ID_MAX_JOB_ID_DESC",
  JobsByComputeNodeIdMaxJobStartedAtAsc = "JOBS_BY_COMPUTE_NODE_ID_MAX_JOB_STARTED_AT_ASC",
  JobsByComputeNodeIdMaxJobStartedAtDesc = "JOBS_BY_COMPUTE_NODE_ID_MAX_JOB_STARTED_AT_DESC",
  JobsByComputeNodeIdMaxOwnerEmailAsc = "JOBS_BY_COMPUTE_NODE_ID_MAX_OWNER_EMAIL_ASC",
  JobsByComputeNodeIdMaxOwnerEmailDesc = "JOBS_BY_COMPUTE_NODE_ID_MAX_OWNER_EMAIL_DESC",
  JobsByComputeNodeIdMaxUpdatedAtAsc = "JOBS_BY_COMPUTE_NODE_ID_MAX_UPDATED_AT_ASC",
  JobsByComputeNodeIdMaxUpdatedAtDesc = "JOBS_BY_COMPUTE_NODE_ID_MAX_UPDATED_AT_DESC",
  JobsByComputeNodeIdMaxUpdatedByEmailAsc = "JOBS_BY_COMPUTE_NODE_ID_MAX_UPDATED_BY_EMAIL_ASC",
  JobsByComputeNodeIdMaxUpdatedByEmailDesc = "JOBS_BY_COMPUTE_NODE_ID_MAX_UPDATED_BY_EMAIL_DESC",
  JobsByComputeNodeIdMinComputeNodeIdAsc = "JOBS_BY_COMPUTE_NODE_ID_MIN_COMPUTE_NODE_ID_ASC",
  JobsByComputeNodeIdMinComputeNodeIdDesc = "JOBS_BY_COMPUTE_NODE_ID_MIN_COMPUTE_NODE_ID_DESC",
  JobsByComputeNodeIdMinCreatedAtAsc = "JOBS_BY_COMPUTE_NODE_ID_MIN_CREATED_AT_ASC",
  JobsByComputeNodeIdMinCreatedAtDesc = "JOBS_BY_COMPUTE_NODE_ID_MIN_CREATED_AT_DESC",
  JobsByComputeNodeIdMinCreatedByEmailAsc = "JOBS_BY_COMPUTE_NODE_ID_MIN_CREATED_BY_EMAIL_ASC",
  JobsByComputeNodeIdMinCreatedByEmailDesc = "JOBS_BY_COMPUTE_NODE_ID_MIN_CREATED_BY_EMAIL_DESC",
  JobsByComputeNodeIdMinJobIdAsc = "JOBS_BY_COMPUTE_NODE_ID_MIN_JOB_ID_ASC",
  JobsByComputeNodeIdMinJobIdDesc = "JOBS_BY_COMPUTE_NODE_ID_MIN_JOB_ID_DESC",
  JobsByComputeNodeIdMinJobStartedAtAsc = "JOBS_BY_COMPUTE_NODE_ID_MIN_JOB_STARTED_AT_ASC",
  JobsByComputeNodeIdMinJobStartedAtDesc = "JOBS_BY_COMPUTE_NODE_ID_MIN_JOB_STARTED_AT_DESC",
  JobsByComputeNodeIdMinOwnerEmailAsc = "JOBS_BY_COMPUTE_NODE_ID_MIN_OWNER_EMAIL_ASC",
  JobsByComputeNodeIdMinOwnerEmailDesc = "JOBS_BY_COMPUTE_NODE_ID_MIN_OWNER_EMAIL_DESC",
  JobsByComputeNodeIdMinUpdatedAtAsc = "JOBS_BY_COMPUTE_NODE_ID_MIN_UPDATED_AT_ASC",
  JobsByComputeNodeIdMinUpdatedAtDesc = "JOBS_BY_COMPUTE_NODE_ID_MIN_UPDATED_AT_DESC",
  JobsByComputeNodeIdMinUpdatedByEmailAsc = "JOBS_BY_COMPUTE_NODE_ID_MIN_UPDATED_BY_EMAIL_ASC",
  JobsByComputeNodeIdMinUpdatedByEmailDesc = "JOBS_BY_COMPUTE_NODE_ID_MIN_UPDATED_BY_EMAIL_DESC",
  JobsCountAsc = "JOBS_COUNT_ASC",
  JobsCountDesc = "JOBS_COUNT_DESC",
  JobsDistinctCountComputeNodeIdAsc = "JOBS_DISTINCT_COUNT_COMPUTE_NODE_ID_ASC",
  JobsDistinctCountComputeNodeIdDesc = "JOBS_DISTINCT_COUNT_COMPUTE_NODE_ID_DESC",
  JobsDistinctCountCreatedAtAsc = "JOBS_DISTINCT_COUNT_CREATED_AT_ASC",
  JobsDistinctCountCreatedAtDesc = "JOBS_DISTINCT_COUNT_CREATED_AT_DESC",
  JobsDistinctCountCreatedByEmailAsc = "JOBS_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  JobsDistinctCountCreatedByEmailDesc = "JOBS_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  JobsDistinctCountJobIdAsc = "JOBS_DISTINCT_COUNT_JOB_ID_ASC",
  JobsDistinctCountJobIdDesc = "JOBS_DISTINCT_COUNT_JOB_ID_DESC",
  JobsDistinctCountJobStartedAtAsc = "JOBS_DISTINCT_COUNT_JOB_STARTED_AT_ASC",
  JobsDistinctCountJobStartedAtDesc = "JOBS_DISTINCT_COUNT_JOB_STARTED_AT_DESC",
  JobsDistinctCountOwnerEmailAsc = "JOBS_DISTINCT_COUNT_OWNER_EMAIL_ASC",
  JobsDistinctCountOwnerEmailDesc = "JOBS_DISTINCT_COUNT_OWNER_EMAIL_DESC",
  JobsDistinctCountUpdatedAtAsc = "JOBS_DISTINCT_COUNT_UPDATED_AT_ASC",
  JobsDistinctCountUpdatedAtDesc = "JOBS_DISTINCT_COUNT_UPDATED_AT_DESC",
  JobsDistinctCountUpdatedByEmailAsc = "JOBS_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  JobsDistinctCountUpdatedByEmailDesc = "JOBS_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  JobsMaxComputeNodeIdAsc = "JOBS_MAX_COMPUTE_NODE_ID_ASC",
  JobsMaxComputeNodeIdDesc = "JOBS_MAX_COMPUTE_NODE_ID_DESC",
  JobsMaxCreatedAtAsc = "JOBS_MAX_CREATED_AT_ASC",
  JobsMaxCreatedAtDesc = "JOBS_MAX_CREATED_AT_DESC",
  JobsMaxCreatedByEmailAsc = "JOBS_MAX_CREATED_BY_EMAIL_ASC",
  JobsMaxCreatedByEmailDesc = "JOBS_MAX_CREATED_BY_EMAIL_DESC",
  JobsMaxJobIdAsc = "JOBS_MAX_JOB_ID_ASC",
  JobsMaxJobIdDesc = "JOBS_MAX_JOB_ID_DESC",
  JobsMaxJobStartedAtAsc = "JOBS_MAX_JOB_STARTED_AT_ASC",
  JobsMaxJobStartedAtDesc = "JOBS_MAX_JOB_STARTED_AT_DESC",
  JobsMaxOwnerEmailAsc = "JOBS_MAX_OWNER_EMAIL_ASC",
  JobsMaxOwnerEmailDesc = "JOBS_MAX_OWNER_EMAIL_DESC",
  JobsMaxUpdatedAtAsc = "JOBS_MAX_UPDATED_AT_ASC",
  JobsMaxUpdatedAtDesc = "JOBS_MAX_UPDATED_AT_DESC",
  JobsMaxUpdatedByEmailAsc = "JOBS_MAX_UPDATED_BY_EMAIL_ASC",
  JobsMaxUpdatedByEmailDesc = "JOBS_MAX_UPDATED_BY_EMAIL_DESC",
  JobsMinComputeNodeIdAsc = "JOBS_MIN_COMPUTE_NODE_ID_ASC",
  JobsMinComputeNodeIdDesc = "JOBS_MIN_COMPUTE_NODE_ID_DESC",
  JobsMinCreatedAtAsc = "JOBS_MIN_CREATED_AT_ASC",
  JobsMinCreatedAtDesc = "JOBS_MIN_CREATED_AT_DESC",
  JobsMinCreatedByEmailAsc = "JOBS_MIN_CREATED_BY_EMAIL_ASC",
  JobsMinCreatedByEmailDesc = "JOBS_MIN_CREATED_BY_EMAIL_DESC",
  JobsMinJobIdAsc = "JOBS_MIN_JOB_ID_ASC",
  JobsMinJobIdDesc = "JOBS_MIN_JOB_ID_DESC",
  JobsMinJobStartedAtAsc = "JOBS_MIN_JOB_STARTED_AT_ASC",
  JobsMinJobStartedAtDesc = "JOBS_MIN_JOB_STARTED_AT_DESC",
  JobsMinOwnerEmailAsc = "JOBS_MIN_OWNER_EMAIL_ASC",
  JobsMinOwnerEmailDesc = "JOBS_MIN_OWNER_EMAIL_DESC",
  JobsMinUpdatedAtAsc = "JOBS_MIN_UPDATED_AT_ASC",
  JobsMinUpdatedAtDesc = "JOBS_MIN_UPDATED_AT_DESC",
  JobsMinUpdatedByEmailAsc = "JOBS_MIN_UPDATED_BY_EMAIL_ASC",
  JobsMinUpdatedByEmailDesc = "JOBS_MIN_UPDATED_BY_EMAIL_DESC",
  JobsStddevPopulationComputeNodeIdAsc = "JOBS_STDDEV_POPULATION_COMPUTE_NODE_ID_ASC",
  JobsStddevPopulationComputeNodeIdDesc = "JOBS_STDDEV_POPULATION_COMPUTE_NODE_ID_DESC",
  JobsStddevPopulationCreatedAtAsc = "JOBS_STDDEV_POPULATION_CREATED_AT_ASC",
  JobsStddevPopulationCreatedAtDesc = "JOBS_STDDEV_POPULATION_CREATED_AT_DESC",
  JobsStddevPopulationCreatedByEmailAsc = "JOBS_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  JobsStddevPopulationCreatedByEmailDesc = "JOBS_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  JobsStddevPopulationJobIdAsc = "JOBS_STDDEV_POPULATION_JOB_ID_ASC",
  JobsStddevPopulationJobIdDesc = "JOBS_STDDEV_POPULATION_JOB_ID_DESC",
  JobsStddevPopulationJobStartedAtAsc = "JOBS_STDDEV_POPULATION_JOB_STARTED_AT_ASC",
  JobsStddevPopulationJobStartedAtDesc = "JOBS_STDDEV_POPULATION_JOB_STARTED_AT_DESC",
  JobsStddevPopulationOwnerEmailAsc = "JOBS_STDDEV_POPULATION_OWNER_EMAIL_ASC",
  JobsStddevPopulationOwnerEmailDesc = "JOBS_STDDEV_POPULATION_OWNER_EMAIL_DESC",
  JobsStddevPopulationUpdatedAtAsc = "JOBS_STDDEV_POPULATION_UPDATED_AT_ASC",
  JobsStddevPopulationUpdatedAtDesc = "JOBS_STDDEV_POPULATION_UPDATED_AT_DESC",
  JobsStddevPopulationUpdatedByEmailAsc = "JOBS_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  JobsStddevPopulationUpdatedByEmailDesc = "JOBS_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  JobsStddevSampleComputeNodeIdAsc = "JOBS_STDDEV_SAMPLE_COMPUTE_NODE_ID_ASC",
  JobsStddevSampleComputeNodeIdDesc = "JOBS_STDDEV_SAMPLE_COMPUTE_NODE_ID_DESC",
  JobsStddevSampleCreatedAtAsc = "JOBS_STDDEV_SAMPLE_CREATED_AT_ASC",
  JobsStddevSampleCreatedAtDesc = "JOBS_STDDEV_SAMPLE_CREATED_AT_DESC",
  JobsStddevSampleCreatedByEmailAsc = "JOBS_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  JobsStddevSampleCreatedByEmailDesc = "JOBS_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  JobsStddevSampleJobIdAsc = "JOBS_STDDEV_SAMPLE_JOB_ID_ASC",
  JobsStddevSampleJobIdDesc = "JOBS_STDDEV_SAMPLE_JOB_ID_DESC",
  JobsStddevSampleJobStartedAtAsc = "JOBS_STDDEV_SAMPLE_JOB_STARTED_AT_ASC",
  JobsStddevSampleJobStartedAtDesc = "JOBS_STDDEV_SAMPLE_JOB_STARTED_AT_DESC",
  JobsStddevSampleOwnerEmailAsc = "JOBS_STDDEV_SAMPLE_OWNER_EMAIL_ASC",
  JobsStddevSampleOwnerEmailDesc = "JOBS_STDDEV_SAMPLE_OWNER_EMAIL_DESC",
  JobsStddevSampleUpdatedAtAsc = "JOBS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  JobsStddevSampleUpdatedAtDesc = "JOBS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  JobsStddevSampleUpdatedByEmailAsc = "JOBS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  JobsStddevSampleUpdatedByEmailDesc = "JOBS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  JobsSumComputeNodeIdAsc = "JOBS_SUM_COMPUTE_NODE_ID_ASC",
  JobsSumComputeNodeIdDesc = "JOBS_SUM_COMPUTE_NODE_ID_DESC",
  JobsSumCreatedAtAsc = "JOBS_SUM_CREATED_AT_ASC",
  JobsSumCreatedAtDesc = "JOBS_SUM_CREATED_AT_DESC",
  JobsSumCreatedByEmailAsc = "JOBS_SUM_CREATED_BY_EMAIL_ASC",
  JobsSumCreatedByEmailDesc = "JOBS_SUM_CREATED_BY_EMAIL_DESC",
  JobsSumJobIdAsc = "JOBS_SUM_JOB_ID_ASC",
  JobsSumJobIdDesc = "JOBS_SUM_JOB_ID_DESC",
  JobsSumJobStartedAtAsc = "JOBS_SUM_JOB_STARTED_AT_ASC",
  JobsSumJobStartedAtDesc = "JOBS_SUM_JOB_STARTED_AT_DESC",
  JobsSumOwnerEmailAsc = "JOBS_SUM_OWNER_EMAIL_ASC",
  JobsSumOwnerEmailDesc = "JOBS_SUM_OWNER_EMAIL_DESC",
  JobsSumUpdatedAtAsc = "JOBS_SUM_UPDATED_AT_ASC",
  JobsSumUpdatedAtDesc = "JOBS_SUM_UPDATED_AT_DESC",
  JobsSumUpdatedByEmailAsc = "JOBS_SUM_UPDATED_BY_EMAIL_ASC",
  JobsSumUpdatedByEmailDesc = "JOBS_SUM_UPDATED_BY_EMAIL_DESC",
  JobsVariancePopulationComputeNodeIdAsc = "JOBS_VARIANCE_POPULATION_COMPUTE_NODE_ID_ASC",
  JobsVariancePopulationComputeNodeIdDesc = "JOBS_VARIANCE_POPULATION_COMPUTE_NODE_ID_DESC",
  JobsVariancePopulationCreatedAtAsc = "JOBS_VARIANCE_POPULATION_CREATED_AT_ASC",
  JobsVariancePopulationCreatedAtDesc = "JOBS_VARIANCE_POPULATION_CREATED_AT_DESC",
  JobsVariancePopulationCreatedByEmailAsc = "JOBS_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  JobsVariancePopulationCreatedByEmailDesc = "JOBS_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  JobsVariancePopulationJobIdAsc = "JOBS_VARIANCE_POPULATION_JOB_ID_ASC",
  JobsVariancePopulationJobIdDesc = "JOBS_VARIANCE_POPULATION_JOB_ID_DESC",
  JobsVariancePopulationJobStartedAtAsc = "JOBS_VARIANCE_POPULATION_JOB_STARTED_AT_ASC",
  JobsVariancePopulationJobStartedAtDesc = "JOBS_VARIANCE_POPULATION_JOB_STARTED_AT_DESC",
  JobsVariancePopulationOwnerEmailAsc = "JOBS_VARIANCE_POPULATION_OWNER_EMAIL_ASC",
  JobsVariancePopulationOwnerEmailDesc = "JOBS_VARIANCE_POPULATION_OWNER_EMAIL_DESC",
  JobsVariancePopulationUpdatedAtAsc = "JOBS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  JobsVariancePopulationUpdatedAtDesc = "JOBS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  JobsVariancePopulationUpdatedByEmailAsc = "JOBS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  JobsVariancePopulationUpdatedByEmailDesc = "JOBS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  JobsVarianceSampleComputeNodeIdAsc = "JOBS_VARIANCE_SAMPLE_COMPUTE_NODE_ID_ASC",
  JobsVarianceSampleComputeNodeIdDesc = "JOBS_VARIANCE_SAMPLE_COMPUTE_NODE_ID_DESC",
  JobsVarianceSampleCreatedAtAsc = "JOBS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  JobsVarianceSampleCreatedAtDesc = "JOBS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  JobsVarianceSampleCreatedByEmailAsc = "JOBS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  JobsVarianceSampleCreatedByEmailDesc = "JOBS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  JobsVarianceSampleJobIdAsc = "JOBS_VARIANCE_SAMPLE_JOB_ID_ASC",
  JobsVarianceSampleJobIdDesc = "JOBS_VARIANCE_SAMPLE_JOB_ID_DESC",
  JobsVarianceSampleJobStartedAtAsc = "JOBS_VARIANCE_SAMPLE_JOB_STARTED_AT_ASC",
  JobsVarianceSampleJobStartedAtDesc = "JOBS_VARIANCE_SAMPLE_JOB_STARTED_AT_DESC",
  JobsVarianceSampleOwnerEmailAsc = "JOBS_VARIANCE_SAMPLE_OWNER_EMAIL_ASC",
  JobsVarianceSampleOwnerEmailDesc = "JOBS_VARIANCE_SAMPLE_OWNER_EMAIL_DESC",
  JobsVarianceSampleUpdatedAtAsc = "JOBS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  JobsVarianceSampleUpdatedAtDesc = "JOBS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  JobsVarianceSampleUpdatedByEmailAsc = "JOBS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  JobsVarianceSampleUpdatedByEmailDesc = "JOBS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  Natural = "NATURAL",
  NodeNameAsc = "NODE_NAME_ASC",
  NodeNameDesc = "NODE_NAME_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

export type Config = Node & {
  __typename?: "Config";
  computeNodesOrder?: Maybe<Array<Maybe<Scalars["String"]>>>;
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  dataNodesOrder?: Maybe<Array<Maybe<Scalars["String"]>>>;
  /** Reads a single `DataRoom` that is related to this `Config`. */
  dataRoom?: Maybe<DataRoom>;
  dataRoomId: Scalars["UUID"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  showOrganizationLogo: Scalars["Boolean"];
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type ConfigAggregates = {
  __typename?: "ConfigAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ConfigDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A condition to be used against `Config` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type ConfigCondition = {
  /** Checks for equality with the object’s `computeNodesOrder` field. */
  computeNodesOrder?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `dataNodesOrder` field. */
  dataNodesOrder?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Checks for equality with the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `showOrganizationLogo` field. */
  showOrganizationLogo?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The fields on `config` to look up the row to connect. */
export type ConfigConfigPkeyConnect = {
  dataRoomId: Scalars["UUID"];
};

/** The fields on `config` to look up the row to delete. */
export type ConfigConfigPkeyDelete = {
  dataRoomId: Scalars["UUID"];
};

/** The `config` to be created by this mutation. */
export type ConfigDataRoomIdFkeyConfigCreateInput = {
  computeNodesOrder?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataNodesOrder?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  dataRoom?: InputMaybe<ConfigDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  showOrganizationLogo?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The `dataRoom` to be created by this mutation. */
export type ConfigDataRoomIdFkeyDataRoomCreateInput = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** Input for the nested mutation of `dataRoom` in the `ConfigInput` mutation. */
export type ConfigDataRoomIdFkeyInput = {
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomHash?: InputMaybe<DataRoomDataRoomDataRoomHashKeyConnect>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomId?: InputMaybe<DataRoomDataRoomPkeyConnect>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectById?: InputMaybe<DataRoomNodeIdConnect>;
  /** A `DataRoomInput` object that will be created and connected to this object. */
  create?: InputMaybe<ConfigDataRoomIdFkeyDataRoomCreateInput>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomHash?: InputMaybe<DataRoomDataRoomDataRoomHashKeyDelete>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomId?: InputMaybe<DataRoomDataRoomPkeyDelete>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteById?: InputMaybe<DataRoomNodeIdDelete>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomHash?: InputMaybe<DataRoomOnConfigForConfigDataRoomIdFkeyUsingDataRoomDataRoomHashKeyUpdate>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomId?: InputMaybe<DataRoomOnConfigForConfigDataRoomIdFkeyUsingDataRoomPkeyUpdate>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateById?: InputMaybe<ConfigOnConfigForConfigDataRoomIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `config` in the `DataRoomInput` mutation. */
export type ConfigDataRoomIdFkeyInverseInput = {
  /** The primary key(s) for `config` for the far side of the relationship. */
  connectByDataRoomId?: InputMaybe<ConfigConfigPkeyConnect>;
  /** The primary key(s) for `config` for the far side of the relationship. */
  connectById?: InputMaybe<ConfigNodeIdConnect>;
  /** A `ConfigInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<ConfigDataRoomIdFkeyConfigCreateInput>>;
  /** The primary key(s) for `config` for the far side of the relationship. */
  deleteByDataRoomId?: InputMaybe<ConfigConfigPkeyDelete>;
  /** The primary key(s) for `config` for the far side of the relationship. */
  deleteById?: InputMaybe<ConfigNodeIdDelete>;
  /** Flag indicating whether all other `config` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `config` for the far side of the relationship. */
  updateByDataRoomId?: InputMaybe<ConfigOnConfigForConfigDataRoomIdFkeyUsingConfigPkeyUpdate>;
  /** The primary key(s) and patch data for `config` for the far side of the relationship. */
  updateById?: InputMaybe<DataRoomOnConfigForConfigDataRoomIdFkeyNodeIdUpdate>;
};

export type ConfigDistinct = {
  __typename?: "ConfigDistinct";
  /** Distinct `computeNodesOrder` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  computeNodesOrder: Array<Maybe<Array<Maybe<Scalars["String"]>>>>;
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `dataNodesOrder` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  dataNodesOrder: Array<Maybe<Array<Maybe<Scalars["String"]>>>>;
  /** Distinct `dataRoomId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  dataRoomId: Array<Scalars["UUID"]>;
  /** Distinct `showOrganizationLogo` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  showOrganizationLogo: Array<Scalars["Boolean"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type ConfigDistinctCountAggregates = {
  __typename?: "ConfigDistinctCountAggregates";
  /** Distinct count of computeNodesOrder across the matching connection */
  computeNodesOrder?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of dataNodesOrder across the matching connection */
  dataNodesOrder?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of dataRoomId across the matching connection */
  dataRoomId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of showOrganizationLogo across the matching connection */
  showOrganizationLogo?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `Config` object types. All fields are combined with a logical ‘and.’ */
export type ConfigFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ConfigFilter>>;
  /** Filter by the object’s `computeNodesOrder` field. */
  computeNodesOrder?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dataNodesOrder` field. */
  dataNodesOrder?: InputMaybe<StringListFilter>;
  /** Filter by the object’s `dataRoom` relation. */
  dataRoom?: InputMaybe<DataRoomFilter>;
  /** Filter by the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ConfigFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ConfigFilter>>;
  /** Filter by the object’s `showOrganizationLogo` field. */
  showOrganizationLogo?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type ConfigGroup = {
  __typename?: "ConfigGroup";
  /**
   * Reads and enables pagination through a set of `Config` of this `ConfigGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `ConfigGroup`. Overriding these may result in the wrong set of `Config` returned.
   */
  configs?: Maybe<ConfigsConnection>;
  /** A list of _all_ `Config` primary keys that belong to the `ConfigGroup`. */
  dataRoomIds?: Maybe<Array<Scalars["UUID"]>>;
  /** `ConfigFilter` that was used to implicitly filter `configs`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** The count of _all_ `Config` you could get from the `ConfigGroup`. */
  totalCount: Scalars["Int"];
};

export type ConfigGroupConfigsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ConfigCondition>;
  filter?: InputMaybe<ConfigFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ConfigsOrderBy>>;
};

/** Grouping methods for `Config` for usage during aggregation. */
export enum ConfigGroupBy {
  ComputeNodesOrder = "COMPUTE_NODES_ORDER",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  DataNodesOrder = "DATA_NODES_ORDER",
  DataRoomByDataRoomIdCreatedAt = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT",
  DataRoomByDataRoomIdCreatedByEmail = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL",
  DataRoomByDataRoomIdDataRoomHash = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH",
  DataRoomByDataRoomIdDataRoomId = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID",
  DataRoomByDataRoomIdDescription = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION",
  DataRoomByDataRoomIdEnableDevelopmentTab = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB",
  DataRoomByDataRoomIdGovernanceProtocol = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL",
  DataRoomByDataRoomIdMrenclave = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE",
  DataRoomByDataRoomIdName = "DATA_ROOM_BY_DATA_ROOM_ID_NAME",
  DataRoomByDataRoomIdOwnerEmail = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL",
  DataRoomByDataRoomIdSource = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE",
  DataRoomByDataRoomIdUpdatedAt = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT",
  DataRoomByDataRoomIdUpdatedByEmail = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL",
  ShowOrganizationLogo = "SHOW_ORGANIZATION_LOGO",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type ConfigHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConfigHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Config` aggregates. */
export type ConfigHavingInput = {
  AND?: InputMaybe<Array<ConfigHavingInput>>;
  OR?: InputMaybe<Array<ConfigHavingInput>>;
  average?: InputMaybe<ConfigHavingAverageInput>;
  distinctCount?: InputMaybe<ConfigHavingDistinctCountInput>;
  max?: InputMaybe<ConfigHavingMaxInput>;
  min?: InputMaybe<ConfigHavingMinInput>;
  stddevPopulation?: InputMaybe<ConfigHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ConfigHavingStddevSampleInput>;
  sum?: InputMaybe<ConfigHavingSumInput>;
  variancePopulation?: InputMaybe<ConfigHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ConfigHavingVarianceSampleInput>;
};

export type ConfigHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConfigHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConfigHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConfigHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConfigHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConfigHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConfigHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Config` */
export type ConfigInput = {
  computeNodesOrder?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataNodesOrder?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  dataRoom?: InputMaybe<ConfigDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  showOrganizationLogo?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ConfigNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `config` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type ConfigNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `config` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type ConfigOnConfigForConfigDataRoomIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `dataRoom` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `dataRoom` being updated. */
  patch: DataRoomPatch;
};

/** The fields on `config` to look up the row to update. */
export type ConfigOnConfigForConfigDataRoomIdFkeyUsingConfigPkeyUpdate = {
  dataRoomId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `config` being updated. */
  patch: UpdateConfigOnConfigForConfigDataRoomIdFkeyPatch;
};

/** Represents an update to a `Config`. Fields that are set will be updated. */
export type ConfigPatch = {
  computeNodesOrder?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataNodesOrder?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  dataRoom?: InputMaybe<ConfigDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  showOrganizationLogo?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** A connection to a list of `Config` values. */
export type ConfigsConnection = {
  __typename?: "ConfigsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ConfigAggregates>;
  /** A list of _all_ `Config` primary keys. */
  dataRoomIds?: Maybe<Array<Scalars["UUID"]>>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<ConfigDistinct>;
  /** A list of edges which contains the `Config` and cursor to aid in pagination. */
  edges: Array<ConfigsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ConfigAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<ConfigGroup>>;
  /** A list of `Config` objects. */
  nodes: Array<Maybe<Config>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Config` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `Config` values. */
export type ConfigsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ConfigGroupBy>;
  having?: InputMaybe<ConfigHavingInput>;
};

/** A connection to a list of `Config` values. */
export type ConfigsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<ConfigGroupBy>;
  having?: InputMaybe<ConfigHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `Config` edge in the connection. */
export type ConfigsEdge = {
  __typename?: "ConfigsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `Config` at the end of the edge. */
  node?: Maybe<Config>;
};

/** Methods to use when ordering `Config`. */
export enum ConfigsOrderBy {
  ComputeNodesOrderAsc = "COMPUTE_NODES_ORDER_ASC",
  ComputeNodesOrderDesc = "COMPUTE_NODES_ORDER_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  DataNodesOrderAsc = "DATA_NODES_ORDER_ASC",
  DataNodesOrderDesc = "DATA_NODES_ORDER_DESC",
  DataRoomByDataRoomIdCreatedAtAsc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT_ASC",
  DataRoomByDataRoomIdCreatedAtDesc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT_DESC",
  DataRoomByDataRoomIdCreatedByEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_ASC",
  DataRoomByDataRoomIdCreatedByEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_DESC",
  DataRoomByDataRoomIdDataRoomHashAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH_ASC",
  DataRoomByDataRoomIdDataRoomHashDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH_DESC",
  DataRoomByDataRoomIdDataRoomIdAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID_ASC",
  DataRoomByDataRoomIdDataRoomIdDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID_DESC",
  DataRoomByDataRoomIdDescriptionAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION_ASC",
  DataRoomByDataRoomIdDescriptionDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION_DESC",
  DataRoomByDataRoomIdEnableDevelopmentTabAsc = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomByDataRoomIdEnableDevelopmentTabDesc = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomByDataRoomIdGovernanceProtocolAsc = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL_ASC",
  DataRoomByDataRoomIdGovernanceProtocolDesc = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL_DESC",
  DataRoomByDataRoomIdMrenclaveAsc = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE_ASC",
  DataRoomByDataRoomIdMrenclaveDesc = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE_DESC",
  DataRoomByDataRoomIdNameAsc = "DATA_ROOM_BY_DATA_ROOM_ID_NAME_ASC",
  DataRoomByDataRoomIdNameDesc = "DATA_ROOM_BY_DATA_ROOM_ID_NAME_DESC",
  DataRoomByDataRoomIdOwnerEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL_ASC",
  DataRoomByDataRoomIdOwnerEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL_DESC",
  DataRoomByDataRoomIdSourceAsc = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE_ASC",
  DataRoomByDataRoomIdSourceDesc = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE_DESC",
  DataRoomByDataRoomIdUpdatedAtAsc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT_ASC",
  DataRoomByDataRoomIdUpdatedAtDesc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT_DESC",
  DataRoomByDataRoomIdUpdatedByEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_ASC",
  DataRoomByDataRoomIdUpdatedByEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_DESC",
  DataRoomIdAsc = "DATA_ROOM_ID_ASC",
  DataRoomIdDesc = "DATA_ROOM_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ShowOrganizationLogoAsc = "SHOW_ORGANIZATION_LOGO_ASC",
  ShowOrganizationLogoDesc = "SHOW_ORGANIZATION_LOGO_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

export type Configuration = Node & {
  __typename?: "Configuration";
  commitId?: Maybe<Scalars["String"]>;
  configurationId: Scalars["UUID"];
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** Reads a single `DataRoom` that is related to this `Configuration`. */
  dataRoom?: Maybe<DataRoom>;
  dataRoomId: Scalars["UUID"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  isDev: Scalars["Boolean"];
  isSaving?: Maybe<Scalars["Boolean"]>;
  /** Reads and enables pagination through a set of `Modification`. */
  modifications: ModificationsConnection;
  ownerEmail: Scalars["String"];
  /** Reads a single `Request` that is related to this `Configuration`. */
  request?: Maybe<Request>;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type ConfigurationModificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ModificationCondition>;
  filter?: InputMaybe<ModificationFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ModificationsOrderBy>>;
};

export type ConfigurationAggregates = {
  __typename?: "ConfigurationAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ConfigurationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A filter to be used against aggregates of `Configuration` object types. */
export type ConfigurationAggregatesFilter = {
  /** Distinct count aggregate over matching `Configuration` objects. */
  distinctCount?: InputMaybe<ConfigurationDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Configuration` object to be included within the aggregate. */
  filter?: InputMaybe<ConfigurationFilter>;
};

/**
 * A condition to be used against `Configuration` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ConfigurationCondition = {
  /** Checks for equality with the object’s `commitId` field. */
  commitId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `configurationId` field. */
  configurationId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isDev` field. */
  isDev?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `ownerEmail` field. */
  ownerEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The fields on `configuration` to look up the row to connect. */
export type ConfigurationConfigurationCommitIdKeyConnect = {
  commitId: Scalars["String"];
};

/** The fields on `configuration` to look up the row to delete. */
export type ConfigurationConfigurationCommitIdKeyDelete = {
  commitId: Scalars["String"];
};

/** The fields on `configuration` to look up the row to connect. */
export type ConfigurationConfigurationPkeyConnect = {
  configurationId: Scalars["UUID"];
};

/** The fields on `configuration` to look up the row to delete. */
export type ConfigurationConfigurationPkeyDelete = {
  configurationId: Scalars["UUID"];
};

/** The `configuration` to be created by this mutation. */
export type ConfigurationDataRoomIdFkeyConfigurationCreateInput = {
  commitId?: InputMaybe<Scalars["String"]>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<ConfigurationDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  isDev?: InputMaybe<Scalars["Boolean"]>;
  modifications?: InputMaybe<ModificationConfigurationIdFkeyInverseInput>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  request?: InputMaybe<RequestConfigurationIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The `dataRoom` to be created by this mutation. */
export type ConfigurationDataRoomIdFkeyDataRoomCreateInput = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** Input for the nested mutation of `dataRoom` in the `ConfigurationInput` mutation. */
export type ConfigurationDataRoomIdFkeyInput = {
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomHash?: InputMaybe<DataRoomDataRoomDataRoomHashKeyConnect>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomId?: InputMaybe<DataRoomDataRoomPkeyConnect>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectById?: InputMaybe<DataRoomNodeIdConnect>;
  /** A `DataRoomInput` object that will be created and connected to this object. */
  create?: InputMaybe<ConfigurationDataRoomIdFkeyDataRoomCreateInput>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomHash?: InputMaybe<DataRoomDataRoomDataRoomHashKeyDelete>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomId?: InputMaybe<DataRoomDataRoomPkeyDelete>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteById?: InputMaybe<DataRoomNodeIdDelete>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomHash?: InputMaybe<DataRoomOnConfigurationForConfigurationDataRoomIdFkeyUsingDataRoomDataRoomHashKeyUpdate>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomId?: InputMaybe<DataRoomOnConfigurationForConfigurationDataRoomIdFkeyUsingDataRoomPkeyUpdate>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateById?: InputMaybe<ConfigurationOnConfigurationForConfigurationDataRoomIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `configuration` in the `DataRoomInput` mutation. */
export type ConfigurationDataRoomIdFkeyInverseInput = {
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  connectByCommitId?: InputMaybe<
    Array<ConfigurationConfigurationCommitIdKeyConnect>
  >;
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  connectByConfigurationId?: InputMaybe<
    Array<ConfigurationConfigurationPkeyConnect>
  >;
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ConfigurationNodeIdConnect>>;
  /** A `ConfigurationInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ConfigurationDataRoomIdFkeyConfigurationCreateInput>
  >;
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  deleteByCommitId?: InputMaybe<
    Array<ConfigurationConfigurationCommitIdKeyDelete>
  >;
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  deleteByConfigurationId?: InputMaybe<
    Array<ConfigurationConfigurationPkeyDelete>
  >;
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ConfigurationNodeIdDelete>>;
  /** Flag indicating whether all other `configuration` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `configuration` for the far side of the relationship. */
  updateByCommitId?: InputMaybe<
    Array<ConfigurationOnConfigurationForConfigurationDataRoomIdFkeyUsingConfigurationCommitIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `configuration` for the far side of the relationship. */
  updateByConfigurationId?: InputMaybe<
    Array<ConfigurationOnConfigurationForConfigurationDataRoomIdFkeyUsingConfigurationPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `configuration` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DataRoomOnConfigurationForConfigurationDataRoomIdFkeyNodeIdUpdate>
  >;
};

export type ConfigurationDistinct = {
  __typename?: "ConfigurationDistinct";
  /** Distinct `commitId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  commitId: Array<Maybe<Scalars["String"]>>;
  /** Distinct `configurationId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  configurationId: Array<Scalars["UUID"]>;
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `dataRoomId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  dataRoomId: Array<Scalars["UUID"]>;
  /** Distinct `isDev` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  isDev: Array<Scalars["Boolean"]>;
  /** Distinct `ownerEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  ownerEmail: Array<Scalars["String"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type ConfigurationDistinctCountAggregateFilter = {
  commitId?: InputMaybe<BigIntFilter>;
  configurationId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  createdByEmail?: InputMaybe<BigIntFilter>;
  dataRoomId?: InputMaybe<BigIntFilter>;
  isDev?: InputMaybe<BigIntFilter>;
  ownerEmail?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  updatedByEmail?: InputMaybe<BigIntFilter>;
};

export type ConfigurationDistinctCountAggregates = {
  __typename?: "ConfigurationDistinctCountAggregates";
  /** Distinct count of commitId across the matching connection */
  commitId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of configurationId across the matching connection */
  configurationId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of dataRoomId across the matching connection */
  dataRoomId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of isDev across the matching connection */
  isDev?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of ownerEmail across the matching connection */
  ownerEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `Configuration` object types. All fields are combined with a logical ‘and.’ */
export type ConfigurationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ConfigurationFilter>>;
  /** Filter by the object’s `commitId` field. */
  commitId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `configurationId` field. */
  configurationId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dataRoom` relation. */
  dataRoom?: InputMaybe<DataRoomFilter>;
  /** Filter by the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isDev` field. */
  isDev?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `modifications` relation. */
  modifications?: InputMaybe<ConfigurationToManyModificationFilter>;
  /** Some related `modifications` exist. */
  modificationsExist?: InputMaybe<Scalars["Boolean"]>;
  /** Negates the expression. */
  not?: InputMaybe<ConfigurationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ConfigurationFilter>>;
  /** Filter by the object’s `ownerEmail` field. */
  ownerEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `request` relation. */
  request?: InputMaybe<RequestFilter>;
  /** A related `request` exists. */
  requestExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type ConfigurationGroup = {
  __typename?: "ConfigurationGroup";
  /** A list of _all_ `Configuration` primary keys that belong to the `ConfigurationGroup`. */
  configurationIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `Configuration` of this `ConfigurationGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `ConfigurationGroup`. Overriding these may result in the wrong set of `Configuration` returned.
   */
  configurations?: Maybe<ConfigurationsConnection>;
  /** `ConfigurationFilter` that was used to implicitly filter `configurations`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** The count of _all_ `Configuration` you could get from the `ConfigurationGroup`. */
  totalCount: Scalars["Int"];
};

export type ConfigurationGroupConfigurationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ConfigurationCondition>;
  filter?: InputMaybe<ConfigurationFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy>>;
};

/** Grouping methods for `Configuration` for usage during aggregation. */
export enum ConfigurationGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  DataRoomByDataRoomIdCreatedAt = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT",
  DataRoomByDataRoomIdCreatedByEmail = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL",
  DataRoomByDataRoomIdDataRoomHash = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH",
  DataRoomByDataRoomIdDataRoomId = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID",
  DataRoomByDataRoomIdDescription = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION",
  DataRoomByDataRoomIdEnableDevelopmentTab = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB",
  DataRoomByDataRoomIdGovernanceProtocol = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL",
  DataRoomByDataRoomIdMrenclave = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE",
  DataRoomByDataRoomIdName = "DATA_ROOM_BY_DATA_ROOM_ID_NAME",
  DataRoomByDataRoomIdOwnerEmail = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL",
  DataRoomByDataRoomIdSource = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE",
  DataRoomByDataRoomIdUpdatedAt = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT",
  DataRoomByDataRoomIdUpdatedByEmail = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL",
  DataRoomId = "DATA_ROOM_ID",
  IsDev = "IS_DEV",
  OwnerEmail = "OWNER_EMAIL",
  RequestByConfigurationIdConfigurationId = "REQUEST_BY_CONFIGURATION_ID_CONFIGURATION_ID",
  RequestByConfigurationIdCreatedAt = "REQUEST_BY_CONFIGURATION_ID_CREATED_AT",
  RequestByConfigurationIdCreatedByEmail = "REQUEST_BY_CONFIGURATION_ID_CREATED_BY_EMAIL",
  RequestByConfigurationIdMessage = "REQUEST_BY_CONFIGURATION_ID_MESSAGE",
  RequestByConfigurationIdRequestId = "REQUEST_BY_CONFIGURATION_ID_REQUEST_ID",
  RequestByConfigurationIdUpdatedAt = "REQUEST_BY_CONFIGURATION_ID_UPDATED_AT",
  RequestByConfigurationIdUpdatedByEmail = "REQUEST_BY_CONFIGURATION_ID_UPDATED_BY_EMAIL",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type ConfigurationHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConfigurationHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Configuration` aggregates. */
export type ConfigurationHavingInput = {
  AND?: InputMaybe<Array<ConfigurationHavingInput>>;
  OR?: InputMaybe<Array<ConfigurationHavingInput>>;
  average?: InputMaybe<ConfigurationHavingAverageInput>;
  distinctCount?: InputMaybe<ConfigurationHavingDistinctCountInput>;
  max?: InputMaybe<ConfigurationHavingMaxInput>;
  min?: InputMaybe<ConfigurationHavingMinInput>;
  stddevPopulation?: InputMaybe<ConfigurationHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ConfigurationHavingStddevSampleInput>;
  sum?: InputMaybe<ConfigurationHavingSumInput>;
  variancePopulation?: InputMaybe<ConfigurationHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ConfigurationHavingVarianceSampleInput>;
};

export type ConfigurationHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConfigurationHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConfigurationHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConfigurationHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConfigurationHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConfigurationHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ConfigurationHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Configuration` */
export type ConfigurationInput = {
  commitId?: InputMaybe<Scalars["String"]>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<ConfigurationDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  isDev?: InputMaybe<Scalars["Boolean"]>;
  modifications?: InputMaybe<ModificationConfigurationIdFkeyInverseInput>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  request?: InputMaybe<RequestConfigurationIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type ConfigurationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `configuration` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type ConfigurationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `configuration` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type ConfigurationOnConfigurationForConfigurationDataRoomIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `dataRoom` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: DataRoomPatch;
  };

/** The fields on `configuration` to look up the row to update. */
export type ConfigurationOnConfigurationForConfigurationDataRoomIdFkeyUsingConfigurationCommitIdKeyUpdate =
  {
    commitId: Scalars["String"];
    /** An object where the defined keys will be set on the `configuration` being updated. */
    patch: UpdateConfigurationOnConfigurationForConfigurationDataRoomIdFkeyPatch;
  };

/** The fields on `configuration` to look up the row to update. */
export type ConfigurationOnConfigurationForConfigurationDataRoomIdFkeyUsingConfigurationPkeyUpdate =
  {
    configurationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `configuration` being updated. */
    patch: UpdateConfigurationOnConfigurationForConfigurationDataRoomIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ConfigurationOnModificationForModificationConfigurationIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `modification` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `modification` being updated. */
    patch: ModificationPatch;
  };

/** The fields on `configuration` to look up the row to update. */
export type ConfigurationOnModificationForModificationConfigurationIdFkeyUsingConfigurationCommitIdKeyUpdate =
  {
    commitId: Scalars["String"];
    /** An object where the defined keys will be set on the `configuration` being updated. */
    patch: UpdateConfigurationOnModificationForModificationConfigurationIdFkeyPatch;
  };

/** The fields on `configuration` to look up the row to update. */
export type ConfigurationOnModificationForModificationConfigurationIdFkeyUsingConfigurationPkeyUpdate =
  {
    configurationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `configuration` being updated. */
    patch: UpdateConfigurationOnModificationForModificationConfigurationIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ConfigurationOnRequestForRequestConfigurationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `request` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `request` being updated. */
  patch: RequestPatch;
};

/** The fields on `configuration` to look up the row to update. */
export type ConfigurationOnRequestForRequestConfigurationIdFkeyUsingConfigurationCommitIdKeyUpdate =
  {
    commitId: Scalars["String"];
    /** An object where the defined keys will be set on the `configuration` being updated. */
    patch: UpdateConfigurationOnRequestForRequestConfigurationIdFkeyPatch;
  };

/** The fields on `configuration` to look up the row to update. */
export type ConfigurationOnRequestForRequestConfigurationIdFkeyUsingConfigurationPkeyUpdate =
  {
    configurationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `configuration` being updated. */
    patch: UpdateConfigurationOnRequestForRequestConfigurationIdFkeyPatch;
  };

/** Represents an update to a `Configuration`. Fields that are set will be updated. */
export type ConfigurationPatch = {
  commitId?: InputMaybe<Scalars["String"]>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<ConfigurationDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  isDev?: InputMaybe<Scalars["Boolean"]>;
  modifications?: InputMaybe<ModificationConfigurationIdFkeyInverseInput>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  request?: InputMaybe<RequestConfigurationIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against many `Modification` object types. All fields are combined with a logical ‘and.’ */
export type ConfigurationToManyModificationFilter = {
  /** Aggregates across related `Modification` match the filter criteria. */
  aggregates?: InputMaybe<ModificationAggregatesFilter>;
  /** Every related `Modification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ModificationFilter>;
  /** No related `Modification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ModificationFilter>;
  /** Some related `Modification` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ModificationFilter>;
};

/** A connection to a list of `Configuration` values. */
export type ConfigurationsConnection = {
  __typename?: "ConfigurationsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ConfigurationAggregates>;
  /** A list of _all_ `Configuration` primary keys. */
  configurationIds?: Maybe<Array<Scalars["UUID"]>>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<ConfigurationDistinct>;
  /** A list of edges which contains the `Configuration` and cursor to aid in pagination. */
  edges: Array<ConfigurationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ConfigurationAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<ConfigurationGroup>>;
  /** A list of `Configuration` objects. */
  nodes: Array<Maybe<Configuration>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Configuration` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `Configuration` values. */
export type ConfigurationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ConfigurationGroupBy>;
  having?: InputMaybe<ConfigurationHavingInput>;
};

/** A connection to a list of `Configuration` values. */
export type ConfigurationsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<ConfigurationGroupBy>;
  having?: InputMaybe<ConfigurationHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `Configuration` edge in the connection. */
export type ConfigurationsEdge = {
  __typename?: "ConfigurationsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `Configuration` at the end of the edge. */
  node?: Maybe<Configuration>;
};

/** Methods to use when ordering `Configuration`. */
export enum ConfigurationsOrderBy {
  CommitIdAsc = "COMMIT_ID_ASC",
  CommitIdDesc = "COMMIT_ID_DESC",
  ConfigurationIdAsc = "CONFIGURATION_ID_ASC",
  ConfigurationIdDesc = "CONFIGURATION_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  DataRoomByDataRoomIdCreatedAtAsc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT_ASC",
  DataRoomByDataRoomIdCreatedAtDesc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT_DESC",
  DataRoomByDataRoomIdCreatedByEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_ASC",
  DataRoomByDataRoomIdCreatedByEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_DESC",
  DataRoomByDataRoomIdDataRoomHashAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH_ASC",
  DataRoomByDataRoomIdDataRoomHashDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH_DESC",
  DataRoomByDataRoomIdDataRoomIdAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID_ASC",
  DataRoomByDataRoomIdDataRoomIdDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID_DESC",
  DataRoomByDataRoomIdDescriptionAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION_ASC",
  DataRoomByDataRoomIdDescriptionDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION_DESC",
  DataRoomByDataRoomIdEnableDevelopmentTabAsc = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomByDataRoomIdEnableDevelopmentTabDesc = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomByDataRoomIdGovernanceProtocolAsc = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL_ASC",
  DataRoomByDataRoomIdGovernanceProtocolDesc = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL_DESC",
  DataRoomByDataRoomIdMrenclaveAsc = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE_ASC",
  DataRoomByDataRoomIdMrenclaveDesc = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE_DESC",
  DataRoomByDataRoomIdNameAsc = "DATA_ROOM_BY_DATA_ROOM_ID_NAME_ASC",
  DataRoomByDataRoomIdNameDesc = "DATA_ROOM_BY_DATA_ROOM_ID_NAME_DESC",
  DataRoomByDataRoomIdOwnerEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL_ASC",
  DataRoomByDataRoomIdOwnerEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL_DESC",
  DataRoomByDataRoomIdSourceAsc = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE_ASC",
  DataRoomByDataRoomIdSourceDesc = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE_DESC",
  DataRoomByDataRoomIdUpdatedAtAsc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT_ASC",
  DataRoomByDataRoomIdUpdatedAtDesc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT_DESC",
  DataRoomByDataRoomIdUpdatedByEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_ASC",
  DataRoomByDataRoomIdUpdatedByEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_DESC",
  DataRoomIdAsc = "DATA_ROOM_ID_ASC",
  DataRoomIdDesc = "DATA_ROOM_ID_DESC",
  IsDevAsc = "IS_DEV_ASC",
  IsDevDesc = "IS_DEV_DESC",
  ModificationsAverageConfigurationIdAsc = "MODIFICATIONS_AVERAGE_CONFIGURATION_ID_ASC",
  ModificationsAverageConfigurationIdDesc = "MODIFICATIONS_AVERAGE_CONFIGURATION_ID_DESC",
  ModificationsAverageConfigAsc = "MODIFICATIONS_AVERAGE_CONFIG_ASC",
  ModificationsAverageConfigDesc = "MODIFICATIONS_AVERAGE_CONFIG_DESC",
  ModificationsAverageCreatedAtAsc = "MODIFICATIONS_AVERAGE_CREATED_AT_ASC",
  ModificationsAverageCreatedAtDesc = "MODIFICATIONS_AVERAGE_CREATED_AT_DESC",
  ModificationsAverageCreatedByEmailAsc = "MODIFICATIONS_AVERAGE_CREATED_BY_EMAIL_ASC",
  ModificationsAverageCreatedByEmailDesc = "MODIFICATIONS_AVERAGE_CREATED_BY_EMAIL_DESC",
  ModificationsAverageElementIdAsc = "MODIFICATIONS_AVERAGE_ELEMENT_ID_ASC",
  ModificationsAverageElementIdDesc = "MODIFICATIONS_AVERAGE_ELEMENT_ID_DESC",
  ModificationsAverageElementTypeAsc = "MODIFICATIONS_AVERAGE_ELEMENT_TYPE_ASC",
  ModificationsAverageElementTypeDesc = "MODIFICATIONS_AVERAGE_ELEMENT_TYPE_DESC",
  ModificationsAverageModificationAsc = "MODIFICATIONS_AVERAGE_MODIFICATION_ASC",
  ModificationsAverageModificationDesc = "MODIFICATIONS_AVERAGE_MODIFICATION_DESC",
  ModificationsAverageModificationIdAsc = "MODIFICATIONS_AVERAGE_MODIFICATION_ID_ASC",
  ModificationsAverageModificationIdDesc = "MODIFICATIONS_AVERAGE_MODIFICATION_ID_DESC",
  ModificationsAverageUpdatedAtAsc = "MODIFICATIONS_AVERAGE_UPDATED_AT_ASC",
  ModificationsAverageUpdatedAtDesc = "MODIFICATIONS_AVERAGE_UPDATED_AT_DESC",
  ModificationsAverageUpdatedByEmailAsc = "MODIFICATIONS_AVERAGE_UPDATED_BY_EMAIL_ASC",
  ModificationsAverageUpdatedByEmailDesc = "MODIFICATIONS_AVERAGE_UPDATED_BY_EMAIL_DESC",
  ModificationsByConfigurationIdCountAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_COUNT_ASC",
  ModificationsByConfigurationIdCountDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_COUNT_DESC",
  ModificationsByConfigurationIdMaxConfigurationIdAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_CONFIGURATION_ID_ASC",
  ModificationsByConfigurationIdMaxConfigurationIdDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_CONFIGURATION_ID_DESC",
  ModificationsByConfigurationIdMaxConfigAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_CONFIG_ASC",
  ModificationsByConfigurationIdMaxConfigDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_CONFIG_DESC",
  ModificationsByConfigurationIdMaxCreatedAtAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_CREATED_AT_ASC",
  ModificationsByConfigurationIdMaxCreatedAtDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_CREATED_AT_DESC",
  ModificationsByConfigurationIdMaxCreatedByEmailAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_CREATED_BY_EMAIL_ASC",
  ModificationsByConfigurationIdMaxCreatedByEmailDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_CREATED_BY_EMAIL_DESC",
  ModificationsByConfigurationIdMaxElementIdAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_ELEMENT_ID_ASC",
  ModificationsByConfigurationIdMaxElementIdDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_ELEMENT_ID_DESC",
  ModificationsByConfigurationIdMaxElementTypeAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_ELEMENT_TYPE_ASC",
  ModificationsByConfigurationIdMaxElementTypeDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_ELEMENT_TYPE_DESC",
  ModificationsByConfigurationIdMaxModificationAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_MODIFICATION_ASC",
  ModificationsByConfigurationIdMaxModificationDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_MODIFICATION_DESC",
  ModificationsByConfigurationIdMaxModificationIdAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_MODIFICATION_ID_ASC",
  ModificationsByConfigurationIdMaxModificationIdDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_MODIFICATION_ID_DESC",
  ModificationsByConfigurationIdMaxUpdatedAtAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_UPDATED_AT_ASC",
  ModificationsByConfigurationIdMaxUpdatedAtDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_UPDATED_AT_DESC",
  ModificationsByConfigurationIdMaxUpdatedByEmailAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_UPDATED_BY_EMAIL_ASC",
  ModificationsByConfigurationIdMaxUpdatedByEmailDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MAX_UPDATED_BY_EMAIL_DESC",
  ModificationsByConfigurationIdMinConfigurationIdAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_CONFIGURATION_ID_ASC",
  ModificationsByConfigurationIdMinConfigurationIdDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_CONFIGURATION_ID_DESC",
  ModificationsByConfigurationIdMinConfigAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_CONFIG_ASC",
  ModificationsByConfigurationIdMinConfigDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_CONFIG_DESC",
  ModificationsByConfigurationIdMinCreatedAtAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_CREATED_AT_ASC",
  ModificationsByConfigurationIdMinCreatedAtDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_CREATED_AT_DESC",
  ModificationsByConfigurationIdMinCreatedByEmailAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_CREATED_BY_EMAIL_ASC",
  ModificationsByConfigurationIdMinCreatedByEmailDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_CREATED_BY_EMAIL_DESC",
  ModificationsByConfigurationIdMinElementIdAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_ELEMENT_ID_ASC",
  ModificationsByConfigurationIdMinElementIdDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_ELEMENT_ID_DESC",
  ModificationsByConfigurationIdMinElementTypeAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_ELEMENT_TYPE_ASC",
  ModificationsByConfigurationIdMinElementTypeDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_ELEMENT_TYPE_DESC",
  ModificationsByConfigurationIdMinModificationAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_MODIFICATION_ASC",
  ModificationsByConfigurationIdMinModificationDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_MODIFICATION_DESC",
  ModificationsByConfigurationIdMinModificationIdAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_MODIFICATION_ID_ASC",
  ModificationsByConfigurationIdMinModificationIdDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_MODIFICATION_ID_DESC",
  ModificationsByConfigurationIdMinUpdatedAtAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_UPDATED_AT_ASC",
  ModificationsByConfigurationIdMinUpdatedAtDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_UPDATED_AT_DESC",
  ModificationsByConfigurationIdMinUpdatedByEmailAsc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_UPDATED_BY_EMAIL_ASC",
  ModificationsByConfigurationIdMinUpdatedByEmailDesc = "MODIFICATIONS_BY_CONFIGURATION_ID_MIN_UPDATED_BY_EMAIL_DESC",
  ModificationsCountAsc = "MODIFICATIONS_COUNT_ASC",
  ModificationsCountDesc = "MODIFICATIONS_COUNT_DESC",
  ModificationsDistinctCountConfigurationIdAsc = "MODIFICATIONS_DISTINCT_COUNT_CONFIGURATION_ID_ASC",
  ModificationsDistinctCountConfigurationIdDesc = "MODIFICATIONS_DISTINCT_COUNT_CONFIGURATION_ID_DESC",
  ModificationsDistinctCountConfigAsc = "MODIFICATIONS_DISTINCT_COUNT_CONFIG_ASC",
  ModificationsDistinctCountConfigDesc = "MODIFICATIONS_DISTINCT_COUNT_CONFIG_DESC",
  ModificationsDistinctCountCreatedAtAsc = "MODIFICATIONS_DISTINCT_COUNT_CREATED_AT_ASC",
  ModificationsDistinctCountCreatedAtDesc = "MODIFICATIONS_DISTINCT_COUNT_CREATED_AT_DESC",
  ModificationsDistinctCountCreatedByEmailAsc = "MODIFICATIONS_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  ModificationsDistinctCountCreatedByEmailDesc = "MODIFICATIONS_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  ModificationsDistinctCountElementIdAsc = "MODIFICATIONS_DISTINCT_COUNT_ELEMENT_ID_ASC",
  ModificationsDistinctCountElementIdDesc = "MODIFICATIONS_DISTINCT_COUNT_ELEMENT_ID_DESC",
  ModificationsDistinctCountElementTypeAsc = "MODIFICATIONS_DISTINCT_COUNT_ELEMENT_TYPE_ASC",
  ModificationsDistinctCountElementTypeDesc = "MODIFICATIONS_DISTINCT_COUNT_ELEMENT_TYPE_DESC",
  ModificationsDistinctCountModificationAsc = "MODIFICATIONS_DISTINCT_COUNT_MODIFICATION_ASC",
  ModificationsDistinctCountModificationDesc = "MODIFICATIONS_DISTINCT_COUNT_MODIFICATION_DESC",
  ModificationsDistinctCountModificationIdAsc = "MODIFICATIONS_DISTINCT_COUNT_MODIFICATION_ID_ASC",
  ModificationsDistinctCountModificationIdDesc = "MODIFICATIONS_DISTINCT_COUNT_MODIFICATION_ID_DESC",
  ModificationsDistinctCountUpdatedAtAsc = "MODIFICATIONS_DISTINCT_COUNT_UPDATED_AT_ASC",
  ModificationsDistinctCountUpdatedAtDesc = "MODIFICATIONS_DISTINCT_COUNT_UPDATED_AT_DESC",
  ModificationsDistinctCountUpdatedByEmailAsc = "MODIFICATIONS_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  ModificationsDistinctCountUpdatedByEmailDesc = "MODIFICATIONS_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  ModificationsMaxConfigurationIdAsc = "MODIFICATIONS_MAX_CONFIGURATION_ID_ASC",
  ModificationsMaxConfigurationIdDesc = "MODIFICATIONS_MAX_CONFIGURATION_ID_DESC",
  ModificationsMaxConfigAsc = "MODIFICATIONS_MAX_CONFIG_ASC",
  ModificationsMaxConfigDesc = "MODIFICATIONS_MAX_CONFIG_DESC",
  ModificationsMaxCreatedAtAsc = "MODIFICATIONS_MAX_CREATED_AT_ASC",
  ModificationsMaxCreatedAtDesc = "MODIFICATIONS_MAX_CREATED_AT_DESC",
  ModificationsMaxCreatedByEmailAsc = "MODIFICATIONS_MAX_CREATED_BY_EMAIL_ASC",
  ModificationsMaxCreatedByEmailDesc = "MODIFICATIONS_MAX_CREATED_BY_EMAIL_DESC",
  ModificationsMaxElementIdAsc = "MODIFICATIONS_MAX_ELEMENT_ID_ASC",
  ModificationsMaxElementIdDesc = "MODIFICATIONS_MAX_ELEMENT_ID_DESC",
  ModificationsMaxElementTypeAsc = "MODIFICATIONS_MAX_ELEMENT_TYPE_ASC",
  ModificationsMaxElementTypeDesc = "MODIFICATIONS_MAX_ELEMENT_TYPE_DESC",
  ModificationsMaxModificationAsc = "MODIFICATIONS_MAX_MODIFICATION_ASC",
  ModificationsMaxModificationDesc = "MODIFICATIONS_MAX_MODIFICATION_DESC",
  ModificationsMaxModificationIdAsc = "MODIFICATIONS_MAX_MODIFICATION_ID_ASC",
  ModificationsMaxModificationIdDesc = "MODIFICATIONS_MAX_MODIFICATION_ID_DESC",
  ModificationsMaxUpdatedAtAsc = "MODIFICATIONS_MAX_UPDATED_AT_ASC",
  ModificationsMaxUpdatedAtDesc = "MODIFICATIONS_MAX_UPDATED_AT_DESC",
  ModificationsMaxUpdatedByEmailAsc = "MODIFICATIONS_MAX_UPDATED_BY_EMAIL_ASC",
  ModificationsMaxUpdatedByEmailDesc = "MODIFICATIONS_MAX_UPDATED_BY_EMAIL_DESC",
  ModificationsMinConfigurationIdAsc = "MODIFICATIONS_MIN_CONFIGURATION_ID_ASC",
  ModificationsMinConfigurationIdDesc = "MODIFICATIONS_MIN_CONFIGURATION_ID_DESC",
  ModificationsMinConfigAsc = "MODIFICATIONS_MIN_CONFIG_ASC",
  ModificationsMinConfigDesc = "MODIFICATIONS_MIN_CONFIG_DESC",
  ModificationsMinCreatedAtAsc = "MODIFICATIONS_MIN_CREATED_AT_ASC",
  ModificationsMinCreatedAtDesc = "MODIFICATIONS_MIN_CREATED_AT_DESC",
  ModificationsMinCreatedByEmailAsc = "MODIFICATIONS_MIN_CREATED_BY_EMAIL_ASC",
  ModificationsMinCreatedByEmailDesc = "MODIFICATIONS_MIN_CREATED_BY_EMAIL_DESC",
  ModificationsMinElementIdAsc = "MODIFICATIONS_MIN_ELEMENT_ID_ASC",
  ModificationsMinElementIdDesc = "MODIFICATIONS_MIN_ELEMENT_ID_DESC",
  ModificationsMinElementTypeAsc = "MODIFICATIONS_MIN_ELEMENT_TYPE_ASC",
  ModificationsMinElementTypeDesc = "MODIFICATIONS_MIN_ELEMENT_TYPE_DESC",
  ModificationsMinModificationAsc = "MODIFICATIONS_MIN_MODIFICATION_ASC",
  ModificationsMinModificationDesc = "MODIFICATIONS_MIN_MODIFICATION_DESC",
  ModificationsMinModificationIdAsc = "MODIFICATIONS_MIN_MODIFICATION_ID_ASC",
  ModificationsMinModificationIdDesc = "MODIFICATIONS_MIN_MODIFICATION_ID_DESC",
  ModificationsMinUpdatedAtAsc = "MODIFICATIONS_MIN_UPDATED_AT_ASC",
  ModificationsMinUpdatedAtDesc = "MODIFICATIONS_MIN_UPDATED_AT_DESC",
  ModificationsMinUpdatedByEmailAsc = "MODIFICATIONS_MIN_UPDATED_BY_EMAIL_ASC",
  ModificationsMinUpdatedByEmailDesc = "MODIFICATIONS_MIN_UPDATED_BY_EMAIL_DESC",
  ModificationsStddevPopulationConfigurationIdAsc = "MODIFICATIONS_STDDEV_POPULATION_CONFIGURATION_ID_ASC",
  ModificationsStddevPopulationConfigurationIdDesc = "MODIFICATIONS_STDDEV_POPULATION_CONFIGURATION_ID_DESC",
  ModificationsStddevPopulationConfigAsc = "MODIFICATIONS_STDDEV_POPULATION_CONFIG_ASC",
  ModificationsStddevPopulationConfigDesc = "MODIFICATIONS_STDDEV_POPULATION_CONFIG_DESC",
  ModificationsStddevPopulationCreatedAtAsc = "MODIFICATIONS_STDDEV_POPULATION_CREATED_AT_ASC",
  ModificationsStddevPopulationCreatedAtDesc = "MODIFICATIONS_STDDEV_POPULATION_CREATED_AT_DESC",
  ModificationsStddevPopulationCreatedByEmailAsc = "MODIFICATIONS_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  ModificationsStddevPopulationCreatedByEmailDesc = "MODIFICATIONS_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  ModificationsStddevPopulationElementIdAsc = "MODIFICATIONS_STDDEV_POPULATION_ELEMENT_ID_ASC",
  ModificationsStddevPopulationElementIdDesc = "MODIFICATIONS_STDDEV_POPULATION_ELEMENT_ID_DESC",
  ModificationsStddevPopulationElementTypeAsc = "MODIFICATIONS_STDDEV_POPULATION_ELEMENT_TYPE_ASC",
  ModificationsStddevPopulationElementTypeDesc = "MODIFICATIONS_STDDEV_POPULATION_ELEMENT_TYPE_DESC",
  ModificationsStddevPopulationModificationAsc = "MODIFICATIONS_STDDEV_POPULATION_MODIFICATION_ASC",
  ModificationsStddevPopulationModificationDesc = "MODIFICATIONS_STDDEV_POPULATION_MODIFICATION_DESC",
  ModificationsStddevPopulationModificationIdAsc = "MODIFICATIONS_STDDEV_POPULATION_MODIFICATION_ID_ASC",
  ModificationsStddevPopulationModificationIdDesc = "MODIFICATIONS_STDDEV_POPULATION_MODIFICATION_ID_DESC",
  ModificationsStddevPopulationUpdatedAtAsc = "MODIFICATIONS_STDDEV_POPULATION_UPDATED_AT_ASC",
  ModificationsStddevPopulationUpdatedAtDesc = "MODIFICATIONS_STDDEV_POPULATION_UPDATED_AT_DESC",
  ModificationsStddevPopulationUpdatedByEmailAsc = "MODIFICATIONS_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  ModificationsStddevPopulationUpdatedByEmailDesc = "MODIFICATIONS_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  ModificationsStddevSampleConfigurationIdAsc = "MODIFICATIONS_STDDEV_SAMPLE_CONFIGURATION_ID_ASC",
  ModificationsStddevSampleConfigurationIdDesc = "MODIFICATIONS_STDDEV_SAMPLE_CONFIGURATION_ID_DESC",
  ModificationsStddevSampleConfigAsc = "MODIFICATIONS_STDDEV_SAMPLE_CONFIG_ASC",
  ModificationsStddevSampleConfigDesc = "MODIFICATIONS_STDDEV_SAMPLE_CONFIG_DESC",
  ModificationsStddevSampleCreatedAtAsc = "MODIFICATIONS_STDDEV_SAMPLE_CREATED_AT_ASC",
  ModificationsStddevSampleCreatedAtDesc = "MODIFICATIONS_STDDEV_SAMPLE_CREATED_AT_DESC",
  ModificationsStddevSampleCreatedByEmailAsc = "MODIFICATIONS_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  ModificationsStddevSampleCreatedByEmailDesc = "MODIFICATIONS_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  ModificationsStddevSampleElementIdAsc = "MODIFICATIONS_STDDEV_SAMPLE_ELEMENT_ID_ASC",
  ModificationsStddevSampleElementIdDesc = "MODIFICATIONS_STDDEV_SAMPLE_ELEMENT_ID_DESC",
  ModificationsStddevSampleElementTypeAsc = "MODIFICATIONS_STDDEV_SAMPLE_ELEMENT_TYPE_ASC",
  ModificationsStddevSampleElementTypeDesc = "MODIFICATIONS_STDDEV_SAMPLE_ELEMENT_TYPE_DESC",
  ModificationsStddevSampleModificationAsc = "MODIFICATIONS_STDDEV_SAMPLE_MODIFICATION_ASC",
  ModificationsStddevSampleModificationDesc = "MODIFICATIONS_STDDEV_SAMPLE_MODIFICATION_DESC",
  ModificationsStddevSampleModificationIdAsc = "MODIFICATIONS_STDDEV_SAMPLE_MODIFICATION_ID_ASC",
  ModificationsStddevSampleModificationIdDesc = "MODIFICATIONS_STDDEV_SAMPLE_MODIFICATION_ID_DESC",
  ModificationsStddevSampleUpdatedAtAsc = "MODIFICATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  ModificationsStddevSampleUpdatedAtDesc = "MODIFICATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  ModificationsStddevSampleUpdatedByEmailAsc = "MODIFICATIONS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  ModificationsStddevSampleUpdatedByEmailDesc = "MODIFICATIONS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  ModificationsSumConfigurationIdAsc = "MODIFICATIONS_SUM_CONFIGURATION_ID_ASC",
  ModificationsSumConfigurationIdDesc = "MODIFICATIONS_SUM_CONFIGURATION_ID_DESC",
  ModificationsSumConfigAsc = "MODIFICATIONS_SUM_CONFIG_ASC",
  ModificationsSumConfigDesc = "MODIFICATIONS_SUM_CONFIG_DESC",
  ModificationsSumCreatedAtAsc = "MODIFICATIONS_SUM_CREATED_AT_ASC",
  ModificationsSumCreatedAtDesc = "MODIFICATIONS_SUM_CREATED_AT_DESC",
  ModificationsSumCreatedByEmailAsc = "MODIFICATIONS_SUM_CREATED_BY_EMAIL_ASC",
  ModificationsSumCreatedByEmailDesc = "MODIFICATIONS_SUM_CREATED_BY_EMAIL_DESC",
  ModificationsSumElementIdAsc = "MODIFICATIONS_SUM_ELEMENT_ID_ASC",
  ModificationsSumElementIdDesc = "MODIFICATIONS_SUM_ELEMENT_ID_DESC",
  ModificationsSumElementTypeAsc = "MODIFICATIONS_SUM_ELEMENT_TYPE_ASC",
  ModificationsSumElementTypeDesc = "MODIFICATIONS_SUM_ELEMENT_TYPE_DESC",
  ModificationsSumModificationAsc = "MODIFICATIONS_SUM_MODIFICATION_ASC",
  ModificationsSumModificationDesc = "MODIFICATIONS_SUM_MODIFICATION_DESC",
  ModificationsSumModificationIdAsc = "MODIFICATIONS_SUM_MODIFICATION_ID_ASC",
  ModificationsSumModificationIdDesc = "MODIFICATIONS_SUM_MODIFICATION_ID_DESC",
  ModificationsSumUpdatedAtAsc = "MODIFICATIONS_SUM_UPDATED_AT_ASC",
  ModificationsSumUpdatedAtDesc = "MODIFICATIONS_SUM_UPDATED_AT_DESC",
  ModificationsSumUpdatedByEmailAsc = "MODIFICATIONS_SUM_UPDATED_BY_EMAIL_ASC",
  ModificationsSumUpdatedByEmailDesc = "MODIFICATIONS_SUM_UPDATED_BY_EMAIL_DESC",
  ModificationsVariancePopulationConfigurationIdAsc = "MODIFICATIONS_VARIANCE_POPULATION_CONFIGURATION_ID_ASC",
  ModificationsVariancePopulationConfigurationIdDesc = "MODIFICATIONS_VARIANCE_POPULATION_CONFIGURATION_ID_DESC",
  ModificationsVariancePopulationConfigAsc = "MODIFICATIONS_VARIANCE_POPULATION_CONFIG_ASC",
  ModificationsVariancePopulationConfigDesc = "MODIFICATIONS_VARIANCE_POPULATION_CONFIG_DESC",
  ModificationsVariancePopulationCreatedAtAsc = "MODIFICATIONS_VARIANCE_POPULATION_CREATED_AT_ASC",
  ModificationsVariancePopulationCreatedAtDesc = "MODIFICATIONS_VARIANCE_POPULATION_CREATED_AT_DESC",
  ModificationsVariancePopulationCreatedByEmailAsc = "MODIFICATIONS_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  ModificationsVariancePopulationCreatedByEmailDesc = "MODIFICATIONS_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  ModificationsVariancePopulationElementIdAsc = "MODIFICATIONS_VARIANCE_POPULATION_ELEMENT_ID_ASC",
  ModificationsVariancePopulationElementIdDesc = "MODIFICATIONS_VARIANCE_POPULATION_ELEMENT_ID_DESC",
  ModificationsVariancePopulationElementTypeAsc = "MODIFICATIONS_VARIANCE_POPULATION_ELEMENT_TYPE_ASC",
  ModificationsVariancePopulationElementTypeDesc = "MODIFICATIONS_VARIANCE_POPULATION_ELEMENT_TYPE_DESC",
  ModificationsVariancePopulationModificationAsc = "MODIFICATIONS_VARIANCE_POPULATION_MODIFICATION_ASC",
  ModificationsVariancePopulationModificationDesc = "MODIFICATIONS_VARIANCE_POPULATION_MODIFICATION_DESC",
  ModificationsVariancePopulationModificationIdAsc = "MODIFICATIONS_VARIANCE_POPULATION_MODIFICATION_ID_ASC",
  ModificationsVariancePopulationModificationIdDesc = "MODIFICATIONS_VARIANCE_POPULATION_MODIFICATION_ID_DESC",
  ModificationsVariancePopulationUpdatedAtAsc = "MODIFICATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  ModificationsVariancePopulationUpdatedAtDesc = "MODIFICATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  ModificationsVariancePopulationUpdatedByEmailAsc = "MODIFICATIONS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  ModificationsVariancePopulationUpdatedByEmailDesc = "MODIFICATIONS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  ModificationsVarianceSampleConfigurationIdAsc = "MODIFICATIONS_VARIANCE_SAMPLE_CONFIGURATION_ID_ASC",
  ModificationsVarianceSampleConfigurationIdDesc = "MODIFICATIONS_VARIANCE_SAMPLE_CONFIGURATION_ID_DESC",
  ModificationsVarianceSampleConfigAsc = "MODIFICATIONS_VARIANCE_SAMPLE_CONFIG_ASC",
  ModificationsVarianceSampleConfigDesc = "MODIFICATIONS_VARIANCE_SAMPLE_CONFIG_DESC",
  ModificationsVarianceSampleCreatedAtAsc = "MODIFICATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  ModificationsVarianceSampleCreatedAtDesc = "MODIFICATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  ModificationsVarianceSampleCreatedByEmailAsc = "MODIFICATIONS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  ModificationsVarianceSampleCreatedByEmailDesc = "MODIFICATIONS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  ModificationsVarianceSampleElementIdAsc = "MODIFICATIONS_VARIANCE_SAMPLE_ELEMENT_ID_ASC",
  ModificationsVarianceSampleElementIdDesc = "MODIFICATIONS_VARIANCE_SAMPLE_ELEMENT_ID_DESC",
  ModificationsVarianceSampleElementTypeAsc = "MODIFICATIONS_VARIANCE_SAMPLE_ELEMENT_TYPE_ASC",
  ModificationsVarianceSampleElementTypeDesc = "MODIFICATIONS_VARIANCE_SAMPLE_ELEMENT_TYPE_DESC",
  ModificationsVarianceSampleModificationAsc = "MODIFICATIONS_VARIANCE_SAMPLE_MODIFICATION_ASC",
  ModificationsVarianceSampleModificationDesc = "MODIFICATIONS_VARIANCE_SAMPLE_MODIFICATION_DESC",
  ModificationsVarianceSampleModificationIdAsc = "MODIFICATIONS_VARIANCE_SAMPLE_MODIFICATION_ID_ASC",
  ModificationsVarianceSampleModificationIdDesc = "MODIFICATIONS_VARIANCE_SAMPLE_MODIFICATION_ID_DESC",
  ModificationsVarianceSampleUpdatedAtAsc = "MODIFICATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  ModificationsVarianceSampleUpdatedAtDesc = "MODIFICATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  ModificationsVarianceSampleUpdatedByEmailAsc = "MODIFICATIONS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  ModificationsVarianceSampleUpdatedByEmailDesc = "MODIFICATIONS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  Natural = "NATURAL",
  OwnerEmailAsc = "OWNER_EMAIL_ASC",
  OwnerEmailDesc = "OWNER_EMAIL_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RequestByConfigurationIdConfigurationIdAsc = "REQUEST_BY_CONFIGURATION_ID_CONFIGURATION_ID_ASC",
  RequestByConfigurationIdConfigurationIdDesc = "REQUEST_BY_CONFIGURATION_ID_CONFIGURATION_ID_DESC",
  RequestByConfigurationIdCreatedAtAsc = "REQUEST_BY_CONFIGURATION_ID_CREATED_AT_ASC",
  RequestByConfigurationIdCreatedAtDesc = "REQUEST_BY_CONFIGURATION_ID_CREATED_AT_DESC",
  RequestByConfigurationIdCreatedByEmailAsc = "REQUEST_BY_CONFIGURATION_ID_CREATED_BY_EMAIL_ASC",
  RequestByConfigurationIdCreatedByEmailDesc = "REQUEST_BY_CONFIGURATION_ID_CREATED_BY_EMAIL_DESC",
  RequestByConfigurationIdMessageAsc = "REQUEST_BY_CONFIGURATION_ID_MESSAGE_ASC",
  RequestByConfigurationIdMessageDesc = "REQUEST_BY_CONFIGURATION_ID_MESSAGE_DESC",
  RequestByConfigurationIdRequestIdAsc = "REQUEST_BY_CONFIGURATION_ID_REQUEST_ID_ASC",
  RequestByConfigurationIdRequestIdDesc = "REQUEST_BY_CONFIGURATION_ID_REQUEST_ID_DESC",
  RequestByConfigurationIdUpdatedAtAsc = "REQUEST_BY_CONFIGURATION_ID_UPDATED_AT_ASC",
  RequestByConfigurationIdUpdatedAtDesc = "REQUEST_BY_CONFIGURATION_ID_UPDATED_AT_DESC",
  RequestByConfigurationIdUpdatedByEmailAsc = "REQUEST_BY_CONFIGURATION_ID_UPDATED_BY_EMAIL_ASC",
  RequestByConfigurationIdUpdatedByEmailDesc = "REQUEST_BY_CONFIGURATION_ID_UPDATED_BY_EMAIL_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

/** All input for the create `AttestationSpecificationAsElement` mutation. */
export type CreateAttestationSpecificationAsElementInput = {
  /** The `AttestationSpecificationAsElement` to be created by this mutation. */
  attestationSpecificationAsElement: AttestationSpecificationAsElementInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our create `AttestationSpecificationAsElement` mutation. */
export type CreateAttestationSpecificationAsElementPayload = {
  __typename?: "CreateAttestationSpecificationAsElementPayload";
  /** The `AttestationSpecificationAsElement` that was created by this mutation. */
  attestationSpecificationAsElement?: Maybe<AttestationSpecificationAsElement>;
  /** An edge for our `AttestationSpecificationAsElement`. May be used by Relay 1. */
  attestationSpecificationAsElementEdge?: Maybe<AttestationSpecificationAsElementsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Modification` that is related to this `AttestationSpecificationAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `AttestationSpecificationAsElement` mutation. */
export type CreateAttestationSpecificationAsElementPayloadAttestationSpecificationAsElementEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AttestationSpecificationAsElementsOrderBy>>;
  };

/** All input for the create `AuthenticationMethodAsElement` mutation. */
export type CreateAuthenticationMethodAsElementInput = {
  /** The `AuthenticationMethodAsElement` to be created by this mutation. */
  authenticationMethodAsElement: AuthenticationMethodAsElementInput;
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our create `AuthenticationMethodAsElement` mutation. */
export type CreateAuthenticationMethodAsElementPayload = {
  __typename?: "CreateAuthenticationMethodAsElementPayload";
  /** The `AuthenticationMethodAsElement` that was created by this mutation. */
  authenticationMethodAsElement?: Maybe<AuthenticationMethodAsElement>;
  /** An edge for our `AuthenticationMethodAsElement`. May be used by Relay 1. */
  authenticationMethodAsElementEdge?: Maybe<AuthenticationMethodAsElementsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Modification` that is related to this `AuthenticationMethodAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `AuthenticationMethodAsElement` mutation. */
export type CreateAuthenticationMethodAsElementPayloadAuthenticationMethodAsElementEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AuthenticationMethodAsElementsOrderBy>>;
  };

/** All input for the create `ComputeNodeAsElement` mutation. */
export type CreateComputeNodeAsElementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `ComputeNodeAsElement` to be created by this mutation. */
  computeNodeAsElement: ComputeNodeAsElementInput;
};

/** The output of our create `ComputeNodeAsElement` mutation. */
export type CreateComputeNodeAsElementPayload = {
  __typename?: "CreateComputeNodeAsElementPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `ComputeNodeAsElement` that was created by this mutation. */
  computeNodeAsElement?: Maybe<ComputeNodeAsElement>;
  /** An edge for our `ComputeNodeAsElement`. May be used by Relay 1. */
  computeNodeAsElementEdge?: Maybe<ComputeNodeAsElementsEdge>;
  /** Reads a single `Modification` that is related to this `ComputeNodeAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `ComputeNodeAsElement` mutation. */
export type CreateComputeNodeAsElementPayloadComputeNodeAsElementEdgeArgs = {
  orderBy?: InputMaybe<Array<ComputeNodeAsElementsOrderBy>>;
};

/** All input for the create `ComputeNode` mutation. */
export type CreateComputeNodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `ComputeNode` to be created by this mutation. */
  computeNode: ComputeNodeInput;
};

/** The output of our create `ComputeNode` mutation. */
export type CreateComputeNodePayload = {
  __typename?: "CreateComputeNodePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `ComputeNode` that was created by this mutation. */
  computeNode?: Maybe<ComputeNode>;
  /** An edge for our `ComputeNode`. May be used by Relay 1. */
  computeNodeEdge?: Maybe<ComputeNodesEdge>;
  /** Reads a single `DataRoom` that is related to this `ComputeNode`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `ComputeNode` mutation. */
export type CreateComputeNodePayloadComputeNodeEdgeArgs = {
  orderBy?: InputMaybe<Array<ComputeNodesOrderBy>>;
};

/** All input for the create `Config` mutation. */
export type CreateConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Config` to be created by this mutation. */
  config: ConfigInput;
};

/** The output of our create `Config` mutation. */
export type CreateConfigPayload = {
  __typename?: "CreateConfigPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Config` that was created by this mutation. */
  config?: Maybe<Config>;
  /** An edge for our `Config`. May be used by Relay 1. */
  configEdge?: Maybe<ConfigsEdge>;
  /** Reads a single `DataRoom` that is related to this `Config`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `Config` mutation. */
export type CreateConfigPayloadConfigEdgeArgs = {
  orderBy?: InputMaybe<Array<ConfigsOrderBy>>;
};

/** All input for the create `Configuration` mutation. */
export type CreateConfigurationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Configuration` to be created by this mutation. */
  configuration: ConfigurationInput;
};

/** The output of our create `Configuration` mutation. */
export type CreateConfigurationPayload = {
  __typename?: "CreateConfigurationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Configuration` that was created by this mutation. */
  configuration?: Maybe<Configuration>;
  /** An edge for our `Configuration`. May be used by Relay 1. */
  configurationEdge?: Maybe<ConfigurationsEdge>;
  /** Reads a single `DataRoom` that is related to this `Configuration`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `Configuration` mutation. */
export type CreateConfigurationPayloadConfigurationEdgeArgs = {
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy>>;
};

/** All input for the create `DataRoom` mutation. */
export type CreateDataRoomInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `DataRoom` to be created by this mutation. */
  dataRoom: DataRoomInput;
};

/** The output of our create `DataRoom` mutation. */
export type CreateDataRoomPayload = {
  __typename?: "CreateDataRoomPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `DataRoom` that was created by this mutation. */
  dataRoom?: Maybe<DataRoom>;
  /** An edge for our `DataRoom`. May be used by Relay 1. */
  dataRoomEdge?: Maybe<DataRoomsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `DataRoom`. */
  userByOwnerEmail?: Maybe<User>;
};

/** The output of our create `DataRoom` mutation. */
export type CreateDataRoomPayloadDataRoomEdgeArgs = {
  orderBy?: InputMaybe<Array<DataRoomsOrderBy>>;
};

/** All input for the create `DatasetLink` mutation. */
export type CreateDatasetLinkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `DatasetLink` to be created by this mutation. */
  datasetLink: DatasetLinkInput;
};

/** The output of our create `DatasetLink` mutation. */
export type CreateDatasetLinkPayload = {
  __typename?: "CreateDatasetLinkPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `ComputeNode` that is related to this `DatasetLink`. */
  computeNode?: Maybe<ComputeNode>;
  /** The `DatasetLink` that was created by this mutation. */
  datasetLink?: Maybe<DatasetLink>;
  /** An edge for our `DatasetLink`. May be used by Relay 1. */
  datasetLinkEdge?: Maybe<DatasetLinksEdge>;
  /** Reads a single `DatasetMeta` that is related to this `DatasetLink`. */
  datasetMeta?: Maybe<DatasetMeta>;
  /** Reads a single `DatasetStat` that is related to this `DatasetLink`. */
  datasetStats?: Maybe<DatasetStat>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `DatasetLink` mutation. */
export type CreateDatasetLinkPayloadDatasetLinkEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetLinksOrderBy>>;
};

/** All input for the create `DatasetMeta` mutation. */
export type CreateDatasetMetaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `DatasetMeta` to be created by this mutation. */
  datasetMeta: DatasetMetaInput;
};

/** The output of our create `DatasetMeta` mutation. */
export type CreateDatasetMetaPayload = {
  __typename?: "CreateDatasetMetaPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `DatasetMeta` that was created by this mutation. */
  datasetMeta?: Maybe<DatasetMeta>;
  /** An edge for our `DatasetMeta`. May be used by Relay 1. */
  datasetMetaEdge?: Maybe<DatasetMetasEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `DatasetMeta` mutation. */
export type CreateDatasetMetaPayloadDatasetMetaEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetMetasOrderBy>>;
};

/** All input for the create `DatasetStat` mutation. */
export type CreateDatasetStatInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `DatasetStat` to be created by this mutation. */
  datasetStat: DatasetStatInput;
};

/** The output of our create `DatasetStat` mutation. */
export type CreateDatasetStatPayload = {
  __typename?: "CreateDatasetStatPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DatasetMeta` that is related to this `DatasetStat`. */
  datasetMeta?: Maybe<DatasetMeta>;
  /** The `DatasetStat` that was created by this mutation. */
  datasetStat?: Maybe<DatasetStat>;
  /** An edge for our `DatasetStat`. May be used by Relay 1. */
  datasetStatEdge?: Maybe<DatasetStatsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `DatasetStat` mutation. */
export type CreateDatasetStatPayloadDatasetStatEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetStatsOrderBy>>;
};

/** All input for the create `Domain` mutation. */
export type CreateDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Domain` to be created by this mutation. */
  domain: DomainInput;
};

/** The output of our create `Domain` mutation. */
export type CreateDomainPayload = {
  __typename?: "CreateDomainPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Domain` that was created by this mutation. */
  domain?: Maybe<Domain>;
  /** An edge for our `Domain`. May be used by Relay 1. */
  domainEdge?: Maybe<DomainsEdge>;
  /** Reads a single `Organization` that is related to this `Domain`. */
  organization?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Domain`. */
  user?: Maybe<User>;
};

/** The output of our create `Domain` mutation. */
export type CreateDomainPayloadDomainEdgeArgs = {
  orderBy?: InputMaybe<Array<DomainsOrderBy>>;
};

/** All input for the create `ExecutionTime` mutation. */
export type CreateExecutionTimeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `ExecutionTime` to be created by this mutation. */
  executionTime: ExecutionTimeInput;
};

/** The output of our create `ExecutionTime` mutation. */
export type CreateExecutionTimePayload = {
  __typename?: "CreateExecutionTimePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `ExecutionTime` that was created by this mutation. */
  executionTime?: Maybe<ExecutionTime>;
  /** An edge for our `ExecutionTime`. May be used by Relay 1. */
  executionTimeEdge?: Maybe<ExecutionTimesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `ExecutionTime` mutation. */
export type CreateExecutionTimePayloadExecutionTimeEdgeArgs = {
  orderBy?: InputMaybe<Array<ExecutionTimesOrderBy>>;
};

/** All input for the create `Job` mutation. */
export type CreateJobInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Job` to be created by this mutation. */
  job: JobInput;
};

/** The output of our create `Job` mutation. */
export type CreateJobPayload = {
  __typename?: "CreateJobPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `ComputeNode` that is related to this `Job`. */
  computeNode?: Maybe<ComputeNode>;
  /** The `Job` that was created by this mutation. */
  job?: Maybe<Job>;
  /** An edge for our `Job`. May be used by Relay 1. */
  jobEdge?: Maybe<JobsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `Job` mutation. */
export type CreateJobPayloadJobEdgeArgs = {
  orderBy?: InputMaybe<Array<JobsOrderBy>>;
};

/** All input for the create `Lock` mutation. */
export type CreateLockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Lock` to be created by this mutation. */
  lock: LockInput;
};

/** The output of our create `Lock` mutation. */
export type CreateLockPayload = {
  __typename?: "CreateLockPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `Lock`. */
  dataRoom?: Maybe<DataRoom>;
  /** The `Lock` that was created by this mutation. */
  lock?: Maybe<Lock>;
  /** An edge for our `Lock`. May be used by Relay 1. */
  lockEdge?: Maybe<LocksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `Lock` mutation. */
export type CreateLockPayloadLockEdgeArgs = {
  orderBy?: InputMaybe<Array<LocksOrderBy>>;
};

/** All input for the create `Modification` mutation. */
export type CreateModificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Modification` to be created by this mutation. */
  modification: ModificationInput;
};

/** The output of our create `Modification` mutation. */
export type CreateModificationPayload = {
  __typename?: "CreateModificationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Configuration` that is related to this `Modification`. */
  configuration?: Maybe<Configuration>;
  /** The `Modification` that was created by this mutation. */
  modification?: Maybe<Modification>;
  /** An edge for our `Modification`. May be used by Relay 1. */
  modificationEdge?: Maybe<ModificationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `Modification` mutation. */
export type CreateModificationPayloadModificationEdgeArgs = {
  orderBy?: InputMaybe<Array<ModificationsOrderBy>>;
};

/** All input for the create `OrganizationAttribute` mutation. */
export type CreateOrganizationAttributeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `OrganizationAttribute` to be created by this mutation. */
  organizationAttribute: OrganizationAttributeInput;
};

/** The output of our create `OrganizationAttribute` mutation. */
export type CreateOrganizationAttributePayload = {
  __typename?: "CreateOrganizationAttributePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `OrganizationAttribute`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationAttribute` that was created by this mutation. */
  organizationAttribute?: Maybe<OrganizationAttribute>;
  /** An edge for our `OrganizationAttribute`. May be used by Relay 1. */
  organizationAttributeEdge?: Maybe<OrganizationAttributesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `OrganizationAttribute` mutation. */
export type CreateOrganizationAttributePayloadOrganizationAttributeEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationAttributesOrderBy>>;
};

/** All input for the create `Organization` mutation. */
export type CreateOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Organization` to be created by this mutation. */
  organization: OrganizationInput;
};

/** All input for the create `OrganizationLogo` mutation. */
export type CreateOrganizationLogoInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `OrganizationLogo` to be created by this mutation. */
  organizationLogo: OrganizationLogoInput;
};

/** The output of our create `OrganizationLogo` mutation. */
export type CreateOrganizationLogoPayload = {
  __typename?: "CreateOrganizationLogoPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `OrganizationLogo`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationLogo` that was created by this mutation. */
  organizationLogo?: Maybe<OrganizationLogo>;
  /** An edge for our `OrganizationLogo`. May be used by Relay 1. */
  organizationLogoEdge?: Maybe<OrganizationLogosEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `OrganizationLogo` mutation. */
export type CreateOrganizationLogoPayloadOrganizationLogoEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationLogosOrderBy>>;
};

/** All input for the create `OrganizationMembership` mutation. */
export type CreateOrganizationMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `OrganizationMembership` to be created by this mutation. */
  organizationMembership: OrganizationMembershipInput;
};

/** The output of our create `OrganizationMembership` mutation. */
export type CreateOrganizationMembershipPayload = {
  __typename?: "CreateOrganizationMembershipPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `OrganizationMembership`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationMembership` that was created by this mutation. */
  organizationMembership?: Maybe<OrganizationMembership>;
  /** An edge for our `OrganizationMembership`. May be used by Relay 1. */
  organizationMembershipEdge?: Maybe<OrganizationMembershipsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `OrganizationMembership`. */
  user?: Maybe<User>;
};

/** The output of our create `OrganizationMembership` mutation. */
export type CreateOrganizationMembershipPayloadOrganizationMembershipEdgeArgs =
  {
    orderBy?: InputMaybe<Array<OrganizationMembershipsOrderBy>>;
  };

/** The output of our create `Organization` mutation. */
export type CreateOrganizationPayload = {
  __typename?: "CreateOrganizationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Organization` that was created by this mutation. */
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `Organization` mutation. */
export type CreateOrganizationPayloadOrganizationEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

/** All input for the create `OrganizationQuota` mutation. */
export type CreateOrganizationQuotaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `OrganizationQuota` to be created by this mutation. */
  organizationQuota: OrganizationQuotaInput;
};

/** The output of our create `OrganizationQuota` mutation. */
export type CreateOrganizationQuotaPayload = {
  __typename?: "CreateOrganizationQuotaPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `OrganizationQuota`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationQuota` that was created by this mutation. */
  organizationQuota?: Maybe<OrganizationQuota>;
  /** An edge for our `OrganizationQuota`. May be used by Relay 1. */
  organizationQuotaEdge?: Maybe<OrganizationQuotasEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our create `OrganizationQuota` mutation. */
export type CreateOrganizationQuotaPayloadOrganizationQuotaEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationQuotasOrderBy>>;
};

/** All input for the create `PermissionAsElement` mutation. */
export type CreatePermissionAsElementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `PermissionAsElement` to be created by this mutation. */
  permissionAsElement: PermissionAsElementInput;
};

/** The output of our create `PermissionAsElement` mutation. */
export type CreatePermissionAsElementPayload = {
  __typename?: "CreatePermissionAsElementPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `PermissionAsElement` that was created by this mutation. */
  permissionAsElement?: Maybe<PermissionAsElement>;
  /** An edge for our `PermissionAsElement`. May be used by Relay 1. */
  permissionAsElementEdge?: Maybe<PermissionAsElementsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UserPermissionAsElement` that is related to this `PermissionAsElement`. */
  userPermission?: Maybe<UserPermissionAsElement>;
};

/** The output of our create `PermissionAsElement` mutation. */
export type CreatePermissionAsElementPayloadPermissionAsElementEdgeArgs = {
  orderBy?: InputMaybe<Array<PermissionAsElementsOrderBy>>;
};

/** All input for the create `Permission` mutation. */
export type CreatePermissionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Permission` to be created by this mutation. */
  permission: PermissionInput;
};

/** The output of our create `Permission` mutation. */
export type CreatePermissionPayload = {
  __typename?: "CreatePermissionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Permission` that was created by this mutation. */
  permission?: Maybe<Permission>;
  /** An edge for our `Permission`. May be used by Relay 1. */
  permissionEdge?: Maybe<PermissionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UserPermission` that is related to this `Permission`. */
  userPermission?: Maybe<UserPermission>;
};

/** The output of our create `Permission` mutation. */
export type CreatePermissionPayloadPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<PermissionsOrderBy>>;
};

/** All input for the create `Request` mutation. */
export type CreateRequestInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Request` to be created by this mutation. */
  request: RequestInput;
};

/** The output of our create `Request` mutation. */
export type CreateRequestPayload = {
  __typename?: "CreateRequestPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Configuration` that is related to this `Request`. */
  configuration?: Maybe<Configuration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Request` that was created by this mutation. */
  request?: Maybe<Request>;
  /** An edge for our `Request`. May be used by Relay 1. */
  requestEdge?: Maybe<RequestsEdge>;
};

/** The output of our create `Request` mutation. */
export type CreateRequestPayloadRequestEdgeArgs = {
  orderBy?: InputMaybe<Array<RequestsOrderBy>>;
};

/** All input for the create `Resource` mutation. */
export type CreateResourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Resource` to be created by this mutation. */
  resource: ResourceInput;
};

/** The output of our create `Resource` mutation. */
export type CreateResourcePayload = {
  __typename?: "CreateResourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Resource` that was created by this mutation. */
  resource?: Maybe<Resource>;
  /** An edge for our `Resource`. May be used by Relay 1. */
  resourceEdge?: Maybe<ResourcesEdge>;
};

/** The output of our create `Resource` mutation. */
export type CreateResourcePayloadResourceEdgeArgs = {
  orderBy?: InputMaybe<Array<ResourcesOrderBy>>;
};

/** All input for the create `Signature` mutation. */
export type CreateSignatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Signature` to be created by this mutation. */
  signature: SignatureInput;
};

/** The output of our create `Signature` mutation. */
export type CreateSignaturePayload = {
  __typename?: "CreateSignaturePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Signature` that was created by this mutation. */
  signature?: Maybe<Signature>;
  /** An edge for our `Signature`. May be used by Relay 1. */
  signatureEdge?: Maybe<SignaturesEdge>;
  /** Reads a single `Signee` that is related to this `Signature`. */
  signee?: Maybe<Signee>;
};

/** The output of our create `Signature` mutation. */
export type CreateSignaturePayloadSignatureEdgeArgs = {
  orderBy?: InputMaybe<Array<SignaturesOrderBy>>;
};

/** All input for the create `Signee` mutation. */
export type CreateSigneeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Signee` to be created by this mutation. */
  signee: SigneeInput;
};

/** The output of our create `Signee` mutation. */
export type CreateSigneePayload = {
  __typename?: "CreateSigneePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Request` that is related to this `Signee`. */
  request?: Maybe<Request>;
  /** The `Signee` that was created by this mutation. */
  signee?: Maybe<Signee>;
  /** An edge for our `Signee`. May be used by Relay 1. */
  signeeEdge?: Maybe<SigneesEdge>;
};

/** The output of our create `Signee` mutation. */
export type CreateSigneePayloadSigneeEdgeArgs = {
  orderBy?: InputMaybe<Array<SigneesOrderBy>>;
};

/** All input for the create `State` mutation. */
export type CreateStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `State` to be created by this mutation. */
  state: StateInput;
};

/** The output of our create `State` mutation. */
export type CreateStatePayload = {
  __typename?: "CreateStatePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `State`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `State` that was created by this mutation. */
  state?: Maybe<State>;
  /** An edge for our `State`. May be used by Relay 1. */
  stateEdge?: Maybe<StatesEdge>;
};

/** The output of our create `State` mutation. */
export type CreateStatePayloadStateEdgeArgs = {
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

/** All input for the create `UserAttribute` mutation. */
export type CreateUserAttributeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `UserAttribute` to be created by this mutation. */
  userAttribute: UserAttributeInput;
};

/** The output of our create `UserAttribute` mutation. */
export type CreateUserAttributePayload = {
  __typename?: "CreateUserAttributePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserAttribute`. */
  user?: Maybe<User>;
  /** The `UserAttribute` that was created by this mutation. */
  userAttribute?: Maybe<UserAttribute>;
  /** An edge for our `UserAttribute`. May be used by Relay 1. */
  userAttributeEdge?: Maybe<UserAttributesEdge>;
};

/** The output of our create `UserAttribute` mutation. */
export type CreateUserAttributePayloadUserAttributeEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAttributesOrderBy>>;
};

/** All input for the create `UserConfig` mutation. */
export type CreateUserConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `UserConfig` to be created by this mutation. */
  userConfig: UserConfigInput;
};

/** The output of our create `UserConfig` mutation. */
export type CreateUserConfigPayload = {
  __typename?: "CreateUserConfigPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `UserConfig`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserConfig`. */
  userByEmail?: Maybe<User>;
  /** The `UserConfig` that was created by this mutation. */
  userConfig?: Maybe<UserConfig>;
  /** An edge for our `UserConfig`. May be used by Relay 1. */
  userConfigEdge?: Maybe<UserConfigsEdge>;
};

/** The output of our create `UserConfig` mutation. */
export type CreateUserConfigPayloadUserConfigEdgeArgs = {
  orderBy?: InputMaybe<Array<UserConfigsOrderBy>>;
};

/** All input for the create `User` mutation. */
export type CreateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `User` to be created by this mutation. */
  user: UserInput;
};

/** The output of our create `User` mutation. */
export type CreateUserPayload = {
  __typename?: "CreateUserPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was created by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};

/** The output of our create `User` mutation. */
export type CreateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the create `UserPermissionAsElement` mutation. */
export type CreateUserPermissionAsElementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `UserPermissionAsElement` to be created by this mutation. */
  userPermissionAsElement: UserPermissionAsElementInput;
};

/** The output of our create `UserPermissionAsElement` mutation. */
export type CreateUserPermissionAsElementPayload = {
  __typename?: "CreateUserPermissionAsElementPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Modification` that is related to this `UserPermissionAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UserPermissionAsElement` that was created by this mutation. */
  userPermissionAsElement?: Maybe<UserPermissionAsElement>;
  /** An edge for our `UserPermissionAsElement`. May be used by Relay 1. */
  userPermissionAsElementEdge?: Maybe<UserPermissionAsElementsEdge>;
};

/** All input for the create `UserPermission` mutation. */
export type CreateUserPermissionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `UserPermission` to be created by this mutation. */
  userPermission: UserPermissionInput;
};

/** The output of our create `UserPermission` mutation. */
export type CreateUserPermissionPayload = {
  __typename?: "CreateUserPermissionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `UserPermission`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserPermission`. */
  userByEmail?: Maybe<User>;
  /** The `UserPermission` that was created by this mutation. */
  userPermission?: Maybe<UserPermission>;
  /** An edge for our `UserPermission`. May be used by Relay 1. */
  userPermissionEdge?: Maybe<UserPermissionsEdge>;
};

/** The output of our create `UserPermission` mutation. */
export type CreateUserPermissionPayloadUserPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<UserPermissionsOrderBy>>;
};

/** All input for the create `UserQuota` mutation. */
export type CreateUserQuotaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `UserQuota` to be created by this mutation. */
  userQuota: UserQuotaInput;
};

/** The output of our create `UserQuota` mutation. */
export type CreateUserQuotaPayload = {
  __typename?: "CreateUserQuotaPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserQuota`. */
  userByEmail?: Maybe<User>;
  /** The `UserQuota` that was created by this mutation. */
  userQuota?: Maybe<UserQuota>;
  /** An edge for our `UserQuota`. May be used by Relay 1. */
  userQuotaEdge?: Maybe<UserQuotasEdge>;
};

/** The output of our create `UserQuota` mutation. */
export type CreateUserQuotaPayloadUserQuotaEdgeArgs = {
  orderBy?: InputMaybe<Array<UserQuotasOrderBy>>;
};

export type DataRoom = Node & {
  __typename?: "DataRoom";
  /** Reads and enables pagination through a set of `ComputeNode`. */
  computeNodes: ComputeNodesConnection;
  /** Reads a single `Config` that is related to this `DataRoom`. */
  config?: Maybe<Config>;
  /** Reads and enables pagination through a set of `Configuration`. */
  configurations: ConfigurationsConnection;
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  dataRoomHash?: Maybe<Scalars["String"]>;
  dataRoomId: Scalars["UUID"];
  description?: Maybe<Scalars["String"]>;
  enableDevelopmentTab: Scalars["Boolean"];
  governanceProtocol: Scalars["JSON"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  isExpertViewEnabled?: Maybe<Scalars["Boolean"]>;
  isOwner?: Maybe<Scalars["Boolean"]>;
  isSaving?: Maybe<Scalars["Boolean"]>;
  lastEditedAt?: Maybe<Scalars["Datetime"]>;
  lastEditedByEmail?: Maybe<Scalars["String"]>;
  /** Reads a single `Lock` that is related to this `DataRoom`. */
  lock?: Maybe<Lock>;
  mrenclave?: Maybe<Scalars["String"]>;
  name: Scalars["String"];
  organizationLogo?: Maybe<OrganizationLogo>;
  ownerEmail: Scalars["String"];
  source: SourceType;
  /** Reads a single `State` that is related to this `DataRoom`. */
  state?: Maybe<State>;
  status?: Maybe<Scalars["String"]>;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
  /** Reads a single `User` that is related to this `DataRoom`. */
  userByOwnerEmail?: Maybe<User>;
  userConfig?: Maybe<UserConfig>;
  /** Reads and enables pagination through a set of `UserConfig`. */
  userConfigs: UserConfigsConnection;
  /** Reads and enables pagination through a set of `UserPermission`. */
  userPermissions: UserPermissionsConnection;
};

export type DataRoomComputeNodesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ComputeNodeCondition>;
  filter?: InputMaybe<ComputeNodeFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ComputeNodesOrderBy>>;
};

export type DataRoomConfigurationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ConfigurationCondition>;
  filter?: InputMaybe<ConfigurationFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy>>;
};

export type DataRoomUserConfigsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<UserConfigCondition>;
  filter?: InputMaybe<UserConfigFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserConfigsOrderBy>>;
};

export type DataRoomUserPermissionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<UserPermissionCondition>;
  filter?: InputMaybe<UserPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserPermissionsOrderBy>>;
};

export type DataRoomAggregates = {
  __typename?: "DataRoomAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DataRoomDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A filter to be used against aggregates of `DataRoom` object types. */
export type DataRoomAggregatesFilter = {
  /** Distinct count aggregate over matching `DataRoom` objects. */
  distinctCount?: InputMaybe<DataRoomDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `DataRoom` object to be included within the aggregate. */
  filter?: InputMaybe<DataRoomFilter>;
};

/**
 * A condition to be used against `DataRoom` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DataRoomCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `dataRoomHash` field. */
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `enableDevelopmentTab` field. */
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `governanceProtocol` field. */
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `mrenclave` field. */
  mrenclave?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `ownerEmail` field. */
  ownerEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `source` field. */
  source?: InputMaybe<SourceType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The fields on `dataRoom` to look up the row to connect. */
export type DataRoomDataRoomDataRoomHashKeyConnect = {
  dataRoomHash: Scalars["String"];
};

/** The fields on `dataRoom` to look up the row to delete. */
export type DataRoomDataRoomDataRoomHashKeyDelete = {
  dataRoomHash: Scalars["String"];
};

/** The fields on `dataRoom` to look up the row to connect. */
export type DataRoomDataRoomPkeyConnect = {
  dataRoomId: Scalars["UUID"];
};

/** The fields on `dataRoom` to look up the row to delete. */
export type DataRoomDataRoomPkeyDelete = {
  dataRoomId: Scalars["UUID"];
};

export type DataRoomDistinct = {
  __typename?: "DataRoomDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `dataRoomHash` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  dataRoomHash: Array<Maybe<Scalars["String"]>>;
  /** Distinct `dataRoomId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  dataRoomId: Array<Scalars["UUID"]>;
  /** Distinct `description` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  description: Array<Maybe<Scalars["String"]>>;
  /** Distinct `enableDevelopmentTab` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  enableDevelopmentTab: Array<Scalars["Boolean"]>;
  /** Distinct `governanceProtocol` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  governanceProtocol: Array<Scalars["JSON"]>;
  /** Distinct `mrenclave` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  mrenclave: Array<Maybe<Scalars["String"]>>;
  /** Distinct `name` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  name: Array<Scalars["String"]>;
  /** Distinct `ownerEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  ownerEmail: Array<Scalars["String"]>;
  /** Distinct `source` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  source: Array<SourceType>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type DataRoomDistinctCountAggregateFilter = {
  createdAt?: InputMaybe<BigIntFilter>;
  createdByEmail?: InputMaybe<BigIntFilter>;
  dataRoomHash?: InputMaybe<BigIntFilter>;
  dataRoomId?: InputMaybe<BigIntFilter>;
  description?: InputMaybe<BigIntFilter>;
  enableDevelopmentTab?: InputMaybe<BigIntFilter>;
  governanceProtocol?: InputMaybe<BigIntFilter>;
  lastEditedAt?: InputMaybe<DatetimeFilter>;
  lastEditedByEmail?: InputMaybe<StringFilter>;
  mrenclave?: InputMaybe<BigIntFilter>;
  name?: InputMaybe<BigIntFilter>;
  ownerEmail?: InputMaybe<BigIntFilter>;
  source?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  updatedByEmail?: InputMaybe<BigIntFilter>;
};

export type DataRoomDistinctCountAggregates = {
  __typename?: "DataRoomDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of dataRoomHash across the matching connection */
  dataRoomHash?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of dataRoomId across the matching connection */
  dataRoomId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of enableDevelopmentTab across the matching connection */
  enableDevelopmentTab?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of governanceProtocol across the matching connection */
  governanceProtocol?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of this field across the matching connection. */
  lastEditedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of this field across the matching connection. */
  lastEditedByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of mrenclave across the matching connection */
  mrenclave?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of this field across the matching connection. */
  organizationLogo?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of ownerEmail across the matching connection */
  ownerEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of source across the matching connection */
  source?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of this field across the matching connection. */
  userConfig?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `DataRoom` object types. All fields are combined with a logical ‘and.’ */
export type DataRoomFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DataRoomFilter>>;
  /** Filter by the object’s `computeNodes` relation. */
  computeNodes?: InputMaybe<DataRoomToManyComputeNodeFilter>;
  /** Some related `computeNodes` exist. */
  computeNodesExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `config` relation. */
  config?: InputMaybe<ConfigFilter>;
  /** A related `config` exists. */
  configExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `configurations` relation. */
  configurations?: InputMaybe<DataRoomToManyConfigurationFilter>;
  /** Some related `configurations` exist. */
  configurationsExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dataRoomHash` field. */
  dataRoomHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `enableDevelopmentTab` field. */
  enableDevelopmentTab?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `governanceProtocol` field. */
  governanceProtocol?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `lastEditedAt` field. */
  lastEditedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `lastEditedByEmail` field. */
  lastEditedByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `lock` relation. */
  lock?: InputMaybe<LockFilter>;
  /** A related `lock` exists. */
  lockExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `mrenclave` field. */
  mrenclave?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DataRoomFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DataRoomFilter>>;
  /** Filter by the object’s `ownerEmail` field. */
  ownerEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `source` field. */
  source?: InputMaybe<SourceTypeFilter>;
  /** Filter by the object’s `state` relation. */
  state?: InputMaybe<StateFilter>;
  /** A related `state` exists. */
  stateExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userByOwnerEmail` relation. */
  userByOwnerEmail?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userConfigs` relation. */
  userConfigs?: InputMaybe<DataRoomToManyUserConfigFilter>;
  /** Some related `userConfigs` exist. */
  userConfigsExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `userPermissions` relation. */
  userPermissions?: InputMaybe<DataRoomToManyUserPermissionFilter>;
  /** Some related `userPermissions` exist. */
  userPermissionsExist?: InputMaybe<Scalars["Boolean"]>;
};

export type DataRoomGroup = {
  __typename?: "DataRoomGroup";
  /** A list of _all_ `DataRoom` primary keys that belong to the `DataRoomGroup`. */
  dataRoomIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `DataRoom` of this `DataRoomGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `DataRoomGroup`. Overriding these may result in the wrong set of `DataRoom` returned.
   */
  dataRooms?: Maybe<DataRoomsConnection>;
  /** `DataRoomFilter` that was used to implicitly filter `dataRooms`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** The count of _all_ `DataRoom` you could get from the `DataRoomGroup`. */
  totalCount: Scalars["Int"];
};

export type DataRoomGroupDataRoomsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DataRoomCondition>;
  filter?: InputMaybe<DataRoomFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DataRoomsOrderBy>>;
};

/** Grouping methods for `DataRoom` for usage during aggregation. */
export enum DataRoomGroupBy {
  ConfigByDataRoomIdComputeNodesOrder = "CONFIG_BY_DATA_ROOM_ID_COMPUTE_NODES_ORDER",
  ConfigByDataRoomIdCreatedAt = "CONFIG_BY_DATA_ROOM_ID_CREATED_AT",
  ConfigByDataRoomIdCreatedByEmail = "CONFIG_BY_DATA_ROOM_ID_CREATED_BY_EMAIL",
  ConfigByDataRoomIdDataNodesOrder = "CONFIG_BY_DATA_ROOM_ID_DATA_NODES_ORDER",
  ConfigByDataRoomIdDataRoomId = "CONFIG_BY_DATA_ROOM_ID_DATA_ROOM_ID",
  ConfigByDataRoomIdShowOrganizationLogo = "CONFIG_BY_DATA_ROOM_ID_SHOW_ORGANIZATION_LOGO",
  ConfigByDataRoomIdUpdatedAt = "CONFIG_BY_DATA_ROOM_ID_UPDATED_AT",
  ConfigByDataRoomIdUpdatedByEmail = "CONFIG_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  Description = "DESCRIPTION",
  EnableDevelopmentTab = "ENABLE_DEVELOPMENT_TAB",
  GovernanceProtocol = "GOVERNANCE_PROTOCOL",
  LockByDataRoomIdCreatedAt = "LOCK_BY_DATA_ROOM_ID_CREATED_AT",
  LockByDataRoomIdCreatedByEmail = "LOCK_BY_DATA_ROOM_ID_CREATED_BY_EMAIL",
  LockByDataRoomIdDataRoomId = "LOCK_BY_DATA_ROOM_ID_DATA_ROOM_ID",
  LockByDataRoomIdIsLocked = "LOCK_BY_DATA_ROOM_ID_IS_LOCKED",
  LockByDataRoomIdUpdatedAt = "LOCK_BY_DATA_ROOM_ID_UPDATED_AT",
  LockByDataRoomIdUpdatedByEmail = "LOCK_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL",
  Mrenclave = "MRENCLAVE",
  Name = "NAME",
  OwnerEmail = "OWNER_EMAIL",
  Source = "SOURCE",
  StateByDataRoomIdCreatedAt = "STATE_BY_DATA_ROOM_ID_CREATED_AT",
  StateByDataRoomIdCreatedByEmail = "STATE_BY_DATA_ROOM_ID_CREATED_BY_EMAIL",
  StateByDataRoomIdDataRoomId = "STATE_BY_DATA_ROOM_ID_DATA_ROOM_ID",
  StateByDataRoomIdStatus = "STATE_BY_DATA_ROOM_ID_STATUS",
  StateByDataRoomIdStatusUpdatedAt = "STATE_BY_DATA_ROOM_ID_STATUS_UPDATED_AT",
  StateByDataRoomIdUpdatedAt = "STATE_BY_DATA_ROOM_ID_UPDATED_AT",
  StateByDataRoomIdUpdatedByEmail = "STATE_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
  UserByOwnerEmailCreatedAt = "USER_BY_OWNER_EMAIL_CREATED_AT",
  UserByOwnerEmailCreatedByEmail = "USER_BY_OWNER_EMAIL_CREATED_BY_EMAIL",
  UserByOwnerEmailDomain = "USER_BY_OWNER_EMAIL_DOMAIN",
  UserByOwnerEmailEmail = "USER_BY_OWNER_EMAIL_EMAIL",
  UserByOwnerEmailEmailVerified = "USER_BY_OWNER_EMAIL_EMAIL_VERIFIED",
  UserByOwnerEmailFamilyName = "USER_BY_OWNER_EMAIL_FAMILY_NAME",
  UserByOwnerEmailGivenName = "USER_BY_OWNER_EMAIL_GIVEN_NAME",
  UserByOwnerEmailName = "USER_BY_OWNER_EMAIL_NAME",
  UserByOwnerEmailNickname = "USER_BY_OWNER_EMAIL_NICKNAME",
  UserByOwnerEmailPicture = "USER_BY_OWNER_EMAIL_PICTURE",
  UserByOwnerEmailUpdatedAt = "USER_BY_OWNER_EMAIL_UPDATED_AT",
  UserByOwnerEmailUpdatedByEmail = "USER_BY_OWNER_EMAIL_UPDATED_BY_EMAIL",
  UserByOwnerEmailUpstreamUserId = "USER_BY_OWNER_EMAIL_UPSTREAM_USER_ID",
  UserByOwnerEmailUserId = "USER_BY_OWNER_EMAIL_USER_ID",
}

export type DataRoomHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DataRoomHavingDistinctCountDataRoomLastEditedAtInput = {
  filter?: InputMaybe<HavingDatetimeFilter>;
};

export type DataRoomHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  lastEditedAt?: InputMaybe<DataRoomHavingDistinctCountDataRoomLastEditedAtInput>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `DataRoom` aggregates. */
export type DataRoomHavingInput = {
  AND?: InputMaybe<Array<DataRoomHavingInput>>;
  OR?: InputMaybe<Array<DataRoomHavingInput>>;
  average?: InputMaybe<DataRoomHavingAverageInput>;
  distinctCount?: InputMaybe<DataRoomHavingDistinctCountInput>;
  max?: InputMaybe<DataRoomHavingMaxInput>;
  min?: InputMaybe<DataRoomHavingMinInput>;
  stddevPopulation?: InputMaybe<DataRoomHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DataRoomHavingStddevSampleInput>;
  sum?: InputMaybe<DataRoomHavingSumInput>;
  variancePopulation?: InputMaybe<DataRoomHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DataRoomHavingVarianceSampleInput>;
};

export type DataRoomHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DataRoomHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DataRoomHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DataRoomHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DataRoomHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DataRoomHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DataRoomHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `DataRoom` */
export type DataRoomInput = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DataRoomNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `dataRoom` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type DataRoomNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `dataRoom` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type DataRoomOnComputeNodeForComputeNodeDataRoomIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `computeNode` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `computeNode` being updated. */
  patch: ComputeNodePatch;
};

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnComputeNodeForComputeNodeDataRoomIdFkeyUsingDataRoomDataRoomHashKeyUpdate =
  {
    dataRoomHash: Scalars["String"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: UpdateDataRoomOnComputeNodeForComputeNodeDataRoomIdFkeyPatch;
  };

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnComputeNodeForComputeNodeDataRoomIdFkeyUsingDataRoomPkeyUpdate =
  {
    dataRoomId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: UpdateDataRoomOnComputeNodeForComputeNodeDataRoomIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DataRoomOnConfigForConfigDataRoomIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `config` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `config` being updated. */
  patch: ConfigPatch;
};

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnConfigForConfigDataRoomIdFkeyUsingDataRoomDataRoomHashKeyUpdate =
  {
    dataRoomHash: Scalars["String"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: UpdateDataRoomOnConfigForConfigDataRoomIdFkeyPatch;
  };

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnConfigForConfigDataRoomIdFkeyUsingDataRoomPkeyUpdate = {
  dataRoomId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `dataRoom` being updated. */
  patch: UpdateDataRoomOnConfigForConfigDataRoomIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type DataRoomOnConfigurationForConfigurationDataRoomIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `configuration` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `configuration` being updated. */
    patch: ConfigurationPatch;
  };

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnConfigurationForConfigurationDataRoomIdFkeyUsingDataRoomDataRoomHashKeyUpdate =
  {
    dataRoomHash: Scalars["String"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: UpdateDataRoomOnConfigurationForConfigurationDataRoomIdFkeyPatch;
  };

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnConfigurationForConfigurationDataRoomIdFkeyUsingDataRoomPkeyUpdate =
  {
    dataRoomId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: UpdateDataRoomOnConfigurationForConfigurationDataRoomIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DataRoomOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `user` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UserPatch;
  };

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0UsingDataRoomDataRoomHashKeyUpdate =
  {
    dataRoomHash: Scalars["String"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: UpdateDataRoomOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0Patch;
  };

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0UsingDataRoomPkeyUpdate =
  {
    dataRoomId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: UpdateDataRoomOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0Patch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DataRoomOnLockForLockDataRoomIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `lock` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `lock` being updated. */
  patch: LockPatch;
};

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnLockForLockDataRoomIdFkeyUsingDataRoomDataRoomHashKeyUpdate =
  {
    dataRoomHash: Scalars["String"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: UpdateDataRoomOnLockForLockDataRoomIdFkeyPatch;
  };

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnLockForLockDataRoomIdFkeyUsingDataRoomPkeyUpdate = {
  dataRoomId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `dataRoom` being updated. */
  patch: UpdateDataRoomOnLockForLockDataRoomIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type DataRoomOnStateForStateDataRoomIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `state` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `state` being updated. */
  patch: StatePatch;
};

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnStateForStateDataRoomIdFkeyUsingDataRoomDataRoomHashKeyUpdate =
  {
    dataRoomHash: Scalars["String"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: UpdateDataRoomOnStateForStateDataRoomIdFkeyPatch;
  };

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnStateForStateDataRoomIdFkeyUsingDataRoomPkeyUpdate = {
  dataRoomId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `dataRoom` being updated. */
  patch: UpdateDataRoomOnStateForStateDataRoomIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type DataRoomOnUserConfigForUserConfigDataRoomIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `userConfig` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `userConfig` being updated. */
  patch: UserConfigPatch;
};

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnUserConfigForUserConfigDataRoomIdFkeyUsingDataRoomDataRoomHashKeyUpdate =
  {
    dataRoomHash: Scalars["String"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: UpdateDataRoomOnUserConfigForUserConfigDataRoomIdFkeyPatch;
  };

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnUserConfigForUserConfigDataRoomIdFkeyUsingDataRoomPkeyUpdate =
  {
    dataRoomId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: UpdateDataRoomOnUserConfigForUserConfigDataRoomIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DataRoomOnUserPermissionForUserPermissionDataRoomIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `userPermission` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `userPermission` being updated. */
    patch: UserPermissionPatch;
  };

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnUserPermissionForUserPermissionDataRoomIdFkeyUsingDataRoomDataRoomHashKeyUpdate =
  {
    dataRoomHash: Scalars["String"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: UpdateDataRoomOnUserPermissionForUserPermissionDataRoomIdFkeyPatch;
  };

/** The fields on `dataRoom` to look up the row to update. */
export type DataRoomOnUserPermissionForUserPermissionDataRoomIdFkeyUsingDataRoomPkeyUpdate =
  {
    dataRoomId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: UpdateDataRoomOnUserPermissionForUserPermissionDataRoomIdFkeyPatch;
  };

/** Represents an update to a `DataRoom`. Fields that are set will be updated. */
export type DataRoomPatch = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** A filter to be used against many `ComputeNode` object types. All fields are combined with a logical ‘and.’ */
export type DataRoomToManyComputeNodeFilter = {
  /** Aggregates across related `ComputeNode` match the filter criteria. */
  aggregates?: InputMaybe<ComputeNodeAggregatesFilter>;
  /** Every related `ComputeNode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ComputeNodeFilter>;
  /** No related `ComputeNode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ComputeNodeFilter>;
  /** Some related `ComputeNode` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ComputeNodeFilter>;
};

/** A filter to be used against many `Configuration` object types. All fields are combined with a logical ‘and.’ */
export type DataRoomToManyConfigurationFilter = {
  /** Aggregates across related `Configuration` match the filter criteria. */
  aggregates?: InputMaybe<ConfigurationAggregatesFilter>;
  /** Every related `Configuration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<ConfigurationFilter>;
  /** No related `Configuration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<ConfigurationFilter>;
  /** Some related `Configuration` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<ConfigurationFilter>;
};

/** A filter to be used against many `UserConfig` object types. All fields are combined with a logical ‘and.’ */
export type DataRoomToManyUserConfigFilter = {
  /** Aggregates across related `UserConfig` match the filter criteria. */
  aggregates?: InputMaybe<UserConfigAggregatesFilter>;
  /** Every related `UserConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserConfigFilter>;
  /** No related `UserConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserConfigFilter>;
  /** Some related `UserConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserConfigFilter>;
};

/** A filter to be used against many `UserPermission` object types. All fields are combined with a logical ‘and.’ */
export type DataRoomToManyUserPermissionFilter = {
  /** Aggregates across related `UserPermission` match the filter criteria. */
  aggregates?: InputMaybe<UserPermissionAggregatesFilter>;
  /** Every related `UserPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserPermissionFilter>;
  /** No related `UserPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserPermissionFilter>;
  /** Some related `UserPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserPermissionFilter>;
};

/** A connection to a list of `DataRoom` values. */
export type DataRoomsConnection = {
  __typename?: "DataRoomsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DataRoomAggregates>;
  /** A list of _all_ `DataRoom` primary keys. */
  dataRoomIds?: Maybe<Array<Scalars["UUID"]>>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<DataRoomDistinct>;
  /** A list of edges which contains the `DataRoom` and cursor to aid in pagination. */
  edges: Array<DataRoomsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DataRoomAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<DataRoomGroup>>;
  /** A list of `DataRoom` objects. */
  nodes: Array<Maybe<DataRoom>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DataRoom` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `DataRoom` values. */
export type DataRoomsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DataRoomGroupBy>;
  having?: InputMaybe<DataRoomHavingInput>;
};

/** A connection to a list of `DataRoom` values. */
export type DataRoomsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<DataRoomGroupBy>;
  having?: InputMaybe<DataRoomHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `DataRoom` edge in the connection. */
export type DataRoomsEdge = {
  __typename?: "DataRoomsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `DataRoom` at the end of the edge. */
  node?: Maybe<DataRoom>;
};

/** Methods to use when ordering `DataRoom`. */
export enum DataRoomsOrderBy {
  ComputeNodesAverageComputeNodeIdAsc = "COMPUTE_NODES_AVERAGE_COMPUTE_NODE_ID_ASC",
  ComputeNodesAverageComputeNodeIdDesc = "COMPUTE_NODES_AVERAGE_COMPUTE_NODE_ID_DESC",
  ComputeNodesAverageComputeNodeTypeAsc = "COMPUTE_NODES_AVERAGE_COMPUTE_NODE_TYPE_ASC",
  ComputeNodesAverageComputeNodeTypeDesc = "COMPUTE_NODES_AVERAGE_COMPUTE_NODE_TYPE_DESC",
  ComputeNodesAverageConfigAsc = "COMPUTE_NODES_AVERAGE_CONFIG_ASC",
  ComputeNodesAverageConfigDesc = "COMPUTE_NODES_AVERAGE_CONFIG_DESC",
  ComputeNodesAverageCreatedAtAsc = "COMPUTE_NODES_AVERAGE_CREATED_AT_ASC",
  ComputeNodesAverageCreatedAtDesc = "COMPUTE_NODES_AVERAGE_CREATED_AT_DESC",
  ComputeNodesAverageCreatedByEmailAsc = "COMPUTE_NODES_AVERAGE_CREATED_BY_EMAIL_ASC",
  ComputeNodesAverageCreatedByEmailDesc = "COMPUTE_NODES_AVERAGE_CREATED_BY_EMAIL_DESC",
  ComputeNodesAverageDataRoomIdAsc = "COMPUTE_NODES_AVERAGE_DATA_ROOM_ID_ASC",
  ComputeNodesAverageDataRoomIdDesc = "COMPUTE_NODES_AVERAGE_DATA_ROOM_ID_DESC",
  ComputeNodesAverageIndexAsc = "COMPUTE_NODES_AVERAGE_INDEX_ASC",
  ComputeNodesAverageIndexDesc = "COMPUTE_NODES_AVERAGE_INDEX_DESC",
  ComputeNodesAverageNodeNameAsc = "COMPUTE_NODES_AVERAGE_NODE_NAME_ASC",
  ComputeNodesAverageNodeNameDesc = "COMPUTE_NODES_AVERAGE_NODE_NAME_DESC",
  ComputeNodesAverageUpdatedAtAsc = "COMPUTE_NODES_AVERAGE_UPDATED_AT_ASC",
  ComputeNodesAverageUpdatedAtDesc = "COMPUTE_NODES_AVERAGE_UPDATED_AT_DESC",
  ComputeNodesAverageUpdatedByEmailAsc = "COMPUTE_NODES_AVERAGE_UPDATED_BY_EMAIL_ASC",
  ComputeNodesAverageUpdatedByEmailDesc = "COMPUTE_NODES_AVERAGE_UPDATED_BY_EMAIL_DESC",
  ComputeNodesByDataRoomIdCountAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_COUNT_ASC",
  ComputeNodesByDataRoomIdCountDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_COUNT_DESC",
  ComputeNodesByDataRoomIdMaxComputeNodeIdAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_COMPUTE_NODE_ID_ASC",
  ComputeNodesByDataRoomIdMaxComputeNodeIdDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_COMPUTE_NODE_ID_DESC",
  ComputeNodesByDataRoomIdMaxComputeNodeTypeAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_COMPUTE_NODE_TYPE_ASC",
  ComputeNodesByDataRoomIdMaxComputeNodeTypeDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_COMPUTE_NODE_TYPE_DESC",
  ComputeNodesByDataRoomIdMaxConfigAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_CONFIG_ASC",
  ComputeNodesByDataRoomIdMaxConfigDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_CONFIG_DESC",
  ComputeNodesByDataRoomIdMaxCreatedAtAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_CREATED_AT_ASC",
  ComputeNodesByDataRoomIdMaxCreatedAtDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_CREATED_AT_DESC",
  ComputeNodesByDataRoomIdMaxCreatedByEmailAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_CREATED_BY_EMAIL_ASC",
  ComputeNodesByDataRoomIdMaxCreatedByEmailDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_CREATED_BY_EMAIL_DESC",
  ComputeNodesByDataRoomIdMaxDataRoomIdAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_DATA_ROOM_ID_ASC",
  ComputeNodesByDataRoomIdMaxDataRoomIdDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_DATA_ROOM_ID_DESC",
  ComputeNodesByDataRoomIdMaxIndexAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_INDEX_ASC",
  ComputeNodesByDataRoomIdMaxIndexDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_INDEX_DESC",
  ComputeNodesByDataRoomIdMaxNodeNameAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_NODE_NAME_ASC",
  ComputeNodesByDataRoomIdMaxNodeNameDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_NODE_NAME_DESC",
  ComputeNodesByDataRoomIdMaxUpdatedAtAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_UPDATED_AT_ASC",
  ComputeNodesByDataRoomIdMaxUpdatedAtDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_UPDATED_AT_DESC",
  ComputeNodesByDataRoomIdMaxUpdatedByEmailAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_UPDATED_BY_EMAIL_ASC",
  ComputeNodesByDataRoomIdMaxUpdatedByEmailDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MAX_UPDATED_BY_EMAIL_DESC",
  ComputeNodesByDataRoomIdMinComputeNodeIdAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_COMPUTE_NODE_ID_ASC",
  ComputeNodesByDataRoomIdMinComputeNodeIdDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_COMPUTE_NODE_ID_DESC",
  ComputeNodesByDataRoomIdMinComputeNodeTypeAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_COMPUTE_NODE_TYPE_ASC",
  ComputeNodesByDataRoomIdMinComputeNodeTypeDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_COMPUTE_NODE_TYPE_DESC",
  ComputeNodesByDataRoomIdMinConfigAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_CONFIG_ASC",
  ComputeNodesByDataRoomIdMinConfigDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_CONFIG_DESC",
  ComputeNodesByDataRoomIdMinCreatedAtAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_CREATED_AT_ASC",
  ComputeNodesByDataRoomIdMinCreatedAtDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_CREATED_AT_DESC",
  ComputeNodesByDataRoomIdMinCreatedByEmailAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_CREATED_BY_EMAIL_ASC",
  ComputeNodesByDataRoomIdMinCreatedByEmailDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_CREATED_BY_EMAIL_DESC",
  ComputeNodesByDataRoomIdMinDataRoomIdAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_DATA_ROOM_ID_ASC",
  ComputeNodesByDataRoomIdMinDataRoomIdDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_DATA_ROOM_ID_DESC",
  ComputeNodesByDataRoomIdMinIndexAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_INDEX_ASC",
  ComputeNodesByDataRoomIdMinIndexDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_INDEX_DESC",
  ComputeNodesByDataRoomIdMinNodeNameAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_NODE_NAME_ASC",
  ComputeNodesByDataRoomIdMinNodeNameDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_NODE_NAME_DESC",
  ComputeNodesByDataRoomIdMinUpdatedAtAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_UPDATED_AT_ASC",
  ComputeNodesByDataRoomIdMinUpdatedAtDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_UPDATED_AT_DESC",
  ComputeNodesByDataRoomIdMinUpdatedByEmailAsc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_UPDATED_BY_EMAIL_ASC",
  ComputeNodesByDataRoomIdMinUpdatedByEmailDesc = "COMPUTE_NODES_BY_DATA_ROOM_ID_MIN_UPDATED_BY_EMAIL_DESC",
  ComputeNodesCountAsc = "COMPUTE_NODES_COUNT_ASC",
  ComputeNodesCountDesc = "COMPUTE_NODES_COUNT_DESC",
  ComputeNodesDistinctCountComputeNodeIdAsc = "COMPUTE_NODES_DISTINCT_COUNT_COMPUTE_NODE_ID_ASC",
  ComputeNodesDistinctCountComputeNodeIdDesc = "COMPUTE_NODES_DISTINCT_COUNT_COMPUTE_NODE_ID_DESC",
  ComputeNodesDistinctCountComputeNodeTypeAsc = "COMPUTE_NODES_DISTINCT_COUNT_COMPUTE_NODE_TYPE_ASC",
  ComputeNodesDistinctCountComputeNodeTypeDesc = "COMPUTE_NODES_DISTINCT_COUNT_COMPUTE_NODE_TYPE_DESC",
  ComputeNodesDistinctCountConfigAsc = "COMPUTE_NODES_DISTINCT_COUNT_CONFIG_ASC",
  ComputeNodesDistinctCountConfigDesc = "COMPUTE_NODES_DISTINCT_COUNT_CONFIG_DESC",
  ComputeNodesDistinctCountCreatedAtAsc = "COMPUTE_NODES_DISTINCT_COUNT_CREATED_AT_ASC",
  ComputeNodesDistinctCountCreatedAtDesc = "COMPUTE_NODES_DISTINCT_COUNT_CREATED_AT_DESC",
  ComputeNodesDistinctCountCreatedByEmailAsc = "COMPUTE_NODES_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  ComputeNodesDistinctCountCreatedByEmailDesc = "COMPUTE_NODES_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  ComputeNodesDistinctCountDataRoomIdAsc = "COMPUTE_NODES_DISTINCT_COUNT_DATA_ROOM_ID_ASC",
  ComputeNodesDistinctCountDataRoomIdDesc = "COMPUTE_NODES_DISTINCT_COUNT_DATA_ROOM_ID_DESC",
  ComputeNodesDistinctCountIndexAsc = "COMPUTE_NODES_DISTINCT_COUNT_INDEX_ASC",
  ComputeNodesDistinctCountIndexDesc = "COMPUTE_NODES_DISTINCT_COUNT_INDEX_DESC",
  ComputeNodesDistinctCountNodeNameAsc = "COMPUTE_NODES_DISTINCT_COUNT_NODE_NAME_ASC",
  ComputeNodesDistinctCountNodeNameDesc = "COMPUTE_NODES_DISTINCT_COUNT_NODE_NAME_DESC",
  ComputeNodesDistinctCountUpdatedAtAsc = "COMPUTE_NODES_DISTINCT_COUNT_UPDATED_AT_ASC",
  ComputeNodesDistinctCountUpdatedAtDesc = "COMPUTE_NODES_DISTINCT_COUNT_UPDATED_AT_DESC",
  ComputeNodesDistinctCountUpdatedByEmailAsc = "COMPUTE_NODES_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  ComputeNodesDistinctCountUpdatedByEmailDesc = "COMPUTE_NODES_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  ComputeNodesMaxComputeNodeIdAsc = "COMPUTE_NODES_MAX_COMPUTE_NODE_ID_ASC",
  ComputeNodesMaxComputeNodeIdDesc = "COMPUTE_NODES_MAX_COMPUTE_NODE_ID_DESC",
  ComputeNodesMaxComputeNodeTypeAsc = "COMPUTE_NODES_MAX_COMPUTE_NODE_TYPE_ASC",
  ComputeNodesMaxComputeNodeTypeDesc = "COMPUTE_NODES_MAX_COMPUTE_NODE_TYPE_DESC",
  ComputeNodesMaxConfigAsc = "COMPUTE_NODES_MAX_CONFIG_ASC",
  ComputeNodesMaxConfigDesc = "COMPUTE_NODES_MAX_CONFIG_DESC",
  ComputeNodesMaxCreatedAtAsc = "COMPUTE_NODES_MAX_CREATED_AT_ASC",
  ComputeNodesMaxCreatedAtDesc = "COMPUTE_NODES_MAX_CREATED_AT_DESC",
  ComputeNodesMaxCreatedByEmailAsc = "COMPUTE_NODES_MAX_CREATED_BY_EMAIL_ASC",
  ComputeNodesMaxCreatedByEmailDesc = "COMPUTE_NODES_MAX_CREATED_BY_EMAIL_DESC",
  ComputeNodesMaxDataRoomIdAsc = "COMPUTE_NODES_MAX_DATA_ROOM_ID_ASC",
  ComputeNodesMaxDataRoomIdDesc = "COMPUTE_NODES_MAX_DATA_ROOM_ID_DESC",
  ComputeNodesMaxIndexAsc = "COMPUTE_NODES_MAX_INDEX_ASC",
  ComputeNodesMaxIndexDesc = "COMPUTE_NODES_MAX_INDEX_DESC",
  ComputeNodesMaxNodeNameAsc = "COMPUTE_NODES_MAX_NODE_NAME_ASC",
  ComputeNodesMaxNodeNameDesc = "COMPUTE_NODES_MAX_NODE_NAME_DESC",
  ComputeNodesMaxUpdatedAtAsc = "COMPUTE_NODES_MAX_UPDATED_AT_ASC",
  ComputeNodesMaxUpdatedAtDesc = "COMPUTE_NODES_MAX_UPDATED_AT_DESC",
  ComputeNodesMaxUpdatedByEmailAsc = "COMPUTE_NODES_MAX_UPDATED_BY_EMAIL_ASC",
  ComputeNodesMaxUpdatedByEmailDesc = "COMPUTE_NODES_MAX_UPDATED_BY_EMAIL_DESC",
  ComputeNodesMinComputeNodeIdAsc = "COMPUTE_NODES_MIN_COMPUTE_NODE_ID_ASC",
  ComputeNodesMinComputeNodeIdDesc = "COMPUTE_NODES_MIN_COMPUTE_NODE_ID_DESC",
  ComputeNodesMinComputeNodeTypeAsc = "COMPUTE_NODES_MIN_COMPUTE_NODE_TYPE_ASC",
  ComputeNodesMinComputeNodeTypeDesc = "COMPUTE_NODES_MIN_COMPUTE_NODE_TYPE_DESC",
  ComputeNodesMinConfigAsc = "COMPUTE_NODES_MIN_CONFIG_ASC",
  ComputeNodesMinConfigDesc = "COMPUTE_NODES_MIN_CONFIG_DESC",
  ComputeNodesMinCreatedAtAsc = "COMPUTE_NODES_MIN_CREATED_AT_ASC",
  ComputeNodesMinCreatedAtDesc = "COMPUTE_NODES_MIN_CREATED_AT_DESC",
  ComputeNodesMinCreatedByEmailAsc = "COMPUTE_NODES_MIN_CREATED_BY_EMAIL_ASC",
  ComputeNodesMinCreatedByEmailDesc = "COMPUTE_NODES_MIN_CREATED_BY_EMAIL_DESC",
  ComputeNodesMinDataRoomIdAsc = "COMPUTE_NODES_MIN_DATA_ROOM_ID_ASC",
  ComputeNodesMinDataRoomIdDesc = "COMPUTE_NODES_MIN_DATA_ROOM_ID_DESC",
  ComputeNodesMinIndexAsc = "COMPUTE_NODES_MIN_INDEX_ASC",
  ComputeNodesMinIndexDesc = "COMPUTE_NODES_MIN_INDEX_DESC",
  ComputeNodesMinNodeNameAsc = "COMPUTE_NODES_MIN_NODE_NAME_ASC",
  ComputeNodesMinNodeNameDesc = "COMPUTE_NODES_MIN_NODE_NAME_DESC",
  ComputeNodesMinUpdatedAtAsc = "COMPUTE_NODES_MIN_UPDATED_AT_ASC",
  ComputeNodesMinUpdatedAtDesc = "COMPUTE_NODES_MIN_UPDATED_AT_DESC",
  ComputeNodesMinUpdatedByEmailAsc = "COMPUTE_NODES_MIN_UPDATED_BY_EMAIL_ASC",
  ComputeNodesMinUpdatedByEmailDesc = "COMPUTE_NODES_MIN_UPDATED_BY_EMAIL_DESC",
  ComputeNodesStddevPopulationComputeNodeIdAsc = "COMPUTE_NODES_STDDEV_POPULATION_COMPUTE_NODE_ID_ASC",
  ComputeNodesStddevPopulationComputeNodeIdDesc = "COMPUTE_NODES_STDDEV_POPULATION_COMPUTE_NODE_ID_DESC",
  ComputeNodesStddevPopulationComputeNodeTypeAsc = "COMPUTE_NODES_STDDEV_POPULATION_COMPUTE_NODE_TYPE_ASC",
  ComputeNodesStddevPopulationComputeNodeTypeDesc = "COMPUTE_NODES_STDDEV_POPULATION_COMPUTE_NODE_TYPE_DESC",
  ComputeNodesStddevPopulationConfigAsc = "COMPUTE_NODES_STDDEV_POPULATION_CONFIG_ASC",
  ComputeNodesStddevPopulationConfigDesc = "COMPUTE_NODES_STDDEV_POPULATION_CONFIG_DESC",
  ComputeNodesStddevPopulationCreatedAtAsc = "COMPUTE_NODES_STDDEV_POPULATION_CREATED_AT_ASC",
  ComputeNodesStddevPopulationCreatedAtDesc = "COMPUTE_NODES_STDDEV_POPULATION_CREATED_AT_DESC",
  ComputeNodesStddevPopulationCreatedByEmailAsc = "COMPUTE_NODES_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  ComputeNodesStddevPopulationCreatedByEmailDesc = "COMPUTE_NODES_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  ComputeNodesStddevPopulationDataRoomIdAsc = "COMPUTE_NODES_STDDEV_POPULATION_DATA_ROOM_ID_ASC",
  ComputeNodesStddevPopulationDataRoomIdDesc = "COMPUTE_NODES_STDDEV_POPULATION_DATA_ROOM_ID_DESC",
  ComputeNodesStddevPopulationIndexAsc = "COMPUTE_NODES_STDDEV_POPULATION_INDEX_ASC",
  ComputeNodesStddevPopulationIndexDesc = "COMPUTE_NODES_STDDEV_POPULATION_INDEX_DESC",
  ComputeNodesStddevPopulationNodeNameAsc = "COMPUTE_NODES_STDDEV_POPULATION_NODE_NAME_ASC",
  ComputeNodesStddevPopulationNodeNameDesc = "COMPUTE_NODES_STDDEV_POPULATION_NODE_NAME_DESC",
  ComputeNodesStddevPopulationUpdatedAtAsc = "COMPUTE_NODES_STDDEV_POPULATION_UPDATED_AT_ASC",
  ComputeNodesStddevPopulationUpdatedAtDesc = "COMPUTE_NODES_STDDEV_POPULATION_UPDATED_AT_DESC",
  ComputeNodesStddevPopulationUpdatedByEmailAsc = "COMPUTE_NODES_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  ComputeNodesStddevPopulationUpdatedByEmailDesc = "COMPUTE_NODES_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  ComputeNodesStddevSampleComputeNodeIdAsc = "COMPUTE_NODES_STDDEV_SAMPLE_COMPUTE_NODE_ID_ASC",
  ComputeNodesStddevSampleComputeNodeIdDesc = "COMPUTE_NODES_STDDEV_SAMPLE_COMPUTE_NODE_ID_DESC",
  ComputeNodesStddevSampleComputeNodeTypeAsc = "COMPUTE_NODES_STDDEV_SAMPLE_COMPUTE_NODE_TYPE_ASC",
  ComputeNodesStddevSampleComputeNodeTypeDesc = "COMPUTE_NODES_STDDEV_SAMPLE_COMPUTE_NODE_TYPE_DESC",
  ComputeNodesStddevSampleConfigAsc = "COMPUTE_NODES_STDDEV_SAMPLE_CONFIG_ASC",
  ComputeNodesStddevSampleConfigDesc = "COMPUTE_NODES_STDDEV_SAMPLE_CONFIG_DESC",
  ComputeNodesStddevSampleCreatedAtAsc = "COMPUTE_NODES_STDDEV_SAMPLE_CREATED_AT_ASC",
  ComputeNodesStddevSampleCreatedAtDesc = "COMPUTE_NODES_STDDEV_SAMPLE_CREATED_AT_DESC",
  ComputeNodesStddevSampleCreatedByEmailAsc = "COMPUTE_NODES_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  ComputeNodesStddevSampleCreatedByEmailDesc = "COMPUTE_NODES_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  ComputeNodesStddevSampleDataRoomIdAsc = "COMPUTE_NODES_STDDEV_SAMPLE_DATA_ROOM_ID_ASC",
  ComputeNodesStddevSampleDataRoomIdDesc = "COMPUTE_NODES_STDDEV_SAMPLE_DATA_ROOM_ID_DESC",
  ComputeNodesStddevSampleIndexAsc = "COMPUTE_NODES_STDDEV_SAMPLE_INDEX_ASC",
  ComputeNodesStddevSampleIndexDesc = "COMPUTE_NODES_STDDEV_SAMPLE_INDEX_DESC",
  ComputeNodesStddevSampleNodeNameAsc = "COMPUTE_NODES_STDDEV_SAMPLE_NODE_NAME_ASC",
  ComputeNodesStddevSampleNodeNameDesc = "COMPUTE_NODES_STDDEV_SAMPLE_NODE_NAME_DESC",
  ComputeNodesStddevSampleUpdatedAtAsc = "COMPUTE_NODES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  ComputeNodesStddevSampleUpdatedAtDesc = "COMPUTE_NODES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  ComputeNodesStddevSampleUpdatedByEmailAsc = "COMPUTE_NODES_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  ComputeNodesStddevSampleUpdatedByEmailDesc = "COMPUTE_NODES_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  ComputeNodesSumComputeNodeIdAsc = "COMPUTE_NODES_SUM_COMPUTE_NODE_ID_ASC",
  ComputeNodesSumComputeNodeIdDesc = "COMPUTE_NODES_SUM_COMPUTE_NODE_ID_DESC",
  ComputeNodesSumComputeNodeTypeAsc = "COMPUTE_NODES_SUM_COMPUTE_NODE_TYPE_ASC",
  ComputeNodesSumComputeNodeTypeDesc = "COMPUTE_NODES_SUM_COMPUTE_NODE_TYPE_DESC",
  ComputeNodesSumConfigAsc = "COMPUTE_NODES_SUM_CONFIG_ASC",
  ComputeNodesSumConfigDesc = "COMPUTE_NODES_SUM_CONFIG_DESC",
  ComputeNodesSumCreatedAtAsc = "COMPUTE_NODES_SUM_CREATED_AT_ASC",
  ComputeNodesSumCreatedAtDesc = "COMPUTE_NODES_SUM_CREATED_AT_DESC",
  ComputeNodesSumCreatedByEmailAsc = "COMPUTE_NODES_SUM_CREATED_BY_EMAIL_ASC",
  ComputeNodesSumCreatedByEmailDesc = "COMPUTE_NODES_SUM_CREATED_BY_EMAIL_DESC",
  ComputeNodesSumDataRoomIdAsc = "COMPUTE_NODES_SUM_DATA_ROOM_ID_ASC",
  ComputeNodesSumDataRoomIdDesc = "COMPUTE_NODES_SUM_DATA_ROOM_ID_DESC",
  ComputeNodesSumIndexAsc = "COMPUTE_NODES_SUM_INDEX_ASC",
  ComputeNodesSumIndexDesc = "COMPUTE_NODES_SUM_INDEX_DESC",
  ComputeNodesSumNodeNameAsc = "COMPUTE_NODES_SUM_NODE_NAME_ASC",
  ComputeNodesSumNodeNameDesc = "COMPUTE_NODES_SUM_NODE_NAME_DESC",
  ComputeNodesSumUpdatedAtAsc = "COMPUTE_NODES_SUM_UPDATED_AT_ASC",
  ComputeNodesSumUpdatedAtDesc = "COMPUTE_NODES_SUM_UPDATED_AT_DESC",
  ComputeNodesSumUpdatedByEmailAsc = "COMPUTE_NODES_SUM_UPDATED_BY_EMAIL_ASC",
  ComputeNodesSumUpdatedByEmailDesc = "COMPUTE_NODES_SUM_UPDATED_BY_EMAIL_DESC",
  ComputeNodesVariancePopulationComputeNodeIdAsc = "COMPUTE_NODES_VARIANCE_POPULATION_COMPUTE_NODE_ID_ASC",
  ComputeNodesVariancePopulationComputeNodeIdDesc = "COMPUTE_NODES_VARIANCE_POPULATION_COMPUTE_NODE_ID_DESC",
  ComputeNodesVariancePopulationComputeNodeTypeAsc = "COMPUTE_NODES_VARIANCE_POPULATION_COMPUTE_NODE_TYPE_ASC",
  ComputeNodesVariancePopulationComputeNodeTypeDesc = "COMPUTE_NODES_VARIANCE_POPULATION_COMPUTE_NODE_TYPE_DESC",
  ComputeNodesVariancePopulationConfigAsc = "COMPUTE_NODES_VARIANCE_POPULATION_CONFIG_ASC",
  ComputeNodesVariancePopulationConfigDesc = "COMPUTE_NODES_VARIANCE_POPULATION_CONFIG_DESC",
  ComputeNodesVariancePopulationCreatedAtAsc = "COMPUTE_NODES_VARIANCE_POPULATION_CREATED_AT_ASC",
  ComputeNodesVariancePopulationCreatedAtDesc = "COMPUTE_NODES_VARIANCE_POPULATION_CREATED_AT_DESC",
  ComputeNodesVariancePopulationCreatedByEmailAsc = "COMPUTE_NODES_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  ComputeNodesVariancePopulationCreatedByEmailDesc = "COMPUTE_NODES_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  ComputeNodesVariancePopulationDataRoomIdAsc = "COMPUTE_NODES_VARIANCE_POPULATION_DATA_ROOM_ID_ASC",
  ComputeNodesVariancePopulationDataRoomIdDesc = "COMPUTE_NODES_VARIANCE_POPULATION_DATA_ROOM_ID_DESC",
  ComputeNodesVariancePopulationIndexAsc = "COMPUTE_NODES_VARIANCE_POPULATION_INDEX_ASC",
  ComputeNodesVariancePopulationIndexDesc = "COMPUTE_NODES_VARIANCE_POPULATION_INDEX_DESC",
  ComputeNodesVariancePopulationNodeNameAsc = "COMPUTE_NODES_VARIANCE_POPULATION_NODE_NAME_ASC",
  ComputeNodesVariancePopulationNodeNameDesc = "COMPUTE_NODES_VARIANCE_POPULATION_NODE_NAME_DESC",
  ComputeNodesVariancePopulationUpdatedAtAsc = "COMPUTE_NODES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  ComputeNodesVariancePopulationUpdatedAtDesc = "COMPUTE_NODES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  ComputeNodesVariancePopulationUpdatedByEmailAsc = "COMPUTE_NODES_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  ComputeNodesVariancePopulationUpdatedByEmailDesc = "COMPUTE_NODES_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  ComputeNodesVarianceSampleComputeNodeIdAsc = "COMPUTE_NODES_VARIANCE_SAMPLE_COMPUTE_NODE_ID_ASC",
  ComputeNodesVarianceSampleComputeNodeIdDesc = "COMPUTE_NODES_VARIANCE_SAMPLE_COMPUTE_NODE_ID_DESC",
  ComputeNodesVarianceSampleComputeNodeTypeAsc = "COMPUTE_NODES_VARIANCE_SAMPLE_COMPUTE_NODE_TYPE_ASC",
  ComputeNodesVarianceSampleComputeNodeTypeDesc = "COMPUTE_NODES_VARIANCE_SAMPLE_COMPUTE_NODE_TYPE_DESC",
  ComputeNodesVarianceSampleConfigAsc = "COMPUTE_NODES_VARIANCE_SAMPLE_CONFIG_ASC",
  ComputeNodesVarianceSampleConfigDesc = "COMPUTE_NODES_VARIANCE_SAMPLE_CONFIG_DESC",
  ComputeNodesVarianceSampleCreatedAtAsc = "COMPUTE_NODES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  ComputeNodesVarianceSampleCreatedAtDesc = "COMPUTE_NODES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  ComputeNodesVarianceSampleCreatedByEmailAsc = "COMPUTE_NODES_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  ComputeNodesVarianceSampleCreatedByEmailDesc = "COMPUTE_NODES_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  ComputeNodesVarianceSampleDataRoomIdAsc = "COMPUTE_NODES_VARIANCE_SAMPLE_DATA_ROOM_ID_ASC",
  ComputeNodesVarianceSampleDataRoomIdDesc = "COMPUTE_NODES_VARIANCE_SAMPLE_DATA_ROOM_ID_DESC",
  ComputeNodesVarianceSampleIndexAsc = "COMPUTE_NODES_VARIANCE_SAMPLE_INDEX_ASC",
  ComputeNodesVarianceSampleIndexDesc = "COMPUTE_NODES_VARIANCE_SAMPLE_INDEX_DESC",
  ComputeNodesVarianceSampleNodeNameAsc = "COMPUTE_NODES_VARIANCE_SAMPLE_NODE_NAME_ASC",
  ComputeNodesVarianceSampleNodeNameDesc = "COMPUTE_NODES_VARIANCE_SAMPLE_NODE_NAME_DESC",
  ComputeNodesVarianceSampleUpdatedAtAsc = "COMPUTE_NODES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  ComputeNodesVarianceSampleUpdatedAtDesc = "COMPUTE_NODES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  ComputeNodesVarianceSampleUpdatedByEmailAsc = "COMPUTE_NODES_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  ComputeNodesVarianceSampleUpdatedByEmailDesc = "COMPUTE_NODES_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  ConfigurationsAverageCommitIdAsc = "CONFIGURATIONS_AVERAGE_COMMIT_ID_ASC",
  ConfigurationsAverageCommitIdDesc = "CONFIGURATIONS_AVERAGE_COMMIT_ID_DESC",
  ConfigurationsAverageConfigurationIdAsc = "CONFIGURATIONS_AVERAGE_CONFIGURATION_ID_ASC",
  ConfigurationsAverageConfigurationIdDesc = "CONFIGURATIONS_AVERAGE_CONFIGURATION_ID_DESC",
  ConfigurationsAverageCreatedAtAsc = "CONFIGURATIONS_AVERAGE_CREATED_AT_ASC",
  ConfigurationsAverageCreatedAtDesc = "CONFIGURATIONS_AVERAGE_CREATED_AT_DESC",
  ConfigurationsAverageCreatedByEmailAsc = "CONFIGURATIONS_AVERAGE_CREATED_BY_EMAIL_ASC",
  ConfigurationsAverageCreatedByEmailDesc = "CONFIGURATIONS_AVERAGE_CREATED_BY_EMAIL_DESC",
  ConfigurationsAverageDataRoomIdAsc = "CONFIGURATIONS_AVERAGE_DATA_ROOM_ID_ASC",
  ConfigurationsAverageDataRoomIdDesc = "CONFIGURATIONS_AVERAGE_DATA_ROOM_ID_DESC",
  ConfigurationsAverageIsDevAsc = "CONFIGURATIONS_AVERAGE_IS_DEV_ASC",
  ConfigurationsAverageIsDevDesc = "CONFIGURATIONS_AVERAGE_IS_DEV_DESC",
  ConfigurationsAverageOwnerEmailAsc = "CONFIGURATIONS_AVERAGE_OWNER_EMAIL_ASC",
  ConfigurationsAverageOwnerEmailDesc = "CONFIGURATIONS_AVERAGE_OWNER_EMAIL_DESC",
  ConfigurationsAverageUpdatedAtAsc = "CONFIGURATIONS_AVERAGE_UPDATED_AT_ASC",
  ConfigurationsAverageUpdatedAtDesc = "CONFIGURATIONS_AVERAGE_UPDATED_AT_DESC",
  ConfigurationsAverageUpdatedByEmailAsc = "CONFIGURATIONS_AVERAGE_UPDATED_BY_EMAIL_ASC",
  ConfigurationsAverageUpdatedByEmailDesc = "CONFIGURATIONS_AVERAGE_UPDATED_BY_EMAIL_DESC",
  ConfigurationsByDataRoomIdCountAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_COUNT_ASC",
  ConfigurationsByDataRoomIdCountDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_COUNT_DESC",
  ConfigurationsByDataRoomIdMaxCommitIdAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_COMMIT_ID_ASC",
  ConfigurationsByDataRoomIdMaxCommitIdDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_COMMIT_ID_DESC",
  ConfigurationsByDataRoomIdMaxConfigurationIdAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_CONFIGURATION_ID_ASC",
  ConfigurationsByDataRoomIdMaxConfigurationIdDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_CONFIGURATION_ID_DESC",
  ConfigurationsByDataRoomIdMaxCreatedAtAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_CREATED_AT_ASC",
  ConfigurationsByDataRoomIdMaxCreatedAtDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_CREATED_AT_DESC",
  ConfigurationsByDataRoomIdMaxCreatedByEmailAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_CREATED_BY_EMAIL_ASC",
  ConfigurationsByDataRoomIdMaxCreatedByEmailDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_CREATED_BY_EMAIL_DESC",
  ConfigurationsByDataRoomIdMaxDataRoomIdAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_DATA_ROOM_ID_ASC",
  ConfigurationsByDataRoomIdMaxDataRoomIdDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_DATA_ROOM_ID_DESC",
  ConfigurationsByDataRoomIdMaxIsDevAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_IS_DEV_ASC",
  ConfigurationsByDataRoomIdMaxIsDevDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_IS_DEV_DESC",
  ConfigurationsByDataRoomIdMaxOwnerEmailAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_OWNER_EMAIL_ASC",
  ConfigurationsByDataRoomIdMaxOwnerEmailDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_OWNER_EMAIL_DESC",
  ConfigurationsByDataRoomIdMaxUpdatedAtAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_UPDATED_AT_ASC",
  ConfigurationsByDataRoomIdMaxUpdatedAtDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_UPDATED_AT_DESC",
  ConfigurationsByDataRoomIdMaxUpdatedByEmailAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_UPDATED_BY_EMAIL_ASC",
  ConfigurationsByDataRoomIdMaxUpdatedByEmailDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MAX_UPDATED_BY_EMAIL_DESC",
  ConfigurationsByDataRoomIdMinCommitIdAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_COMMIT_ID_ASC",
  ConfigurationsByDataRoomIdMinCommitIdDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_COMMIT_ID_DESC",
  ConfigurationsByDataRoomIdMinConfigurationIdAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_CONFIGURATION_ID_ASC",
  ConfigurationsByDataRoomIdMinConfigurationIdDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_CONFIGURATION_ID_DESC",
  ConfigurationsByDataRoomIdMinCreatedAtAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_CREATED_AT_ASC",
  ConfigurationsByDataRoomIdMinCreatedAtDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_CREATED_AT_DESC",
  ConfigurationsByDataRoomIdMinCreatedByEmailAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_CREATED_BY_EMAIL_ASC",
  ConfigurationsByDataRoomIdMinCreatedByEmailDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_CREATED_BY_EMAIL_DESC",
  ConfigurationsByDataRoomIdMinDataRoomIdAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_DATA_ROOM_ID_ASC",
  ConfigurationsByDataRoomIdMinDataRoomIdDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_DATA_ROOM_ID_DESC",
  ConfigurationsByDataRoomIdMinIsDevAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_IS_DEV_ASC",
  ConfigurationsByDataRoomIdMinIsDevDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_IS_DEV_DESC",
  ConfigurationsByDataRoomIdMinOwnerEmailAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_OWNER_EMAIL_ASC",
  ConfigurationsByDataRoomIdMinOwnerEmailDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_OWNER_EMAIL_DESC",
  ConfigurationsByDataRoomIdMinUpdatedAtAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_UPDATED_AT_ASC",
  ConfigurationsByDataRoomIdMinUpdatedAtDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_UPDATED_AT_DESC",
  ConfigurationsByDataRoomIdMinUpdatedByEmailAsc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_UPDATED_BY_EMAIL_ASC",
  ConfigurationsByDataRoomIdMinUpdatedByEmailDesc = "CONFIGURATIONS_BY_DATA_ROOM_ID_MIN_UPDATED_BY_EMAIL_DESC",
  ConfigurationsCountAsc = "CONFIGURATIONS_COUNT_ASC",
  ConfigurationsCountDesc = "CONFIGURATIONS_COUNT_DESC",
  ConfigurationsDistinctCountCommitIdAsc = "CONFIGURATIONS_DISTINCT_COUNT_COMMIT_ID_ASC",
  ConfigurationsDistinctCountCommitIdDesc = "CONFIGURATIONS_DISTINCT_COUNT_COMMIT_ID_DESC",
  ConfigurationsDistinctCountConfigurationIdAsc = "CONFIGURATIONS_DISTINCT_COUNT_CONFIGURATION_ID_ASC",
  ConfigurationsDistinctCountConfigurationIdDesc = "CONFIGURATIONS_DISTINCT_COUNT_CONFIGURATION_ID_DESC",
  ConfigurationsDistinctCountCreatedAtAsc = "CONFIGURATIONS_DISTINCT_COUNT_CREATED_AT_ASC",
  ConfigurationsDistinctCountCreatedAtDesc = "CONFIGURATIONS_DISTINCT_COUNT_CREATED_AT_DESC",
  ConfigurationsDistinctCountCreatedByEmailAsc = "CONFIGURATIONS_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  ConfigurationsDistinctCountCreatedByEmailDesc = "CONFIGURATIONS_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  ConfigurationsDistinctCountDataRoomIdAsc = "CONFIGURATIONS_DISTINCT_COUNT_DATA_ROOM_ID_ASC",
  ConfigurationsDistinctCountDataRoomIdDesc = "CONFIGURATIONS_DISTINCT_COUNT_DATA_ROOM_ID_DESC",
  ConfigurationsDistinctCountIsDevAsc = "CONFIGURATIONS_DISTINCT_COUNT_IS_DEV_ASC",
  ConfigurationsDistinctCountIsDevDesc = "CONFIGURATIONS_DISTINCT_COUNT_IS_DEV_DESC",
  ConfigurationsDistinctCountOwnerEmailAsc = "CONFIGURATIONS_DISTINCT_COUNT_OWNER_EMAIL_ASC",
  ConfigurationsDistinctCountOwnerEmailDesc = "CONFIGURATIONS_DISTINCT_COUNT_OWNER_EMAIL_DESC",
  ConfigurationsDistinctCountUpdatedAtAsc = "CONFIGURATIONS_DISTINCT_COUNT_UPDATED_AT_ASC",
  ConfigurationsDistinctCountUpdatedAtDesc = "CONFIGURATIONS_DISTINCT_COUNT_UPDATED_AT_DESC",
  ConfigurationsDistinctCountUpdatedByEmailAsc = "CONFIGURATIONS_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  ConfigurationsDistinctCountUpdatedByEmailDesc = "CONFIGURATIONS_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  ConfigurationsMaxCommitIdAsc = "CONFIGURATIONS_MAX_COMMIT_ID_ASC",
  ConfigurationsMaxCommitIdDesc = "CONFIGURATIONS_MAX_COMMIT_ID_DESC",
  ConfigurationsMaxConfigurationIdAsc = "CONFIGURATIONS_MAX_CONFIGURATION_ID_ASC",
  ConfigurationsMaxConfigurationIdDesc = "CONFIGURATIONS_MAX_CONFIGURATION_ID_DESC",
  ConfigurationsMaxCreatedAtAsc = "CONFIGURATIONS_MAX_CREATED_AT_ASC",
  ConfigurationsMaxCreatedAtDesc = "CONFIGURATIONS_MAX_CREATED_AT_DESC",
  ConfigurationsMaxCreatedByEmailAsc = "CONFIGURATIONS_MAX_CREATED_BY_EMAIL_ASC",
  ConfigurationsMaxCreatedByEmailDesc = "CONFIGURATIONS_MAX_CREATED_BY_EMAIL_DESC",
  ConfigurationsMaxDataRoomIdAsc = "CONFIGURATIONS_MAX_DATA_ROOM_ID_ASC",
  ConfigurationsMaxDataRoomIdDesc = "CONFIGURATIONS_MAX_DATA_ROOM_ID_DESC",
  ConfigurationsMaxIsDevAsc = "CONFIGURATIONS_MAX_IS_DEV_ASC",
  ConfigurationsMaxIsDevDesc = "CONFIGURATIONS_MAX_IS_DEV_DESC",
  ConfigurationsMaxOwnerEmailAsc = "CONFIGURATIONS_MAX_OWNER_EMAIL_ASC",
  ConfigurationsMaxOwnerEmailDesc = "CONFIGURATIONS_MAX_OWNER_EMAIL_DESC",
  ConfigurationsMaxUpdatedAtAsc = "CONFIGURATIONS_MAX_UPDATED_AT_ASC",
  ConfigurationsMaxUpdatedAtDesc = "CONFIGURATIONS_MAX_UPDATED_AT_DESC",
  ConfigurationsMaxUpdatedByEmailAsc = "CONFIGURATIONS_MAX_UPDATED_BY_EMAIL_ASC",
  ConfigurationsMaxUpdatedByEmailDesc = "CONFIGURATIONS_MAX_UPDATED_BY_EMAIL_DESC",
  ConfigurationsMinCommitIdAsc = "CONFIGURATIONS_MIN_COMMIT_ID_ASC",
  ConfigurationsMinCommitIdDesc = "CONFIGURATIONS_MIN_COMMIT_ID_DESC",
  ConfigurationsMinConfigurationIdAsc = "CONFIGURATIONS_MIN_CONFIGURATION_ID_ASC",
  ConfigurationsMinConfigurationIdDesc = "CONFIGURATIONS_MIN_CONFIGURATION_ID_DESC",
  ConfigurationsMinCreatedAtAsc = "CONFIGURATIONS_MIN_CREATED_AT_ASC",
  ConfigurationsMinCreatedAtDesc = "CONFIGURATIONS_MIN_CREATED_AT_DESC",
  ConfigurationsMinCreatedByEmailAsc = "CONFIGURATIONS_MIN_CREATED_BY_EMAIL_ASC",
  ConfigurationsMinCreatedByEmailDesc = "CONFIGURATIONS_MIN_CREATED_BY_EMAIL_DESC",
  ConfigurationsMinDataRoomIdAsc = "CONFIGURATIONS_MIN_DATA_ROOM_ID_ASC",
  ConfigurationsMinDataRoomIdDesc = "CONFIGURATIONS_MIN_DATA_ROOM_ID_DESC",
  ConfigurationsMinIsDevAsc = "CONFIGURATIONS_MIN_IS_DEV_ASC",
  ConfigurationsMinIsDevDesc = "CONFIGURATIONS_MIN_IS_DEV_DESC",
  ConfigurationsMinOwnerEmailAsc = "CONFIGURATIONS_MIN_OWNER_EMAIL_ASC",
  ConfigurationsMinOwnerEmailDesc = "CONFIGURATIONS_MIN_OWNER_EMAIL_DESC",
  ConfigurationsMinUpdatedAtAsc = "CONFIGURATIONS_MIN_UPDATED_AT_ASC",
  ConfigurationsMinUpdatedAtDesc = "CONFIGURATIONS_MIN_UPDATED_AT_DESC",
  ConfigurationsMinUpdatedByEmailAsc = "CONFIGURATIONS_MIN_UPDATED_BY_EMAIL_ASC",
  ConfigurationsMinUpdatedByEmailDesc = "CONFIGURATIONS_MIN_UPDATED_BY_EMAIL_DESC",
  ConfigurationsStddevPopulationCommitIdAsc = "CONFIGURATIONS_STDDEV_POPULATION_COMMIT_ID_ASC",
  ConfigurationsStddevPopulationCommitIdDesc = "CONFIGURATIONS_STDDEV_POPULATION_COMMIT_ID_DESC",
  ConfigurationsStddevPopulationConfigurationIdAsc = "CONFIGURATIONS_STDDEV_POPULATION_CONFIGURATION_ID_ASC",
  ConfigurationsStddevPopulationConfigurationIdDesc = "CONFIGURATIONS_STDDEV_POPULATION_CONFIGURATION_ID_DESC",
  ConfigurationsStddevPopulationCreatedAtAsc = "CONFIGURATIONS_STDDEV_POPULATION_CREATED_AT_ASC",
  ConfigurationsStddevPopulationCreatedAtDesc = "CONFIGURATIONS_STDDEV_POPULATION_CREATED_AT_DESC",
  ConfigurationsStddevPopulationCreatedByEmailAsc = "CONFIGURATIONS_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  ConfigurationsStddevPopulationCreatedByEmailDesc = "CONFIGURATIONS_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  ConfigurationsStddevPopulationDataRoomIdAsc = "CONFIGURATIONS_STDDEV_POPULATION_DATA_ROOM_ID_ASC",
  ConfigurationsStddevPopulationDataRoomIdDesc = "CONFIGURATIONS_STDDEV_POPULATION_DATA_ROOM_ID_DESC",
  ConfigurationsStddevPopulationIsDevAsc = "CONFIGURATIONS_STDDEV_POPULATION_IS_DEV_ASC",
  ConfigurationsStddevPopulationIsDevDesc = "CONFIGURATIONS_STDDEV_POPULATION_IS_DEV_DESC",
  ConfigurationsStddevPopulationOwnerEmailAsc = "CONFIGURATIONS_STDDEV_POPULATION_OWNER_EMAIL_ASC",
  ConfigurationsStddevPopulationOwnerEmailDesc = "CONFIGURATIONS_STDDEV_POPULATION_OWNER_EMAIL_DESC",
  ConfigurationsStddevPopulationUpdatedAtAsc = "CONFIGURATIONS_STDDEV_POPULATION_UPDATED_AT_ASC",
  ConfigurationsStddevPopulationUpdatedAtDesc = "CONFIGURATIONS_STDDEV_POPULATION_UPDATED_AT_DESC",
  ConfigurationsStddevPopulationUpdatedByEmailAsc = "CONFIGURATIONS_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  ConfigurationsStddevPopulationUpdatedByEmailDesc = "CONFIGURATIONS_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  ConfigurationsStddevSampleCommitIdAsc = "CONFIGURATIONS_STDDEV_SAMPLE_COMMIT_ID_ASC",
  ConfigurationsStddevSampleCommitIdDesc = "CONFIGURATIONS_STDDEV_SAMPLE_COMMIT_ID_DESC",
  ConfigurationsStddevSampleConfigurationIdAsc = "CONFIGURATIONS_STDDEV_SAMPLE_CONFIGURATION_ID_ASC",
  ConfigurationsStddevSampleConfigurationIdDesc = "CONFIGURATIONS_STDDEV_SAMPLE_CONFIGURATION_ID_DESC",
  ConfigurationsStddevSampleCreatedAtAsc = "CONFIGURATIONS_STDDEV_SAMPLE_CREATED_AT_ASC",
  ConfigurationsStddevSampleCreatedAtDesc = "CONFIGURATIONS_STDDEV_SAMPLE_CREATED_AT_DESC",
  ConfigurationsStddevSampleCreatedByEmailAsc = "CONFIGURATIONS_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  ConfigurationsStddevSampleCreatedByEmailDesc = "CONFIGURATIONS_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  ConfigurationsStddevSampleDataRoomIdAsc = "CONFIGURATIONS_STDDEV_SAMPLE_DATA_ROOM_ID_ASC",
  ConfigurationsStddevSampleDataRoomIdDesc = "CONFIGURATIONS_STDDEV_SAMPLE_DATA_ROOM_ID_DESC",
  ConfigurationsStddevSampleIsDevAsc = "CONFIGURATIONS_STDDEV_SAMPLE_IS_DEV_ASC",
  ConfigurationsStddevSampleIsDevDesc = "CONFIGURATIONS_STDDEV_SAMPLE_IS_DEV_DESC",
  ConfigurationsStddevSampleOwnerEmailAsc = "CONFIGURATIONS_STDDEV_SAMPLE_OWNER_EMAIL_ASC",
  ConfigurationsStddevSampleOwnerEmailDesc = "CONFIGURATIONS_STDDEV_SAMPLE_OWNER_EMAIL_DESC",
  ConfigurationsStddevSampleUpdatedAtAsc = "CONFIGURATIONS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  ConfigurationsStddevSampleUpdatedAtDesc = "CONFIGURATIONS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  ConfigurationsStddevSampleUpdatedByEmailAsc = "CONFIGURATIONS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  ConfigurationsStddevSampleUpdatedByEmailDesc = "CONFIGURATIONS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  ConfigurationsSumCommitIdAsc = "CONFIGURATIONS_SUM_COMMIT_ID_ASC",
  ConfigurationsSumCommitIdDesc = "CONFIGURATIONS_SUM_COMMIT_ID_DESC",
  ConfigurationsSumConfigurationIdAsc = "CONFIGURATIONS_SUM_CONFIGURATION_ID_ASC",
  ConfigurationsSumConfigurationIdDesc = "CONFIGURATIONS_SUM_CONFIGURATION_ID_DESC",
  ConfigurationsSumCreatedAtAsc = "CONFIGURATIONS_SUM_CREATED_AT_ASC",
  ConfigurationsSumCreatedAtDesc = "CONFIGURATIONS_SUM_CREATED_AT_DESC",
  ConfigurationsSumCreatedByEmailAsc = "CONFIGURATIONS_SUM_CREATED_BY_EMAIL_ASC",
  ConfigurationsSumCreatedByEmailDesc = "CONFIGURATIONS_SUM_CREATED_BY_EMAIL_DESC",
  ConfigurationsSumDataRoomIdAsc = "CONFIGURATIONS_SUM_DATA_ROOM_ID_ASC",
  ConfigurationsSumDataRoomIdDesc = "CONFIGURATIONS_SUM_DATA_ROOM_ID_DESC",
  ConfigurationsSumIsDevAsc = "CONFIGURATIONS_SUM_IS_DEV_ASC",
  ConfigurationsSumIsDevDesc = "CONFIGURATIONS_SUM_IS_DEV_DESC",
  ConfigurationsSumOwnerEmailAsc = "CONFIGURATIONS_SUM_OWNER_EMAIL_ASC",
  ConfigurationsSumOwnerEmailDesc = "CONFIGURATIONS_SUM_OWNER_EMAIL_DESC",
  ConfigurationsSumUpdatedAtAsc = "CONFIGURATIONS_SUM_UPDATED_AT_ASC",
  ConfigurationsSumUpdatedAtDesc = "CONFIGURATIONS_SUM_UPDATED_AT_DESC",
  ConfigurationsSumUpdatedByEmailAsc = "CONFIGURATIONS_SUM_UPDATED_BY_EMAIL_ASC",
  ConfigurationsSumUpdatedByEmailDesc = "CONFIGURATIONS_SUM_UPDATED_BY_EMAIL_DESC",
  ConfigurationsVariancePopulationCommitIdAsc = "CONFIGURATIONS_VARIANCE_POPULATION_COMMIT_ID_ASC",
  ConfigurationsVariancePopulationCommitIdDesc = "CONFIGURATIONS_VARIANCE_POPULATION_COMMIT_ID_DESC",
  ConfigurationsVariancePopulationConfigurationIdAsc = "CONFIGURATIONS_VARIANCE_POPULATION_CONFIGURATION_ID_ASC",
  ConfigurationsVariancePopulationConfigurationIdDesc = "CONFIGURATIONS_VARIANCE_POPULATION_CONFIGURATION_ID_DESC",
  ConfigurationsVariancePopulationCreatedAtAsc = "CONFIGURATIONS_VARIANCE_POPULATION_CREATED_AT_ASC",
  ConfigurationsVariancePopulationCreatedAtDesc = "CONFIGURATIONS_VARIANCE_POPULATION_CREATED_AT_DESC",
  ConfigurationsVariancePopulationCreatedByEmailAsc = "CONFIGURATIONS_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  ConfigurationsVariancePopulationCreatedByEmailDesc = "CONFIGURATIONS_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  ConfigurationsVariancePopulationDataRoomIdAsc = "CONFIGURATIONS_VARIANCE_POPULATION_DATA_ROOM_ID_ASC",
  ConfigurationsVariancePopulationDataRoomIdDesc = "CONFIGURATIONS_VARIANCE_POPULATION_DATA_ROOM_ID_DESC",
  ConfigurationsVariancePopulationIsDevAsc = "CONFIGURATIONS_VARIANCE_POPULATION_IS_DEV_ASC",
  ConfigurationsVariancePopulationIsDevDesc = "CONFIGURATIONS_VARIANCE_POPULATION_IS_DEV_DESC",
  ConfigurationsVariancePopulationOwnerEmailAsc = "CONFIGURATIONS_VARIANCE_POPULATION_OWNER_EMAIL_ASC",
  ConfigurationsVariancePopulationOwnerEmailDesc = "CONFIGURATIONS_VARIANCE_POPULATION_OWNER_EMAIL_DESC",
  ConfigurationsVariancePopulationUpdatedAtAsc = "CONFIGURATIONS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  ConfigurationsVariancePopulationUpdatedAtDesc = "CONFIGURATIONS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  ConfigurationsVariancePopulationUpdatedByEmailAsc = "CONFIGURATIONS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  ConfigurationsVariancePopulationUpdatedByEmailDesc = "CONFIGURATIONS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  ConfigurationsVarianceSampleCommitIdAsc = "CONFIGURATIONS_VARIANCE_SAMPLE_COMMIT_ID_ASC",
  ConfigurationsVarianceSampleCommitIdDesc = "CONFIGURATIONS_VARIANCE_SAMPLE_COMMIT_ID_DESC",
  ConfigurationsVarianceSampleConfigurationIdAsc = "CONFIGURATIONS_VARIANCE_SAMPLE_CONFIGURATION_ID_ASC",
  ConfigurationsVarianceSampleConfigurationIdDesc = "CONFIGURATIONS_VARIANCE_SAMPLE_CONFIGURATION_ID_DESC",
  ConfigurationsVarianceSampleCreatedAtAsc = "CONFIGURATIONS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  ConfigurationsVarianceSampleCreatedAtDesc = "CONFIGURATIONS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  ConfigurationsVarianceSampleCreatedByEmailAsc = "CONFIGURATIONS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  ConfigurationsVarianceSampleCreatedByEmailDesc = "CONFIGURATIONS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  ConfigurationsVarianceSampleDataRoomIdAsc = "CONFIGURATIONS_VARIANCE_SAMPLE_DATA_ROOM_ID_ASC",
  ConfigurationsVarianceSampleDataRoomIdDesc = "CONFIGURATIONS_VARIANCE_SAMPLE_DATA_ROOM_ID_DESC",
  ConfigurationsVarianceSampleIsDevAsc = "CONFIGURATIONS_VARIANCE_SAMPLE_IS_DEV_ASC",
  ConfigurationsVarianceSampleIsDevDesc = "CONFIGURATIONS_VARIANCE_SAMPLE_IS_DEV_DESC",
  ConfigurationsVarianceSampleOwnerEmailAsc = "CONFIGURATIONS_VARIANCE_SAMPLE_OWNER_EMAIL_ASC",
  ConfigurationsVarianceSampleOwnerEmailDesc = "CONFIGURATIONS_VARIANCE_SAMPLE_OWNER_EMAIL_DESC",
  ConfigurationsVarianceSampleUpdatedAtAsc = "CONFIGURATIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  ConfigurationsVarianceSampleUpdatedAtDesc = "CONFIGURATIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  ConfigurationsVarianceSampleUpdatedByEmailAsc = "CONFIGURATIONS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  ConfigurationsVarianceSampleUpdatedByEmailDesc = "CONFIGURATIONS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  ConfigByDataRoomIdComputeNodesOrderAsc = "CONFIG_BY_DATA_ROOM_ID_COMPUTE_NODES_ORDER_ASC",
  ConfigByDataRoomIdComputeNodesOrderDesc = "CONFIG_BY_DATA_ROOM_ID_COMPUTE_NODES_ORDER_DESC",
  ConfigByDataRoomIdCreatedAtAsc = "CONFIG_BY_DATA_ROOM_ID_CREATED_AT_ASC",
  ConfigByDataRoomIdCreatedAtDesc = "CONFIG_BY_DATA_ROOM_ID_CREATED_AT_DESC",
  ConfigByDataRoomIdCreatedByEmailAsc = "CONFIG_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_ASC",
  ConfigByDataRoomIdCreatedByEmailDesc = "CONFIG_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_DESC",
  ConfigByDataRoomIdDataNodesOrderAsc = "CONFIG_BY_DATA_ROOM_ID_DATA_NODES_ORDER_ASC",
  ConfigByDataRoomIdDataNodesOrderDesc = "CONFIG_BY_DATA_ROOM_ID_DATA_NODES_ORDER_DESC",
  ConfigByDataRoomIdDataRoomIdAsc = "CONFIG_BY_DATA_ROOM_ID_DATA_ROOM_ID_ASC",
  ConfigByDataRoomIdDataRoomIdDesc = "CONFIG_BY_DATA_ROOM_ID_DATA_ROOM_ID_DESC",
  ConfigByDataRoomIdShowOrganizationLogoAsc = "CONFIG_BY_DATA_ROOM_ID_SHOW_ORGANIZATION_LOGO_ASC",
  ConfigByDataRoomIdShowOrganizationLogoDesc = "CONFIG_BY_DATA_ROOM_ID_SHOW_ORGANIZATION_LOGO_DESC",
  ConfigByDataRoomIdUpdatedAtAsc = "CONFIG_BY_DATA_ROOM_ID_UPDATED_AT_ASC",
  ConfigByDataRoomIdUpdatedAtDesc = "CONFIG_BY_DATA_ROOM_ID_UPDATED_AT_DESC",
  ConfigByDataRoomIdUpdatedByEmailAsc = "CONFIG_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_ASC",
  ConfigByDataRoomIdUpdatedByEmailDesc = "CONFIG_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  DataRoomHashAsc = "DATA_ROOM_HASH_ASC",
  DataRoomHashDesc = "DATA_ROOM_HASH_DESC",
  DataRoomIdAsc = "DATA_ROOM_ID_ASC",
  DataRoomIdDesc = "DATA_ROOM_ID_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  EnableDevelopmentTabAsc = "ENABLE_DEVELOPMENT_TAB_ASC",
  EnableDevelopmentTabDesc = "ENABLE_DEVELOPMENT_TAB_DESC",
  GovernanceProtocolAsc = "GOVERNANCE_PROTOCOL_ASC",
  GovernanceProtocolDesc = "GOVERNANCE_PROTOCOL_DESC",
  LockByDataRoomIdCreatedAtAsc = "LOCK_BY_DATA_ROOM_ID_CREATED_AT_ASC",
  LockByDataRoomIdCreatedAtDesc = "LOCK_BY_DATA_ROOM_ID_CREATED_AT_DESC",
  LockByDataRoomIdCreatedByEmailAsc = "LOCK_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_ASC",
  LockByDataRoomIdCreatedByEmailDesc = "LOCK_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_DESC",
  LockByDataRoomIdDataRoomIdAsc = "LOCK_BY_DATA_ROOM_ID_DATA_ROOM_ID_ASC",
  LockByDataRoomIdDataRoomIdDesc = "LOCK_BY_DATA_ROOM_ID_DATA_ROOM_ID_DESC",
  LockByDataRoomIdIsLockedAsc = "LOCK_BY_DATA_ROOM_ID_IS_LOCKED_ASC",
  LockByDataRoomIdIsLockedDesc = "LOCK_BY_DATA_ROOM_ID_IS_LOCKED_DESC",
  LockByDataRoomIdUpdatedAtAsc = "LOCK_BY_DATA_ROOM_ID_UPDATED_AT_ASC",
  LockByDataRoomIdUpdatedAtDesc = "LOCK_BY_DATA_ROOM_ID_UPDATED_AT_DESC",
  LockByDataRoomIdUpdatedByEmailAsc = "LOCK_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_ASC",
  LockByDataRoomIdUpdatedByEmailDesc = "LOCK_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_DESC",
  MrenclaveAsc = "MRENCLAVE_ASC",
  MrenclaveDesc = "MRENCLAVE_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OwnerEmailAsc = "OWNER_EMAIL_ASC",
  OwnerEmailDesc = "OWNER_EMAIL_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SourceAsc = "SOURCE_ASC",
  SourceDesc = "SOURCE_DESC",
  StateByDataRoomIdCreatedAtAsc = "STATE_BY_DATA_ROOM_ID_CREATED_AT_ASC",
  StateByDataRoomIdCreatedAtDesc = "STATE_BY_DATA_ROOM_ID_CREATED_AT_DESC",
  StateByDataRoomIdCreatedByEmailAsc = "STATE_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_ASC",
  StateByDataRoomIdCreatedByEmailDesc = "STATE_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_DESC",
  StateByDataRoomIdDataRoomIdAsc = "STATE_BY_DATA_ROOM_ID_DATA_ROOM_ID_ASC",
  StateByDataRoomIdDataRoomIdDesc = "STATE_BY_DATA_ROOM_ID_DATA_ROOM_ID_DESC",
  StateByDataRoomIdStatusAsc = "STATE_BY_DATA_ROOM_ID_STATUS_ASC",
  StateByDataRoomIdStatusDesc = "STATE_BY_DATA_ROOM_ID_STATUS_DESC",
  StateByDataRoomIdStatusUpdatedAtAsc = "STATE_BY_DATA_ROOM_ID_STATUS_UPDATED_AT_ASC",
  StateByDataRoomIdStatusUpdatedAtDesc = "STATE_BY_DATA_ROOM_ID_STATUS_UPDATED_AT_DESC",
  StateByDataRoomIdUpdatedAtAsc = "STATE_BY_DATA_ROOM_ID_UPDATED_AT_ASC",
  StateByDataRoomIdUpdatedAtDesc = "STATE_BY_DATA_ROOM_ID_UPDATED_AT_DESC",
  StateByDataRoomIdUpdatedByEmailAsc = "STATE_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_ASC",
  StateByDataRoomIdUpdatedByEmailDesc = "STATE_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
  UserByOwnerEmailCreatedAtAsc = "USER_BY_OWNER_EMAIL_CREATED_AT_ASC",
  UserByOwnerEmailCreatedAtDesc = "USER_BY_OWNER_EMAIL_CREATED_AT_DESC",
  UserByOwnerEmailCreatedByEmailAsc = "USER_BY_OWNER_EMAIL_CREATED_BY_EMAIL_ASC",
  UserByOwnerEmailCreatedByEmailDesc = "USER_BY_OWNER_EMAIL_CREATED_BY_EMAIL_DESC",
  UserByOwnerEmailDomainAsc = "USER_BY_OWNER_EMAIL_DOMAIN_ASC",
  UserByOwnerEmailDomainDesc = "USER_BY_OWNER_EMAIL_DOMAIN_DESC",
  UserByOwnerEmailEmailAsc = "USER_BY_OWNER_EMAIL_EMAIL_ASC",
  UserByOwnerEmailEmailDesc = "USER_BY_OWNER_EMAIL_EMAIL_DESC",
  UserByOwnerEmailEmailVerifiedAsc = "USER_BY_OWNER_EMAIL_EMAIL_VERIFIED_ASC",
  UserByOwnerEmailEmailVerifiedDesc = "USER_BY_OWNER_EMAIL_EMAIL_VERIFIED_DESC",
  UserByOwnerEmailFamilyNameAsc = "USER_BY_OWNER_EMAIL_FAMILY_NAME_ASC",
  UserByOwnerEmailFamilyNameDesc = "USER_BY_OWNER_EMAIL_FAMILY_NAME_DESC",
  UserByOwnerEmailGivenNameAsc = "USER_BY_OWNER_EMAIL_GIVEN_NAME_ASC",
  UserByOwnerEmailGivenNameDesc = "USER_BY_OWNER_EMAIL_GIVEN_NAME_DESC",
  UserByOwnerEmailNameAsc = "USER_BY_OWNER_EMAIL_NAME_ASC",
  UserByOwnerEmailNameDesc = "USER_BY_OWNER_EMAIL_NAME_DESC",
  UserByOwnerEmailNicknameAsc = "USER_BY_OWNER_EMAIL_NICKNAME_ASC",
  UserByOwnerEmailNicknameDesc = "USER_BY_OWNER_EMAIL_NICKNAME_DESC",
  UserByOwnerEmailPictureAsc = "USER_BY_OWNER_EMAIL_PICTURE_ASC",
  UserByOwnerEmailPictureDesc = "USER_BY_OWNER_EMAIL_PICTURE_DESC",
  UserByOwnerEmailUpdatedAtAsc = "USER_BY_OWNER_EMAIL_UPDATED_AT_ASC",
  UserByOwnerEmailUpdatedAtDesc = "USER_BY_OWNER_EMAIL_UPDATED_AT_DESC",
  UserByOwnerEmailUpdatedByEmailAsc = "USER_BY_OWNER_EMAIL_UPDATED_BY_EMAIL_ASC",
  UserByOwnerEmailUpdatedByEmailDesc = "USER_BY_OWNER_EMAIL_UPDATED_BY_EMAIL_DESC",
  UserByOwnerEmailUpstreamUserIdAsc = "USER_BY_OWNER_EMAIL_UPSTREAM_USER_ID_ASC",
  UserByOwnerEmailUpstreamUserIdDesc = "USER_BY_OWNER_EMAIL_UPSTREAM_USER_ID_DESC",
  UserByOwnerEmailUserIdAsc = "USER_BY_OWNER_EMAIL_USER_ID_ASC",
  UserByOwnerEmailUserIdDesc = "USER_BY_OWNER_EMAIL_USER_ID_DESC",
  UserConfigsAverageCreatedAtAsc = "USER_CONFIGS_AVERAGE_CREATED_AT_ASC",
  UserConfigsAverageCreatedAtDesc = "USER_CONFIGS_AVERAGE_CREATED_AT_DESC",
  UserConfigsAverageCreatedByEmailAsc = "USER_CONFIGS_AVERAGE_CREATED_BY_EMAIL_ASC",
  UserConfigsAverageCreatedByEmailDesc = "USER_CONFIGS_AVERAGE_CREATED_BY_EMAIL_DESC",
  UserConfigsAverageDataRoomIdAsc = "USER_CONFIGS_AVERAGE_DATA_ROOM_ID_ASC",
  UserConfigsAverageDataRoomIdDesc = "USER_CONFIGS_AVERAGE_DATA_ROOM_ID_DESC",
  UserConfigsAverageEmailAsc = "USER_CONFIGS_AVERAGE_EMAIL_ASC",
  UserConfigsAverageEmailDesc = "USER_CONFIGS_AVERAGE_EMAIL_DESC",
  UserConfigsAverageIsArchivedAsc = "USER_CONFIGS_AVERAGE_IS_ARCHIVED_ASC",
  UserConfigsAverageIsArchivedDesc = "USER_CONFIGS_AVERAGE_IS_ARCHIVED_DESC",
  UserConfigsAverageIsFavoriteAsc = "USER_CONFIGS_AVERAGE_IS_FAVORITE_ASC",
  UserConfigsAverageIsFavoriteDesc = "USER_CONFIGS_AVERAGE_IS_FAVORITE_DESC",
  UserConfigsAverageUpdatedAtAsc = "USER_CONFIGS_AVERAGE_UPDATED_AT_ASC",
  UserConfigsAverageUpdatedAtDesc = "USER_CONFIGS_AVERAGE_UPDATED_AT_DESC",
  UserConfigsAverageUpdatedByEmailAsc = "USER_CONFIGS_AVERAGE_UPDATED_BY_EMAIL_ASC",
  UserConfigsAverageUpdatedByEmailDesc = "USER_CONFIGS_AVERAGE_UPDATED_BY_EMAIL_DESC",
  UserConfigsAverageUserConfigIdAsc = "USER_CONFIGS_AVERAGE_USER_CONFIG_ID_ASC",
  UserConfigsAverageUserConfigIdDesc = "USER_CONFIGS_AVERAGE_USER_CONFIG_ID_DESC",
  UserConfigsByDataRoomIdCountAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_COUNT_ASC",
  UserConfigsByDataRoomIdCountDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_COUNT_DESC",
  UserConfigsByDataRoomIdMaxCreatedAtAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_CREATED_AT_ASC",
  UserConfigsByDataRoomIdMaxCreatedAtDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_CREATED_AT_DESC",
  UserConfigsByDataRoomIdMaxCreatedByEmailAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_CREATED_BY_EMAIL_ASC",
  UserConfigsByDataRoomIdMaxCreatedByEmailDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_CREATED_BY_EMAIL_DESC",
  UserConfigsByDataRoomIdMaxDataRoomIdAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_DATA_ROOM_ID_ASC",
  UserConfigsByDataRoomIdMaxDataRoomIdDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_DATA_ROOM_ID_DESC",
  UserConfigsByDataRoomIdMaxEmailAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_EMAIL_ASC",
  UserConfigsByDataRoomIdMaxEmailDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_EMAIL_DESC",
  UserConfigsByDataRoomIdMaxIsArchivedAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_IS_ARCHIVED_ASC",
  UserConfigsByDataRoomIdMaxIsArchivedDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_IS_ARCHIVED_DESC",
  UserConfigsByDataRoomIdMaxIsFavoriteAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_IS_FAVORITE_ASC",
  UserConfigsByDataRoomIdMaxIsFavoriteDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_IS_FAVORITE_DESC",
  UserConfigsByDataRoomIdMaxUpdatedAtAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_UPDATED_AT_ASC",
  UserConfigsByDataRoomIdMaxUpdatedAtDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_UPDATED_AT_DESC",
  UserConfigsByDataRoomIdMaxUpdatedByEmailAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_UPDATED_BY_EMAIL_ASC",
  UserConfigsByDataRoomIdMaxUpdatedByEmailDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_UPDATED_BY_EMAIL_DESC",
  UserConfigsByDataRoomIdMaxUserConfigIdAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_USER_CONFIG_ID_ASC",
  UserConfigsByDataRoomIdMaxUserConfigIdDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MAX_USER_CONFIG_ID_DESC",
  UserConfigsByDataRoomIdMinCreatedAtAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_CREATED_AT_ASC",
  UserConfigsByDataRoomIdMinCreatedAtDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_CREATED_AT_DESC",
  UserConfigsByDataRoomIdMinCreatedByEmailAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_CREATED_BY_EMAIL_ASC",
  UserConfigsByDataRoomIdMinCreatedByEmailDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_CREATED_BY_EMAIL_DESC",
  UserConfigsByDataRoomIdMinDataRoomIdAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_DATA_ROOM_ID_ASC",
  UserConfigsByDataRoomIdMinDataRoomIdDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_DATA_ROOM_ID_DESC",
  UserConfigsByDataRoomIdMinEmailAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_EMAIL_ASC",
  UserConfigsByDataRoomIdMinEmailDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_EMAIL_DESC",
  UserConfigsByDataRoomIdMinIsArchivedAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_IS_ARCHIVED_ASC",
  UserConfigsByDataRoomIdMinIsArchivedDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_IS_ARCHIVED_DESC",
  UserConfigsByDataRoomIdMinIsFavoriteAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_IS_FAVORITE_ASC",
  UserConfigsByDataRoomIdMinIsFavoriteDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_IS_FAVORITE_DESC",
  UserConfigsByDataRoomIdMinUpdatedAtAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_UPDATED_AT_ASC",
  UserConfigsByDataRoomIdMinUpdatedAtDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_UPDATED_AT_DESC",
  UserConfigsByDataRoomIdMinUpdatedByEmailAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_UPDATED_BY_EMAIL_ASC",
  UserConfigsByDataRoomIdMinUpdatedByEmailDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_UPDATED_BY_EMAIL_DESC",
  UserConfigsByDataRoomIdMinUserConfigIdAsc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_USER_CONFIG_ID_ASC",
  UserConfigsByDataRoomIdMinUserConfigIdDesc = "USER_CONFIGS_BY_DATA_ROOM_ID_MIN_USER_CONFIG_ID_DESC",
  UserConfigsCountAsc = "USER_CONFIGS_COUNT_ASC",
  UserConfigsCountDesc = "USER_CONFIGS_COUNT_DESC",
  UserConfigsDistinctCountCreatedAtAsc = "USER_CONFIGS_DISTINCT_COUNT_CREATED_AT_ASC",
  UserConfigsDistinctCountCreatedAtDesc = "USER_CONFIGS_DISTINCT_COUNT_CREATED_AT_DESC",
  UserConfigsDistinctCountCreatedByEmailAsc = "USER_CONFIGS_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  UserConfigsDistinctCountCreatedByEmailDesc = "USER_CONFIGS_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  UserConfigsDistinctCountDataRoomIdAsc = "USER_CONFIGS_DISTINCT_COUNT_DATA_ROOM_ID_ASC",
  UserConfigsDistinctCountDataRoomIdDesc = "USER_CONFIGS_DISTINCT_COUNT_DATA_ROOM_ID_DESC",
  UserConfigsDistinctCountEmailAsc = "USER_CONFIGS_DISTINCT_COUNT_EMAIL_ASC",
  UserConfigsDistinctCountEmailDesc = "USER_CONFIGS_DISTINCT_COUNT_EMAIL_DESC",
  UserConfigsDistinctCountIsArchivedAsc = "USER_CONFIGS_DISTINCT_COUNT_IS_ARCHIVED_ASC",
  UserConfigsDistinctCountIsArchivedDesc = "USER_CONFIGS_DISTINCT_COUNT_IS_ARCHIVED_DESC",
  UserConfigsDistinctCountIsFavoriteAsc = "USER_CONFIGS_DISTINCT_COUNT_IS_FAVORITE_ASC",
  UserConfigsDistinctCountIsFavoriteDesc = "USER_CONFIGS_DISTINCT_COUNT_IS_FAVORITE_DESC",
  UserConfigsDistinctCountUpdatedAtAsc = "USER_CONFIGS_DISTINCT_COUNT_UPDATED_AT_ASC",
  UserConfigsDistinctCountUpdatedAtDesc = "USER_CONFIGS_DISTINCT_COUNT_UPDATED_AT_DESC",
  UserConfigsDistinctCountUpdatedByEmailAsc = "USER_CONFIGS_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  UserConfigsDistinctCountUpdatedByEmailDesc = "USER_CONFIGS_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  UserConfigsDistinctCountUserConfigIdAsc = "USER_CONFIGS_DISTINCT_COUNT_USER_CONFIG_ID_ASC",
  UserConfigsDistinctCountUserConfigIdDesc = "USER_CONFIGS_DISTINCT_COUNT_USER_CONFIG_ID_DESC",
  UserConfigsMaxCreatedAtAsc = "USER_CONFIGS_MAX_CREATED_AT_ASC",
  UserConfigsMaxCreatedAtDesc = "USER_CONFIGS_MAX_CREATED_AT_DESC",
  UserConfigsMaxCreatedByEmailAsc = "USER_CONFIGS_MAX_CREATED_BY_EMAIL_ASC",
  UserConfigsMaxCreatedByEmailDesc = "USER_CONFIGS_MAX_CREATED_BY_EMAIL_DESC",
  UserConfigsMaxDataRoomIdAsc = "USER_CONFIGS_MAX_DATA_ROOM_ID_ASC",
  UserConfigsMaxDataRoomIdDesc = "USER_CONFIGS_MAX_DATA_ROOM_ID_DESC",
  UserConfigsMaxEmailAsc = "USER_CONFIGS_MAX_EMAIL_ASC",
  UserConfigsMaxEmailDesc = "USER_CONFIGS_MAX_EMAIL_DESC",
  UserConfigsMaxIsArchivedAsc = "USER_CONFIGS_MAX_IS_ARCHIVED_ASC",
  UserConfigsMaxIsArchivedDesc = "USER_CONFIGS_MAX_IS_ARCHIVED_DESC",
  UserConfigsMaxIsFavoriteAsc = "USER_CONFIGS_MAX_IS_FAVORITE_ASC",
  UserConfigsMaxIsFavoriteDesc = "USER_CONFIGS_MAX_IS_FAVORITE_DESC",
  UserConfigsMaxUpdatedAtAsc = "USER_CONFIGS_MAX_UPDATED_AT_ASC",
  UserConfigsMaxUpdatedAtDesc = "USER_CONFIGS_MAX_UPDATED_AT_DESC",
  UserConfigsMaxUpdatedByEmailAsc = "USER_CONFIGS_MAX_UPDATED_BY_EMAIL_ASC",
  UserConfigsMaxUpdatedByEmailDesc = "USER_CONFIGS_MAX_UPDATED_BY_EMAIL_DESC",
  UserConfigsMaxUserConfigIdAsc = "USER_CONFIGS_MAX_USER_CONFIG_ID_ASC",
  UserConfigsMaxUserConfigIdDesc = "USER_CONFIGS_MAX_USER_CONFIG_ID_DESC",
  UserConfigsMinCreatedAtAsc = "USER_CONFIGS_MIN_CREATED_AT_ASC",
  UserConfigsMinCreatedAtDesc = "USER_CONFIGS_MIN_CREATED_AT_DESC",
  UserConfigsMinCreatedByEmailAsc = "USER_CONFIGS_MIN_CREATED_BY_EMAIL_ASC",
  UserConfigsMinCreatedByEmailDesc = "USER_CONFIGS_MIN_CREATED_BY_EMAIL_DESC",
  UserConfigsMinDataRoomIdAsc = "USER_CONFIGS_MIN_DATA_ROOM_ID_ASC",
  UserConfigsMinDataRoomIdDesc = "USER_CONFIGS_MIN_DATA_ROOM_ID_DESC",
  UserConfigsMinEmailAsc = "USER_CONFIGS_MIN_EMAIL_ASC",
  UserConfigsMinEmailDesc = "USER_CONFIGS_MIN_EMAIL_DESC",
  UserConfigsMinIsArchivedAsc = "USER_CONFIGS_MIN_IS_ARCHIVED_ASC",
  UserConfigsMinIsArchivedDesc = "USER_CONFIGS_MIN_IS_ARCHIVED_DESC",
  UserConfigsMinIsFavoriteAsc = "USER_CONFIGS_MIN_IS_FAVORITE_ASC",
  UserConfigsMinIsFavoriteDesc = "USER_CONFIGS_MIN_IS_FAVORITE_DESC",
  UserConfigsMinUpdatedAtAsc = "USER_CONFIGS_MIN_UPDATED_AT_ASC",
  UserConfigsMinUpdatedAtDesc = "USER_CONFIGS_MIN_UPDATED_AT_DESC",
  UserConfigsMinUpdatedByEmailAsc = "USER_CONFIGS_MIN_UPDATED_BY_EMAIL_ASC",
  UserConfigsMinUpdatedByEmailDesc = "USER_CONFIGS_MIN_UPDATED_BY_EMAIL_DESC",
  UserConfigsMinUserConfigIdAsc = "USER_CONFIGS_MIN_USER_CONFIG_ID_ASC",
  UserConfigsMinUserConfigIdDesc = "USER_CONFIGS_MIN_USER_CONFIG_ID_DESC",
  UserConfigsStddevPopulationCreatedAtAsc = "USER_CONFIGS_STDDEV_POPULATION_CREATED_AT_ASC",
  UserConfigsStddevPopulationCreatedAtDesc = "USER_CONFIGS_STDDEV_POPULATION_CREATED_AT_DESC",
  UserConfigsStddevPopulationCreatedByEmailAsc = "USER_CONFIGS_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  UserConfigsStddevPopulationCreatedByEmailDesc = "USER_CONFIGS_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  UserConfigsStddevPopulationDataRoomIdAsc = "USER_CONFIGS_STDDEV_POPULATION_DATA_ROOM_ID_ASC",
  UserConfigsStddevPopulationDataRoomIdDesc = "USER_CONFIGS_STDDEV_POPULATION_DATA_ROOM_ID_DESC",
  UserConfigsStddevPopulationEmailAsc = "USER_CONFIGS_STDDEV_POPULATION_EMAIL_ASC",
  UserConfigsStddevPopulationEmailDesc = "USER_CONFIGS_STDDEV_POPULATION_EMAIL_DESC",
  UserConfigsStddevPopulationIsArchivedAsc = "USER_CONFIGS_STDDEV_POPULATION_IS_ARCHIVED_ASC",
  UserConfigsStddevPopulationIsArchivedDesc = "USER_CONFIGS_STDDEV_POPULATION_IS_ARCHIVED_DESC",
  UserConfigsStddevPopulationIsFavoriteAsc = "USER_CONFIGS_STDDEV_POPULATION_IS_FAVORITE_ASC",
  UserConfigsStddevPopulationIsFavoriteDesc = "USER_CONFIGS_STDDEV_POPULATION_IS_FAVORITE_DESC",
  UserConfigsStddevPopulationUpdatedAtAsc = "USER_CONFIGS_STDDEV_POPULATION_UPDATED_AT_ASC",
  UserConfigsStddevPopulationUpdatedAtDesc = "USER_CONFIGS_STDDEV_POPULATION_UPDATED_AT_DESC",
  UserConfigsStddevPopulationUpdatedByEmailAsc = "USER_CONFIGS_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  UserConfigsStddevPopulationUpdatedByEmailDesc = "USER_CONFIGS_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  UserConfigsStddevPopulationUserConfigIdAsc = "USER_CONFIGS_STDDEV_POPULATION_USER_CONFIG_ID_ASC",
  UserConfigsStddevPopulationUserConfigIdDesc = "USER_CONFIGS_STDDEV_POPULATION_USER_CONFIG_ID_DESC",
  UserConfigsStddevSampleCreatedAtAsc = "USER_CONFIGS_STDDEV_SAMPLE_CREATED_AT_ASC",
  UserConfigsStddevSampleCreatedAtDesc = "USER_CONFIGS_STDDEV_SAMPLE_CREATED_AT_DESC",
  UserConfigsStddevSampleCreatedByEmailAsc = "USER_CONFIGS_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  UserConfigsStddevSampleCreatedByEmailDesc = "USER_CONFIGS_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  UserConfigsStddevSampleDataRoomIdAsc = "USER_CONFIGS_STDDEV_SAMPLE_DATA_ROOM_ID_ASC",
  UserConfigsStddevSampleDataRoomIdDesc = "USER_CONFIGS_STDDEV_SAMPLE_DATA_ROOM_ID_DESC",
  UserConfigsStddevSampleEmailAsc = "USER_CONFIGS_STDDEV_SAMPLE_EMAIL_ASC",
  UserConfigsStddevSampleEmailDesc = "USER_CONFIGS_STDDEV_SAMPLE_EMAIL_DESC",
  UserConfigsStddevSampleIsArchivedAsc = "USER_CONFIGS_STDDEV_SAMPLE_IS_ARCHIVED_ASC",
  UserConfigsStddevSampleIsArchivedDesc = "USER_CONFIGS_STDDEV_SAMPLE_IS_ARCHIVED_DESC",
  UserConfigsStddevSampleIsFavoriteAsc = "USER_CONFIGS_STDDEV_SAMPLE_IS_FAVORITE_ASC",
  UserConfigsStddevSampleIsFavoriteDesc = "USER_CONFIGS_STDDEV_SAMPLE_IS_FAVORITE_DESC",
  UserConfigsStddevSampleUpdatedAtAsc = "USER_CONFIGS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  UserConfigsStddevSampleUpdatedAtDesc = "USER_CONFIGS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  UserConfigsStddevSampleUpdatedByEmailAsc = "USER_CONFIGS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  UserConfigsStddevSampleUpdatedByEmailDesc = "USER_CONFIGS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  UserConfigsStddevSampleUserConfigIdAsc = "USER_CONFIGS_STDDEV_SAMPLE_USER_CONFIG_ID_ASC",
  UserConfigsStddevSampleUserConfigIdDesc = "USER_CONFIGS_STDDEV_SAMPLE_USER_CONFIG_ID_DESC",
  UserConfigsSumCreatedAtAsc = "USER_CONFIGS_SUM_CREATED_AT_ASC",
  UserConfigsSumCreatedAtDesc = "USER_CONFIGS_SUM_CREATED_AT_DESC",
  UserConfigsSumCreatedByEmailAsc = "USER_CONFIGS_SUM_CREATED_BY_EMAIL_ASC",
  UserConfigsSumCreatedByEmailDesc = "USER_CONFIGS_SUM_CREATED_BY_EMAIL_DESC",
  UserConfigsSumDataRoomIdAsc = "USER_CONFIGS_SUM_DATA_ROOM_ID_ASC",
  UserConfigsSumDataRoomIdDesc = "USER_CONFIGS_SUM_DATA_ROOM_ID_DESC",
  UserConfigsSumEmailAsc = "USER_CONFIGS_SUM_EMAIL_ASC",
  UserConfigsSumEmailDesc = "USER_CONFIGS_SUM_EMAIL_DESC",
  UserConfigsSumIsArchivedAsc = "USER_CONFIGS_SUM_IS_ARCHIVED_ASC",
  UserConfigsSumIsArchivedDesc = "USER_CONFIGS_SUM_IS_ARCHIVED_DESC",
  UserConfigsSumIsFavoriteAsc = "USER_CONFIGS_SUM_IS_FAVORITE_ASC",
  UserConfigsSumIsFavoriteDesc = "USER_CONFIGS_SUM_IS_FAVORITE_DESC",
  UserConfigsSumUpdatedAtAsc = "USER_CONFIGS_SUM_UPDATED_AT_ASC",
  UserConfigsSumUpdatedAtDesc = "USER_CONFIGS_SUM_UPDATED_AT_DESC",
  UserConfigsSumUpdatedByEmailAsc = "USER_CONFIGS_SUM_UPDATED_BY_EMAIL_ASC",
  UserConfigsSumUpdatedByEmailDesc = "USER_CONFIGS_SUM_UPDATED_BY_EMAIL_DESC",
  UserConfigsSumUserConfigIdAsc = "USER_CONFIGS_SUM_USER_CONFIG_ID_ASC",
  UserConfigsSumUserConfigIdDesc = "USER_CONFIGS_SUM_USER_CONFIG_ID_DESC",
  UserConfigsVariancePopulationCreatedAtAsc = "USER_CONFIGS_VARIANCE_POPULATION_CREATED_AT_ASC",
  UserConfigsVariancePopulationCreatedAtDesc = "USER_CONFIGS_VARIANCE_POPULATION_CREATED_AT_DESC",
  UserConfigsVariancePopulationCreatedByEmailAsc = "USER_CONFIGS_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  UserConfigsVariancePopulationCreatedByEmailDesc = "USER_CONFIGS_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  UserConfigsVariancePopulationDataRoomIdAsc = "USER_CONFIGS_VARIANCE_POPULATION_DATA_ROOM_ID_ASC",
  UserConfigsVariancePopulationDataRoomIdDesc = "USER_CONFIGS_VARIANCE_POPULATION_DATA_ROOM_ID_DESC",
  UserConfigsVariancePopulationEmailAsc = "USER_CONFIGS_VARIANCE_POPULATION_EMAIL_ASC",
  UserConfigsVariancePopulationEmailDesc = "USER_CONFIGS_VARIANCE_POPULATION_EMAIL_DESC",
  UserConfigsVariancePopulationIsArchivedAsc = "USER_CONFIGS_VARIANCE_POPULATION_IS_ARCHIVED_ASC",
  UserConfigsVariancePopulationIsArchivedDesc = "USER_CONFIGS_VARIANCE_POPULATION_IS_ARCHIVED_DESC",
  UserConfigsVariancePopulationIsFavoriteAsc = "USER_CONFIGS_VARIANCE_POPULATION_IS_FAVORITE_ASC",
  UserConfigsVariancePopulationIsFavoriteDesc = "USER_CONFIGS_VARIANCE_POPULATION_IS_FAVORITE_DESC",
  UserConfigsVariancePopulationUpdatedAtAsc = "USER_CONFIGS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  UserConfigsVariancePopulationUpdatedAtDesc = "USER_CONFIGS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  UserConfigsVariancePopulationUpdatedByEmailAsc = "USER_CONFIGS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  UserConfigsVariancePopulationUpdatedByEmailDesc = "USER_CONFIGS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  UserConfigsVariancePopulationUserConfigIdAsc = "USER_CONFIGS_VARIANCE_POPULATION_USER_CONFIG_ID_ASC",
  UserConfigsVariancePopulationUserConfigIdDesc = "USER_CONFIGS_VARIANCE_POPULATION_USER_CONFIG_ID_DESC",
  UserConfigsVarianceSampleCreatedAtAsc = "USER_CONFIGS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  UserConfigsVarianceSampleCreatedAtDesc = "USER_CONFIGS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  UserConfigsVarianceSampleCreatedByEmailAsc = "USER_CONFIGS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  UserConfigsVarianceSampleCreatedByEmailDesc = "USER_CONFIGS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  UserConfigsVarianceSampleDataRoomIdAsc = "USER_CONFIGS_VARIANCE_SAMPLE_DATA_ROOM_ID_ASC",
  UserConfigsVarianceSampleDataRoomIdDesc = "USER_CONFIGS_VARIANCE_SAMPLE_DATA_ROOM_ID_DESC",
  UserConfigsVarianceSampleEmailAsc = "USER_CONFIGS_VARIANCE_SAMPLE_EMAIL_ASC",
  UserConfigsVarianceSampleEmailDesc = "USER_CONFIGS_VARIANCE_SAMPLE_EMAIL_DESC",
  UserConfigsVarianceSampleIsArchivedAsc = "USER_CONFIGS_VARIANCE_SAMPLE_IS_ARCHIVED_ASC",
  UserConfigsVarianceSampleIsArchivedDesc = "USER_CONFIGS_VARIANCE_SAMPLE_IS_ARCHIVED_DESC",
  UserConfigsVarianceSampleIsFavoriteAsc = "USER_CONFIGS_VARIANCE_SAMPLE_IS_FAVORITE_ASC",
  UserConfigsVarianceSampleIsFavoriteDesc = "USER_CONFIGS_VARIANCE_SAMPLE_IS_FAVORITE_DESC",
  UserConfigsVarianceSampleUpdatedAtAsc = "USER_CONFIGS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  UserConfigsVarianceSampleUpdatedAtDesc = "USER_CONFIGS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  UserConfigsVarianceSampleUpdatedByEmailAsc = "USER_CONFIGS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  UserConfigsVarianceSampleUpdatedByEmailDesc = "USER_CONFIGS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  UserConfigsVarianceSampleUserConfigIdAsc = "USER_CONFIGS_VARIANCE_SAMPLE_USER_CONFIG_ID_ASC",
  UserConfigsVarianceSampleUserConfigIdDesc = "USER_CONFIGS_VARIANCE_SAMPLE_USER_CONFIG_ID_DESC",
  UserPermissionsAverageCreatedAtAsc = "USER_PERMISSIONS_AVERAGE_CREATED_AT_ASC",
  UserPermissionsAverageCreatedAtDesc = "USER_PERMISSIONS_AVERAGE_CREATED_AT_DESC",
  UserPermissionsAverageCreatedByEmailAsc = "USER_PERMISSIONS_AVERAGE_CREATED_BY_EMAIL_ASC",
  UserPermissionsAverageCreatedByEmailDesc = "USER_PERMISSIONS_AVERAGE_CREATED_BY_EMAIL_DESC",
  UserPermissionsAverageDataRoomIdAsc = "USER_PERMISSIONS_AVERAGE_DATA_ROOM_ID_ASC",
  UserPermissionsAverageDataRoomIdDesc = "USER_PERMISSIONS_AVERAGE_DATA_ROOM_ID_DESC",
  UserPermissionsAverageEmailAsc = "USER_PERMISSIONS_AVERAGE_EMAIL_ASC",
  UserPermissionsAverageEmailDesc = "USER_PERMISSIONS_AVERAGE_EMAIL_DESC",
  UserPermissionsAverageUpdatedAtAsc = "USER_PERMISSIONS_AVERAGE_UPDATED_AT_ASC",
  UserPermissionsAverageUpdatedAtDesc = "USER_PERMISSIONS_AVERAGE_UPDATED_AT_DESC",
  UserPermissionsAverageUpdatedByEmailAsc = "USER_PERMISSIONS_AVERAGE_UPDATED_BY_EMAIL_ASC",
  UserPermissionsAverageUpdatedByEmailDesc = "USER_PERMISSIONS_AVERAGE_UPDATED_BY_EMAIL_DESC",
  UserPermissionsAverageUserPermissionIdAsc = "USER_PERMISSIONS_AVERAGE_USER_PERMISSION_ID_ASC",
  UserPermissionsAverageUserPermissionIdDesc = "USER_PERMISSIONS_AVERAGE_USER_PERMISSION_ID_DESC",
  UserPermissionsByDataRoomIdCountAsc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_COUNT_ASC",
  UserPermissionsByDataRoomIdCountDesc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_COUNT_DESC",
  UserPermissionsByDataRoomIdMaxCreatedAtAsc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MAX_CREATED_AT_ASC",
  UserPermissionsByDataRoomIdMaxCreatedAtDesc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MAX_CREATED_AT_DESC",
  UserPermissionsByDataRoomIdMaxCreatedByEmailAsc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MAX_CREATED_BY_EMAIL_ASC",
  UserPermissionsByDataRoomIdMaxCreatedByEmailDesc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MAX_CREATED_BY_EMAIL_DESC",
  UserPermissionsByDataRoomIdMaxDataRoomIdAsc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MAX_DATA_ROOM_ID_ASC",
  UserPermissionsByDataRoomIdMaxDataRoomIdDesc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MAX_DATA_ROOM_ID_DESC",
  UserPermissionsByDataRoomIdMaxEmailAsc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MAX_EMAIL_ASC",
  UserPermissionsByDataRoomIdMaxEmailDesc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MAX_EMAIL_DESC",
  UserPermissionsByDataRoomIdMaxUpdatedAtAsc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MAX_UPDATED_AT_ASC",
  UserPermissionsByDataRoomIdMaxUpdatedAtDesc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MAX_UPDATED_AT_DESC",
  UserPermissionsByDataRoomIdMaxUpdatedByEmailAsc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MAX_UPDATED_BY_EMAIL_ASC",
  UserPermissionsByDataRoomIdMaxUpdatedByEmailDesc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MAX_UPDATED_BY_EMAIL_DESC",
  UserPermissionsByDataRoomIdMaxUserPermissionIdAsc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MAX_USER_PERMISSION_ID_ASC",
  UserPermissionsByDataRoomIdMaxUserPermissionIdDesc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MAX_USER_PERMISSION_ID_DESC",
  UserPermissionsByDataRoomIdMinCreatedAtAsc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MIN_CREATED_AT_ASC",
  UserPermissionsByDataRoomIdMinCreatedAtDesc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MIN_CREATED_AT_DESC",
  UserPermissionsByDataRoomIdMinCreatedByEmailAsc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MIN_CREATED_BY_EMAIL_ASC",
  UserPermissionsByDataRoomIdMinCreatedByEmailDesc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MIN_CREATED_BY_EMAIL_DESC",
  UserPermissionsByDataRoomIdMinDataRoomIdAsc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MIN_DATA_ROOM_ID_ASC",
  UserPermissionsByDataRoomIdMinDataRoomIdDesc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MIN_DATA_ROOM_ID_DESC",
  UserPermissionsByDataRoomIdMinEmailAsc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MIN_EMAIL_ASC",
  UserPermissionsByDataRoomIdMinEmailDesc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MIN_EMAIL_DESC",
  UserPermissionsByDataRoomIdMinUpdatedAtAsc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MIN_UPDATED_AT_ASC",
  UserPermissionsByDataRoomIdMinUpdatedAtDesc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MIN_UPDATED_AT_DESC",
  UserPermissionsByDataRoomIdMinUpdatedByEmailAsc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MIN_UPDATED_BY_EMAIL_ASC",
  UserPermissionsByDataRoomIdMinUpdatedByEmailDesc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MIN_UPDATED_BY_EMAIL_DESC",
  UserPermissionsByDataRoomIdMinUserPermissionIdAsc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MIN_USER_PERMISSION_ID_ASC",
  UserPermissionsByDataRoomIdMinUserPermissionIdDesc = "USER_PERMISSIONS_BY_DATA_ROOM_ID_MIN_USER_PERMISSION_ID_DESC",
  UserPermissionsCountAsc = "USER_PERMISSIONS_COUNT_ASC",
  UserPermissionsCountDesc = "USER_PERMISSIONS_COUNT_DESC",
  UserPermissionsDistinctCountCreatedAtAsc = "USER_PERMISSIONS_DISTINCT_COUNT_CREATED_AT_ASC",
  UserPermissionsDistinctCountCreatedAtDesc = "USER_PERMISSIONS_DISTINCT_COUNT_CREATED_AT_DESC",
  UserPermissionsDistinctCountCreatedByEmailAsc = "USER_PERMISSIONS_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  UserPermissionsDistinctCountCreatedByEmailDesc = "USER_PERMISSIONS_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  UserPermissionsDistinctCountDataRoomIdAsc = "USER_PERMISSIONS_DISTINCT_COUNT_DATA_ROOM_ID_ASC",
  UserPermissionsDistinctCountDataRoomIdDesc = "USER_PERMISSIONS_DISTINCT_COUNT_DATA_ROOM_ID_DESC",
  UserPermissionsDistinctCountEmailAsc = "USER_PERMISSIONS_DISTINCT_COUNT_EMAIL_ASC",
  UserPermissionsDistinctCountEmailDesc = "USER_PERMISSIONS_DISTINCT_COUNT_EMAIL_DESC",
  UserPermissionsDistinctCountUpdatedAtAsc = "USER_PERMISSIONS_DISTINCT_COUNT_UPDATED_AT_ASC",
  UserPermissionsDistinctCountUpdatedAtDesc = "USER_PERMISSIONS_DISTINCT_COUNT_UPDATED_AT_DESC",
  UserPermissionsDistinctCountUpdatedByEmailAsc = "USER_PERMISSIONS_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  UserPermissionsDistinctCountUpdatedByEmailDesc = "USER_PERMISSIONS_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  UserPermissionsDistinctCountUserPermissionIdAsc = "USER_PERMISSIONS_DISTINCT_COUNT_USER_PERMISSION_ID_ASC",
  UserPermissionsDistinctCountUserPermissionIdDesc = "USER_PERMISSIONS_DISTINCT_COUNT_USER_PERMISSION_ID_DESC",
  UserPermissionsMaxCreatedAtAsc = "USER_PERMISSIONS_MAX_CREATED_AT_ASC",
  UserPermissionsMaxCreatedAtDesc = "USER_PERMISSIONS_MAX_CREATED_AT_DESC",
  UserPermissionsMaxCreatedByEmailAsc = "USER_PERMISSIONS_MAX_CREATED_BY_EMAIL_ASC",
  UserPermissionsMaxCreatedByEmailDesc = "USER_PERMISSIONS_MAX_CREATED_BY_EMAIL_DESC",
  UserPermissionsMaxDataRoomIdAsc = "USER_PERMISSIONS_MAX_DATA_ROOM_ID_ASC",
  UserPermissionsMaxDataRoomIdDesc = "USER_PERMISSIONS_MAX_DATA_ROOM_ID_DESC",
  UserPermissionsMaxEmailAsc = "USER_PERMISSIONS_MAX_EMAIL_ASC",
  UserPermissionsMaxEmailDesc = "USER_PERMISSIONS_MAX_EMAIL_DESC",
  UserPermissionsMaxUpdatedAtAsc = "USER_PERMISSIONS_MAX_UPDATED_AT_ASC",
  UserPermissionsMaxUpdatedAtDesc = "USER_PERMISSIONS_MAX_UPDATED_AT_DESC",
  UserPermissionsMaxUpdatedByEmailAsc = "USER_PERMISSIONS_MAX_UPDATED_BY_EMAIL_ASC",
  UserPermissionsMaxUpdatedByEmailDesc = "USER_PERMISSIONS_MAX_UPDATED_BY_EMAIL_DESC",
  UserPermissionsMaxUserPermissionIdAsc = "USER_PERMISSIONS_MAX_USER_PERMISSION_ID_ASC",
  UserPermissionsMaxUserPermissionIdDesc = "USER_PERMISSIONS_MAX_USER_PERMISSION_ID_DESC",
  UserPermissionsMinCreatedAtAsc = "USER_PERMISSIONS_MIN_CREATED_AT_ASC",
  UserPermissionsMinCreatedAtDesc = "USER_PERMISSIONS_MIN_CREATED_AT_DESC",
  UserPermissionsMinCreatedByEmailAsc = "USER_PERMISSIONS_MIN_CREATED_BY_EMAIL_ASC",
  UserPermissionsMinCreatedByEmailDesc = "USER_PERMISSIONS_MIN_CREATED_BY_EMAIL_DESC",
  UserPermissionsMinDataRoomIdAsc = "USER_PERMISSIONS_MIN_DATA_ROOM_ID_ASC",
  UserPermissionsMinDataRoomIdDesc = "USER_PERMISSIONS_MIN_DATA_ROOM_ID_DESC",
  UserPermissionsMinEmailAsc = "USER_PERMISSIONS_MIN_EMAIL_ASC",
  UserPermissionsMinEmailDesc = "USER_PERMISSIONS_MIN_EMAIL_DESC",
  UserPermissionsMinUpdatedAtAsc = "USER_PERMISSIONS_MIN_UPDATED_AT_ASC",
  UserPermissionsMinUpdatedAtDesc = "USER_PERMISSIONS_MIN_UPDATED_AT_DESC",
  UserPermissionsMinUpdatedByEmailAsc = "USER_PERMISSIONS_MIN_UPDATED_BY_EMAIL_ASC",
  UserPermissionsMinUpdatedByEmailDesc = "USER_PERMISSIONS_MIN_UPDATED_BY_EMAIL_DESC",
  UserPermissionsMinUserPermissionIdAsc = "USER_PERMISSIONS_MIN_USER_PERMISSION_ID_ASC",
  UserPermissionsMinUserPermissionIdDesc = "USER_PERMISSIONS_MIN_USER_PERMISSION_ID_DESC",
  UserPermissionsStddevPopulationCreatedAtAsc = "USER_PERMISSIONS_STDDEV_POPULATION_CREATED_AT_ASC",
  UserPermissionsStddevPopulationCreatedAtDesc = "USER_PERMISSIONS_STDDEV_POPULATION_CREATED_AT_DESC",
  UserPermissionsStddevPopulationCreatedByEmailAsc = "USER_PERMISSIONS_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  UserPermissionsStddevPopulationCreatedByEmailDesc = "USER_PERMISSIONS_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  UserPermissionsStddevPopulationDataRoomIdAsc = "USER_PERMISSIONS_STDDEV_POPULATION_DATA_ROOM_ID_ASC",
  UserPermissionsStddevPopulationDataRoomIdDesc = "USER_PERMISSIONS_STDDEV_POPULATION_DATA_ROOM_ID_DESC",
  UserPermissionsStddevPopulationEmailAsc = "USER_PERMISSIONS_STDDEV_POPULATION_EMAIL_ASC",
  UserPermissionsStddevPopulationEmailDesc = "USER_PERMISSIONS_STDDEV_POPULATION_EMAIL_DESC",
  UserPermissionsStddevPopulationUpdatedAtAsc = "USER_PERMISSIONS_STDDEV_POPULATION_UPDATED_AT_ASC",
  UserPermissionsStddevPopulationUpdatedAtDesc = "USER_PERMISSIONS_STDDEV_POPULATION_UPDATED_AT_DESC",
  UserPermissionsStddevPopulationUpdatedByEmailAsc = "USER_PERMISSIONS_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  UserPermissionsStddevPopulationUpdatedByEmailDesc = "USER_PERMISSIONS_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  UserPermissionsStddevPopulationUserPermissionIdAsc = "USER_PERMISSIONS_STDDEV_POPULATION_USER_PERMISSION_ID_ASC",
  UserPermissionsStddevPopulationUserPermissionIdDesc = "USER_PERMISSIONS_STDDEV_POPULATION_USER_PERMISSION_ID_DESC",
  UserPermissionsStddevSampleCreatedAtAsc = "USER_PERMISSIONS_STDDEV_SAMPLE_CREATED_AT_ASC",
  UserPermissionsStddevSampleCreatedAtDesc = "USER_PERMISSIONS_STDDEV_SAMPLE_CREATED_AT_DESC",
  UserPermissionsStddevSampleCreatedByEmailAsc = "USER_PERMISSIONS_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  UserPermissionsStddevSampleCreatedByEmailDesc = "USER_PERMISSIONS_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  UserPermissionsStddevSampleDataRoomIdAsc = "USER_PERMISSIONS_STDDEV_SAMPLE_DATA_ROOM_ID_ASC",
  UserPermissionsStddevSampleDataRoomIdDesc = "USER_PERMISSIONS_STDDEV_SAMPLE_DATA_ROOM_ID_DESC",
  UserPermissionsStddevSampleEmailAsc = "USER_PERMISSIONS_STDDEV_SAMPLE_EMAIL_ASC",
  UserPermissionsStddevSampleEmailDesc = "USER_PERMISSIONS_STDDEV_SAMPLE_EMAIL_DESC",
  UserPermissionsStddevSampleUpdatedAtAsc = "USER_PERMISSIONS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  UserPermissionsStddevSampleUpdatedAtDesc = "USER_PERMISSIONS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  UserPermissionsStddevSampleUpdatedByEmailAsc = "USER_PERMISSIONS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  UserPermissionsStddevSampleUpdatedByEmailDesc = "USER_PERMISSIONS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  UserPermissionsStddevSampleUserPermissionIdAsc = "USER_PERMISSIONS_STDDEV_SAMPLE_USER_PERMISSION_ID_ASC",
  UserPermissionsStddevSampleUserPermissionIdDesc = "USER_PERMISSIONS_STDDEV_SAMPLE_USER_PERMISSION_ID_DESC",
  UserPermissionsSumCreatedAtAsc = "USER_PERMISSIONS_SUM_CREATED_AT_ASC",
  UserPermissionsSumCreatedAtDesc = "USER_PERMISSIONS_SUM_CREATED_AT_DESC",
  UserPermissionsSumCreatedByEmailAsc = "USER_PERMISSIONS_SUM_CREATED_BY_EMAIL_ASC",
  UserPermissionsSumCreatedByEmailDesc = "USER_PERMISSIONS_SUM_CREATED_BY_EMAIL_DESC",
  UserPermissionsSumDataRoomIdAsc = "USER_PERMISSIONS_SUM_DATA_ROOM_ID_ASC",
  UserPermissionsSumDataRoomIdDesc = "USER_PERMISSIONS_SUM_DATA_ROOM_ID_DESC",
  UserPermissionsSumEmailAsc = "USER_PERMISSIONS_SUM_EMAIL_ASC",
  UserPermissionsSumEmailDesc = "USER_PERMISSIONS_SUM_EMAIL_DESC",
  UserPermissionsSumUpdatedAtAsc = "USER_PERMISSIONS_SUM_UPDATED_AT_ASC",
  UserPermissionsSumUpdatedAtDesc = "USER_PERMISSIONS_SUM_UPDATED_AT_DESC",
  UserPermissionsSumUpdatedByEmailAsc = "USER_PERMISSIONS_SUM_UPDATED_BY_EMAIL_ASC",
  UserPermissionsSumUpdatedByEmailDesc = "USER_PERMISSIONS_SUM_UPDATED_BY_EMAIL_DESC",
  UserPermissionsSumUserPermissionIdAsc = "USER_PERMISSIONS_SUM_USER_PERMISSION_ID_ASC",
  UserPermissionsSumUserPermissionIdDesc = "USER_PERMISSIONS_SUM_USER_PERMISSION_ID_DESC",
  UserPermissionsVariancePopulationCreatedAtAsc = "USER_PERMISSIONS_VARIANCE_POPULATION_CREATED_AT_ASC",
  UserPermissionsVariancePopulationCreatedAtDesc = "USER_PERMISSIONS_VARIANCE_POPULATION_CREATED_AT_DESC",
  UserPermissionsVariancePopulationCreatedByEmailAsc = "USER_PERMISSIONS_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  UserPermissionsVariancePopulationCreatedByEmailDesc = "USER_PERMISSIONS_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  UserPermissionsVariancePopulationDataRoomIdAsc = "USER_PERMISSIONS_VARIANCE_POPULATION_DATA_ROOM_ID_ASC",
  UserPermissionsVariancePopulationDataRoomIdDesc = "USER_PERMISSIONS_VARIANCE_POPULATION_DATA_ROOM_ID_DESC",
  UserPermissionsVariancePopulationEmailAsc = "USER_PERMISSIONS_VARIANCE_POPULATION_EMAIL_ASC",
  UserPermissionsVariancePopulationEmailDesc = "USER_PERMISSIONS_VARIANCE_POPULATION_EMAIL_DESC",
  UserPermissionsVariancePopulationUpdatedAtAsc = "USER_PERMISSIONS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  UserPermissionsVariancePopulationUpdatedAtDesc = "USER_PERMISSIONS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  UserPermissionsVariancePopulationUpdatedByEmailAsc = "USER_PERMISSIONS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  UserPermissionsVariancePopulationUpdatedByEmailDesc = "USER_PERMISSIONS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  UserPermissionsVariancePopulationUserPermissionIdAsc = "USER_PERMISSIONS_VARIANCE_POPULATION_USER_PERMISSION_ID_ASC",
  UserPermissionsVariancePopulationUserPermissionIdDesc = "USER_PERMISSIONS_VARIANCE_POPULATION_USER_PERMISSION_ID_DESC",
  UserPermissionsVarianceSampleCreatedAtAsc = "USER_PERMISSIONS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  UserPermissionsVarianceSampleCreatedAtDesc = "USER_PERMISSIONS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  UserPermissionsVarianceSampleCreatedByEmailAsc = "USER_PERMISSIONS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  UserPermissionsVarianceSampleCreatedByEmailDesc = "USER_PERMISSIONS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  UserPermissionsVarianceSampleDataRoomIdAsc = "USER_PERMISSIONS_VARIANCE_SAMPLE_DATA_ROOM_ID_ASC",
  UserPermissionsVarianceSampleDataRoomIdDesc = "USER_PERMISSIONS_VARIANCE_SAMPLE_DATA_ROOM_ID_DESC",
  UserPermissionsVarianceSampleEmailAsc = "USER_PERMISSIONS_VARIANCE_SAMPLE_EMAIL_ASC",
  UserPermissionsVarianceSampleEmailDesc = "USER_PERMISSIONS_VARIANCE_SAMPLE_EMAIL_DESC",
  UserPermissionsVarianceSampleUpdatedAtAsc = "USER_PERMISSIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  UserPermissionsVarianceSampleUpdatedAtDesc = "USER_PERMISSIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  UserPermissionsVarianceSampleUpdatedByEmailAsc = "USER_PERMISSIONS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  UserPermissionsVarianceSampleUpdatedByEmailDesc = "USER_PERMISSIONS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  UserPermissionsVarianceSampleUserPermissionIdAsc = "USER_PERMISSIONS_VARIANCE_SAMPLE_USER_PERMISSION_ID_ASC",
  UserPermissionsVarianceSampleUserPermissionIdDesc = "USER_PERMISSIONS_VARIANCE_SAMPLE_USER_PERMISSION_ID_DESC",
}

export type Dataset = {
  __typename?: "Dataset";
  chunks: Array<Scalars["String"]>;
  createdAt?: Maybe<Scalars["Datetime"]>;
  createdByEmail?: Maybe<Scalars["String"]>;
  dataRooms?: Maybe<DataRoomsConnection>;
  datasetHash: Scalars["String"];
  datasetLinks?: Maybe<DatasetLinksConnection>;
  datasetMeta?: Maybe<DatasetMeta>;
  datasetStats?: Maybe<DatasetStat>;
  description?: Maybe<Scalars["String"]>;
  id: Scalars["ID"];
  manifest?: Maybe<Scalars["JSON"]>;
  manifestHash: Scalars["String"];
  name: Scalars["String"];
  owner: User;
  ownerEmail?: Maybe<Scalars["String"]>;
  publications: Array<DatasetPublication>;
  size?: Maybe<Scalars["Int"]>;
  statistics?: Maybe<Scalars["JSON"]>;
  updatedAt?: Maybe<Scalars["Datetime"]>;
  updatedByEmail?: Maybe<Scalars["String"]>;
  uploadDate: Scalars["Datetime"];
};

export type DatasetDataRoomsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DataRoomCondition>;
  filter?: InputMaybe<DataRoomFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DataRoomsOrderBy>>;
};

export type DatasetDatasetLinksArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DatasetLinkCondition>;
  filter?: InputMaybe<DatasetLinkFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DatasetLinksOrderBy>>;
};

export type DatasetLink = Node & {
  __typename?: "DatasetLink";
  allowStatisticsSharing: Scalars["Boolean"];
  /** Reads a single `ComputeNode` that is related to this `DatasetLink`. */
  computeNode?: Maybe<ComputeNode>;
  computeNodeId?: Maybe<Scalars["UUID"]>;
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** Reads a single `Dataset` that is related to this `DatasetLink`. */
  dataset?: Maybe<Dataset>;
  datasetHash: Scalars["String"];
  datasetLinkId: Scalars["UUID"];
  /** Reads a single `DatasetMeta` that is related to this `DatasetLink`. */
  datasetMeta?: Maybe<DatasetMeta>;
  /** Reads a single `DatasetStat` that is related to this `DatasetLink`. */
  datasetStats?: Maybe<DatasetStat>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type DatasetLinkAggregates = {
  __typename?: "DatasetLinkAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DatasetLinkDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A filter to be used against aggregates of `DatasetLink` object types. */
export type DatasetLinkAggregatesFilter = {
  /** Distinct count aggregate over matching `DatasetLink` objects. */
  distinctCount?: InputMaybe<DatasetLinkDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `DatasetLink` object to be included within the aggregate. */
  filter?: InputMaybe<DatasetLinkFilter>;
};

/**
 * A condition to be used against `DatasetLink` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DatasetLinkCondition = {
  /** Checks for equality with the object’s `allowStatisticsSharing` field. */
  allowStatisticsSharing?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `computeNodeId` field. */
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `datasetHash` field. */
  datasetHash?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `datasetLinkId` field. */
  datasetLinkId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The fields on `datasetLink` to look up the row to connect. */
export type DatasetLinkDatasetLinkPkeyConnect = {
  datasetLinkId: Scalars["UUID"];
};

/** The fields on `datasetLink` to look up the row to delete. */
export type DatasetLinkDatasetLinkPkeyDelete = {
  datasetLinkId: Scalars["UUID"];
};

export type DatasetLinkDistinct = {
  __typename?: "DatasetLinkDistinct";
  /** Distinct `allowStatisticsSharing` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  allowStatisticsSharing: Array<Scalars["Boolean"]>;
  /** Distinct `computeNodeId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  computeNodeId: Array<Maybe<Scalars["UUID"]>>;
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `datasetHash` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  datasetHash: Array<Scalars["String"]>;
  /** Distinct `datasetLinkId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  datasetLinkId: Array<Scalars["UUID"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type DatasetLinkDistinctCountAggregateFilter = {
  allowStatisticsSharing?: InputMaybe<BigIntFilter>;
  computeNodeId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  createdByEmail?: InputMaybe<BigIntFilter>;
  datasetHash?: InputMaybe<BigIntFilter>;
  datasetLinkId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  updatedByEmail?: InputMaybe<BigIntFilter>;
};

export type DatasetLinkDistinctCountAggregates = {
  __typename?: "DatasetLinkDistinctCountAggregates";
  /** Distinct count of allowStatisticsSharing across the matching connection */
  allowStatisticsSharing?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of computeNodeId across the matching connection */
  computeNodeId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of datasetHash across the matching connection */
  datasetHash?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of datasetLinkId across the matching connection */
  datasetLinkId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `DatasetLink` object types. All fields are combined with a logical ‘and.’ */
export type DatasetLinkFilter = {
  /** Filter by the object’s `allowStatisticsSharing` field. */
  allowStatisticsSharing?: InputMaybe<BooleanFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DatasetLinkFilter>>;
  /** Filter by the object’s `computeNode` relation. */
  computeNode?: InputMaybe<ComputeNodeFilter>;
  /** A related `computeNode` exists. */
  computeNodeExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `computeNodeId` field. */
  computeNodeId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `datasetHash` field. */
  datasetHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `datasetLinkId` field. */
  datasetLinkId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `datasetMeta` relation. */
  datasetMeta?: InputMaybe<DatasetMetaFilter>;
  /** Filter by the object’s `datasetStats` relation. */
  datasetStats?: InputMaybe<DatasetStatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DatasetLinkFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DatasetLinkFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type DatasetLinkGroup = {
  __typename?: "DatasetLinkGroup";
  /** A list of _all_ `DatasetLink` primary keys that belong to the `DatasetLinkGroup`. */
  datasetLinkIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `DatasetLink` of this `DatasetLinkGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `DatasetLinkGroup`. Overriding these may result in the wrong set of `DatasetLink` returned.
   */
  datasetLinks?: Maybe<DatasetLinksConnection>;
  /** `DatasetLinkFilter` that was used to implicitly filter `datasetLinks`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** The count of _all_ `DatasetLink` you could get from the `DatasetLinkGroup`. */
  totalCount: Scalars["Int"];
};

export type DatasetLinkGroupDatasetLinksArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DatasetLinkCondition>;
  filter?: InputMaybe<DatasetLinkFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DatasetLinksOrderBy>>;
};

/** Grouping methods for `DatasetLink` for usage during aggregation. */
export enum DatasetLinkGroupBy {
  AllowStatisticsSharing = "ALLOW_STATISTICS_SHARING",
  ComputeNodeByComputeNodeIdComputationType = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTATION_TYPE",
  ComputeNodeByComputeNodeIdComputeNodeId = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTE_NODE_ID",
  ComputeNodeByComputeNodeIdComputeNodeType = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTE_NODE_TYPE",
  ComputeNodeByComputeNodeIdConfig = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CONFIG",
  ComputeNodeByComputeNodeIdCreatedAt = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CREATED_AT",
  ComputeNodeByComputeNodeIdCreatedByEmail = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CREATED_BY_EMAIL",
  ComputeNodeByComputeNodeIdDataRoomId = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_DATA_ROOM_ID",
  ComputeNodeByComputeNodeIdIndex = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_INDEX",
  ComputeNodeByComputeNodeIdNodeName = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_NODE_NAME",
  ComputeNodeByComputeNodeIdUpdatedAt = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_UPDATED_AT",
  ComputeNodeByComputeNodeIdUpdatedByEmail = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_UPDATED_BY_EMAIL",
  ComputeNodeId = "COMPUTE_NODE_ID",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  DatasetHash = "DATASET_HASH",
  DatasetMetaByDatasetHashCreatedAt = "DATASET_META_BY_DATASET_HASH_CREATED_AT",
  DatasetMetaByDatasetHashCreatedByEmail = "DATASET_META_BY_DATASET_HASH_CREATED_BY_EMAIL",
  DatasetMetaByDatasetHashDatasetHash = "DATASET_META_BY_DATASET_HASH_DATASET_HASH",
  DatasetMetaByDatasetHashDescription = "DATASET_META_BY_DATASET_HASH_DESCRIPTION",
  DatasetMetaByDatasetHashName = "DATASET_META_BY_DATASET_HASH_NAME",
  DatasetMetaByDatasetHashOwnerEmail = "DATASET_META_BY_DATASET_HASH_OWNER_EMAIL",
  DatasetMetaByDatasetHashUpdatedAt = "DATASET_META_BY_DATASET_HASH_UPDATED_AT",
  DatasetMetaByDatasetHashUpdatedByEmail = "DATASET_META_BY_DATASET_HASH_UPDATED_BY_EMAIL",
  DatasetStatByDatasetHashCreatedAt = "DATASET_STAT_BY_DATASET_HASH_CREATED_AT",
  DatasetStatByDatasetHashCreatedByEmail = "DATASET_STAT_BY_DATASET_HASH_CREATED_BY_EMAIL",
  DatasetStatByDatasetHashDatasetHash = "DATASET_STAT_BY_DATASET_HASH_DATASET_HASH",
  DatasetStatByDatasetHashFileSize = "DATASET_STAT_BY_DATASET_HASH_FILE_SIZE",
  DatasetStatByDatasetHashFillRatio = "DATASET_STAT_BY_DATASET_HASH_FILL_RATIO",
  DatasetStatByDatasetHashNumberOfDuplicateRows = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_DUPLICATE_ROWS",
  DatasetStatByDatasetHashNumberOfNotNullableRows = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_NOT_NULLABLE_ROWS",
  DatasetStatByDatasetHashNumberOfRows = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_ROWS",
  DatasetStatByDatasetHashOwnerEmail = "DATASET_STAT_BY_DATASET_HASH_OWNER_EMAIL",
  DatasetStatByDatasetHashStatistics = "DATASET_STAT_BY_DATASET_HASH_STATISTICS",
  DatasetStatByDatasetHashStatisticsVersion = "DATASET_STAT_BY_DATASET_HASH_STATISTICS_VERSION",
  DatasetStatByDatasetHashUpdatedAt = "DATASET_STAT_BY_DATASET_HASH_UPDATED_AT",
  DatasetStatByDatasetHashUpdatedByEmail = "DATASET_STAT_BY_DATASET_HASH_UPDATED_BY_EMAIL",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type DatasetLinkHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetLinkHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `DatasetLink` aggregates. */
export type DatasetLinkHavingInput = {
  AND?: InputMaybe<Array<DatasetLinkHavingInput>>;
  OR?: InputMaybe<Array<DatasetLinkHavingInput>>;
  average?: InputMaybe<DatasetLinkHavingAverageInput>;
  distinctCount?: InputMaybe<DatasetLinkHavingDistinctCountInput>;
  max?: InputMaybe<DatasetLinkHavingMaxInput>;
  min?: InputMaybe<DatasetLinkHavingMinInput>;
  stddevPopulation?: InputMaybe<DatasetLinkHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DatasetLinkHavingStddevSampleInput>;
  sum?: InputMaybe<DatasetLinkHavingSumInput>;
  variancePopulation?: InputMaybe<DatasetLinkHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DatasetLinkHavingVarianceSampleInput>;
};

export type DatasetLinkHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetLinkHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetLinkHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetLinkHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetLinkHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetLinkHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetLinkHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `DatasetLink` */
export type DatasetLinkInput = {
  allowStatisticsSharing?: InputMaybe<Scalars["Boolean"]>;
  computeNode?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0Input>;
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  datasetHash?: InputMaybe<Scalars["String"]>;
  datasetLinkId?: InputMaybe<Scalars["UUID"]>;
  datasetMeta?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey1Input>;
  datasetStats?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey2Input>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DatasetLinkNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `datasetLink` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type DatasetLinkNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `datasetLink` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type DatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey0NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `computeNode` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `computeNode` being updated. */
    patch: ComputeNodePatch;
  };

/** The fields on `datasetLink` to look up the row to update. */
export type DatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey0UsingDatasetLinkPkeyUpdate =
  {
    datasetLinkId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `datasetLink` being updated. */
    patch: UpdateDatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey0Patch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey1NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `datasetMeta` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `datasetMeta` being updated. */
    patch: DatasetMetaPatch;
  };

/** The fields on `datasetLink` to look up the row to update. */
export type DatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey1UsingDatasetLinkPkeyUpdate =
  {
    datasetLinkId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `datasetLink` being updated. */
    patch: UpdateDatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey1Patch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey2NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `datasetStat` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `datasetStat` being updated. */
    patch: DatasetStatPatch;
  };

/** The fields on `datasetLink` to look up the row to update. */
export type DatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey2UsingDatasetLinkPkeyUpdate =
  {
    datasetLinkId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `datasetLink` being updated. */
    patch: UpdateDatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey2Patch;
  };

/** Represents an update to a `DatasetLink`. Fields that are set will be updated. */
export type DatasetLinkPatch = {
  allowStatisticsSharing?: InputMaybe<Scalars["Boolean"]>;
  computeNode?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0Input>;
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  datasetHash?: InputMaybe<Scalars["String"]>;
  datasetLinkId?: InputMaybe<Scalars["UUID"]>;
  datasetMeta?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey1Input>;
  datasetStats?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey2Input>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** A connection to a list of `DatasetLink` values. */
export type DatasetLinksConnection = {
  __typename?: "DatasetLinksConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DatasetLinkAggregates>;
  /** A list of _all_ `DatasetLink` primary keys. */
  datasetLinkIds?: Maybe<Array<Scalars["UUID"]>>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<DatasetLinkDistinct>;
  /** A list of edges which contains the `DatasetLink` and cursor to aid in pagination. */
  edges: Array<DatasetLinksEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DatasetLinkAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<DatasetLinkGroup>>;
  /** A list of `DatasetLink` objects. */
  nodes: Array<Maybe<DatasetLink>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DatasetLink` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `DatasetLink` values. */
export type DatasetLinksConnectionGroupedAggregatesArgs = {
  groupBy: Array<DatasetLinkGroupBy>;
  having?: InputMaybe<DatasetLinkHavingInput>;
};

/** A connection to a list of `DatasetLink` values. */
export type DatasetLinksConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<DatasetLinkGroupBy>;
  having?: InputMaybe<DatasetLinkHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `DatasetLink` edge in the connection. */
export type DatasetLinksEdge = {
  __typename?: "DatasetLinksEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `DatasetLink` at the end of the edge. */
  node?: Maybe<DatasetLink>;
};

/** Methods to use when ordering `DatasetLink`. */
export enum DatasetLinksOrderBy {
  AllowStatisticsSharingAsc = "ALLOW_STATISTICS_SHARING_ASC",
  AllowStatisticsSharingDesc = "ALLOW_STATISTICS_SHARING_DESC",
  ComputeNodeByComputeNodeIdComputationTypeAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTATION_TYPE_ASC",
  ComputeNodeByComputeNodeIdComputationTypeDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTATION_TYPE_DESC",
  ComputeNodeByComputeNodeIdComputeNodeIdAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTE_NODE_ID_ASC",
  ComputeNodeByComputeNodeIdComputeNodeIdDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTE_NODE_ID_DESC",
  ComputeNodeByComputeNodeIdComputeNodeTypeAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTE_NODE_TYPE_ASC",
  ComputeNodeByComputeNodeIdComputeNodeTypeDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTE_NODE_TYPE_DESC",
  ComputeNodeByComputeNodeIdConfigAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CONFIG_ASC",
  ComputeNodeByComputeNodeIdConfigDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CONFIG_DESC",
  ComputeNodeByComputeNodeIdCreatedAtAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CREATED_AT_ASC",
  ComputeNodeByComputeNodeIdCreatedAtDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CREATED_AT_DESC",
  ComputeNodeByComputeNodeIdCreatedByEmailAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CREATED_BY_EMAIL_ASC",
  ComputeNodeByComputeNodeIdCreatedByEmailDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CREATED_BY_EMAIL_DESC",
  ComputeNodeByComputeNodeIdDataRoomIdAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_DATA_ROOM_ID_ASC",
  ComputeNodeByComputeNodeIdDataRoomIdDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_DATA_ROOM_ID_DESC",
  ComputeNodeByComputeNodeIdIndexAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_INDEX_ASC",
  ComputeNodeByComputeNodeIdIndexDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_INDEX_DESC",
  ComputeNodeByComputeNodeIdNodeNameAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_NODE_NAME_ASC",
  ComputeNodeByComputeNodeIdNodeNameDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_NODE_NAME_DESC",
  ComputeNodeByComputeNodeIdUpdatedAtAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_UPDATED_AT_ASC",
  ComputeNodeByComputeNodeIdUpdatedAtDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_UPDATED_AT_DESC",
  ComputeNodeByComputeNodeIdUpdatedByEmailAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_UPDATED_BY_EMAIL_ASC",
  ComputeNodeByComputeNodeIdUpdatedByEmailDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_UPDATED_BY_EMAIL_DESC",
  ComputeNodeIdAsc = "COMPUTE_NODE_ID_ASC",
  ComputeNodeIdDesc = "COMPUTE_NODE_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  DatasetHashAsc = "DATASET_HASH_ASC",
  DatasetHashDesc = "DATASET_HASH_DESC",
  DatasetLinkIdAsc = "DATASET_LINK_ID_ASC",
  DatasetLinkIdDesc = "DATASET_LINK_ID_DESC",
  DatasetMetaByDatasetHashCreatedAtAsc = "DATASET_META_BY_DATASET_HASH_CREATED_AT_ASC",
  DatasetMetaByDatasetHashCreatedAtDesc = "DATASET_META_BY_DATASET_HASH_CREATED_AT_DESC",
  DatasetMetaByDatasetHashCreatedByEmailAsc = "DATASET_META_BY_DATASET_HASH_CREATED_BY_EMAIL_ASC",
  DatasetMetaByDatasetHashCreatedByEmailDesc = "DATASET_META_BY_DATASET_HASH_CREATED_BY_EMAIL_DESC",
  DatasetMetaByDatasetHashDatasetHashAsc = "DATASET_META_BY_DATASET_HASH_DATASET_HASH_ASC",
  DatasetMetaByDatasetHashDatasetHashDesc = "DATASET_META_BY_DATASET_HASH_DATASET_HASH_DESC",
  DatasetMetaByDatasetHashDescriptionAsc = "DATASET_META_BY_DATASET_HASH_DESCRIPTION_ASC",
  DatasetMetaByDatasetHashDescriptionDesc = "DATASET_META_BY_DATASET_HASH_DESCRIPTION_DESC",
  DatasetMetaByDatasetHashNameAsc = "DATASET_META_BY_DATASET_HASH_NAME_ASC",
  DatasetMetaByDatasetHashNameDesc = "DATASET_META_BY_DATASET_HASH_NAME_DESC",
  DatasetMetaByDatasetHashOwnerEmailAsc = "DATASET_META_BY_DATASET_HASH_OWNER_EMAIL_ASC",
  DatasetMetaByDatasetHashOwnerEmailDesc = "DATASET_META_BY_DATASET_HASH_OWNER_EMAIL_DESC",
  DatasetMetaByDatasetHashUpdatedAtAsc = "DATASET_META_BY_DATASET_HASH_UPDATED_AT_ASC",
  DatasetMetaByDatasetHashUpdatedAtDesc = "DATASET_META_BY_DATASET_HASH_UPDATED_AT_DESC",
  DatasetMetaByDatasetHashUpdatedByEmailAsc = "DATASET_META_BY_DATASET_HASH_UPDATED_BY_EMAIL_ASC",
  DatasetMetaByDatasetHashUpdatedByEmailDesc = "DATASET_META_BY_DATASET_HASH_UPDATED_BY_EMAIL_DESC",
  DatasetStatByDatasetHashCreatedAtAsc = "DATASET_STAT_BY_DATASET_HASH_CREATED_AT_ASC",
  DatasetStatByDatasetHashCreatedAtDesc = "DATASET_STAT_BY_DATASET_HASH_CREATED_AT_DESC",
  DatasetStatByDatasetHashCreatedByEmailAsc = "DATASET_STAT_BY_DATASET_HASH_CREATED_BY_EMAIL_ASC",
  DatasetStatByDatasetHashCreatedByEmailDesc = "DATASET_STAT_BY_DATASET_HASH_CREATED_BY_EMAIL_DESC",
  DatasetStatByDatasetHashDatasetHashAsc = "DATASET_STAT_BY_DATASET_HASH_DATASET_HASH_ASC",
  DatasetStatByDatasetHashDatasetHashDesc = "DATASET_STAT_BY_DATASET_HASH_DATASET_HASH_DESC",
  DatasetStatByDatasetHashFileSizeAsc = "DATASET_STAT_BY_DATASET_HASH_FILE_SIZE_ASC",
  DatasetStatByDatasetHashFileSizeDesc = "DATASET_STAT_BY_DATASET_HASH_FILE_SIZE_DESC",
  DatasetStatByDatasetHashFillRatioAsc = "DATASET_STAT_BY_DATASET_HASH_FILL_RATIO_ASC",
  DatasetStatByDatasetHashFillRatioDesc = "DATASET_STAT_BY_DATASET_HASH_FILL_RATIO_DESC",
  DatasetStatByDatasetHashNumberOfDuplicateRowsAsc = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_DUPLICATE_ROWS_ASC",
  DatasetStatByDatasetHashNumberOfDuplicateRowsDesc = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_DUPLICATE_ROWS_DESC",
  DatasetStatByDatasetHashNumberOfNotNullableRowsAsc = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_NOT_NULLABLE_ROWS_ASC",
  DatasetStatByDatasetHashNumberOfNotNullableRowsDesc = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_NOT_NULLABLE_ROWS_DESC",
  DatasetStatByDatasetHashNumberOfRowsAsc = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_ROWS_ASC",
  DatasetStatByDatasetHashNumberOfRowsDesc = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_ROWS_DESC",
  DatasetStatByDatasetHashOwnerEmailAsc = "DATASET_STAT_BY_DATASET_HASH_OWNER_EMAIL_ASC",
  DatasetStatByDatasetHashOwnerEmailDesc = "DATASET_STAT_BY_DATASET_HASH_OWNER_EMAIL_DESC",
  DatasetStatByDatasetHashStatisticsAsc = "DATASET_STAT_BY_DATASET_HASH_STATISTICS_ASC",
  DatasetStatByDatasetHashStatisticsDesc = "DATASET_STAT_BY_DATASET_HASH_STATISTICS_DESC",
  DatasetStatByDatasetHashStatisticsVersionAsc = "DATASET_STAT_BY_DATASET_HASH_STATISTICS_VERSION_ASC",
  DatasetStatByDatasetHashStatisticsVersionDesc = "DATASET_STAT_BY_DATASET_HASH_STATISTICS_VERSION_DESC",
  DatasetStatByDatasetHashUpdatedAtAsc = "DATASET_STAT_BY_DATASET_HASH_UPDATED_AT_ASC",
  DatasetStatByDatasetHashUpdatedAtDesc = "DATASET_STAT_BY_DATASET_HASH_UPDATED_AT_DESC",
  DatasetStatByDatasetHashUpdatedByEmailAsc = "DATASET_STAT_BY_DATASET_HASH_UPDATED_BY_EMAIL_ASC",
  DatasetStatByDatasetHashUpdatedByEmailDesc = "DATASET_STAT_BY_DATASET_HASH_UPDATED_BY_EMAIL_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

export type DatasetMeta = Node & {
  __typename?: "DatasetMeta";
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** Reads a single `Dataset` that is related to this `DatasetMeta`. */
  dataset?: Maybe<Dataset>;
  datasetHash: Scalars["String"];
  /** Reads and enables pagination through a set of `DatasetLink`. */
  datasetLinks: DatasetLinksConnection;
  /** Reads a single `DatasetStat` that is related to this `DatasetMeta`. */
  datasetStats?: Maybe<DatasetStat>;
  description?: Maybe<Scalars["String"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  ownerEmail: Scalars["String"];
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type DatasetMetaDatasetLinksArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DatasetLinkCondition>;
  filter?: InputMaybe<DatasetLinkFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DatasetLinksOrderBy>>;
};

export type DatasetMetaAggregates = {
  __typename?: "DatasetMetaAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DatasetMetaDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/**
 * A condition to be used against `DatasetMeta` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DatasetMetaCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `datasetHash` field. */
  datasetHash?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `ownerEmail` field. */
  ownerEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The fields on `datasetMeta` to look up the row to connect. */
export type DatasetMetaDatasetMetaPkeyConnect = {
  datasetHash: Scalars["String"];
};

/** The fields on `datasetMeta` to look up the row to delete. */
export type DatasetMetaDatasetMetaPkeyDelete = {
  datasetHash: Scalars["String"];
};

export type DatasetMetaDistinct = {
  __typename?: "DatasetMetaDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `datasetHash` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  datasetHash: Array<Scalars["String"]>;
  /** Distinct `description` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  description: Array<Maybe<Scalars["String"]>>;
  /** Distinct `name` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  name: Array<Maybe<Scalars["String"]>>;
  /** Distinct `ownerEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  ownerEmail: Array<Scalars["String"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type DatasetMetaDistinctCountAggregates = {
  __typename?: "DatasetMetaDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of datasetHash across the matching connection */
  datasetHash?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of ownerEmail across the matching connection */
  ownerEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `DatasetMeta` object types. All fields are combined with a logical ‘and.’ */
export type DatasetMetaFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DatasetMetaFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `datasetHash` field. */
  datasetHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `datasetLinks` relation. */
  datasetLinks?: InputMaybe<DatasetMetaToManyDatasetLinkFilter>;
  /** Some related `datasetLinks` exist. */
  datasetLinksExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `datasetStats` relation. */
  datasetStats?: InputMaybe<DatasetStatFilter>;
  /** A related `datasetStats` exists. */
  datasetStatsExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DatasetMetaFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DatasetMetaFilter>>;
  /** Filter by the object’s `ownerEmail` field. */
  ownerEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type DatasetMetaGroup = {
  __typename?: "DatasetMetaGroup";
  /** A list of _all_ `DatasetMeta` primary keys that belong to the `DatasetMetaGroup`. */
  datasetHashes?: Maybe<Array<Scalars["String"]>>;
  /**
   * Reads and enables pagination through a set of `DatasetMeta` of this `DatasetMetaGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `DatasetMetaGroup`. Overriding these may result in the wrong set of `DatasetMeta` returned.
   */
  datasetMetas?: Maybe<DatasetMetasConnection>;
  /** `DatasetMetaFilter` that was used to implicitly filter `datasetMetas`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** The count of _all_ `DatasetMeta` you could get from the `DatasetMetaGroup`. */
  totalCount: Scalars["Int"];
};

export type DatasetMetaGroupDatasetMetasArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DatasetMetaCondition>;
  filter?: InputMaybe<DatasetMetaFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DatasetMetasOrderBy>>;
};

/** Grouping methods for `DatasetMeta` for usage during aggregation. */
export enum DatasetMetaGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  DatasetStatByDatasetHashCreatedAt = "DATASET_STAT_BY_DATASET_HASH_CREATED_AT",
  DatasetStatByDatasetHashCreatedByEmail = "DATASET_STAT_BY_DATASET_HASH_CREATED_BY_EMAIL",
  DatasetStatByDatasetHashDatasetHash = "DATASET_STAT_BY_DATASET_HASH_DATASET_HASH",
  DatasetStatByDatasetHashFileSize = "DATASET_STAT_BY_DATASET_HASH_FILE_SIZE",
  DatasetStatByDatasetHashFillRatio = "DATASET_STAT_BY_DATASET_HASH_FILL_RATIO",
  DatasetStatByDatasetHashNumberOfDuplicateRows = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_DUPLICATE_ROWS",
  DatasetStatByDatasetHashNumberOfNotNullableRows = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_NOT_NULLABLE_ROWS",
  DatasetStatByDatasetHashNumberOfRows = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_ROWS",
  DatasetStatByDatasetHashOwnerEmail = "DATASET_STAT_BY_DATASET_HASH_OWNER_EMAIL",
  DatasetStatByDatasetHashStatistics = "DATASET_STAT_BY_DATASET_HASH_STATISTICS",
  DatasetStatByDatasetHashStatisticsVersion = "DATASET_STAT_BY_DATASET_HASH_STATISTICS_VERSION",
  DatasetStatByDatasetHashUpdatedAt = "DATASET_STAT_BY_DATASET_HASH_UPDATED_AT",
  DatasetStatByDatasetHashUpdatedByEmail = "DATASET_STAT_BY_DATASET_HASH_UPDATED_BY_EMAIL",
  Description = "DESCRIPTION",
  Name = "NAME",
  OwnerEmail = "OWNER_EMAIL",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type DatasetMetaHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetMetaHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `DatasetMeta` aggregates. */
export type DatasetMetaHavingInput = {
  AND?: InputMaybe<Array<DatasetMetaHavingInput>>;
  OR?: InputMaybe<Array<DatasetMetaHavingInput>>;
  average?: InputMaybe<DatasetMetaHavingAverageInput>;
  distinctCount?: InputMaybe<DatasetMetaHavingDistinctCountInput>;
  max?: InputMaybe<DatasetMetaHavingMaxInput>;
  min?: InputMaybe<DatasetMetaHavingMinInput>;
  stddevPopulation?: InputMaybe<DatasetMetaHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DatasetMetaHavingStddevSampleInput>;
  sum?: InputMaybe<DatasetMetaHavingSumInput>;
  variancePopulation?: InputMaybe<DatasetMetaHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DatasetMetaHavingVarianceSampleInput>;
};

export type DatasetMetaHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetMetaHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetMetaHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetMetaHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetMetaHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetMetaHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetMetaHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `DatasetMeta` */
export type DatasetMetaInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  datasetHash: Scalars["String"];
  datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey1InverseInput>;
  datasetStats?: InputMaybe<FakeDecentriqPlatformDatasetStatsForeignKey0InverseInput>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DatasetMetaNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `datasetMeta` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type DatasetMetaNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `datasetMeta` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type DatasetMetaOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey1NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `datasetLink` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `datasetLink` being updated. */
    patch: DatasetLinkPatch;
  };

/** The fields on `datasetMeta` to look up the row to update. */
export type DatasetMetaOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey1UsingDatasetMetaPkeyUpdate =
  {
    datasetHash: Scalars["String"];
    /** An object where the defined keys will be set on the `datasetMeta` being updated. */
    patch: UpdateDatasetMetaOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey1Patch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DatasetMetaOnDatasetStatForFakeDecentriqPlatformDatasetStatsForeignKey0NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `datasetStat` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `datasetStat` being updated. */
    patch: DatasetStatPatch;
  };

/** The fields on `datasetMeta` to look up the row to update. */
export type DatasetMetaOnDatasetStatForFakeDecentriqPlatformDatasetStatsForeignKey0UsingDatasetMetaPkeyUpdate =
  {
    datasetHash: Scalars["String"];
    /** An object where the defined keys will be set on the `datasetMeta` being updated. */
    patch: UpdateDatasetMetaOnDatasetStatForFakeDecentriqPlatformDatasetStatsForeignKey0Patch;
  };

/** Represents an update to a `DatasetMeta`. Fields that are set will be updated. */
export type DatasetMetaPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  datasetHash?: InputMaybe<Scalars["String"]>;
  datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey1InverseInput>;
  datasetStats?: InputMaybe<FakeDecentriqPlatformDatasetStatsForeignKey0InverseInput>;
  description?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against many `DatasetLink` object types. All fields are combined with a logical ‘and.’ */
export type DatasetMetaToManyDatasetLinkFilter = {
  /** Aggregates across related `DatasetLink` match the filter criteria. */
  aggregates?: InputMaybe<DatasetLinkAggregatesFilter>;
  /** Every related `DatasetLink` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetLinkFilter>;
  /** No related `DatasetLink` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetLinkFilter>;
  /** Some related `DatasetLink` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetLinkFilter>;
};

/** A connection to a list of `DatasetMeta` values. */
export type DatasetMetasConnection = {
  __typename?: "DatasetMetasConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DatasetMetaAggregates>;
  /** A list of _all_ `DatasetMeta` primary keys. */
  datasetHashes?: Maybe<Array<Scalars["String"]>>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<DatasetMetaDistinct>;
  /** A list of edges which contains the `DatasetMeta` and cursor to aid in pagination. */
  edges: Array<DatasetMetasEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DatasetMetaAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<DatasetMetaGroup>>;
  /** A list of `DatasetMeta` objects. */
  nodes: Array<Maybe<DatasetMeta>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DatasetMeta` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `DatasetMeta` values. */
export type DatasetMetasConnectionGroupedAggregatesArgs = {
  groupBy: Array<DatasetMetaGroupBy>;
  having?: InputMaybe<DatasetMetaHavingInput>;
};

/** A connection to a list of `DatasetMeta` values. */
export type DatasetMetasConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<DatasetMetaGroupBy>;
  having?: InputMaybe<DatasetMetaHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `DatasetMeta` edge in the connection. */
export type DatasetMetasEdge = {
  __typename?: "DatasetMetasEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `DatasetMeta` at the end of the edge. */
  node?: Maybe<DatasetMeta>;
};

/** Methods to use when ordering `DatasetMeta`. */
export enum DatasetMetasOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  DatasetHashAsc = "DATASET_HASH_ASC",
  DatasetHashDesc = "DATASET_HASH_DESC",
  DatasetLinksAverageAllowStatisticsSharingAsc = "DATASET_LINKS_AVERAGE_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksAverageAllowStatisticsSharingDesc = "DATASET_LINKS_AVERAGE_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksAverageComputeNodeIdAsc = "DATASET_LINKS_AVERAGE_COMPUTE_NODE_ID_ASC",
  DatasetLinksAverageComputeNodeIdDesc = "DATASET_LINKS_AVERAGE_COMPUTE_NODE_ID_DESC",
  DatasetLinksAverageCreatedAtAsc = "DATASET_LINKS_AVERAGE_CREATED_AT_ASC",
  DatasetLinksAverageCreatedAtDesc = "DATASET_LINKS_AVERAGE_CREATED_AT_DESC",
  DatasetLinksAverageCreatedByEmailAsc = "DATASET_LINKS_AVERAGE_CREATED_BY_EMAIL_ASC",
  DatasetLinksAverageCreatedByEmailDesc = "DATASET_LINKS_AVERAGE_CREATED_BY_EMAIL_DESC",
  DatasetLinksAverageDatasetHashAsc = "DATASET_LINKS_AVERAGE_DATASET_HASH_ASC",
  DatasetLinksAverageDatasetHashDesc = "DATASET_LINKS_AVERAGE_DATASET_HASH_DESC",
  DatasetLinksAverageDatasetLinkIdAsc = "DATASET_LINKS_AVERAGE_DATASET_LINK_ID_ASC",
  DatasetLinksAverageDatasetLinkIdDesc = "DATASET_LINKS_AVERAGE_DATASET_LINK_ID_DESC",
  DatasetLinksAverageUpdatedAtAsc = "DATASET_LINKS_AVERAGE_UPDATED_AT_ASC",
  DatasetLinksAverageUpdatedAtDesc = "DATASET_LINKS_AVERAGE_UPDATED_AT_DESC",
  DatasetLinksAverageUpdatedByEmailAsc = "DATASET_LINKS_AVERAGE_UPDATED_BY_EMAIL_ASC",
  DatasetLinksAverageUpdatedByEmailDesc = "DATASET_LINKS_AVERAGE_UPDATED_BY_EMAIL_DESC",
  DatasetLinksByDatasetHashCountAsc = "DATASET_LINKS_BY_DATASET_HASH_COUNT_ASC",
  DatasetLinksByDatasetHashCountDesc = "DATASET_LINKS_BY_DATASET_HASH_COUNT_DESC",
  DatasetLinksByDatasetHashMaxAllowStatisticsSharingAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksByDatasetHashMaxAllowStatisticsSharingDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksByDatasetHashMaxComputeNodeIdAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_COMPUTE_NODE_ID_ASC",
  DatasetLinksByDatasetHashMaxComputeNodeIdDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_COMPUTE_NODE_ID_DESC",
  DatasetLinksByDatasetHashMaxCreatedAtAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_CREATED_AT_ASC",
  DatasetLinksByDatasetHashMaxCreatedAtDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_CREATED_AT_DESC",
  DatasetLinksByDatasetHashMaxCreatedByEmailAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_CREATED_BY_EMAIL_ASC",
  DatasetLinksByDatasetHashMaxCreatedByEmailDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_CREATED_BY_EMAIL_DESC",
  DatasetLinksByDatasetHashMaxDatasetHashAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_DATASET_HASH_ASC",
  DatasetLinksByDatasetHashMaxDatasetHashDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_DATASET_HASH_DESC",
  DatasetLinksByDatasetHashMaxDatasetLinkIdAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_DATASET_LINK_ID_ASC",
  DatasetLinksByDatasetHashMaxDatasetLinkIdDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_DATASET_LINK_ID_DESC",
  DatasetLinksByDatasetHashMaxUpdatedAtAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_UPDATED_AT_ASC",
  DatasetLinksByDatasetHashMaxUpdatedAtDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_UPDATED_AT_DESC",
  DatasetLinksByDatasetHashMaxUpdatedByEmailAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_UPDATED_BY_EMAIL_ASC",
  DatasetLinksByDatasetHashMaxUpdatedByEmailDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_UPDATED_BY_EMAIL_DESC",
  DatasetLinksByDatasetHashMinAllowStatisticsSharingAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksByDatasetHashMinAllowStatisticsSharingDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksByDatasetHashMinComputeNodeIdAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_COMPUTE_NODE_ID_ASC",
  DatasetLinksByDatasetHashMinComputeNodeIdDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_COMPUTE_NODE_ID_DESC",
  DatasetLinksByDatasetHashMinCreatedAtAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_CREATED_AT_ASC",
  DatasetLinksByDatasetHashMinCreatedAtDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_CREATED_AT_DESC",
  DatasetLinksByDatasetHashMinCreatedByEmailAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_CREATED_BY_EMAIL_ASC",
  DatasetLinksByDatasetHashMinCreatedByEmailDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_CREATED_BY_EMAIL_DESC",
  DatasetLinksByDatasetHashMinDatasetHashAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_DATASET_HASH_ASC",
  DatasetLinksByDatasetHashMinDatasetHashDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_DATASET_HASH_DESC",
  DatasetLinksByDatasetHashMinDatasetLinkIdAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_DATASET_LINK_ID_ASC",
  DatasetLinksByDatasetHashMinDatasetLinkIdDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_DATASET_LINK_ID_DESC",
  DatasetLinksByDatasetHashMinUpdatedAtAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_UPDATED_AT_ASC",
  DatasetLinksByDatasetHashMinUpdatedAtDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_UPDATED_AT_DESC",
  DatasetLinksByDatasetHashMinUpdatedByEmailAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_UPDATED_BY_EMAIL_ASC",
  DatasetLinksByDatasetHashMinUpdatedByEmailDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_UPDATED_BY_EMAIL_DESC",
  DatasetLinksCountAsc = "DATASET_LINKS_COUNT_ASC",
  DatasetLinksCountDesc = "DATASET_LINKS_COUNT_DESC",
  DatasetLinksDistinctCountAllowStatisticsSharingAsc = "DATASET_LINKS_DISTINCT_COUNT_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksDistinctCountAllowStatisticsSharingDesc = "DATASET_LINKS_DISTINCT_COUNT_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksDistinctCountComputeNodeIdAsc = "DATASET_LINKS_DISTINCT_COUNT_COMPUTE_NODE_ID_ASC",
  DatasetLinksDistinctCountComputeNodeIdDesc = "DATASET_LINKS_DISTINCT_COUNT_COMPUTE_NODE_ID_DESC",
  DatasetLinksDistinctCountCreatedAtAsc = "DATASET_LINKS_DISTINCT_COUNT_CREATED_AT_ASC",
  DatasetLinksDistinctCountCreatedAtDesc = "DATASET_LINKS_DISTINCT_COUNT_CREATED_AT_DESC",
  DatasetLinksDistinctCountCreatedByEmailAsc = "DATASET_LINKS_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  DatasetLinksDistinctCountCreatedByEmailDesc = "DATASET_LINKS_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  DatasetLinksDistinctCountDatasetHashAsc = "DATASET_LINKS_DISTINCT_COUNT_DATASET_HASH_ASC",
  DatasetLinksDistinctCountDatasetHashDesc = "DATASET_LINKS_DISTINCT_COUNT_DATASET_HASH_DESC",
  DatasetLinksDistinctCountDatasetLinkIdAsc = "DATASET_LINKS_DISTINCT_COUNT_DATASET_LINK_ID_ASC",
  DatasetLinksDistinctCountDatasetLinkIdDesc = "DATASET_LINKS_DISTINCT_COUNT_DATASET_LINK_ID_DESC",
  DatasetLinksDistinctCountUpdatedAtAsc = "DATASET_LINKS_DISTINCT_COUNT_UPDATED_AT_ASC",
  DatasetLinksDistinctCountUpdatedAtDesc = "DATASET_LINKS_DISTINCT_COUNT_UPDATED_AT_DESC",
  DatasetLinksDistinctCountUpdatedByEmailAsc = "DATASET_LINKS_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  DatasetLinksDistinctCountUpdatedByEmailDesc = "DATASET_LINKS_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  DatasetLinksMaxAllowStatisticsSharingAsc = "DATASET_LINKS_MAX_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksMaxAllowStatisticsSharingDesc = "DATASET_LINKS_MAX_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksMaxComputeNodeIdAsc = "DATASET_LINKS_MAX_COMPUTE_NODE_ID_ASC",
  DatasetLinksMaxComputeNodeIdDesc = "DATASET_LINKS_MAX_COMPUTE_NODE_ID_DESC",
  DatasetLinksMaxCreatedAtAsc = "DATASET_LINKS_MAX_CREATED_AT_ASC",
  DatasetLinksMaxCreatedAtDesc = "DATASET_LINKS_MAX_CREATED_AT_DESC",
  DatasetLinksMaxCreatedByEmailAsc = "DATASET_LINKS_MAX_CREATED_BY_EMAIL_ASC",
  DatasetLinksMaxCreatedByEmailDesc = "DATASET_LINKS_MAX_CREATED_BY_EMAIL_DESC",
  DatasetLinksMaxDatasetHashAsc = "DATASET_LINKS_MAX_DATASET_HASH_ASC",
  DatasetLinksMaxDatasetHashDesc = "DATASET_LINKS_MAX_DATASET_HASH_DESC",
  DatasetLinksMaxDatasetLinkIdAsc = "DATASET_LINKS_MAX_DATASET_LINK_ID_ASC",
  DatasetLinksMaxDatasetLinkIdDesc = "DATASET_LINKS_MAX_DATASET_LINK_ID_DESC",
  DatasetLinksMaxUpdatedAtAsc = "DATASET_LINKS_MAX_UPDATED_AT_ASC",
  DatasetLinksMaxUpdatedAtDesc = "DATASET_LINKS_MAX_UPDATED_AT_DESC",
  DatasetLinksMaxUpdatedByEmailAsc = "DATASET_LINKS_MAX_UPDATED_BY_EMAIL_ASC",
  DatasetLinksMaxUpdatedByEmailDesc = "DATASET_LINKS_MAX_UPDATED_BY_EMAIL_DESC",
  DatasetLinksMinAllowStatisticsSharingAsc = "DATASET_LINKS_MIN_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksMinAllowStatisticsSharingDesc = "DATASET_LINKS_MIN_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksMinComputeNodeIdAsc = "DATASET_LINKS_MIN_COMPUTE_NODE_ID_ASC",
  DatasetLinksMinComputeNodeIdDesc = "DATASET_LINKS_MIN_COMPUTE_NODE_ID_DESC",
  DatasetLinksMinCreatedAtAsc = "DATASET_LINKS_MIN_CREATED_AT_ASC",
  DatasetLinksMinCreatedAtDesc = "DATASET_LINKS_MIN_CREATED_AT_DESC",
  DatasetLinksMinCreatedByEmailAsc = "DATASET_LINKS_MIN_CREATED_BY_EMAIL_ASC",
  DatasetLinksMinCreatedByEmailDesc = "DATASET_LINKS_MIN_CREATED_BY_EMAIL_DESC",
  DatasetLinksMinDatasetHashAsc = "DATASET_LINKS_MIN_DATASET_HASH_ASC",
  DatasetLinksMinDatasetHashDesc = "DATASET_LINKS_MIN_DATASET_HASH_DESC",
  DatasetLinksMinDatasetLinkIdAsc = "DATASET_LINKS_MIN_DATASET_LINK_ID_ASC",
  DatasetLinksMinDatasetLinkIdDesc = "DATASET_LINKS_MIN_DATASET_LINK_ID_DESC",
  DatasetLinksMinUpdatedAtAsc = "DATASET_LINKS_MIN_UPDATED_AT_ASC",
  DatasetLinksMinUpdatedAtDesc = "DATASET_LINKS_MIN_UPDATED_AT_DESC",
  DatasetLinksMinUpdatedByEmailAsc = "DATASET_LINKS_MIN_UPDATED_BY_EMAIL_ASC",
  DatasetLinksMinUpdatedByEmailDesc = "DATASET_LINKS_MIN_UPDATED_BY_EMAIL_DESC",
  DatasetLinksStddevPopulationAllowStatisticsSharingAsc = "DATASET_LINKS_STDDEV_POPULATION_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksStddevPopulationAllowStatisticsSharingDesc = "DATASET_LINKS_STDDEV_POPULATION_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksStddevPopulationComputeNodeIdAsc = "DATASET_LINKS_STDDEV_POPULATION_COMPUTE_NODE_ID_ASC",
  DatasetLinksStddevPopulationComputeNodeIdDesc = "DATASET_LINKS_STDDEV_POPULATION_COMPUTE_NODE_ID_DESC",
  DatasetLinksStddevPopulationCreatedAtAsc = "DATASET_LINKS_STDDEV_POPULATION_CREATED_AT_ASC",
  DatasetLinksStddevPopulationCreatedAtDesc = "DATASET_LINKS_STDDEV_POPULATION_CREATED_AT_DESC",
  DatasetLinksStddevPopulationCreatedByEmailAsc = "DATASET_LINKS_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  DatasetLinksStddevPopulationCreatedByEmailDesc = "DATASET_LINKS_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  DatasetLinksStddevPopulationDatasetHashAsc = "DATASET_LINKS_STDDEV_POPULATION_DATASET_HASH_ASC",
  DatasetLinksStddevPopulationDatasetHashDesc = "DATASET_LINKS_STDDEV_POPULATION_DATASET_HASH_DESC",
  DatasetLinksStddevPopulationDatasetLinkIdAsc = "DATASET_LINKS_STDDEV_POPULATION_DATASET_LINK_ID_ASC",
  DatasetLinksStddevPopulationDatasetLinkIdDesc = "DATASET_LINKS_STDDEV_POPULATION_DATASET_LINK_ID_DESC",
  DatasetLinksStddevPopulationUpdatedAtAsc = "DATASET_LINKS_STDDEV_POPULATION_UPDATED_AT_ASC",
  DatasetLinksStddevPopulationUpdatedAtDesc = "DATASET_LINKS_STDDEV_POPULATION_UPDATED_AT_DESC",
  DatasetLinksStddevPopulationUpdatedByEmailAsc = "DATASET_LINKS_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  DatasetLinksStddevPopulationUpdatedByEmailDesc = "DATASET_LINKS_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  DatasetLinksStddevSampleAllowStatisticsSharingAsc = "DATASET_LINKS_STDDEV_SAMPLE_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksStddevSampleAllowStatisticsSharingDesc = "DATASET_LINKS_STDDEV_SAMPLE_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksStddevSampleComputeNodeIdAsc = "DATASET_LINKS_STDDEV_SAMPLE_COMPUTE_NODE_ID_ASC",
  DatasetLinksStddevSampleComputeNodeIdDesc = "DATASET_LINKS_STDDEV_SAMPLE_COMPUTE_NODE_ID_DESC",
  DatasetLinksStddevSampleCreatedAtAsc = "DATASET_LINKS_STDDEV_SAMPLE_CREATED_AT_ASC",
  DatasetLinksStddevSampleCreatedAtDesc = "DATASET_LINKS_STDDEV_SAMPLE_CREATED_AT_DESC",
  DatasetLinksStddevSampleCreatedByEmailAsc = "DATASET_LINKS_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  DatasetLinksStddevSampleCreatedByEmailDesc = "DATASET_LINKS_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  DatasetLinksStddevSampleDatasetHashAsc = "DATASET_LINKS_STDDEV_SAMPLE_DATASET_HASH_ASC",
  DatasetLinksStddevSampleDatasetHashDesc = "DATASET_LINKS_STDDEV_SAMPLE_DATASET_HASH_DESC",
  DatasetLinksStddevSampleDatasetLinkIdAsc = "DATASET_LINKS_STDDEV_SAMPLE_DATASET_LINK_ID_ASC",
  DatasetLinksStddevSampleDatasetLinkIdDesc = "DATASET_LINKS_STDDEV_SAMPLE_DATASET_LINK_ID_DESC",
  DatasetLinksStddevSampleUpdatedAtAsc = "DATASET_LINKS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  DatasetLinksStddevSampleUpdatedAtDesc = "DATASET_LINKS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  DatasetLinksStddevSampleUpdatedByEmailAsc = "DATASET_LINKS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  DatasetLinksStddevSampleUpdatedByEmailDesc = "DATASET_LINKS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  DatasetLinksSumAllowStatisticsSharingAsc = "DATASET_LINKS_SUM_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksSumAllowStatisticsSharingDesc = "DATASET_LINKS_SUM_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksSumComputeNodeIdAsc = "DATASET_LINKS_SUM_COMPUTE_NODE_ID_ASC",
  DatasetLinksSumComputeNodeIdDesc = "DATASET_LINKS_SUM_COMPUTE_NODE_ID_DESC",
  DatasetLinksSumCreatedAtAsc = "DATASET_LINKS_SUM_CREATED_AT_ASC",
  DatasetLinksSumCreatedAtDesc = "DATASET_LINKS_SUM_CREATED_AT_DESC",
  DatasetLinksSumCreatedByEmailAsc = "DATASET_LINKS_SUM_CREATED_BY_EMAIL_ASC",
  DatasetLinksSumCreatedByEmailDesc = "DATASET_LINKS_SUM_CREATED_BY_EMAIL_DESC",
  DatasetLinksSumDatasetHashAsc = "DATASET_LINKS_SUM_DATASET_HASH_ASC",
  DatasetLinksSumDatasetHashDesc = "DATASET_LINKS_SUM_DATASET_HASH_DESC",
  DatasetLinksSumDatasetLinkIdAsc = "DATASET_LINKS_SUM_DATASET_LINK_ID_ASC",
  DatasetLinksSumDatasetLinkIdDesc = "DATASET_LINKS_SUM_DATASET_LINK_ID_DESC",
  DatasetLinksSumUpdatedAtAsc = "DATASET_LINKS_SUM_UPDATED_AT_ASC",
  DatasetLinksSumUpdatedAtDesc = "DATASET_LINKS_SUM_UPDATED_AT_DESC",
  DatasetLinksSumUpdatedByEmailAsc = "DATASET_LINKS_SUM_UPDATED_BY_EMAIL_ASC",
  DatasetLinksSumUpdatedByEmailDesc = "DATASET_LINKS_SUM_UPDATED_BY_EMAIL_DESC",
  DatasetLinksVariancePopulationAllowStatisticsSharingAsc = "DATASET_LINKS_VARIANCE_POPULATION_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksVariancePopulationAllowStatisticsSharingDesc = "DATASET_LINKS_VARIANCE_POPULATION_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksVariancePopulationComputeNodeIdAsc = "DATASET_LINKS_VARIANCE_POPULATION_COMPUTE_NODE_ID_ASC",
  DatasetLinksVariancePopulationComputeNodeIdDesc = "DATASET_LINKS_VARIANCE_POPULATION_COMPUTE_NODE_ID_DESC",
  DatasetLinksVariancePopulationCreatedAtAsc = "DATASET_LINKS_VARIANCE_POPULATION_CREATED_AT_ASC",
  DatasetLinksVariancePopulationCreatedAtDesc = "DATASET_LINKS_VARIANCE_POPULATION_CREATED_AT_DESC",
  DatasetLinksVariancePopulationCreatedByEmailAsc = "DATASET_LINKS_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  DatasetLinksVariancePopulationCreatedByEmailDesc = "DATASET_LINKS_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  DatasetLinksVariancePopulationDatasetHashAsc = "DATASET_LINKS_VARIANCE_POPULATION_DATASET_HASH_ASC",
  DatasetLinksVariancePopulationDatasetHashDesc = "DATASET_LINKS_VARIANCE_POPULATION_DATASET_HASH_DESC",
  DatasetLinksVariancePopulationDatasetLinkIdAsc = "DATASET_LINKS_VARIANCE_POPULATION_DATASET_LINK_ID_ASC",
  DatasetLinksVariancePopulationDatasetLinkIdDesc = "DATASET_LINKS_VARIANCE_POPULATION_DATASET_LINK_ID_DESC",
  DatasetLinksVariancePopulationUpdatedAtAsc = "DATASET_LINKS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  DatasetLinksVariancePopulationUpdatedAtDesc = "DATASET_LINKS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  DatasetLinksVariancePopulationUpdatedByEmailAsc = "DATASET_LINKS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  DatasetLinksVariancePopulationUpdatedByEmailDesc = "DATASET_LINKS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  DatasetLinksVarianceSampleAllowStatisticsSharingAsc = "DATASET_LINKS_VARIANCE_SAMPLE_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksVarianceSampleAllowStatisticsSharingDesc = "DATASET_LINKS_VARIANCE_SAMPLE_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksVarianceSampleComputeNodeIdAsc = "DATASET_LINKS_VARIANCE_SAMPLE_COMPUTE_NODE_ID_ASC",
  DatasetLinksVarianceSampleComputeNodeIdDesc = "DATASET_LINKS_VARIANCE_SAMPLE_COMPUTE_NODE_ID_DESC",
  DatasetLinksVarianceSampleCreatedAtAsc = "DATASET_LINKS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  DatasetLinksVarianceSampleCreatedAtDesc = "DATASET_LINKS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  DatasetLinksVarianceSampleCreatedByEmailAsc = "DATASET_LINKS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  DatasetLinksVarianceSampleCreatedByEmailDesc = "DATASET_LINKS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  DatasetLinksVarianceSampleDatasetHashAsc = "DATASET_LINKS_VARIANCE_SAMPLE_DATASET_HASH_ASC",
  DatasetLinksVarianceSampleDatasetHashDesc = "DATASET_LINKS_VARIANCE_SAMPLE_DATASET_HASH_DESC",
  DatasetLinksVarianceSampleDatasetLinkIdAsc = "DATASET_LINKS_VARIANCE_SAMPLE_DATASET_LINK_ID_ASC",
  DatasetLinksVarianceSampleDatasetLinkIdDesc = "DATASET_LINKS_VARIANCE_SAMPLE_DATASET_LINK_ID_DESC",
  DatasetLinksVarianceSampleUpdatedAtAsc = "DATASET_LINKS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  DatasetLinksVarianceSampleUpdatedAtDesc = "DATASET_LINKS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  DatasetLinksVarianceSampleUpdatedByEmailAsc = "DATASET_LINKS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  DatasetLinksVarianceSampleUpdatedByEmailDesc = "DATASET_LINKS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  DatasetStatByDatasetHashCreatedAtAsc = "DATASET_STAT_BY_DATASET_HASH_CREATED_AT_ASC",
  DatasetStatByDatasetHashCreatedAtDesc = "DATASET_STAT_BY_DATASET_HASH_CREATED_AT_DESC",
  DatasetStatByDatasetHashCreatedByEmailAsc = "DATASET_STAT_BY_DATASET_HASH_CREATED_BY_EMAIL_ASC",
  DatasetStatByDatasetHashCreatedByEmailDesc = "DATASET_STAT_BY_DATASET_HASH_CREATED_BY_EMAIL_DESC",
  DatasetStatByDatasetHashDatasetHashAsc = "DATASET_STAT_BY_DATASET_HASH_DATASET_HASH_ASC",
  DatasetStatByDatasetHashDatasetHashDesc = "DATASET_STAT_BY_DATASET_HASH_DATASET_HASH_DESC",
  DatasetStatByDatasetHashFileSizeAsc = "DATASET_STAT_BY_DATASET_HASH_FILE_SIZE_ASC",
  DatasetStatByDatasetHashFileSizeDesc = "DATASET_STAT_BY_DATASET_HASH_FILE_SIZE_DESC",
  DatasetStatByDatasetHashFillRatioAsc = "DATASET_STAT_BY_DATASET_HASH_FILL_RATIO_ASC",
  DatasetStatByDatasetHashFillRatioDesc = "DATASET_STAT_BY_DATASET_HASH_FILL_RATIO_DESC",
  DatasetStatByDatasetHashNumberOfDuplicateRowsAsc = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_DUPLICATE_ROWS_ASC",
  DatasetStatByDatasetHashNumberOfDuplicateRowsDesc = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_DUPLICATE_ROWS_DESC",
  DatasetStatByDatasetHashNumberOfNotNullableRowsAsc = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_NOT_NULLABLE_ROWS_ASC",
  DatasetStatByDatasetHashNumberOfNotNullableRowsDesc = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_NOT_NULLABLE_ROWS_DESC",
  DatasetStatByDatasetHashNumberOfRowsAsc = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_ROWS_ASC",
  DatasetStatByDatasetHashNumberOfRowsDesc = "DATASET_STAT_BY_DATASET_HASH_NUMBER_OF_ROWS_DESC",
  DatasetStatByDatasetHashOwnerEmailAsc = "DATASET_STAT_BY_DATASET_HASH_OWNER_EMAIL_ASC",
  DatasetStatByDatasetHashOwnerEmailDesc = "DATASET_STAT_BY_DATASET_HASH_OWNER_EMAIL_DESC",
  DatasetStatByDatasetHashStatisticsAsc = "DATASET_STAT_BY_DATASET_HASH_STATISTICS_ASC",
  DatasetStatByDatasetHashStatisticsDesc = "DATASET_STAT_BY_DATASET_HASH_STATISTICS_DESC",
  DatasetStatByDatasetHashStatisticsVersionAsc = "DATASET_STAT_BY_DATASET_HASH_STATISTICS_VERSION_ASC",
  DatasetStatByDatasetHashStatisticsVersionDesc = "DATASET_STAT_BY_DATASET_HASH_STATISTICS_VERSION_DESC",
  DatasetStatByDatasetHashUpdatedAtAsc = "DATASET_STAT_BY_DATASET_HASH_UPDATED_AT_ASC",
  DatasetStatByDatasetHashUpdatedAtDesc = "DATASET_STAT_BY_DATASET_HASH_UPDATED_AT_DESC",
  DatasetStatByDatasetHashUpdatedByEmailAsc = "DATASET_STAT_BY_DATASET_HASH_UPDATED_BY_EMAIL_ASC",
  DatasetStatByDatasetHashUpdatedByEmailDesc = "DATASET_STAT_BY_DATASET_HASH_UPDATED_BY_EMAIL_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OwnerEmailAsc = "OWNER_EMAIL_ASC",
  OwnerEmailDesc = "OWNER_EMAIL_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

export type DatasetPublication = {
  __typename?: "DatasetPublication";
  dataRoom: PublishedDataRoom;
  leaf: PublishedTableLeafNode;
};

export type DatasetStat = Node & {
  __typename?: "DatasetStat";
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** Reads a single `Dataset` that is related to this `DatasetStat`. */
  dataset?: Maybe<Dataset>;
  datasetHash: Scalars["String"];
  /** Reads and enables pagination through a set of `DatasetLink`. */
  datasetLinks: DatasetLinksConnection;
  /** Reads a single `DatasetMeta` that is related to this `DatasetStat`. */
  datasetMeta?: Maybe<DatasetMeta>;
  fileSize: Scalars["Int"];
  fillRatio: Scalars["Float"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  numberOfDuplicateRows: Scalars["Int"];
  numberOfNotNullableRows: Scalars["Int"];
  numberOfRows: Scalars["Int"];
  ownerEmail: Scalars["String"];
  statistics?: Maybe<Scalars["JSON"]>;
  statisticsVersion: Scalars["Int"];
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type DatasetStatDatasetLinksArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DatasetLinkCondition>;
  filter?: InputMaybe<DatasetLinkFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DatasetLinksOrderBy>>;
};

export type DatasetStatAggregates = {
  __typename?: "DatasetStatAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<DatasetStatAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DatasetStatDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<DatasetStatMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<DatasetStatMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<DatasetStatStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<DatasetStatStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<DatasetStatSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<DatasetStatVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<DatasetStatVarianceSampleAggregates>;
};

export type DatasetStatAverageAggregates = {
  __typename?: "DatasetStatAverageAggregates";
  /** Mean average of fileSize across the matching connection */
  fileSize?: Maybe<Scalars["BigFloat"]>;
  /** Mean average of fillRatio across the matching connection */
  fillRatio?: Maybe<Scalars["Float"]>;
  /** Mean average of numberOfDuplicateRows across the matching connection */
  numberOfDuplicateRows?: Maybe<Scalars["BigFloat"]>;
  /** Mean average of numberOfNotNullableRows across the matching connection */
  numberOfNotNullableRows?: Maybe<Scalars["BigFloat"]>;
  /** Mean average of numberOfRows across the matching connection */
  numberOfRows?: Maybe<Scalars["BigFloat"]>;
  /** Mean average of statisticsVersion across the matching connection */
  statisticsVersion?: Maybe<Scalars["BigFloat"]>;
};

/**
 * A condition to be used against `DatasetStat` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type DatasetStatCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `datasetHash` field. */
  datasetHash?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `fileSize` field. */
  fileSize?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `fillRatio` field. */
  fillRatio?: InputMaybe<Scalars["Float"]>;
  /** Checks for equality with the object’s `numberOfDuplicateRows` field. */
  numberOfDuplicateRows?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `numberOfNotNullableRows` field. */
  numberOfNotNullableRows?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `numberOfRows` field. */
  numberOfRows?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `ownerEmail` field. */
  ownerEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `statistics` field. */
  statistics?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `statisticsVersion` field. */
  statisticsVersion?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The fields on `datasetStat` to look up the row to connect. */
export type DatasetStatDatasetStatsPkeyConnect = {
  datasetHash: Scalars["String"];
};

/** The fields on `datasetStat` to look up the row to delete. */
export type DatasetStatDatasetStatsPkeyDelete = {
  datasetHash: Scalars["String"];
};

export type DatasetStatDistinct = {
  __typename?: "DatasetStatDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `datasetHash` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  datasetHash: Array<Scalars["String"]>;
  /** Distinct `fileSize` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  fileSize: Array<Scalars["Int"]>;
  /** Distinct `fillRatio` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  fillRatio: Array<Scalars["Float"]>;
  /** Distinct `numberOfDuplicateRows` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  numberOfDuplicateRows: Array<Scalars["Int"]>;
  /** Distinct `numberOfNotNullableRows` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  numberOfNotNullableRows: Array<Scalars["Int"]>;
  /** Distinct `numberOfRows` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  numberOfRows: Array<Scalars["Int"]>;
  /** Distinct `ownerEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  ownerEmail: Array<Scalars["String"]>;
  /** Distinct `statistics` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  statistics: Array<Maybe<Scalars["JSON"]>>;
  /** Distinct `statisticsVersion` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  statisticsVersion: Array<Scalars["Int"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type DatasetStatDistinctCountAggregates = {
  __typename?: "DatasetStatDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of datasetHash across the matching connection */
  datasetHash?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of fileSize across the matching connection */
  fileSize?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of fillRatio across the matching connection */
  fillRatio?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of numberOfDuplicateRows across the matching connection */
  numberOfDuplicateRows?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of numberOfNotNullableRows across the matching connection */
  numberOfNotNullableRows?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of numberOfRows across the matching connection */
  numberOfRows?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of ownerEmail across the matching connection */
  ownerEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of statistics across the matching connection */
  statistics?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of statisticsVersion across the matching connection */
  statisticsVersion?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `DatasetStat` object types. All fields are combined with a logical ‘and.’ */
export type DatasetStatFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DatasetStatFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `datasetHash` field. */
  datasetHash?: InputMaybe<StringFilter>;
  /** Filter by the object’s `datasetLinks` relation. */
  datasetLinks?: InputMaybe<DatasetStatToManyDatasetLinkFilter>;
  /** Some related `datasetLinks` exist. */
  datasetLinksExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `datasetMeta` relation. */
  datasetMeta?: InputMaybe<DatasetMetaFilter>;
  /** Filter by the object’s `fileSize` field. */
  fileSize?: InputMaybe<IntFilter>;
  /** Filter by the object’s `fillRatio` field. */
  fillRatio?: InputMaybe<FloatFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DatasetStatFilter>;
  /** Filter by the object’s `numberOfDuplicateRows` field. */
  numberOfDuplicateRows?: InputMaybe<IntFilter>;
  /** Filter by the object’s `numberOfNotNullableRows` field. */
  numberOfNotNullableRows?: InputMaybe<IntFilter>;
  /** Filter by the object’s `numberOfRows` field. */
  numberOfRows?: InputMaybe<IntFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DatasetStatFilter>>;
  /** Filter by the object’s `ownerEmail` field. */
  ownerEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `statisticsVersion` field. */
  statisticsVersion?: InputMaybe<IntFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `DatasetStat` */
export type DatasetStatInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  datasetHash?: InputMaybe<Scalars["String"]>;
  datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey2InverseInput>;
  datasetMeta?: InputMaybe<FakeDecentriqPlatformDatasetStatsForeignKey0Input>;
  fileSize?: InputMaybe<Scalars["Int"]>;
  fillRatio?: InputMaybe<Scalars["Float"]>;
  numberOfDuplicateRows?: InputMaybe<Scalars["Int"]>;
  numberOfNotNullableRows?: InputMaybe<Scalars["Int"]>;
  numberOfRows?: InputMaybe<Scalars["Int"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  statistics?: InputMaybe<Scalars["JSON"]>;
  statisticsVersion?: InputMaybe<Scalars["Int"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type DatasetStatMaxAggregates = {
  __typename?: "DatasetStatMaxAggregates";
  /** Maximum of fileSize across the matching connection */
  fileSize?: Maybe<Scalars["Int"]>;
  /** Maximum of fillRatio across the matching connection */
  fillRatio?: Maybe<Scalars["Float"]>;
  /** Maximum of numberOfDuplicateRows across the matching connection */
  numberOfDuplicateRows?: Maybe<Scalars["Int"]>;
  /** Maximum of numberOfNotNullableRows across the matching connection */
  numberOfNotNullableRows?: Maybe<Scalars["Int"]>;
  /** Maximum of numberOfRows across the matching connection */
  numberOfRows?: Maybe<Scalars["Int"]>;
  /** Maximum of statisticsVersion across the matching connection */
  statisticsVersion?: Maybe<Scalars["Int"]>;
};

export type DatasetStatMinAggregates = {
  __typename?: "DatasetStatMinAggregates";
  /** Minimum of fileSize across the matching connection */
  fileSize?: Maybe<Scalars["Int"]>;
  /** Minimum of fillRatio across the matching connection */
  fillRatio?: Maybe<Scalars["Float"]>;
  /** Minimum of numberOfDuplicateRows across the matching connection */
  numberOfDuplicateRows?: Maybe<Scalars["Int"]>;
  /** Minimum of numberOfNotNullableRows across the matching connection */
  numberOfNotNullableRows?: Maybe<Scalars["Int"]>;
  /** Minimum of numberOfRows across the matching connection */
  numberOfRows?: Maybe<Scalars["Int"]>;
  /** Minimum of statisticsVersion across the matching connection */
  statisticsVersion?: Maybe<Scalars["Int"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DatasetStatNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `datasetStat` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type DatasetStatNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `datasetStat` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type DatasetStatOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey2NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `datasetLink` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `datasetLink` being updated. */
    patch: DatasetLinkPatch;
  };

/** The fields on `datasetStat` to look up the row to update. */
export type DatasetStatOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey2UsingDatasetStatsPkeyUpdate =
  {
    datasetHash: Scalars["String"];
    /** An object where the defined keys will be set on the `datasetStat` being updated. */
    patch: UpdateDatasetStatOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey2Patch;
  };

/** The globally unique `ID` look up for the row to update. */
export type DatasetStatOnDatasetStatForFakeDecentriqPlatformDatasetStatsForeignKey0NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `datasetMeta` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `datasetMeta` being updated. */
    patch: DatasetMetaPatch;
  };

/** The fields on `datasetStat` to look up the row to update. */
export type DatasetStatOnDatasetStatForFakeDecentriqPlatformDatasetStatsForeignKey0UsingDatasetStatsPkeyUpdate =
  {
    datasetHash: Scalars["String"];
    /** An object where the defined keys will be set on the `datasetStat` being updated. */
    patch: UpdateDatasetStatOnDatasetStatForFakeDecentriqPlatformDatasetStatsForeignKey0Patch;
  };

/** Represents an update to a `DatasetStat`. Fields that are set will be updated. */
export type DatasetStatPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  datasetHash?: InputMaybe<Scalars["String"]>;
  datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey2InverseInput>;
  datasetMeta?: InputMaybe<FakeDecentriqPlatformDatasetStatsForeignKey0Input>;
  fileSize?: InputMaybe<Scalars["Int"]>;
  fillRatio?: InputMaybe<Scalars["Float"]>;
  numberOfDuplicateRows?: InputMaybe<Scalars["Int"]>;
  numberOfNotNullableRows?: InputMaybe<Scalars["Int"]>;
  numberOfRows?: InputMaybe<Scalars["Int"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  statistics?: InputMaybe<Scalars["JSON"]>;
  statisticsVersion?: InputMaybe<Scalars["Int"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type DatasetStatStddevPopulationAggregates = {
  __typename?: "DatasetStatStddevPopulationAggregates";
  /** Population standard deviation of fileSize across the matching connection */
  fileSize?: Maybe<Scalars["BigFloat"]>;
  /** Population standard deviation of fillRatio across the matching connection */
  fillRatio?: Maybe<Scalars["Float"]>;
  /** Population standard deviation of numberOfDuplicateRows across the matching connection */
  numberOfDuplicateRows?: Maybe<Scalars["BigFloat"]>;
  /** Population standard deviation of numberOfNotNullableRows across the matching connection */
  numberOfNotNullableRows?: Maybe<Scalars["BigFloat"]>;
  /** Population standard deviation of numberOfRows across the matching connection */
  numberOfRows?: Maybe<Scalars["BigFloat"]>;
  /** Population standard deviation of statisticsVersion across the matching connection */
  statisticsVersion?: Maybe<Scalars["BigFloat"]>;
};

export type DatasetStatStddevSampleAggregates = {
  __typename?: "DatasetStatStddevSampleAggregates";
  /** Sample standard deviation of fileSize across the matching connection */
  fileSize?: Maybe<Scalars["BigFloat"]>;
  /** Sample standard deviation of fillRatio across the matching connection */
  fillRatio?: Maybe<Scalars["Float"]>;
  /** Sample standard deviation of numberOfDuplicateRows across the matching connection */
  numberOfDuplicateRows?: Maybe<Scalars["BigFloat"]>;
  /** Sample standard deviation of numberOfNotNullableRows across the matching connection */
  numberOfNotNullableRows?: Maybe<Scalars["BigFloat"]>;
  /** Sample standard deviation of numberOfRows across the matching connection */
  numberOfRows?: Maybe<Scalars["BigFloat"]>;
  /** Sample standard deviation of statisticsVersion across the matching connection */
  statisticsVersion?: Maybe<Scalars["BigFloat"]>;
};

export type DatasetStatSumAggregates = {
  __typename?: "DatasetStatSumAggregates";
  /** Sum of fileSize across the matching connection */
  fileSize: Scalars["BigInt"];
  /** Sum of fillRatio across the matching connection */
  fillRatio: Scalars["Float"];
  /** Sum of numberOfDuplicateRows across the matching connection */
  numberOfDuplicateRows: Scalars["BigInt"];
  /** Sum of numberOfNotNullableRows across the matching connection */
  numberOfNotNullableRows: Scalars["BigInt"];
  /** Sum of numberOfRows across the matching connection */
  numberOfRows: Scalars["BigInt"];
  /** Sum of statisticsVersion across the matching connection */
  statisticsVersion: Scalars["BigInt"];
};

/** A filter to be used against many `DatasetLink` object types. All fields are combined with a logical ‘and.’ */
export type DatasetStatToManyDatasetLinkFilter = {
  /** Aggregates across related `DatasetLink` match the filter criteria. */
  aggregates?: InputMaybe<DatasetLinkAggregatesFilter>;
  /** Every related `DatasetLink` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DatasetLinkFilter>;
  /** No related `DatasetLink` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DatasetLinkFilter>;
  /** Some related `DatasetLink` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DatasetLinkFilter>;
};

export type DatasetStatVariancePopulationAggregates = {
  __typename?: "DatasetStatVariancePopulationAggregates";
  /** Population variance of fileSize across the matching connection */
  fileSize?: Maybe<Scalars["BigFloat"]>;
  /** Population variance of fillRatio across the matching connection */
  fillRatio?: Maybe<Scalars["Float"]>;
  /** Population variance of numberOfDuplicateRows across the matching connection */
  numberOfDuplicateRows?: Maybe<Scalars["BigFloat"]>;
  /** Population variance of numberOfNotNullableRows across the matching connection */
  numberOfNotNullableRows?: Maybe<Scalars["BigFloat"]>;
  /** Population variance of numberOfRows across the matching connection */
  numberOfRows?: Maybe<Scalars["BigFloat"]>;
  /** Population variance of statisticsVersion across the matching connection */
  statisticsVersion?: Maybe<Scalars["BigFloat"]>;
};

export type DatasetStatVarianceSampleAggregates = {
  __typename?: "DatasetStatVarianceSampleAggregates";
  /** Sample variance of fileSize across the matching connection */
  fileSize?: Maybe<Scalars["BigFloat"]>;
  /** Sample variance of fillRatio across the matching connection */
  fillRatio?: Maybe<Scalars["Float"]>;
  /** Sample variance of numberOfDuplicateRows across the matching connection */
  numberOfDuplicateRows?: Maybe<Scalars["BigFloat"]>;
  /** Sample variance of numberOfNotNullableRows across the matching connection */
  numberOfNotNullableRows?: Maybe<Scalars["BigFloat"]>;
  /** Sample variance of numberOfRows across the matching connection */
  numberOfRows?: Maybe<Scalars["BigFloat"]>;
  /** Sample variance of statisticsVersion across the matching connection */
  statisticsVersion?: Maybe<Scalars["BigFloat"]>;
};

/** A connection to a list of `DatasetStat` values. */
export type DatasetStatsConnection = {
  __typename?: "DatasetStatsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DatasetStatAggregates>;
  /** A list of _all_ `DatasetStat` primary keys. */
  datasetHashes?: Maybe<Array<Scalars["String"]>>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<DatasetStatDistinct>;
  /** A list of edges which contains the `DatasetStat` and cursor to aid in pagination. */
  edges: Array<DatasetStatsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DatasetStatAggregates>>;
  /** A list of `DatasetStat` objects. */
  nodes: Array<Maybe<DatasetStat>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `DatasetStat` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `DatasetStat` values. */
export type DatasetStatsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DatasetStatsGroupBy>;
  having?: InputMaybe<DatasetStatsHavingInput>;
};

/** A `DatasetStat` edge in the connection. */
export type DatasetStatsEdge = {
  __typename?: "DatasetStatsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `DatasetStat` at the end of the edge. */
  node?: Maybe<DatasetStat>;
};

/** Grouping methods for `DatasetStat` for usage during aggregation. */
export enum DatasetStatsGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  DatasetMetaByDatasetHashCreatedAt = "DATASET_META_BY_DATASET_HASH_CREATED_AT",
  DatasetMetaByDatasetHashCreatedByEmail = "DATASET_META_BY_DATASET_HASH_CREATED_BY_EMAIL",
  DatasetMetaByDatasetHashDatasetHash = "DATASET_META_BY_DATASET_HASH_DATASET_HASH",
  DatasetMetaByDatasetHashDescription = "DATASET_META_BY_DATASET_HASH_DESCRIPTION",
  DatasetMetaByDatasetHashName = "DATASET_META_BY_DATASET_HASH_NAME",
  DatasetMetaByDatasetHashOwnerEmail = "DATASET_META_BY_DATASET_HASH_OWNER_EMAIL",
  DatasetMetaByDatasetHashUpdatedAt = "DATASET_META_BY_DATASET_HASH_UPDATED_AT",
  DatasetMetaByDatasetHashUpdatedByEmail = "DATASET_META_BY_DATASET_HASH_UPDATED_BY_EMAIL",
  FileSize = "FILE_SIZE",
  FillRatio = "FILL_RATIO",
  NumberOfDuplicateRows = "NUMBER_OF_DUPLICATE_ROWS",
  NumberOfNotNullableRows = "NUMBER_OF_NOT_NULLABLE_ROWS",
  NumberOfRows = "NUMBER_OF_ROWS",
  OwnerEmail = "OWNER_EMAIL",
  Statistics = "STATISTICS",
  StatisticsVersion = "STATISTICS_VERSION",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type DatasetStatsHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fileSize?: InputMaybe<HavingIntFilter>;
  fillRatio?: InputMaybe<HavingFloatFilter>;
  numberOfDuplicateRows?: InputMaybe<HavingIntFilter>;
  numberOfNotNullableRows?: InputMaybe<HavingIntFilter>;
  numberOfRows?: InputMaybe<HavingIntFilter>;
  statisticsVersion?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetStatsHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fileSize?: InputMaybe<HavingIntFilter>;
  fillRatio?: InputMaybe<HavingFloatFilter>;
  numberOfDuplicateRows?: InputMaybe<HavingIntFilter>;
  numberOfNotNullableRows?: InputMaybe<HavingIntFilter>;
  numberOfRows?: InputMaybe<HavingIntFilter>;
  statisticsVersion?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `DatasetStat` aggregates. */
export type DatasetStatsHavingInput = {
  AND?: InputMaybe<Array<DatasetStatsHavingInput>>;
  OR?: InputMaybe<Array<DatasetStatsHavingInput>>;
  average?: InputMaybe<DatasetStatsHavingAverageInput>;
  distinctCount?: InputMaybe<DatasetStatsHavingDistinctCountInput>;
  max?: InputMaybe<DatasetStatsHavingMaxInput>;
  min?: InputMaybe<DatasetStatsHavingMinInput>;
  stddevPopulation?: InputMaybe<DatasetStatsHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DatasetStatsHavingStddevSampleInput>;
  sum?: InputMaybe<DatasetStatsHavingSumInput>;
  variancePopulation?: InputMaybe<DatasetStatsHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DatasetStatsHavingVarianceSampleInput>;
};

export type DatasetStatsHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fileSize?: InputMaybe<HavingIntFilter>;
  fillRatio?: InputMaybe<HavingFloatFilter>;
  numberOfDuplicateRows?: InputMaybe<HavingIntFilter>;
  numberOfNotNullableRows?: InputMaybe<HavingIntFilter>;
  numberOfRows?: InputMaybe<HavingIntFilter>;
  statisticsVersion?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetStatsHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fileSize?: InputMaybe<HavingIntFilter>;
  fillRatio?: InputMaybe<HavingFloatFilter>;
  numberOfDuplicateRows?: InputMaybe<HavingIntFilter>;
  numberOfNotNullableRows?: InputMaybe<HavingIntFilter>;
  numberOfRows?: InputMaybe<HavingIntFilter>;
  statisticsVersion?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetStatsHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fileSize?: InputMaybe<HavingIntFilter>;
  fillRatio?: InputMaybe<HavingFloatFilter>;
  numberOfDuplicateRows?: InputMaybe<HavingIntFilter>;
  numberOfNotNullableRows?: InputMaybe<HavingIntFilter>;
  numberOfRows?: InputMaybe<HavingIntFilter>;
  statisticsVersion?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetStatsHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fileSize?: InputMaybe<HavingIntFilter>;
  fillRatio?: InputMaybe<HavingFloatFilter>;
  numberOfDuplicateRows?: InputMaybe<HavingIntFilter>;
  numberOfNotNullableRows?: InputMaybe<HavingIntFilter>;
  numberOfRows?: InputMaybe<HavingIntFilter>;
  statisticsVersion?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetStatsHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fileSize?: InputMaybe<HavingIntFilter>;
  fillRatio?: InputMaybe<HavingFloatFilter>;
  numberOfDuplicateRows?: InputMaybe<HavingIntFilter>;
  numberOfNotNullableRows?: InputMaybe<HavingIntFilter>;
  numberOfRows?: InputMaybe<HavingIntFilter>;
  statisticsVersion?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetStatsHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fileSize?: InputMaybe<HavingIntFilter>;
  fillRatio?: InputMaybe<HavingFloatFilter>;
  numberOfDuplicateRows?: InputMaybe<HavingIntFilter>;
  numberOfNotNullableRows?: InputMaybe<HavingIntFilter>;
  numberOfRows?: InputMaybe<HavingIntFilter>;
  statisticsVersion?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DatasetStatsHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  fileSize?: InputMaybe<HavingIntFilter>;
  fillRatio?: InputMaybe<HavingFloatFilter>;
  numberOfDuplicateRows?: InputMaybe<HavingIntFilter>;
  numberOfNotNullableRows?: InputMaybe<HavingIntFilter>;
  numberOfRows?: InputMaybe<HavingIntFilter>;
  statisticsVersion?: InputMaybe<HavingIntFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `DatasetStat`. */
export enum DatasetStatsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  DatasetHashAsc = "DATASET_HASH_ASC",
  DatasetHashDesc = "DATASET_HASH_DESC",
  DatasetLinksAverageAllowStatisticsSharingAsc = "DATASET_LINKS_AVERAGE_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksAverageAllowStatisticsSharingDesc = "DATASET_LINKS_AVERAGE_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksAverageComputeNodeIdAsc = "DATASET_LINKS_AVERAGE_COMPUTE_NODE_ID_ASC",
  DatasetLinksAverageComputeNodeIdDesc = "DATASET_LINKS_AVERAGE_COMPUTE_NODE_ID_DESC",
  DatasetLinksAverageCreatedAtAsc = "DATASET_LINKS_AVERAGE_CREATED_AT_ASC",
  DatasetLinksAverageCreatedAtDesc = "DATASET_LINKS_AVERAGE_CREATED_AT_DESC",
  DatasetLinksAverageCreatedByEmailAsc = "DATASET_LINKS_AVERAGE_CREATED_BY_EMAIL_ASC",
  DatasetLinksAverageCreatedByEmailDesc = "DATASET_LINKS_AVERAGE_CREATED_BY_EMAIL_DESC",
  DatasetLinksAverageDatasetHashAsc = "DATASET_LINKS_AVERAGE_DATASET_HASH_ASC",
  DatasetLinksAverageDatasetHashDesc = "DATASET_LINKS_AVERAGE_DATASET_HASH_DESC",
  DatasetLinksAverageDatasetLinkIdAsc = "DATASET_LINKS_AVERAGE_DATASET_LINK_ID_ASC",
  DatasetLinksAverageDatasetLinkIdDesc = "DATASET_LINKS_AVERAGE_DATASET_LINK_ID_DESC",
  DatasetLinksAverageUpdatedAtAsc = "DATASET_LINKS_AVERAGE_UPDATED_AT_ASC",
  DatasetLinksAverageUpdatedAtDesc = "DATASET_LINKS_AVERAGE_UPDATED_AT_DESC",
  DatasetLinksAverageUpdatedByEmailAsc = "DATASET_LINKS_AVERAGE_UPDATED_BY_EMAIL_ASC",
  DatasetLinksAverageUpdatedByEmailDesc = "DATASET_LINKS_AVERAGE_UPDATED_BY_EMAIL_DESC",
  DatasetLinksByDatasetHashCountAsc = "DATASET_LINKS_BY_DATASET_HASH_COUNT_ASC",
  DatasetLinksByDatasetHashCountDesc = "DATASET_LINKS_BY_DATASET_HASH_COUNT_DESC",
  DatasetLinksByDatasetHashMaxAllowStatisticsSharingAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksByDatasetHashMaxAllowStatisticsSharingDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksByDatasetHashMaxComputeNodeIdAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_COMPUTE_NODE_ID_ASC",
  DatasetLinksByDatasetHashMaxComputeNodeIdDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_COMPUTE_NODE_ID_DESC",
  DatasetLinksByDatasetHashMaxCreatedAtAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_CREATED_AT_ASC",
  DatasetLinksByDatasetHashMaxCreatedAtDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_CREATED_AT_DESC",
  DatasetLinksByDatasetHashMaxCreatedByEmailAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_CREATED_BY_EMAIL_ASC",
  DatasetLinksByDatasetHashMaxCreatedByEmailDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_CREATED_BY_EMAIL_DESC",
  DatasetLinksByDatasetHashMaxDatasetHashAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_DATASET_HASH_ASC",
  DatasetLinksByDatasetHashMaxDatasetHashDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_DATASET_HASH_DESC",
  DatasetLinksByDatasetHashMaxDatasetLinkIdAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_DATASET_LINK_ID_ASC",
  DatasetLinksByDatasetHashMaxDatasetLinkIdDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_DATASET_LINK_ID_DESC",
  DatasetLinksByDatasetHashMaxUpdatedAtAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_UPDATED_AT_ASC",
  DatasetLinksByDatasetHashMaxUpdatedAtDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_UPDATED_AT_DESC",
  DatasetLinksByDatasetHashMaxUpdatedByEmailAsc = "DATASET_LINKS_BY_DATASET_HASH_MAX_UPDATED_BY_EMAIL_ASC",
  DatasetLinksByDatasetHashMaxUpdatedByEmailDesc = "DATASET_LINKS_BY_DATASET_HASH_MAX_UPDATED_BY_EMAIL_DESC",
  DatasetLinksByDatasetHashMinAllowStatisticsSharingAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksByDatasetHashMinAllowStatisticsSharingDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksByDatasetHashMinComputeNodeIdAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_COMPUTE_NODE_ID_ASC",
  DatasetLinksByDatasetHashMinComputeNodeIdDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_COMPUTE_NODE_ID_DESC",
  DatasetLinksByDatasetHashMinCreatedAtAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_CREATED_AT_ASC",
  DatasetLinksByDatasetHashMinCreatedAtDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_CREATED_AT_DESC",
  DatasetLinksByDatasetHashMinCreatedByEmailAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_CREATED_BY_EMAIL_ASC",
  DatasetLinksByDatasetHashMinCreatedByEmailDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_CREATED_BY_EMAIL_DESC",
  DatasetLinksByDatasetHashMinDatasetHashAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_DATASET_HASH_ASC",
  DatasetLinksByDatasetHashMinDatasetHashDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_DATASET_HASH_DESC",
  DatasetLinksByDatasetHashMinDatasetLinkIdAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_DATASET_LINK_ID_ASC",
  DatasetLinksByDatasetHashMinDatasetLinkIdDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_DATASET_LINK_ID_DESC",
  DatasetLinksByDatasetHashMinUpdatedAtAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_UPDATED_AT_ASC",
  DatasetLinksByDatasetHashMinUpdatedAtDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_UPDATED_AT_DESC",
  DatasetLinksByDatasetHashMinUpdatedByEmailAsc = "DATASET_LINKS_BY_DATASET_HASH_MIN_UPDATED_BY_EMAIL_ASC",
  DatasetLinksByDatasetHashMinUpdatedByEmailDesc = "DATASET_LINKS_BY_DATASET_HASH_MIN_UPDATED_BY_EMAIL_DESC",
  DatasetLinksCountAsc = "DATASET_LINKS_COUNT_ASC",
  DatasetLinksCountDesc = "DATASET_LINKS_COUNT_DESC",
  DatasetLinksDistinctCountAllowStatisticsSharingAsc = "DATASET_LINKS_DISTINCT_COUNT_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksDistinctCountAllowStatisticsSharingDesc = "DATASET_LINKS_DISTINCT_COUNT_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksDistinctCountComputeNodeIdAsc = "DATASET_LINKS_DISTINCT_COUNT_COMPUTE_NODE_ID_ASC",
  DatasetLinksDistinctCountComputeNodeIdDesc = "DATASET_LINKS_DISTINCT_COUNT_COMPUTE_NODE_ID_DESC",
  DatasetLinksDistinctCountCreatedAtAsc = "DATASET_LINKS_DISTINCT_COUNT_CREATED_AT_ASC",
  DatasetLinksDistinctCountCreatedAtDesc = "DATASET_LINKS_DISTINCT_COUNT_CREATED_AT_DESC",
  DatasetLinksDistinctCountCreatedByEmailAsc = "DATASET_LINKS_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  DatasetLinksDistinctCountCreatedByEmailDesc = "DATASET_LINKS_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  DatasetLinksDistinctCountDatasetHashAsc = "DATASET_LINKS_DISTINCT_COUNT_DATASET_HASH_ASC",
  DatasetLinksDistinctCountDatasetHashDesc = "DATASET_LINKS_DISTINCT_COUNT_DATASET_HASH_DESC",
  DatasetLinksDistinctCountDatasetLinkIdAsc = "DATASET_LINKS_DISTINCT_COUNT_DATASET_LINK_ID_ASC",
  DatasetLinksDistinctCountDatasetLinkIdDesc = "DATASET_LINKS_DISTINCT_COUNT_DATASET_LINK_ID_DESC",
  DatasetLinksDistinctCountUpdatedAtAsc = "DATASET_LINKS_DISTINCT_COUNT_UPDATED_AT_ASC",
  DatasetLinksDistinctCountUpdatedAtDesc = "DATASET_LINKS_DISTINCT_COUNT_UPDATED_AT_DESC",
  DatasetLinksDistinctCountUpdatedByEmailAsc = "DATASET_LINKS_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  DatasetLinksDistinctCountUpdatedByEmailDesc = "DATASET_LINKS_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  DatasetLinksMaxAllowStatisticsSharingAsc = "DATASET_LINKS_MAX_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksMaxAllowStatisticsSharingDesc = "DATASET_LINKS_MAX_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksMaxComputeNodeIdAsc = "DATASET_LINKS_MAX_COMPUTE_NODE_ID_ASC",
  DatasetLinksMaxComputeNodeIdDesc = "DATASET_LINKS_MAX_COMPUTE_NODE_ID_DESC",
  DatasetLinksMaxCreatedAtAsc = "DATASET_LINKS_MAX_CREATED_AT_ASC",
  DatasetLinksMaxCreatedAtDesc = "DATASET_LINKS_MAX_CREATED_AT_DESC",
  DatasetLinksMaxCreatedByEmailAsc = "DATASET_LINKS_MAX_CREATED_BY_EMAIL_ASC",
  DatasetLinksMaxCreatedByEmailDesc = "DATASET_LINKS_MAX_CREATED_BY_EMAIL_DESC",
  DatasetLinksMaxDatasetHashAsc = "DATASET_LINKS_MAX_DATASET_HASH_ASC",
  DatasetLinksMaxDatasetHashDesc = "DATASET_LINKS_MAX_DATASET_HASH_DESC",
  DatasetLinksMaxDatasetLinkIdAsc = "DATASET_LINKS_MAX_DATASET_LINK_ID_ASC",
  DatasetLinksMaxDatasetLinkIdDesc = "DATASET_LINKS_MAX_DATASET_LINK_ID_DESC",
  DatasetLinksMaxUpdatedAtAsc = "DATASET_LINKS_MAX_UPDATED_AT_ASC",
  DatasetLinksMaxUpdatedAtDesc = "DATASET_LINKS_MAX_UPDATED_AT_DESC",
  DatasetLinksMaxUpdatedByEmailAsc = "DATASET_LINKS_MAX_UPDATED_BY_EMAIL_ASC",
  DatasetLinksMaxUpdatedByEmailDesc = "DATASET_LINKS_MAX_UPDATED_BY_EMAIL_DESC",
  DatasetLinksMinAllowStatisticsSharingAsc = "DATASET_LINKS_MIN_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksMinAllowStatisticsSharingDesc = "DATASET_LINKS_MIN_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksMinComputeNodeIdAsc = "DATASET_LINKS_MIN_COMPUTE_NODE_ID_ASC",
  DatasetLinksMinComputeNodeIdDesc = "DATASET_LINKS_MIN_COMPUTE_NODE_ID_DESC",
  DatasetLinksMinCreatedAtAsc = "DATASET_LINKS_MIN_CREATED_AT_ASC",
  DatasetLinksMinCreatedAtDesc = "DATASET_LINKS_MIN_CREATED_AT_DESC",
  DatasetLinksMinCreatedByEmailAsc = "DATASET_LINKS_MIN_CREATED_BY_EMAIL_ASC",
  DatasetLinksMinCreatedByEmailDesc = "DATASET_LINKS_MIN_CREATED_BY_EMAIL_DESC",
  DatasetLinksMinDatasetHashAsc = "DATASET_LINKS_MIN_DATASET_HASH_ASC",
  DatasetLinksMinDatasetHashDesc = "DATASET_LINKS_MIN_DATASET_HASH_DESC",
  DatasetLinksMinDatasetLinkIdAsc = "DATASET_LINKS_MIN_DATASET_LINK_ID_ASC",
  DatasetLinksMinDatasetLinkIdDesc = "DATASET_LINKS_MIN_DATASET_LINK_ID_DESC",
  DatasetLinksMinUpdatedAtAsc = "DATASET_LINKS_MIN_UPDATED_AT_ASC",
  DatasetLinksMinUpdatedAtDesc = "DATASET_LINKS_MIN_UPDATED_AT_DESC",
  DatasetLinksMinUpdatedByEmailAsc = "DATASET_LINKS_MIN_UPDATED_BY_EMAIL_ASC",
  DatasetLinksMinUpdatedByEmailDesc = "DATASET_LINKS_MIN_UPDATED_BY_EMAIL_DESC",
  DatasetLinksStddevPopulationAllowStatisticsSharingAsc = "DATASET_LINKS_STDDEV_POPULATION_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksStddevPopulationAllowStatisticsSharingDesc = "DATASET_LINKS_STDDEV_POPULATION_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksStddevPopulationComputeNodeIdAsc = "DATASET_LINKS_STDDEV_POPULATION_COMPUTE_NODE_ID_ASC",
  DatasetLinksStddevPopulationComputeNodeIdDesc = "DATASET_LINKS_STDDEV_POPULATION_COMPUTE_NODE_ID_DESC",
  DatasetLinksStddevPopulationCreatedAtAsc = "DATASET_LINKS_STDDEV_POPULATION_CREATED_AT_ASC",
  DatasetLinksStddevPopulationCreatedAtDesc = "DATASET_LINKS_STDDEV_POPULATION_CREATED_AT_DESC",
  DatasetLinksStddevPopulationCreatedByEmailAsc = "DATASET_LINKS_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  DatasetLinksStddevPopulationCreatedByEmailDesc = "DATASET_LINKS_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  DatasetLinksStddevPopulationDatasetHashAsc = "DATASET_LINKS_STDDEV_POPULATION_DATASET_HASH_ASC",
  DatasetLinksStddevPopulationDatasetHashDesc = "DATASET_LINKS_STDDEV_POPULATION_DATASET_HASH_DESC",
  DatasetLinksStddevPopulationDatasetLinkIdAsc = "DATASET_LINKS_STDDEV_POPULATION_DATASET_LINK_ID_ASC",
  DatasetLinksStddevPopulationDatasetLinkIdDesc = "DATASET_LINKS_STDDEV_POPULATION_DATASET_LINK_ID_DESC",
  DatasetLinksStddevPopulationUpdatedAtAsc = "DATASET_LINKS_STDDEV_POPULATION_UPDATED_AT_ASC",
  DatasetLinksStddevPopulationUpdatedAtDesc = "DATASET_LINKS_STDDEV_POPULATION_UPDATED_AT_DESC",
  DatasetLinksStddevPopulationUpdatedByEmailAsc = "DATASET_LINKS_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  DatasetLinksStddevPopulationUpdatedByEmailDesc = "DATASET_LINKS_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  DatasetLinksStddevSampleAllowStatisticsSharingAsc = "DATASET_LINKS_STDDEV_SAMPLE_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksStddevSampleAllowStatisticsSharingDesc = "DATASET_LINKS_STDDEV_SAMPLE_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksStddevSampleComputeNodeIdAsc = "DATASET_LINKS_STDDEV_SAMPLE_COMPUTE_NODE_ID_ASC",
  DatasetLinksStddevSampleComputeNodeIdDesc = "DATASET_LINKS_STDDEV_SAMPLE_COMPUTE_NODE_ID_DESC",
  DatasetLinksStddevSampleCreatedAtAsc = "DATASET_LINKS_STDDEV_SAMPLE_CREATED_AT_ASC",
  DatasetLinksStddevSampleCreatedAtDesc = "DATASET_LINKS_STDDEV_SAMPLE_CREATED_AT_DESC",
  DatasetLinksStddevSampleCreatedByEmailAsc = "DATASET_LINKS_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  DatasetLinksStddevSampleCreatedByEmailDesc = "DATASET_LINKS_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  DatasetLinksStddevSampleDatasetHashAsc = "DATASET_LINKS_STDDEV_SAMPLE_DATASET_HASH_ASC",
  DatasetLinksStddevSampleDatasetHashDesc = "DATASET_LINKS_STDDEV_SAMPLE_DATASET_HASH_DESC",
  DatasetLinksStddevSampleDatasetLinkIdAsc = "DATASET_LINKS_STDDEV_SAMPLE_DATASET_LINK_ID_ASC",
  DatasetLinksStddevSampleDatasetLinkIdDesc = "DATASET_LINKS_STDDEV_SAMPLE_DATASET_LINK_ID_DESC",
  DatasetLinksStddevSampleUpdatedAtAsc = "DATASET_LINKS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  DatasetLinksStddevSampleUpdatedAtDesc = "DATASET_LINKS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  DatasetLinksStddevSampleUpdatedByEmailAsc = "DATASET_LINKS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  DatasetLinksStddevSampleUpdatedByEmailDesc = "DATASET_LINKS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  DatasetLinksSumAllowStatisticsSharingAsc = "DATASET_LINKS_SUM_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksSumAllowStatisticsSharingDesc = "DATASET_LINKS_SUM_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksSumComputeNodeIdAsc = "DATASET_LINKS_SUM_COMPUTE_NODE_ID_ASC",
  DatasetLinksSumComputeNodeIdDesc = "DATASET_LINKS_SUM_COMPUTE_NODE_ID_DESC",
  DatasetLinksSumCreatedAtAsc = "DATASET_LINKS_SUM_CREATED_AT_ASC",
  DatasetLinksSumCreatedAtDesc = "DATASET_LINKS_SUM_CREATED_AT_DESC",
  DatasetLinksSumCreatedByEmailAsc = "DATASET_LINKS_SUM_CREATED_BY_EMAIL_ASC",
  DatasetLinksSumCreatedByEmailDesc = "DATASET_LINKS_SUM_CREATED_BY_EMAIL_DESC",
  DatasetLinksSumDatasetHashAsc = "DATASET_LINKS_SUM_DATASET_HASH_ASC",
  DatasetLinksSumDatasetHashDesc = "DATASET_LINKS_SUM_DATASET_HASH_DESC",
  DatasetLinksSumDatasetLinkIdAsc = "DATASET_LINKS_SUM_DATASET_LINK_ID_ASC",
  DatasetLinksSumDatasetLinkIdDesc = "DATASET_LINKS_SUM_DATASET_LINK_ID_DESC",
  DatasetLinksSumUpdatedAtAsc = "DATASET_LINKS_SUM_UPDATED_AT_ASC",
  DatasetLinksSumUpdatedAtDesc = "DATASET_LINKS_SUM_UPDATED_AT_DESC",
  DatasetLinksSumUpdatedByEmailAsc = "DATASET_LINKS_SUM_UPDATED_BY_EMAIL_ASC",
  DatasetLinksSumUpdatedByEmailDesc = "DATASET_LINKS_SUM_UPDATED_BY_EMAIL_DESC",
  DatasetLinksVariancePopulationAllowStatisticsSharingAsc = "DATASET_LINKS_VARIANCE_POPULATION_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksVariancePopulationAllowStatisticsSharingDesc = "DATASET_LINKS_VARIANCE_POPULATION_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksVariancePopulationComputeNodeIdAsc = "DATASET_LINKS_VARIANCE_POPULATION_COMPUTE_NODE_ID_ASC",
  DatasetLinksVariancePopulationComputeNodeIdDesc = "DATASET_LINKS_VARIANCE_POPULATION_COMPUTE_NODE_ID_DESC",
  DatasetLinksVariancePopulationCreatedAtAsc = "DATASET_LINKS_VARIANCE_POPULATION_CREATED_AT_ASC",
  DatasetLinksVariancePopulationCreatedAtDesc = "DATASET_LINKS_VARIANCE_POPULATION_CREATED_AT_DESC",
  DatasetLinksVariancePopulationCreatedByEmailAsc = "DATASET_LINKS_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  DatasetLinksVariancePopulationCreatedByEmailDesc = "DATASET_LINKS_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  DatasetLinksVariancePopulationDatasetHashAsc = "DATASET_LINKS_VARIANCE_POPULATION_DATASET_HASH_ASC",
  DatasetLinksVariancePopulationDatasetHashDesc = "DATASET_LINKS_VARIANCE_POPULATION_DATASET_HASH_DESC",
  DatasetLinksVariancePopulationDatasetLinkIdAsc = "DATASET_LINKS_VARIANCE_POPULATION_DATASET_LINK_ID_ASC",
  DatasetLinksVariancePopulationDatasetLinkIdDesc = "DATASET_LINKS_VARIANCE_POPULATION_DATASET_LINK_ID_DESC",
  DatasetLinksVariancePopulationUpdatedAtAsc = "DATASET_LINKS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  DatasetLinksVariancePopulationUpdatedAtDesc = "DATASET_LINKS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  DatasetLinksVariancePopulationUpdatedByEmailAsc = "DATASET_LINKS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  DatasetLinksVariancePopulationUpdatedByEmailDesc = "DATASET_LINKS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  DatasetLinksVarianceSampleAllowStatisticsSharingAsc = "DATASET_LINKS_VARIANCE_SAMPLE_ALLOW_STATISTICS_SHARING_ASC",
  DatasetLinksVarianceSampleAllowStatisticsSharingDesc = "DATASET_LINKS_VARIANCE_SAMPLE_ALLOW_STATISTICS_SHARING_DESC",
  DatasetLinksVarianceSampleComputeNodeIdAsc = "DATASET_LINKS_VARIANCE_SAMPLE_COMPUTE_NODE_ID_ASC",
  DatasetLinksVarianceSampleComputeNodeIdDesc = "DATASET_LINKS_VARIANCE_SAMPLE_COMPUTE_NODE_ID_DESC",
  DatasetLinksVarianceSampleCreatedAtAsc = "DATASET_LINKS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  DatasetLinksVarianceSampleCreatedAtDesc = "DATASET_LINKS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  DatasetLinksVarianceSampleCreatedByEmailAsc = "DATASET_LINKS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  DatasetLinksVarianceSampleCreatedByEmailDesc = "DATASET_LINKS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  DatasetLinksVarianceSampleDatasetHashAsc = "DATASET_LINKS_VARIANCE_SAMPLE_DATASET_HASH_ASC",
  DatasetLinksVarianceSampleDatasetHashDesc = "DATASET_LINKS_VARIANCE_SAMPLE_DATASET_HASH_DESC",
  DatasetLinksVarianceSampleDatasetLinkIdAsc = "DATASET_LINKS_VARIANCE_SAMPLE_DATASET_LINK_ID_ASC",
  DatasetLinksVarianceSampleDatasetLinkIdDesc = "DATASET_LINKS_VARIANCE_SAMPLE_DATASET_LINK_ID_DESC",
  DatasetLinksVarianceSampleUpdatedAtAsc = "DATASET_LINKS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  DatasetLinksVarianceSampleUpdatedAtDesc = "DATASET_LINKS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  DatasetLinksVarianceSampleUpdatedByEmailAsc = "DATASET_LINKS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  DatasetLinksVarianceSampleUpdatedByEmailDesc = "DATASET_LINKS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  DatasetMetaByDatasetHashCreatedAtAsc = "DATASET_META_BY_DATASET_HASH_CREATED_AT_ASC",
  DatasetMetaByDatasetHashCreatedAtDesc = "DATASET_META_BY_DATASET_HASH_CREATED_AT_DESC",
  DatasetMetaByDatasetHashCreatedByEmailAsc = "DATASET_META_BY_DATASET_HASH_CREATED_BY_EMAIL_ASC",
  DatasetMetaByDatasetHashCreatedByEmailDesc = "DATASET_META_BY_DATASET_HASH_CREATED_BY_EMAIL_DESC",
  DatasetMetaByDatasetHashDatasetHashAsc = "DATASET_META_BY_DATASET_HASH_DATASET_HASH_ASC",
  DatasetMetaByDatasetHashDatasetHashDesc = "DATASET_META_BY_DATASET_HASH_DATASET_HASH_DESC",
  DatasetMetaByDatasetHashDescriptionAsc = "DATASET_META_BY_DATASET_HASH_DESCRIPTION_ASC",
  DatasetMetaByDatasetHashDescriptionDesc = "DATASET_META_BY_DATASET_HASH_DESCRIPTION_DESC",
  DatasetMetaByDatasetHashNameAsc = "DATASET_META_BY_DATASET_HASH_NAME_ASC",
  DatasetMetaByDatasetHashNameDesc = "DATASET_META_BY_DATASET_HASH_NAME_DESC",
  DatasetMetaByDatasetHashOwnerEmailAsc = "DATASET_META_BY_DATASET_HASH_OWNER_EMAIL_ASC",
  DatasetMetaByDatasetHashOwnerEmailDesc = "DATASET_META_BY_DATASET_HASH_OWNER_EMAIL_DESC",
  DatasetMetaByDatasetHashUpdatedAtAsc = "DATASET_META_BY_DATASET_HASH_UPDATED_AT_ASC",
  DatasetMetaByDatasetHashUpdatedAtDesc = "DATASET_META_BY_DATASET_HASH_UPDATED_AT_DESC",
  DatasetMetaByDatasetHashUpdatedByEmailAsc = "DATASET_META_BY_DATASET_HASH_UPDATED_BY_EMAIL_ASC",
  DatasetMetaByDatasetHashUpdatedByEmailDesc = "DATASET_META_BY_DATASET_HASH_UPDATED_BY_EMAIL_DESC",
  FileSizeAsc = "FILE_SIZE_ASC",
  FileSizeDesc = "FILE_SIZE_DESC",
  FillRatioAsc = "FILL_RATIO_ASC",
  FillRatioDesc = "FILL_RATIO_DESC",
  Natural = "NATURAL",
  NumberOfDuplicateRowsAsc = "NUMBER_OF_DUPLICATE_ROWS_ASC",
  NumberOfDuplicateRowsDesc = "NUMBER_OF_DUPLICATE_ROWS_DESC",
  NumberOfNotNullableRowsAsc = "NUMBER_OF_NOT_NULLABLE_ROWS_ASC",
  NumberOfNotNullableRowsDesc = "NUMBER_OF_NOT_NULLABLE_ROWS_DESC",
  NumberOfRowsAsc = "NUMBER_OF_ROWS_ASC",
  NumberOfRowsDesc = "NUMBER_OF_ROWS_DESC",
  OwnerEmailAsc = "OWNER_EMAIL_ASC",
  OwnerEmailDesc = "OWNER_EMAIL_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StatisticsAsc = "STATISTICS_ASC",
  StatisticsDesc = "STATISTICS_DESC",
  StatisticsVersionAsc = "STATISTICS_VERSION_ASC",
  StatisticsVersionDesc = "STATISTICS_VERSION_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

export type Datasets = {
  __typename?: "Datasets";
  nodes?: Maybe<Array<Maybe<Dataset>>>;
  totalCount?: Maybe<Scalars["Int"]>;
};

/** A filter to be used against Datetime fields. All fields are combined with a logical ‘and.’ */
export type DatetimeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Datetime"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Datetime"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Datetime"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Datetime"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Datetime"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Datetime"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Datetime"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Datetime"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Datetime"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Datetime"]>>;
};

export type DeleteAccountInput = {
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

export type DeleteAccountPayload = {
  __typename?: "DeleteAccountPayload";
  clientMutationId?: Maybe<Scalars["String"]>;
};

/** All input for the `deleteAttestationSpecificationAsElementById` mutation. */
export type DeleteAttestationSpecificationAsElementByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `AttestationSpecificationAsElement` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteAttestationSpecificationAsElement` mutation. */
export type DeleteAttestationSpecificationAsElementInput = {
  attestationSpecificationId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our delete `AttestationSpecificationAsElement` mutation. */
export type DeleteAttestationSpecificationAsElementPayload = {
  __typename?: "DeleteAttestationSpecificationAsElementPayload";
  /** The `AttestationSpecificationAsElement` that was deleted by this mutation. */
  attestationSpecificationAsElement?: Maybe<AttestationSpecificationAsElement>;
  /** An edge for our `AttestationSpecificationAsElement`. May be used by Relay 1. */
  attestationSpecificationAsElementEdge?: Maybe<AttestationSpecificationAsElementsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedAttestationSpecificationAsElementId?: Maybe<Scalars["ID"]>;
  /** Reads a single `Modification` that is related to this `AttestationSpecificationAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `AttestationSpecificationAsElement` mutation. */
export type DeleteAttestationSpecificationAsElementPayloadAttestationSpecificationAsElementEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AttestationSpecificationAsElementsOrderBy>>;
  };

/** All input for the `deleteAuthenticationMethodAsElementById` mutation. */
export type DeleteAuthenticationMethodAsElementByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `AuthenticationMethodAsElement` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteAuthenticationMethodAsElement` mutation. */
export type DeleteAuthenticationMethodAsElementInput = {
  authenticationMethodId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our delete `AuthenticationMethodAsElement` mutation. */
export type DeleteAuthenticationMethodAsElementPayload = {
  __typename?: "DeleteAuthenticationMethodAsElementPayload";
  /** The `AuthenticationMethodAsElement` that was deleted by this mutation. */
  authenticationMethodAsElement?: Maybe<AuthenticationMethodAsElement>;
  /** An edge for our `AuthenticationMethodAsElement`. May be used by Relay 1. */
  authenticationMethodAsElementEdge?: Maybe<AuthenticationMethodAsElementsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedAuthenticationMethodAsElementId?: Maybe<Scalars["ID"]>;
  /** Reads a single `Modification` that is related to this `AuthenticationMethodAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `AuthenticationMethodAsElement` mutation. */
export type DeleteAuthenticationMethodAsElementPayloadAuthenticationMethodAsElementEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AuthenticationMethodAsElementsOrderBy>>;
  };

/** All input for the `deleteComputeNodeAsElementById` mutation. */
export type DeleteComputeNodeAsElementByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `ComputeNodeAsElement` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteComputeNodeAsElement` mutation. */
export type DeleteComputeNodeAsElementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  computeNodeId: Scalars["UUID"];
};

/** The output of our delete `ComputeNodeAsElement` mutation. */
export type DeleteComputeNodeAsElementPayload = {
  __typename?: "DeleteComputeNodeAsElementPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `ComputeNodeAsElement` that was deleted by this mutation. */
  computeNodeAsElement?: Maybe<ComputeNodeAsElement>;
  /** An edge for our `ComputeNodeAsElement`. May be used by Relay 1. */
  computeNodeAsElementEdge?: Maybe<ComputeNodeAsElementsEdge>;
  deletedComputeNodeAsElementId?: Maybe<Scalars["ID"]>;
  /** Reads a single `Modification` that is related to this `ComputeNodeAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `ComputeNodeAsElement` mutation. */
export type DeleteComputeNodeAsElementPayloadComputeNodeAsElementEdgeArgs = {
  orderBy?: InputMaybe<Array<ComputeNodeAsElementsOrderBy>>;
};

/** All input for the `deleteComputeNodeById` mutation. */
export type DeleteComputeNodeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `ComputeNode` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteComputeNode` mutation. */
export type DeleteComputeNodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  computeNodeId: Scalars["UUID"];
};

/** The output of our delete `ComputeNode` mutation. */
export type DeleteComputeNodePayload = {
  __typename?: "DeleteComputeNodePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `ComputeNode` that was deleted by this mutation. */
  computeNode?: Maybe<ComputeNode>;
  /** An edge for our `ComputeNode`. May be used by Relay 1. */
  computeNodeEdge?: Maybe<ComputeNodesEdge>;
  /** Reads a single `DataRoom` that is related to this `ComputeNode`. */
  dataRoom?: Maybe<DataRoom>;
  deletedComputeNodeId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `ComputeNode` mutation. */
export type DeleteComputeNodePayloadComputeNodeEdgeArgs = {
  orderBy?: InputMaybe<Array<ComputeNodesOrderBy>>;
};

/** All input for the `deleteConfigById` mutation. */
export type DeleteConfigByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Config` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteConfig` mutation. */
export type DeleteConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  dataRoomId: Scalars["UUID"];
};

/** The output of our delete `Config` mutation. */
export type DeleteConfigPayload = {
  __typename?: "DeleteConfigPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Config` that was deleted by this mutation. */
  config?: Maybe<Config>;
  /** An edge for our `Config`. May be used by Relay 1. */
  configEdge?: Maybe<ConfigsEdge>;
  /** Reads a single `DataRoom` that is related to this `Config`. */
  dataRoom?: Maybe<DataRoom>;
  deletedConfigId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `Config` mutation. */
export type DeleteConfigPayloadConfigEdgeArgs = {
  orderBy?: InputMaybe<Array<ConfigsOrderBy>>;
};

/** All input for the `deleteConfigurationAndElements` mutation. */
export type DeleteConfigurationAndElementsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  configurationId: Scalars["UUID"];
};

/** The output of our `deleteConfigurationAndElements` mutation. */
export type DeleteConfigurationAndElementsPayload = {
  __typename?: "DeleteConfigurationAndElementsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  configuration?: Maybe<Configuration>;
  /** An edge for our `Configuration`. May be used by Relay 1. */
  configurationEdge?: Maybe<ConfigurationsEdge>;
  /** Reads a single `DataRoom` that is related to this `Configuration`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our `deleteConfigurationAndElements` mutation. */
export type DeleteConfigurationAndElementsPayloadConfigurationEdgeArgs = {
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy>>;
};

/** All input for the `deleteConfigurationByCommitId` mutation. */
export type DeleteConfigurationByCommitIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  commitId: Scalars["String"];
};

/** All input for the `deleteConfigurationById` mutation. */
export type DeleteConfigurationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Configuration` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteConfiguration` mutation. */
export type DeleteConfigurationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  configurationId: Scalars["UUID"];
};

/** The output of our delete `Configuration` mutation. */
export type DeleteConfigurationPayload = {
  __typename?: "DeleteConfigurationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Configuration` that was deleted by this mutation. */
  configuration?: Maybe<Configuration>;
  /** An edge for our `Configuration`. May be used by Relay 1. */
  configurationEdge?: Maybe<ConfigurationsEdge>;
  /** Reads a single `DataRoom` that is related to this `Configuration`. */
  dataRoom?: Maybe<DataRoom>;
  deletedConfigurationId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `Configuration` mutation. */
export type DeleteConfigurationPayloadConfigurationEdgeArgs = {
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy>>;
};

/** All input for the `deleteDataRoomByDataRoomHash` mutation. */
export type DeleteDataRoomByDataRoomHashInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  dataRoomHash: Scalars["String"];
};

/** All input for the `deleteDataRoomById` mutation. */
export type DeleteDataRoomByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `DataRoom` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteDataRoom` mutation. */
export type DeleteDataRoomInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  dataRoomId: Scalars["UUID"];
};

/** The output of our delete `DataRoom` mutation. */
export type DeleteDataRoomPayload = {
  __typename?: "DeleteDataRoomPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `DataRoom` that was deleted by this mutation. */
  dataRoom?: Maybe<DataRoom>;
  /** An edge for our `DataRoom`. May be used by Relay 1. */
  dataRoomEdge?: Maybe<DataRoomsEdge>;
  deletedDataRoomId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `DataRoom`. */
  userByOwnerEmail?: Maybe<User>;
};

/** The output of our delete `DataRoom` mutation. */
export type DeleteDataRoomPayloadDataRoomEdgeArgs = {
  orderBy?: InputMaybe<Array<DataRoomsOrderBy>>;
};

/** All input for the `deleteDatasetLinkById` mutation. */
export type DeleteDatasetLinkByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `DatasetLink` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteDatasetLink` mutation. */
export type DeleteDatasetLinkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  datasetLinkId: Scalars["UUID"];
};

/** The output of our delete `DatasetLink` mutation. */
export type DeleteDatasetLinkPayload = {
  __typename?: "DeleteDatasetLinkPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `ComputeNode` that is related to this `DatasetLink`. */
  computeNode?: Maybe<ComputeNode>;
  /** The `DatasetLink` that was deleted by this mutation. */
  datasetLink?: Maybe<DatasetLink>;
  /** An edge for our `DatasetLink`. May be used by Relay 1. */
  datasetLinkEdge?: Maybe<DatasetLinksEdge>;
  /** Reads a single `DatasetMeta` that is related to this `DatasetLink`. */
  datasetMeta?: Maybe<DatasetMeta>;
  /** Reads a single `DatasetStat` that is related to this `DatasetLink`. */
  datasetStats?: Maybe<DatasetStat>;
  deletedDatasetLinkId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `DatasetLink` mutation. */
export type DeleteDatasetLinkPayloadDatasetLinkEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetLinksOrderBy>>;
};

/** All input for the `deleteDatasetMetaById` mutation. */
export type DeleteDatasetMetaByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `DatasetMeta` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteDatasetMeta` mutation. */
export type DeleteDatasetMetaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  datasetHash: Scalars["String"];
};

/** The output of our delete `DatasetMeta` mutation. */
export type DeleteDatasetMetaPayload = {
  __typename?: "DeleteDatasetMetaPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `DatasetMeta` that was deleted by this mutation. */
  datasetMeta?: Maybe<DatasetMeta>;
  /** An edge for our `DatasetMeta`. May be used by Relay 1. */
  datasetMetaEdge?: Maybe<DatasetMetasEdge>;
  deletedDatasetMetaId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `DatasetMeta` mutation. */
export type DeleteDatasetMetaPayloadDatasetMetaEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetMetasOrderBy>>;
};

/** All input for the `deleteDatasetStatById` mutation. */
export type DeleteDatasetStatByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `DatasetStat` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteDatasetStat` mutation. */
export type DeleteDatasetStatInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  datasetHash: Scalars["String"];
};

/** The output of our delete `DatasetStat` mutation. */
export type DeleteDatasetStatPayload = {
  __typename?: "DeleteDatasetStatPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DatasetMeta` that is related to this `DatasetStat`. */
  datasetMeta?: Maybe<DatasetMeta>;
  /** The `DatasetStat` that was deleted by this mutation. */
  datasetStat?: Maybe<DatasetStat>;
  /** An edge for our `DatasetStat`. May be used by Relay 1. */
  datasetStatEdge?: Maybe<DatasetStatsEdge>;
  deletedDatasetStatId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `DatasetStat` mutation. */
export type DeleteDatasetStatPayloadDatasetStatEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetStatsOrderBy>>;
};

/** All input for the `deleteDomainByDomain` mutation. */
export type DeleteDomainByDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  domain: Scalars["String"];
};

/** All input for the `deleteDomainById` mutation. */
export type DeleteDomainByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Domain` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteDomain` mutation. */
export type DeleteDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  domainId: Scalars["UUID"];
};

/** The output of our delete `Domain` mutation. */
export type DeleteDomainPayload = {
  __typename?: "DeleteDomainPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedDomainId?: Maybe<Scalars["ID"]>;
  /** The `Domain` that was deleted by this mutation. */
  domain?: Maybe<Domain>;
  /** An edge for our `Domain`. May be used by Relay 1. */
  domainEdge?: Maybe<DomainsEdge>;
  /** Reads a single `Organization` that is related to this `Domain`. */
  organization?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Domain`. */
  user?: Maybe<User>;
};

/** The output of our delete `Domain` mutation. */
export type DeleteDomainPayloadDomainEdgeArgs = {
  orderBy?: InputMaybe<Array<DomainsOrderBy>>;
};

/** All input for the `deleteExecutionTimeById` mutation. */
export type DeleteExecutionTimeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `ExecutionTime` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteExecutionTime` mutation. */
export type DeleteExecutionTimeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  taskId: Scalars["UUID"];
};

/** The output of our delete `ExecutionTime` mutation. */
export type DeleteExecutionTimePayload = {
  __typename?: "DeleteExecutionTimePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedExecutionTimeId?: Maybe<Scalars["ID"]>;
  /** The `ExecutionTime` that was deleted by this mutation. */
  executionTime?: Maybe<ExecutionTime>;
  /** An edge for our `ExecutionTime`. May be used by Relay 1. */
  executionTimeEdge?: Maybe<ExecutionTimesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `ExecutionTime` mutation. */
export type DeleteExecutionTimePayloadExecutionTimeEdgeArgs = {
  orderBy?: InputMaybe<Array<ExecutionTimesOrderBy>>;
};

/** All input for the `deleteJobById` mutation. */
export type DeleteJobByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Job` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteJob` mutation. */
export type DeleteJobInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  computeNodeId: Scalars["UUID"];
  ownerEmail: Scalars["String"];
};

/** The output of our delete `Job` mutation. */
export type DeleteJobPayload = {
  __typename?: "DeleteJobPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `ComputeNode` that is related to this `Job`. */
  computeNode?: Maybe<ComputeNode>;
  deletedJobId?: Maybe<Scalars["ID"]>;
  /** The `Job` that was deleted by this mutation. */
  job?: Maybe<Job>;
  /** An edge for our `Job`. May be used by Relay 1. */
  jobEdge?: Maybe<JobsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `Job` mutation. */
export type DeleteJobPayloadJobEdgeArgs = {
  orderBy?: InputMaybe<Array<JobsOrderBy>>;
};

/** All input for the `deleteLockById` mutation. */
export type DeleteLockByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Lock` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteLock` mutation. */
export type DeleteLockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  dataRoomId: Scalars["UUID"];
};

/** The output of our delete `Lock` mutation. */
export type DeleteLockPayload = {
  __typename?: "DeleteLockPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `Lock`. */
  dataRoom?: Maybe<DataRoom>;
  deletedLockId?: Maybe<Scalars["ID"]>;
  /** The `Lock` that was deleted by this mutation. */
  lock?: Maybe<Lock>;
  /** An edge for our `Lock`. May be used by Relay 1. */
  lockEdge?: Maybe<LocksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `Lock` mutation. */
export type DeleteLockPayloadLockEdgeArgs = {
  orderBy?: InputMaybe<Array<LocksOrderBy>>;
};

/** All input for the `deleteModificationAndElements` mutation. */
export type DeleteModificationAndElementsInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  modificationId: Scalars["UUID"];
};

/** The output of our `deleteModificationAndElements` mutation. */
export type DeleteModificationAndElementsPayload = {
  __typename?: "DeleteModificationAndElementsPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Configuration` that is related to this `Modification`. */
  configuration?: Maybe<Configuration>;
  modification?: Maybe<Modification>;
  /** An edge for our `Modification`. May be used by Relay 1. */
  modificationEdge?: Maybe<ModificationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our `deleteModificationAndElements` mutation. */
export type DeleteModificationAndElementsPayloadModificationEdgeArgs = {
  orderBy?: InputMaybe<Array<ModificationsOrderBy>>;
};

/** All input for the `deleteModificationByElementId` mutation. */
export type DeleteModificationByElementIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  elementId: Scalars["UUID"];
};

/** All input for the `deleteModificationById` mutation. */
export type DeleteModificationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Modification` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteModification` mutation. */
export type DeleteModificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  modificationId: Scalars["UUID"];
};

/** The output of our delete `Modification` mutation. */
export type DeleteModificationPayload = {
  __typename?: "DeleteModificationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Configuration` that is related to this `Modification`. */
  configuration?: Maybe<Configuration>;
  deletedModificationId?: Maybe<Scalars["ID"]>;
  /** The `Modification` that was deleted by this mutation. */
  modification?: Maybe<Modification>;
  /** An edge for our `Modification`. May be used by Relay 1. */
  modificationEdge?: Maybe<ModificationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `Modification` mutation. */
export type DeleteModificationPayloadModificationEdgeArgs = {
  orderBy?: InputMaybe<Array<ModificationsOrderBy>>;
};

/** All input for the `deleteOrganizationAttributeById` mutation. */
export type DeleteOrganizationAttributeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `OrganizationAttribute` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteOrganizationAttribute` mutation. */
export type DeleteOrganizationAttributeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
};

/** The output of our delete `OrganizationAttribute` mutation. */
export type DeleteOrganizationAttributePayload = {
  __typename?: "DeleteOrganizationAttributePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedOrganizationAttributeId?: Maybe<Scalars["ID"]>;
  /** Reads a single `Organization` that is related to this `OrganizationAttribute`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationAttribute` that was deleted by this mutation. */
  organizationAttribute?: Maybe<OrganizationAttribute>;
  /** An edge for our `OrganizationAttribute`. May be used by Relay 1. */
  organizationAttributeEdge?: Maybe<OrganizationAttributesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `OrganizationAttribute` mutation. */
export type DeleteOrganizationAttributePayloadOrganizationAttributeEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationAttributesOrderBy>>;
};

/** All input for the `deleteOrganizationById` mutation. */
export type DeleteOrganizationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Organization` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteOrganization` mutation. */
export type DeleteOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
};

/** All input for the `deleteOrganizationLogoById` mutation. */
export type DeleteOrganizationLogoByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `OrganizationLogo` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteOrganizationLogo` mutation. */
export type DeleteOrganizationLogoInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
};

/** The output of our delete `OrganizationLogo` mutation. */
export type DeleteOrganizationLogoPayload = {
  __typename?: "DeleteOrganizationLogoPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedOrganizationLogoId?: Maybe<Scalars["ID"]>;
  /** Reads a single `Organization` that is related to this `OrganizationLogo`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationLogo` that was deleted by this mutation. */
  organizationLogo?: Maybe<OrganizationLogo>;
  /** An edge for our `OrganizationLogo`. May be used by Relay 1. */
  organizationLogoEdge?: Maybe<OrganizationLogosEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `OrganizationLogo` mutation. */
export type DeleteOrganizationLogoPayloadOrganizationLogoEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationLogosOrderBy>>;
};

/** All input for the `deleteOrganizationMembershipById` mutation. */
export type DeleteOrganizationMembershipByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `OrganizationMembership` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteOrganizationMembership` mutation. */
export type DeleteOrganizationMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  organizationMembershipId: Scalars["UUID"];
};

/** The output of our delete `OrganizationMembership` mutation. */
export type DeleteOrganizationMembershipPayload = {
  __typename?: "DeleteOrganizationMembershipPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedOrganizationMembershipId?: Maybe<Scalars["ID"]>;
  /** Reads a single `Organization` that is related to this `OrganizationMembership`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationMembership` that was deleted by this mutation. */
  organizationMembership?: Maybe<OrganizationMembership>;
  /** An edge for our `OrganizationMembership`. May be used by Relay 1. */
  organizationMembershipEdge?: Maybe<OrganizationMembershipsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `OrganizationMembership`. */
  user?: Maybe<User>;
};

/** The output of our delete `OrganizationMembership` mutation. */
export type DeleteOrganizationMembershipPayloadOrganizationMembershipEdgeArgs =
  {
    orderBy?: InputMaybe<Array<OrganizationMembershipsOrderBy>>;
  };

/** The output of our delete `Organization` mutation. */
export type DeleteOrganizationPayload = {
  __typename?: "DeleteOrganizationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedOrganizationId?: Maybe<Scalars["ID"]>;
  /** The `Organization` that was deleted by this mutation. */
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `Organization` mutation. */
export type DeleteOrganizationPayloadOrganizationEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

/** All input for the `deleteOrganizationQuotaById` mutation. */
export type DeleteOrganizationQuotaByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `OrganizationQuota` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteOrganizationQuota` mutation. */
export type DeleteOrganizationQuotaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
};

/** The output of our delete `OrganizationQuota` mutation. */
export type DeleteOrganizationQuotaPayload = {
  __typename?: "DeleteOrganizationQuotaPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedOrganizationQuotaId?: Maybe<Scalars["ID"]>;
  /** Reads a single `Organization` that is related to this `OrganizationQuota`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationQuota` that was deleted by this mutation. */
  organizationQuota?: Maybe<OrganizationQuota>;
  /** An edge for our `OrganizationQuota`. May be used by Relay 1. */
  organizationQuotaEdge?: Maybe<OrganizationQuotasEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our delete `OrganizationQuota` mutation. */
export type DeleteOrganizationQuotaPayloadOrganizationQuotaEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationQuotasOrderBy>>;
};

/** All input for the `deletePermissionAsElementById` mutation. */
export type DeletePermissionAsElementByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `PermissionAsElement` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deletePermissionAsElement` mutation. */
export type DeletePermissionAsElementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  permissionId: Scalars["UUID"];
};

/** The output of our delete `PermissionAsElement` mutation. */
export type DeletePermissionAsElementPayload = {
  __typename?: "DeletePermissionAsElementPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedPermissionAsElementId?: Maybe<Scalars["ID"]>;
  /** The `PermissionAsElement` that was deleted by this mutation. */
  permissionAsElement?: Maybe<PermissionAsElement>;
  /** An edge for our `PermissionAsElement`. May be used by Relay 1. */
  permissionAsElementEdge?: Maybe<PermissionAsElementsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UserPermissionAsElement` that is related to this `PermissionAsElement`. */
  userPermission?: Maybe<UserPermissionAsElement>;
};

/** The output of our delete `PermissionAsElement` mutation. */
export type DeletePermissionAsElementPayloadPermissionAsElementEdgeArgs = {
  orderBy?: InputMaybe<Array<PermissionAsElementsOrderBy>>;
};

/** All input for the `deletePermissionById` mutation. */
export type DeletePermissionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Permission` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deletePermission` mutation. */
export type DeletePermissionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  permissionId: Scalars["UUID"];
};

/** The output of our delete `Permission` mutation. */
export type DeletePermissionPayload = {
  __typename?: "DeletePermissionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedPermissionId?: Maybe<Scalars["ID"]>;
  /** The `Permission` that was deleted by this mutation. */
  permission?: Maybe<Permission>;
  /** An edge for our `Permission`. May be used by Relay 1. */
  permissionEdge?: Maybe<PermissionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UserPermission` that is related to this `Permission`. */
  userPermission?: Maybe<UserPermission>;
};

/** The output of our delete `Permission` mutation. */
export type DeletePermissionPayloadPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<PermissionsOrderBy>>;
};

/** All input for the `deleteRequestByConfigurationId` mutation. */
export type DeleteRequestByConfigurationIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  configurationId: Scalars["UUID"];
};

/** All input for the `deleteRequestById` mutation. */
export type DeleteRequestByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Request` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteRequest` mutation. */
export type DeleteRequestInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  requestId: Scalars["UUID"];
};

/** The output of our delete `Request` mutation. */
export type DeleteRequestPayload = {
  __typename?: "DeleteRequestPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Configuration` that is related to this `Request`. */
  configuration?: Maybe<Configuration>;
  deletedRequestId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Request` that was deleted by this mutation. */
  request?: Maybe<Request>;
  /** An edge for our `Request`. May be used by Relay 1. */
  requestEdge?: Maybe<RequestsEdge>;
};

/** The output of our delete `Request` mutation. */
export type DeleteRequestPayloadRequestEdgeArgs = {
  orderBy?: InputMaybe<Array<RequestsOrderBy>>;
};

/** All input for the `deleteResourceById` mutation. */
export type DeleteResourceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Resource` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteResource` mutation. */
export type DeleteResourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ownerEmail: Scalars["String"];
  resourceId: Scalars["String"];
};

/** The output of our delete `Resource` mutation. */
export type DeleteResourcePayload = {
  __typename?: "DeleteResourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedResourceId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Resource` that was deleted by this mutation. */
  resource?: Maybe<Resource>;
  /** An edge for our `Resource`. May be used by Relay 1. */
  resourceEdge?: Maybe<ResourcesEdge>;
};

/** The output of our delete `Resource` mutation. */
export type DeleteResourcePayloadResourceEdgeArgs = {
  orderBy?: InputMaybe<Array<ResourcesOrderBy>>;
};

/** All input for the `deleteSignatureById` mutation. */
export type DeleteSignatureByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Signature` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteSignatureBySignatureHash` mutation. */
export type DeleteSignatureBySignatureHashInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  signatureHash: Scalars["String"];
};

/** All input for the `deleteSignature` mutation. */
export type DeleteSignatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  signeeId: Scalars["UUID"];
};

/** The output of our delete `Signature` mutation. */
export type DeleteSignaturePayload = {
  __typename?: "DeleteSignaturePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedSignatureId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Signature` that was deleted by this mutation. */
  signature?: Maybe<Signature>;
  /** An edge for our `Signature`. May be used by Relay 1. */
  signatureEdge?: Maybe<SignaturesEdge>;
  /** Reads a single `Signee` that is related to this `Signature`. */
  signee?: Maybe<Signee>;
};

/** The output of our delete `Signature` mutation. */
export type DeleteSignaturePayloadSignatureEdgeArgs = {
  orderBy?: InputMaybe<Array<SignaturesOrderBy>>;
};

/** All input for the `deleteSigneeById` mutation. */
export type DeleteSigneeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Signee` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteSigneeByRequestIdAndSigneeEmail` mutation. */
export type DeleteSigneeByRequestIdAndSigneeEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  requestId: Scalars["UUID"];
  signeeEmail: Scalars["String"];
};

/** All input for the `deleteSignee` mutation. */
export type DeleteSigneeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  signeeId: Scalars["UUID"];
};

/** The output of our delete `Signee` mutation. */
export type DeleteSigneePayload = {
  __typename?: "DeleteSigneePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedSigneeId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Request` that is related to this `Signee`. */
  request?: Maybe<Request>;
  /** The `Signee` that was deleted by this mutation. */
  signee?: Maybe<Signee>;
  /** An edge for our `Signee`. May be used by Relay 1. */
  signeeEdge?: Maybe<SigneesEdge>;
};

/** The output of our delete `Signee` mutation. */
export type DeleteSigneePayloadSigneeEdgeArgs = {
  orderBy?: InputMaybe<Array<SigneesOrderBy>>;
};

/** All input for the `deleteStateById` mutation. */
export type DeleteStateByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `State` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteState` mutation. */
export type DeleteStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  dataRoomId: Scalars["UUID"];
};

/** The output of our delete `State` mutation. */
export type DeleteStatePayload = {
  __typename?: "DeleteStatePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `State`. */
  dataRoom?: Maybe<DataRoom>;
  deletedStateId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `State` that was deleted by this mutation. */
  state?: Maybe<State>;
  /** An edge for our `State`. May be used by Relay 1. */
  stateEdge?: Maybe<StatesEdge>;
};

/** The output of our delete `State` mutation. */
export type DeleteStatePayloadStateEdgeArgs = {
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

/** All input for the `deleteUserAttributeById` mutation. */
export type DeleteUserAttributeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `UserAttribute` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteUserAttribute` mutation. */
export type DeleteUserAttributeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["UUID"];
};

/** The output of our delete `UserAttribute` mutation. */
export type DeleteUserAttributePayload = {
  __typename?: "DeleteUserAttributePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedUserAttributeId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserAttribute`. */
  user?: Maybe<User>;
  /** The `UserAttribute` that was deleted by this mutation. */
  userAttribute?: Maybe<UserAttribute>;
  /** An edge for our `UserAttribute`. May be used by Relay 1. */
  userAttributeEdge?: Maybe<UserAttributesEdge>;
};

/** The output of our delete `UserAttribute` mutation. */
export type DeleteUserAttributePayloadUserAttributeEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAttributesOrderBy>>;
};

/** All input for the `deleteUserByEmail` mutation. */
export type DeleteUserByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
};

/** All input for the `deleteUserById` mutation. */
export type DeleteUserByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `User` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteUserConfigByDataRoomIdAndEmail` mutation. */
export type DeleteUserConfigByDataRoomIdAndEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  dataRoomId: Scalars["UUID"];
  email: Scalars["String"];
};

/** All input for the `deleteUserConfigById` mutation. */
export type DeleteUserConfigByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `UserConfig` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteUserConfig` mutation. */
export type DeleteUserConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  userConfigId: Scalars["UUID"];
};

/** The output of our delete `UserConfig` mutation. */
export type DeleteUserConfigPayload = {
  __typename?: "DeleteUserConfigPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `UserConfig`. */
  dataRoom?: Maybe<DataRoom>;
  deletedUserConfigId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserConfig`. */
  userByEmail?: Maybe<User>;
  /** The `UserConfig` that was deleted by this mutation. */
  userConfig?: Maybe<UserConfig>;
  /** An edge for our `UserConfig`. May be used by Relay 1. */
  userConfigEdge?: Maybe<UserConfigsEdge>;
};

/** The output of our delete `UserConfig` mutation. */
export type DeleteUserConfigPayloadUserConfigEdgeArgs = {
  orderBy?: InputMaybe<Array<UserConfigsOrderBy>>;
};

/** All input for the `deleteUser` mutation. */
export type DeleteUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  userId: Scalars["UUID"];
};

/** The output of our delete `User` mutation. */
export type DeleteUserPayload = {
  __typename?: "DeleteUserPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedUserId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was deleted by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};

/** The output of our delete `User` mutation. */
export type DeleteUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `deleteUserPermissionAsElementById` mutation. */
export type DeleteUserPermissionAsElementByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `UserPermissionAsElement` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteUserPermissionAsElement` mutation. */
export type DeleteUserPermissionAsElementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  userPermissionId: Scalars["UUID"];
};

/** The output of our delete `UserPermissionAsElement` mutation. */
export type DeleteUserPermissionAsElementPayload = {
  __typename?: "DeleteUserPermissionAsElementPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedUserPermissionAsElementId?: Maybe<Scalars["ID"]>;
  /** Reads a single `Modification` that is related to this `UserPermissionAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UserPermissionAsElement` that was deleted by this mutation. */
  userPermissionAsElement?: Maybe<UserPermissionAsElement>;
  /** An edge for our `UserPermissionAsElement`. May be used by Relay 1. */
  userPermissionAsElementEdge?: Maybe<UserPermissionAsElementsEdge>;
};

/** All input for the `deleteUserPermissionByDataRoomIdAndEmail` mutation. */
export type DeleteUserPermissionByDataRoomIdAndEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  dataRoomId: Scalars["UUID"];
  email: Scalars["String"];
};

/** All input for the `deleteUserPermissionById` mutation. */
export type DeleteUserPermissionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `UserPermission` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteUserPermission` mutation. */
export type DeleteUserPermissionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  userPermissionId: Scalars["UUID"];
};

/** The output of our delete `UserPermission` mutation. */
export type DeleteUserPermissionPayload = {
  __typename?: "DeleteUserPermissionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `UserPermission`. */
  dataRoom?: Maybe<DataRoom>;
  deletedUserPermissionId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserPermission`. */
  userByEmail?: Maybe<User>;
  /** The `UserPermission` that was deleted by this mutation. */
  userPermission?: Maybe<UserPermission>;
  /** An edge for our `UserPermission`. May be used by Relay 1. */
  userPermissionEdge?: Maybe<UserPermissionsEdge>;
};

/** The output of our delete `UserPermission` mutation. */
export type DeleteUserPermissionPayloadUserPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<UserPermissionsOrderBy>>;
};

/** All input for the `deleteUserQuotaById` mutation. */
export type DeleteUserQuotaByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `UserQuota` to be deleted. */
  id: Scalars["ID"];
};

/** All input for the `deleteUserQuota` mutation. */
export type DeleteUserQuotaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
};

/** The output of our delete `UserQuota` mutation. */
export type DeleteUserQuotaPayload = {
  __typename?: "DeleteUserQuotaPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  deletedUserQuotaId?: Maybe<Scalars["ID"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserQuota`. */
  userByEmail?: Maybe<User>;
  /** The `UserQuota` that was deleted by this mutation. */
  userQuota?: Maybe<UserQuota>;
  /** An edge for our `UserQuota`. May be used by Relay 1. */
  userQuotaEdge?: Maybe<UserQuotasEdge>;
};

/** The output of our delete `UserQuota` mutation. */
export type DeleteUserQuotaPayloadUserQuotaEdgeArgs = {
  orderBy?: InputMaybe<Array<UserQuotasOrderBy>>;
};

export type Domain = Node & {
  __typename?: "Domain";
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  domain: Scalars["String"];
  domainId: Scalars["UUID"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  /** Reads a single `Organization` that is related to this `Domain`. */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars["UUID"]>;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
  /** Reads a single `User` that is related to this `Domain`. */
  user?: Maybe<User>;
};

export type DomainAggregates = {
  __typename?: "DomainAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<DomainDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A filter to be used against aggregates of `Domain` object types. */
export type DomainAggregatesFilter = {
  /** Distinct count aggregate over matching `Domain` objects. */
  distinctCount?: InputMaybe<DomainDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Domain` object to be included within the aggregate. */
  filter?: InputMaybe<DomainFilter>;
};

/** A condition to be used against `Domain` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type DomainCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `domain` field. */
  domain?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `domainId` field. */
  domainId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type DomainDistinct = {
  __typename?: "DomainDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `domain` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  domain: Array<Scalars["String"]>;
  /** Distinct `domainId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  domainId: Array<Scalars["UUID"]>;
  /** Distinct `organizationId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  organizationId: Array<Maybe<Scalars["UUID"]>>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type DomainDistinctCountAggregateFilter = {
  createdAt?: InputMaybe<BigIntFilter>;
  createdByEmail?: InputMaybe<BigIntFilter>;
  domain?: InputMaybe<BigIntFilter>;
  domainId?: InputMaybe<BigIntFilter>;
  organizationId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  updatedByEmail?: InputMaybe<BigIntFilter>;
};

export type DomainDistinctCountAggregates = {
  __typename?: "DomainDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of domain across the matching connection */
  domain?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of domainId across the matching connection */
  domainId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of organizationId across the matching connection */
  organizationId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** The fields on `domain` to look up the row to connect. */
export type DomainDomainDomainKeyConnect = {
  domain: Scalars["String"];
};

/** The fields on `domain` to look up the row to delete. */
export type DomainDomainDomainKeyDelete = {
  domain: Scalars["String"];
};

/** The fields on `domain` to look up the row to connect. */
export type DomainDomainPkeyConnect = {
  domainId: Scalars["UUID"];
};

/** The fields on `domain` to look up the row to delete. */
export type DomainDomainPkeyDelete = {
  domainId: Scalars["UUID"];
};

/** A filter to be used against `Domain` object types. All fields are combined with a logical ‘and.’ */
export type DomainFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<DomainFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `domain` field. */
  domain?: InputMaybe<StringFilter>;
  /** Filter by the object’s `domainId` field. */
  domainId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<DomainFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<DomainFilter>>;
  /** Filter by the object’s `organization` relation. */
  organization?: InputMaybe<OrganizationFilter>;
  /** A related `organization` exists. */
  organizationExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `organizationId` field. */
  organizationId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
};

export type DomainGroup = {
  __typename?: "DomainGroup";
  /** A list of _all_ `Domain` primary keys that belong to the `DomainGroup`. */
  domainIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `Domain` of this `DomainGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `DomainGroup`. Overriding these may result in the wrong set of `Domain` returned.
   */
  domains?: Maybe<DomainsConnection>;
  /** `DomainFilter` that was used to implicitly filter `domains`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** The count of _all_ `Domain` you could get from the `DomainGroup`. */
  totalCount: Scalars["Int"];
};

export type DomainGroupDomainsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DomainCondition>;
  filter?: InputMaybe<DomainFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DomainsOrderBy>>;
};

/** Grouping methods for `Domain` for usage during aggregation. */
export enum DomainGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  OrganizationByOrganizationIdContainerMetadata = "ORGANIZATION_BY_ORGANIZATION_ID_CONTAINER_METADATA",
  OrganizationByOrganizationIdCreatedAt = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_AT",
  OrganizationByOrganizationIdCreatedByEmail = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_BY_EMAIL",
  OrganizationByOrganizationIdDescription = "ORGANIZATION_BY_ORGANIZATION_ID_DESCRIPTION",
  OrganizationByOrganizationIdName = "ORGANIZATION_BY_ORGANIZATION_ID_NAME",
  OrganizationByOrganizationIdOrganizationId = "ORGANIZATION_BY_ORGANIZATION_ID_ORGANIZATION_ID",
  OrganizationByOrganizationIdUpdatedAt = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_AT",
  OrganizationByOrganizationIdUpdatedByEmail = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL",
  OrganizationId = "ORGANIZATION_ID",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
  UserByDomainCreatedAt = "USER_BY_DOMAIN_CREATED_AT",
  UserByDomainCreatedByEmail = "USER_BY_DOMAIN_CREATED_BY_EMAIL",
  UserByDomainDomain = "USER_BY_DOMAIN_DOMAIN",
  UserByDomainEmail = "USER_BY_DOMAIN_EMAIL",
  UserByDomainEmailVerified = "USER_BY_DOMAIN_EMAIL_VERIFIED",
  UserByDomainFamilyName = "USER_BY_DOMAIN_FAMILY_NAME",
  UserByDomainGivenName = "USER_BY_DOMAIN_GIVEN_NAME",
  UserByDomainName = "USER_BY_DOMAIN_NAME",
  UserByDomainNickname = "USER_BY_DOMAIN_NICKNAME",
  UserByDomainPicture = "USER_BY_DOMAIN_PICTURE",
  UserByDomainUpdatedAt = "USER_BY_DOMAIN_UPDATED_AT",
  UserByDomainUpdatedByEmail = "USER_BY_DOMAIN_UPDATED_BY_EMAIL",
  UserByDomainUpstreamUserId = "USER_BY_DOMAIN_UPSTREAM_USER_ID",
  UserByDomainUserId = "USER_BY_DOMAIN_USER_ID",
}

export type DomainHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DomainHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Domain` aggregates. */
export type DomainHavingInput = {
  AND?: InputMaybe<Array<DomainHavingInput>>;
  OR?: InputMaybe<Array<DomainHavingInput>>;
  average?: InputMaybe<DomainHavingAverageInput>;
  distinctCount?: InputMaybe<DomainHavingDistinctCountInput>;
  max?: InputMaybe<DomainHavingMaxInput>;
  min?: InputMaybe<DomainHavingMinInput>;
  stddevPopulation?: InputMaybe<DomainHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<DomainHavingStddevSampleInput>;
  sum?: InputMaybe<DomainHavingSumInput>;
  variancePopulation?: InputMaybe<DomainHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<DomainHavingVarianceSampleInput>;
};

export type DomainHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DomainHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DomainHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DomainHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DomainHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DomainHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type DomainHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Domain` */
export type DomainInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  domain?: InputMaybe<Scalars["String"]>;
  domainId?: InputMaybe<Scalars["UUID"]>;
  organization?: InputMaybe<DomainOrganizationIdFkeyInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0Input>;
};

/** The globally unique `ID` look up for the row to connect. */
export type DomainNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `domain` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type DomainNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `domain` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type DomainOnDomainForDomainOrganizationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `organization` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `organization` being updated. */
  patch: OrganizationPatch;
};

/** The fields on `domain` to look up the row to update. */
export type DomainOnDomainForDomainOrganizationIdFkeyUsingDomainDomainKeyUpdate =
  {
    domain: Scalars["String"];
    /** An object where the defined keys will be set on the `domain` being updated. */
    patch: UpdateDomainOnDomainForDomainOrganizationIdFkeyPatch;
  };

/** The fields on `domain` to look up the row to update. */
export type DomainOnDomainForDomainOrganizationIdFkeyUsingDomainPkeyUpdate = {
  domainId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `domain` being updated. */
  patch: UpdateDomainOnDomainForDomainOrganizationIdFkeyPatch;
};

/** The globally unique `ID` look up for the row to update. */
export type DomainOnDomainForFakeDecentriqPlatformDomainForeignKey0NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `user` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UserPatch;
  };

/** The fields on `domain` to look up the row to update. */
export type DomainOnDomainForFakeDecentriqPlatformDomainForeignKey0UsingDomainDomainKeyUpdate =
  {
    domain: Scalars["String"];
    /** An object where the defined keys will be set on the `domain` being updated. */
    patch: UpdateDomainOnDomainForFakeDecentriqPlatformDomainForeignKey0Patch;
  };

/** The fields on `domain` to look up the row to update. */
export type DomainOnDomainForFakeDecentriqPlatformDomainForeignKey0UsingDomainPkeyUpdate =
  {
    domainId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `domain` being updated. */
    patch: UpdateDomainOnDomainForFakeDecentriqPlatformDomainForeignKey0Patch;
  };

/** The `domain` to be created by this mutation. */
export type DomainOrganizationIdFkeyDomainCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  domain?: InputMaybe<Scalars["String"]>;
  domainId?: InputMaybe<Scalars["UUID"]>;
  organization?: InputMaybe<DomainOrganizationIdFkeyInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0Input>;
};

/** Input for the nested mutation of `organization` in the `DomainInput` mutation. */
export type DomainOrganizationIdFkeyInput = {
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectById?: InputMaybe<OrganizationNodeIdConnect>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByOrganizationId?: InputMaybe<OrganizationOrganizationPkeyConnect>;
  /** A `OrganizationInput` object that will be created and connected to this object. */
  create?: InputMaybe<DomainOrganizationIdFkeyOrganizationCreateInput>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteById?: InputMaybe<OrganizationNodeIdDelete>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByOrganizationId?: InputMaybe<OrganizationOrganizationPkeyDelete>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateById?: InputMaybe<DomainOnDomainForDomainOrganizationIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByOrganizationId?: InputMaybe<OrganizationOnDomainForDomainOrganizationIdFkeyUsingOrganizationPkeyUpdate>;
};

/** Input for the nested mutation of `domain` in the `OrganizationInput` mutation. */
export type DomainOrganizationIdFkeyInverseInput = {
  /** The primary key(s) for `domain` for the far side of the relationship. */
  connectByDomain?: InputMaybe<Array<DomainDomainDomainKeyConnect>>;
  /** The primary key(s) for `domain` for the far side of the relationship. */
  connectByDomainId?: InputMaybe<Array<DomainDomainPkeyConnect>>;
  /** The primary key(s) for `domain` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DomainNodeIdConnect>>;
  /** A `DomainInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<DomainOrganizationIdFkeyDomainCreateInput>>;
  /** The primary key(s) for `domain` for the far side of the relationship. */
  deleteByDomain?: InputMaybe<Array<DomainDomainDomainKeyDelete>>;
  /** The primary key(s) for `domain` for the far side of the relationship. */
  deleteByDomainId?: InputMaybe<Array<DomainDomainPkeyDelete>>;
  /** The primary key(s) for `domain` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DomainNodeIdDelete>>;
  /** Flag indicating whether all other `domain` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `domain` for the far side of the relationship. */
  updateByDomain?: InputMaybe<
    Array<DomainOnDomainForDomainOrganizationIdFkeyUsingDomainDomainKeyUpdate>
  >;
  /** The primary key(s) and patch data for `domain` for the far side of the relationship. */
  updateByDomainId?: InputMaybe<
    Array<DomainOnDomainForDomainOrganizationIdFkeyUsingDomainPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `domain` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<OrganizationOnDomainForDomainOrganizationIdFkeyNodeIdUpdate>
  >;
};

/** The `organization` to be created by this mutation. */
export type DomainOrganizationIdFkeyOrganizationCreateInput = {
  containerMetadata?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  domains?: InputMaybe<DomainOrganizationIdFkeyInverseInput>;
  name: Scalars["String"];
  organizationAttribute?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  organizationLogo?: InputMaybe<OrganizationLogoOrganizationIdFkeyInverseInput>;
  organizationMemberships?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInverseInput>;
  organizationQuota?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** Represents an update to a `Domain`. Fields that are set will be updated. */
export type DomainPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  domain?: InputMaybe<Scalars["String"]>;
  domainId?: InputMaybe<Scalars["UUID"]>;
  organization?: InputMaybe<DomainOrganizationIdFkeyInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0Input>;
};

/** A connection to a list of `Domain` values. */
export type DomainsConnection = {
  __typename?: "DomainsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<DomainAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<DomainDistinct>;
  /** A list of _all_ `Domain` primary keys. */
  domainIds?: Maybe<Array<Scalars["UUID"]>>;
  /** A list of edges which contains the `Domain` and cursor to aid in pagination. */
  edges: Array<DomainsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<DomainAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<DomainGroup>>;
  /** A list of `Domain` objects. */
  nodes: Array<Maybe<Domain>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Domain` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `Domain` values. */
export type DomainsConnectionGroupedAggregatesArgs = {
  groupBy: Array<DomainGroupBy>;
  having?: InputMaybe<DomainHavingInput>;
};

/** A connection to a list of `Domain` values. */
export type DomainsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<DomainGroupBy>;
  having?: InputMaybe<DomainHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `Domain` edge in the connection. */
export type DomainsEdge = {
  __typename?: "DomainsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `Domain` at the end of the edge. */
  node?: Maybe<Domain>;
};

/** Methods to use when ordering `Domain`. */
export enum DomainsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  DomainAsc = "DOMAIN_ASC",
  DomainDesc = "DOMAIN_DESC",
  DomainIdAsc = "DOMAIN_ID_ASC",
  DomainIdDesc = "DOMAIN_ID_DESC",
  Natural = "NATURAL",
  OrganizationByOrganizationIdContainerMetadataAsc = "ORGANIZATION_BY_ORGANIZATION_ID_CONTAINER_METADATA_ASC",
  OrganizationByOrganizationIdContainerMetadataDesc = "ORGANIZATION_BY_ORGANIZATION_ID_CONTAINER_METADATA_DESC",
  OrganizationByOrganizationIdCreatedAtAsc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_AT_ASC",
  OrganizationByOrganizationIdCreatedAtDesc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_AT_DESC",
  OrganizationByOrganizationIdCreatedByEmailAsc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_ASC",
  OrganizationByOrganizationIdCreatedByEmailDesc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_DESC",
  OrganizationByOrganizationIdDescriptionAsc = "ORGANIZATION_BY_ORGANIZATION_ID_DESCRIPTION_ASC",
  OrganizationByOrganizationIdDescriptionDesc = "ORGANIZATION_BY_ORGANIZATION_ID_DESCRIPTION_DESC",
  OrganizationByOrganizationIdNameAsc = "ORGANIZATION_BY_ORGANIZATION_ID_NAME_ASC",
  OrganizationByOrganizationIdNameDesc = "ORGANIZATION_BY_ORGANIZATION_ID_NAME_DESC",
  OrganizationByOrganizationIdOrganizationIdAsc = "ORGANIZATION_BY_ORGANIZATION_ID_ORGANIZATION_ID_ASC",
  OrganizationByOrganizationIdOrganizationIdDesc = "ORGANIZATION_BY_ORGANIZATION_ID_ORGANIZATION_ID_DESC",
  OrganizationByOrganizationIdUpdatedAtAsc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_AT_ASC",
  OrganizationByOrganizationIdUpdatedAtDesc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_AT_DESC",
  OrganizationByOrganizationIdUpdatedByEmailAsc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_ASC",
  OrganizationByOrganizationIdUpdatedByEmailDesc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_DESC",
  OrganizationIdAsc = "ORGANIZATION_ID_ASC",
  OrganizationIdDesc = "ORGANIZATION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
  UserByDomainCreatedAtAsc = "USER_BY_DOMAIN_CREATED_AT_ASC",
  UserByDomainCreatedAtDesc = "USER_BY_DOMAIN_CREATED_AT_DESC",
  UserByDomainCreatedByEmailAsc = "USER_BY_DOMAIN_CREATED_BY_EMAIL_ASC",
  UserByDomainCreatedByEmailDesc = "USER_BY_DOMAIN_CREATED_BY_EMAIL_DESC",
  UserByDomainDomainAsc = "USER_BY_DOMAIN_DOMAIN_ASC",
  UserByDomainDomainDesc = "USER_BY_DOMAIN_DOMAIN_DESC",
  UserByDomainEmailAsc = "USER_BY_DOMAIN_EMAIL_ASC",
  UserByDomainEmailDesc = "USER_BY_DOMAIN_EMAIL_DESC",
  UserByDomainEmailVerifiedAsc = "USER_BY_DOMAIN_EMAIL_VERIFIED_ASC",
  UserByDomainEmailVerifiedDesc = "USER_BY_DOMAIN_EMAIL_VERIFIED_DESC",
  UserByDomainFamilyNameAsc = "USER_BY_DOMAIN_FAMILY_NAME_ASC",
  UserByDomainFamilyNameDesc = "USER_BY_DOMAIN_FAMILY_NAME_DESC",
  UserByDomainGivenNameAsc = "USER_BY_DOMAIN_GIVEN_NAME_ASC",
  UserByDomainGivenNameDesc = "USER_BY_DOMAIN_GIVEN_NAME_DESC",
  UserByDomainNameAsc = "USER_BY_DOMAIN_NAME_ASC",
  UserByDomainNameDesc = "USER_BY_DOMAIN_NAME_DESC",
  UserByDomainNicknameAsc = "USER_BY_DOMAIN_NICKNAME_ASC",
  UserByDomainNicknameDesc = "USER_BY_DOMAIN_NICKNAME_DESC",
  UserByDomainPictureAsc = "USER_BY_DOMAIN_PICTURE_ASC",
  UserByDomainPictureDesc = "USER_BY_DOMAIN_PICTURE_DESC",
  UserByDomainUpdatedAtAsc = "USER_BY_DOMAIN_UPDATED_AT_ASC",
  UserByDomainUpdatedAtDesc = "USER_BY_DOMAIN_UPDATED_AT_DESC",
  UserByDomainUpdatedByEmailAsc = "USER_BY_DOMAIN_UPDATED_BY_EMAIL_ASC",
  UserByDomainUpdatedByEmailDesc = "USER_BY_DOMAIN_UPDATED_BY_EMAIL_DESC",
  UserByDomainUpstreamUserIdAsc = "USER_BY_DOMAIN_UPSTREAM_USER_ID_ASC",
  UserByDomainUpstreamUserIdDesc = "USER_BY_DOMAIN_UPSTREAM_USER_ID_DESC",
  UserByDomainUserIdAsc = "USER_BY_DOMAIN_USER_ID_ASC",
  UserByDomainUserIdDesc = "USER_BY_DOMAIN_USER_ID_DESC",
}

export enum ElementTypeType {
  AttestationSpecification = "ATTESTATION_SPECIFICATION",
  AuthenticationMethod = "AUTHENTICATION_METHOD",
  ComputeNode = "COMPUTE_NODE",
  UserPermission = "USER_PERMISSION",
}

/** A filter to be used against ElementTypeType fields. All fields are combined with a logical ‘and.’ */
export type ElementTypeTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ElementTypeType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ElementTypeType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ElementTypeType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ElementTypeType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ElementTypeType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ElementTypeType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ElementTypeType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ElementTypeType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ElementTypeType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ElementTypeType>>;
};

export type Event = {
  __typename?: "Event";
  eventLevel: Scalars["Int"];
  eventMessage?: Maybe<Scalars["String"]>;
  eventType: Scalars["String"];
  timestamp: Scalars["Datetime"];
};

export type EventsPayload = {
  __typename?: "EventsPayload";
  nodes: Array<Maybe<Event>>;
};

export type EventsSubscriptionPayload = {
  __typename?: "EventsSubscriptionPayload";
  nodes: Array<Maybe<Event>>;
};

export type ExecutionTime = Node & {
  __typename?: "ExecutionTime";
  createdAt: Scalars["Datetime"];
  duration?: Maybe<Scalars["Int"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  organizationId: Scalars["String"];
  taskId: Scalars["UUID"];
  userId: Scalars["String"];
  workerType?: Maybe<Scalars["String"]>;
};

export type ExecutionTimeAggregates = {
  __typename?: "ExecutionTimeAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<ExecutionTimeAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ExecutionTimeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<ExecutionTimeMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<ExecutionTimeMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<ExecutionTimeStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<ExecutionTimeStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<ExecutionTimeSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<ExecutionTimeVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<ExecutionTimeVarianceSampleAggregates>;
};

export type ExecutionTimeAverageAggregates = {
  __typename?: "ExecutionTimeAverageAggregates";
  /** Mean average of duration across the matching connection */
  duration?: Maybe<Scalars["BigFloat"]>;
};

/**
 * A condition to be used against `ExecutionTime` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ExecutionTimeCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `duration` field. */
  duration?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `taskId` field. */
  taskId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `workerType` field. */
  workerType?: InputMaybe<Scalars["String"]>;
};

export type ExecutionTimeDistinct = {
  __typename?: "ExecutionTimeDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `duration` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  duration: Array<Maybe<Scalars["Int"]>>;
  /** Distinct `organizationId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  organizationId: Array<Scalars["String"]>;
  /** Distinct `taskId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  taskId: Array<Scalars["UUID"]>;
  /** Distinct `userId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  userId: Array<Scalars["String"]>;
  /** Distinct `workerType` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  workerType: Array<Maybe<Scalars["String"]>>;
};

export type ExecutionTimeDistinctCountAggregates = {
  __typename?: "ExecutionTimeDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of duration across the matching connection */
  duration?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of organizationId across the matching connection */
  organizationId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of taskId across the matching connection */
  taskId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of workerType across the matching connection */
  workerType?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `ExecutionTime` object types. All fields are combined with a logical ‘and.’ */
export type ExecutionTimeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ExecutionTimeFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `duration` field. */
  duration?: InputMaybe<IntFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ExecutionTimeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ExecutionTimeFilter>>;
  /** Filter by the object’s `organizationId` field. */
  organizationId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `taskId` field. */
  taskId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `workerType` field. */
  workerType?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `ExecutionTime` */
export type ExecutionTimeInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  organizationId: Scalars["String"];
  taskId?: InputMaybe<Scalars["UUID"]>;
  userId: Scalars["String"];
  workerType?: InputMaybe<Scalars["String"]>;
};

export type ExecutionTimeMaxAggregates = {
  __typename?: "ExecutionTimeMaxAggregates";
  /** Maximum of duration across the matching connection */
  duration?: Maybe<Scalars["Int"]>;
};

export type ExecutionTimeMinAggregates = {
  __typename?: "ExecutionTimeMinAggregates";
  /** Minimum of duration across the matching connection */
  duration?: Maybe<Scalars["Int"]>;
};

/** Represents an update to a `ExecutionTime`. Fields that are set will be updated. */
export type ExecutionTimePatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  duration?: InputMaybe<Scalars["Int"]>;
  organizationId?: InputMaybe<Scalars["String"]>;
  taskId?: InputMaybe<Scalars["UUID"]>;
  userId?: InputMaybe<Scalars["String"]>;
  workerType?: InputMaybe<Scalars["String"]>;
};

export type ExecutionTimeStddevPopulationAggregates = {
  __typename?: "ExecutionTimeStddevPopulationAggregates";
  /** Population standard deviation of duration across the matching connection */
  duration?: Maybe<Scalars["BigFloat"]>;
};

export type ExecutionTimeStddevSampleAggregates = {
  __typename?: "ExecutionTimeStddevSampleAggregates";
  /** Sample standard deviation of duration across the matching connection */
  duration?: Maybe<Scalars["BigFloat"]>;
};

export type ExecutionTimeSumAggregates = {
  __typename?: "ExecutionTimeSumAggregates";
  /** Sum of duration across the matching connection */
  duration: Scalars["BigInt"];
};

export type ExecutionTimeVariancePopulationAggregates = {
  __typename?: "ExecutionTimeVariancePopulationAggregates";
  /** Population variance of duration across the matching connection */
  duration?: Maybe<Scalars["BigFloat"]>;
};

export type ExecutionTimeVarianceSampleAggregates = {
  __typename?: "ExecutionTimeVarianceSampleAggregates";
  /** Sample variance of duration across the matching connection */
  duration?: Maybe<Scalars["BigFloat"]>;
};

/** A connection to a list of `ExecutionTime` values. */
export type ExecutionTimesConnection = {
  __typename?: "ExecutionTimesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ExecutionTimeAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<ExecutionTimeDistinct>;
  /** A list of edges which contains the `ExecutionTime` and cursor to aid in pagination. */
  edges: Array<ExecutionTimesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ExecutionTimeAggregates>>;
  /** A list of `ExecutionTime` objects. */
  nodes: Array<Maybe<ExecutionTime>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of _all_ `ExecutionTime` primary keys. */
  taskIds?: Maybe<Array<Scalars["UUID"]>>;
  /** The count of *all* `ExecutionTime` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `ExecutionTime` values. */
export type ExecutionTimesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ExecutionTimesGroupBy>;
  having?: InputMaybe<ExecutionTimesHavingInput>;
};

/** A `ExecutionTime` edge in the connection. */
export type ExecutionTimesEdge = {
  __typename?: "ExecutionTimesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `ExecutionTime` at the end of the edge. */
  node?: Maybe<ExecutionTime>;
};

/** Grouping methods for `ExecutionTime` for usage during aggregation. */
export enum ExecutionTimesGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  Duration = "DURATION",
  OrganizationId = "ORGANIZATION_ID",
  UserId = "USER_ID",
  WorkerType = "WORKER_TYPE",
}

export type ExecutionTimesHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
};

export type ExecutionTimesHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `ExecutionTime` aggregates. */
export type ExecutionTimesHavingInput = {
  AND?: InputMaybe<Array<ExecutionTimesHavingInput>>;
  OR?: InputMaybe<Array<ExecutionTimesHavingInput>>;
  average?: InputMaybe<ExecutionTimesHavingAverageInput>;
  distinctCount?: InputMaybe<ExecutionTimesHavingDistinctCountInput>;
  max?: InputMaybe<ExecutionTimesHavingMaxInput>;
  min?: InputMaybe<ExecutionTimesHavingMinInput>;
  stddevPopulation?: InputMaybe<ExecutionTimesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ExecutionTimesHavingStddevSampleInput>;
  sum?: InputMaybe<ExecutionTimesHavingSumInput>;
  variancePopulation?: InputMaybe<ExecutionTimesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ExecutionTimesHavingVarianceSampleInput>;
};

export type ExecutionTimesHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
};

export type ExecutionTimesHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
};

export type ExecutionTimesHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
};

export type ExecutionTimesHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
};

export type ExecutionTimesHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
};

export type ExecutionTimesHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
};

export type ExecutionTimesHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  duration?: InputMaybe<HavingIntFilter>;
};

/** Methods to use when ordering `ExecutionTime`. */
export enum ExecutionTimesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  DurationAsc = "DURATION_ASC",
  DurationDesc = "DURATION_DESC",
  Natural = "NATURAL",
  OrganizationIdAsc = "ORGANIZATION_ID_ASC",
  OrganizationIdDesc = "ORGANIZATION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  TaskIdAsc = "TASK_ID_ASC",
  TaskIdDesc = "TASK_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
  WorkerTypeAsc = "WORKER_TYPE_ASC",
  WorkerTypeDesc = "WORKER_TYPE_DESC",
}

/** The `dataRoom` to be created by this mutation. */
export type FakeDecentriqPlatformDataRoomForeignKey0DataRoomCreateInput = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** Input for the nested mutation of `user` in the `DataRoomInput` mutation. */
export type FakeDecentriqPlatformDataRoomForeignKey0Input = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByUserId?: InputMaybe<UserUserPkeyConnect>;
  /** A `UserInput` object that will be created and connected to this object. */
  create?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0UserCreateInput>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<UserUserEmailKeyDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteById?: InputMaybe<UserNodeIdDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByUserId?: InputMaybe<UserUserPkeyDelete>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0UsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<DataRoomOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0NodeIdUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByUserId?: InputMaybe<UserOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0UsingUserPkeyUpdate>;
};

/** Input for the nested mutation of `dataRoom` in the `UserInput` mutation. */
export type FakeDecentriqPlatformDataRoomForeignKey0InverseInput = {
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomHash?: InputMaybe<
    Array<DataRoomDataRoomDataRoomHashKeyConnect>
  >;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomId?: InputMaybe<Array<DataRoomDataRoomPkeyConnect>>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DataRoomNodeIdConnect>>;
  /** A `DataRoomInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FakeDecentriqPlatformDataRoomForeignKey0DataRoomCreateInput>
  >;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomHash?: InputMaybe<
    Array<DataRoomDataRoomDataRoomHashKeyDelete>
  >;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomId?: InputMaybe<Array<DataRoomDataRoomPkeyDelete>>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DataRoomNodeIdDelete>>;
  /** Flag indicating whether all other `dataRoom` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomHash?: InputMaybe<
    Array<DataRoomOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0UsingDataRoomDataRoomHashKeyUpdate>
  >;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomId?: InputMaybe<
    Array<DataRoomOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0UsingDataRoomPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<UserOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0NodeIdUpdate>
  >;
};

/** The `user` to be created by this mutation. */
export type FakeDecentriqPlatformDataRoomForeignKey0UserCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
  domain?: InputMaybe<Scalars["String"]>;
  domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
  email?: InputMaybe<Scalars["String"]>;
  emailVerified?: InputMaybe<Scalars["Boolean"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
  password?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  upstreamUserId?: InputMaybe<Scalars["String"]>;
  userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
  userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
  userId?: InputMaybe<Scalars["UUID"]>;
  userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
  userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
};

/** The `computeNode` to be created by this mutation. */
export type FakeDecentriqPlatformDatasetLinkForeignKey0ComputeNodeCreateInput =
  {
    computeNodeId?: InputMaybe<Scalars["UUID"]>;
    computeNodeType: ComputeNodeTypeType;
    config: Scalars["JSON"];
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRoom?: InputMaybe<ComputeNodeDataRoomIdFkeyInput>;
    dataRoomId?: InputMaybe<Scalars["UUID"]>;
    datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0InverseInput>;
    index?: InputMaybe<Scalars["Int"]>;
    jobs?: InputMaybe<JobComputeNodeIdFkeyInverseInput>;
    nodeName: Scalars["String"];
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** The `datasetLink` to be created by this mutation. */
export type FakeDecentriqPlatformDatasetLinkForeignKey0DatasetLinkCreateInput =
  {
    allowStatisticsSharing?: InputMaybe<Scalars["Boolean"]>;
    computeNode?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0Input>;
    computeNodeId?: InputMaybe<Scalars["UUID"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    datasetHash?: InputMaybe<Scalars["String"]>;
    datasetLinkId?: InputMaybe<Scalars["UUID"]>;
    datasetMeta?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey1Input>;
    datasetStats?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey2Input>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** Input for the nested mutation of `computeNode` in the `DatasetLinkInput` mutation. */
export type FakeDecentriqPlatformDatasetLinkForeignKey0Input = {
  /** The primary key(s) for `computeNode` for the far side of the relationship. */
  connectByComputeNodeId?: InputMaybe<ComputeNodeComputeNodePkeyConnect>;
  /** The primary key(s) for `computeNode` for the far side of the relationship. */
  connectById?: InputMaybe<ComputeNodeNodeIdConnect>;
  /** A `ComputeNodeInput` object that will be created and connected to this object. */
  create?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0ComputeNodeCreateInput>;
  /** The primary key(s) for `computeNode` for the far side of the relationship. */
  deleteByComputeNodeId?: InputMaybe<ComputeNodeComputeNodePkeyDelete>;
  /** The primary key(s) for `computeNode` for the far side of the relationship. */
  deleteById?: InputMaybe<ComputeNodeNodeIdDelete>;
  /** The primary key(s) and patch data for `computeNode` for the far side of the relationship. */
  updateByComputeNodeId?: InputMaybe<ComputeNodeOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey0UsingComputeNodePkeyUpdate>;
  /** The primary key(s) and patch data for `computeNode` for the far side of the relationship. */
  updateById?: InputMaybe<DatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey0NodeIdUpdate>;
};

/** Input for the nested mutation of `datasetLink` in the `ComputeNodeInput` mutation. */
export type FakeDecentriqPlatformDatasetLinkForeignKey0InverseInput = {
  /** The primary key(s) for `datasetLink` for the far side of the relationship. */
  connectByDatasetLinkId?: InputMaybe<Array<DatasetLinkDatasetLinkPkeyConnect>>;
  /** The primary key(s) for `datasetLink` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DatasetLinkNodeIdConnect>>;
  /** A `DatasetLinkInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FakeDecentriqPlatformDatasetLinkForeignKey0DatasetLinkCreateInput>
  >;
  /** The primary key(s) for `datasetLink` for the far side of the relationship. */
  deleteByDatasetLinkId?: InputMaybe<Array<DatasetLinkDatasetLinkPkeyDelete>>;
  /** The primary key(s) for `datasetLink` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DatasetLinkNodeIdDelete>>;
  /** Flag indicating whether all other `datasetLink` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `datasetLink` for the far side of the relationship. */
  updateByDatasetLinkId?: InputMaybe<
    Array<DatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey0UsingDatasetLinkPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `datasetLink` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ComputeNodeOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey0NodeIdUpdate>
  >;
};

/** The `datasetLink` to be created by this mutation. */
export type FakeDecentriqPlatformDatasetLinkForeignKey1DatasetLinkCreateInput =
  {
    allowStatisticsSharing?: InputMaybe<Scalars["Boolean"]>;
    computeNode?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0Input>;
    computeNodeId?: InputMaybe<Scalars["UUID"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    datasetHash?: InputMaybe<Scalars["String"]>;
    datasetLinkId?: InputMaybe<Scalars["UUID"]>;
    datasetMeta?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey1Input>;
    datasetStats?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey2Input>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** The `datasetMeta` to be created by this mutation. */
export type FakeDecentriqPlatformDatasetLinkForeignKey1DatasetMetaCreateInput =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    datasetHash: Scalars["String"];
    datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey1InverseInput>;
    datasetStats?: InputMaybe<FakeDecentriqPlatformDatasetStatsForeignKey0InverseInput>;
    description?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    ownerEmail?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** Input for the nested mutation of `datasetMeta` in the `DatasetLinkInput` mutation. */
export type FakeDecentriqPlatformDatasetLinkForeignKey1Input = {
  /** The primary key(s) for `datasetMeta` for the far side of the relationship. */
  connectByDatasetHash?: InputMaybe<DatasetMetaDatasetMetaPkeyConnect>;
  /** The primary key(s) for `datasetMeta` for the far side of the relationship. */
  connectById?: InputMaybe<DatasetMetaNodeIdConnect>;
  /** A `DatasetMetaInput` object that will be created and connected to this object. */
  create?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey1DatasetMetaCreateInput>;
  /** The primary key(s) for `datasetMeta` for the far side of the relationship. */
  deleteByDatasetHash?: InputMaybe<DatasetMetaDatasetMetaPkeyDelete>;
  /** The primary key(s) for `datasetMeta` for the far side of the relationship. */
  deleteById?: InputMaybe<DatasetMetaNodeIdDelete>;
  /** The primary key(s) and patch data for `datasetMeta` for the far side of the relationship. */
  updateByDatasetHash?: InputMaybe<DatasetMetaOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey1UsingDatasetMetaPkeyUpdate>;
  /** The primary key(s) and patch data for `datasetMeta` for the far side of the relationship. */
  updateById?: InputMaybe<DatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey1NodeIdUpdate>;
};

/** Input for the nested mutation of `datasetLink` in the `DatasetMetaInput` mutation. */
export type FakeDecentriqPlatformDatasetLinkForeignKey1InverseInput = {
  /** The primary key(s) for `datasetLink` for the far side of the relationship. */
  connectByDatasetLinkId?: InputMaybe<Array<DatasetLinkDatasetLinkPkeyConnect>>;
  /** The primary key(s) for `datasetLink` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DatasetLinkNodeIdConnect>>;
  /** A `DatasetLinkInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FakeDecentriqPlatformDatasetLinkForeignKey1DatasetLinkCreateInput>
  >;
  /** The primary key(s) for `datasetLink` for the far side of the relationship. */
  deleteByDatasetLinkId?: InputMaybe<Array<DatasetLinkDatasetLinkPkeyDelete>>;
  /** The primary key(s) for `datasetLink` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DatasetLinkNodeIdDelete>>;
  /** Flag indicating whether all other `datasetLink` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `datasetLink` for the far side of the relationship. */
  updateByDatasetLinkId?: InputMaybe<
    Array<DatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey1UsingDatasetLinkPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `datasetLink` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetMetaOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey1NodeIdUpdate>
  >;
};

/** The `datasetLink` to be created by this mutation. */
export type FakeDecentriqPlatformDatasetLinkForeignKey2DatasetLinkCreateInput =
  {
    allowStatisticsSharing?: InputMaybe<Scalars["Boolean"]>;
    computeNode?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0Input>;
    computeNodeId?: InputMaybe<Scalars["UUID"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    datasetHash?: InputMaybe<Scalars["String"]>;
    datasetLinkId?: InputMaybe<Scalars["UUID"]>;
    datasetMeta?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey1Input>;
    datasetStats?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey2Input>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** The `datasetStat` to be created by this mutation. */
export type FakeDecentriqPlatformDatasetLinkForeignKey2DatasetStatsCreateInput =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    datasetHash?: InputMaybe<Scalars["String"]>;
    datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey2InverseInput>;
    datasetMeta?: InputMaybe<FakeDecentriqPlatformDatasetStatsForeignKey0Input>;
    fileSize?: InputMaybe<Scalars["Int"]>;
    fillRatio?: InputMaybe<Scalars["Float"]>;
    numberOfDuplicateRows?: InputMaybe<Scalars["Int"]>;
    numberOfNotNullableRows?: InputMaybe<Scalars["Int"]>;
    numberOfRows?: InputMaybe<Scalars["Int"]>;
    ownerEmail?: InputMaybe<Scalars["String"]>;
    statistics?: InputMaybe<Scalars["JSON"]>;
    statisticsVersion?: InputMaybe<Scalars["Int"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** Input for the nested mutation of `datasetStat` in the `DatasetLinkInput` mutation. */
export type FakeDecentriqPlatformDatasetLinkForeignKey2Input = {
  /** The primary key(s) for `datasetStat` for the far side of the relationship. */
  connectByDatasetHash?: InputMaybe<DatasetStatDatasetStatsPkeyConnect>;
  /** The primary key(s) for `datasetStat` for the far side of the relationship. */
  connectById?: InputMaybe<DatasetStatNodeIdConnect>;
  /** A `DatasetStatInput` object that will be created and connected to this object. */
  create?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey2DatasetStatsCreateInput>;
  /** The primary key(s) for `datasetStat` for the far side of the relationship. */
  deleteByDatasetHash?: InputMaybe<DatasetStatDatasetStatsPkeyDelete>;
  /** The primary key(s) for `datasetStat` for the far side of the relationship. */
  deleteById?: InputMaybe<DatasetStatNodeIdDelete>;
  /** The primary key(s) and patch data for `datasetStat` for the far side of the relationship. */
  updateByDatasetHash?: InputMaybe<DatasetStatOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey2UsingDatasetStatsPkeyUpdate>;
  /** The primary key(s) and patch data for `datasetStat` for the far side of the relationship. */
  updateById?: InputMaybe<DatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey2NodeIdUpdate>;
};

/** Input for the nested mutation of `datasetLink` in the `DatasetStatInput` mutation. */
export type FakeDecentriqPlatformDatasetLinkForeignKey2InverseInput = {
  /** The primary key(s) for `datasetLink` for the far side of the relationship. */
  connectByDatasetLinkId?: InputMaybe<Array<DatasetLinkDatasetLinkPkeyConnect>>;
  /** The primary key(s) for `datasetLink` for the far side of the relationship. */
  connectById?: InputMaybe<Array<DatasetLinkNodeIdConnect>>;
  /** A `DatasetLinkInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FakeDecentriqPlatformDatasetLinkForeignKey2DatasetLinkCreateInput>
  >;
  /** The primary key(s) for `datasetLink` for the far side of the relationship. */
  deleteByDatasetLinkId?: InputMaybe<Array<DatasetLinkDatasetLinkPkeyDelete>>;
  /** The primary key(s) for `datasetLink` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<DatasetLinkNodeIdDelete>>;
  /** Flag indicating whether all other `datasetLink` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `datasetLink` for the far side of the relationship. */
  updateByDatasetLinkId?: InputMaybe<
    Array<DatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey2UsingDatasetLinkPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `datasetLink` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DatasetStatOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey2NodeIdUpdate>
  >;
};

/** The `datasetMeta` to be created by this mutation. */
export type FakeDecentriqPlatformDatasetStatsForeignKey0DatasetMetaCreateInput =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    datasetHash: Scalars["String"];
    datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey1InverseInput>;
    datasetStats?: InputMaybe<FakeDecentriqPlatformDatasetStatsForeignKey0InverseInput>;
    description?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    ownerEmail?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** The `datasetStat` to be created by this mutation. */
export type FakeDecentriqPlatformDatasetStatsForeignKey0DatasetStatsCreateInput =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    datasetHash?: InputMaybe<Scalars["String"]>;
    datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey2InverseInput>;
    datasetMeta?: InputMaybe<FakeDecentriqPlatformDatasetStatsForeignKey0Input>;
    fileSize?: InputMaybe<Scalars["Int"]>;
    fillRatio?: InputMaybe<Scalars["Float"]>;
    numberOfDuplicateRows?: InputMaybe<Scalars["Int"]>;
    numberOfNotNullableRows?: InputMaybe<Scalars["Int"]>;
    numberOfRows?: InputMaybe<Scalars["Int"]>;
    ownerEmail?: InputMaybe<Scalars["String"]>;
    statistics?: InputMaybe<Scalars["JSON"]>;
    statisticsVersion?: InputMaybe<Scalars["Int"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** Input for the nested mutation of `datasetMeta` in the `DatasetStatInput` mutation. */
export type FakeDecentriqPlatformDatasetStatsForeignKey0Input = {
  /** The primary key(s) for `datasetMeta` for the far side of the relationship. */
  connectByDatasetHash?: InputMaybe<DatasetMetaDatasetMetaPkeyConnect>;
  /** The primary key(s) for `datasetMeta` for the far side of the relationship. */
  connectById?: InputMaybe<DatasetMetaNodeIdConnect>;
  /** A `DatasetMetaInput` object that will be created and connected to this object. */
  create?: InputMaybe<FakeDecentriqPlatformDatasetStatsForeignKey0DatasetMetaCreateInput>;
  /** The primary key(s) for `datasetMeta` for the far side of the relationship. */
  deleteByDatasetHash?: InputMaybe<DatasetMetaDatasetMetaPkeyDelete>;
  /** The primary key(s) for `datasetMeta` for the far side of the relationship. */
  deleteById?: InputMaybe<DatasetMetaNodeIdDelete>;
  /** The primary key(s) and patch data for `datasetMeta` for the far side of the relationship. */
  updateByDatasetHash?: InputMaybe<DatasetMetaOnDatasetStatForFakeDecentriqPlatformDatasetStatsForeignKey0UsingDatasetMetaPkeyUpdate>;
  /** The primary key(s) and patch data for `datasetMeta` for the far side of the relationship. */
  updateById?: InputMaybe<DatasetStatOnDatasetStatForFakeDecentriqPlatformDatasetStatsForeignKey0NodeIdUpdate>;
};

/** Input for the nested mutation of `datasetStat` in the `DatasetMetaInput` mutation. */
export type FakeDecentriqPlatformDatasetStatsForeignKey0InverseInput = {
  /** The primary key(s) for `datasetStat` for the far side of the relationship. */
  connectByDatasetHash?: InputMaybe<DatasetStatDatasetStatsPkeyConnect>;
  /** The primary key(s) for `datasetStat` for the far side of the relationship. */
  connectById?: InputMaybe<DatasetStatNodeIdConnect>;
  /** A `DatasetStatInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FakeDecentriqPlatformDatasetStatsForeignKey0DatasetStatsCreateInput>
  >;
  /** The primary key(s) for `datasetStat` for the far side of the relationship. */
  deleteByDatasetHash?: InputMaybe<DatasetStatDatasetStatsPkeyDelete>;
  /** The primary key(s) for `datasetStat` for the far side of the relationship. */
  deleteById?: InputMaybe<DatasetStatNodeIdDelete>;
  /** Flag indicating whether all other `datasetStat` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `datasetStat` for the far side of the relationship. */
  updateByDatasetHash?: InputMaybe<DatasetStatOnDatasetStatForFakeDecentriqPlatformDatasetStatsForeignKey0UsingDatasetStatsPkeyUpdate>;
  /** The primary key(s) and patch data for `datasetStat` for the far side of the relationship. */
  updateById?: InputMaybe<DatasetMetaOnDatasetStatForFakeDecentriqPlatformDatasetStatsForeignKey0NodeIdUpdate>;
};

/** The `domain` to be created by this mutation. */
export type FakeDecentriqPlatformDomainForeignKey0DomainCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  domain?: InputMaybe<Scalars["String"]>;
  domainId?: InputMaybe<Scalars["UUID"]>;
  organization?: InputMaybe<DomainOrganizationIdFkeyInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0Input>;
};

/** Input for the nested mutation of `user` in the `DomainInput` mutation. */
export type FakeDecentriqPlatformDomainForeignKey0Input = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByUserId?: InputMaybe<UserUserPkeyConnect>;
  /** A `UserInput` object that will be created and connected to this object. */
  create?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0UserCreateInput>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<UserUserEmailKeyDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteById?: InputMaybe<UserNodeIdDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByUserId?: InputMaybe<UserUserPkeyDelete>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnDomainForFakeDecentriqPlatformDomainForeignKey0UsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<DomainOnDomainForFakeDecentriqPlatformDomainForeignKey0NodeIdUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByUserId?: InputMaybe<UserOnDomainForFakeDecentriqPlatformDomainForeignKey0UsingUserPkeyUpdate>;
};

/** Input for the nested mutation of `domain` in the `UserInput` mutation. */
export type FakeDecentriqPlatformDomainForeignKey0InverseInput = {
  /** The primary key(s) for `domain` for the far side of the relationship. */
  connectByDomain?: InputMaybe<DomainDomainDomainKeyConnect>;
  /** The primary key(s) for `domain` for the far side of the relationship. */
  connectByDomainId?: InputMaybe<DomainDomainPkeyConnect>;
  /** The primary key(s) for `domain` for the far side of the relationship. */
  connectById?: InputMaybe<DomainNodeIdConnect>;
  /** A `DomainInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FakeDecentriqPlatformDomainForeignKey0DomainCreateInput>
  >;
  /** The primary key(s) for `domain` for the far side of the relationship. */
  deleteByDomain?: InputMaybe<DomainDomainDomainKeyDelete>;
  /** The primary key(s) for `domain` for the far side of the relationship. */
  deleteByDomainId?: InputMaybe<DomainDomainPkeyDelete>;
  /** The primary key(s) for `domain` for the far side of the relationship. */
  deleteById?: InputMaybe<DomainNodeIdDelete>;
  /** Flag indicating whether all other `domain` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `domain` for the far side of the relationship. */
  updateByDomain?: InputMaybe<DomainOnDomainForFakeDecentriqPlatformDomainForeignKey0UsingDomainDomainKeyUpdate>;
  /** The primary key(s) and patch data for `domain` for the far side of the relationship. */
  updateByDomainId?: InputMaybe<DomainOnDomainForFakeDecentriqPlatformDomainForeignKey0UsingDomainPkeyUpdate>;
  /** The primary key(s) and patch data for `domain` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnDomainForFakeDecentriqPlatformDomainForeignKey0NodeIdUpdate>;
};

/** The `user` to be created by this mutation. */
export type FakeDecentriqPlatformDomainForeignKey0UserCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
  domain?: InputMaybe<Scalars["String"]>;
  domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
  email?: InputMaybe<Scalars["String"]>;
  emailVerified?: InputMaybe<Scalars["Boolean"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
  password?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  upstreamUserId?: InputMaybe<Scalars["String"]>;
  userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
  userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
  userId?: InputMaybe<Scalars["UUID"]>;
  userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
  userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
};

/** Input for the nested mutation of `user` in the `UserConfigInput` mutation. */
export type FakeDecentriqPlatformUserConfigForeignKey0Input = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByUserId?: InputMaybe<UserUserPkeyConnect>;
  /** A `UserInput` object that will be created and connected to this object. */
  create?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0UserCreateInput>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<UserUserEmailKeyDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteById?: InputMaybe<UserNodeIdDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByUserId?: InputMaybe<UserUserPkeyDelete>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0UsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserConfigOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0NodeIdUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByUserId?: InputMaybe<UserOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0UsingUserPkeyUpdate>;
};

/** Input for the nested mutation of `userConfig` in the `UserInput` mutation. */
export type FakeDecentriqPlatformUserConfigForeignKey0InverseInput = {
  /** The primary key(s) for `userConfig` for the far side of the relationship. */
  connectByDataRoomIdAndEmail?: InputMaybe<
    Array<UserConfigUserConfigDataRoomIdEmailKeyConnect>
  >;
  /** The primary key(s) for `userConfig` for the far side of the relationship. */
  connectById?: InputMaybe<Array<UserConfigNodeIdConnect>>;
  /** The primary key(s) for `userConfig` for the far side of the relationship. */
  connectByUserConfigId?: InputMaybe<Array<UserConfigUserConfigPkeyConnect>>;
  /** A `UserConfigInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FakeDecentriqPlatformUserConfigForeignKey0UserConfigCreateInput>
  >;
  /** The primary key(s) for `userConfig` for the far side of the relationship. */
  deleteByDataRoomIdAndEmail?: InputMaybe<
    Array<UserConfigUserConfigDataRoomIdEmailKeyDelete>
  >;
  /** The primary key(s) for `userConfig` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<UserConfigNodeIdDelete>>;
  /** The primary key(s) for `userConfig` for the far side of the relationship. */
  deleteByUserConfigId?: InputMaybe<Array<UserConfigUserConfigPkeyDelete>>;
  /** Flag indicating whether all other `userConfig` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `userConfig` for the far side of the relationship. */
  updateByDataRoomIdAndEmail?: InputMaybe<
    Array<UserConfigOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0UsingUserConfigDataRoomIdEmailKeyUpdate>
  >;
  /** The primary key(s) and patch data for `userConfig` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<UserOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0NodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `userConfig` for the far side of the relationship. */
  updateByUserConfigId?: InputMaybe<
    Array<UserConfigOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0UsingUserConfigPkeyUpdate>
  >;
};

/** The `userConfig` to be created by this mutation. */
export type FakeDecentriqPlatformUserConfigForeignKey0UserConfigCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<UserConfigDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  email?: InputMaybe<Scalars["String"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]>;
  isFavorite?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByEmail?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0Input>;
  userConfigId?: InputMaybe<Scalars["UUID"]>;
};

/** The `user` to be created by this mutation. */
export type FakeDecentriqPlatformUserConfigForeignKey0UserCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
  domain?: InputMaybe<Scalars["String"]>;
  domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
  email?: InputMaybe<Scalars["String"]>;
  emailVerified?: InputMaybe<Scalars["Boolean"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
  password?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  upstreamUserId?: InputMaybe<Scalars["String"]>;
  userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
  userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
  userId?: InputMaybe<Scalars["UUID"]>;
  userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
  userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
};

/** Input for the nested mutation of `user` in the `UserPermissionInput` mutation. */
export type FakeDecentriqPlatformUserPermissionForeignKey0Input = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByUserId?: InputMaybe<UserUserPkeyConnect>;
  /** A `UserInput` object that will be created and connected to this object. */
  create?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0UserCreateInput>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<UserUserEmailKeyDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteById?: InputMaybe<UserNodeIdDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByUserId?: InputMaybe<UserUserPkeyDelete>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0UsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserPermissionOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0NodeIdUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByUserId?: InputMaybe<UserOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0UsingUserPkeyUpdate>;
};

/** Input for the nested mutation of `userPermission` in the `UserInput` mutation. */
export type FakeDecentriqPlatformUserPermissionForeignKey0InverseInput = {
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  connectByDataRoomIdAndEmail?: InputMaybe<
    Array<UserPermissionUserPermissionDataRoomIdEmailKeyConnect>
  >;
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  connectById?: InputMaybe<Array<UserPermissionNodeIdConnect>>;
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  connectByUserPermissionId?: InputMaybe<
    Array<UserPermissionUserPermissionPkeyConnect>
  >;
  /** A `UserPermissionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FakeDecentriqPlatformUserPermissionForeignKey0UserPermissionCreateInput>
  >;
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  deleteByDataRoomIdAndEmail?: InputMaybe<
    Array<UserPermissionUserPermissionDataRoomIdEmailKeyDelete>
  >;
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<UserPermissionNodeIdDelete>>;
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  deleteByUserPermissionId?: InputMaybe<
    Array<UserPermissionUserPermissionPkeyDelete>
  >;
  /** Flag indicating whether all other `userPermission` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `userPermission` for the far side of the relationship. */
  updateByDataRoomIdAndEmail?: InputMaybe<
    Array<UserPermissionOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0UsingUserPermissionDataRoomIdEmailKeyUpdate>
  >;
  /** The primary key(s) and patch data for `userPermission` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<UserOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0NodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `userPermission` for the far side of the relationship. */
  updateByUserPermissionId?: InputMaybe<
    Array<UserPermissionOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0UsingUserPermissionPkeyUpdate>
  >;
};

/** The `user` to be created by this mutation. */
export type FakeDecentriqPlatformUserPermissionForeignKey0UserCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
  domain?: InputMaybe<Scalars["String"]>;
  domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
  email?: InputMaybe<Scalars["String"]>;
  emailVerified?: InputMaybe<Scalars["Boolean"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
  password?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  upstreamUserId?: InputMaybe<Scalars["String"]>;
  userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
  userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
  userId?: InputMaybe<Scalars["UUID"]>;
  userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
  userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
};

/** The `userPermission` to be created by this mutation. */
export type FakeDecentriqPlatformUserPermissionForeignKey0UserPermissionCreateInput =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRoom?: InputMaybe<UserPermissionDataRoomIdFkeyInput>;
    dataRoomId?: InputMaybe<Scalars["UUID"]>;
    email?: InputMaybe<Scalars["String"]>;
    permissions?: InputMaybe<PermissionUserPermissionIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userByEmail?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0Input>;
    userPermissionId?: InputMaybe<Scalars["UUID"]>;
  };

/** Input for the nested mutation of `user` in the `UserQuotaInput` mutation. */
export type FakeDecentriqPlatformUserQuotaForeignKey0Input = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByUserId?: InputMaybe<UserUserPkeyConnect>;
  /** A `UserInput` object that will be created and connected to this object. */
  create?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0UserCreateInput>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<UserUserEmailKeyDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteById?: InputMaybe<UserNodeIdDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByUserId?: InputMaybe<UserUserPkeyDelete>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnUserQuotaForFakeDecentriqPlatformUserQuotaForeignKey0UsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserQuotaOnUserQuotaForFakeDecentriqPlatformUserQuotaForeignKey0NodeIdUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByUserId?: InputMaybe<UserOnUserQuotaForFakeDecentriqPlatformUserQuotaForeignKey0UsingUserPkeyUpdate>;
};

/** Input for the nested mutation of `userQuota` in the `UserInput` mutation. */
export type FakeDecentriqPlatformUserQuotaForeignKey0InverseInput = {
  /** The primary key(s) for `userQuota` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserQuotaUserQuotaPkeyConnect>;
  /** The primary key(s) for `userQuota` for the far side of the relationship. */
  connectById?: InputMaybe<UserQuotaNodeIdConnect>;
  /** A `UserQuotaInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<FakeDecentriqPlatformUserQuotaForeignKey0UserQuotaCreateInput>
  >;
  /** The primary key(s) for `userQuota` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<UserQuotaUserQuotaPkeyDelete>;
  /** The primary key(s) for `userQuota` for the far side of the relationship. */
  deleteById?: InputMaybe<UserQuotaNodeIdDelete>;
  /** Flag indicating whether all other `userQuota` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `userQuota` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserQuotaOnUserQuotaForFakeDecentriqPlatformUserQuotaForeignKey0UsingUserQuotaPkeyUpdate>;
  /** The primary key(s) and patch data for `userQuota` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnUserQuotaForFakeDecentriqPlatformUserQuotaForeignKey0NodeIdUpdate>;
};

/** The `user` to be created by this mutation. */
export type FakeDecentriqPlatformUserQuotaForeignKey0UserCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
  domain?: InputMaybe<Scalars["String"]>;
  domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
  email?: InputMaybe<Scalars["String"]>;
  emailVerified?: InputMaybe<Scalars["Boolean"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
  password?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  upstreamUserId?: InputMaybe<Scalars["String"]>;
  userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
  userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
  userId?: InputMaybe<Scalars["UUID"]>;
  userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
  userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
};

/** The `userQuota` to be created by this mutation. */
export type FakeDecentriqPlatformUserQuotaForeignKey0UserQuotaCreateInput = {
  allowed?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByEmail?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0Input>;
};

/** A filter to be used against Float fields. All fields are combined with a logical ‘and.’ */
export type FloatFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Float"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Float"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Float"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Float"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Float"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Float"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Float"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Float"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Float"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Float"]>>;
};

export type HavingDatetimeFilter = {
  equalTo?: InputMaybe<Scalars["Datetime"]>;
  greaterThan?: InputMaybe<Scalars["Datetime"]>;
  greaterThanOrEqualTo?: InputMaybe<Scalars["Datetime"]>;
  lessThan?: InputMaybe<Scalars["Datetime"]>;
  lessThanOrEqualTo?: InputMaybe<Scalars["Datetime"]>;
  notEqualTo?: InputMaybe<Scalars["Datetime"]>;
};

export type HavingFloatFilter = {
  equalTo?: InputMaybe<Scalars["Float"]>;
  greaterThan?: InputMaybe<Scalars["Float"]>;
  greaterThanOrEqualTo?: InputMaybe<Scalars["Float"]>;
  lessThan?: InputMaybe<Scalars["Float"]>;
  lessThanOrEqualTo?: InputMaybe<Scalars["Float"]>;
  notEqualTo?: InputMaybe<Scalars["Float"]>;
};

export type HavingIntFilter = {
  equalTo?: InputMaybe<Scalars["Int"]>;
  greaterThan?: InputMaybe<Scalars["Int"]>;
  greaterThanOrEqualTo?: InputMaybe<Scalars["Int"]>;
  lessThan?: InputMaybe<Scalars["Int"]>;
  lessThanOrEqualTo?: InputMaybe<Scalars["Int"]>;
  notEqualTo?: InputMaybe<Scalars["Int"]>;
};

/** A filter to be used against Int fields. All fields are combined with a logical ‘and.’ */
export type IntFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["Int"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["Int"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["Int"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["Int"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["Int"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["Int"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["Int"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["Int"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["Int"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["Int"]>>;
};

/** A filter to be used against JSON fields. All fields are combined with a logical ‘and.’ */
export type JsonFilter = {
  /** Contained by the specified JSON. */
  containedBy?: InputMaybe<Scalars["JSON"]>;
  /** Contains the specified JSON. */
  contains?: InputMaybe<Scalars["JSON"]>;
  /** Contains all of the specified keys. */
  containsAllKeys?: InputMaybe<Array<Scalars["String"]>>;
  /** Contains any of the specified keys. */
  containsAnyKeys?: InputMaybe<Array<Scalars["String"]>>;
  /** Checks whether the JSON path returns any item for the specified JSON value. */
  containsJsonpath?: InputMaybe<Scalars["String"]>;
  /** Contains the specified key. */
  containsKey?: InputMaybe<Scalars["String"]>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["JSON"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["JSON"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["JSON"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["JSON"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["JSON"]>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["JSON"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["JSON"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["JSON"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["JSON"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["JSON"]>>;
};

export type Job = Node & {
  __typename?: "Job";
  /** Reads a single `ComputeNode` that is related to this `Job`. */
  computeNode?: Maybe<ComputeNode>;
  computeNodeId: Scalars["UUID"];
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  isSending?: Maybe<Scalars["Boolean"]>;
  jobId?: Maybe<Scalars["String"]>;
  jobStartedAt?: Maybe<Scalars["Datetime"]>;
  ownerEmail: Scalars["String"];
  result?: Maybe<Scalars["String"]>;
  resultAsFile?: Maybe<ResultAsFile>;
  status?: Maybe<JobStatus>;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type JobAggregates = {
  __typename?: "JobAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<JobDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A filter to be used against aggregates of `Job` object types. */
export type JobAggregatesFilter = {
  /** Distinct count aggregate over matching `Job` objects. */
  distinctCount?: InputMaybe<JobDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Job` object to be included within the aggregate. */
  filter?: InputMaybe<JobFilter>;
};

/** The `computeNode` to be created by this mutation. */
export type JobComputeNodeIdFkeyComputeNodeCreateInput = {
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  computeNodeType: ComputeNodeTypeType;
  config: Scalars["JSON"];
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<ComputeNodeDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0InverseInput>;
  index?: InputMaybe<Scalars["Int"]>;
  jobs?: InputMaybe<JobComputeNodeIdFkeyInverseInput>;
  nodeName: Scalars["String"];
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** Input for the nested mutation of `computeNode` in the `JobInput` mutation. */
export type JobComputeNodeIdFkeyInput = {
  /** The primary key(s) for `computeNode` for the far side of the relationship. */
  connectByComputeNodeId?: InputMaybe<ComputeNodeComputeNodePkeyConnect>;
  /** The primary key(s) for `computeNode` for the far side of the relationship. */
  connectById?: InputMaybe<ComputeNodeNodeIdConnect>;
  /** A `ComputeNodeInput` object that will be created and connected to this object. */
  create?: InputMaybe<JobComputeNodeIdFkeyComputeNodeCreateInput>;
  /** The primary key(s) for `computeNode` for the far side of the relationship. */
  deleteByComputeNodeId?: InputMaybe<ComputeNodeComputeNodePkeyDelete>;
  /** The primary key(s) for `computeNode` for the far side of the relationship. */
  deleteById?: InputMaybe<ComputeNodeNodeIdDelete>;
  /** The primary key(s) and patch data for `computeNode` for the far side of the relationship. */
  updateByComputeNodeId?: InputMaybe<ComputeNodeOnJobForJobComputeNodeIdFkeyUsingComputeNodePkeyUpdate>;
  /** The primary key(s) and patch data for `computeNode` for the far side of the relationship. */
  updateById?: InputMaybe<JobOnJobForJobComputeNodeIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `job` in the `ComputeNodeInput` mutation. */
export type JobComputeNodeIdFkeyInverseInput = {
  /** The primary key(s) for `job` for the far side of the relationship. */
  connectByComputeNodeIdAndOwnerEmail?: InputMaybe<Array<JobJobPkeyConnect>>;
  /** The primary key(s) for `job` for the far side of the relationship. */
  connectById?: InputMaybe<Array<JobNodeIdConnect>>;
  /** A `JobInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<JobComputeNodeIdFkeyJobCreateInput>>;
  /** The primary key(s) for `job` for the far side of the relationship. */
  deleteByComputeNodeIdAndOwnerEmail?: InputMaybe<Array<JobJobPkeyDelete>>;
  /** The primary key(s) for `job` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<JobNodeIdDelete>>;
  /** Flag indicating whether all other `job` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `job` for the far side of the relationship. */
  updateByComputeNodeIdAndOwnerEmail?: InputMaybe<
    Array<JobOnJobForJobComputeNodeIdFkeyUsingJobPkeyUpdate>
  >;
  /** The primary key(s) and patch data for `job` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ComputeNodeOnJobForJobComputeNodeIdFkeyNodeIdUpdate>
  >;
};

/** The `job` to be created by this mutation. */
export type JobComputeNodeIdFkeyJobCreateInput = {
  computeNode?: InputMaybe<JobComputeNodeIdFkeyInput>;
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  jobId?: InputMaybe<Scalars["String"]>;
  jobStartedAt?: InputMaybe<Scalars["Datetime"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** A condition to be used against `Job` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type JobCondition = {
  /** Checks for equality with the object’s `computeNodeId` field. */
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `jobId` field. */
  jobId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `jobStartedAt` field. */
  jobStartedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `ownerEmail` field. */
  ownerEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type JobDistinct = {
  __typename?: "JobDistinct";
  /** Distinct `computeNodeId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  computeNodeId: Array<Scalars["UUID"]>;
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `jobId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  jobId: Array<Maybe<Scalars["String"]>>;
  /** Distinct `jobStartedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  jobStartedAt: Array<Maybe<Scalars["Datetime"]>>;
  /** Distinct `ownerEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  ownerEmail: Array<Scalars["String"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type JobDistinctCountAggregateFilter = {
  computeNodeId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  createdByEmail?: InputMaybe<BigIntFilter>;
  jobId?: InputMaybe<BigIntFilter>;
  jobStartedAt?: InputMaybe<BigIntFilter>;
  ownerEmail?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  updatedByEmail?: InputMaybe<BigIntFilter>;
};

export type JobDistinctCountAggregates = {
  __typename?: "JobDistinctCountAggregates";
  /** Distinct count of computeNodeId across the matching connection */
  computeNodeId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of jobId across the matching connection */
  jobId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of jobStartedAt across the matching connection */
  jobStartedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of ownerEmail across the matching connection */
  ownerEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `Job` object types. All fields are combined with a logical ‘and.’ */
export type JobFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<JobFilter>>;
  /** Filter by the object’s `computeNode` relation. */
  computeNode?: InputMaybe<ComputeNodeFilter>;
  /** Filter by the object’s `computeNodeId` field. */
  computeNodeId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `jobId` field. */
  jobId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `jobStartedAt` field. */
  jobStartedAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<JobFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<JobFilter>>;
  /** Filter by the object’s `ownerEmail` field. */
  ownerEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type JobGroup = {
  __typename?: "JobGroup";
  /** `JobFilter` that was used to implicitly filter `jobs`. */
  filter?: Maybe<Scalars["JSON"]>;
  /**
   * Reads and enables pagination through a set of `Job` of this `JobGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `JobGroup`. Overriding these may result in the wrong set of `Job` returned.
   */
  jobs?: Maybe<JobsConnection>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** The count of _all_ `Job` you could get from the `JobGroup`. */
  totalCount: Scalars["Int"];
};

export type JobGroupJobsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<JobCondition>;
  filter?: InputMaybe<JobFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<JobsOrderBy>>;
};

/** Grouping methods for `Job` for usage during aggregation. */
export enum JobGroupBy {
  ComputeNodeByComputeNodeIdComputationType = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTATION_TYPE",
  ComputeNodeByComputeNodeIdComputeNodeId = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTE_NODE_ID",
  ComputeNodeByComputeNodeIdComputeNodeType = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTE_NODE_TYPE",
  ComputeNodeByComputeNodeIdConfig = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CONFIG",
  ComputeNodeByComputeNodeIdCreatedAt = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CREATED_AT",
  ComputeNodeByComputeNodeIdCreatedByEmail = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CREATED_BY_EMAIL",
  ComputeNodeByComputeNodeIdDataRoomId = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_DATA_ROOM_ID",
  ComputeNodeByComputeNodeIdIndex = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_INDEX",
  ComputeNodeByComputeNodeIdNodeName = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_NODE_NAME",
  ComputeNodeByComputeNodeIdUpdatedAt = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_UPDATED_AT",
  ComputeNodeByComputeNodeIdUpdatedByEmail = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_UPDATED_BY_EMAIL",
  ComputeNodeId = "COMPUTE_NODE_ID",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  JobId = "JOB_ID",
  JobStartedAt = "JOB_STARTED_AT",
  JobStartedAtTruncatedToDay = "JOB_STARTED_AT_TRUNCATED_TO_DAY",
  JobStartedAtTruncatedToHour = "JOB_STARTED_AT_TRUNCATED_TO_HOUR",
  OwnerEmail = "OWNER_EMAIL",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type JobHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  jobStartedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type JobHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  jobStartedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Job` aggregates. */
export type JobHavingInput = {
  AND?: InputMaybe<Array<JobHavingInput>>;
  OR?: InputMaybe<Array<JobHavingInput>>;
  average?: InputMaybe<JobHavingAverageInput>;
  distinctCount?: InputMaybe<JobHavingDistinctCountInput>;
  max?: InputMaybe<JobHavingMaxInput>;
  min?: InputMaybe<JobHavingMinInput>;
  stddevPopulation?: InputMaybe<JobHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<JobHavingStddevSampleInput>;
  sum?: InputMaybe<JobHavingSumInput>;
  variancePopulation?: InputMaybe<JobHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<JobHavingVarianceSampleInput>;
};

export type JobHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  jobStartedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type JobHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  jobStartedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type JobHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  jobStartedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type JobHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  jobStartedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type JobHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  jobStartedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type JobHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  jobStartedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type JobHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  jobStartedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Job` */
export type JobInput = {
  computeNode?: InputMaybe<JobComputeNodeIdFkeyInput>;
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  jobId?: InputMaybe<Scalars["String"]>;
  jobStartedAt?: InputMaybe<Scalars["Datetime"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The fields on `job` to look up the row to connect. */
export type JobJobPkeyConnect = {
  computeNodeId: Scalars["UUID"];
  ownerEmail: Scalars["String"];
};

/** The fields on `job` to look up the row to delete. */
export type JobJobPkeyDelete = {
  computeNodeId: Scalars["UUID"];
  ownerEmail: Scalars["String"];
};

/** The globally unique `ID` look up for the row to connect. */
export type JobNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `job` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type JobNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `job` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type JobOnJobForJobComputeNodeIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `computeNode` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `computeNode` being updated. */
  patch: ComputeNodePatch;
};

/** The fields on `job` to look up the row to update. */
export type JobOnJobForJobComputeNodeIdFkeyUsingJobPkeyUpdate = {
  computeNodeId: Scalars["UUID"];
  ownerEmail: Scalars["String"];
  /** An object where the defined keys will be set on the `job` being updated. */
  patch: UpdateJobOnJobForJobComputeNodeIdFkeyPatch;
};

/** Represents an update to a `Job`. Fields that are set will be updated. */
export type JobPatch = {
  computeNode?: InputMaybe<JobComputeNodeIdFkeyInput>;
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  jobId?: InputMaybe<Scalars["String"]>;
  jobStartedAt?: InputMaybe<Scalars["Datetime"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export enum JobStatus {
  Canceled = "CANCELED",
  Finished = "FINISHED",
  Running = "RUNNING",
}

/** A connection to a list of `Job` values. */
export type JobsConnection = {
  __typename?: "JobsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<JobAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<JobDistinct>;
  /** A list of edges which contains the `Job` and cursor to aid in pagination. */
  edges: Array<JobsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<JobAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<JobGroup>>;
  /** A list of `Job` objects. */
  nodes: Array<Maybe<Job>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Job` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `Job` values. */
export type JobsConnectionGroupedAggregatesArgs = {
  groupBy: Array<JobGroupBy>;
  having?: InputMaybe<JobHavingInput>;
};

/** A connection to a list of `Job` values. */
export type JobsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<JobGroupBy>;
  having?: InputMaybe<JobHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `Job` edge in the connection. */
export type JobsEdge = {
  __typename?: "JobsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `Job` at the end of the edge. */
  node?: Maybe<Job>;
};

/** Methods to use when ordering `Job`. */
export enum JobsOrderBy {
  ComputeNodeByComputeNodeIdComputationTypeAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTATION_TYPE_ASC",
  ComputeNodeByComputeNodeIdComputationTypeDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTATION_TYPE_DESC",
  ComputeNodeByComputeNodeIdComputeNodeIdAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTE_NODE_ID_ASC",
  ComputeNodeByComputeNodeIdComputeNodeIdDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTE_NODE_ID_DESC",
  ComputeNodeByComputeNodeIdComputeNodeTypeAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTE_NODE_TYPE_ASC",
  ComputeNodeByComputeNodeIdComputeNodeTypeDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_COMPUTE_NODE_TYPE_DESC",
  ComputeNodeByComputeNodeIdConfigAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CONFIG_ASC",
  ComputeNodeByComputeNodeIdConfigDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CONFIG_DESC",
  ComputeNodeByComputeNodeIdCreatedAtAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CREATED_AT_ASC",
  ComputeNodeByComputeNodeIdCreatedAtDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CREATED_AT_DESC",
  ComputeNodeByComputeNodeIdCreatedByEmailAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CREATED_BY_EMAIL_ASC",
  ComputeNodeByComputeNodeIdCreatedByEmailDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_CREATED_BY_EMAIL_DESC",
  ComputeNodeByComputeNodeIdDataRoomIdAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_DATA_ROOM_ID_ASC",
  ComputeNodeByComputeNodeIdDataRoomIdDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_DATA_ROOM_ID_DESC",
  ComputeNodeByComputeNodeIdIndexAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_INDEX_ASC",
  ComputeNodeByComputeNodeIdIndexDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_INDEX_DESC",
  ComputeNodeByComputeNodeIdNodeNameAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_NODE_NAME_ASC",
  ComputeNodeByComputeNodeIdNodeNameDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_NODE_NAME_DESC",
  ComputeNodeByComputeNodeIdUpdatedAtAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_UPDATED_AT_ASC",
  ComputeNodeByComputeNodeIdUpdatedAtDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_UPDATED_AT_DESC",
  ComputeNodeByComputeNodeIdUpdatedByEmailAsc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_UPDATED_BY_EMAIL_ASC",
  ComputeNodeByComputeNodeIdUpdatedByEmailDesc = "COMPUTE_NODE_BY_COMPUTE_NODE_ID_UPDATED_BY_EMAIL_DESC",
  ComputeNodeIdAsc = "COMPUTE_NODE_ID_ASC",
  ComputeNodeIdDesc = "COMPUTE_NODE_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  JobIdAsc = "JOB_ID_ASC",
  JobIdDesc = "JOB_ID_DESC",
  JobStartedAtAsc = "JOB_STARTED_AT_ASC",
  JobStartedAtDesc = "JOB_STARTED_AT_DESC",
  Natural = "NATURAL",
  OwnerEmailAsc = "OWNER_EMAIL_ASC",
  OwnerEmailDesc = "OWNER_EMAIL_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

export type Lock = Node & {
  __typename?: "Lock";
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** Reads a single `DataRoom` that is related to this `Lock`. */
  dataRoom?: Maybe<DataRoom>;
  dataRoomId: Scalars["UUID"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  isLocked?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type LockAggregates = {
  __typename?: "LockAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<LockDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A condition to be used against `Lock` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type LockCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `isLocked` field. */
  isLocked?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The `dataRoom` to be created by this mutation. */
export type LockDataRoomIdFkeyDataRoomCreateInput = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** Input for the nested mutation of `dataRoom` in the `LockInput` mutation. */
export type LockDataRoomIdFkeyInput = {
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomHash?: InputMaybe<DataRoomDataRoomDataRoomHashKeyConnect>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomId?: InputMaybe<DataRoomDataRoomPkeyConnect>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectById?: InputMaybe<DataRoomNodeIdConnect>;
  /** A `DataRoomInput` object that will be created and connected to this object. */
  create?: InputMaybe<LockDataRoomIdFkeyDataRoomCreateInput>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomHash?: InputMaybe<DataRoomDataRoomDataRoomHashKeyDelete>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomId?: InputMaybe<DataRoomDataRoomPkeyDelete>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteById?: InputMaybe<DataRoomNodeIdDelete>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomHash?: InputMaybe<DataRoomOnLockForLockDataRoomIdFkeyUsingDataRoomDataRoomHashKeyUpdate>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomId?: InputMaybe<DataRoomOnLockForLockDataRoomIdFkeyUsingDataRoomPkeyUpdate>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateById?: InputMaybe<LockOnLockForLockDataRoomIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `lock` in the `DataRoomInput` mutation. */
export type LockDataRoomIdFkeyInverseInput = {
  /** The primary key(s) for `lock` for the far side of the relationship. */
  connectByDataRoomId?: InputMaybe<LockLockPkeyConnect>;
  /** The primary key(s) for `lock` for the far side of the relationship. */
  connectById?: InputMaybe<LockNodeIdConnect>;
  /** A `LockInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<LockDataRoomIdFkeyLockCreateInput>>;
  /** The primary key(s) for `lock` for the far side of the relationship. */
  deleteByDataRoomId?: InputMaybe<LockLockPkeyDelete>;
  /** The primary key(s) for `lock` for the far side of the relationship. */
  deleteById?: InputMaybe<LockNodeIdDelete>;
  /** Flag indicating whether all other `lock` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `lock` for the far side of the relationship. */
  updateByDataRoomId?: InputMaybe<LockOnLockForLockDataRoomIdFkeyUsingLockPkeyUpdate>;
  /** The primary key(s) and patch data for `lock` for the far side of the relationship. */
  updateById?: InputMaybe<DataRoomOnLockForLockDataRoomIdFkeyNodeIdUpdate>;
};

/** The `lock` to be created by this mutation. */
export type LockDataRoomIdFkeyLockCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<LockDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  isLocked?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type LockDistinct = {
  __typename?: "LockDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `dataRoomId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  dataRoomId: Array<Scalars["UUID"]>;
  /** Distinct `isLocked` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  isLocked: Array<Maybe<Scalars["Boolean"]>>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type LockDistinctCountAggregates = {
  __typename?: "LockDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of dataRoomId across the matching connection */
  dataRoomId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of isLocked across the matching connection */
  isLocked?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `Lock` object types. All fields are combined with a logical ‘and.’ */
export type LockFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<LockFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dataRoom` relation. */
  dataRoom?: InputMaybe<DataRoomFilter>;
  /** Filter by the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `isLocked` field. */
  isLocked?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<LockFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<LockFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type LockGroup = {
  __typename?: "LockGroup";
  /** A list of _all_ `Lock` primary keys that belong to the `LockGroup`. */
  dataRoomIds?: Maybe<Array<Scalars["UUID"]>>;
  /** `LockFilter` that was used to implicitly filter `locks`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /**
   * Reads and enables pagination through a set of `Lock` of this `LockGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `LockGroup`. Overriding these may result in the wrong set of `Lock` returned.
   */
  locks?: Maybe<LocksConnection>;
  /** The count of _all_ `Lock` you could get from the `LockGroup`. */
  totalCount: Scalars["Int"];
};

export type LockGroupLocksArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<LockCondition>;
  filter?: InputMaybe<LockFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<LocksOrderBy>>;
};

/** Grouping methods for `Lock` for usage during aggregation. */
export enum LockGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  DataRoomByDataRoomIdCreatedAt = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT",
  DataRoomByDataRoomIdCreatedByEmail = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL",
  DataRoomByDataRoomIdDataRoomHash = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH",
  DataRoomByDataRoomIdDataRoomId = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID",
  DataRoomByDataRoomIdDescription = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION",
  DataRoomByDataRoomIdEnableDevelopmentTab = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB",
  DataRoomByDataRoomIdGovernanceProtocol = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL",
  DataRoomByDataRoomIdMrenclave = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE",
  DataRoomByDataRoomIdName = "DATA_ROOM_BY_DATA_ROOM_ID_NAME",
  DataRoomByDataRoomIdOwnerEmail = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL",
  DataRoomByDataRoomIdSource = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE",
  DataRoomByDataRoomIdUpdatedAt = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT",
  DataRoomByDataRoomIdUpdatedByEmail = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL",
  IsLocked = "IS_LOCKED",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type LockHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LockHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Lock` aggregates. */
export type LockHavingInput = {
  AND?: InputMaybe<Array<LockHavingInput>>;
  OR?: InputMaybe<Array<LockHavingInput>>;
  average?: InputMaybe<LockHavingAverageInput>;
  distinctCount?: InputMaybe<LockHavingDistinctCountInput>;
  max?: InputMaybe<LockHavingMaxInput>;
  min?: InputMaybe<LockHavingMinInput>;
  stddevPopulation?: InputMaybe<LockHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<LockHavingStddevSampleInput>;
  sum?: InputMaybe<LockHavingSumInput>;
  variancePopulation?: InputMaybe<LockHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<LockHavingVarianceSampleInput>;
};

export type LockHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LockHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LockHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LockHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LockHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LockHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type LockHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Lock` */
export type LockInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<LockDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  isLocked?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The fields on `lock` to look up the row to connect. */
export type LockLockPkeyConnect = {
  dataRoomId: Scalars["UUID"];
};

/** The fields on `lock` to look up the row to delete. */
export type LockLockPkeyDelete = {
  dataRoomId: Scalars["UUID"];
};

/** The globally unique `ID` look up for the row to connect. */
export type LockNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `lock` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type LockNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `lock` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type LockOnLockForLockDataRoomIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `dataRoom` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `dataRoom` being updated. */
  patch: DataRoomPatch;
};

/** The fields on `lock` to look up the row to update. */
export type LockOnLockForLockDataRoomIdFkeyUsingLockPkeyUpdate = {
  dataRoomId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `lock` being updated. */
  patch: UpdateLockOnLockForLockDataRoomIdFkeyPatch;
};

/** Represents an update to a `Lock`. Fields that are set will be updated. */
export type LockPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<LockDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  isLocked?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** A connection to a list of `Lock` values. */
export type LocksConnection = {
  __typename?: "LocksConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<LockAggregates>;
  /** A list of _all_ `Lock` primary keys. */
  dataRoomIds?: Maybe<Array<Scalars["UUID"]>>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<LockDistinct>;
  /** A list of edges which contains the `Lock` and cursor to aid in pagination. */
  edges: Array<LocksEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<LockAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<LockGroup>>;
  /** A list of `Lock` objects. */
  nodes: Array<Maybe<Lock>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Lock` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `Lock` values. */
export type LocksConnectionGroupedAggregatesArgs = {
  groupBy: Array<LockGroupBy>;
  having?: InputMaybe<LockHavingInput>;
};

/** A connection to a list of `Lock` values. */
export type LocksConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<LockGroupBy>;
  having?: InputMaybe<LockHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `Lock` edge in the connection. */
export type LocksEdge = {
  __typename?: "LocksEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `Lock` at the end of the edge. */
  node?: Maybe<Lock>;
};

/** Methods to use when ordering `Lock`. */
export enum LocksOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  DataRoomByDataRoomIdCreatedAtAsc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT_ASC",
  DataRoomByDataRoomIdCreatedAtDesc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT_DESC",
  DataRoomByDataRoomIdCreatedByEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_ASC",
  DataRoomByDataRoomIdCreatedByEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_DESC",
  DataRoomByDataRoomIdDataRoomHashAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH_ASC",
  DataRoomByDataRoomIdDataRoomHashDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH_DESC",
  DataRoomByDataRoomIdDataRoomIdAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID_ASC",
  DataRoomByDataRoomIdDataRoomIdDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID_DESC",
  DataRoomByDataRoomIdDescriptionAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION_ASC",
  DataRoomByDataRoomIdDescriptionDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION_DESC",
  DataRoomByDataRoomIdEnableDevelopmentTabAsc = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomByDataRoomIdEnableDevelopmentTabDesc = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomByDataRoomIdGovernanceProtocolAsc = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL_ASC",
  DataRoomByDataRoomIdGovernanceProtocolDesc = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL_DESC",
  DataRoomByDataRoomIdMrenclaveAsc = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE_ASC",
  DataRoomByDataRoomIdMrenclaveDesc = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE_DESC",
  DataRoomByDataRoomIdNameAsc = "DATA_ROOM_BY_DATA_ROOM_ID_NAME_ASC",
  DataRoomByDataRoomIdNameDesc = "DATA_ROOM_BY_DATA_ROOM_ID_NAME_DESC",
  DataRoomByDataRoomIdOwnerEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL_ASC",
  DataRoomByDataRoomIdOwnerEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL_DESC",
  DataRoomByDataRoomIdSourceAsc = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE_ASC",
  DataRoomByDataRoomIdSourceDesc = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE_DESC",
  DataRoomByDataRoomIdUpdatedAtAsc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT_ASC",
  DataRoomByDataRoomIdUpdatedAtDesc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT_DESC",
  DataRoomByDataRoomIdUpdatedByEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_ASC",
  DataRoomByDataRoomIdUpdatedByEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_DESC",
  DataRoomIdAsc = "DATA_ROOM_ID_ASC",
  DataRoomIdDesc = "DATA_ROOM_ID_DESC",
  IsLockedAsc = "IS_LOCKED_ASC",
  IsLockedDesc = "IS_LOCKED_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

/** All input for the `mergeConfiguration` mutation. */
export type MergeConfigurationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  configurationId: Scalars["UUID"];
};

/** The output of our `mergeConfiguration` mutation. */
export type MergeConfigurationPayload = {
  __typename?: "MergeConfigurationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  configuration?: Maybe<Configuration>;
  /** An edge for our `Configuration`. May be used by Relay 1. */
  configurationEdge?: Maybe<ConfigurationsEdge>;
  /** Reads a single `DataRoom` that is related to this `Configuration`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our `mergeConfiguration` mutation. */
export type MergeConfigurationPayloadConfigurationEdgeArgs = {
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy>>;
};

export type Modification = Node & {
  __typename?: "Modification";
  /** Reads a single `AttestationSpecificationAsElement` that is related to this `Modification`. */
  attestationSpecificationAsElement?: Maybe<AttestationSpecificationAsElement>;
  /** Reads a single `AuthenticationMethodAsElement` that is related to this `Modification`. */
  authenticationMethodAsElement?: Maybe<AuthenticationMethodAsElement>;
  /** Reads a single `ComputeNodeAsElement` that is related to this `Modification`. */
  computeNodeAsElement?: Maybe<ComputeNodeAsElement>;
  config?: Maybe<Scalars["JSON"]>;
  /** Reads a single `Configuration` that is related to this `Modification`. */
  configuration?: Maybe<Configuration>;
  configurationId: Scalars["UUID"];
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  elementId: Scalars["UUID"];
  elementType: ElementTypeType;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  modification: ModificationType;
  modificationId: Scalars["UUID"];
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
  /** Reads a single `UserPermissionAsElement` that is related to this `Modification`. */
  userPermissionAsElement?: Maybe<UserPermissionAsElement>;
};

export type ModificationAggregates = {
  __typename?: "ModificationAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ModificationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A filter to be used against aggregates of `Modification` object types. */
export type ModificationAggregatesFilter = {
  /** Distinct count aggregate over matching `Modification` objects. */
  distinctCount?: InputMaybe<ModificationDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Modification` object to be included within the aggregate. */
  filter?: InputMaybe<ModificationFilter>;
};

/**
 * A condition to be used against `Modification` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type ModificationCondition = {
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `configurationId` field. */
  configurationId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `elementId` field. */
  elementId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `elementType` field. */
  elementType?: InputMaybe<ElementTypeType>;
  /** Checks for equality with the object’s `modification` field. */
  modification?: InputMaybe<ModificationType>;
  /** Checks for equality with the object’s `modificationId` field. */
  modificationId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The `configuration` to be created by this mutation. */
export type ModificationConfigurationIdFkeyConfigurationCreateInput = {
  commitId?: InputMaybe<Scalars["String"]>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<ConfigurationDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  isDev?: InputMaybe<Scalars["Boolean"]>;
  modifications?: InputMaybe<ModificationConfigurationIdFkeyInverseInput>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  request?: InputMaybe<RequestConfigurationIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** Input for the nested mutation of `configuration` in the `ModificationInput` mutation. */
export type ModificationConfigurationIdFkeyInput = {
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  connectByCommitId?: InputMaybe<ConfigurationConfigurationCommitIdKeyConnect>;
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  connectByConfigurationId?: InputMaybe<ConfigurationConfigurationPkeyConnect>;
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  connectById?: InputMaybe<ConfigurationNodeIdConnect>;
  /** A `ConfigurationInput` object that will be created and connected to this object. */
  create?: InputMaybe<ModificationConfigurationIdFkeyConfigurationCreateInput>;
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  deleteByCommitId?: InputMaybe<ConfigurationConfigurationCommitIdKeyDelete>;
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  deleteByConfigurationId?: InputMaybe<ConfigurationConfigurationPkeyDelete>;
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  deleteById?: InputMaybe<ConfigurationNodeIdDelete>;
  /** The primary key(s) and patch data for `configuration` for the far side of the relationship. */
  updateByCommitId?: InputMaybe<ConfigurationOnModificationForModificationConfigurationIdFkeyUsingConfigurationCommitIdKeyUpdate>;
  /** The primary key(s) and patch data for `configuration` for the far side of the relationship. */
  updateByConfigurationId?: InputMaybe<ConfigurationOnModificationForModificationConfigurationIdFkeyUsingConfigurationPkeyUpdate>;
  /** The primary key(s) and patch data for `configuration` for the far side of the relationship. */
  updateById?: InputMaybe<ModificationOnModificationForModificationConfigurationIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `modification` in the `ConfigurationInput` mutation. */
export type ModificationConfigurationIdFkeyInverseInput = {
  /** The primary key(s) for `modification` for the far side of the relationship. */
  connectByElementId?: InputMaybe<
    Array<ModificationModificationElementIdKeyConnect>
  >;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  connectById?: InputMaybe<Array<ModificationNodeIdConnect>>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  connectByModificationId?: InputMaybe<
    Array<ModificationModificationPkeyConnect>
  >;
  /** A `ModificationInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<ModificationConfigurationIdFkeyModificationCreateInput>
  >;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  deleteByElementId?: InputMaybe<
    Array<ModificationModificationElementIdKeyDelete>
  >;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<ModificationNodeIdDelete>>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  deleteByModificationId?: InputMaybe<
    Array<ModificationModificationPkeyDelete>
  >;
  /** Flag indicating whether all other `modification` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `modification` for the far side of the relationship. */
  updateByElementId?: InputMaybe<
    Array<ModificationOnModificationForModificationConfigurationIdFkeyUsingModificationElementIdKeyUpdate>
  >;
  /** The primary key(s) and patch data for `modification` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<ConfigurationOnModificationForModificationConfigurationIdFkeyNodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `modification` for the far side of the relationship. */
  updateByModificationId?: InputMaybe<
    Array<ModificationOnModificationForModificationConfigurationIdFkeyUsingModificationPkeyUpdate>
  >;
};

/** The `modification` to be created by this mutation. */
export type ModificationConfigurationIdFkeyModificationCreateInput = {
  attestationSpecificationAsElement?: InputMaybe<AttestationSpecificationAsElementIdFkeyInverseInput>;
  authenticationMethodAsElement?: InputMaybe<AuthenticationMethodAsElementIdFkeyInverseInput>;
  computeNodeAsElement?: InputMaybe<ComputeNodeAsElementIdFkeyInverseInput>;
  config?: InputMaybe<Scalars["JSON"]>;
  configuration?: InputMaybe<ModificationConfigurationIdFkeyInput>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  elementId: Scalars["UUID"];
  elementType: ElementTypeType;
  modification: ModificationType;
  modificationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userPermissionAsElement?: InputMaybe<UserPermissionAsElementIdFkeyInverseInput>;
};

export type ModificationDistinct = {
  __typename?: "ModificationDistinct";
  /** Distinct `config` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  config: Array<Maybe<Scalars["JSON"]>>;
  /** Distinct `configurationId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  configurationId: Array<Scalars["UUID"]>;
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `elementId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  elementId: Array<Scalars["UUID"]>;
  /** Distinct `elementType` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  elementType: Array<ElementTypeType>;
  /** Distinct `modification` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  modification: Array<ModificationType>;
  /** Distinct `modificationId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  modificationId: Array<Scalars["UUID"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type ModificationDistinctCountAggregateFilter = {
  config?: InputMaybe<BigIntFilter>;
  configurationId?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  createdByEmail?: InputMaybe<BigIntFilter>;
  elementId?: InputMaybe<BigIntFilter>;
  elementType?: InputMaybe<BigIntFilter>;
  modification?: InputMaybe<BigIntFilter>;
  modificationId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  updatedByEmail?: InputMaybe<BigIntFilter>;
};

export type ModificationDistinctCountAggregates = {
  __typename?: "ModificationDistinctCountAggregates";
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of configurationId across the matching connection */
  configurationId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of elementId across the matching connection */
  elementId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of elementType across the matching connection */
  elementType?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of modification across the matching connection */
  modification?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of modificationId across the matching connection */
  modificationId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `Modification` object types. All fields are combined with a logical ‘and.’ */
export type ModificationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ModificationFilter>>;
  /** Filter by the object’s `attestationSpecificationAsElement` relation. */
  attestationSpecificationAsElement?: InputMaybe<AttestationSpecificationAsElementFilter>;
  /** A related `attestationSpecificationAsElement` exists. */
  attestationSpecificationAsElementExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `authenticationMethodAsElement` relation. */
  authenticationMethodAsElement?: InputMaybe<AuthenticationMethodAsElementFilter>;
  /** A related `authenticationMethodAsElement` exists. */
  authenticationMethodAsElementExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `computeNodeAsElement` relation. */
  computeNodeAsElement?: InputMaybe<ComputeNodeAsElementFilter>;
  /** A related `computeNodeAsElement` exists. */
  computeNodeAsElementExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `configuration` relation. */
  configuration?: InputMaybe<ConfigurationFilter>;
  /** Filter by the object’s `configurationId` field. */
  configurationId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `elementId` field. */
  elementId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `elementType` field. */
  elementType?: InputMaybe<ElementTypeTypeFilter>;
  /** Filter by the object’s `modification` field. */
  modification?: InputMaybe<ModificationTypeFilter>;
  /** Filter by the object’s `modificationId` field. */
  modificationId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ModificationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ModificationFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userPermissionAsElement` relation. */
  userPermissionAsElement?: InputMaybe<UserPermissionAsElementFilter>;
  /** A related `userPermissionAsElement` exists. */
  userPermissionAsElementExists?: InputMaybe<Scalars["Boolean"]>;
};

export type ModificationGroup = {
  __typename?: "ModificationGroup";
  /** `ModificationFilter` that was used to implicitly filter `modifications`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** A list of _all_ `Modification` primary keys that belong to the `ModificationGroup`. */
  modificationIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `Modification` of this `ModificationGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `ModificationGroup`. Overriding these may result in the wrong set of `Modification` returned.
   */
  modifications?: Maybe<ModificationsConnection>;
  /** The count of _all_ `Modification` you could get from the `ModificationGroup`. */
  totalCount: Scalars["Int"];
};

export type ModificationGroupModificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ModificationCondition>;
  filter?: InputMaybe<ModificationFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ModificationsOrderBy>>;
};

/** Grouping methods for `Modification` for usage during aggregation. */
export enum ModificationGroupBy {
  AttestationSpecificationAsElementByAttestationSpecificationIdAttestationSpecificationId = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_ATTESTATION_SPECIFICATION_ID",
  AttestationSpecificationAsElementByAttestationSpecificationIdConfig = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_CONFIG",
  AttestationSpecificationAsElementByAttestationSpecificationIdCreatedAt = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_CREATED_AT",
  AttestationSpecificationAsElementByAttestationSpecificationIdCreatedByEmail = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_CREATED_BY_EMAIL",
  AttestationSpecificationAsElementByAttestationSpecificationIdUpdatedAt = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_UPDATED_AT",
  AttestationSpecificationAsElementByAttestationSpecificationIdUpdatedByEmail = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_UPDATED_BY_EMAIL",
  AuthenticationMethodAsElementByAuthenticationMethodIdAuthenticationMethodId = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_AUTHENTICATION_METHOD_ID",
  AuthenticationMethodAsElementByAuthenticationMethodIdConfig = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_CONFIG",
  AuthenticationMethodAsElementByAuthenticationMethodIdCreatedAt = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_CREATED_AT",
  AuthenticationMethodAsElementByAuthenticationMethodIdCreatedByEmail = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_CREATED_BY_EMAIL",
  AuthenticationMethodAsElementByAuthenticationMethodIdUpdatedAt = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_UPDATED_AT",
  AuthenticationMethodAsElementByAuthenticationMethodIdUpdatedByEmail = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_UPDATED_BY_EMAIL",
  ComputeNodeAsElementByComputeNodeIdComputeNodeId = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_COMPUTE_NODE_ID",
  ComputeNodeAsElementByComputeNodeIdComputeNodeType = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_COMPUTE_NODE_TYPE",
  ComputeNodeAsElementByComputeNodeIdConfig = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_CONFIG",
  ComputeNodeAsElementByComputeNodeIdCreatedAt = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_CREATED_AT",
  ComputeNodeAsElementByComputeNodeIdCreatedByEmail = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_CREATED_BY_EMAIL",
  ComputeNodeAsElementByComputeNodeIdNodeName = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_NODE_NAME",
  ComputeNodeAsElementByComputeNodeIdUpdatedAt = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_UPDATED_AT",
  ComputeNodeAsElementByComputeNodeIdUpdatedByEmail = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_UPDATED_BY_EMAIL",
  Config = "CONFIG",
  ConfigurationByConfigurationIdCommitId = "CONFIGURATION_BY_CONFIGURATION_ID_COMMIT_ID",
  ConfigurationByConfigurationIdConfigurationId = "CONFIGURATION_BY_CONFIGURATION_ID_CONFIGURATION_ID",
  ConfigurationByConfigurationIdCreatedAt = "CONFIGURATION_BY_CONFIGURATION_ID_CREATED_AT",
  ConfigurationByConfigurationIdCreatedByEmail = "CONFIGURATION_BY_CONFIGURATION_ID_CREATED_BY_EMAIL",
  ConfigurationByConfigurationIdDataRoomId = "CONFIGURATION_BY_CONFIGURATION_ID_DATA_ROOM_ID",
  ConfigurationByConfigurationIdIsDev = "CONFIGURATION_BY_CONFIGURATION_ID_IS_DEV",
  ConfigurationByConfigurationIdOwnerEmail = "CONFIGURATION_BY_CONFIGURATION_ID_OWNER_EMAIL",
  ConfigurationByConfigurationIdUpdatedAt = "CONFIGURATION_BY_CONFIGURATION_ID_UPDATED_AT",
  ConfigurationByConfigurationIdUpdatedByEmail = "CONFIGURATION_BY_CONFIGURATION_ID_UPDATED_BY_EMAIL",
  ConfigurationId = "CONFIGURATION_ID",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  ElementType = "ELEMENT_TYPE",
  Modification = "MODIFICATION",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
  UserPermissionAsElementByUserPermissionIdCreatedAt = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_CREATED_AT",
  UserPermissionAsElementByUserPermissionIdCreatedByEmail = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_CREATED_BY_EMAIL",
  UserPermissionAsElementByUserPermissionIdEmail = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_EMAIL",
  UserPermissionAsElementByUserPermissionIdUpdatedAt = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_UPDATED_AT",
  UserPermissionAsElementByUserPermissionIdUpdatedByEmail = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_UPDATED_BY_EMAIL",
  UserPermissionAsElementByUserPermissionIdUserPermissionId = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_USER_PERMISSION_ID",
}

export type ModificationHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ModificationHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Modification` aggregates. */
export type ModificationHavingInput = {
  AND?: InputMaybe<Array<ModificationHavingInput>>;
  OR?: InputMaybe<Array<ModificationHavingInput>>;
  average?: InputMaybe<ModificationHavingAverageInput>;
  distinctCount?: InputMaybe<ModificationHavingDistinctCountInput>;
  max?: InputMaybe<ModificationHavingMaxInput>;
  min?: InputMaybe<ModificationHavingMinInput>;
  stddevPopulation?: InputMaybe<ModificationHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ModificationHavingStddevSampleInput>;
  sum?: InputMaybe<ModificationHavingSumInput>;
  variancePopulation?: InputMaybe<ModificationHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ModificationHavingVarianceSampleInput>;
};

export type ModificationHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ModificationHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ModificationHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ModificationHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ModificationHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ModificationHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ModificationHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Modification` */
export type ModificationInput = {
  attestationSpecificationAsElement?: InputMaybe<AttestationSpecificationAsElementIdFkeyInverseInput>;
  authenticationMethodAsElement?: InputMaybe<AuthenticationMethodAsElementIdFkeyInverseInput>;
  computeNodeAsElement?: InputMaybe<ComputeNodeAsElementIdFkeyInverseInput>;
  config?: InputMaybe<Scalars["JSON"]>;
  configuration?: InputMaybe<ModificationConfigurationIdFkeyInput>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  elementId: Scalars["UUID"];
  elementType: ElementTypeType;
  modification: ModificationType;
  modificationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userPermissionAsElement?: InputMaybe<UserPermissionAsElementIdFkeyInverseInput>;
};

/** The fields on `modification` to look up the row to connect. */
export type ModificationModificationElementIdKeyConnect = {
  elementId: Scalars["UUID"];
};

/** The fields on `modification` to look up the row to delete. */
export type ModificationModificationElementIdKeyDelete = {
  elementId: Scalars["UUID"];
};

/** The fields on `modification` to look up the row to connect. */
export type ModificationModificationPkeyConnect = {
  modificationId: Scalars["UUID"];
};

/** The fields on `modification` to look up the row to delete. */
export type ModificationModificationPkeyDelete = {
  modificationId: Scalars["UUID"];
};

/** The globally unique `ID` look up for the row to connect. */
export type ModificationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `modification` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type ModificationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `modification` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type ModificationOnAttestationSpecificationAsElementForAttestationSpecificationAsElementIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `attestationSpecificationAsElement` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `attestationSpecificationAsElement` being updated. */
    patch: AttestationSpecificationAsElementPatch;
  };

/** The fields on `modification` to look up the row to update. */
export type ModificationOnAttestationSpecificationAsElementForAttestationSpecificationAsElementIdFkeyUsingModificationElementIdKeyUpdate =
  {
    elementId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `modification` being updated. */
    patch: UpdateModificationOnAttestationSpecificationAsElementForAttestationSpecificationAsElementIdFkeyPatch;
  };

/** The fields on `modification` to look up the row to update. */
export type ModificationOnAttestationSpecificationAsElementForAttestationSpecificationAsElementIdFkeyUsingModificationPkeyUpdate =
  {
    modificationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `modification` being updated. */
    patch: UpdateModificationOnAttestationSpecificationAsElementForAttestationSpecificationAsElementIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ModificationOnAuthenticationMethodAsElementForAuthenticationMethodAsElementIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `authenticationMethodAsElement` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `authenticationMethodAsElement` being updated. */
    patch: AuthenticationMethodAsElementPatch;
  };

/** The fields on `modification` to look up the row to update. */
export type ModificationOnAuthenticationMethodAsElementForAuthenticationMethodAsElementIdFkeyUsingModificationElementIdKeyUpdate =
  {
    elementId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `modification` being updated. */
    patch: UpdateModificationOnAuthenticationMethodAsElementForAuthenticationMethodAsElementIdFkeyPatch;
  };

/** The fields on `modification` to look up the row to update. */
export type ModificationOnAuthenticationMethodAsElementForAuthenticationMethodAsElementIdFkeyUsingModificationPkeyUpdate =
  {
    modificationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `modification` being updated. */
    patch: UpdateModificationOnAuthenticationMethodAsElementForAuthenticationMethodAsElementIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ModificationOnComputeNodeAsElementForComputeNodeAsElementIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `computeNodeAsElement` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `computeNodeAsElement` being updated. */
    patch: ComputeNodeAsElementPatch;
  };

/** The fields on `modification` to look up the row to update. */
export type ModificationOnComputeNodeAsElementForComputeNodeAsElementIdFkeyUsingModificationElementIdKeyUpdate =
  {
    elementId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `modification` being updated. */
    patch: UpdateModificationOnComputeNodeAsElementForComputeNodeAsElementIdFkeyPatch;
  };

/** The fields on `modification` to look up the row to update. */
export type ModificationOnComputeNodeAsElementForComputeNodeAsElementIdFkeyUsingModificationPkeyUpdate =
  {
    modificationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `modification` being updated. */
    patch: UpdateModificationOnComputeNodeAsElementForComputeNodeAsElementIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ModificationOnModificationForModificationConfigurationIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `configuration` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `configuration` being updated. */
    patch: ConfigurationPatch;
  };

/** The fields on `modification` to look up the row to update. */
export type ModificationOnModificationForModificationConfigurationIdFkeyUsingModificationElementIdKeyUpdate =
  {
    elementId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `modification` being updated. */
    patch: UpdateModificationOnModificationForModificationConfigurationIdFkeyPatch;
  };

/** The fields on `modification` to look up the row to update. */
export type ModificationOnModificationForModificationConfigurationIdFkeyUsingModificationPkeyUpdate =
  {
    modificationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `modification` being updated. */
    patch: UpdateModificationOnModificationForModificationConfigurationIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type ModificationOnUserPermissionAsElementForUserPermissionAsElementIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `userPermissionAsElement` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `userPermissionAsElement` being updated. */
    patch: UserPermissionAsElementPatch;
  };

/** The fields on `modification` to look up the row to update. */
export type ModificationOnUserPermissionAsElementForUserPermissionAsElementIdFkeyUsingModificationElementIdKeyUpdate =
  {
    elementId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `modification` being updated. */
    patch: UpdateModificationOnUserPermissionAsElementForUserPermissionAsElementIdFkeyPatch;
  };

/** The fields on `modification` to look up the row to update. */
export type ModificationOnUserPermissionAsElementForUserPermissionAsElementIdFkeyUsingModificationPkeyUpdate =
  {
    modificationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `modification` being updated. */
    patch: UpdateModificationOnUserPermissionAsElementForUserPermissionAsElementIdFkeyPatch;
  };

/** Represents an update to a `Modification`. Fields that are set will be updated. */
export type ModificationPatch = {
  attestationSpecificationAsElement?: InputMaybe<AttestationSpecificationAsElementIdFkeyInverseInput>;
  authenticationMethodAsElement?: InputMaybe<AuthenticationMethodAsElementIdFkeyInverseInput>;
  computeNodeAsElement?: InputMaybe<ComputeNodeAsElementIdFkeyInverseInput>;
  config?: InputMaybe<Scalars["JSON"]>;
  configuration?: InputMaybe<ModificationConfigurationIdFkeyInput>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  elementId?: InputMaybe<Scalars["UUID"]>;
  elementType?: InputMaybe<ElementTypeType>;
  modification?: InputMaybe<ModificationType>;
  modificationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userPermissionAsElement?: InputMaybe<UserPermissionAsElementIdFkeyInverseInput>;
};

export enum ModificationType {
  Add = "ADD",
  Change = "CHANGE",
  Delete = "DELETE",
}

/** A filter to be used against ModificationType fields. All fields are combined with a logical ‘and.’ */
export type ModificationTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<ModificationType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<ModificationType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<ModificationType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<ModificationType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<ModificationType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<ModificationType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<ModificationType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<ModificationType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<ModificationType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<ModificationType>>;
};

/** A connection to a list of `Modification` values. */
export type ModificationsConnection = {
  __typename?: "ModificationsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ModificationAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<ModificationDistinct>;
  /** A list of edges which contains the `Modification` and cursor to aid in pagination. */
  edges: Array<ModificationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ModificationAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<ModificationGroup>>;
  /** A list of _all_ `Modification` primary keys. */
  modificationIds?: Maybe<Array<Scalars["UUID"]>>;
  /** A list of `Modification` objects. */
  nodes: Array<Maybe<Modification>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Modification` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `Modification` values. */
export type ModificationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<ModificationGroupBy>;
  having?: InputMaybe<ModificationHavingInput>;
};

/** A connection to a list of `Modification` values. */
export type ModificationsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<ModificationGroupBy>;
  having?: InputMaybe<ModificationHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `Modification` edge in the connection. */
export type ModificationsEdge = {
  __typename?: "ModificationsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `Modification` at the end of the edge. */
  node?: Maybe<Modification>;
};

/** Methods to use when ordering `Modification`. */
export enum ModificationsOrderBy {
  AttestationSpecificationAsElementByAttestationSpecificationIdAttestationSpecificationIdAsc = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_ATTESTATION_SPECIFICATION_ID_ASC",
  AttestationSpecificationAsElementByAttestationSpecificationIdAttestationSpecificationIdDesc = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_ATTESTATION_SPECIFICATION_ID_DESC",
  AttestationSpecificationAsElementByAttestationSpecificationIdConfigAsc = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_CONFIG_ASC",
  AttestationSpecificationAsElementByAttestationSpecificationIdConfigDesc = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_CONFIG_DESC",
  AttestationSpecificationAsElementByAttestationSpecificationIdCreatedAtAsc = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_CREATED_AT_ASC",
  AttestationSpecificationAsElementByAttestationSpecificationIdCreatedAtDesc = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_CREATED_AT_DESC",
  AttestationSpecificationAsElementByAttestationSpecificationIdCreatedByEmailAsc = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_CREATED_BY_EMAIL_ASC",
  AttestationSpecificationAsElementByAttestationSpecificationIdCreatedByEmailDesc = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_CREATED_BY_EMAIL_DESC",
  AttestationSpecificationAsElementByAttestationSpecificationIdUpdatedAtAsc = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_UPDATED_AT_ASC",
  AttestationSpecificationAsElementByAttestationSpecificationIdUpdatedAtDesc = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_UPDATED_AT_DESC",
  AttestationSpecificationAsElementByAttestationSpecificationIdUpdatedByEmailAsc = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_UPDATED_BY_EMAIL_ASC",
  AttestationSpecificationAsElementByAttestationSpecificationIdUpdatedByEmailDesc = "ATTESTATION_SPECIFICATION_AS_ELEMENT_BY_ATTESTATION_SPECIFICATION_ID_UPDATED_BY_EMAIL_DESC",
  AuthenticationMethodAsElementByAuthenticationMethodIdAuthenticationMethodIdAsc = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_AUTHENTICATION_METHOD_ID_ASC",
  AuthenticationMethodAsElementByAuthenticationMethodIdAuthenticationMethodIdDesc = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_AUTHENTICATION_METHOD_ID_DESC",
  AuthenticationMethodAsElementByAuthenticationMethodIdConfigAsc = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_CONFIG_ASC",
  AuthenticationMethodAsElementByAuthenticationMethodIdConfigDesc = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_CONFIG_DESC",
  AuthenticationMethodAsElementByAuthenticationMethodIdCreatedAtAsc = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_CREATED_AT_ASC",
  AuthenticationMethodAsElementByAuthenticationMethodIdCreatedAtDesc = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_CREATED_AT_DESC",
  AuthenticationMethodAsElementByAuthenticationMethodIdCreatedByEmailAsc = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_CREATED_BY_EMAIL_ASC",
  AuthenticationMethodAsElementByAuthenticationMethodIdCreatedByEmailDesc = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_CREATED_BY_EMAIL_DESC",
  AuthenticationMethodAsElementByAuthenticationMethodIdUpdatedAtAsc = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_UPDATED_AT_ASC",
  AuthenticationMethodAsElementByAuthenticationMethodIdUpdatedAtDesc = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_UPDATED_AT_DESC",
  AuthenticationMethodAsElementByAuthenticationMethodIdUpdatedByEmailAsc = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_UPDATED_BY_EMAIL_ASC",
  AuthenticationMethodAsElementByAuthenticationMethodIdUpdatedByEmailDesc = "AUTHENTICATION_METHOD_AS_ELEMENT_BY_AUTHENTICATION_METHOD_ID_UPDATED_BY_EMAIL_DESC",
  ComputeNodeAsElementByComputeNodeIdComputeNodeIdAsc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_COMPUTE_NODE_ID_ASC",
  ComputeNodeAsElementByComputeNodeIdComputeNodeIdDesc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_COMPUTE_NODE_ID_DESC",
  ComputeNodeAsElementByComputeNodeIdComputeNodeTypeAsc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_COMPUTE_NODE_TYPE_ASC",
  ComputeNodeAsElementByComputeNodeIdComputeNodeTypeDesc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_COMPUTE_NODE_TYPE_DESC",
  ComputeNodeAsElementByComputeNodeIdConfigAsc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_CONFIG_ASC",
  ComputeNodeAsElementByComputeNodeIdConfigDesc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_CONFIG_DESC",
  ComputeNodeAsElementByComputeNodeIdCreatedAtAsc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_CREATED_AT_ASC",
  ComputeNodeAsElementByComputeNodeIdCreatedAtDesc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_CREATED_AT_DESC",
  ComputeNodeAsElementByComputeNodeIdCreatedByEmailAsc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_CREATED_BY_EMAIL_ASC",
  ComputeNodeAsElementByComputeNodeIdCreatedByEmailDesc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_CREATED_BY_EMAIL_DESC",
  ComputeNodeAsElementByComputeNodeIdNodeNameAsc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_NODE_NAME_ASC",
  ComputeNodeAsElementByComputeNodeIdNodeNameDesc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_NODE_NAME_DESC",
  ComputeNodeAsElementByComputeNodeIdUpdatedAtAsc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_UPDATED_AT_ASC",
  ComputeNodeAsElementByComputeNodeIdUpdatedAtDesc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_UPDATED_AT_DESC",
  ComputeNodeAsElementByComputeNodeIdUpdatedByEmailAsc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_UPDATED_BY_EMAIL_ASC",
  ComputeNodeAsElementByComputeNodeIdUpdatedByEmailDesc = "COMPUTE_NODE_AS_ELEMENT_BY_COMPUTE_NODE_ID_UPDATED_BY_EMAIL_DESC",
  ConfigurationByConfigurationIdCommitIdAsc = "CONFIGURATION_BY_CONFIGURATION_ID_COMMIT_ID_ASC",
  ConfigurationByConfigurationIdCommitIdDesc = "CONFIGURATION_BY_CONFIGURATION_ID_COMMIT_ID_DESC",
  ConfigurationByConfigurationIdConfigurationIdAsc = "CONFIGURATION_BY_CONFIGURATION_ID_CONFIGURATION_ID_ASC",
  ConfigurationByConfigurationIdConfigurationIdDesc = "CONFIGURATION_BY_CONFIGURATION_ID_CONFIGURATION_ID_DESC",
  ConfigurationByConfigurationIdCreatedAtAsc = "CONFIGURATION_BY_CONFIGURATION_ID_CREATED_AT_ASC",
  ConfigurationByConfigurationIdCreatedAtDesc = "CONFIGURATION_BY_CONFIGURATION_ID_CREATED_AT_DESC",
  ConfigurationByConfigurationIdCreatedByEmailAsc = "CONFIGURATION_BY_CONFIGURATION_ID_CREATED_BY_EMAIL_ASC",
  ConfigurationByConfigurationIdCreatedByEmailDesc = "CONFIGURATION_BY_CONFIGURATION_ID_CREATED_BY_EMAIL_DESC",
  ConfigurationByConfigurationIdDataRoomIdAsc = "CONFIGURATION_BY_CONFIGURATION_ID_DATA_ROOM_ID_ASC",
  ConfigurationByConfigurationIdDataRoomIdDesc = "CONFIGURATION_BY_CONFIGURATION_ID_DATA_ROOM_ID_DESC",
  ConfigurationByConfigurationIdIsDevAsc = "CONFIGURATION_BY_CONFIGURATION_ID_IS_DEV_ASC",
  ConfigurationByConfigurationIdIsDevDesc = "CONFIGURATION_BY_CONFIGURATION_ID_IS_DEV_DESC",
  ConfigurationByConfigurationIdOwnerEmailAsc = "CONFIGURATION_BY_CONFIGURATION_ID_OWNER_EMAIL_ASC",
  ConfigurationByConfigurationIdOwnerEmailDesc = "CONFIGURATION_BY_CONFIGURATION_ID_OWNER_EMAIL_DESC",
  ConfigurationByConfigurationIdUpdatedAtAsc = "CONFIGURATION_BY_CONFIGURATION_ID_UPDATED_AT_ASC",
  ConfigurationByConfigurationIdUpdatedAtDesc = "CONFIGURATION_BY_CONFIGURATION_ID_UPDATED_AT_DESC",
  ConfigurationByConfigurationIdUpdatedByEmailAsc = "CONFIGURATION_BY_CONFIGURATION_ID_UPDATED_BY_EMAIL_ASC",
  ConfigurationByConfigurationIdUpdatedByEmailDesc = "CONFIGURATION_BY_CONFIGURATION_ID_UPDATED_BY_EMAIL_DESC",
  ConfigurationIdAsc = "CONFIGURATION_ID_ASC",
  ConfigurationIdDesc = "CONFIGURATION_ID_DESC",
  ConfigAsc = "CONFIG_ASC",
  ConfigDesc = "CONFIG_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  ElementIdAsc = "ELEMENT_ID_ASC",
  ElementIdDesc = "ELEMENT_ID_DESC",
  ElementTypeAsc = "ELEMENT_TYPE_ASC",
  ElementTypeDesc = "ELEMENT_TYPE_DESC",
  ModificationAsc = "MODIFICATION_ASC",
  ModificationDesc = "MODIFICATION_DESC",
  ModificationIdAsc = "MODIFICATION_ID_ASC",
  ModificationIdDesc = "MODIFICATION_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
  UserPermissionAsElementByUserPermissionIdCreatedAtAsc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_CREATED_AT_ASC",
  UserPermissionAsElementByUserPermissionIdCreatedAtDesc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_CREATED_AT_DESC",
  UserPermissionAsElementByUserPermissionIdCreatedByEmailAsc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_CREATED_BY_EMAIL_ASC",
  UserPermissionAsElementByUserPermissionIdCreatedByEmailDesc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_CREATED_BY_EMAIL_DESC",
  UserPermissionAsElementByUserPermissionIdEmailAsc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_EMAIL_ASC",
  UserPermissionAsElementByUserPermissionIdEmailDesc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_EMAIL_DESC",
  UserPermissionAsElementByUserPermissionIdUpdatedAtAsc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_UPDATED_AT_ASC",
  UserPermissionAsElementByUserPermissionIdUpdatedAtDesc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_UPDATED_AT_DESC",
  UserPermissionAsElementByUserPermissionIdUpdatedByEmailAsc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_UPDATED_BY_EMAIL_ASC",
  UserPermissionAsElementByUserPermissionIdUpdatedByEmailDesc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_UPDATED_BY_EMAIL_DESC",
  UserPermissionAsElementByUserPermissionIdUserPermissionIdAsc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_USER_PERMISSION_ID_ASC",
  UserPermissionAsElementByUserPermissionIdUserPermissionIdDesc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_USER_PERMISSION_ID_DESC",
}

/** The root mutation type which contains root level fields which mutate data. */
export type Mutation = {
  __typename?: "Mutation";
  /** Creates "Change password" request on Auth0 */
  changePassword: ChangePasswordPayload;
  /** Creates a single `AttestationSpecificationAsElement`. */
  createAttestationSpecificationAsElement?: Maybe<CreateAttestationSpecificationAsElementPayload>;
  /** Creates a single `AuthenticationMethodAsElement`. */
  createAuthenticationMethodAsElement?: Maybe<CreateAuthenticationMethodAsElementPayload>;
  /** Creates a single `ComputeNode`. */
  createComputeNode?: Maybe<CreateComputeNodePayload>;
  /** Creates a single `ComputeNodeAsElement`. */
  createComputeNodeAsElement?: Maybe<CreateComputeNodeAsElementPayload>;
  /** Creates a single `Config`. */
  createConfig?: Maybe<CreateConfigPayload>;
  /** Creates a single `Configuration`. */
  createConfiguration?: Maybe<CreateConfigurationPayload>;
  /** Creates a single `DataRoom`. */
  createDataRoom?: Maybe<CreateDataRoomPayload>;
  /** Creates a single `DatasetLink`. */
  createDatasetLink?: Maybe<CreateDatasetLinkPayload>;
  /** Creates a single `DatasetMeta`. */
  createDatasetMeta?: Maybe<CreateDatasetMetaPayload>;
  /** Creates a single `DatasetStat`. */
  createDatasetStat?: Maybe<CreateDatasetStatPayload>;
  /** Creates a single `Domain`. */
  createDomain?: Maybe<CreateDomainPayload>;
  /** Creates a single `ExecutionTime`. */
  createExecutionTime?: Maybe<CreateExecutionTimePayload>;
  /** Creates a single `Job`. */
  createJob?: Maybe<CreateJobPayload>;
  /** Creates a single `Lock`. */
  createLock?: Maybe<CreateLockPayload>;
  /** Creates a single `Modification`. */
  createModification?: Maybe<CreateModificationPayload>;
  /** Creates a single `Organization`. */
  createOrganization?: Maybe<CreateOrganizationPayload>;
  /** Creates a single `OrganizationAttribute`. */
  createOrganizationAttribute?: Maybe<CreateOrganizationAttributePayload>;
  /** Creates a single `OrganizationLogo`. */
  createOrganizationLogo?: Maybe<CreateOrganizationLogoPayload>;
  /** Creates a single `OrganizationMembership`. */
  createOrganizationMembership?: Maybe<CreateOrganizationMembershipPayload>;
  /** Creates a single `OrganizationQuota`. */
  createOrganizationQuota?: Maybe<CreateOrganizationQuotaPayload>;
  /** Creates a single `Permission`. */
  createPermission?: Maybe<CreatePermissionPayload>;
  /** Creates a single `PermissionAsElement`. */
  createPermissionAsElement?: Maybe<CreatePermissionAsElementPayload>;
  /** Creates a single `Request`. */
  createRequest?: Maybe<CreateRequestPayload>;
  /** Creates a single `Resource`. */
  createResource?: Maybe<CreateResourcePayload>;
  /** Creates a single `Signature`. */
  createSignature?: Maybe<CreateSignaturePayload>;
  /** Creates a single `Signee`. */
  createSignee?: Maybe<CreateSigneePayload>;
  /** Creates a single `State`. */
  createState?: Maybe<CreateStatePayload>;
  /** Creates a single `User`. */
  createUser?: Maybe<CreateUserPayload>;
  /** Creates a single `UserAttribute`. */
  createUserAttribute?: Maybe<CreateUserAttributePayload>;
  /** Creates a single `UserConfig`. */
  createUserConfig?: Maybe<CreateUserConfigPayload>;
  /** Creates a single `UserPermission`. */
  createUserPermission?: Maybe<CreateUserPermissionPayload>;
  /** Creates a single `UserPermissionAsElement`. */
  createUserPermissionAsElement?: Maybe<CreateUserPermissionAsElementPayload>;
  /** Creates a single `UserQuota`. */
  createUserQuota?: Maybe<CreateUserQuotaPayload>;
  /** Deletes user account on Auth0 */
  deleteAccount: DeleteAccountPayload;
  /** Deletes a single `AttestationSpecificationAsElement` using a unique key. */
  deleteAttestationSpecificationAsElement?: Maybe<DeleteAttestationSpecificationAsElementPayload>;
  /** Deletes a single `AttestationSpecificationAsElement` using its globally unique id. */
  deleteAttestationSpecificationAsElementById?: Maybe<DeleteAttestationSpecificationAsElementPayload>;
  /** Deletes a single `AuthenticationMethodAsElement` using a unique key. */
  deleteAuthenticationMethodAsElement?: Maybe<DeleteAuthenticationMethodAsElementPayload>;
  /** Deletes a single `AuthenticationMethodAsElement` using its globally unique id. */
  deleteAuthenticationMethodAsElementById?: Maybe<DeleteAuthenticationMethodAsElementPayload>;
  /** Deletes a single `ComputeNode` using a unique key. */
  deleteComputeNode?: Maybe<DeleteComputeNodePayload>;
  /** Deletes a single `ComputeNodeAsElement` using a unique key. */
  deleteComputeNodeAsElement?: Maybe<DeleteComputeNodeAsElementPayload>;
  /** Deletes a single `ComputeNodeAsElement` using its globally unique id. */
  deleteComputeNodeAsElementById?: Maybe<DeleteComputeNodeAsElementPayload>;
  /** Deletes a single `ComputeNode` using its globally unique id. */
  deleteComputeNodeById?: Maybe<DeleteComputeNodePayload>;
  /** Deletes a single `Config` using a unique key. */
  deleteConfig?: Maybe<DeleteConfigPayload>;
  /** Deletes a single `Config` using its globally unique id. */
  deleteConfigById?: Maybe<DeleteConfigPayload>;
  /** Deletes a single `Configuration` using a unique key. */
  deleteConfiguration?: Maybe<DeleteConfigurationPayload>;
  deleteConfigurationAndElements?: Maybe<DeleteConfigurationAndElementsPayload>;
  /** Deletes a single `Configuration` using a unique key. */
  deleteConfigurationByCommitId?: Maybe<DeleteConfigurationPayload>;
  /** Deletes a single `Configuration` using its globally unique id. */
  deleteConfigurationById?: Maybe<DeleteConfigurationPayload>;
  /** Deletes a single `DataRoom` using a unique key. */
  deleteDataRoom?: Maybe<DeleteDataRoomPayload>;
  /** Deletes a single `DataRoom` using a unique key. */
  deleteDataRoomByDataRoomHash?: Maybe<DeleteDataRoomPayload>;
  /** Deletes a single `DataRoom` using its globally unique id. */
  deleteDataRoomById?: Maybe<DeleteDataRoomPayload>;
  /** Deletes a single `DatasetLink` using a unique key. */
  deleteDatasetLink?: Maybe<DeleteDatasetLinkPayload>;
  /** Deletes a single `DatasetLink` using its globally unique id. */
  deleteDatasetLinkById?: Maybe<DeleteDatasetLinkPayload>;
  /** Deletes a single `DatasetMeta` using a unique key. */
  deleteDatasetMeta?: Maybe<DeleteDatasetMetaPayload>;
  /** Deletes a single `DatasetMeta` using its globally unique id. */
  deleteDatasetMetaById?: Maybe<DeleteDatasetMetaPayload>;
  /** Deletes a single `DatasetStat` using a unique key. */
  deleteDatasetStat?: Maybe<DeleteDatasetStatPayload>;
  /** Deletes a single `DatasetStat` using its globally unique id. */
  deleteDatasetStatById?: Maybe<DeleteDatasetStatPayload>;
  /** Deletes a single `Domain` using a unique key. */
  deleteDomain?: Maybe<DeleteDomainPayload>;
  /** Deletes a single `Domain` using a unique key. */
  deleteDomainByDomain?: Maybe<DeleteDomainPayload>;
  /** Deletes a single `Domain` using its globally unique id. */
  deleteDomainById?: Maybe<DeleteDomainPayload>;
  /** Deletes a single `ExecutionTime` using a unique key. */
  deleteExecutionTime?: Maybe<DeleteExecutionTimePayload>;
  /** Deletes a single `ExecutionTime` using its globally unique id. */
  deleteExecutionTimeById?: Maybe<DeleteExecutionTimePayload>;
  /** Deletes a single `Job` using a unique key. */
  deleteJob?: Maybe<DeleteJobPayload>;
  /** Deletes a single `Job` using its globally unique id. */
  deleteJobById?: Maybe<DeleteJobPayload>;
  /** Deletes a single `Lock` using a unique key. */
  deleteLock?: Maybe<DeleteLockPayload>;
  /** Deletes a single `Lock` using its globally unique id. */
  deleteLockById?: Maybe<DeleteLockPayload>;
  /** Deletes a single `Modification` using a unique key. */
  deleteModification?: Maybe<DeleteModificationPayload>;
  deleteModificationAndElements?: Maybe<DeleteModificationAndElementsPayload>;
  /** Deletes a single `Modification` using a unique key. */
  deleteModificationByElementId?: Maybe<DeleteModificationPayload>;
  /** Deletes a single `Modification` using its globally unique id. */
  deleteModificationById?: Maybe<DeleteModificationPayload>;
  /** Deletes a single `Organization` using a unique key. */
  deleteOrganization?: Maybe<DeleteOrganizationPayload>;
  /** Deletes a single `OrganizationAttribute` using a unique key. */
  deleteOrganizationAttribute?: Maybe<DeleteOrganizationAttributePayload>;
  /** Deletes a single `OrganizationAttribute` using its globally unique id. */
  deleteOrganizationAttributeById?: Maybe<DeleteOrganizationAttributePayload>;
  /** Deletes a single `Organization` using its globally unique id. */
  deleteOrganizationById?: Maybe<DeleteOrganizationPayload>;
  /** Deletes a single `OrganizationLogo` using a unique key. */
  deleteOrganizationLogo?: Maybe<DeleteOrganizationLogoPayload>;
  /** Deletes a single `OrganizationLogo` using its globally unique id. */
  deleteOrganizationLogoById?: Maybe<DeleteOrganizationLogoPayload>;
  /** Deletes a single `OrganizationMembership` using a unique key. */
  deleteOrganizationMembership?: Maybe<DeleteOrganizationMembershipPayload>;
  /** Deletes a single `OrganizationMembership` using its globally unique id. */
  deleteOrganizationMembershipById?: Maybe<DeleteOrganizationMembershipPayload>;
  /** Deletes a single `OrganizationQuota` using a unique key. */
  deleteOrganizationQuota?: Maybe<DeleteOrganizationQuotaPayload>;
  /** Deletes a single `OrganizationQuota` using its globally unique id. */
  deleteOrganizationQuotaById?: Maybe<DeleteOrganizationQuotaPayload>;
  /** Deletes a single `Permission` using a unique key. */
  deletePermission?: Maybe<DeletePermissionPayload>;
  /** Deletes a single `PermissionAsElement` using a unique key. */
  deletePermissionAsElement?: Maybe<DeletePermissionAsElementPayload>;
  /** Deletes a single `PermissionAsElement` using its globally unique id. */
  deletePermissionAsElementById?: Maybe<DeletePermissionAsElementPayload>;
  /** Deletes a single `Permission` using its globally unique id. */
  deletePermissionById?: Maybe<DeletePermissionPayload>;
  /** Deletes a single `Request` using a unique key. */
  deleteRequest?: Maybe<DeleteRequestPayload>;
  /** Deletes a single `Request` using a unique key. */
  deleteRequestByConfigurationId?: Maybe<DeleteRequestPayload>;
  /** Deletes a single `Request` using its globally unique id. */
  deleteRequestById?: Maybe<DeleteRequestPayload>;
  /** Deletes a single `Resource` using a unique key. */
  deleteResource?: Maybe<DeleteResourcePayload>;
  /** Deletes a single `Resource` using its globally unique id. */
  deleteResourceById?: Maybe<DeleteResourcePayload>;
  /** Deletes a single `Signature` using a unique key. */
  deleteSignature?: Maybe<DeleteSignaturePayload>;
  /** Deletes a single `Signature` using its globally unique id. */
  deleteSignatureById?: Maybe<DeleteSignaturePayload>;
  /** Deletes a single `Signature` using a unique key. */
  deleteSignatureBySignatureHash?: Maybe<DeleteSignaturePayload>;
  /** Deletes a single `Signee` using a unique key. */
  deleteSignee?: Maybe<DeleteSigneePayload>;
  /** Deletes a single `Signee` using its globally unique id. */
  deleteSigneeById?: Maybe<DeleteSigneePayload>;
  /** Deletes a single `Signee` using a unique key. */
  deleteSigneeByRequestIdAndSigneeEmail?: Maybe<DeleteSigneePayload>;
  /** Deletes a single `State` using a unique key. */
  deleteState?: Maybe<DeleteStatePayload>;
  /** Deletes a single `State` using its globally unique id. */
  deleteStateById?: Maybe<DeleteStatePayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUser?: Maybe<DeleteUserPayload>;
  /** Deletes a single `UserAttribute` using a unique key. */
  deleteUserAttribute?: Maybe<DeleteUserAttributePayload>;
  /** Deletes a single `UserAttribute` using its globally unique id. */
  deleteUserAttributeById?: Maybe<DeleteUserAttributePayload>;
  /** Deletes a single `User` using a unique key. */
  deleteUserByEmail?: Maybe<DeleteUserPayload>;
  /** Deletes a single `User` using its globally unique id. */
  deleteUserById?: Maybe<DeleteUserPayload>;
  /** Deletes a single `UserConfig` using a unique key. */
  deleteUserConfig?: Maybe<DeleteUserConfigPayload>;
  /** Deletes a single `UserConfig` using a unique key. */
  deleteUserConfigByDataRoomIdAndEmail?: Maybe<DeleteUserConfigPayload>;
  /** Deletes a single `UserConfig` using its globally unique id. */
  deleteUserConfigById?: Maybe<DeleteUserConfigPayload>;
  /** Deletes a single `UserPermission` using a unique key. */
  deleteUserPermission?: Maybe<DeleteUserPermissionPayload>;
  /** Deletes a single `UserPermissionAsElement` using a unique key. */
  deleteUserPermissionAsElement?: Maybe<DeleteUserPermissionAsElementPayload>;
  /** Deletes a single `UserPermissionAsElement` using its globally unique id. */
  deleteUserPermissionAsElementById?: Maybe<DeleteUserPermissionAsElementPayload>;
  /** Deletes a single `UserPermission` using a unique key. */
  deleteUserPermissionByDataRoomIdAndEmail?: Maybe<DeleteUserPermissionPayload>;
  /** Deletes a single `UserPermission` using its globally unique id. */
  deleteUserPermissionById?: Maybe<DeleteUserPermissionPayload>;
  /** Deletes a single `UserQuota` using a unique key. */
  deleteUserQuota?: Maybe<DeleteUserQuotaPayload>;
  /** Deletes a single `UserQuota` using its globally unique id. */
  deleteUserQuotaById?: Maybe<DeleteUserQuotaPayload>;
  mergeConfiguration?: Maybe<MergeConfigurationPayload>;
  runComputeNodeById?: Maybe<RunComputeNodeByIdPayload>;
  /** Updates a single `AttestationSpecificationAsElement` using a unique key and a patch. */
  updateAttestationSpecificationAsElement?: Maybe<UpdateAttestationSpecificationAsElementPayload>;
  /** Updates a single `AttestationSpecificationAsElement` using its globally unique id and a patch. */
  updateAttestationSpecificationAsElementById?: Maybe<UpdateAttestationSpecificationAsElementPayload>;
  /** Updates list of `AttestationSpecificationAsElement`. */
  updateAttestationSpecificationAsElements?: Maybe<UpdateAttestationSpecificationAsElementsPayload>;
  /** Updates a single `AuthenticationMethodAsElement` using a unique key and a patch. */
  updateAuthenticationMethodAsElement?: Maybe<UpdateAuthenticationMethodAsElementPayload>;
  /** Updates a single `AuthenticationMethodAsElement` using its globally unique id and a patch. */
  updateAuthenticationMethodAsElementById?: Maybe<UpdateAuthenticationMethodAsElementPayload>;
  /** Updates list of `AuthenticationMethodAsElement`. */
  updateAuthenticationMethodAsElements?: Maybe<UpdateAuthenticationMethodAsElementsPayload>;
  /** Updates a single `ComputeNode` using a unique key and a patch. */
  updateComputeNode?: Maybe<UpdateComputeNodePayload>;
  /** Updates a single `ComputeNodeAsElement` using a unique key and a patch. */
  updateComputeNodeAsElement?: Maybe<UpdateComputeNodeAsElementPayload>;
  /** Updates a single `ComputeNodeAsElement` using its globally unique id and a patch. */
  updateComputeNodeAsElementById?: Maybe<UpdateComputeNodeAsElementPayload>;
  /** Updates list of `ComputeNodeAsElement`. */
  updateComputeNodeAsElements?: Maybe<UpdateComputeNodeAsElementsPayload>;
  /** Updates a single `ComputeNode` using its globally unique id and a patch. */
  updateComputeNodeById?: Maybe<UpdateComputeNodePayload>;
  /** Updates list of `ComputeNode`. */
  updateComputeNodes?: Maybe<UpdateComputeNodesPayload>;
  /** Updates a single `Config` using a unique key and a patch. */
  updateConfig?: Maybe<UpdateConfigPayload>;
  /** Updates a single `Config` using its globally unique id and a patch. */
  updateConfigById?: Maybe<UpdateConfigPayload>;
  /** Updates list of `Config`. */
  updateConfigs?: Maybe<UpdateConfigsPayload>;
  /** Updates a single `Configuration` using a unique key and a patch. */
  updateConfiguration?: Maybe<UpdateConfigurationPayload>;
  /** Updates a single `Configuration` using a unique key and a patch. */
  updateConfigurationByCommitId?: Maybe<UpdateConfigurationPayload>;
  /** Updates a single `Configuration` using its globally unique id and a patch. */
  updateConfigurationById?: Maybe<UpdateConfigurationPayload>;
  /** Updates list of `Configuration`. */
  updateConfigurations?: Maybe<UpdateConfigurationsPayload>;
  /** Updates a single `DataRoom` using a unique key and a patch. */
  updateDataRoom?: Maybe<UpdateDataRoomPayload>;
  /** Updates a single `DataRoom` using a unique key and a patch. */
  updateDataRoomByDataRoomHash?: Maybe<UpdateDataRoomPayload>;
  /** Updates a single `DataRoom` using its globally unique id and a patch. */
  updateDataRoomById?: Maybe<UpdateDataRoomPayload>;
  /** Updates list of `DataRoom`. */
  updateDataRooms?: Maybe<UpdateDataRoomsPayload>;
  /** Updates a single `DatasetLink` using a unique key and a patch. */
  updateDatasetLink?: Maybe<UpdateDatasetLinkPayload>;
  /** Updates a single `DatasetLink` using its globally unique id and a patch. */
  updateDatasetLinkById?: Maybe<UpdateDatasetLinkPayload>;
  /** Updates list of `DatasetLink`. */
  updateDatasetLinks?: Maybe<UpdateDatasetLinksPayload>;
  /** Updates a single `DatasetMeta` using a unique key and a patch. */
  updateDatasetMeta?: Maybe<UpdateDatasetMetaPayload>;
  /** Updates a single `DatasetMeta` using its globally unique id and a patch. */
  updateDatasetMetaById?: Maybe<UpdateDatasetMetaPayload>;
  /** Updates list of `DatasetMeta`. */
  updateDatasetMetas?: Maybe<UpdateDatasetMetasPayload>;
  /** Updates a single `DatasetStat` using a unique key and a patch. */
  updateDatasetStat?: Maybe<UpdateDatasetStatPayload>;
  /** Updates a single `DatasetStat` using its globally unique id and a patch. */
  updateDatasetStatById?: Maybe<UpdateDatasetStatPayload>;
  /** Updates a single `Domain` using a unique key and a patch. */
  updateDomain?: Maybe<UpdateDomainPayload>;
  /** Updates a single `Domain` using a unique key and a patch. */
  updateDomainByDomain?: Maybe<UpdateDomainPayload>;
  /** Updates a single `Domain` using its globally unique id and a patch. */
  updateDomainById?: Maybe<UpdateDomainPayload>;
  /** Updates list of `Domain`. */
  updateDomains?: Maybe<UpdateDomainsPayload>;
  /** Updates a single `ExecutionTime` using a unique key and a patch. */
  updateExecutionTime?: Maybe<UpdateExecutionTimePayload>;
  /** Updates a single `ExecutionTime` using its globally unique id and a patch. */
  updateExecutionTimeById?: Maybe<UpdateExecutionTimePayload>;
  /** Updates a single `Job` using a unique key and a patch. */
  updateJob?: Maybe<UpdateJobPayload>;
  /** Updates a single `Job` using its globally unique id and a patch. */
  updateJobById?: Maybe<UpdateJobPayload>;
  /** Updates list of `Job`. */
  updateJobs?: Maybe<UpdateJobsPayload>;
  /** Updates a single `Lock` using a unique key and a patch. */
  updateLock?: Maybe<UpdateLockPayload>;
  /** Updates a single `Lock` using its globally unique id and a patch. */
  updateLockById?: Maybe<UpdateLockPayload>;
  /** Updates list of `Lock`. */
  updateLocks?: Maybe<UpdateLocksPayload>;
  /** Updates a single `Modification` using a unique key and a patch. */
  updateModification?: Maybe<UpdateModificationPayload>;
  /** Updates a single `Modification` using a unique key and a patch. */
  updateModificationByElementId?: Maybe<UpdateModificationPayload>;
  /** Updates a single `Modification` using its globally unique id and a patch. */
  updateModificationById?: Maybe<UpdateModificationPayload>;
  /** Updates list of `Modification`. */
  updateModifications?: Maybe<UpdateModificationsPayload>;
  /** Updates a single `Organization` using a unique key and a patch. */
  updateOrganization?: Maybe<UpdateOrganizationPayload>;
  /** Updates a single `OrganizationAttribute` using a unique key and a patch. */
  updateOrganizationAttribute?: Maybe<UpdateOrganizationAttributePayload>;
  /** Updates a single `OrganizationAttribute` using its globally unique id and a patch. */
  updateOrganizationAttributeById?: Maybe<UpdateOrganizationAttributePayload>;
  /** Updates a single `Organization` using its globally unique id and a patch. */
  updateOrganizationById?: Maybe<UpdateOrganizationPayload>;
  /** Updates a single `OrganizationLogo` using a unique key and a patch. */
  updateOrganizationLogo?: Maybe<UpdateOrganizationLogoPayload>;
  /** Updates a single `OrganizationLogo` using its globally unique id and a patch. */
  updateOrganizationLogoById?: Maybe<UpdateOrganizationLogoPayload>;
  /** Updates list of `OrganizationLogo`. */
  updateOrganizationLogos?: Maybe<UpdateOrganizationLogosPayload>;
  /** Updates a single `OrganizationMembership` using a unique key and a patch. */
  updateOrganizationMembership?: Maybe<UpdateOrganizationMembershipPayload>;
  /** Updates a single `OrganizationMembership` using its globally unique id and a patch. */
  updateOrganizationMembershipById?: Maybe<UpdateOrganizationMembershipPayload>;
  /** Updates list of `OrganizationMembership`. */
  updateOrganizationMemberships?: Maybe<UpdateOrganizationMembershipsPayload>;
  /** Updates a single `OrganizationQuota` using a unique key and a patch. */
  updateOrganizationQuota?: Maybe<UpdateOrganizationQuotaPayload>;
  /** Updates a single `OrganizationQuota` using its globally unique id and a patch. */
  updateOrganizationQuotaById?: Maybe<UpdateOrganizationQuotaPayload>;
  /** Updates list of `OrganizationQuota`. */
  updateOrganizationQuotas?: Maybe<UpdateOrganizationQuotasPayload>;
  /** Updates list of `Organization`. */
  updateOrganizations?: Maybe<UpdateOrganizationsPayload>;
  /** Updates a single `Permission` using a unique key and a patch. */
  updatePermission?: Maybe<UpdatePermissionPayload>;
  /** Updates a single `PermissionAsElement` using a unique key and a patch. */
  updatePermissionAsElement?: Maybe<UpdatePermissionAsElementPayload>;
  /** Updates a single `PermissionAsElement` using its globally unique id and a patch. */
  updatePermissionAsElementById?: Maybe<UpdatePermissionAsElementPayload>;
  /** Updates list of `PermissionAsElement`. */
  updatePermissionAsElements?: Maybe<UpdatePermissionAsElementsPayload>;
  /** Updates a single `Permission` using its globally unique id and a patch. */
  updatePermissionById?: Maybe<UpdatePermissionPayload>;
  /** Updates list of `Permission`. */
  updatePermissions?: Maybe<UpdatePermissionsPayload>;
  /** Updates a single `Request` using a unique key and a patch. */
  updateRequest?: Maybe<UpdateRequestPayload>;
  /** Updates a single `Request` using a unique key and a patch. */
  updateRequestByConfigurationId?: Maybe<UpdateRequestPayload>;
  /** Updates a single `Request` using its globally unique id and a patch. */
  updateRequestById?: Maybe<UpdateRequestPayload>;
  /** Updates list of `Request`. */
  updateRequests?: Maybe<UpdateRequestsPayload>;
  /** Updates a single `Resource` using a unique key and a patch. */
  updateResource?: Maybe<UpdateResourcePayload>;
  /** Updates a single `Resource` using its globally unique id and a patch. */
  updateResourceById?: Maybe<UpdateResourcePayload>;
  /** Updates list of `Resource`. */
  updateResources?: Maybe<UpdateResourcesPayload>;
  /** Updates a single `Signature` using a unique key and a patch. */
  updateSignature?: Maybe<UpdateSignaturePayload>;
  /** Updates a single `Signature` using its globally unique id and a patch. */
  updateSignatureById?: Maybe<UpdateSignaturePayload>;
  /** Updates a single `Signature` using a unique key and a patch. */
  updateSignatureBySignatureHash?: Maybe<UpdateSignaturePayload>;
  /** Updates list of `Signature`. */
  updateSignatures?: Maybe<UpdateSignaturesPayload>;
  /** Updates a single `Signee` using a unique key and a patch. */
  updateSignee?: Maybe<UpdateSigneePayload>;
  /** Updates a single `Signee` using its globally unique id and a patch. */
  updateSigneeById?: Maybe<UpdateSigneePayload>;
  /** Updates a single `Signee` using a unique key and a patch. */
  updateSigneeByRequestIdAndSigneeEmail?: Maybe<UpdateSigneePayload>;
  /** Updates list of `Signee`. */
  updateSignees?: Maybe<UpdateSigneesPayload>;
  /** Updates a single `State` using a unique key and a patch. */
  updateState?: Maybe<UpdateStatePayload>;
  /** Updates a single `State` using its globally unique id and a patch. */
  updateStateById?: Maybe<UpdateStatePayload>;
  /** Updates list of `State`. */
  updateStates?: Maybe<UpdateStatesPayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUser?: Maybe<UpdateUserPayload>;
  /** Updates a single `UserAttribute` using a unique key and a patch. */
  updateUserAttribute?: Maybe<UpdateUserAttributePayload>;
  /** Updates a single `UserAttribute` using its globally unique id and a patch. */
  updateUserAttributeById?: Maybe<UpdateUserAttributePayload>;
  /** Updates a single `User` using a unique key and a patch. */
  updateUserByEmail?: Maybe<UpdateUserPayload>;
  /** Updates a single `User` using its globally unique id and a patch. */
  updateUserById?: Maybe<UpdateUserPayload>;
  /** Updates a single `UserConfig` using a unique key and a patch. */
  updateUserConfig?: Maybe<UpdateUserConfigPayload>;
  /** Updates a single `UserConfig` using a unique key and a patch. */
  updateUserConfigByDataRoomIdAndEmail?: Maybe<UpdateUserConfigPayload>;
  /** Updates a single `UserConfig` using its globally unique id and a patch. */
  updateUserConfigById?: Maybe<UpdateUserConfigPayload>;
  /** Updates list of `UserConfig`. */
  updateUserConfigs?: Maybe<UpdateUserConfigsPayload>;
  /** Updates a single `UserPermission` using a unique key and a patch. */
  updateUserPermission?: Maybe<UpdateUserPermissionPayload>;
  /** Updates a single `UserPermissionAsElement` using a unique key and a patch. */
  updateUserPermissionAsElement?: Maybe<UpdateUserPermissionAsElementPayload>;
  /** Updates a single `UserPermissionAsElement` using its globally unique id and a patch. */
  updateUserPermissionAsElementById?: Maybe<UpdateUserPermissionAsElementPayload>;
  /** Updates list of `UserPermissionAsElement`. */
  updateUserPermissionAsElements?: Maybe<UpdateUserPermissionAsElementsPayload>;
  /** Updates a single `UserPermission` using a unique key and a patch. */
  updateUserPermissionByDataRoomIdAndEmail?: Maybe<UpdateUserPermissionPayload>;
  /** Updates a single `UserPermission` using its globally unique id and a patch. */
  updateUserPermissionById?: Maybe<UpdateUserPermissionPayload>;
  /** Updates list of `UserPermission`. */
  updateUserPermissions?: Maybe<UpdateUserPermissionsPayload>;
  /** Updates a single `UserQuota` using a unique key and a patch. */
  updateUserQuota?: Maybe<UpdateUserQuotaPayload>;
  /** Updates a single `UserQuota` using its globally unique id and a patch. */
  updateUserQuotaById?: Maybe<UpdateUserQuotaPayload>;
  /** Updates list of `UserQuota`. */
  updateUserQuotas?: Maybe<UpdateUserQuotasPayload>;
  /** Updates list of `User`. */
  updateUsers?: Maybe<UpdateUsersPayload>;
  /** Upserts a single `AttestationSpecificationAsElement`. */
  upsertAttestationSpecificationAsElement?: Maybe<UpsertAttestationSpecificationAsElementPayload>;
  /** Upserts a single `AuthenticationMethodAsElement`. */
  upsertAuthenticationMethodAsElement?: Maybe<UpsertAuthenticationMethodAsElementPayload>;
  /** Upserts a single `ComputeNode`. */
  upsertComputeNode?: Maybe<UpsertComputeNodePayload>;
  /** Upserts a single `ComputeNodeAsElement`. */
  upsertComputeNodeAsElement?: Maybe<UpsertComputeNodeAsElementPayload>;
  /** Upserts a single `Config`. */
  upsertConfig?: Maybe<UpsertConfigPayload>;
  /** Upserts a single `Configuration`. */
  upsertConfiguration?: Maybe<UpsertConfigurationPayload>;
  /** Upserts a single `DataRoom`. */
  upsertDataRoom?: Maybe<UpsertDataRoomPayload>;
  /** Upserts a single `DatasetLink`. */
  upsertDatasetLink?: Maybe<UpsertDatasetLinkPayload>;
  /** Upserts a single `DatasetMeta`. */
  upsertDatasetMeta?: Maybe<UpsertDatasetMetaPayload>;
  /** Upserts a single `DatasetStat`. */
  upsertDatasetStat?: Maybe<UpsertDatasetStatPayload>;
  /** Upserts a single `Domain`. */
  upsertDomain?: Maybe<UpsertDomainPayload>;
  /** Upserts a single `ExecutionTime`. */
  upsertExecutionTime?: Maybe<UpsertExecutionTimePayload>;
  /** Upserts a single `Job`. */
  upsertJob?: Maybe<UpsertJobPayload>;
  /** Upserts a single `Lock`. */
  upsertLock?: Maybe<UpsertLockPayload>;
  /** Upserts a single `Modification`. */
  upsertModification?: Maybe<UpsertModificationPayload>;
  /** Upserts a single `Organization`. */
  upsertOrganization?: Maybe<UpsertOrganizationPayload>;
  /** Upserts a single `OrganizationAttribute`. */
  upsertOrganizationAttribute?: Maybe<UpsertOrganizationAttributePayload>;
  /** Upserts a single `OrganizationLogo`. */
  upsertOrganizationLogo?: Maybe<UpsertOrganizationLogoPayload>;
  /** Upserts a single `OrganizationMembership`. */
  upsertOrganizationMembership?: Maybe<UpsertOrganizationMembershipPayload>;
  /** Upserts a single `OrganizationQuota`. */
  upsertOrganizationQuota?: Maybe<UpsertOrganizationQuotaPayload>;
  /** Upserts a single `Permission`. */
  upsertPermission?: Maybe<UpsertPermissionPayload>;
  /** Upserts a single `PermissionAsElement`. */
  upsertPermissionAsElement?: Maybe<UpsertPermissionAsElementPayload>;
  /** Upserts a single `Request`. */
  upsertRequest?: Maybe<UpsertRequestPayload>;
  /** Upserts a single `Resource`. */
  upsertResource?: Maybe<UpsertResourcePayload>;
  /** Upserts a single `Signature`. */
  upsertSignature?: Maybe<UpsertSignaturePayload>;
  /** Upserts a single `Signee`. */
  upsertSignee?: Maybe<UpsertSigneePayload>;
  /** Upserts a single `State`. */
  upsertState?: Maybe<UpsertStatePayload>;
  /** Upserts a single `User`. */
  upsertUser?: Maybe<UpsertUserPayload>;
  /** Upserts a single `UserAttribute`. */
  upsertUserAttribute?: Maybe<UpsertUserAttributePayload>;
  /** Upserts a single `UserConfig`. */
  upsertUserConfig?: Maybe<UpsertUserConfigPayload>;
  /** Upserts a single `UserPermission`. */
  upsertUserPermission?: Maybe<UpsertUserPermissionPayload>;
  /** Upserts a single `UserPermissionAsElement`. */
  upsertUserPermissionAsElement?: Maybe<UpsertUserPermissionAsElementPayload>;
  /** Upserts a single `UserQuota`. */
  upsertUserQuota?: Maybe<UpsertUserQuotaPayload>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationChangePasswordArgs = {
  input?: InputMaybe<ChangePasswordInput>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAttestationSpecificationAsElementArgs = {
  input: CreateAttestationSpecificationAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateAuthenticationMethodAsElementArgs = {
  input: CreateAuthenticationMethodAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateComputeNodeArgs = {
  input: CreateComputeNodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateComputeNodeAsElementArgs = {
  input: CreateComputeNodeAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateConfigArgs = {
  input: CreateConfigInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateConfigurationArgs = {
  input: CreateConfigurationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDataRoomArgs = {
  input: CreateDataRoomInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDatasetLinkArgs = {
  input: CreateDatasetLinkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDatasetMetaArgs = {
  input: CreateDatasetMetaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDatasetStatArgs = {
  input: CreateDatasetStatInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateDomainArgs = {
  input: CreateDomainInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateExecutionTimeArgs = {
  input: CreateExecutionTimeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateJobArgs = {
  input: CreateJobInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateLockArgs = {
  input: CreateLockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateModificationArgs = {
  input: CreateModificationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrganizationArgs = {
  input: CreateOrganizationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrganizationAttributeArgs = {
  input: CreateOrganizationAttributeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrganizationLogoArgs = {
  input: CreateOrganizationLogoInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrganizationMembershipArgs = {
  input: CreateOrganizationMembershipInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateOrganizationQuotaArgs = {
  input: CreateOrganizationQuotaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePermissionArgs = {
  input: CreatePermissionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreatePermissionAsElementArgs = {
  input: CreatePermissionAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateRequestArgs = {
  input: CreateRequestInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateResourceArgs = {
  input: CreateResourceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSignatureArgs = {
  input: CreateSignatureInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateSigneeArgs = {
  input: CreateSigneeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateStateArgs = {
  input: CreateStateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserAttributeArgs = {
  input: CreateUserAttributeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserConfigArgs = {
  input: CreateUserConfigInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserPermissionArgs = {
  input: CreateUserPermissionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserPermissionAsElementArgs = {
  input: CreateUserPermissionAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationCreateUserQuotaArgs = {
  input: CreateUserQuotaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAccountArgs = {
  input?: InputMaybe<DeleteAccountInput>;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAttestationSpecificationAsElementArgs = {
  input: DeleteAttestationSpecificationAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAttestationSpecificationAsElementByIdArgs = {
  input: DeleteAttestationSpecificationAsElementByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAuthenticationMethodAsElementArgs = {
  input: DeleteAuthenticationMethodAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteAuthenticationMethodAsElementByIdArgs = {
  input: DeleteAuthenticationMethodAsElementByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteComputeNodeArgs = {
  input: DeleteComputeNodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteComputeNodeAsElementArgs = {
  input: DeleteComputeNodeAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteComputeNodeAsElementByIdArgs = {
  input: DeleteComputeNodeAsElementByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteComputeNodeByIdArgs = {
  input: DeleteComputeNodeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteConfigArgs = {
  input: DeleteConfigInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteConfigByIdArgs = {
  input: DeleteConfigByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteConfigurationArgs = {
  input: DeleteConfigurationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteConfigurationAndElementsArgs = {
  input: DeleteConfigurationAndElementsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteConfigurationByCommitIdArgs = {
  input: DeleteConfigurationByCommitIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteConfigurationByIdArgs = {
  input: DeleteConfigurationByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDataRoomArgs = {
  input: DeleteDataRoomInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDataRoomByDataRoomHashArgs = {
  input: DeleteDataRoomByDataRoomHashInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDataRoomByIdArgs = {
  input: DeleteDataRoomByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetLinkArgs = {
  input: DeleteDatasetLinkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetLinkByIdArgs = {
  input: DeleteDatasetLinkByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetMetaArgs = {
  input: DeleteDatasetMetaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetMetaByIdArgs = {
  input: DeleteDatasetMetaByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetStatArgs = {
  input: DeleteDatasetStatInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDatasetStatByIdArgs = {
  input: DeleteDatasetStatByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDomainArgs = {
  input: DeleteDomainInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDomainByDomainArgs = {
  input: DeleteDomainByDomainInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteDomainByIdArgs = {
  input: DeleteDomainByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExecutionTimeArgs = {
  input: DeleteExecutionTimeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteExecutionTimeByIdArgs = {
  input: DeleteExecutionTimeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteJobArgs = {
  input: DeleteJobInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteJobByIdArgs = {
  input: DeleteJobByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLockArgs = {
  input: DeleteLockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteLockByIdArgs = {
  input: DeleteLockByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteModificationArgs = {
  input: DeleteModificationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteModificationAndElementsArgs = {
  input: DeleteModificationAndElementsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteModificationByElementIdArgs = {
  input: DeleteModificationByElementIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteModificationByIdArgs = {
  input: DeleteModificationByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationArgs = {
  input: DeleteOrganizationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationAttributeArgs = {
  input: DeleteOrganizationAttributeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationAttributeByIdArgs = {
  input: DeleteOrganizationAttributeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationByIdArgs = {
  input: DeleteOrganizationByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationLogoArgs = {
  input: DeleteOrganizationLogoInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationLogoByIdArgs = {
  input: DeleteOrganizationLogoByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationMembershipArgs = {
  input: DeleteOrganizationMembershipInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationMembershipByIdArgs = {
  input: DeleteOrganizationMembershipByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationQuotaArgs = {
  input: DeleteOrganizationQuotaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteOrganizationQuotaByIdArgs = {
  input: DeleteOrganizationQuotaByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePermissionArgs = {
  input: DeletePermissionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePermissionAsElementArgs = {
  input: DeletePermissionAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePermissionAsElementByIdArgs = {
  input: DeletePermissionAsElementByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeletePermissionByIdArgs = {
  input: DeletePermissionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRequestArgs = {
  input: DeleteRequestInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRequestByConfigurationIdArgs = {
  input: DeleteRequestByConfigurationIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteRequestByIdArgs = {
  input: DeleteRequestByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteResourceArgs = {
  input: DeleteResourceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteResourceByIdArgs = {
  input: DeleteResourceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSignatureArgs = {
  input: DeleteSignatureInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSignatureByIdArgs = {
  input: DeleteSignatureByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSignatureBySignatureHashArgs = {
  input: DeleteSignatureBySignatureHashInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSigneeArgs = {
  input: DeleteSigneeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSigneeByIdArgs = {
  input: DeleteSigneeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteSigneeByRequestIdAndSigneeEmailArgs = {
  input: DeleteSigneeByRequestIdAndSigneeEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStateArgs = {
  input: DeleteStateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteStateByIdArgs = {
  input: DeleteStateByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserArgs = {
  input: DeleteUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserAttributeArgs = {
  input: DeleteUserAttributeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserAttributeByIdArgs = {
  input: DeleteUserAttributeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByEmailArgs = {
  input: DeleteUserByEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserByIdArgs = {
  input: DeleteUserByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserConfigArgs = {
  input: DeleteUserConfigInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserConfigByDataRoomIdAndEmailArgs = {
  input: DeleteUserConfigByDataRoomIdAndEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserConfigByIdArgs = {
  input: DeleteUserConfigByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserPermissionArgs = {
  input: DeleteUserPermissionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserPermissionAsElementArgs = {
  input: DeleteUserPermissionAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserPermissionAsElementByIdArgs = {
  input: DeleteUserPermissionAsElementByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserPermissionByDataRoomIdAndEmailArgs = {
  input: DeleteUserPermissionByDataRoomIdAndEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserPermissionByIdArgs = {
  input: DeleteUserPermissionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserQuotaArgs = {
  input: DeleteUserQuotaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationDeleteUserQuotaByIdArgs = {
  input: DeleteUserQuotaByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationMergeConfigurationArgs = {
  input: MergeConfigurationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationRunComputeNodeByIdArgs = {
  input: RunComputeNodeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAttestationSpecificationAsElementArgs = {
  input: UpdateAttestationSpecificationAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAttestationSpecificationAsElementByIdArgs = {
  input: UpdateAttestationSpecificationAsElementByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAttestationSpecificationAsElementsArgs = {
  input: UpdateAttestationSpecificationAsElementsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuthenticationMethodAsElementArgs = {
  input: UpdateAuthenticationMethodAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuthenticationMethodAsElementByIdArgs = {
  input: UpdateAuthenticationMethodAsElementByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateAuthenticationMethodAsElementsArgs = {
  input: UpdateAuthenticationMethodAsElementsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateComputeNodeArgs = {
  input: UpdateComputeNodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateComputeNodeAsElementArgs = {
  input: UpdateComputeNodeAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateComputeNodeAsElementByIdArgs = {
  input: UpdateComputeNodeAsElementByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateComputeNodeAsElementsArgs = {
  input: UpdateComputeNodeAsElementsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateComputeNodeByIdArgs = {
  input: UpdateComputeNodeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateComputeNodesArgs = {
  input: UpdateComputeNodesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateConfigArgs = {
  input: UpdateConfigInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateConfigByIdArgs = {
  input: UpdateConfigByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateConfigsArgs = {
  input: UpdateConfigsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateConfigurationArgs = {
  input: UpdateConfigurationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateConfigurationByCommitIdArgs = {
  input: UpdateConfigurationByCommitIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateConfigurationByIdArgs = {
  input: UpdateConfigurationByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateConfigurationsArgs = {
  input: UpdateConfigurationsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDataRoomArgs = {
  input: UpdateDataRoomInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDataRoomByDataRoomHashArgs = {
  input: UpdateDataRoomByDataRoomHashInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDataRoomByIdArgs = {
  input: UpdateDataRoomByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDataRoomsArgs = {
  input: UpdateDataRoomsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetLinkArgs = {
  input: UpdateDatasetLinkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetLinkByIdArgs = {
  input: UpdateDatasetLinkByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetLinksArgs = {
  input: UpdateDatasetLinksInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetMetaArgs = {
  input: UpdateDatasetMetaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetMetaByIdArgs = {
  input: UpdateDatasetMetaByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetMetasArgs = {
  input: UpdateDatasetMetasInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetStatArgs = {
  input: UpdateDatasetStatInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDatasetStatByIdArgs = {
  input: UpdateDatasetStatByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDomainArgs = {
  input: UpdateDomainInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDomainByDomainArgs = {
  input: UpdateDomainByDomainInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDomainByIdArgs = {
  input: UpdateDomainByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateDomainsArgs = {
  input: UpdateDomainsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExecutionTimeArgs = {
  input: UpdateExecutionTimeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateExecutionTimeByIdArgs = {
  input: UpdateExecutionTimeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateJobArgs = {
  input: UpdateJobInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateJobByIdArgs = {
  input: UpdateJobByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateJobsArgs = {
  input: UpdateJobsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLockArgs = {
  input: UpdateLockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLockByIdArgs = {
  input: UpdateLockByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateLocksArgs = {
  input: UpdateLocksInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateModificationArgs = {
  input: UpdateModificationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateModificationByElementIdArgs = {
  input: UpdateModificationByElementIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateModificationByIdArgs = {
  input: UpdateModificationByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateModificationsArgs = {
  input: UpdateModificationsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationArgs = {
  input: UpdateOrganizationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationAttributeArgs = {
  input: UpdateOrganizationAttributeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationAttributeByIdArgs = {
  input: UpdateOrganizationAttributeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationByIdArgs = {
  input: UpdateOrganizationByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationLogoArgs = {
  input: UpdateOrganizationLogoInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationLogoByIdArgs = {
  input: UpdateOrganizationLogoByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationLogosArgs = {
  input: UpdateOrganizationLogosInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationMembershipArgs = {
  input: UpdateOrganizationMembershipInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationMembershipByIdArgs = {
  input: UpdateOrganizationMembershipByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationMembershipsArgs = {
  input: UpdateOrganizationMembershipsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationQuotaArgs = {
  input: UpdateOrganizationQuotaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationQuotaByIdArgs = {
  input: UpdateOrganizationQuotaByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationQuotasArgs = {
  input: UpdateOrganizationQuotasInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateOrganizationsArgs = {
  input: UpdateOrganizationsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePermissionArgs = {
  input: UpdatePermissionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePermissionAsElementArgs = {
  input: UpdatePermissionAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePermissionAsElementByIdArgs = {
  input: UpdatePermissionAsElementByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePermissionAsElementsArgs = {
  input: UpdatePermissionAsElementsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePermissionByIdArgs = {
  input: UpdatePermissionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdatePermissionsArgs = {
  input: UpdatePermissionsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRequestArgs = {
  input: UpdateRequestInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRequestByConfigurationIdArgs = {
  input: UpdateRequestByConfigurationIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRequestByIdArgs = {
  input: UpdateRequestByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateRequestsArgs = {
  input: UpdateRequestsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateResourceArgs = {
  input: UpdateResourceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateResourceByIdArgs = {
  input: UpdateResourceByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateResourcesArgs = {
  input: UpdateResourcesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSignatureArgs = {
  input: UpdateSignatureInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSignatureByIdArgs = {
  input: UpdateSignatureByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSignatureBySignatureHashArgs = {
  input: UpdateSignatureBySignatureHashInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSignaturesArgs = {
  input: UpdateSignaturesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSigneeArgs = {
  input: UpdateSigneeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSigneeByIdArgs = {
  input: UpdateSigneeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSigneeByRequestIdAndSigneeEmailArgs = {
  input: UpdateSigneeByRequestIdAndSigneeEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateSigneesArgs = {
  input: UpdateSigneesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateStateArgs = {
  input: UpdateStateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateStateByIdArgs = {
  input: UpdateStateByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateStatesArgs = {
  input: UpdateStatesInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserAttributeArgs = {
  input: UpdateUserAttributeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserAttributeByIdArgs = {
  input: UpdateUserAttributeByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByEmailArgs = {
  input: UpdateUserByEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserByIdArgs = {
  input: UpdateUserByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserConfigArgs = {
  input: UpdateUserConfigInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserConfigByDataRoomIdAndEmailArgs = {
  input: UpdateUserConfigByDataRoomIdAndEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserConfigByIdArgs = {
  input: UpdateUserConfigByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserConfigsArgs = {
  input: UpdateUserConfigsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserPermissionArgs = {
  input: UpdateUserPermissionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserPermissionAsElementArgs = {
  input: UpdateUserPermissionAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserPermissionAsElementByIdArgs = {
  input: UpdateUserPermissionAsElementByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserPermissionAsElementsArgs = {
  input: UpdateUserPermissionAsElementsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserPermissionByDataRoomIdAndEmailArgs = {
  input: UpdateUserPermissionByDataRoomIdAndEmailInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserPermissionByIdArgs = {
  input: UpdateUserPermissionByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserPermissionsArgs = {
  input: UpdateUserPermissionsInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserQuotaArgs = {
  input: UpdateUserQuotaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserQuotaByIdArgs = {
  input: UpdateUserQuotaByIdInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUserQuotasArgs = {
  input: UpdateUserQuotasInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpdateUsersArgs = {
  input: UpdateUsersInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertAttestationSpecificationAsElementArgs = {
  input: UpsertAttestationSpecificationAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertAuthenticationMethodAsElementArgs = {
  input: UpsertAuthenticationMethodAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertComputeNodeArgs = {
  input: UpsertComputeNodeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertComputeNodeAsElementArgs = {
  input: UpsertComputeNodeAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertConfigArgs = {
  input: UpsertConfigInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertConfigurationArgs = {
  input: UpsertConfigurationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertDataRoomArgs = {
  input: UpsertDataRoomInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertDatasetLinkArgs = {
  input: UpsertDatasetLinkInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertDatasetMetaArgs = {
  input: UpsertDatasetMetaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertDatasetStatArgs = {
  input: UpsertDatasetStatInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertDomainArgs = {
  input: UpsertDomainInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertExecutionTimeArgs = {
  input: UpsertExecutionTimeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertJobArgs = {
  input: UpsertJobInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertLockArgs = {
  input: UpsertLockInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertModificationArgs = {
  input: UpsertModificationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertOrganizationArgs = {
  input: UpsertOrganizationInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertOrganizationAttributeArgs = {
  input: UpsertOrganizationAttributeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertOrganizationLogoArgs = {
  input: UpsertOrganizationLogoInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertOrganizationMembershipArgs = {
  input: UpsertOrganizationMembershipInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertOrganizationQuotaArgs = {
  input: UpsertOrganizationQuotaInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertPermissionArgs = {
  input: UpsertPermissionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertPermissionAsElementArgs = {
  input: UpsertPermissionAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertRequestArgs = {
  input: UpsertRequestInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertResourceArgs = {
  input: UpsertResourceInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertSignatureArgs = {
  input: UpsertSignatureInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertSigneeArgs = {
  input: UpsertSigneeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertStateArgs = {
  input: UpsertStateInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertUserArgs = {
  input: UpsertUserInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertUserAttributeArgs = {
  input: UpsertUserAttributeInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertUserConfigArgs = {
  input: UpsertUserConfigInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertUserPermissionArgs = {
  input: UpsertUserPermissionInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertUserPermissionAsElementArgs = {
  input: UpsertUserPermissionAsElementInput;
};

/** The root mutation type which contains root level fields which mutate data. */
export type MutationUpsertUserQuotaArgs = {
  input: UpsertUserQuotaInput;
};

/** An object with a globally unique `ID`. */
export type Node = {
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
};

export type Organization = Node & {
  __typename?: "Organization";
  containerMetadata?: Maybe<Scalars["JSON"]>;
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  description?: Maybe<Scalars["String"]>;
  /** Reads and enables pagination through a set of `Domain`. */
  domains: DomainsConnection;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  isSaving?: Maybe<Scalars["Boolean"]>;
  name: Scalars["String"];
  /** Reads a single `OrganizationAttribute` that is related to this `Organization`. */
  organizationAttribute?: Maybe<OrganizationAttribute>;
  organizationId: Scalars["UUID"];
  /** Reads a single `OrganizationLogo` that is related to this `Organization`. */
  organizationLogo?: Maybe<OrganizationLogo>;
  /** Reads and enables pagination through a set of `OrganizationMembership`. */
  organizationMemberships: OrganizationMembershipsConnection;
  /** Reads a single `OrganizationQuota` that is related to this `Organization`. */
  organizationQuota?: Maybe<OrganizationQuota>;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type OrganizationDomainsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DomainCondition>;
  filter?: InputMaybe<DomainFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DomainsOrderBy>>;
};

export type OrganizationOrganizationMembershipsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<OrganizationMembershipCondition>;
  filter?: InputMaybe<OrganizationMembershipFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrganizationMembershipsOrderBy>>;
};

export type OrganizationAggregates = {
  __typename?: "OrganizationAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OrganizationDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

export type OrganizationAttribute = Node & {
  __typename?: "OrganizationAttribute";
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  isPostComputeEnabled: Scalars["Boolean"];
  /** Reads a single `Organization` that is related to this `OrganizationAttribute`. */
  organization?: Maybe<Organization>;
  organizationId: Scalars["UUID"];
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type OrganizationAttributeAggregates = {
  __typename?: "OrganizationAttributeAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OrganizationAttributeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/**
 * A condition to be used against `OrganizationAttribute` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type OrganizationAttributeCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `isPostComputeEnabled` field. */
  isPostComputeEnabled?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type OrganizationAttributeDistinct = {
  __typename?: "OrganizationAttributeDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `isPostComputeEnabled` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  isPostComputeEnabled: Array<Scalars["Boolean"]>;
  /** Distinct `organizationId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  organizationId: Array<Scalars["UUID"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type OrganizationAttributeDistinctCountAggregates = {
  __typename?: "OrganizationAttributeDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of isPostComputeEnabled across the matching connection */
  isPostComputeEnabled?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of organizationId across the matching connection */
  organizationId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `OrganizationAttribute` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationAttributeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OrganizationAttributeFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isPostComputeEnabled` field. */
  isPostComputeEnabled?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OrganizationAttributeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OrganizationAttributeFilter>>;
  /** Filter by the object’s `organization` relation. */
  organization?: InputMaybe<OrganizationFilter>;
  /** Filter by the object’s `organizationId` field. */
  organizationId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

/** An input for mutations affecting `OrganizationAttribute` */
export type OrganizationAttributeInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  isPostComputeEnabled?: InputMaybe<Scalars["Boolean"]>;
  organization?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type OrganizationAttributeNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `organizationAttribute` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type OrganizationAttributeNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `organizationAttribute` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type OrganizationAttributeOnOrganizationAttributeForOrganizationAttributesOrganizationIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `organization` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `organization` being updated. */
    patch: OrganizationPatch;
  };

/** The fields on `organizationAttribute` to look up the row to update. */
export type OrganizationAttributeOnOrganizationAttributeForOrganizationAttributesOrganizationIdFkeyUsingOrganizationAttributesPkeyUpdate =
  {
    organizationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `organizationAttribute` being updated. */
    patch: UpdateOrganizationAttributeOnOrganizationAttributeForOrganizationAttributesOrganizationIdFkeyPatch;
  };

/** The fields on `organizationAttribute` to look up the row to connect. */
export type OrganizationAttributeOrganizationAttributesPkeyConnect = {
  organizationId: Scalars["UUID"];
};

/** The fields on `organizationAttribute` to look up the row to delete. */
export type OrganizationAttributeOrganizationAttributesPkeyDelete = {
  organizationId: Scalars["UUID"];
};

/** Represents an update to a `OrganizationAttribute`. Fields that are set will be updated. */
export type OrganizationAttributePatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  isPostComputeEnabled?: InputMaybe<Scalars["Boolean"]>;
  organization?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** A connection to a list of `OrganizationAttribute` values. */
export type OrganizationAttributesConnection = {
  __typename?: "OrganizationAttributesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OrganizationAttributeAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<OrganizationAttributeDistinct>;
  /** A list of edges which contains the `OrganizationAttribute` and cursor to aid in pagination. */
  edges: Array<OrganizationAttributesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OrganizationAttributeAggregates>>;
  /** A list of `OrganizationAttribute` objects. */
  nodes: Array<Maybe<OrganizationAttribute>>;
  /** A list of _all_ `OrganizationAttribute` primary keys. */
  organizationIds?: Maybe<Array<Scalars["UUID"]>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OrganizationAttribute` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `OrganizationAttribute` values. */
export type OrganizationAttributesConnectionGroupedAggregatesArgs = {
  groupBy: Array<OrganizationAttributesGroupBy>;
  having?: InputMaybe<OrganizationAttributesHavingInput>;
};

/** A `OrganizationAttribute` edge in the connection. */
export type OrganizationAttributesEdge = {
  __typename?: "OrganizationAttributesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `OrganizationAttribute` at the end of the edge. */
  node?: Maybe<OrganizationAttribute>;
};

/** Grouping methods for `OrganizationAttribute` for usage during aggregation. */
export enum OrganizationAttributesGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  IsPostComputeEnabled = "IS_POST_COMPUTE_ENABLED",
  OrganizationByOrganizationIdContainerMetadata = "ORGANIZATION_BY_ORGANIZATION_ID_CONTAINER_METADATA",
  OrganizationByOrganizationIdCreatedAt = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_AT",
  OrganizationByOrganizationIdCreatedByEmail = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_BY_EMAIL",
  OrganizationByOrganizationIdDescription = "ORGANIZATION_BY_ORGANIZATION_ID_DESCRIPTION",
  OrganizationByOrganizationIdName = "ORGANIZATION_BY_ORGANIZATION_ID_NAME",
  OrganizationByOrganizationIdOrganizationId = "ORGANIZATION_BY_ORGANIZATION_ID_ORGANIZATION_ID",
  OrganizationByOrganizationIdUpdatedAt = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_AT",
  OrganizationByOrganizationIdUpdatedByEmail = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type OrganizationAttributesHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationAttributesHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `OrganizationAttribute` aggregates. */
export type OrganizationAttributesHavingInput = {
  AND?: InputMaybe<Array<OrganizationAttributesHavingInput>>;
  OR?: InputMaybe<Array<OrganizationAttributesHavingInput>>;
  average?: InputMaybe<OrganizationAttributesHavingAverageInput>;
  distinctCount?: InputMaybe<OrganizationAttributesHavingDistinctCountInput>;
  max?: InputMaybe<OrganizationAttributesHavingMaxInput>;
  min?: InputMaybe<OrganizationAttributesHavingMinInput>;
  stddevPopulation?: InputMaybe<OrganizationAttributesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<OrganizationAttributesHavingStddevSampleInput>;
  sum?: InputMaybe<OrganizationAttributesHavingSumInput>;
  variancePopulation?: InputMaybe<OrganizationAttributesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<OrganizationAttributesHavingVarianceSampleInput>;
};

export type OrganizationAttributesHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationAttributesHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationAttributesHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationAttributesHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationAttributesHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationAttributesHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationAttributesHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `OrganizationAttribute`. */
export enum OrganizationAttributesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  IsPostComputeEnabledAsc = "IS_POST_COMPUTE_ENABLED_ASC",
  IsPostComputeEnabledDesc = "IS_POST_COMPUTE_ENABLED_DESC",
  Natural = "NATURAL",
  OrganizationByOrganizationIdContainerMetadataAsc = "ORGANIZATION_BY_ORGANIZATION_ID_CONTAINER_METADATA_ASC",
  OrganizationByOrganizationIdContainerMetadataDesc = "ORGANIZATION_BY_ORGANIZATION_ID_CONTAINER_METADATA_DESC",
  OrganizationByOrganizationIdCreatedAtAsc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_AT_ASC",
  OrganizationByOrganizationIdCreatedAtDesc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_AT_DESC",
  OrganizationByOrganizationIdCreatedByEmailAsc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_ASC",
  OrganizationByOrganizationIdCreatedByEmailDesc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_DESC",
  OrganizationByOrganizationIdDescriptionAsc = "ORGANIZATION_BY_ORGANIZATION_ID_DESCRIPTION_ASC",
  OrganizationByOrganizationIdDescriptionDesc = "ORGANIZATION_BY_ORGANIZATION_ID_DESCRIPTION_DESC",
  OrganizationByOrganizationIdNameAsc = "ORGANIZATION_BY_ORGANIZATION_ID_NAME_ASC",
  OrganizationByOrganizationIdNameDesc = "ORGANIZATION_BY_ORGANIZATION_ID_NAME_DESC",
  OrganizationByOrganizationIdOrganizationIdAsc = "ORGANIZATION_BY_ORGANIZATION_ID_ORGANIZATION_ID_ASC",
  OrganizationByOrganizationIdOrganizationIdDesc = "ORGANIZATION_BY_ORGANIZATION_ID_ORGANIZATION_ID_DESC",
  OrganizationByOrganizationIdUpdatedAtAsc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_AT_ASC",
  OrganizationByOrganizationIdUpdatedAtDesc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_AT_DESC",
  OrganizationByOrganizationIdUpdatedByEmailAsc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_ASC",
  OrganizationByOrganizationIdUpdatedByEmailDesc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_DESC",
  OrganizationIdAsc = "ORGANIZATION_ID_ASC",
  OrganizationIdDesc = "ORGANIZATION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

/** Input for the nested mutation of `organization` in the `OrganizationAttributeInput` mutation. */
export type OrganizationAttributesOrganizationIdFkeyInput = {
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectById?: InputMaybe<OrganizationNodeIdConnect>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByOrganizationId?: InputMaybe<OrganizationOrganizationPkeyConnect>;
  /** A `OrganizationInput` object that will be created and connected to this object. */
  create?: InputMaybe<OrganizationAttributesOrganizationIdFkeyOrganizationCreateInput>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteById?: InputMaybe<OrganizationNodeIdDelete>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByOrganizationId?: InputMaybe<OrganizationOrganizationPkeyDelete>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateById?: InputMaybe<OrganizationAttributeOnOrganizationAttributeForOrganizationAttributesOrganizationIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByOrganizationId?: InputMaybe<OrganizationOnOrganizationAttributeForOrganizationAttributesOrganizationIdFkeyUsingOrganizationPkeyUpdate>;
};

/** Input for the nested mutation of `organizationAttribute` in the `OrganizationInput` mutation. */
export type OrganizationAttributesOrganizationIdFkeyInverseInput = {
  /** The primary key(s) for `organizationAttribute` for the far side of the relationship. */
  connectById?: InputMaybe<OrganizationAttributeNodeIdConnect>;
  /** The primary key(s) for `organizationAttribute` for the far side of the relationship. */
  connectByOrganizationId?: InputMaybe<OrganizationAttributeOrganizationAttributesPkeyConnect>;
  /** A `OrganizationAttributeInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<OrganizationAttributesOrganizationIdFkeyOrganizationAttributesCreateInput>
  >;
  /** The primary key(s) for `organizationAttribute` for the far side of the relationship. */
  deleteById?: InputMaybe<OrganizationAttributeNodeIdDelete>;
  /** The primary key(s) for `organizationAttribute` for the far side of the relationship. */
  deleteByOrganizationId?: InputMaybe<OrganizationAttributeOrganizationAttributesPkeyDelete>;
  /** Flag indicating whether all other `organizationAttribute` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `organizationAttribute` for the far side of the relationship. */
  updateById?: InputMaybe<OrganizationOnOrganizationAttributeForOrganizationAttributesOrganizationIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `organizationAttribute` for the far side of the relationship. */
  updateByOrganizationId?: InputMaybe<OrganizationAttributeOnOrganizationAttributeForOrganizationAttributesOrganizationIdFkeyUsingOrganizationAttributesPkeyUpdate>;
};

/** The `organizationAttribute` to be created by this mutation. */
export type OrganizationAttributesOrganizationIdFkeyOrganizationAttributesCreateInput =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    isPostComputeEnabled?: InputMaybe<Scalars["Boolean"]>;
    organization?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInput>;
    organizationId?: InputMaybe<Scalars["UUID"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** The `organization` to be created by this mutation. */
export type OrganizationAttributesOrganizationIdFkeyOrganizationCreateInput = {
  containerMetadata?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  domains?: InputMaybe<DomainOrganizationIdFkeyInverseInput>;
  name: Scalars["String"];
  organizationAttribute?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  organizationLogo?: InputMaybe<OrganizationLogoOrganizationIdFkeyInverseInput>;
  organizationMemberships?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInverseInput>;
  organizationQuota?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/**
 * A condition to be used against `Organization` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OrganizationCondition = {
  /** Checks for equality with the object’s `containerMetadata` field. */
  containerMetadata?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `description` field. */
  description?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type OrganizationDistinct = {
  __typename?: "OrganizationDistinct";
  /** Distinct `containerMetadata` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  containerMetadata: Array<Maybe<Scalars["JSON"]>>;
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `description` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  description: Array<Maybe<Scalars["String"]>>;
  /** Distinct `name` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  name: Array<Scalars["String"]>;
  /** Distinct `organizationId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  organizationId: Array<Scalars["UUID"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type OrganizationDistinctCountAggregates = {
  __typename?: "OrganizationDistinctCountAggregates";
  /** Distinct count of containerMetadata across the matching connection */
  containerMetadata?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of description across the matching connection */
  description?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of organizationId across the matching connection */
  organizationId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `Organization` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OrganizationFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `description` field. */
  description?: InputMaybe<StringFilter>;
  /** Filter by the object’s `domains` relation. */
  domains?: InputMaybe<OrganizationToManyDomainFilter>;
  /** Some related `domains` exist. */
  domainsExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OrganizationFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OrganizationFilter>>;
  /** Filter by the object’s `organizationAttribute` relation. */
  organizationAttribute?: InputMaybe<OrganizationAttributeFilter>;
  /** A related `organizationAttribute` exists. */
  organizationAttributeExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `organizationId` field. */
  organizationId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `organizationLogo` relation. */
  organizationLogo?: InputMaybe<OrganizationLogoFilter>;
  /** A related `organizationLogo` exists. */
  organizationLogoExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `organizationMemberships` relation. */
  organizationMemberships?: InputMaybe<OrganizationToManyOrganizationMembershipFilter>;
  /** Some related `organizationMemberships` exist. */
  organizationMembershipsExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `organizationQuota` relation. */
  organizationQuota?: InputMaybe<OrganizationQuotaFilter>;
  /** A related `organizationQuota` exists. */
  organizationQuotaExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type OrganizationGroup = {
  __typename?: "OrganizationGroup";
  /** `OrganizationFilter` that was used to implicitly filter `organizations`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** A list of _all_ `Organization` primary keys that belong to the `OrganizationGroup`. */
  organizationIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `Organization` of this `OrganizationGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `OrganizationGroup`. Overriding these may result in the wrong set of `Organization` returned.
   */
  organizations?: Maybe<OrganizationsConnection>;
  /** The count of _all_ `Organization` you could get from the `OrganizationGroup`. */
  totalCount: Scalars["Int"];
};

export type OrganizationGroupOrganizationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<OrganizationCondition>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

/** Grouping methods for `Organization` for usage during aggregation. */
export enum OrganizationGroupBy {
  ContainerMetadata = "CONTAINER_METADATA",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  Description = "DESCRIPTION",
  Name = "NAME",
  OrganizationAttributeByOrganizationIdCreatedAt = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_CREATED_AT",
  OrganizationAttributeByOrganizationIdCreatedByEmail = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_CREATED_BY_EMAIL",
  OrganizationAttributeByOrganizationIdIsPostComputeEnabled = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_IS_POST_COMPUTE_ENABLED",
  OrganizationAttributeByOrganizationIdOrganizationId = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_ORGANIZATION_ID",
  OrganizationAttributeByOrganizationIdUpdatedAt = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_UPDATED_AT",
  OrganizationAttributeByOrganizationIdUpdatedByEmail = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL",
  OrganizationLogoByOrganizationIdCreatedAt = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_CREATED_AT",
  OrganizationLogoByOrganizationIdCreatedByEmail = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_CREATED_BY_EMAIL",
  OrganizationLogoByOrganizationIdLogo = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_LOGO",
  OrganizationLogoByOrganizationIdOrganizationId = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_ORGANIZATION_ID",
  OrganizationLogoByOrganizationIdUpdatedAt = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_UPDATED_AT",
  OrganizationLogoByOrganizationIdUpdatedByEmail = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL",
  OrganizationQuotaByOrganizationIdAllowed = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_ALLOWED",
  OrganizationQuotaByOrganizationIdCreatedAt = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_CREATED_AT",
  OrganizationQuotaByOrganizationIdCreatedByEmail = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_CREATED_BY_EMAIL",
  OrganizationQuotaByOrganizationIdOrganizationId = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_ORGANIZATION_ID",
  OrganizationQuotaByOrganizationIdUpdatedAt = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_UPDATED_AT",
  OrganizationQuotaByOrganizationIdUpdatedByEmail = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type OrganizationHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Organization` aggregates. */
export type OrganizationHavingInput = {
  AND?: InputMaybe<Array<OrganizationHavingInput>>;
  OR?: InputMaybe<Array<OrganizationHavingInput>>;
  average?: InputMaybe<OrganizationHavingAverageInput>;
  distinctCount?: InputMaybe<OrganizationHavingDistinctCountInput>;
  max?: InputMaybe<OrganizationHavingMaxInput>;
  min?: InputMaybe<OrganizationHavingMinInput>;
  stddevPopulation?: InputMaybe<OrganizationHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<OrganizationHavingStddevSampleInput>;
  sum?: InputMaybe<OrganizationHavingSumInput>;
  variancePopulation?: InputMaybe<OrganizationHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<OrganizationHavingVarianceSampleInput>;
};

export type OrganizationHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Organization` */
export type OrganizationInput = {
  containerMetadata?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  domains?: InputMaybe<DomainOrganizationIdFkeyInverseInput>;
  name: Scalars["String"];
  organizationAttribute?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  organizationLogo?: InputMaybe<OrganizationLogoOrganizationIdFkeyInverseInput>;
  organizationMemberships?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInverseInput>;
  organizationQuota?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type OrganizationLogo = Node & {
  __typename?: "OrganizationLogo";
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  logo: Scalars["String"];
  /** Reads a single `Organization` that is related to this `OrganizationLogo`. */
  organization?: Maybe<Organization>;
  organizationId: Scalars["UUID"];
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type OrganizationLogoAggregates = {
  __typename?: "OrganizationLogoAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OrganizationLogoDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/**
 * A condition to be used against `OrganizationLogo` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OrganizationLogoCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `logo` field. */
  logo?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type OrganizationLogoDistinct = {
  __typename?: "OrganizationLogoDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `logo` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  logo: Array<Scalars["String"]>;
  /** Distinct `organizationId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  organizationId: Array<Scalars["UUID"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type OrganizationLogoDistinctCountAggregates = {
  __typename?: "OrganizationLogoDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of logo across the matching connection */
  logo?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of organizationId across the matching connection */
  organizationId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `OrganizationLogo` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationLogoFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OrganizationLogoFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OrganizationLogoFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OrganizationLogoFilter>>;
  /** Filter by the object’s `organization` relation. */
  organization?: InputMaybe<OrganizationFilter>;
  /** Filter by the object’s `organizationId` field. */
  organizationId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type OrganizationLogoGroup = {
  __typename?: "OrganizationLogoGroup";
  /** `OrganizationLogoFilter` that was used to implicitly filter `organizationLogos`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** A list of _all_ `OrganizationLogo` primary keys that belong to the `OrganizationLogoGroup`. */
  organizationIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `OrganizationLogo` of this `OrganizationLogoGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `OrganizationLogoGroup`. Overriding these may result in the wrong set of `OrganizationLogo` returned.
   */
  organizationLogos?: Maybe<OrganizationLogosConnection>;
  /** The count of _all_ `OrganizationLogo` you could get from the `OrganizationLogoGroup`. */
  totalCount: Scalars["Int"];
};

export type OrganizationLogoGroupOrganizationLogosArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<OrganizationLogoCondition>;
  filter?: InputMaybe<OrganizationLogoFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrganizationLogosOrderBy>>;
};

/** Grouping methods for `OrganizationLogo` for usage during aggregation. */
export enum OrganizationLogoGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  Logo = "LOGO",
  OrganizationByOrganizationIdContainerMetadata = "ORGANIZATION_BY_ORGANIZATION_ID_CONTAINER_METADATA",
  OrganizationByOrganizationIdCreatedAt = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_AT",
  OrganizationByOrganizationIdCreatedByEmail = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_BY_EMAIL",
  OrganizationByOrganizationIdDescription = "ORGANIZATION_BY_ORGANIZATION_ID_DESCRIPTION",
  OrganizationByOrganizationIdName = "ORGANIZATION_BY_ORGANIZATION_ID_NAME",
  OrganizationByOrganizationIdOrganizationId = "ORGANIZATION_BY_ORGANIZATION_ID_ORGANIZATION_ID",
  OrganizationByOrganizationIdUpdatedAt = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_AT",
  OrganizationByOrganizationIdUpdatedByEmail = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type OrganizationLogoHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationLogoHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `OrganizationLogo` aggregates. */
export type OrganizationLogoHavingInput = {
  AND?: InputMaybe<Array<OrganizationLogoHavingInput>>;
  OR?: InputMaybe<Array<OrganizationLogoHavingInput>>;
  average?: InputMaybe<OrganizationLogoHavingAverageInput>;
  distinctCount?: InputMaybe<OrganizationLogoHavingDistinctCountInput>;
  max?: InputMaybe<OrganizationLogoHavingMaxInput>;
  min?: InputMaybe<OrganizationLogoHavingMinInput>;
  stddevPopulation?: InputMaybe<OrganizationLogoHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<OrganizationLogoHavingStddevSampleInput>;
  sum?: InputMaybe<OrganizationLogoHavingSumInput>;
  variancePopulation?: InputMaybe<OrganizationLogoHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<OrganizationLogoHavingVarianceSampleInput>;
};

export type OrganizationLogoHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationLogoHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationLogoHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationLogoHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationLogoHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationLogoHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationLogoHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `OrganizationLogo` */
export type OrganizationLogoInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  logo?: InputMaybe<Scalars["Upload"]>;
  organization?: InputMaybe<OrganizationLogoOrganizationIdFkeyInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type OrganizationLogoNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `organizationLogo` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type OrganizationLogoNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `organizationLogo` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type OrganizationLogoOnOrganizationLogoForOrganizationLogoOrganizationIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `organization` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `organization` being updated. */
    patch: OrganizationPatch;
  };

/** The fields on `organizationLogo` to look up the row to update. */
export type OrganizationLogoOnOrganizationLogoForOrganizationLogoOrganizationIdFkeyUsingOrganizationLogoPkeyUpdate =
  {
    organizationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `organizationLogo` being updated. */
    patch: UpdateOrganizationLogoOnOrganizationLogoForOrganizationLogoOrganizationIdFkeyPatch;
  };

/** Input for the nested mutation of `organization` in the `OrganizationLogoInput` mutation. */
export type OrganizationLogoOrganizationIdFkeyInput = {
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectById?: InputMaybe<OrganizationNodeIdConnect>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByOrganizationId?: InputMaybe<OrganizationOrganizationPkeyConnect>;
  /** A `OrganizationInput` object that will be created and connected to this object. */
  create?: InputMaybe<OrganizationLogoOrganizationIdFkeyOrganizationCreateInput>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteById?: InputMaybe<OrganizationNodeIdDelete>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByOrganizationId?: InputMaybe<OrganizationOrganizationPkeyDelete>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateById?: InputMaybe<OrganizationLogoOnOrganizationLogoForOrganizationLogoOrganizationIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByOrganizationId?: InputMaybe<OrganizationOnOrganizationLogoForOrganizationLogoOrganizationIdFkeyUsingOrganizationPkeyUpdate>;
};

/** Input for the nested mutation of `organizationLogo` in the `OrganizationInput` mutation. */
export type OrganizationLogoOrganizationIdFkeyInverseInput = {
  /** The primary key(s) for `organizationLogo` for the far side of the relationship. */
  connectById?: InputMaybe<OrganizationLogoNodeIdConnect>;
  /** The primary key(s) for `organizationLogo` for the far side of the relationship. */
  connectByOrganizationId?: InputMaybe<OrganizationLogoOrganizationLogoPkeyConnect>;
  /** A `OrganizationLogoInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<OrganizationLogoOrganizationIdFkeyOrganizationLogoCreateInput>
  >;
  /** The primary key(s) for `organizationLogo` for the far side of the relationship. */
  deleteById?: InputMaybe<OrganizationLogoNodeIdDelete>;
  /** The primary key(s) for `organizationLogo` for the far side of the relationship. */
  deleteByOrganizationId?: InputMaybe<OrganizationLogoOrganizationLogoPkeyDelete>;
  /** Flag indicating whether all other `organizationLogo` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `organizationLogo` for the far side of the relationship. */
  updateById?: InputMaybe<OrganizationOnOrganizationLogoForOrganizationLogoOrganizationIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `organizationLogo` for the far side of the relationship. */
  updateByOrganizationId?: InputMaybe<OrganizationLogoOnOrganizationLogoForOrganizationLogoOrganizationIdFkeyUsingOrganizationLogoPkeyUpdate>;
};

/** The `organization` to be created by this mutation. */
export type OrganizationLogoOrganizationIdFkeyOrganizationCreateInput = {
  containerMetadata?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  domains?: InputMaybe<DomainOrganizationIdFkeyInverseInput>;
  name: Scalars["String"];
  organizationAttribute?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  organizationLogo?: InputMaybe<OrganizationLogoOrganizationIdFkeyInverseInput>;
  organizationMemberships?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInverseInput>;
  organizationQuota?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The `organizationLogo` to be created by this mutation. */
export type OrganizationLogoOrganizationIdFkeyOrganizationLogoCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  logo?: InputMaybe<Scalars["Upload"]>;
  organization?: InputMaybe<OrganizationLogoOrganizationIdFkeyInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The fields on `organizationLogo` to look up the row to connect. */
export type OrganizationLogoOrganizationLogoPkeyConnect = {
  organizationId: Scalars["UUID"];
};

/** The fields on `organizationLogo` to look up the row to delete. */
export type OrganizationLogoOrganizationLogoPkeyDelete = {
  organizationId: Scalars["UUID"];
};

/** Represents an update to a `OrganizationLogo`. Fields that are set will be updated. */
export type OrganizationLogoPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  logo?: InputMaybe<Scalars["Upload"]>;
  organization?: InputMaybe<OrganizationLogoOrganizationIdFkeyInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** A connection to a list of `OrganizationLogo` values. */
export type OrganizationLogosConnection = {
  __typename?: "OrganizationLogosConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OrganizationLogoAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<OrganizationLogoDistinct>;
  /** A list of edges which contains the `OrganizationLogo` and cursor to aid in pagination. */
  edges: Array<OrganizationLogosEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OrganizationLogoAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<OrganizationLogoGroup>>;
  /** A list of `OrganizationLogo` objects. */
  nodes: Array<Maybe<OrganizationLogo>>;
  /** A list of _all_ `OrganizationLogo` primary keys. */
  organizationIds?: Maybe<Array<Scalars["UUID"]>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OrganizationLogo` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `OrganizationLogo` values. */
export type OrganizationLogosConnectionGroupedAggregatesArgs = {
  groupBy: Array<OrganizationLogoGroupBy>;
  having?: InputMaybe<OrganizationLogoHavingInput>;
};

/** A connection to a list of `OrganizationLogo` values. */
export type OrganizationLogosConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<OrganizationLogoGroupBy>;
  having?: InputMaybe<OrganizationLogoHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `OrganizationLogo` edge in the connection. */
export type OrganizationLogosEdge = {
  __typename?: "OrganizationLogosEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `OrganizationLogo` at the end of the edge. */
  node?: Maybe<OrganizationLogo>;
};

/** Methods to use when ordering `OrganizationLogo`. */
export enum OrganizationLogosOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  LogoAsc = "LOGO_ASC",
  LogoDesc = "LOGO_DESC",
  Natural = "NATURAL",
  OrganizationByOrganizationIdContainerMetadataAsc = "ORGANIZATION_BY_ORGANIZATION_ID_CONTAINER_METADATA_ASC",
  OrganizationByOrganizationIdContainerMetadataDesc = "ORGANIZATION_BY_ORGANIZATION_ID_CONTAINER_METADATA_DESC",
  OrganizationByOrganizationIdCreatedAtAsc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_AT_ASC",
  OrganizationByOrganizationIdCreatedAtDesc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_AT_DESC",
  OrganizationByOrganizationIdCreatedByEmailAsc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_ASC",
  OrganizationByOrganizationIdCreatedByEmailDesc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_DESC",
  OrganizationByOrganizationIdDescriptionAsc = "ORGANIZATION_BY_ORGANIZATION_ID_DESCRIPTION_ASC",
  OrganizationByOrganizationIdDescriptionDesc = "ORGANIZATION_BY_ORGANIZATION_ID_DESCRIPTION_DESC",
  OrganizationByOrganizationIdNameAsc = "ORGANIZATION_BY_ORGANIZATION_ID_NAME_ASC",
  OrganizationByOrganizationIdNameDesc = "ORGANIZATION_BY_ORGANIZATION_ID_NAME_DESC",
  OrganizationByOrganizationIdOrganizationIdAsc = "ORGANIZATION_BY_ORGANIZATION_ID_ORGANIZATION_ID_ASC",
  OrganizationByOrganizationIdOrganizationIdDesc = "ORGANIZATION_BY_ORGANIZATION_ID_ORGANIZATION_ID_DESC",
  OrganizationByOrganizationIdUpdatedAtAsc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_AT_ASC",
  OrganizationByOrganizationIdUpdatedAtDesc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_AT_DESC",
  OrganizationByOrganizationIdUpdatedByEmailAsc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_ASC",
  OrganizationByOrganizationIdUpdatedByEmailDesc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_DESC",
  OrganizationIdAsc = "ORGANIZATION_ID_ASC",
  OrganizationIdDesc = "ORGANIZATION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

export type OrganizationMembership = Node & {
  __typename?: "OrganizationMembership";
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  /** Reads a single `Organization` that is related to this `OrganizationMembership`. */
  organization?: Maybe<Organization>;
  organizationId?: Maybe<Scalars["UUID"]>;
  organizationMembershipId: Scalars["UUID"];
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
  /** Reads a single `User` that is related to this `OrganizationMembership`. */
  user?: Maybe<User>;
  userId: Scalars["UUID"];
};

export type OrganizationMembershipAggregates = {
  __typename?: "OrganizationMembershipAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OrganizationMembershipDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A filter to be used against aggregates of `OrganizationMembership` object types. */
export type OrganizationMembershipAggregatesFilter = {
  /** Distinct count aggregate over matching `OrganizationMembership` objects. */
  distinctCount?: InputMaybe<OrganizationMembershipDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `OrganizationMembership` object to be included within the aggregate. */
  filter?: InputMaybe<OrganizationMembershipFilter>;
};

/**
 * A condition to be used against `OrganizationMembership` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type OrganizationMembershipCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `organizationMembershipId` field. */
  organizationMembershipId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]>;
};

export type OrganizationMembershipDistinct = {
  __typename?: "OrganizationMembershipDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `organizationId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  organizationId: Array<Maybe<Scalars["UUID"]>>;
  /** Distinct `organizationMembershipId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  organizationMembershipId: Array<Scalars["UUID"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
  /** Distinct `userId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  userId: Array<Scalars["UUID"]>;
};

export type OrganizationMembershipDistinctCountAggregateFilter = {
  createdAt?: InputMaybe<BigIntFilter>;
  createdByEmail?: InputMaybe<BigIntFilter>;
  organizationId?: InputMaybe<BigIntFilter>;
  organizationMembershipId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  updatedByEmail?: InputMaybe<BigIntFilter>;
  userId?: InputMaybe<BigIntFilter>;
};

export type OrganizationMembershipDistinctCountAggregates = {
  __typename?: "OrganizationMembershipDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of organizationId across the matching connection */
  organizationId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of organizationMembershipId across the matching connection */
  organizationMembershipId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `OrganizationMembership` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationMembershipFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OrganizationMembershipFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OrganizationMembershipFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OrganizationMembershipFilter>>;
  /** Filter by the object’s `organization` relation. */
  organization?: InputMaybe<OrganizationFilter>;
  /** A related `organization` exists. */
  organizationExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `organizationId` field. */
  organizationId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `organizationMembershipId` field. */
  organizationMembershipId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

export type OrganizationMembershipGroup = {
  __typename?: "OrganizationMembershipGroup";
  /** `OrganizationMembershipFilter` that was used to implicitly filter `organizationMemberships`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** A list of _all_ `OrganizationMembership` primary keys that belong to the `OrganizationMembershipGroup`. */
  organizationMembershipIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `OrganizationMembership` of this `OrganizationMembershipGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `OrganizationMembershipGroup`. Overriding these may result in the wrong set of `OrganizationMembership` returned.
   */
  organizationMemberships?: Maybe<OrganizationMembershipsConnection>;
  /** The count of _all_ `OrganizationMembership` you could get from the `OrganizationMembershipGroup`. */
  totalCount: Scalars["Int"];
};

export type OrganizationMembershipGroupOrganizationMembershipsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<OrganizationMembershipCondition>;
  filter?: InputMaybe<OrganizationMembershipFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrganizationMembershipsOrderBy>>;
};

/** Grouping methods for `OrganizationMembership` for usage during aggregation. */
export enum OrganizationMembershipGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  OrganizationByOrganizationIdContainerMetadata = "ORGANIZATION_BY_ORGANIZATION_ID_CONTAINER_METADATA",
  OrganizationByOrganizationIdCreatedAt = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_AT",
  OrganizationByOrganizationIdCreatedByEmail = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_BY_EMAIL",
  OrganizationByOrganizationIdDescription = "ORGANIZATION_BY_ORGANIZATION_ID_DESCRIPTION",
  OrganizationByOrganizationIdName = "ORGANIZATION_BY_ORGANIZATION_ID_NAME",
  OrganizationByOrganizationIdOrganizationId = "ORGANIZATION_BY_ORGANIZATION_ID_ORGANIZATION_ID",
  OrganizationByOrganizationIdUpdatedAt = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_AT",
  OrganizationByOrganizationIdUpdatedByEmail = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL",
  OrganizationId = "ORGANIZATION_ID",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
  UserByUserIdCreatedAt = "USER_BY_USER_ID_CREATED_AT",
  UserByUserIdCreatedByEmail = "USER_BY_USER_ID_CREATED_BY_EMAIL",
  UserByUserIdDomain = "USER_BY_USER_ID_DOMAIN",
  UserByUserIdEmail = "USER_BY_USER_ID_EMAIL",
  UserByUserIdEmailVerified = "USER_BY_USER_ID_EMAIL_VERIFIED",
  UserByUserIdFamilyName = "USER_BY_USER_ID_FAMILY_NAME",
  UserByUserIdGivenName = "USER_BY_USER_ID_GIVEN_NAME",
  UserByUserIdName = "USER_BY_USER_ID_NAME",
  UserByUserIdNickname = "USER_BY_USER_ID_NICKNAME",
  UserByUserIdPicture = "USER_BY_USER_ID_PICTURE",
  UserByUserIdUpdatedAt = "USER_BY_USER_ID_UPDATED_AT",
  UserByUserIdUpdatedByEmail = "USER_BY_USER_ID_UPDATED_BY_EMAIL",
  UserByUserIdUpstreamUserId = "USER_BY_USER_ID_UPSTREAM_USER_ID",
  UserByUserIdUserId = "USER_BY_USER_ID_USER_ID",
  UserId = "USER_ID",
}

export type OrganizationMembershipHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationMembershipHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `OrganizationMembership` aggregates. */
export type OrganizationMembershipHavingInput = {
  AND?: InputMaybe<Array<OrganizationMembershipHavingInput>>;
  OR?: InputMaybe<Array<OrganizationMembershipHavingInput>>;
  average?: InputMaybe<OrganizationMembershipHavingAverageInput>;
  distinctCount?: InputMaybe<OrganizationMembershipHavingDistinctCountInput>;
  max?: InputMaybe<OrganizationMembershipHavingMaxInput>;
  min?: InputMaybe<OrganizationMembershipHavingMinInput>;
  stddevPopulation?: InputMaybe<OrganizationMembershipHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<OrganizationMembershipHavingStddevSampleInput>;
  sum?: InputMaybe<OrganizationMembershipHavingSumInput>;
  variancePopulation?: InputMaybe<OrganizationMembershipHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<OrganizationMembershipHavingVarianceSampleInput>;
};

export type OrganizationMembershipHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationMembershipHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationMembershipHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationMembershipHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationMembershipHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationMembershipHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationMembershipHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `OrganizationMembership` */
export type OrganizationMembershipInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  organization?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  organizationMembershipId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<OrganizationMembershipUserIdFkeyInput>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type OrganizationMembershipNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `organizationMembership` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type OrganizationMembershipNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `organizationMembership` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type OrganizationMembershipOnOrganizationMembershipForOrganizationMembershipOrganizationIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `organization` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `organization` being updated. */
    patch: OrganizationPatch;
  };

/** The fields on `organizationMembership` to look up the row to update. */
export type OrganizationMembershipOnOrganizationMembershipForOrganizationMembershipOrganizationIdFkeyUsingOrganizationMembershipPkeyUpdate =
  {
    organizationMembershipId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `organizationMembership` being updated. */
    patch: UpdateOrganizationMembershipOnOrganizationMembershipForOrganizationMembershipOrganizationIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type OrganizationMembershipOnOrganizationMembershipForOrganizationMembershipUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `user` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UserPatch;
  };

/** The fields on `organizationMembership` to look up the row to update. */
export type OrganizationMembershipOnOrganizationMembershipForOrganizationMembershipUserIdFkeyUsingOrganizationMembershipPkeyUpdate =
  {
    organizationMembershipId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `organizationMembership` being updated. */
    patch: UpdateOrganizationMembershipOnOrganizationMembershipForOrganizationMembershipUserIdFkeyPatch;
  };

/** Input for the nested mutation of `organization` in the `OrganizationMembershipInput` mutation. */
export type OrganizationMembershipOrganizationIdFkeyInput = {
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectById?: InputMaybe<OrganizationNodeIdConnect>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByOrganizationId?: InputMaybe<OrganizationOrganizationPkeyConnect>;
  /** A `OrganizationInput` object that will be created and connected to this object. */
  create?: InputMaybe<OrganizationMembershipOrganizationIdFkeyOrganizationCreateInput>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteById?: InputMaybe<OrganizationNodeIdDelete>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByOrganizationId?: InputMaybe<OrganizationOrganizationPkeyDelete>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateById?: InputMaybe<OrganizationMembershipOnOrganizationMembershipForOrganizationMembershipOrganizationIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByOrganizationId?: InputMaybe<OrganizationOnOrganizationMembershipForOrganizationMembershipOrganizationIdFkeyUsingOrganizationPkeyUpdate>;
};

/** Input for the nested mutation of `organizationMembership` in the `OrganizationInput` mutation. */
export type OrganizationMembershipOrganizationIdFkeyInverseInput = {
  /** The primary key(s) for `organizationMembership` for the far side of the relationship. */
  connectById?: InputMaybe<Array<OrganizationMembershipNodeIdConnect>>;
  /** The primary key(s) for `organizationMembership` for the far side of the relationship. */
  connectByOrganizationMembershipId?: InputMaybe<
    Array<OrganizationMembershipOrganizationMembershipPkeyConnect>
  >;
  /** A `OrganizationMembershipInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<OrganizationMembershipOrganizationIdFkeyOrganizationMembershipCreateInput>
  >;
  /** The primary key(s) for `organizationMembership` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<OrganizationMembershipNodeIdDelete>>;
  /** The primary key(s) for `organizationMembership` for the far side of the relationship. */
  deleteByOrganizationMembershipId?: InputMaybe<
    Array<OrganizationMembershipOrganizationMembershipPkeyDelete>
  >;
  /** Flag indicating whether all other `organizationMembership` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `organizationMembership` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<OrganizationOnOrganizationMembershipForOrganizationMembershipOrganizationIdFkeyNodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `organizationMembership` for the far side of the relationship. */
  updateByOrganizationMembershipId?: InputMaybe<
    Array<OrganizationMembershipOnOrganizationMembershipForOrganizationMembershipOrganizationIdFkeyUsingOrganizationMembershipPkeyUpdate>
  >;
};

/** The `organization` to be created by this mutation. */
export type OrganizationMembershipOrganizationIdFkeyOrganizationCreateInput = {
  containerMetadata?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  domains?: InputMaybe<DomainOrganizationIdFkeyInverseInput>;
  name: Scalars["String"];
  organizationAttribute?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  organizationLogo?: InputMaybe<OrganizationLogoOrganizationIdFkeyInverseInput>;
  organizationMemberships?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInverseInput>;
  organizationQuota?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The `organizationMembership` to be created by this mutation. */
export type OrganizationMembershipOrganizationIdFkeyOrganizationMembershipCreateInput =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    organization?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInput>;
    organizationId?: InputMaybe<Scalars["UUID"]>;
    organizationMembershipId?: InputMaybe<Scalars["UUID"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    user?: InputMaybe<OrganizationMembershipUserIdFkeyInput>;
    userId?: InputMaybe<Scalars["UUID"]>;
  };

/** The fields on `organizationMembership` to look up the row to connect. */
export type OrganizationMembershipOrganizationMembershipPkeyConnect = {
  organizationMembershipId: Scalars["UUID"];
};

/** The fields on `organizationMembership` to look up the row to delete. */
export type OrganizationMembershipOrganizationMembershipPkeyDelete = {
  organizationMembershipId: Scalars["UUID"];
};

/** Represents an update to a `OrganizationMembership`. Fields that are set will be updated. */
export type OrganizationMembershipPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  organization?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  organizationMembershipId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<OrganizationMembershipUserIdFkeyInput>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** Input for the nested mutation of `user` in the `OrganizationMembershipInput` mutation. */
export type OrganizationMembershipUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByUserId?: InputMaybe<UserUserPkeyConnect>;
  /** A `UserInput` object that will be created and connected to this object. */
  create?: InputMaybe<OrganizationMembershipUserIdFkeyUserCreateInput>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<UserUserEmailKeyDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteById?: InputMaybe<UserNodeIdDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByUserId?: InputMaybe<UserUserPkeyDelete>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnOrganizationMembershipForOrganizationMembershipUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<OrganizationMembershipOnOrganizationMembershipForOrganizationMembershipUserIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByUserId?: InputMaybe<UserOnOrganizationMembershipForOrganizationMembershipUserIdFkeyUsingUserPkeyUpdate>;
};

/** Input for the nested mutation of `organizationMembership` in the `UserInput` mutation. */
export type OrganizationMembershipUserIdFkeyInverseInput = {
  /** The primary key(s) for `organizationMembership` for the far side of the relationship. */
  connectById?: InputMaybe<Array<OrganizationMembershipNodeIdConnect>>;
  /** The primary key(s) for `organizationMembership` for the far side of the relationship. */
  connectByOrganizationMembershipId?: InputMaybe<
    Array<OrganizationMembershipOrganizationMembershipPkeyConnect>
  >;
  /** A `OrganizationMembershipInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<OrganizationMembershipUserIdFkeyOrganizationMembershipCreateInput>
  >;
  /** The primary key(s) for `organizationMembership` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<OrganizationMembershipNodeIdDelete>>;
  /** The primary key(s) for `organizationMembership` for the far side of the relationship. */
  deleteByOrganizationMembershipId?: InputMaybe<
    Array<OrganizationMembershipOrganizationMembershipPkeyDelete>
  >;
  /** Flag indicating whether all other `organizationMembership` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `organizationMembership` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<UserOnOrganizationMembershipForOrganizationMembershipUserIdFkeyNodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `organizationMembership` for the far side of the relationship. */
  updateByOrganizationMembershipId?: InputMaybe<
    Array<OrganizationMembershipOnOrganizationMembershipForOrganizationMembershipUserIdFkeyUsingOrganizationMembershipPkeyUpdate>
  >;
};

/** The `organizationMembership` to be created by this mutation. */
export type OrganizationMembershipUserIdFkeyOrganizationMembershipCreateInput =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    organization?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInput>;
    organizationId?: InputMaybe<Scalars["UUID"]>;
    organizationMembershipId?: InputMaybe<Scalars["UUID"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    user?: InputMaybe<OrganizationMembershipUserIdFkeyInput>;
    userId?: InputMaybe<Scalars["UUID"]>;
  };

/** The `user` to be created by this mutation. */
export type OrganizationMembershipUserIdFkeyUserCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
  domain?: InputMaybe<Scalars["String"]>;
  domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
  email?: InputMaybe<Scalars["String"]>;
  emailVerified?: InputMaybe<Scalars["Boolean"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
  password?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  upstreamUserId?: InputMaybe<Scalars["String"]>;
  userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
  userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
  userId?: InputMaybe<Scalars["UUID"]>;
  userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
  userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
};

/** A connection to a list of `OrganizationMembership` values. */
export type OrganizationMembershipsConnection = {
  __typename?: "OrganizationMembershipsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OrganizationMembershipAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<OrganizationMembershipDistinct>;
  /** A list of edges which contains the `OrganizationMembership` and cursor to aid in pagination. */
  edges: Array<OrganizationMembershipsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OrganizationMembershipAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<OrganizationMembershipGroup>>;
  /** A list of `OrganizationMembership` objects. */
  nodes: Array<Maybe<OrganizationMembership>>;
  /** A list of _all_ `OrganizationMembership` primary keys. */
  organizationMembershipIds?: Maybe<Array<Scalars["UUID"]>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OrganizationMembership` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `OrganizationMembership` values. */
export type OrganizationMembershipsConnectionGroupedAggregatesArgs = {
  groupBy: Array<OrganizationMembershipGroupBy>;
  having?: InputMaybe<OrganizationMembershipHavingInput>;
};

/** A connection to a list of `OrganizationMembership` values. */
export type OrganizationMembershipsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<OrganizationMembershipGroupBy>;
  having?: InputMaybe<OrganizationMembershipHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `OrganizationMembership` edge in the connection. */
export type OrganizationMembershipsEdge = {
  __typename?: "OrganizationMembershipsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `OrganizationMembership` at the end of the edge. */
  node?: Maybe<OrganizationMembership>;
};

/** Methods to use when ordering `OrganizationMembership`. */
export enum OrganizationMembershipsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  Natural = "NATURAL",
  OrganizationByOrganizationIdContainerMetadataAsc = "ORGANIZATION_BY_ORGANIZATION_ID_CONTAINER_METADATA_ASC",
  OrganizationByOrganizationIdContainerMetadataDesc = "ORGANIZATION_BY_ORGANIZATION_ID_CONTAINER_METADATA_DESC",
  OrganizationByOrganizationIdCreatedAtAsc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_AT_ASC",
  OrganizationByOrganizationIdCreatedAtDesc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_AT_DESC",
  OrganizationByOrganizationIdCreatedByEmailAsc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_ASC",
  OrganizationByOrganizationIdCreatedByEmailDesc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_DESC",
  OrganizationByOrganizationIdDescriptionAsc = "ORGANIZATION_BY_ORGANIZATION_ID_DESCRIPTION_ASC",
  OrganizationByOrganizationIdDescriptionDesc = "ORGANIZATION_BY_ORGANIZATION_ID_DESCRIPTION_DESC",
  OrganizationByOrganizationIdNameAsc = "ORGANIZATION_BY_ORGANIZATION_ID_NAME_ASC",
  OrganizationByOrganizationIdNameDesc = "ORGANIZATION_BY_ORGANIZATION_ID_NAME_DESC",
  OrganizationByOrganizationIdOrganizationIdAsc = "ORGANIZATION_BY_ORGANIZATION_ID_ORGANIZATION_ID_ASC",
  OrganizationByOrganizationIdOrganizationIdDesc = "ORGANIZATION_BY_ORGANIZATION_ID_ORGANIZATION_ID_DESC",
  OrganizationByOrganizationIdUpdatedAtAsc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_AT_ASC",
  OrganizationByOrganizationIdUpdatedAtDesc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_AT_DESC",
  OrganizationByOrganizationIdUpdatedByEmailAsc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_ASC",
  OrganizationByOrganizationIdUpdatedByEmailDesc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_DESC",
  OrganizationIdAsc = "ORGANIZATION_ID_ASC",
  OrganizationIdDesc = "ORGANIZATION_ID_DESC",
  OrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIP_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
  UserByUserIdCreatedAtAsc = "USER_BY_USER_ID_CREATED_AT_ASC",
  UserByUserIdCreatedAtDesc = "USER_BY_USER_ID_CREATED_AT_DESC",
  UserByUserIdCreatedByEmailAsc = "USER_BY_USER_ID_CREATED_BY_EMAIL_ASC",
  UserByUserIdCreatedByEmailDesc = "USER_BY_USER_ID_CREATED_BY_EMAIL_DESC",
  UserByUserIdDomainAsc = "USER_BY_USER_ID_DOMAIN_ASC",
  UserByUserIdDomainDesc = "USER_BY_USER_ID_DOMAIN_DESC",
  UserByUserIdEmailAsc = "USER_BY_USER_ID_EMAIL_ASC",
  UserByUserIdEmailDesc = "USER_BY_USER_ID_EMAIL_DESC",
  UserByUserIdEmailVerifiedAsc = "USER_BY_USER_ID_EMAIL_VERIFIED_ASC",
  UserByUserIdEmailVerifiedDesc = "USER_BY_USER_ID_EMAIL_VERIFIED_DESC",
  UserByUserIdFamilyNameAsc = "USER_BY_USER_ID_FAMILY_NAME_ASC",
  UserByUserIdFamilyNameDesc = "USER_BY_USER_ID_FAMILY_NAME_DESC",
  UserByUserIdGivenNameAsc = "USER_BY_USER_ID_GIVEN_NAME_ASC",
  UserByUserIdGivenNameDesc = "USER_BY_USER_ID_GIVEN_NAME_DESC",
  UserByUserIdNameAsc = "USER_BY_USER_ID_NAME_ASC",
  UserByUserIdNameDesc = "USER_BY_USER_ID_NAME_DESC",
  UserByUserIdNicknameAsc = "USER_BY_USER_ID_NICKNAME_ASC",
  UserByUserIdNicknameDesc = "USER_BY_USER_ID_NICKNAME_DESC",
  UserByUserIdPictureAsc = "USER_BY_USER_ID_PICTURE_ASC",
  UserByUserIdPictureDesc = "USER_BY_USER_ID_PICTURE_DESC",
  UserByUserIdUpdatedAtAsc = "USER_BY_USER_ID_UPDATED_AT_ASC",
  UserByUserIdUpdatedAtDesc = "USER_BY_USER_ID_UPDATED_AT_DESC",
  UserByUserIdUpdatedByEmailAsc = "USER_BY_USER_ID_UPDATED_BY_EMAIL_ASC",
  UserByUserIdUpdatedByEmailDesc = "USER_BY_USER_ID_UPDATED_BY_EMAIL_DESC",
  UserByUserIdUpstreamUserIdAsc = "USER_BY_USER_ID_UPSTREAM_USER_ID_ASC",
  UserByUserIdUpstreamUserIdDesc = "USER_BY_USER_ID_UPSTREAM_USER_ID_DESC",
  UserByUserIdUserIdAsc = "USER_BY_USER_ID_USER_ID_ASC",
  UserByUserIdUserIdDesc = "USER_BY_USER_ID_USER_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** The globally unique `ID` look up for the row to connect. */
export type OrganizationNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `organization` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type OrganizationNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `organization` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type OrganizationOnDomainForDomainOrganizationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `domain` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `domain` being updated. */
  patch: DomainPatch;
};

/** The fields on `organization` to look up the row to update. */
export type OrganizationOnDomainForDomainOrganizationIdFkeyUsingOrganizationPkeyUpdate =
  {
    organizationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `organization` being updated. */
    patch: UpdateOrganizationOnDomainForDomainOrganizationIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type OrganizationOnOrganizationAttributeForOrganizationAttributesOrganizationIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `organizationAttribute` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `organizationAttribute` being updated. */
    patch: OrganizationAttributePatch;
  };

/** The fields on `organization` to look up the row to update. */
export type OrganizationOnOrganizationAttributeForOrganizationAttributesOrganizationIdFkeyUsingOrganizationPkeyUpdate =
  {
    organizationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `organization` being updated. */
    patch: UpdateOrganizationOnOrganizationAttributeForOrganizationAttributesOrganizationIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type OrganizationOnOrganizationLogoForOrganizationLogoOrganizationIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `organizationLogo` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `organizationLogo` being updated. */
    patch: OrganizationLogoPatch;
  };

/** The fields on `organization` to look up the row to update. */
export type OrganizationOnOrganizationLogoForOrganizationLogoOrganizationIdFkeyUsingOrganizationPkeyUpdate =
  {
    organizationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `organization` being updated. */
    patch: UpdateOrganizationOnOrganizationLogoForOrganizationLogoOrganizationIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type OrganizationOnOrganizationMembershipForOrganizationMembershipOrganizationIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `organizationMembership` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `organizationMembership` being updated. */
    patch: OrganizationMembershipPatch;
  };

/** The fields on `organization` to look up the row to update. */
export type OrganizationOnOrganizationMembershipForOrganizationMembershipOrganizationIdFkeyUsingOrganizationPkeyUpdate =
  {
    organizationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `organization` being updated. */
    patch: UpdateOrganizationOnOrganizationMembershipForOrganizationMembershipOrganizationIdFkeyPatch;
  };

/** The globally unique `ID` look up for the row to update. */
export type OrganizationOnOrganizationQuotaForOrganizationQuotaOrganizationIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `organizationQuota` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `organizationQuota` being updated. */
    patch: OrganizationQuotaPatch;
  };

/** The fields on `organization` to look up the row to update. */
export type OrganizationOnOrganizationQuotaForOrganizationQuotaOrganizationIdFkeyUsingOrganizationPkeyUpdate =
  {
    organizationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `organization` being updated. */
    patch: UpdateOrganizationOnOrganizationQuotaForOrganizationQuotaOrganizationIdFkeyPatch;
  };

/** The fields on `organization` to look up the row to connect. */
export type OrganizationOrganizationPkeyConnect = {
  organizationId: Scalars["UUID"];
};

/** The fields on `organization` to look up the row to delete. */
export type OrganizationOrganizationPkeyDelete = {
  organizationId: Scalars["UUID"];
};

/** Represents an update to a `Organization`. Fields that are set will be updated. */
export type OrganizationPatch = {
  containerMetadata?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  domains?: InputMaybe<DomainOrganizationIdFkeyInverseInput>;
  name?: InputMaybe<Scalars["String"]>;
  organizationAttribute?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  organizationLogo?: InputMaybe<OrganizationLogoOrganizationIdFkeyInverseInput>;
  organizationMemberships?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInverseInput>;
  organizationQuota?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type OrganizationQuota = Node & {
  __typename?: "OrganizationQuota";
  allowed: Scalars["Int"];
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  /** Reads a single `Organization` that is related to this `OrganizationQuota`. */
  organization?: Maybe<Organization>;
  organizationId: Scalars["UUID"];
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type OrganizationQuotaAggregates = {
  __typename?: "OrganizationQuotaAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<OrganizationQuotaAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<OrganizationQuotaDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<OrganizationQuotaMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<OrganizationQuotaMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<OrganizationQuotaStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<OrganizationQuotaStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<OrganizationQuotaSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<OrganizationQuotaVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<OrganizationQuotaVarianceSampleAggregates>;
};

export type OrganizationQuotaAverageAggregates = {
  __typename?: "OrganizationQuotaAverageAggregates";
  /** Mean average of allowed across the matching connection */
  allowed?: Maybe<Scalars["BigFloat"]>;
};

/**
 * A condition to be used against `OrganizationQuota` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type OrganizationQuotaCondition = {
  /** Checks for equality with the object’s `allowed` field. */
  allowed?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `organizationId` field. */
  organizationId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type OrganizationQuotaDistinct = {
  __typename?: "OrganizationQuotaDistinct";
  /** Distinct `allowed` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  allowed: Array<Scalars["Int"]>;
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `organizationId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  organizationId: Array<Scalars["UUID"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type OrganizationQuotaDistinctCountAggregates = {
  __typename?: "OrganizationQuotaDistinctCountAggregates";
  /** Distinct count of allowed across the matching connection */
  allowed?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of organizationId across the matching connection */
  organizationId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `OrganizationQuota` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationQuotaFilter = {
  /** Filter by the object’s `allowed` field. */
  allowed?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<OrganizationQuotaFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<OrganizationQuotaFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<OrganizationQuotaFilter>>;
  /** Filter by the object’s `organization` relation. */
  organization?: InputMaybe<OrganizationFilter>;
  /** Filter by the object’s `organizationId` field. */
  organizationId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type OrganizationQuotaGroup = {
  __typename?: "OrganizationQuotaGroup";
  /** `OrganizationQuotaFilter` that was used to implicitly filter `organizationQuotas`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** A list of _all_ `OrganizationQuota` primary keys that belong to the `OrganizationQuotaGroup`. */
  organizationIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `OrganizationQuota` of this `OrganizationQuotaGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `OrganizationQuotaGroup`. Overriding these may result in the wrong set of `OrganizationQuota` returned.
   */
  organizationQuotas?: Maybe<OrganizationQuotasConnection>;
  /** The count of _all_ `OrganizationQuota` you could get from the `OrganizationQuotaGroup`. */
  totalCount: Scalars["Int"];
};

export type OrganizationQuotaGroupOrganizationQuotasArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<OrganizationQuotaCondition>;
  filter?: InputMaybe<OrganizationQuotaFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrganizationQuotasOrderBy>>;
};

/** Grouping methods for `OrganizationQuota` for usage during aggregation. */
export enum OrganizationQuotaGroupBy {
  Allowed = "ALLOWED",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  OrganizationByOrganizationIdContainerMetadata = "ORGANIZATION_BY_ORGANIZATION_ID_CONTAINER_METADATA",
  OrganizationByOrganizationIdCreatedAt = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_AT",
  OrganizationByOrganizationIdCreatedByEmail = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_BY_EMAIL",
  OrganizationByOrganizationIdDescription = "ORGANIZATION_BY_ORGANIZATION_ID_DESCRIPTION",
  OrganizationByOrganizationIdName = "ORGANIZATION_BY_ORGANIZATION_ID_NAME",
  OrganizationByOrganizationIdOrganizationId = "ORGANIZATION_BY_ORGANIZATION_ID_ORGANIZATION_ID",
  OrganizationByOrganizationIdUpdatedAt = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_AT",
  OrganizationByOrganizationIdUpdatedByEmail = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type OrganizationQuotaHavingAverageInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationQuotaHavingDistinctCountInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `OrganizationQuota` aggregates. */
export type OrganizationQuotaHavingInput = {
  AND?: InputMaybe<Array<OrganizationQuotaHavingInput>>;
  OR?: InputMaybe<Array<OrganizationQuotaHavingInput>>;
  average?: InputMaybe<OrganizationQuotaHavingAverageInput>;
  distinctCount?: InputMaybe<OrganizationQuotaHavingDistinctCountInput>;
  max?: InputMaybe<OrganizationQuotaHavingMaxInput>;
  min?: InputMaybe<OrganizationQuotaHavingMinInput>;
  stddevPopulation?: InputMaybe<OrganizationQuotaHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<OrganizationQuotaHavingStddevSampleInput>;
  sum?: InputMaybe<OrganizationQuotaHavingSumInput>;
  variancePopulation?: InputMaybe<OrganizationQuotaHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<OrganizationQuotaHavingVarianceSampleInput>;
};

export type OrganizationQuotaHavingMaxInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationQuotaHavingMinInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationQuotaHavingStddevPopulationInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationQuotaHavingStddevSampleInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationQuotaHavingSumInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationQuotaHavingVariancePopulationInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type OrganizationQuotaHavingVarianceSampleInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `OrganizationQuota` */
export type OrganizationQuotaInput = {
  allowed?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  organization?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type OrganizationQuotaMaxAggregates = {
  __typename?: "OrganizationQuotaMaxAggregates";
  /** Maximum of allowed across the matching connection */
  allowed?: Maybe<Scalars["Int"]>;
};

export type OrganizationQuotaMinAggregates = {
  __typename?: "OrganizationQuotaMinAggregates";
  /** Minimum of allowed across the matching connection */
  allowed?: Maybe<Scalars["Int"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type OrganizationQuotaNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `organizationQuota` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type OrganizationQuotaNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `organizationQuota` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type OrganizationQuotaOnOrganizationQuotaForOrganizationQuotaOrganizationIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `organization` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `organization` being updated. */
    patch: OrganizationPatch;
  };

/** The fields on `organizationQuota` to look up the row to update. */
export type OrganizationQuotaOnOrganizationQuotaForOrganizationQuotaOrganizationIdFkeyUsingOrganizationQuotaPkeyUpdate =
  {
    organizationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `organizationQuota` being updated. */
    patch: UpdateOrganizationQuotaOnOrganizationQuotaForOrganizationQuotaOrganizationIdFkeyPatch;
  };

/** Input for the nested mutation of `organization` in the `OrganizationQuotaInput` mutation. */
export type OrganizationQuotaOrganizationIdFkeyInput = {
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectById?: InputMaybe<OrganizationNodeIdConnect>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  connectByOrganizationId?: InputMaybe<OrganizationOrganizationPkeyConnect>;
  /** A `OrganizationInput` object that will be created and connected to this object. */
  create?: InputMaybe<OrganizationQuotaOrganizationIdFkeyOrganizationCreateInput>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteById?: InputMaybe<OrganizationNodeIdDelete>;
  /** The primary key(s) for `organization` for the far side of the relationship. */
  deleteByOrganizationId?: InputMaybe<OrganizationOrganizationPkeyDelete>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateById?: InputMaybe<OrganizationQuotaOnOrganizationQuotaForOrganizationQuotaOrganizationIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `organization` for the far side of the relationship. */
  updateByOrganizationId?: InputMaybe<OrganizationOnOrganizationQuotaForOrganizationQuotaOrganizationIdFkeyUsingOrganizationPkeyUpdate>;
};

/** Input for the nested mutation of `organizationQuota` in the `OrganizationInput` mutation. */
export type OrganizationQuotaOrganizationIdFkeyInverseInput = {
  /** The primary key(s) for `organizationQuota` for the far side of the relationship. */
  connectById?: InputMaybe<OrganizationQuotaNodeIdConnect>;
  /** The primary key(s) for `organizationQuota` for the far side of the relationship. */
  connectByOrganizationId?: InputMaybe<OrganizationQuotaOrganizationQuotaPkeyConnect>;
  /** A `OrganizationQuotaInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<OrganizationQuotaOrganizationIdFkeyOrganizationQuotaCreateInput>
  >;
  /** The primary key(s) for `organizationQuota` for the far side of the relationship. */
  deleteById?: InputMaybe<OrganizationQuotaNodeIdDelete>;
  /** The primary key(s) for `organizationQuota` for the far side of the relationship. */
  deleteByOrganizationId?: InputMaybe<OrganizationQuotaOrganizationQuotaPkeyDelete>;
  /** Flag indicating whether all other `organizationQuota` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `organizationQuota` for the far side of the relationship. */
  updateById?: InputMaybe<OrganizationOnOrganizationQuotaForOrganizationQuotaOrganizationIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `organizationQuota` for the far side of the relationship. */
  updateByOrganizationId?: InputMaybe<OrganizationQuotaOnOrganizationQuotaForOrganizationQuotaOrganizationIdFkeyUsingOrganizationQuotaPkeyUpdate>;
};

/** The `organization` to be created by this mutation. */
export type OrganizationQuotaOrganizationIdFkeyOrganizationCreateInput = {
  containerMetadata?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  domains?: InputMaybe<DomainOrganizationIdFkeyInverseInput>;
  name: Scalars["String"];
  organizationAttribute?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInverseInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  organizationLogo?: InputMaybe<OrganizationLogoOrganizationIdFkeyInverseInput>;
  organizationMemberships?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInverseInput>;
  organizationQuota?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The `organizationQuota` to be created by this mutation. */
export type OrganizationQuotaOrganizationIdFkeyOrganizationQuotaCreateInput = {
  allowed?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  organization?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The fields on `organizationQuota` to look up the row to connect. */
export type OrganizationQuotaOrganizationQuotaPkeyConnect = {
  organizationId: Scalars["UUID"];
};

/** The fields on `organizationQuota` to look up the row to delete. */
export type OrganizationQuotaOrganizationQuotaPkeyDelete = {
  organizationId: Scalars["UUID"];
};

/** Represents an update to a `OrganizationQuota`. Fields that are set will be updated. */
export type OrganizationQuotaPatch = {
  allowed?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  organization?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInput>;
  organizationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type OrganizationQuotaStddevPopulationAggregates = {
  __typename?: "OrganizationQuotaStddevPopulationAggregates";
  /** Population standard deviation of allowed across the matching connection */
  allowed?: Maybe<Scalars["BigFloat"]>;
};

export type OrganizationQuotaStddevSampleAggregates = {
  __typename?: "OrganizationQuotaStddevSampleAggregates";
  /** Sample standard deviation of allowed across the matching connection */
  allowed?: Maybe<Scalars["BigFloat"]>;
};

export type OrganizationQuotaSumAggregates = {
  __typename?: "OrganizationQuotaSumAggregates";
  /** Sum of allowed across the matching connection */
  allowed: Scalars["BigInt"];
};

export type OrganizationQuotaVariancePopulationAggregates = {
  __typename?: "OrganizationQuotaVariancePopulationAggregates";
  /** Population variance of allowed across the matching connection */
  allowed?: Maybe<Scalars["BigFloat"]>;
};

export type OrganizationQuotaVarianceSampleAggregates = {
  __typename?: "OrganizationQuotaVarianceSampleAggregates";
  /** Sample variance of allowed across the matching connection */
  allowed?: Maybe<Scalars["BigFloat"]>;
};

/** A connection to a list of `OrganizationQuota` values. */
export type OrganizationQuotasConnection = {
  __typename?: "OrganizationQuotasConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OrganizationQuotaAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<OrganizationQuotaDistinct>;
  /** A list of edges which contains the `OrganizationQuota` and cursor to aid in pagination. */
  edges: Array<OrganizationQuotasEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OrganizationQuotaAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<OrganizationQuotaGroup>>;
  /** A list of `OrganizationQuota` objects. */
  nodes: Array<Maybe<OrganizationQuota>>;
  /** A list of _all_ `OrganizationQuota` primary keys. */
  organizationIds?: Maybe<Array<Scalars["UUID"]>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `OrganizationQuota` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `OrganizationQuota` values. */
export type OrganizationQuotasConnectionGroupedAggregatesArgs = {
  groupBy: Array<OrganizationQuotaGroupBy>;
  having?: InputMaybe<OrganizationQuotaHavingInput>;
};

/** A connection to a list of `OrganizationQuota` values. */
export type OrganizationQuotasConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<OrganizationQuotaGroupBy>;
  having?: InputMaybe<OrganizationQuotaHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `OrganizationQuota` edge in the connection. */
export type OrganizationQuotasEdge = {
  __typename?: "OrganizationQuotasEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `OrganizationQuota` at the end of the edge. */
  node?: Maybe<OrganizationQuota>;
};

/** Methods to use when ordering `OrganizationQuota`. */
export enum OrganizationQuotasOrderBy {
  AllowedAsc = "ALLOWED_ASC",
  AllowedDesc = "ALLOWED_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  Natural = "NATURAL",
  OrganizationByOrganizationIdContainerMetadataAsc = "ORGANIZATION_BY_ORGANIZATION_ID_CONTAINER_METADATA_ASC",
  OrganizationByOrganizationIdContainerMetadataDesc = "ORGANIZATION_BY_ORGANIZATION_ID_CONTAINER_METADATA_DESC",
  OrganizationByOrganizationIdCreatedAtAsc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_AT_ASC",
  OrganizationByOrganizationIdCreatedAtDesc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_AT_DESC",
  OrganizationByOrganizationIdCreatedByEmailAsc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_ASC",
  OrganizationByOrganizationIdCreatedByEmailDesc = "ORGANIZATION_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_DESC",
  OrganizationByOrganizationIdDescriptionAsc = "ORGANIZATION_BY_ORGANIZATION_ID_DESCRIPTION_ASC",
  OrganizationByOrganizationIdDescriptionDesc = "ORGANIZATION_BY_ORGANIZATION_ID_DESCRIPTION_DESC",
  OrganizationByOrganizationIdNameAsc = "ORGANIZATION_BY_ORGANIZATION_ID_NAME_ASC",
  OrganizationByOrganizationIdNameDesc = "ORGANIZATION_BY_ORGANIZATION_ID_NAME_DESC",
  OrganizationByOrganizationIdOrganizationIdAsc = "ORGANIZATION_BY_ORGANIZATION_ID_ORGANIZATION_ID_ASC",
  OrganizationByOrganizationIdOrganizationIdDesc = "ORGANIZATION_BY_ORGANIZATION_ID_ORGANIZATION_ID_DESC",
  OrganizationByOrganizationIdUpdatedAtAsc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_AT_ASC",
  OrganizationByOrganizationIdUpdatedAtDesc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_AT_DESC",
  OrganizationByOrganizationIdUpdatedByEmailAsc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_ASC",
  OrganizationByOrganizationIdUpdatedByEmailDesc = "ORGANIZATION_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_DESC",
  OrganizationIdAsc = "ORGANIZATION_ID_ASC",
  OrganizationIdDesc = "ORGANIZATION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

/** A filter to be used against many `Domain` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationToManyDomainFilter = {
  /** Aggregates across related `Domain` match the filter criteria. */
  aggregates?: InputMaybe<DomainAggregatesFilter>;
  /** Every related `Domain` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DomainFilter>;
  /** No related `Domain` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DomainFilter>;
  /** Some related `Domain` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DomainFilter>;
};

/** A filter to be used against many `OrganizationMembership` object types. All fields are combined with a logical ‘and.’ */
export type OrganizationToManyOrganizationMembershipFilter = {
  /** Aggregates across related `OrganizationMembership` match the filter criteria. */
  aggregates?: InputMaybe<OrganizationMembershipAggregatesFilter>;
  /** Every related `OrganizationMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<OrganizationMembershipFilter>;
  /** No related `OrganizationMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<OrganizationMembershipFilter>;
  /** Some related `OrganizationMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<OrganizationMembershipFilter>;
};

/** A connection to a list of `Organization` values. */
export type OrganizationsConnection = {
  __typename?: "OrganizationsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<OrganizationAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<OrganizationDistinct>;
  /** A list of edges which contains the `Organization` and cursor to aid in pagination. */
  edges: Array<OrganizationsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<OrganizationAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<OrganizationGroup>>;
  /** A list of `Organization` objects. */
  nodes: Array<Maybe<Organization>>;
  /** A list of _all_ `Organization` primary keys. */
  organizationIds?: Maybe<Array<Scalars["UUID"]>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Organization` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `Organization` values. */
export type OrganizationsConnectionGroupedAggregatesArgs = {
  groupBy: Array<OrganizationGroupBy>;
  having?: InputMaybe<OrganizationHavingInput>;
};

/** A connection to a list of `Organization` values. */
export type OrganizationsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<OrganizationGroupBy>;
  having?: InputMaybe<OrganizationHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `Organization` edge in the connection. */
export type OrganizationsEdge = {
  __typename?: "OrganizationsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `Organization` at the end of the edge. */
  node?: Maybe<Organization>;
};

/** Methods to use when ordering `Organization`. */
export enum OrganizationsOrderBy {
  ContainerMetadataAsc = "CONTAINER_METADATA_ASC",
  ContainerMetadataDesc = "CONTAINER_METADATA_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  DescriptionAsc = "DESCRIPTION_ASC",
  DescriptionDesc = "DESCRIPTION_DESC",
  DomainsAverageCreatedAtAsc = "DOMAINS_AVERAGE_CREATED_AT_ASC",
  DomainsAverageCreatedAtDesc = "DOMAINS_AVERAGE_CREATED_AT_DESC",
  DomainsAverageCreatedByEmailAsc = "DOMAINS_AVERAGE_CREATED_BY_EMAIL_ASC",
  DomainsAverageCreatedByEmailDesc = "DOMAINS_AVERAGE_CREATED_BY_EMAIL_DESC",
  DomainsAverageDomainAsc = "DOMAINS_AVERAGE_DOMAIN_ASC",
  DomainsAverageDomainDesc = "DOMAINS_AVERAGE_DOMAIN_DESC",
  DomainsAverageDomainIdAsc = "DOMAINS_AVERAGE_DOMAIN_ID_ASC",
  DomainsAverageDomainIdDesc = "DOMAINS_AVERAGE_DOMAIN_ID_DESC",
  DomainsAverageOrganizationIdAsc = "DOMAINS_AVERAGE_ORGANIZATION_ID_ASC",
  DomainsAverageOrganizationIdDesc = "DOMAINS_AVERAGE_ORGANIZATION_ID_DESC",
  DomainsAverageUpdatedAtAsc = "DOMAINS_AVERAGE_UPDATED_AT_ASC",
  DomainsAverageUpdatedAtDesc = "DOMAINS_AVERAGE_UPDATED_AT_DESC",
  DomainsAverageUpdatedByEmailAsc = "DOMAINS_AVERAGE_UPDATED_BY_EMAIL_ASC",
  DomainsAverageUpdatedByEmailDesc = "DOMAINS_AVERAGE_UPDATED_BY_EMAIL_DESC",
  DomainsByOrganizationIdCountAsc = "DOMAINS_BY_ORGANIZATION_ID_COUNT_ASC",
  DomainsByOrganizationIdCountDesc = "DOMAINS_BY_ORGANIZATION_ID_COUNT_DESC",
  DomainsByOrganizationIdMaxCreatedAtAsc = "DOMAINS_BY_ORGANIZATION_ID_MAX_CREATED_AT_ASC",
  DomainsByOrganizationIdMaxCreatedAtDesc = "DOMAINS_BY_ORGANIZATION_ID_MAX_CREATED_AT_DESC",
  DomainsByOrganizationIdMaxCreatedByEmailAsc = "DOMAINS_BY_ORGANIZATION_ID_MAX_CREATED_BY_EMAIL_ASC",
  DomainsByOrganizationIdMaxCreatedByEmailDesc = "DOMAINS_BY_ORGANIZATION_ID_MAX_CREATED_BY_EMAIL_DESC",
  DomainsByOrganizationIdMaxDomainAsc = "DOMAINS_BY_ORGANIZATION_ID_MAX_DOMAIN_ASC",
  DomainsByOrganizationIdMaxDomainDesc = "DOMAINS_BY_ORGANIZATION_ID_MAX_DOMAIN_DESC",
  DomainsByOrganizationIdMaxDomainIdAsc = "DOMAINS_BY_ORGANIZATION_ID_MAX_DOMAIN_ID_ASC",
  DomainsByOrganizationIdMaxDomainIdDesc = "DOMAINS_BY_ORGANIZATION_ID_MAX_DOMAIN_ID_DESC",
  DomainsByOrganizationIdMaxOrganizationIdAsc = "DOMAINS_BY_ORGANIZATION_ID_MAX_ORGANIZATION_ID_ASC",
  DomainsByOrganizationIdMaxOrganizationIdDesc = "DOMAINS_BY_ORGANIZATION_ID_MAX_ORGANIZATION_ID_DESC",
  DomainsByOrganizationIdMaxUpdatedAtAsc = "DOMAINS_BY_ORGANIZATION_ID_MAX_UPDATED_AT_ASC",
  DomainsByOrganizationIdMaxUpdatedAtDesc = "DOMAINS_BY_ORGANIZATION_ID_MAX_UPDATED_AT_DESC",
  DomainsByOrganizationIdMaxUpdatedByEmailAsc = "DOMAINS_BY_ORGANIZATION_ID_MAX_UPDATED_BY_EMAIL_ASC",
  DomainsByOrganizationIdMaxUpdatedByEmailDesc = "DOMAINS_BY_ORGANIZATION_ID_MAX_UPDATED_BY_EMAIL_DESC",
  DomainsByOrganizationIdMinCreatedAtAsc = "DOMAINS_BY_ORGANIZATION_ID_MIN_CREATED_AT_ASC",
  DomainsByOrganizationIdMinCreatedAtDesc = "DOMAINS_BY_ORGANIZATION_ID_MIN_CREATED_AT_DESC",
  DomainsByOrganizationIdMinCreatedByEmailAsc = "DOMAINS_BY_ORGANIZATION_ID_MIN_CREATED_BY_EMAIL_ASC",
  DomainsByOrganizationIdMinCreatedByEmailDesc = "DOMAINS_BY_ORGANIZATION_ID_MIN_CREATED_BY_EMAIL_DESC",
  DomainsByOrganizationIdMinDomainAsc = "DOMAINS_BY_ORGANIZATION_ID_MIN_DOMAIN_ASC",
  DomainsByOrganizationIdMinDomainDesc = "DOMAINS_BY_ORGANIZATION_ID_MIN_DOMAIN_DESC",
  DomainsByOrganizationIdMinDomainIdAsc = "DOMAINS_BY_ORGANIZATION_ID_MIN_DOMAIN_ID_ASC",
  DomainsByOrganizationIdMinDomainIdDesc = "DOMAINS_BY_ORGANIZATION_ID_MIN_DOMAIN_ID_DESC",
  DomainsByOrganizationIdMinOrganizationIdAsc = "DOMAINS_BY_ORGANIZATION_ID_MIN_ORGANIZATION_ID_ASC",
  DomainsByOrganizationIdMinOrganizationIdDesc = "DOMAINS_BY_ORGANIZATION_ID_MIN_ORGANIZATION_ID_DESC",
  DomainsByOrganizationIdMinUpdatedAtAsc = "DOMAINS_BY_ORGANIZATION_ID_MIN_UPDATED_AT_ASC",
  DomainsByOrganizationIdMinUpdatedAtDesc = "DOMAINS_BY_ORGANIZATION_ID_MIN_UPDATED_AT_DESC",
  DomainsByOrganizationIdMinUpdatedByEmailAsc = "DOMAINS_BY_ORGANIZATION_ID_MIN_UPDATED_BY_EMAIL_ASC",
  DomainsByOrganizationIdMinUpdatedByEmailDesc = "DOMAINS_BY_ORGANIZATION_ID_MIN_UPDATED_BY_EMAIL_DESC",
  DomainsCountAsc = "DOMAINS_COUNT_ASC",
  DomainsCountDesc = "DOMAINS_COUNT_DESC",
  DomainsDistinctCountCreatedAtAsc = "DOMAINS_DISTINCT_COUNT_CREATED_AT_ASC",
  DomainsDistinctCountCreatedAtDesc = "DOMAINS_DISTINCT_COUNT_CREATED_AT_DESC",
  DomainsDistinctCountCreatedByEmailAsc = "DOMAINS_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  DomainsDistinctCountCreatedByEmailDesc = "DOMAINS_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  DomainsDistinctCountDomainAsc = "DOMAINS_DISTINCT_COUNT_DOMAIN_ASC",
  DomainsDistinctCountDomainDesc = "DOMAINS_DISTINCT_COUNT_DOMAIN_DESC",
  DomainsDistinctCountDomainIdAsc = "DOMAINS_DISTINCT_COUNT_DOMAIN_ID_ASC",
  DomainsDistinctCountDomainIdDesc = "DOMAINS_DISTINCT_COUNT_DOMAIN_ID_DESC",
  DomainsDistinctCountOrganizationIdAsc = "DOMAINS_DISTINCT_COUNT_ORGANIZATION_ID_ASC",
  DomainsDistinctCountOrganizationIdDesc = "DOMAINS_DISTINCT_COUNT_ORGANIZATION_ID_DESC",
  DomainsDistinctCountUpdatedAtAsc = "DOMAINS_DISTINCT_COUNT_UPDATED_AT_ASC",
  DomainsDistinctCountUpdatedAtDesc = "DOMAINS_DISTINCT_COUNT_UPDATED_AT_DESC",
  DomainsDistinctCountUpdatedByEmailAsc = "DOMAINS_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  DomainsDistinctCountUpdatedByEmailDesc = "DOMAINS_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  DomainsMaxCreatedAtAsc = "DOMAINS_MAX_CREATED_AT_ASC",
  DomainsMaxCreatedAtDesc = "DOMAINS_MAX_CREATED_AT_DESC",
  DomainsMaxCreatedByEmailAsc = "DOMAINS_MAX_CREATED_BY_EMAIL_ASC",
  DomainsMaxCreatedByEmailDesc = "DOMAINS_MAX_CREATED_BY_EMAIL_DESC",
  DomainsMaxDomainAsc = "DOMAINS_MAX_DOMAIN_ASC",
  DomainsMaxDomainDesc = "DOMAINS_MAX_DOMAIN_DESC",
  DomainsMaxDomainIdAsc = "DOMAINS_MAX_DOMAIN_ID_ASC",
  DomainsMaxDomainIdDesc = "DOMAINS_MAX_DOMAIN_ID_DESC",
  DomainsMaxOrganizationIdAsc = "DOMAINS_MAX_ORGANIZATION_ID_ASC",
  DomainsMaxOrganizationIdDesc = "DOMAINS_MAX_ORGANIZATION_ID_DESC",
  DomainsMaxUpdatedAtAsc = "DOMAINS_MAX_UPDATED_AT_ASC",
  DomainsMaxUpdatedAtDesc = "DOMAINS_MAX_UPDATED_AT_DESC",
  DomainsMaxUpdatedByEmailAsc = "DOMAINS_MAX_UPDATED_BY_EMAIL_ASC",
  DomainsMaxUpdatedByEmailDesc = "DOMAINS_MAX_UPDATED_BY_EMAIL_DESC",
  DomainsMinCreatedAtAsc = "DOMAINS_MIN_CREATED_AT_ASC",
  DomainsMinCreatedAtDesc = "DOMAINS_MIN_CREATED_AT_DESC",
  DomainsMinCreatedByEmailAsc = "DOMAINS_MIN_CREATED_BY_EMAIL_ASC",
  DomainsMinCreatedByEmailDesc = "DOMAINS_MIN_CREATED_BY_EMAIL_DESC",
  DomainsMinDomainAsc = "DOMAINS_MIN_DOMAIN_ASC",
  DomainsMinDomainDesc = "DOMAINS_MIN_DOMAIN_DESC",
  DomainsMinDomainIdAsc = "DOMAINS_MIN_DOMAIN_ID_ASC",
  DomainsMinDomainIdDesc = "DOMAINS_MIN_DOMAIN_ID_DESC",
  DomainsMinOrganizationIdAsc = "DOMAINS_MIN_ORGANIZATION_ID_ASC",
  DomainsMinOrganizationIdDesc = "DOMAINS_MIN_ORGANIZATION_ID_DESC",
  DomainsMinUpdatedAtAsc = "DOMAINS_MIN_UPDATED_AT_ASC",
  DomainsMinUpdatedAtDesc = "DOMAINS_MIN_UPDATED_AT_DESC",
  DomainsMinUpdatedByEmailAsc = "DOMAINS_MIN_UPDATED_BY_EMAIL_ASC",
  DomainsMinUpdatedByEmailDesc = "DOMAINS_MIN_UPDATED_BY_EMAIL_DESC",
  DomainsStddevPopulationCreatedAtAsc = "DOMAINS_STDDEV_POPULATION_CREATED_AT_ASC",
  DomainsStddevPopulationCreatedAtDesc = "DOMAINS_STDDEV_POPULATION_CREATED_AT_DESC",
  DomainsStddevPopulationCreatedByEmailAsc = "DOMAINS_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  DomainsStddevPopulationCreatedByEmailDesc = "DOMAINS_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  DomainsStddevPopulationDomainAsc = "DOMAINS_STDDEV_POPULATION_DOMAIN_ASC",
  DomainsStddevPopulationDomainDesc = "DOMAINS_STDDEV_POPULATION_DOMAIN_DESC",
  DomainsStddevPopulationDomainIdAsc = "DOMAINS_STDDEV_POPULATION_DOMAIN_ID_ASC",
  DomainsStddevPopulationDomainIdDesc = "DOMAINS_STDDEV_POPULATION_DOMAIN_ID_DESC",
  DomainsStddevPopulationOrganizationIdAsc = "DOMAINS_STDDEV_POPULATION_ORGANIZATION_ID_ASC",
  DomainsStddevPopulationOrganizationIdDesc = "DOMAINS_STDDEV_POPULATION_ORGANIZATION_ID_DESC",
  DomainsStddevPopulationUpdatedAtAsc = "DOMAINS_STDDEV_POPULATION_UPDATED_AT_ASC",
  DomainsStddevPopulationUpdatedAtDesc = "DOMAINS_STDDEV_POPULATION_UPDATED_AT_DESC",
  DomainsStddevPopulationUpdatedByEmailAsc = "DOMAINS_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  DomainsStddevPopulationUpdatedByEmailDesc = "DOMAINS_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  DomainsStddevSampleCreatedAtAsc = "DOMAINS_STDDEV_SAMPLE_CREATED_AT_ASC",
  DomainsStddevSampleCreatedAtDesc = "DOMAINS_STDDEV_SAMPLE_CREATED_AT_DESC",
  DomainsStddevSampleCreatedByEmailAsc = "DOMAINS_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  DomainsStddevSampleCreatedByEmailDesc = "DOMAINS_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  DomainsStddevSampleDomainAsc = "DOMAINS_STDDEV_SAMPLE_DOMAIN_ASC",
  DomainsStddevSampleDomainDesc = "DOMAINS_STDDEV_SAMPLE_DOMAIN_DESC",
  DomainsStddevSampleDomainIdAsc = "DOMAINS_STDDEV_SAMPLE_DOMAIN_ID_ASC",
  DomainsStddevSampleDomainIdDesc = "DOMAINS_STDDEV_SAMPLE_DOMAIN_ID_DESC",
  DomainsStddevSampleOrganizationIdAsc = "DOMAINS_STDDEV_SAMPLE_ORGANIZATION_ID_ASC",
  DomainsStddevSampleOrganizationIdDesc = "DOMAINS_STDDEV_SAMPLE_ORGANIZATION_ID_DESC",
  DomainsStddevSampleUpdatedAtAsc = "DOMAINS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  DomainsStddevSampleUpdatedAtDesc = "DOMAINS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  DomainsStddevSampleUpdatedByEmailAsc = "DOMAINS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  DomainsStddevSampleUpdatedByEmailDesc = "DOMAINS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  DomainsSumCreatedAtAsc = "DOMAINS_SUM_CREATED_AT_ASC",
  DomainsSumCreatedAtDesc = "DOMAINS_SUM_CREATED_AT_DESC",
  DomainsSumCreatedByEmailAsc = "DOMAINS_SUM_CREATED_BY_EMAIL_ASC",
  DomainsSumCreatedByEmailDesc = "DOMAINS_SUM_CREATED_BY_EMAIL_DESC",
  DomainsSumDomainAsc = "DOMAINS_SUM_DOMAIN_ASC",
  DomainsSumDomainDesc = "DOMAINS_SUM_DOMAIN_DESC",
  DomainsSumDomainIdAsc = "DOMAINS_SUM_DOMAIN_ID_ASC",
  DomainsSumDomainIdDesc = "DOMAINS_SUM_DOMAIN_ID_DESC",
  DomainsSumOrganizationIdAsc = "DOMAINS_SUM_ORGANIZATION_ID_ASC",
  DomainsSumOrganizationIdDesc = "DOMAINS_SUM_ORGANIZATION_ID_DESC",
  DomainsSumUpdatedAtAsc = "DOMAINS_SUM_UPDATED_AT_ASC",
  DomainsSumUpdatedAtDesc = "DOMAINS_SUM_UPDATED_AT_DESC",
  DomainsSumUpdatedByEmailAsc = "DOMAINS_SUM_UPDATED_BY_EMAIL_ASC",
  DomainsSumUpdatedByEmailDesc = "DOMAINS_SUM_UPDATED_BY_EMAIL_DESC",
  DomainsVariancePopulationCreatedAtAsc = "DOMAINS_VARIANCE_POPULATION_CREATED_AT_ASC",
  DomainsVariancePopulationCreatedAtDesc = "DOMAINS_VARIANCE_POPULATION_CREATED_AT_DESC",
  DomainsVariancePopulationCreatedByEmailAsc = "DOMAINS_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  DomainsVariancePopulationCreatedByEmailDesc = "DOMAINS_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  DomainsVariancePopulationDomainAsc = "DOMAINS_VARIANCE_POPULATION_DOMAIN_ASC",
  DomainsVariancePopulationDomainDesc = "DOMAINS_VARIANCE_POPULATION_DOMAIN_DESC",
  DomainsVariancePopulationDomainIdAsc = "DOMAINS_VARIANCE_POPULATION_DOMAIN_ID_ASC",
  DomainsVariancePopulationDomainIdDesc = "DOMAINS_VARIANCE_POPULATION_DOMAIN_ID_DESC",
  DomainsVariancePopulationOrganizationIdAsc = "DOMAINS_VARIANCE_POPULATION_ORGANIZATION_ID_ASC",
  DomainsVariancePopulationOrganizationIdDesc = "DOMAINS_VARIANCE_POPULATION_ORGANIZATION_ID_DESC",
  DomainsVariancePopulationUpdatedAtAsc = "DOMAINS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  DomainsVariancePopulationUpdatedAtDesc = "DOMAINS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  DomainsVariancePopulationUpdatedByEmailAsc = "DOMAINS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  DomainsVariancePopulationUpdatedByEmailDesc = "DOMAINS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  DomainsVarianceSampleCreatedAtAsc = "DOMAINS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  DomainsVarianceSampleCreatedAtDesc = "DOMAINS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  DomainsVarianceSampleCreatedByEmailAsc = "DOMAINS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  DomainsVarianceSampleCreatedByEmailDesc = "DOMAINS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  DomainsVarianceSampleDomainAsc = "DOMAINS_VARIANCE_SAMPLE_DOMAIN_ASC",
  DomainsVarianceSampleDomainDesc = "DOMAINS_VARIANCE_SAMPLE_DOMAIN_DESC",
  DomainsVarianceSampleDomainIdAsc = "DOMAINS_VARIANCE_SAMPLE_DOMAIN_ID_ASC",
  DomainsVarianceSampleDomainIdDesc = "DOMAINS_VARIANCE_SAMPLE_DOMAIN_ID_DESC",
  DomainsVarianceSampleOrganizationIdAsc = "DOMAINS_VARIANCE_SAMPLE_ORGANIZATION_ID_ASC",
  DomainsVarianceSampleOrganizationIdDesc = "DOMAINS_VARIANCE_SAMPLE_ORGANIZATION_ID_DESC",
  DomainsVarianceSampleUpdatedAtAsc = "DOMAINS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  DomainsVarianceSampleUpdatedAtDesc = "DOMAINS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  DomainsVarianceSampleUpdatedByEmailAsc = "DOMAINS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  DomainsVarianceSampleUpdatedByEmailDesc = "DOMAINS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  OrganizationAttributeByOrganizationIdCreatedAtAsc = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_CREATED_AT_ASC",
  OrganizationAttributeByOrganizationIdCreatedAtDesc = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_CREATED_AT_DESC",
  OrganizationAttributeByOrganizationIdCreatedByEmailAsc = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_ASC",
  OrganizationAttributeByOrganizationIdCreatedByEmailDesc = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_DESC",
  OrganizationAttributeByOrganizationIdIsPostComputeEnabledAsc = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_IS_POST_COMPUTE_ENABLED_ASC",
  OrganizationAttributeByOrganizationIdIsPostComputeEnabledDesc = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_IS_POST_COMPUTE_ENABLED_DESC",
  OrganizationAttributeByOrganizationIdOrganizationIdAsc = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_ORGANIZATION_ID_ASC",
  OrganizationAttributeByOrganizationIdOrganizationIdDesc = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_ORGANIZATION_ID_DESC",
  OrganizationAttributeByOrganizationIdUpdatedAtAsc = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_UPDATED_AT_ASC",
  OrganizationAttributeByOrganizationIdUpdatedAtDesc = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_UPDATED_AT_DESC",
  OrganizationAttributeByOrganizationIdUpdatedByEmailAsc = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_ASC",
  OrganizationAttributeByOrganizationIdUpdatedByEmailDesc = "ORGANIZATION_ATTRIBUTE_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_DESC",
  OrganizationIdAsc = "ORGANIZATION_ID_ASC",
  OrganizationIdDesc = "ORGANIZATION_ID_DESC",
  OrganizationLogoByOrganizationIdCreatedAtAsc = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_CREATED_AT_ASC",
  OrganizationLogoByOrganizationIdCreatedAtDesc = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_CREATED_AT_DESC",
  OrganizationLogoByOrganizationIdCreatedByEmailAsc = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_ASC",
  OrganizationLogoByOrganizationIdCreatedByEmailDesc = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_DESC",
  OrganizationLogoByOrganizationIdLogoAsc = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_LOGO_ASC",
  OrganizationLogoByOrganizationIdLogoDesc = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_LOGO_DESC",
  OrganizationLogoByOrganizationIdOrganizationIdAsc = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_ORGANIZATION_ID_ASC",
  OrganizationLogoByOrganizationIdOrganizationIdDesc = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_ORGANIZATION_ID_DESC",
  OrganizationLogoByOrganizationIdUpdatedAtAsc = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_UPDATED_AT_ASC",
  OrganizationLogoByOrganizationIdUpdatedAtDesc = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_UPDATED_AT_DESC",
  OrganizationLogoByOrganizationIdUpdatedByEmailAsc = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_ASC",
  OrganizationLogoByOrganizationIdUpdatedByEmailDesc = "ORGANIZATION_LOGO_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsAverageCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_CREATED_AT_ASC",
  OrganizationMembershipsAverageCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_CREATED_AT_DESC",
  OrganizationMembershipsAverageCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsAverageCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsAverageOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_ORGANIZATION_ID_ASC",
  OrganizationMembershipsAverageOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_ORGANIZATION_ID_DESC",
  OrganizationMembershipsAverageOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsAverageOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsAverageUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_UPDATED_AT_ASC",
  OrganizationMembershipsAverageUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_UPDATED_AT_DESC",
  OrganizationMembershipsAverageUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsAverageUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsAverageUserIdAsc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_USER_ID_ASC",
  OrganizationMembershipsAverageUserIdDesc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_USER_ID_DESC",
  OrganizationMembershipsByOrganizationIdCountAsc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_COUNT_ASC",
  OrganizationMembershipsByOrganizationIdCountDesc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_COUNT_DESC",
  OrganizationMembershipsByOrganizationIdMaxCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MAX_CREATED_AT_ASC",
  OrganizationMembershipsByOrganizationIdMaxCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MAX_CREATED_AT_DESC",
  OrganizationMembershipsByOrganizationIdMaxCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MAX_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsByOrganizationIdMaxCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MAX_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsByOrganizationIdMaxOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MAX_ORGANIZATION_ID_ASC",
  OrganizationMembershipsByOrganizationIdMaxOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MAX_ORGANIZATION_ID_DESC",
  OrganizationMembershipsByOrganizationIdMaxOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MAX_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsByOrganizationIdMaxOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MAX_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsByOrganizationIdMaxUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MAX_UPDATED_AT_ASC",
  OrganizationMembershipsByOrganizationIdMaxUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MAX_UPDATED_AT_DESC",
  OrganizationMembershipsByOrganizationIdMaxUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MAX_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsByOrganizationIdMaxUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MAX_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsByOrganizationIdMaxUserIdAsc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MAX_USER_ID_ASC",
  OrganizationMembershipsByOrganizationIdMaxUserIdDesc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MAX_USER_ID_DESC",
  OrganizationMembershipsByOrganizationIdMinCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MIN_CREATED_AT_ASC",
  OrganizationMembershipsByOrganizationIdMinCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MIN_CREATED_AT_DESC",
  OrganizationMembershipsByOrganizationIdMinCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MIN_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsByOrganizationIdMinCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MIN_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsByOrganizationIdMinOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MIN_ORGANIZATION_ID_ASC",
  OrganizationMembershipsByOrganizationIdMinOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MIN_ORGANIZATION_ID_DESC",
  OrganizationMembershipsByOrganizationIdMinOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MIN_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsByOrganizationIdMinOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MIN_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsByOrganizationIdMinUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MIN_UPDATED_AT_ASC",
  OrganizationMembershipsByOrganizationIdMinUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MIN_UPDATED_AT_DESC",
  OrganizationMembershipsByOrganizationIdMinUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MIN_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsByOrganizationIdMinUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MIN_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsByOrganizationIdMinUserIdAsc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MIN_USER_ID_ASC",
  OrganizationMembershipsByOrganizationIdMinUserIdDesc = "ORGANIZATION_MEMBERSHIPS_BY_ORGANIZATION_ID_MIN_USER_ID_DESC",
  OrganizationMembershipsCountAsc = "ORGANIZATION_MEMBERSHIPS_COUNT_ASC",
  OrganizationMembershipsCountDesc = "ORGANIZATION_MEMBERSHIPS_COUNT_DESC",
  OrganizationMembershipsDistinctCountCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_CREATED_AT_ASC",
  OrganizationMembershipsDistinctCountCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_CREATED_AT_DESC",
  OrganizationMembershipsDistinctCountCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsDistinctCountCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsDistinctCountOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_ORGANIZATION_ID_ASC",
  OrganizationMembershipsDistinctCountOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_ORGANIZATION_ID_DESC",
  OrganizationMembershipsDistinctCountOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsDistinctCountOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsDistinctCountUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_UPDATED_AT_ASC",
  OrganizationMembershipsDistinctCountUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_UPDATED_AT_DESC",
  OrganizationMembershipsDistinctCountUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsDistinctCountUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsDistinctCountUserIdAsc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_USER_ID_ASC",
  OrganizationMembershipsDistinctCountUserIdDesc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_USER_ID_DESC",
  OrganizationMembershipsMaxCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_MAX_CREATED_AT_ASC",
  OrganizationMembershipsMaxCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_MAX_CREATED_AT_DESC",
  OrganizationMembershipsMaxCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_MAX_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsMaxCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_MAX_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsMaxOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_MAX_ORGANIZATION_ID_ASC",
  OrganizationMembershipsMaxOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_MAX_ORGANIZATION_ID_DESC",
  OrganizationMembershipsMaxOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_MAX_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsMaxOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_MAX_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsMaxUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_MAX_UPDATED_AT_ASC",
  OrganizationMembershipsMaxUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_MAX_UPDATED_AT_DESC",
  OrganizationMembershipsMaxUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_MAX_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsMaxUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_MAX_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsMaxUserIdAsc = "ORGANIZATION_MEMBERSHIPS_MAX_USER_ID_ASC",
  OrganizationMembershipsMaxUserIdDesc = "ORGANIZATION_MEMBERSHIPS_MAX_USER_ID_DESC",
  OrganizationMembershipsMinCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_MIN_CREATED_AT_ASC",
  OrganizationMembershipsMinCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_MIN_CREATED_AT_DESC",
  OrganizationMembershipsMinCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_MIN_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsMinCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_MIN_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsMinOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_MIN_ORGANIZATION_ID_ASC",
  OrganizationMembershipsMinOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_MIN_ORGANIZATION_ID_DESC",
  OrganizationMembershipsMinOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_MIN_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsMinOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_MIN_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsMinUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_MIN_UPDATED_AT_ASC",
  OrganizationMembershipsMinUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_MIN_UPDATED_AT_DESC",
  OrganizationMembershipsMinUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_MIN_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsMinUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_MIN_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsMinUserIdAsc = "ORGANIZATION_MEMBERSHIPS_MIN_USER_ID_ASC",
  OrganizationMembershipsMinUserIdDesc = "ORGANIZATION_MEMBERSHIPS_MIN_USER_ID_DESC",
  OrganizationMembershipsStddevPopulationCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_CREATED_AT_ASC",
  OrganizationMembershipsStddevPopulationCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_CREATED_AT_DESC",
  OrganizationMembershipsStddevPopulationCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsStddevPopulationCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsStddevPopulationOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_ORGANIZATION_ID_ASC",
  OrganizationMembershipsStddevPopulationOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_ORGANIZATION_ID_DESC",
  OrganizationMembershipsStddevPopulationOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsStddevPopulationOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsStddevPopulationUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_UPDATED_AT_ASC",
  OrganizationMembershipsStddevPopulationUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_UPDATED_AT_DESC",
  OrganizationMembershipsStddevPopulationUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsStddevPopulationUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsStddevPopulationUserIdAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_USER_ID_ASC",
  OrganizationMembershipsStddevPopulationUserIdDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_USER_ID_DESC",
  OrganizationMembershipsStddevSampleCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_CREATED_AT_ASC",
  OrganizationMembershipsStddevSampleCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_CREATED_AT_DESC",
  OrganizationMembershipsStddevSampleCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsStddevSampleCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsStddevSampleOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_ORGANIZATION_ID_ASC",
  OrganizationMembershipsStddevSampleOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_ORGANIZATION_ID_DESC",
  OrganizationMembershipsStddevSampleOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsStddevSampleOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsStddevSampleUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  OrganizationMembershipsStddevSampleUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  OrganizationMembershipsStddevSampleUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsStddevSampleUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsStddevSampleUserIdAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_USER_ID_ASC",
  OrganizationMembershipsStddevSampleUserIdDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_USER_ID_DESC",
  OrganizationMembershipsSumCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_SUM_CREATED_AT_ASC",
  OrganizationMembershipsSumCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_SUM_CREATED_AT_DESC",
  OrganizationMembershipsSumCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_SUM_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsSumCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_SUM_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsSumOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_SUM_ORGANIZATION_ID_ASC",
  OrganizationMembershipsSumOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_SUM_ORGANIZATION_ID_DESC",
  OrganizationMembershipsSumOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_SUM_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsSumOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_SUM_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsSumUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_SUM_UPDATED_AT_ASC",
  OrganizationMembershipsSumUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_SUM_UPDATED_AT_DESC",
  OrganizationMembershipsSumUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_SUM_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsSumUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_SUM_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsSumUserIdAsc = "ORGANIZATION_MEMBERSHIPS_SUM_USER_ID_ASC",
  OrganizationMembershipsSumUserIdDesc = "ORGANIZATION_MEMBERSHIPS_SUM_USER_ID_DESC",
  OrganizationMembershipsVariancePopulationCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_CREATED_AT_ASC",
  OrganizationMembershipsVariancePopulationCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_CREATED_AT_DESC",
  OrganizationMembershipsVariancePopulationCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsVariancePopulationCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsVariancePopulationOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_ORGANIZATION_ID_ASC",
  OrganizationMembershipsVariancePopulationOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_ORGANIZATION_ID_DESC",
  OrganizationMembershipsVariancePopulationOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsVariancePopulationOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsVariancePopulationUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  OrganizationMembershipsVariancePopulationUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  OrganizationMembershipsVariancePopulationUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsVariancePopulationUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsVariancePopulationUserIdAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_USER_ID_ASC",
  OrganizationMembershipsVariancePopulationUserIdDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_USER_ID_DESC",
  OrganizationMembershipsVarianceSampleCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  OrganizationMembershipsVarianceSampleCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  OrganizationMembershipsVarianceSampleCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsVarianceSampleCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsVarianceSampleOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_ORGANIZATION_ID_ASC",
  OrganizationMembershipsVarianceSampleOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_ORGANIZATION_ID_DESC",
  OrganizationMembershipsVarianceSampleOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsVarianceSampleOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsVarianceSampleUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  OrganizationMembershipsVarianceSampleUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  OrganizationMembershipsVarianceSampleUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsVarianceSampleUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsVarianceSampleUserIdAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_USER_ID_ASC",
  OrganizationMembershipsVarianceSampleUserIdDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_USER_ID_DESC",
  OrganizationQuotaByOrganizationIdAllowedAsc = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_ALLOWED_ASC",
  OrganizationQuotaByOrganizationIdAllowedDesc = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_ALLOWED_DESC",
  OrganizationQuotaByOrganizationIdCreatedAtAsc = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_CREATED_AT_ASC",
  OrganizationQuotaByOrganizationIdCreatedAtDesc = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_CREATED_AT_DESC",
  OrganizationQuotaByOrganizationIdCreatedByEmailAsc = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_ASC",
  OrganizationQuotaByOrganizationIdCreatedByEmailDesc = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_CREATED_BY_EMAIL_DESC",
  OrganizationQuotaByOrganizationIdOrganizationIdAsc = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_ORGANIZATION_ID_ASC",
  OrganizationQuotaByOrganizationIdOrganizationIdDesc = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_ORGANIZATION_ID_DESC",
  OrganizationQuotaByOrganizationIdUpdatedAtAsc = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_UPDATED_AT_ASC",
  OrganizationQuotaByOrganizationIdUpdatedAtDesc = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_UPDATED_AT_DESC",
  OrganizationQuotaByOrganizationIdUpdatedByEmailAsc = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_ASC",
  OrganizationQuotaByOrganizationIdUpdatedByEmailDesc = "ORGANIZATION_QUOTA_BY_ORGANIZATION_ID_UPDATED_BY_EMAIL_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: "PageInfo";
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars["Cursor"]>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars["Boolean"];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars["Boolean"];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars["Cursor"]>;
};

export type Permission = Node & {
  __typename?: "Permission";
  config: Scalars["JSON"];
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  permissionId: Scalars["UUID"];
  permissionType: PermissionTypeType;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
  /** Reads a single `UserPermission` that is related to this `Permission`. */
  userPermission?: Maybe<UserPermission>;
  userPermissionId: Scalars["UUID"];
};

export type PermissionAggregates = {
  __typename?: "PermissionAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<PermissionDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A filter to be used against aggregates of `Permission` object types. */
export type PermissionAggregatesFilter = {
  /** Distinct count aggregate over matching `Permission` objects. */
  distinctCount?: InputMaybe<PermissionDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Permission` object to be included within the aggregate. */
  filter?: InputMaybe<PermissionFilter>;
};

export type PermissionAsElement = Node & {
  __typename?: "PermissionAsElement";
  config: Scalars["JSON"];
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  permissionId: Scalars["UUID"];
  permissionType: PermissionTypeType;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
  /** Reads a single `UserPermissionAsElement` that is related to this `PermissionAsElement`. */
  userPermission?: Maybe<UserPermissionAsElement>;
  userPermissionId: Scalars["UUID"];
};

export type PermissionAsElementAggregates = {
  __typename?: "PermissionAsElementAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<PermissionAsElementDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A filter to be used against aggregates of `PermissionAsElement` object types. */
export type PermissionAsElementAggregatesFilter = {
  /** Distinct count aggregate over matching `PermissionAsElement` objects. */
  distinctCount?: InputMaybe<PermissionAsElementDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `PermissionAsElement` object to be included within the aggregate. */
  filter?: InputMaybe<PermissionAsElementFilter>;
};

/**
 * A condition to be used against `PermissionAsElement` object types. All fields
 * are tested for equality and combined with a logical ‘and.’
 */
export type PermissionAsElementCondition = {
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `permissionId` field. */
  permissionId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `permissionType` field. */
  permissionType?: InputMaybe<PermissionTypeType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `userPermissionId` field. */
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

export type PermissionAsElementDistinct = {
  __typename?: "PermissionAsElementDistinct";
  /** Distinct `config` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  config: Array<Scalars["JSON"]>;
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `permissionId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  permissionId: Array<Scalars["UUID"]>;
  /** Distinct `permissionType` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  permissionType: Array<PermissionTypeType>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
  /** Distinct `userPermissionId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  userPermissionId: Array<Scalars["UUID"]>;
};

export type PermissionAsElementDistinctCountAggregateFilter = {
  config?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  createdByEmail?: InputMaybe<BigIntFilter>;
  permissionId?: InputMaybe<BigIntFilter>;
  permissionType?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  updatedByEmail?: InputMaybe<BigIntFilter>;
  userPermissionId?: InputMaybe<BigIntFilter>;
};

export type PermissionAsElementDistinctCountAggregates = {
  __typename?: "PermissionAsElementDistinctCountAggregates";
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of permissionId across the matching connection */
  permissionId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of permissionType across the matching connection */
  permissionType?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of userPermissionId across the matching connection */
  userPermissionId?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `PermissionAsElement` object types. All fields are combined with a logical ‘and.’ */
export type PermissionAsElementFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PermissionAsElementFilter>>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PermissionAsElementFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PermissionAsElementFilter>>;
  /** Filter by the object’s `permissionId` field. */
  permissionId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `permissionType` field. */
  permissionType?: InputMaybe<PermissionTypeTypeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userPermission` relation. */
  userPermission?: InputMaybe<UserPermissionAsElementFilter>;
  /** Filter by the object’s `userPermissionId` field. */
  userPermissionId?: InputMaybe<UuidFilter>;
};

export type PermissionAsElementGroup = {
  __typename?: "PermissionAsElementGroup";
  /** `PermissionAsElementFilter` that was used to implicitly filter `permissionAsElements`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /**
   * Reads and enables pagination through a set of `PermissionAsElement` of this `PermissionAsElementGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `PermissionAsElementGroup`. Overriding these may result in the wrong set of `PermissionAsElement` returned.
   */
  permissionAsElements?: Maybe<PermissionAsElementsConnection>;
  /** A list of _all_ `PermissionAsElement` primary keys that belong to the `PermissionAsElementGroup`. */
  permissionIds?: Maybe<Array<Scalars["UUID"]>>;
  /** The count of _all_ `PermissionAsElement` you could get from the `PermissionAsElementGroup`. */
  totalCount: Scalars["Int"];
};

export type PermissionAsElementGroupPermissionAsElementsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<PermissionAsElementCondition>;
  filter?: InputMaybe<PermissionAsElementFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PermissionAsElementsOrderBy>>;
};

/** Grouping methods for `PermissionAsElement` for usage during aggregation. */
export enum PermissionAsElementGroupBy {
  Config = "CONFIG",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  PermissionType = "PERMISSION_TYPE",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
  UserPermissionAsElementByUserPermissionIdCreatedAt = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_CREATED_AT",
  UserPermissionAsElementByUserPermissionIdCreatedByEmail = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_CREATED_BY_EMAIL",
  UserPermissionAsElementByUserPermissionIdEmail = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_EMAIL",
  UserPermissionAsElementByUserPermissionIdUpdatedAt = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_UPDATED_AT",
  UserPermissionAsElementByUserPermissionIdUpdatedByEmail = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_UPDATED_BY_EMAIL",
  UserPermissionAsElementByUserPermissionIdUserPermissionId = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_USER_PERMISSION_ID",
  UserPermissionId = "USER_PERMISSION_ID",
}

export type PermissionAsElementHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PermissionAsElementHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `PermissionAsElement` aggregates. */
export type PermissionAsElementHavingInput = {
  AND?: InputMaybe<Array<PermissionAsElementHavingInput>>;
  OR?: InputMaybe<Array<PermissionAsElementHavingInput>>;
  average?: InputMaybe<PermissionAsElementHavingAverageInput>;
  distinctCount?: InputMaybe<PermissionAsElementHavingDistinctCountInput>;
  max?: InputMaybe<PermissionAsElementHavingMaxInput>;
  min?: InputMaybe<PermissionAsElementHavingMinInput>;
  stddevPopulation?: InputMaybe<PermissionAsElementHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<PermissionAsElementHavingStddevSampleInput>;
  sum?: InputMaybe<PermissionAsElementHavingSumInput>;
  variancePopulation?: InputMaybe<PermissionAsElementHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<PermissionAsElementHavingVarianceSampleInput>;
};

export type PermissionAsElementHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PermissionAsElementHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PermissionAsElementHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PermissionAsElementHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PermissionAsElementHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PermissionAsElementHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PermissionAsElementHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `PermissionAsElement` */
export type PermissionAsElementInput = {
  config: Scalars["JSON"];
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  permissionId?: InputMaybe<Scalars["UUID"]>;
  permissionType: PermissionTypeType;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userPermissionAsElement?: InputMaybe<PermissionAsElementUserPermissionIdFkeyInput>;
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type PermissionAsElementNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `permissionAsElement` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type PermissionAsElementNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `permissionAsElement` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type PermissionAsElementOnPermissionAsElementForPermissionAsElementUserPermissionIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `userPermissionAsElement` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `userPermissionAsElement` being updated. */
    patch: UserPermissionAsElementPatch;
  };

/** The fields on `permissionAsElement` to look up the row to update. */
export type PermissionAsElementOnPermissionAsElementForPermissionAsElementUserPermissionIdFkeyUsingPermissionAsElementPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `permissionAsElement` being updated. */
    patch: UpdatePermissionAsElementOnPermissionAsElementForPermissionAsElementUserPermissionIdFkeyPatch;
    permissionId: Scalars["UUID"];
  };

/** Represents an update to a `PermissionAsElement`. Fields that are set will be updated. */
export type PermissionAsElementPatch = {
  config?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  permissionId?: InputMaybe<Scalars["UUID"]>;
  permissionType?: InputMaybe<PermissionTypeType>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userPermissionAsElement?: InputMaybe<PermissionAsElementUserPermissionIdFkeyInput>;
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

/** The fields on `permissionAsElement` to look up the row to connect. */
export type PermissionAsElementPermissionAsElementPkeyConnect = {
  permissionId: Scalars["UUID"];
};

/** The fields on `permissionAsElement` to look up the row to delete. */
export type PermissionAsElementPermissionAsElementPkeyDelete = {
  permissionId: Scalars["UUID"];
};

/** Input for the nested mutation of `userPermissionAsElement` in the `PermissionAsElementInput` mutation. */
export type PermissionAsElementUserPermissionIdFkeyInput = {
  /** The primary key(s) for `userPermissionAsElement` for the far side of the relationship. */
  connectById?: InputMaybe<UserPermissionAsElementNodeIdConnect>;
  /** The primary key(s) for `userPermissionAsElement` for the far side of the relationship. */
  connectByUserPermissionId?: InputMaybe<UserPermissionAsElementUserPermissionAsElementPkeyConnect>;
  /** A `UserPermissionAsElementInput` object that will be created and connected to this object. */
  create?: InputMaybe<PermissionAsElementUserPermissionIdFkeyUserPermissionAsElementCreateInput>;
  /** The primary key(s) for `userPermissionAsElement` for the far side of the relationship. */
  deleteById?: InputMaybe<UserPermissionAsElementNodeIdDelete>;
  /** The primary key(s) for `userPermissionAsElement` for the far side of the relationship. */
  deleteByUserPermissionId?: InputMaybe<UserPermissionAsElementUserPermissionAsElementPkeyDelete>;
  /** The primary key(s) and patch data for `userPermissionAsElement` for the far side of the relationship. */
  updateById?: InputMaybe<PermissionAsElementOnPermissionAsElementForPermissionAsElementUserPermissionIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `userPermissionAsElement` for the far side of the relationship. */
  updateByUserPermissionId?: InputMaybe<UserPermissionAsElementOnPermissionAsElementForPermissionAsElementUserPermissionIdFkeyUsingUserPermissionAsElementPkeyUpdate>;
};

/** Input for the nested mutation of `permissionAsElement` in the `UserPermissionAsElementInput` mutation. */
export type PermissionAsElementUserPermissionIdFkeyInverseInput = {
  /** The primary key(s) for `permissionAsElement` for the far side of the relationship. */
  connectById?: InputMaybe<Array<PermissionAsElementNodeIdConnect>>;
  /** The primary key(s) for `permissionAsElement` for the far side of the relationship. */
  connectByPermissionId?: InputMaybe<
    Array<PermissionAsElementPermissionAsElementPkeyConnect>
  >;
  /** A `PermissionAsElementInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<PermissionAsElementUserPermissionIdFkeyPermissionAsElementCreateInput>
  >;
  /** The primary key(s) for `permissionAsElement` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<PermissionAsElementNodeIdDelete>>;
  /** The primary key(s) for `permissionAsElement` for the far side of the relationship. */
  deleteByPermissionId?: InputMaybe<
    Array<PermissionAsElementPermissionAsElementPkeyDelete>
  >;
  /** Flag indicating whether all other `permissionAsElement` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `permissionAsElement` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<UserPermissionAsElementOnPermissionAsElementForPermissionAsElementUserPermissionIdFkeyNodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `permissionAsElement` for the far side of the relationship. */
  updateByPermissionId?: InputMaybe<
    Array<PermissionAsElementOnPermissionAsElementForPermissionAsElementUserPermissionIdFkeyUsingPermissionAsElementPkeyUpdate>
  >;
};

/** The `permissionAsElement` to be created by this mutation. */
export type PermissionAsElementUserPermissionIdFkeyPermissionAsElementCreateInput =
  {
    config: Scalars["JSON"];
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    permissionId?: InputMaybe<Scalars["UUID"]>;
    permissionType: PermissionTypeType;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userPermissionAsElement?: InputMaybe<PermissionAsElementUserPermissionIdFkeyInput>;
    userPermissionId?: InputMaybe<Scalars["UUID"]>;
  };

/** The `userPermissionAsElement` to be created by this mutation. */
export type PermissionAsElementUserPermissionIdFkeyUserPermissionAsElementCreateInput =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    email: Scalars["String"];
    modification?: InputMaybe<UserPermissionAsElementIdFkeyInput>;
    permissionAsElements?: InputMaybe<PermissionAsElementUserPermissionIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userPermissionId?: InputMaybe<Scalars["UUID"]>;
  };

/** A connection to a list of `PermissionAsElement` values. */
export type PermissionAsElementsConnection = {
  __typename?: "PermissionAsElementsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<PermissionAsElementAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<PermissionAsElementDistinct>;
  /** A list of edges which contains the `PermissionAsElement` and cursor to aid in pagination. */
  edges: Array<PermissionAsElementsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<PermissionAsElementAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<PermissionAsElementGroup>>;
  /** A list of `PermissionAsElement` objects. */
  nodes: Array<Maybe<PermissionAsElement>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of _all_ `PermissionAsElement` primary keys. */
  permissionIds?: Maybe<Array<Scalars["UUID"]>>;
  /** The count of *all* `PermissionAsElement` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `PermissionAsElement` values. */
export type PermissionAsElementsConnectionGroupedAggregatesArgs = {
  groupBy: Array<PermissionAsElementGroupBy>;
  having?: InputMaybe<PermissionAsElementHavingInput>;
};

/** A connection to a list of `PermissionAsElement` values. */
export type PermissionAsElementsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<PermissionAsElementGroupBy>;
  having?: InputMaybe<PermissionAsElementHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `PermissionAsElement` edge in the connection. */
export type PermissionAsElementsEdge = {
  __typename?: "PermissionAsElementsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `PermissionAsElement` at the end of the edge. */
  node?: Maybe<PermissionAsElement>;
};

/** Methods to use when ordering `PermissionAsElement`. */
export enum PermissionAsElementsOrderBy {
  ConfigAsc = "CONFIG_ASC",
  ConfigDesc = "CONFIG_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  Natural = "NATURAL",
  PermissionIdAsc = "PERMISSION_ID_ASC",
  PermissionIdDesc = "PERMISSION_ID_DESC",
  PermissionTypeAsc = "PERMISSION_TYPE_ASC",
  PermissionTypeDesc = "PERMISSION_TYPE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
  UserPermissionAsElementByUserPermissionIdCreatedAtAsc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_CREATED_AT_ASC",
  UserPermissionAsElementByUserPermissionIdCreatedAtDesc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_CREATED_AT_DESC",
  UserPermissionAsElementByUserPermissionIdCreatedByEmailAsc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_CREATED_BY_EMAIL_ASC",
  UserPermissionAsElementByUserPermissionIdCreatedByEmailDesc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_CREATED_BY_EMAIL_DESC",
  UserPermissionAsElementByUserPermissionIdEmailAsc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_EMAIL_ASC",
  UserPermissionAsElementByUserPermissionIdEmailDesc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_EMAIL_DESC",
  UserPermissionAsElementByUserPermissionIdUpdatedAtAsc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_UPDATED_AT_ASC",
  UserPermissionAsElementByUserPermissionIdUpdatedAtDesc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_UPDATED_AT_DESC",
  UserPermissionAsElementByUserPermissionIdUpdatedByEmailAsc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_UPDATED_BY_EMAIL_ASC",
  UserPermissionAsElementByUserPermissionIdUpdatedByEmailDesc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_UPDATED_BY_EMAIL_DESC",
  UserPermissionAsElementByUserPermissionIdUserPermissionIdAsc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_USER_PERMISSION_ID_ASC",
  UserPermissionAsElementByUserPermissionIdUserPermissionIdDesc = "USER_PERMISSION_AS_ELEMENT_BY_USER_PERMISSION_ID_USER_PERMISSION_ID_DESC",
  UserPermissionIdAsc = "USER_PERMISSION_ID_ASC",
  UserPermissionIdDesc = "USER_PERMISSION_ID_DESC",
}

/**
 * A condition to be used against `Permission` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type PermissionCondition = {
  /** Checks for equality with the object’s `config` field. */
  config?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `permissionId` field. */
  permissionId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `permissionType` field. */
  permissionType?: InputMaybe<PermissionTypeType>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `userPermissionId` field. */
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

export type PermissionDistinct = {
  __typename?: "PermissionDistinct";
  /** Distinct `config` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  config: Array<Scalars["JSON"]>;
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `permissionId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  permissionId: Array<Scalars["UUID"]>;
  /** Distinct `permissionType` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  permissionType: Array<PermissionTypeType>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
  /** Distinct `userPermissionId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  userPermissionId: Array<Scalars["UUID"]>;
};

export type PermissionDistinctCountAggregateFilter = {
  config?: InputMaybe<BigIntFilter>;
  createdAt?: InputMaybe<BigIntFilter>;
  createdByEmail?: InputMaybe<BigIntFilter>;
  permissionId?: InputMaybe<BigIntFilter>;
  permissionType?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  updatedByEmail?: InputMaybe<BigIntFilter>;
  userPermissionId?: InputMaybe<BigIntFilter>;
};

export type PermissionDistinctCountAggregates = {
  __typename?: "PermissionDistinctCountAggregates";
  /** Distinct count of config across the matching connection */
  config?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of permissionId across the matching connection */
  permissionId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of permissionType across the matching connection */
  permissionType?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of userPermissionId across the matching connection */
  userPermissionId?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `Permission` object types. All fields are combined with a logical ‘and.’ */
export type PermissionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<PermissionFilter>>;
  /** Filter by the object’s `config` field. */
  config?: InputMaybe<JsonFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<PermissionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<PermissionFilter>>;
  /** Filter by the object’s `permissionId` field. */
  permissionId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `permissionType` field. */
  permissionType?: InputMaybe<PermissionTypeTypeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userPermission` relation. */
  userPermission?: InputMaybe<UserPermissionFilter>;
  /** Filter by the object’s `userPermissionId` field. */
  userPermissionId?: InputMaybe<UuidFilter>;
};

export type PermissionGroup = {
  __typename?: "PermissionGroup";
  /** `PermissionFilter` that was used to implicitly filter `permissions`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** A list of _all_ `Permission` primary keys that belong to the `PermissionGroup`. */
  permissionIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `Permission` of this `PermissionGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `PermissionGroup`. Overriding these may result in the wrong set of `Permission` returned.
   */
  permissions?: Maybe<PermissionsConnection>;
  /** The count of _all_ `Permission` you could get from the `PermissionGroup`. */
  totalCount: Scalars["Int"];
};

export type PermissionGroupPermissionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<PermissionCondition>;
  filter?: InputMaybe<PermissionFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PermissionsOrderBy>>;
};

/** Grouping methods for `Permission` for usage during aggregation. */
export enum PermissionGroupBy {
  Config = "CONFIG",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  PermissionType = "PERMISSION_TYPE",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
  UserPermissionByUserPermissionIdCreatedAt = "USER_PERMISSION_BY_USER_PERMISSION_ID_CREATED_AT",
  UserPermissionByUserPermissionIdCreatedByEmail = "USER_PERMISSION_BY_USER_PERMISSION_ID_CREATED_BY_EMAIL",
  UserPermissionByUserPermissionIdDataRoomId = "USER_PERMISSION_BY_USER_PERMISSION_ID_DATA_ROOM_ID",
  UserPermissionByUserPermissionIdEmail = "USER_PERMISSION_BY_USER_PERMISSION_ID_EMAIL",
  UserPermissionByUserPermissionIdUpdatedAt = "USER_PERMISSION_BY_USER_PERMISSION_ID_UPDATED_AT",
  UserPermissionByUserPermissionIdUpdatedByEmail = "USER_PERMISSION_BY_USER_PERMISSION_ID_UPDATED_BY_EMAIL",
  UserPermissionByUserPermissionIdUserPermissionId = "USER_PERMISSION_BY_USER_PERMISSION_ID_USER_PERMISSION_ID",
  UserPermissionId = "USER_PERMISSION_ID",
}

export type PermissionHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PermissionHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Permission` aggregates. */
export type PermissionHavingInput = {
  AND?: InputMaybe<Array<PermissionHavingInput>>;
  OR?: InputMaybe<Array<PermissionHavingInput>>;
  average?: InputMaybe<PermissionHavingAverageInput>;
  distinctCount?: InputMaybe<PermissionHavingDistinctCountInput>;
  max?: InputMaybe<PermissionHavingMaxInput>;
  min?: InputMaybe<PermissionHavingMinInput>;
  stddevPopulation?: InputMaybe<PermissionHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<PermissionHavingStddevSampleInput>;
  sum?: InputMaybe<PermissionHavingSumInput>;
  variancePopulation?: InputMaybe<PermissionHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<PermissionHavingVarianceSampleInput>;
};

export type PermissionHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PermissionHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PermissionHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PermissionHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PermissionHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PermissionHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type PermissionHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Permission` */
export type PermissionInput = {
  config: Scalars["JSON"];
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  permissionId?: InputMaybe<Scalars["UUID"]>;
  permissionType: PermissionTypeType;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userPermission?: InputMaybe<PermissionUserPermissionIdFkeyInput>;
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type PermissionNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `permission` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type PermissionNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `permission` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type PermissionOnPermissionForPermissionUserPermissionIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `userPermission` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `userPermission` being updated. */
    patch: UserPermissionPatch;
  };

/** The fields on `permission` to look up the row to update. */
export type PermissionOnPermissionForPermissionUserPermissionIdFkeyUsingPermissionPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `permission` being updated. */
    patch: UpdatePermissionOnPermissionForPermissionUserPermissionIdFkeyPatch;
    permissionId: Scalars["UUID"];
  };

/** Represents an update to a `Permission`. Fields that are set will be updated. */
export type PermissionPatch = {
  config?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  permissionId?: InputMaybe<Scalars["UUID"]>;
  permissionType?: InputMaybe<PermissionTypeType>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userPermission?: InputMaybe<PermissionUserPermissionIdFkeyInput>;
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

/** The fields on `permission` to look up the row to connect. */
export type PermissionPermissionPkeyConnect = {
  permissionId: Scalars["UUID"];
};

/** The fields on `permission` to look up the row to delete. */
export type PermissionPermissionPkeyDelete = {
  permissionId: Scalars["UUID"];
};

export enum PermissionTypeType {
  DryRunPermission = "DRY_RUN_PERMISSION",
  ExecuteComputePermission = "EXECUTE_COMPUTE_PERMISSION",
  LeafCrudPermission = "LEAF_CRUD_PERMISSION",
  RetrieveAuditLogPermission = "RETRIEVE_AUDIT_LOG_PERMISSION",
  RetrieveDataRoomPermission = "RETRIEVE_DATA_ROOM_PERMISSION",
  RetrieveDataRoomStatusPermission = "RETRIEVE_DATA_ROOM_STATUS_PERMISSION",
  RetrievePublishedDatasetsPermission = "RETRIEVE_PUBLISHED_DATASETS_PERMISSION",
  UpdateDataRoomStatusPermission = "UPDATE_DATA_ROOM_STATUS_PERMISSION",
}

/** A filter to be used against PermissionTypeType fields. All fields are combined with a logical ‘and.’ */
export type PermissionTypeTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<PermissionTypeType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<PermissionTypeType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<PermissionTypeType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<PermissionTypeType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<PermissionTypeType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<PermissionTypeType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<PermissionTypeType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<PermissionTypeType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<PermissionTypeType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<PermissionTypeType>>;
};

/** Input for the nested mutation of `userPermission` in the `PermissionInput` mutation. */
export type PermissionUserPermissionIdFkeyInput = {
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  connectByDataRoomIdAndEmail?: InputMaybe<UserPermissionUserPermissionDataRoomIdEmailKeyConnect>;
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  connectById?: InputMaybe<UserPermissionNodeIdConnect>;
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  connectByUserPermissionId?: InputMaybe<UserPermissionUserPermissionPkeyConnect>;
  /** A `UserPermissionInput` object that will be created and connected to this object. */
  create?: InputMaybe<PermissionUserPermissionIdFkeyUserPermissionCreateInput>;
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  deleteByDataRoomIdAndEmail?: InputMaybe<UserPermissionUserPermissionDataRoomIdEmailKeyDelete>;
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  deleteById?: InputMaybe<UserPermissionNodeIdDelete>;
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  deleteByUserPermissionId?: InputMaybe<UserPermissionUserPermissionPkeyDelete>;
  /** The primary key(s) and patch data for `userPermission` for the far side of the relationship. */
  updateByDataRoomIdAndEmail?: InputMaybe<UserPermissionOnPermissionForPermissionUserPermissionIdFkeyUsingUserPermissionDataRoomIdEmailKeyUpdate>;
  /** The primary key(s) and patch data for `userPermission` for the far side of the relationship. */
  updateById?: InputMaybe<PermissionOnPermissionForPermissionUserPermissionIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `userPermission` for the far side of the relationship. */
  updateByUserPermissionId?: InputMaybe<UserPermissionOnPermissionForPermissionUserPermissionIdFkeyUsingUserPermissionPkeyUpdate>;
};

/** Input for the nested mutation of `permission` in the `UserPermissionInput` mutation. */
export type PermissionUserPermissionIdFkeyInverseInput = {
  /** The primary key(s) for `permission` for the far side of the relationship. */
  connectById?: InputMaybe<Array<PermissionNodeIdConnect>>;
  /** The primary key(s) for `permission` for the far side of the relationship. */
  connectByPermissionId?: InputMaybe<Array<PermissionPermissionPkeyConnect>>;
  /** A `PermissionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<PermissionUserPermissionIdFkeyPermissionCreateInput>
  >;
  /** The primary key(s) for `permission` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<PermissionNodeIdDelete>>;
  /** The primary key(s) for `permission` for the far side of the relationship. */
  deleteByPermissionId?: InputMaybe<Array<PermissionPermissionPkeyDelete>>;
  /** Flag indicating whether all other `permission` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `permission` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<UserPermissionOnPermissionForPermissionUserPermissionIdFkeyNodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `permission` for the far side of the relationship. */
  updateByPermissionId?: InputMaybe<
    Array<PermissionOnPermissionForPermissionUserPermissionIdFkeyUsingPermissionPkeyUpdate>
  >;
};

/** The `permission` to be created by this mutation. */
export type PermissionUserPermissionIdFkeyPermissionCreateInput = {
  config: Scalars["JSON"];
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  permissionId?: InputMaybe<Scalars["UUID"]>;
  permissionType: PermissionTypeType;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userPermission?: InputMaybe<PermissionUserPermissionIdFkeyInput>;
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

/** The `userPermission` to be created by this mutation. */
export type PermissionUserPermissionIdFkeyUserPermissionCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<UserPermissionDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  email?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<PermissionUserPermissionIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByEmail?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0Input>;
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

/** A connection to a list of `Permission` values. */
export type PermissionsConnection = {
  __typename?: "PermissionsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<PermissionAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<PermissionDistinct>;
  /** A list of edges which contains the `Permission` and cursor to aid in pagination. */
  edges: Array<PermissionsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<PermissionAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<PermissionGroup>>;
  /** A list of `Permission` objects. */
  nodes: Array<Maybe<Permission>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of _all_ `Permission` primary keys. */
  permissionIds?: Maybe<Array<Scalars["UUID"]>>;
  /** The count of *all* `Permission` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `Permission` values. */
export type PermissionsConnectionGroupedAggregatesArgs = {
  groupBy: Array<PermissionGroupBy>;
  having?: InputMaybe<PermissionHavingInput>;
};

/** A connection to a list of `Permission` values. */
export type PermissionsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<PermissionGroupBy>;
  having?: InputMaybe<PermissionHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `Permission` edge in the connection. */
export type PermissionsEdge = {
  __typename?: "PermissionsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `Permission` at the end of the edge. */
  node?: Maybe<Permission>;
};

/** Methods to use when ordering `Permission`. */
export enum PermissionsOrderBy {
  ConfigAsc = "CONFIG_ASC",
  ConfigDesc = "CONFIG_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  Natural = "NATURAL",
  PermissionIdAsc = "PERMISSION_ID_ASC",
  PermissionIdDesc = "PERMISSION_ID_DESC",
  PermissionTypeAsc = "PERMISSION_TYPE_ASC",
  PermissionTypeDesc = "PERMISSION_TYPE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
  UserPermissionByUserPermissionIdCreatedAtAsc = "USER_PERMISSION_BY_USER_PERMISSION_ID_CREATED_AT_ASC",
  UserPermissionByUserPermissionIdCreatedAtDesc = "USER_PERMISSION_BY_USER_PERMISSION_ID_CREATED_AT_DESC",
  UserPermissionByUserPermissionIdCreatedByEmailAsc = "USER_PERMISSION_BY_USER_PERMISSION_ID_CREATED_BY_EMAIL_ASC",
  UserPermissionByUserPermissionIdCreatedByEmailDesc = "USER_PERMISSION_BY_USER_PERMISSION_ID_CREATED_BY_EMAIL_DESC",
  UserPermissionByUserPermissionIdDataRoomIdAsc = "USER_PERMISSION_BY_USER_PERMISSION_ID_DATA_ROOM_ID_ASC",
  UserPermissionByUserPermissionIdDataRoomIdDesc = "USER_PERMISSION_BY_USER_PERMISSION_ID_DATA_ROOM_ID_DESC",
  UserPermissionByUserPermissionIdEmailAsc = "USER_PERMISSION_BY_USER_PERMISSION_ID_EMAIL_ASC",
  UserPermissionByUserPermissionIdEmailDesc = "USER_PERMISSION_BY_USER_PERMISSION_ID_EMAIL_DESC",
  UserPermissionByUserPermissionIdUpdatedAtAsc = "USER_PERMISSION_BY_USER_PERMISSION_ID_UPDATED_AT_ASC",
  UserPermissionByUserPermissionIdUpdatedAtDesc = "USER_PERMISSION_BY_USER_PERMISSION_ID_UPDATED_AT_DESC",
  UserPermissionByUserPermissionIdUpdatedByEmailAsc = "USER_PERMISSION_BY_USER_PERMISSION_ID_UPDATED_BY_EMAIL_ASC",
  UserPermissionByUserPermissionIdUpdatedByEmailDesc = "USER_PERMISSION_BY_USER_PERMISSION_ID_UPDATED_BY_EMAIL_DESC",
  UserPermissionByUserPermissionIdUserPermissionIdAsc = "USER_PERMISSION_BY_USER_PERMISSION_ID_USER_PERMISSION_ID_ASC",
  UserPermissionByUserPermissionIdUserPermissionIdDesc = "USER_PERMISSION_BY_USER_PERMISSION_ID_USER_PERMISSION_ID_DESC",
  UserPermissionIdAsc = "USER_PERMISSION_ID_ASC",
  UserPermissionIdDesc = "USER_PERMISSION_ID_DESC",
}

export type PublishedDataRoom = {
  __typename?: "PublishedDataRoom";
  attestationHash: Scalars["String"];
  computeJobs: Array<ComputeJob>;
  computeNodesOrder: Array<Scalars["ID"]>;
  creationDate: Scalars["Datetime"];
  dataNodesOrder: Array<Scalars["ID"]>;
  description?: Maybe<Scalars["String"]>;
  enableDevelopment: Scalars["Boolean"];
  enableInteractivity: Scalars["Boolean"];
  enclaveConfigurationPin: Scalars["String"];
  enclaveId: Scalars["String"];
  id: Scalars["ID"];
  isArchived: Scalars["Boolean"];
  isStopped: Scalars["Boolean"];
  logo?: Maybe<Scalars["String"]>;
  nodes: Array<PublishedNode>;
  participants: Array<User>;
  title: Scalars["String"];
  updateDate: Scalars["Datetime"];
};

export type PublishedLeafNode = {
  dataset?: Maybe<Dataset>;
  id: Scalars["ID"];
  isRequired: Scalars["Boolean"];
  name: Scalars["String"];
};

export type PublishedNode = {
  id: Scalars["ID"];
  name: Scalars["String"];
};

export type PublishedRawLeafNode = PublishedLeafNode &
  PublishedNode & {
    __typename?: "PublishedRawLeafNode";
    dataset?: Maybe<Dataset>;
    id: Scalars["ID"];
    isRequired: Scalars["Boolean"];
    name: Scalars["String"];
  };

export type PublishedSqlNode = PublishedNode & {
  __typename?: "PublishedSqlNode";
  dependencies: Array<PublishedTableLeafNode>;
  id: Scalars["ID"];
  name: Scalars["String"];
  privacyFilter?: Maybe<SqlNodePrivacyFilter>;
  statement: Scalars["String"];
};

export type PublishedTableLeafNode = PublishedLeafNode &
  PublishedNode & {
    __typename?: "PublishedTableLeafNode";
    columns: Array<TableLeafNodeColumn>;
    columnsOrder: Array<Scalars["ID"]>;
    dataset?: Maybe<Dataset>;
    id: Scalars["ID"];
    isRequired: Scalars["Boolean"];
    name: Scalars["String"];
  };

/** The root query type which gives access points into the data universe. */
export type Query = Node & {
  __typename?: "Query";
  /** Reads and enables pagination through a set of `DataRoom`. */
  archivedDataRooms?: Maybe<DataRoomsConnection>;
  attestationSpecificationAsElement?: Maybe<AttestationSpecificationAsElement>;
  /** Reads a single `AttestationSpecificationAsElement` using its globally unique `ID`. */
  attestationSpecificationAsElementById?: Maybe<AttestationSpecificationAsElement>;
  /** Reads and enables pagination through a set of `AttestationSpecificationAsElement`. */
  attestationSpecificationAsElements?: Maybe<AttestationSpecificationAsElementsConnection>;
  authenticationMethodAsElement?: Maybe<AuthenticationMethodAsElement>;
  /** Reads a single `AuthenticationMethodAsElement` using its globally unique `ID`. */
  authenticationMethodAsElementById?: Maybe<AuthenticationMethodAsElement>;
  /** Reads and enables pagination through a set of `AuthenticationMethodAsElement`. */
  authenticationMethodAsElements?: Maybe<AuthenticationMethodAsElementsConnection>;
  byteaToHex?: Maybe<Scalars["String"]>;
  computeNode?: Maybe<ComputeNode>;
  computeNodeAsElement?: Maybe<ComputeNodeAsElement>;
  /** Reads a single `ComputeNodeAsElement` using its globally unique `ID`. */
  computeNodeAsElementById?: Maybe<ComputeNodeAsElement>;
  /** Reads and enables pagination through a set of `ComputeNodeAsElement`. */
  computeNodeAsElements?: Maybe<ComputeNodeAsElementsConnection>;
  /** Reads a single `ComputeNode` using its globally unique `ID`. */
  computeNodeById?: Maybe<ComputeNode>;
  /** Reads and enables pagination through a set of `ComputeNode`. */
  computeNodes?: Maybe<ComputeNodesConnection>;
  config?: Maybe<Config>;
  /** Reads a single `Config` using its globally unique `ID`. */
  configById?: Maybe<Config>;
  /** Reads and enables pagination through a set of `Config`. */
  configs?: Maybe<ConfigsConnection>;
  configuration?: Maybe<Configuration>;
  configurationByCommitId?: Maybe<Configuration>;
  /** Reads a single `Configuration` using its globally unique `ID`. */
  configurationById?: Maybe<Configuration>;
  /** Reads and enables pagination through a set of `Configuration`. */
  configurations?: Maybe<ConfigurationsConnection>;
  /** Returns `email` for the currently logged in `User`. */
  currentEmail?: Maybe<Scalars["String"]>;
  dataRoom?: Maybe<DataRoom>;
  dataRoomByDataRoomHash?: Maybe<DataRoom>;
  /** Reads a single `DataRoom` using its globally unique `ID`. */
  dataRoomById?: Maybe<DataRoom>;
  /** Reads and enables pagination through a set of `DataRoom`. */
  dataRooms?: Maybe<DataRoomsConnection>;
  /** Reads and enables pagination through a set of `DataRoom`. */
  datasetDataRooms?: Maybe<DataRoomsConnection>;
  datasetLink?: Maybe<DatasetLink>;
  /** Reads a single `DatasetLink` using its globally unique `ID`. */
  datasetLinkById?: Maybe<DatasetLink>;
  /** Reads and enables pagination through a set of `DatasetLink`. */
  datasetLinks?: Maybe<DatasetLinksConnection>;
  datasetMeta?: Maybe<DatasetMeta>;
  /** Reads a single `DatasetMeta` using its globally unique `ID`. */
  datasetMetaById?: Maybe<DatasetMeta>;
  /** Reads and enables pagination through a set of `DatasetMeta`. */
  datasetMetas?: Maybe<DatasetMetasConnection>;
  datasetStat?: Maybe<DatasetStat>;
  /** Reads a single `DatasetStat` using its globally unique `ID`. */
  datasetStatById?: Maybe<DatasetStat>;
  /** Reads and enables pagination through a set of `DatasetStat`. */
  datasetStats?: Maybe<DatasetStatsConnection>;
  /** Reads a set of `Dataset`. */
  datasets?: Maybe<Datasets>;
  domain?: Maybe<Domain>;
  domainByDomain?: Maybe<Domain>;
  /** Reads a single `Domain` using its globally unique `ID`. */
  domainById?: Maybe<Domain>;
  /** Reads and enables pagination through a set of `Domain`. */
  domains?: Maybe<DomainsConnection>;
  /** Reads all events. */
  events?: Maybe<EventsPayload>;
  executionTime?: Maybe<ExecutionTime>;
  /** Reads a single `ExecutionTime` using its globally unique `ID`. */
  executionTimeById?: Maybe<ExecutionTime>;
  /** Reads and enables pagination through a set of `ExecutionTime`. */
  executionTimes?: Maybe<ExecutionTimesConnection>;
  /** Reads and enables pagination through a set of `DataRoom`. */
  favoriteDataRooms?: Maybe<DataRoomsConnection>;
  hasPermission?: Maybe<Scalars["Boolean"]>;
  /** The root query type must be a `Node` to work well with Relay 1 mutations. This just resolves to `query`. */
  id: Scalars["ID"];
  isConfigurationOwner?: Maybe<Scalars["Boolean"]>;
  isCurrentEmail?: Maybe<Scalars["Boolean"]>;
  isDataRoomImmutable?: Maybe<Scalars["Boolean"]>;
  isDataRoomInteractive?: Maybe<Scalars["Boolean"]>;
  isDataRoomMutable?: Maybe<Scalars["Boolean"]>;
  isDataRoomOwner?: Maybe<Scalars["Boolean"]>;
  isDataRoomUser?: Maybe<Scalars["Boolean"]>;
  isSuperAdmin?: Maybe<Scalars["Boolean"]>;
  job?: Maybe<Job>;
  /** Reads a single `Job` using its globally unique `ID`. */
  jobById?: Maybe<Job>;
  /** Reads and enables pagination through a set of `Job`. */
  jobs?: Maybe<JobsConnection>;
  lock?: Maybe<Lock>;
  /** Reads a single `Lock` using its globally unique `ID`. */
  lockById?: Maybe<Lock>;
  /** Reads and enables pagination through a set of `Lock`. */
  locks?: Maybe<LocksConnection>;
  modification?: Maybe<Modification>;
  modificationByElementId?: Maybe<Modification>;
  /** Reads a single `Modification` using its globally unique `ID`. */
  modificationById?: Maybe<Modification>;
  /** Reads and enables pagination through a set of `Modification`. */
  modifications?: Maybe<ModificationsConnection>;
  /** Reads and enables pagination through a set of `DataRoom`. */
  myDataRooms?: Maybe<DataRoomsConnection>;
  /** Fetches an object given its globally unique `ID`. */
  node?: Maybe<Node>;
  organization?: Maybe<Organization>;
  organizationAttribute?: Maybe<OrganizationAttribute>;
  /** Reads a single `OrganizationAttribute` using its globally unique `ID`. */
  organizationAttributeById?: Maybe<OrganizationAttribute>;
  /** Reads and enables pagination through a set of `OrganizationAttribute`. */
  organizationAttributes?: Maybe<OrganizationAttributesConnection>;
  /** Reads a single `Organization` using its globally unique `ID`. */
  organizationById?: Maybe<Organization>;
  organizationLogo?: Maybe<OrganizationLogo>;
  /** Reads a single `OrganizationLogo` using its globally unique `ID`. */
  organizationLogoById?: Maybe<OrganizationLogo>;
  /** Reads and enables pagination through a set of `OrganizationLogo`. */
  organizationLogos?: Maybe<OrganizationLogosConnection>;
  organizationMembership?: Maybe<OrganizationMembership>;
  /** Reads a single `OrganizationMembership` using its globally unique `ID`. */
  organizationMembershipById?: Maybe<OrganizationMembership>;
  /** Reads and enables pagination through a set of `OrganizationMembership`. */
  organizationMemberships?: Maybe<OrganizationMembershipsConnection>;
  organizationQuota?: Maybe<OrganizationQuota>;
  /** Reads a single `OrganizationQuota` using its globally unique `ID`. */
  organizationQuotaById?: Maybe<OrganizationQuota>;
  /** Reads and enables pagination through a set of `OrganizationQuota`. */
  organizationQuotas?: Maybe<OrganizationQuotasConnection>;
  /** Reads and enables pagination through a set of `Organization`. */
  organizations?: Maybe<OrganizationsConnection>;
  permission?: Maybe<Permission>;
  permissionAsElement?: Maybe<PermissionAsElement>;
  /** Reads a single `PermissionAsElement` using its globally unique `ID`. */
  permissionAsElementById?: Maybe<PermissionAsElement>;
  /** Reads and enables pagination through a set of `PermissionAsElement`. */
  permissionAsElements?: Maybe<PermissionAsElementsConnection>;
  /** Reads a single `Permission` using its globally unique `ID`. */
  permissionById?: Maybe<Permission>;
  /** Reads and enables pagination through a set of `Permission`. */
  permissions?: Maybe<PermissionsConnection>;
  publishedDataRoom?: Maybe<PublishedDataRoom>;
  /**
   * Exposes the root query type nested one level down. This is helpful for Relay 1
   * which can only query top level fields if they are in a particular form.
   */
  query: Query;
  quota?: Maybe<Quota>;
  /** Reads a single `Quota` using its globally unique `ID`. */
  quotaById?: Maybe<Quota>;
  /** Reads and enables pagination through a set of `Quota`. */
  quotas?: Maybe<QuotasConnection>;
  request?: Maybe<Request>;
  requestByConfigurationId?: Maybe<Request>;
  /** Reads a single `Request` using its globally unique `ID`. */
  requestById?: Maybe<Request>;
  /** Reads and enables pagination through a set of `Request`. */
  requests?: Maybe<RequestsConnection>;
  resource?: Maybe<Resource>;
  /** Reads a single `Resource` using its globally unique `ID`. */
  resourceById?: Maybe<Resource>;
  /** Reads and enables pagination through a set of `Resource`. */
  resources?: Maybe<ResourcesConnection>;
  /** Reads and enables pagination through a set of `DataRoom`. */
  sharedWithMeDataRooms?: Maybe<DataRoomsConnection>;
  signature?: Maybe<Signature>;
  /** Reads a single `Signature` using its globally unique `ID`. */
  signatureById?: Maybe<Signature>;
  signatureBySignatureHash?: Maybe<Signature>;
  /** Reads and enables pagination through a set of `Signature`. */
  signatures?: Maybe<SignaturesConnection>;
  signee?: Maybe<Signee>;
  /** Reads a single `Signee` using its globally unique `ID`. */
  signeeById?: Maybe<Signee>;
  signeeByRequestIdAndSigneeEmail?: Maybe<Signee>;
  /** Reads and enables pagination through a set of `Signee`. */
  signees?: Maybe<SigneesConnection>;
  state?: Maybe<State>;
  /** Reads a single `State` using its globally unique `ID`. */
  stateById?: Maybe<State>;
  /** Reads and enables pagination through a set of `State`. */
  states?: Maybe<StatesConnection>;
  user?: Maybe<User>;
  userAttribute?: Maybe<UserAttribute>;
  /** Reads a single `UserAttribute` using its globally unique `ID`. */
  userAttributeById?: Maybe<UserAttribute>;
  /** Reads and enables pagination through a set of `UserAttribute`. */
  userAttributes?: Maybe<UserAttributesConnection>;
  userByEmail?: Maybe<User>;
  /** Reads a single `User` using its globally unique `ID`. */
  userById?: Maybe<User>;
  userConfig?: Maybe<UserConfig>;
  userConfigByDataRoomIdAndEmail?: Maybe<UserConfig>;
  /** Reads a single `UserConfig` using its globally unique `ID`. */
  userConfigById?: Maybe<UserConfig>;
  /** Reads and enables pagination through a set of `UserConfig`. */
  userConfigs?: Maybe<UserConfigsConnection>;
  userPermission?: Maybe<UserPermission>;
  userPermissionAsElement?: Maybe<UserPermissionAsElement>;
  /** Reads a single `UserPermissionAsElement` using its globally unique `ID`. */
  userPermissionAsElementById?: Maybe<UserPermissionAsElement>;
  /** Reads and enables pagination through a set of `UserPermissionAsElement`. */
  userPermissionAsElements?: Maybe<UserPermissionAsElementsConnection>;
  userPermissionByDataRoomIdAndEmail?: Maybe<UserPermission>;
  /** Reads a single `UserPermission` using its globally unique `ID`. */
  userPermissionById?: Maybe<UserPermission>;
  /** Reads and enables pagination through a set of `UserPermission`. */
  userPermissions?: Maybe<UserPermissionsConnection>;
  userQuota?: Maybe<UserQuota>;
  /** Reads a single `UserQuota` using its globally unique `ID`. */
  userQuotaById?: Maybe<UserQuota>;
  /** Reads and enables pagination through a set of `UserQuota`. */
  userQuotas?: Maybe<UserQuotasConnection>;
  /** Reads and enables pagination through a set of `User`. */
  users?: Maybe<UsersConnection>;
};

/** The root query type which gives access points into the data universe. */
export type QueryArchivedDataRoomsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DataRoomCondition>;
  filter?: InputMaybe<DataRoomFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DataRoomsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAttestationSpecificationAsElementArgs = {
  attestationSpecificationId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAttestationSpecificationAsElementByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAttestationSpecificationAsElementsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<AttestationSpecificationAsElementCondition>;
  filter?: InputMaybe<AttestationSpecificationAsElementFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AttestationSpecificationAsElementsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryAuthenticationMethodAsElementArgs = {
  authenticationMethodId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAuthenticationMethodAsElementByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryAuthenticationMethodAsElementsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<AuthenticationMethodAsElementCondition>;
  filter?: InputMaybe<AuthenticationMethodAsElementFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<AuthenticationMethodAsElementsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryByteaToHexArgs = {
  bytea: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryComputeNodeArgs = {
  computeNodeId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryComputeNodeAsElementArgs = {
  computeNodeId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryComputeNodeAsElementByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryComputeNodeAsElementsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ComputeNodeAsElementCondition>;
  filter?: InputMaybe<ComputeNodeAsElementFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ComputeNodeAsElementsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryComputeNodeByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryComputeNodesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ComputeNodeCondition>;
  filter?: InputMaybe<ComputeNodeFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ComputeNodesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryConfigArgs = {
  dataRoomId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryConfigByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryConfigsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ConfigCondition>;
  filter?: InputMaybe<ConfigFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ConfigsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryConfigurationArgs = {
  configurationId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryConfigurationByCommitIdArgs = {
  commitId: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryConfigurationByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryConfigurationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ConfigurationCondition>;
  filter?: InputMaybe<ConfigurationFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDataRoomArgs = {
  dataRoomId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDataRoomByDataRoomHashArgs = {
  dataRoomHash: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDataRoomByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDataRoomsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DataRoomCondition>;
  filter?: InputMaybe<DataRoomFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DataRoomsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetDataRoomsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  datasetHash: Scalars["String"];
  filter?: InputMaybe<DataRoomFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetLinkArgs = {
  datasetLinkId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetLinkByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetLinksArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DatasetLinkCondition>;
  filter?: InputMaybe<DatasetLinkFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DatasetLinksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetMetaArgs = {
  datasetHash: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetMetaByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetMetasArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DatasetMetaCondition>;
  filter?: InputMaybe<DatasetMetaFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DatasetMetasOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetStatArgs = {
  datasetHash: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetStatByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetStatsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DatasetStatCondition>;
  filter?: InputMaybe<DatasetStatFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DatasetStatsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDatasetsArgs = {
  condition?: InputMaybe<Scalars["JSON"]>;
  filter?: InputMaybe<Scalars["JSON"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["JSON"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryDomainArgs = {
  domainId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDomainByDomainArgs = {
  domain: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDomainByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryDomainsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DomainCondition>;
  filter?: InputMaybe<DomainFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DomainsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryExecutionTimeArgs = {
  taskId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryExecutionTimeByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryExecutionTimesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ExecutionTimeCondition>;
  filter?: InputMaybe<ExecutionTimeFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ExecutionTimesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryFavoriteDataRoomsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DataRoomCondition>;
  filter?: InputMaybe<DataRoomFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DataRoomsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryHasPermissionArgs = {
  permission: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryIsConfigurationOwnerArgs = {
  configurationId: Scalars["UUID"];
  email?: InputMaybe<Scalars["String"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryIsCurrentEmailArgs = {
  email: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryIsDataRoomImmutableArgs = {
  dataRoomId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryIsDataRoomInteractiveArgs = {
  dataRoomId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryIsDataRoomMutableArgs = {
  dataRoomId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryIsDataRoomOwnerArgs = {
  dataRoomId: Scalars["UUID"];
  email?: InputMaybe<Scalars["String"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryIsDataRoomUserArgs = {
  dataRoomId: Scalars["UUID"];
  email?: InputMaybe<Scalars["String"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryJobArgs = {
  computeNodeId: Scalars["UUID"];
  ownerEmail: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryJobByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryJobsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<JobCondition>;
  filter?: InputMaybe<JobFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<JobsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryLockArgs = {
  dataRoomId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLockByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryLocksArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<LockCondition>;
  filter?: InputMaybe<LockFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<LocksOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryModificationArgs = {
  modificationId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryModificationByElementIdArgs = {
  elementId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryModificationByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryModificationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ModificationCondition>;
  filter?: InputMaybe<ModificationFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ModificationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryMyDataRoomsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DataRoomCondition>;
  filter?: InputMaybe<DataRoomFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DataRoomsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryNodeArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganizationArgs = {
  organizationId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganizationAttributeArgs = {
  organizationId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganizationAttributeByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganizationAttributesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<OrganizationAttributeCondition>;
  filter?: InputMaybe<OrganizationAttributeFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrganizationAttributesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganizationByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganizationLogoArgs = {
  organizationId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganizationLogoByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganizationLogosArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<OrganizationLogoCondition>;
  filter?: InputMaybe<OrganizationLogoFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrganizationLogosOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganizationMembershipArgs = {
  organizationMembershipId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganizationMembershipByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganizationMembershipsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<OrganizationMembershipCondition>;
  filter?: InputMaybe<OrganizationMembershipFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrganizationMembershipsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganizationQuotaArgs = {
  organizationId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganizationQuotaByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganizationQuotasArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<OrganizationQuotaCondition>;
  filter?: InputMaybe<OrganizationQuotaFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrganizationQuotasOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryOrganizationsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<OrganizationCondition>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPermissionArgs = {
  permissionId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPermissionAsElementArgs = {
  permissionId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPermissionAsElementByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPermissionAsElementsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<PermissionAsElementCondition>;
  filter?: InputMaybe<PermissionAsElementFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PermissionAsElementsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPermissionByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryPermissionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<PermissionCondition>;
  filter?: InputMaybe<PermissionFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PermissionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryPublishedDataRoomArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryQuotaArgs = {
  email: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryQuotaByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryQuotasArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<QuotaCondition>;
  filter?: InputMaybe<QuotaFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<QuotasOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryRequestArgs = {
  requestId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryRequestByConfigurationIdArgs = {
  configurationId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryRequestByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryRequestsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<RequestCondition>;
  filter?: InputMaybe<RequestFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<RequestsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryResourceArgs = {
  ownerEmail: Scalars["String"];
  resourceId: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryResourceByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryResourcesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ResourceCondition>;
  filter?: InputMaybe<ResourceFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ResourcesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySharedWithMeDataRoomsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DataRoomCondition>;
  filter?: InputMaybe<DataRoomFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DataRoomsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySignatureArgs = {
  signeeId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySignatureByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySignatureBySignatureHashArgs = {
  signatureHash: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySignaturesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<SignatureCondition>;
  filter?: InputMaybe<SignatureFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SignaturesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QuerySigneeArgs = {
  signeeId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySigneeByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySigneeByRequestIdAndSigneeEmailArgs = {
  requestId: Scalars["UUID"];
  signeeEmail: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QuerySigneesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<SigneeCondition>;
  filter?: InputMaybe<SigneeFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SigneesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryStateArgs = {
  dataRoomId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryStateByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<StateCondition>;
  filter?: InputMaybe<StateFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserArgs = {
  userId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserAttributeArgs = {
  userId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserAttributeByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserAttributesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<UserAttributeCondition>;
  filter?: InputMaybe<UserAttributeFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserAttributesOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserByEmailArgs = {
  email: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserConfigArgs = {
  userConfigId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserConfigByDataRoomIdAndEmailArgs = {
  dataRoomId: Scalars["UUID"];
  email: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserConfigByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserConfigsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<UserConfigCondition>;
  filter?: InputMaybe<UserConfigFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserConfigsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserPermissionArgs = {
  userPermissionId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserPermissionAsElementArgs = {
  userPermissionId: Scalars["UUID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserPermissionAsElementByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserPermissionAsElementsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<UserPermissionAsElementCondition>;
  filter?: InputMaybe<UserPermissionAsElementFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserPermissionByDataRoomIdAndEmailArgs = {
  dataRoomId: Scalars["UUID"];
  email: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserPermissionByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserPermissionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<UserPermissionCondition>;
  filter?: InputMaybe<UserPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserPermissionsOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUserQuotaArgs = {
  email: Scalars["String"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserQuotaByIdArgs = {
  id: Scalars["ID"];
};

/** The root query type which gives access points into the data universe. */
export type QueryUserQuotasArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<UserQuotaCondition>;
  filter?: InputMaybe<UserQuotaFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserQuotasOrderBy>>;
};

/** The root query type which gives access points into the data universe. */
export type QueryUsersArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

export type Quota = Node & {
  __typename?: "Quota";
  available?: Maybe<Scalars["Int"]>;
  email: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
};

export type QuotaAggregates = {
  __typename?: "QuotaAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<QuotaAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<QuotaDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<QuotaMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<QuotaMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<QuotaStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<QuotaStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<QuotaSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<QuotaVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<QuotaVarianceSampleAggregates>;
};

export type QuotaAverageAggregates = {
  __typename?: "QuotaAverageAggregates";
  /** Mean average of available across the matching connection */
  available?: Maybe<Scalars["BigFloat"]>;
};

/** A condition to be used against `Quota` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type QuotaCondition = {
  /** Checks for equality with the object’s `available` field. */
  available?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]>;
};

export type QuotaDistinct = {
  __typename?: "QuotaDistinct";
  /** Distinct `available` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  available: Array<Maybe<Scalars["Int"]>>;
  /** Distinct `email` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  email: Array<Maybe<Scalars["String"]>>;
};

export type QuotaDistinctCountAggregates = {
  __typename?: "QuotaDistinctCountAggregates";
  /** Distinct count of available across the matching connection */
  available?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `Quota` object types. All fields are combined with a logical ‘and.’ */
export type QuotaFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<QuotaFilter>>;
  /** Filter by the object’s `available` field. */
  available?: InputMaybe<IntFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<QuotaFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<QuotaFilter>>;
};

export type QuotaGroup = {
  __typename?: "QuotaGroup";
  /** A list of _all_ `Quota` primary keys that belong to the `QuotaGroup`. */
  emails?: Maybe<Array<Scalars["String"]>>;
  /** `QuotaFilter` that was used to implicitly filter `quotas`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /**
   * Reads and enables pagination through a set of `Quota` of this `QuotaGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `QuotaGroup`. Overriding these may result in the wrong set of `Quota` returned.
   */
  quotas?: Maybe<QuotasConnection>;
  /** The count of _all_ `Quota` you could get from the `QuotaGroup`. */
  totalCount: Scalars["Int"];
};

export type QuotaGroupQuotasArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<QuotaCondition>;
  filter?: InputMaybe<QuotaFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<QuotasOrderBy>>;
};

/** Grouping methods for `Quota` for usage during aggregation. */
export enum QuotaGroupBy {
  Available = "AVAILABLE",
  Email = "EMAIL",
}

export type QuotaHavingAverageInput = {
  available?: InputMaybe<HavingIntFilter>;
};

export type QuotaHavingDistinctCountInput = {
  available?: InputMaybe<HavingIntFilter>;
};

/** Conditions for `Quota` aggregates. */
export type QuotaHavingInput = {
  AND?: InputMaybe<Array<QuotaHavingInput>>;
  OR?: InputMaybe<Array<QuotaHavingInput>>;
  average?: InputMaybe<QuotaHavingAverageInput>;
  distinctCount?: InputMaybe<QuotaHavingDistinctCountInput>;
  max?: InputMaybe<QuotaHavingMaxInput>;
  min?: InputMaybe<QuotaHavingMinInput>;
  stddevPopulation?: InputMaybe<QuotaHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<QuotaHavingStddevSampleInput>;
  sum?: InputMaybe<QuotaHavingSumInput>;
  variancePopulation?: InputMaybe<QuotaHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<QuotaHavingVarianceSampleInput>;
};

export type QuotaHavingMaxInput = {
  available?: InputMaybe<HavingIntFilter>;
};

export type QuotaHavingMinInput = {
  available?: InputMaybe<HavingIntFilter>;
};

export type QuotaHavingStddevPopulationInput = {
  available?: InputMaybe<HavingIntFilter>;
};

export type QuotaHavingStddevSampleInput = {
  available?: InputMaybe<HavingIntFilter>;
};

export type QuotaHavingSumInput = {
  available?: InputMaybe<HavingIntFilter>;
};

export type QuotaHavingVariancePopulationInput = {
  available?: InputMaybe<HavingIntFilter>;
};

export type QuotaHavingVarianceSampleInput = {
  available?: InputMaybe<HavingIntFilter>;
};

export type QuotaMaxAggregates = {
  __typename?: "QuotaMaxAggregates";
  /** Maximum of available across the matching connection */
  available?: Maybe<Scalars["Int"]>;
};

export type QuotaMinAggregates = {
  __typename?: "QuotaMinAggregates";
  /** Minimum of available across the matching connection */
  available?: Maybe<Scalars["Int"]>;
};

export type QuotaStddevPopulationAggregates = {
  __typename?: "QuotaStddevPopulationAggregates";
  /** Population standard deviation of available across the matching connection */
  available?: Maybe<Scalars["BigFloat"]>;
};

export type QuotaStddevSampleAggregates = {
  __typename?: "QuotaStddevSampleAggregates";
  /** Sample standard deviation of available across the matching connection */
  available?: Maybe<Scalars["BigFloat"]>;
};

export type QuotaSumAggregates = {
  __typename?: "QuotaSumAggregates";
  /** Sum of available across the matching connection */
  available: Scalars["BigInt"];
};

export type QuotaVariancePopulationAggregates = {
  __typename?: "QuotaVariancePopulationAggregates";
  /** Population variance of available across the matching connection */
  available?: Maybe<Scalars["BigFloat"]>;
};

export type QuotaVarianceSampleAggregates = {
  __typename?: "QuotaVarianceSampleAggregates";
  /** Sample variance of available across the matching connection */
  available?: Maybe<Scalars["BigFloat"]>;
};

/** A connection to a list of `Quota` values. */
export type QuotasConnection = {
  __typename?: "QuotasConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<QuotaAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<QuotaDistinct>;
  /** A list of edges which contains the `Quota` and cursor to aid in pagination. */
  edges: Array<QuotasEdge>;
  /** A list of _all_ `Quota` primary keys. */
  emails?: Maybe<Array<Scalars["String"]>>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<QuotaAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<QuotaGroup>>;
  /** A list of `Quota` objects. */
  nodes: Array<Maybe<Quota>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Quota` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `Quota` values. */
export type QuotasConnectionGroupedAggregatesArgs = {
  groupBy: Array<QuotaGroupBy>;
  having?: InputMaybe<QuotaHavingInput>;
};

/** A connection to a list of `Quota` values. */
export type QuotasConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<QuotaGroupBy>;
  having?: InputMaybe<QuotaHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `Quota` edge in the connection. */
export type QuotasEdge = {
  __typename?: "QuotasEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `Quota` at the end of the edge. */
  node?: Maybe<Quota>;
};

/** Methods to use when ordering `Quota`. */
export enum QuotasOrderBy {
  AvailableAsc = "AVAILABLE_ASC",
  AvailableDesc = "AVAILABLE_DESC",
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
}

export type Request = Node & {
  __typename?: "Request";
  /** Reads a single `Configuration` that is related to this `Request`. */
  configuration?: Maybe<Configuration>;
  configurationId: Scalars["UUID"];
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  isSigned?: Maybe<Scalars["Boolean"]>;
  message: Scalars["String"];
  requestId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `Signee`. */
  signees: SigneesConnection;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type RequestSigneesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<SigneeCondition>;
  filter?: InputMaybe<SigneeFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SigneesOrderBy>>;
};

export type RequestAggregates = {
  __typename?: "RequestAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<RequestDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A condition to be used against `Request` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type RequestCondition = {
  /** Checks for equality with the object’s `configurationId` field. */
  configurationId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `message` field. */
  message?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `requestId` field. */
  requestId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The `configuration` to be created by this mutation. */
export type RequestConfigurationIdFkeyConfigurationCreateInput = {
  commitId?: InputMaybe<Scalars["String"]>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<ConfigurationDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  isDev?: InputMaybe<Scalars["Boolean"]>;
  modifications?: InputMaybe<ModificationConfigurationIdFkeyInverseInput>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  request?: InputMaybe<RequestConfigurationIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** Input for the nested mutation of `configuration` in the `RequestInput` mutation. */
export type RequestConfigurationIdFkeyInput = {
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  connectByCommitId?: InputMaybe<ConfigurationConfigurationCommitIdKeyConnect>;
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  connectByConfigurationId?: InputMaybe<ConfigurationConfigurationPkeyConnect>;
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  connectById?: InputMaybe<ConfigurationNodeIdConnect>;
  /** A `ConfigurationInput` object that will be created and connected to this object. */
  create?: InputMaybe<RequestConfigurationIdFkeyConfigurationCreateInput>;
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  deleteByCommitId?: InputMaybe<ConfigurationConfigurationCommitIdKeyDelete>;
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  deleteByConfigurationId?: InputMaybe<ConfigurationConfigurationPkeyDelete>;
  /** The primary key(s) for `configuration` for the far side of the relationship. */
  deleteById?: InputMaybe<ConfigurationNodeIdDelete>;
  /** The primary key(s) and patch data for `configuration` for the far side of the relationship. */
  updateByCommitId?: InputMaybe<ConfigurationOnRequestForRequestConfigurationIdFkeyUsingConfigurationCommitIdKeyUpdate>;
  /** The primary key(s) and patch data for `configuration` for the far side of the relationship. */
  updateByConfigurationId?: InputMaybe<ConfigurationOnRequestForRequestConfigurationIdFkeyUsingConfigurationPkeyUpdate>;
  /** The primary key(s) and patch data for `configuration` for the far side of the relationship. */
  updateById?: InputMaybe<RequestOnRequestForRequestConfigurationIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `request` in the `ConfigurationInput` mutation. */
export type RequestConfigurationIdFkeyInverseInput = {
  /** The primary key(s) for `request` for the far side of the relationship. */
  connectByConfigurationId?: InputMaybe<RequestRequestConfigurationIdKeyConnect>;
  /** The primary key(s) for `request` for the far side of the relationship. */
  connectById?: InputMaybe<RequestNodeIdConnect>;
  /** The primary key(s) for `request` for the far side of the relationship. */
  connectByRequestId?: InputMaybe<RequestRequestPkeyConnect>;
  /** A `RequestInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<RequestConfigurationIdFkeyRequestCreateInput>>;
  /** The primary key(s) for `request` for the far side of the relationship. */
  deleteByConfigurationId?: InputMaybe<RequestRequestConfigurationIdKeyDelete>;
  /** The primary key(s) for `request` for the far side of the relationship. */
  deleteById?: InputMaybe<RequestNodeIdDelete>;
  /** The primary key(s) for `request` for the far side of the relationship. */
  deleteByRequestId?: InputMaybe<RequestRequestPkeyDelete>;
  /** Flag indicating whether all other `request` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `request` for the far side of the relationship. */
  updateByConfigurationId?: InputMaybe<RequestOnRequestForRequestConfigurationIdFkeyUsingRequestConfigurationIdKeyUpdate>;
  /** The primary key(s) and patch data for `request` for the far side of the relationship. */
  updateById?: InputMaybe<ConfigurationOnRequestForRequestConfigurationIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `request` for the far side of the relationship. */
  updateByRequestId?: InputMaybe<RequestOnRequestForRequestConfigurationIdFkeyUsingRequestPkeyUpdate>;
};

/** The `request` to be created by this mutation. */
export type RequestConfigurationIdFkeyRequestCreateInput = {
  configuration?: InputMaybe<RequestConfigurationIdFkeyInput>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  message: Scalars["String"];
  requestId?: InputMaybe<Scalars["UUID"]>;
  signees?: InputMaybe<SigneeRequestIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type RequestDistinct = {
  __typename?: "RequestDistinct";
  /** Distinct `configurationId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  configurationId: Array<Scalars["UUID"]>;
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `message` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  message: Array<Scalars["String"]>;
  /** Distinct `requestId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  requestId: Array<Scalars["UUID"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type RequestDistinctCountAggregates = {
  __typename?: "RequestDistinctCountAggregates";
  /** Distinct count of configurationId across the matching connection */
  configurationId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of this field across the matching connection. */
  isSigned?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of message across the matching connection */
  message?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of requestId across the matching connection */
  requestId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `Request` object types. All fields are combined with a logical ‘and.’ */
export type RequestFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<RequestFilter>>;
  /** Filter by the object’s `configuration` relation. */
  configuration?: InputMaybe<ConfigurationFilter>;
  /** Filter by the object’s `configurationId` field. */
  configurationId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isSigned` field. */
  isSigned?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `message` field. */
  message?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<RequestFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<RequestFilter>>;
  /** Filter by the object’s `requestId` field. */
  requestId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `signees` relation. */
  signees?: InputMaybe<RequestToManySigneeFilter>;
  /** Some related `signees` exist. */
  signeesExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type RequestGroup = {
  __typename?: "RequestGroup";
  /** `RequestFilter` that was used to implicitly filter `requests`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** A list of _all_ `Request` primary keys that belong to the `RequestGroup`. */
  requestIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `Request` of this `RequestGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `RequestGroup`. Overriding these may result in the wrong set of `Request` returned.
   */
  requests?: Maybe<RequestsConnection>;
  /** The count of _all_ `Request` you could get from the `RequestGroup`. */
  totalCount: Scalars["Int"];
};

export type RequestGroupRequestsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<RequestCondition>;
  filter?: InputMaybe<RequestFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<RequestsOrderBy>>;
};

/** Grouping methods for `Request` for usage during aggregation. */
export enum RequestGroupBy {
  ConfigurationByConfigurationIdCommitId = "CONFIGURATION_BY_CONFIGURATION_ID_COMMIT_ID",
  ConfigurationByConfigurationIdConfigurationId = "CONFIGURATION_BY_CONFIGURATION_ID_CONFIGURATION_ID",
  ConfigurationByConfigurationIdCreatedAt = "CONFIGURATION_BY_CONFIGURATION_ID_CREATED_AT",
  ConfigurationByConfigurationIdCreatedByEmail = "CONFIGURATION_BY_CONFIGURATION_ID_CREATED_BY_EMAIL",
  ConfigurationByConfigurationIdDataRoomId = "CONFIGURATION_BY_CONFIGURATION_ID_DATA_ROOM_ID",
  ConfigurationByConfigurationIdIsDev = "CONFIGURATION_BY_CONFIGURATION_ID_IS_DEV",
  ConfigurationByConfigurationIdOwnerEmail = "CONFIGURATION_BY_CONFIGURATION_ID_OWNER_EMAIL",
  ConfigurationByConfigurationIdUpdatedAt = "CONFIGURATION_BY_CONFIGURATION_ID_UPDATED_AT",
  ConfigurationByConfigurationIdUpdatedByEmail = "CONFIGURATION_BY_CONFIGURATION_ID_UPDATED_BY_EMAIL",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  Message = "MESSAGE",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type RequestHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RequestHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Request` aggregates. */
export type RequestHavingInput = {
  AND?: InputMaybe<Array<RequestHavingInput>>;
  OR?: InputMaybe<Array<RequestHavingInput>>;
  average?: InputMaybe<RequestHavingAverageInput>;
  distinctCount?: InputMaybe<RequestHavingDistinctCountInput>;
  max?: InputMaybe<RequestHavingMaxInput>;
  min?: InputMaybe<RequestHavingMinInput>;
  stddevPopulation?: InputMaybe<RequestHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<RequestHavingStddevSampleInput>;
  sum?: InputMaybe<RequestHavingSumInput>;
  variancePopulation?: InputMaybe<RequestHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<RequestHavingVarianceSampleInput>;
};

export type RequestHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RequestHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RequestHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RequestHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RequestHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RequestHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type RequestHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Request` */
export type RequestInput = {
  configuration?: InputMaybe<RequestConfigurationIdFkeyInput>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  message: Scalars["String"];
  requestId?: InputMaybe<Scalars["UUID"]>;
  signees?: InputMaybe<SigneeRequestIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type RequestNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `request` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type RequestNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `request` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type RequestOnRequestForRequestConfigurationIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `configuration` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `configuration` being updated. */
  patch: ConfigurationPatch;
};

/** The fields on `request` to look up the row to update. */
export type RequestOnRequestForRequestConfigurationIdFkeyUsingRequestConfigurationIdKeyUpdate =
  {
    configurationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `request` being updated. */
    patch: UpdateRequestOnRequestForRequestConfigurationIdFkeyPatch;
  };

/** The fields on `request` to look up the row to update. */
export type RequestOnRequestForRequestConfigurationIdFkeyUsingRequestPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `request` being updated. */
    patch: UpdateRequestOnRequestForRequestConfigurationIdFkeyPatch;
    requestId: Scalars["UUID"];
  };

/** The globally unique `ID` look up for the row to update. */
export type RequestOnSigneeForSigneeRequestIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `signee` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `signee` being updated. */
  patch: SigneePatch;
};

/** The fields on `request` to look up the row to update. */
export type RequestOnSigneeForSigneeRequestIdFkeyUsingRequestConfigurationIdKeyUpdate =
  {
    configurationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `request` being updated. */
    patch: UpdateRequestOnSigneeForSigneeRequestIdFkeyPatch;
  };

/** The fields on `request` to look up the row to update. */
export type RequestOnSigneeForSigneeRequestIdFkeyUsingRequestPkeyUpdate = {
  /** An object where the defined keys will be set on the `request` being updated. */
  patch: UpdateRequestOnSigneeForSigneeRequestIdFkeyPatch;
  requestId: Scalars["UUID"];
};

/** Represents an update to a `Request`. Fields that are set will be updated. */
export type RequestPatch = {
  configuration?: InputMaybe<RequestConfigurationIdFkeyInput>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<Scalars["String"]>;
  requestId?: InputMaybe<Scalars["UUID"]>;
  signees?: InputMaybe<SigneeRequestIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The fields on `request` to look up the row to connect. */
export type RequestRequestConfigurationIdKeyConnect = {
  configurationId: Scalars["UUID"];
};

/** The fields on `request` to look up the row to delete. */
export type RequestRequestConfigurationIdKeyDelete = {
  configurationId: Scalars["UUID"];
};

/** The fields on `request` to look up the row to connect. */
export type RequestRequestPkeyConnect = {
  requestId: Scalars["UUID"];
};

/** The fields on `request` to look up the row to delete. */
export type RequestRequestPkeyDelete = {
  requestId: Scalars["UUID"];
};

/** A filter to be used against many `Signee` object types. All fields are combined with a logical ‘and.’ */
export type RequestToManySigneeFilter = {
  /** Aggregates across related `Signee` match the filter criteria. */
  aggregates?: InputMaybe<SigneeAggregatesFilter>;
  /** Every related `Signee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<SigneeFilter>;
  /** No related `Signee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<SigneeFilter>;
  /** Some related `Signee` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<SigneeFilter>;
};

/** A connection to a list of `Request` values. */
export type RequestsConnection = {
  __typename?: "RequestsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<RequestAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<RequestDistinct>;
  /** A list of edges which contains the `Request` and cursor to aid in pagination. */
  edges: Array<RequestsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<RequestAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<RequestGroup>>;
  /** A list of `Request` objects. */
  nodes: Array<Maybe<Request>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of _all_ `Request` primary keys. */
  requestIds?: Maybe<Array<Scalars["UUID"]>>;
  /** The count of *all* `Request` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `Request` values. */
export type RequestsConnectionGroupedAggregatesArgs = {
  groupBy: Array<RequestGroupBy>;
  having?: InputMaybe<RequestHavingInput>;
};

/** A connection to a list of `Request` values. */
export type RequestsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<RequestGroupBy>;
  having?: InputMaybe<RequestHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `Request` edge in the connection. */
export type RequestsEdge = {
  __typename?: "RequestsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `Request` at the end of the edge. */
  node?: Maybe<Request>;
};

/** Methods to use when ordering `Request`. */
export enum RequestsOrderBy {
  ConfigurationByConfigurationIdCommitIdAsc = "CONFIGURATION_BY_CONFIGURATION_ID_COMMIT_ID_ASC",
  ConfigurationByConfigurationIdCommitIdDesc = "CONFIGURATION_BY_CONFIGURATION_ID_COMMIT_ID_DESC",
  ConfigurationByConfigurationIdConfigurationIdAsc = "CONFIGURATION_BY_CONFIGURATION_ID_CONFIGURATION_ID_ASC",
  ConfigurationByConfigurationIdConfigurationIdDesc = "CONFIGURATION_BY_CONFIGURATION_ID_CONFIGURATION_ID_DESC",
  ConfigurationByConfigurationIdCreatedAtAsc = "CONFIGURATION_BY_CONFIGURATION_ID_CREATED_AT_ASC",
  ConfigurationByConfigurationIdCreatedAtDesc = "CONFIGURATION_BY_CONFIGURATION_ID_CREATED_AT_DESC",
  ConfigurationByConfigurationIdCreatedByEmailAsc = "CONFIGURATION_BY_CONFIGURATION_ID_CREATED_BY_EMAIL_ASC",
  ConfigurationByConfigurationIdCreatedByEmailDesc = "CONFIGURATION_BY_CONFIGURATION_ID_CREATED_BY_EMAIL_DESC",
  ConfigurationByConfigurationIdDataRoomIdAsc = "CONFIGURATION_BY_CONFIGURATION_ID_DATA_ROOM_ID_ASC",
  ConfigurationByConfigurationIdDataRoomIdDesc = "CONFIGURATION_BY_CONFIGURATION_ID_DATA_ROOM_ID_DESC",
  ConfigurationByConfigurationIdIsDevAsc = "CONFIGURATION_BY_CONFIGURATION_ID_IS_DEV_ASC",
  ConfigurationByConfigurationIdIsDevDesc = "CONFIGURATION_BY_CONFIGURATION_ID_IS_DEV_DESC",
  ConfigurationByConfigurationIdOwnerEmailAsc = "CONFIGURATION_BY_CONFIGURATION_ID_OWNER_EMAIL_ASC",
  ConfigurationByConfigurationIdOwnerEmailDesc = "CONFIGURATION_BY_CONFIGURATION_ID_OWNER_EMAIL_DESC",
  ConfigurationByConfigurationIdUpdatedAtAsc = "CONFIGURATION_BY_CONFIGURATION_ID_UPDATED_AT_ASC",
  ConfigurationByConfigurationIdUpdatedAtDesc = "CONFIGURATION_BY_CONFIGURATION_ID_UPDATED_AT_DESC",
  ConfigurationByConfigurationIdUpdatedByEmailAsc = "CONFIGURATION_BY_CONFIGURATION_ID_UPDATED_BY_EMAIL_ASC",
  ConfigurationByConfigurationIdUpdatedByEmailDesc = "CONFIGURATION_BY_CONFIGURATION_ID_UPDATED_BY_EMAIL_DESC",
  ConfigurationIdAsc = "CONFIGURATION_ID_ASC",
  ConfigurationIdDesc = "CONFIGURATION_ID_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  MessageAsc = "MESSAGE_ASC",
  MessageDesc = "MESSAGE_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RequestIdAsc = "REQUEST_ID_ASC",
  RequestIdDesc = "REQUEST_ID_DESC",
  SigneesAverageCreatedAtAsc = "SIGNEES_AVERAGE_CREATED_AT_ASC",
  SigneesAverageCreatedAtDesc = "SIGNEES_AVERAGE_CREATED_AT_DESC",
  SigneesAverageCreatedByEmailAsc = "SIGNEES_AVERAGE_CREATED_BY_EMAIL_ASC",
  SigneesAverageCreatedByEmailDesc = "SIGNEES_AVERAGE_CREATED_BY_EMAIL_DESC",
  SigneesAverageDatasetsAsc = "SIGNEES_AVERAGE_DATASETS_ASC",
  SigneesAverageDatasetsDesc = "SIGNEES_AVERAGE_DATASETS_DESC",
  SigneesAverageRequestIdAsc = "SIGNEES_AVERAGE_REQUEST_ID_ASC",
  SigneesAverageRequestIdDesc = "SIGNEES_AVERAGE_REQUEST_ID_DESC",
  SigneesAverageSigneeEmailAsc = "SIGNEES_AVERAGE_SIGNEE_EMAIL_ASC",
  SigneesAverageSigneeEmailDesc = "SIGNEES_AVERAGE_SIGNEE_EMAIL_DESC",
  SigneesAverageSigneeIdAsc = "SIGNEES_AVERAGE_SIGNEE_ID_ASC",
  SigneesAverageSigneeIdDesc = "SIGNEES_AVERAGE_SIGNEE_ID_DESC",
  SigneesAverageUpdatedAtAsc = "SIGNEES_AVERAGE_UPDATED_AT_ASC",
  SigneesAverageUpdatedAtDesc = "SIGNEES_AVERAGE_UPDATED_AT_DESC",
  SigneesAverageUpdatedByEmailAsc = "SIGNEES_AVERAGE_UPDATED_BY_EMAIL_ASC",
  SigneesAverageUpdatedByEmailDesc = "SIGNEES_AVERAGE_UPDATED_BY_EMAIL_DESC",
  SigneesByRequestIdCountAsc = "SIGNEES_BY_REQUEST_ID_COUNT_ASC",
  SigneesByRequestIdCountDesc = "SIGNEES_BY_REQUEST_ID_COUNT_DESC",
  SigneesByRequestIdMaxCreatedAtAsc = "SIGNEES_BY_REQUEST_ID_MAX_CREATED_AT_ASC",
  SigneesByRequestIdMaxCreatedAtDesc = "SIGNEES_BY_REQUEST_ID_MAX_CREATED_AT_DESC",
  SigneesByRequestIdMaxCreatedByEmailAsc = "SIGNEES_BY_REQUEST_ID_MAX_CREATED_BY_EMAIL_ASC",
  SigneesByRequestIdMaxCreatedByEmailDesc = "SIGNEES_BY_REQUEST_ID_MAX_CREATED_BY_EMAIL_DESC",
  SigneesByRequestIdMaxDatasetsAsc = "SIGNEES_BY_REQUEST_ID_MAX_DATASETS_ASC",
  SigneesByRequestIdMaxDatasetsDesc = "SIGNEES_BY_REQUEST_ID_MAX_DATASETS_DESC",
  SigneesByRequestIdMaxRequestIdAsc = "SIGNEES_BY_REQUEST_ID_MAX_REQUEST_ID_ASC",
  SigneesByRequestIdMaxRequestIdDesc = "SIGNEES_BY_REQUEST_ID_MAX_REQUEST_ID_DESC",
  SigneesByRequestIdMaxSigneeEmailAsc = "SIGNEES_BY_REQUEST_ID_MAX_SIGNEE_EMAIL_ASC",
  SigneesByRequestIdMaxSigneeEmailDesc = "SIGNEES_BY_REQUEST_ID_MAX_SIGNEE_EMAIL_DESC",
  SigneesByRequestIdMaxSigneeIdAsc = "SIGNEES_BY_REQUEST_ID_MAX_SIGNEE_ID_ASC",
  SigneesByRequestIdMaxSigneeIdDesc = "SIGNEES_BY_REQUEST_ID_MAX_SIGNEE_ID_DESC",
  SigneesByRequestIdMaxUpdatedAtAsc = "SIGNEES_BY_REQUEST_ID_MAX_UPDATED_AT_ASC",
  SigneesByRequestIdMaxUpdatedAtDesc = "SIGNEES_BY_REQUEST_ID_MAX_UPDATED_AT_DESC",
  SigneesByRequestIdMaxUpdatedByEmailAsc = "SIGNEES_BY_REQUEST_ID_MAX_UPDATED_BY_EMAIL_ASC",
  SigneesByRequestIdMaxUpdatedByEmailDesc = "SIGNEES_BY_REQUEST_ID_MAX_UPDATED_BY_EMAIL_DESC",
  SigneesByRequestIdMinCreatedAtAsc = "SIGNEES_BY_REQUEST_ID_MIN_CREATED_AT_ASC",
  SigneesByRequestIdMinCreatedAtDesc = "SIGNEES_BY_REQUEST_ID_MIN_CREATED_AT_DESC",
  SigneesByRequestIdMinCreatedByEmailAsc = "SIGNEES_BY_REQUEST_ID_MIN_CREATED_BY_EMAIL_ASC",
  SigneesByRequestIdMinCreatedByEmailDesc = "SIGNEES_BY_REQUEST_ID_MIN_CREATED_BY_EMAIL_DESC",
  SigneesByRequestIdMinDatasetsAsc = "SIGNEES_BY_REQUEST_ID_MIN_DATASETS_ASC",
  SigneesByRequestIdMinDatasetsDesc = "SIGNEES_BY_REQUEST_ID_MIN_DATASETS_DESC",
  SigneesByRequestIdMinRequestIdAsc = "SIGNEES_BY_REQUEST_ID_MIN_REQUEST_ID_ASC",
  SigneesByRequestIdMinRequestIdDesc = "SIGNEES_BY_REQUEST_ID_MIN_REQUEST_ID_DESC",
  SigneesByRequestIdMinSigneeEmailAsc = "SIGNEES_BY_REQUEST_ID_MIN_SIGNEE_EMAIL_ASC",
  SigneesByRequestIdMinSigneeEmailDesc = "SIGNEES_BY_REQUEST_ID_MIN_SIGNEE_EMAIL_DESC",
  SigneesByRequestIdMinSigneeIdAsc = "SIGNEES_BY_REQUEST_ID_MIN_SIGNEE_ID_ASC",
  SigneesByRequestIdMinSigneeIdDesc = "SIGNEES_BY_REQUEST_ID_MIN_SIGNEE_ID_DESC",
  SigneesByRequestIdMinUpdatedAtAsc = "SIGNEES_BY_REQUEST_ID_MIN_UPDATED_AT_ASC",
  SigneesByRequestIdMinUpdatedAtDesc = "SIGNEES_BY_REQUEST_ID_MIN_UPDATED_AT_DESC",
  SigneesByRequestIdMinUpdatedByEmailAsc = "SIGNEES_BY_REQUEST_ID_MIN_UPDATED_BY_EMAIL_ASC",
  SigneesByRequestIdMinUpdatedByEmailDesc = "SIGNEES_BY_REQUEST_ID_MIN_UPDATED_BY_EMAIL_DESC",
  SigneesCountAsc = "SIGNEES_COUNT_ASC",
  SigneesCountDesc = "SIGNEES_COUNT_DESC",
  SigneesDistinctCountCreatedAtAsc = "SIGNEES_DISTINCT_COUNT_CREATED_AT_ASC",
  SigneesDistinctCountCreatedAtDesc = "SIGNEES_DISTINCT_COUNT_CREATED_AT_DESC",
  SigneesDistinctCountCreatedByEmailAsc = "SIGNEES_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  SigneesDistinctCountCreatedByEmailDesc = "SIGNEES_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  SigneesDistinctCountDatasetsAsc = "SIGNEES_DISTINCT_COUNT_DATASETS_ASC",
  SigneesDistinctCountDatasetsDesc = "SIGNEES_DISTINCT_COUNT_DATASETS_DESC",
  SigneesDistinctCountRequestIdAsc = "SIGNEES_DISTINCT_COUNT_REQUEST_ID_ASC",
  SigneesDistinctCountRequestIdDesc = "SIGNEES_DISTINCT_COUNT_REQUEST_ID_DESC",
  SigneesDistinctCountSigneeEmailAsc = "SIGNEES_DISTINCT_COUNT_SIGNEE_EMAIL_ASC",
  SigneesDistinctCountSigneeEmailDesc = "SIGNEES_DISTINCT_COUNT_SIGNEE_EMAIL_DESC",
  SigneesDistinctCountSigneeIdAsc = "SIGNEES_DISTINCT_COUNT_SIGNEE_ID_ASC",
  SigneesDistinctCountSigneeIdDesc = "SIGNEES_DISTINCT_COUNT_SIGNEE_ID_DESC",
  SigneesDistinctCountUpdatedAtAsc = "SIGNEES_DISTINCT_COUNT_UPDATED_AT_ASC",
  SigneesDistinctCountUpdatedAtDesc = "SIGNEES_DISTINCT_COUNT_UPDATED_AT_DESC",
  SigneesDistinctCountUpdatedByEmailAsc = "SIGNEES_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  SigneesDistinctCountUpdatedByEmailDesc = "SIGNEES_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  SigneesMaxCreatedAtAsc = "SIGNEES_MAX_CREATED_AT_ASC",
  SigneesMaxCreatedAtDesc = "SIGNEES_MAX_CREATED_AT_DESC",
  SigneesMaxCreatedByEmailAsc = "SIGNEES_MAX_CREATED_BY_EMAIL_ASC",
  SigneesMaxCreatedByEmailDesc = "SIGNEES_MAX_CREATED_BY_EMAIL_DESC",
  SigneesMaxDatasetsAsc = "SIGNEES_MAX_DATASETS_ASC",
  SigneesMaxDatasetsDesc = "SIGNEES_MAX_DATASETS_DESC",
  SigneesMaxRequestIdAsc = "SIGNEES_MAX_REQUEST_ID_ASC",
  SigneesMaxRequestIdDesc = "SIGNEES_MAX_REQUEST_ID_DESC",
  SigneesMaxSigneeEmailAsc = "SIGNEES_MAX_SIGNEE_EMAIL_ASC",
  SigneesMaxSigneeEmailDesc = "SIGNEES_MAX_SIGNEE_EMAIL_DESC",
  SigneesMaxSigneeIdAsc = "SIGNEES_MAX_SIGNEE_ID_ASC",
  SigneesMaxSigneeIdDesc = "SIGNEES_MAX_SIGNEE_ID_DESC",
  SigneesMaxUpdatedAtAsc = "SIGNEES_MAX_UPDATED_AT_ASC",
  SigneesMaxUpdatedAtDesc = "SIGNEES_MAX_UPDATED_AT_DESC",
  SigneesMaxUpdatedByEmailAsc = "SIGNEES_MAX_UPDATED_BY_EMAIL_ASC",
  SigneesMaxUpdatedByEmailDesc = "SIGNEES_MAX_UPDATED_BY_EMAIL_DESC",
  SigneesMinCreatedAtAsc = "SIGNEES_MIN_CREATED_AT_ASC",
  SigneesMinCreatedAtDesc = "SIGNEES_MIN_CREATED_AT_DESC",
  SigneesMinCreatedByEmailAsc = "SIGNEES_MIN_CREATED_BY_EMAIL_ASC",
  SigneesMinCreatedByEmailDesc = "SIGNEES_MIN_CREATED_BY_EMAIL_DESC",
  SigneesMinDatasetsAsc = "SIGNEES_MIN_DATASETS_ASC",
  SigneesMinDatasetsDesc = "SIGNEES_MIN_DATASETS_DESC",
  SigneesMinRequestIdAsc = "SIGNEES_MIN_REQUEST_ID_ASC",
  SigneesMinRequestIdDesc = "SIGNEES_MIN_REQUEST_ID_DESC",
  SigneesMinSigneeEmailAsc = "SIGNEES_MIN_SIGNEE_EMAIL_ASC",
  SigneesMinSigneeEmailDesc = "SIGNEES_MIN_SIGNEE_EMAIL_DESC",
  SigneesMinSigneeIdAsc = "SIGNEES_MIN_SIGNEE_ID_ASC",
  SigneesMinSigneeIdDesc = "SIGNEES_MIN_SIGNEE_ID_DESC",
  SigneesMinUpdatedAtAsc = "SIGNEES_MIN_UPDATED_AT_ASC",
  SigneesMinUpdatedAtDesc = "SIGNEES_MIN_UPDATED_AT_DESC",
  SigneesMinUpdatedByEmailAsc = "SIGNEES_MIN_UPDATED_BY_EMAIL_ASC",
  SigneesMinUpdatedByEmailDesc = "SIGNEES_MIN_UPDATED_BY_EMAIL_DESC",
  SigneesStddevPopulationCreatedAtAsc = "SIGNEES_STDDEV_POPULATION_CREATED_AT_ASC",
  SigneesStddevPopulationCreatedAtDesc = "SIGNEES_STDDEV_POPULATION_CREATED_AT_DESC",
  SigneesStddevPopulationCreatedByEmailAsc = "SIGNEES_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  SigneesStddevPopulationCreatedByEmailDesc = "SIGNEES_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  SigneesStddevPopulationDatasetsAsc = "SIGNEES_STDDEV_POPULATION_DATASETS_ASC",
  SigneesStddevPopulationDatasetsDesc = "SIGNEES_STDDEV_POPULATION_DATASETS_DESC",
  SigneesStddevPopulationRequestIdAsc = "SIGNEES_STDDEV_POPULATION_REQUEST_ID_ASC",
  SigneesStddevPopulationRequestIdDesc = "SIGNEES_STDDEV_POPULATION_REQUEST_ID_DESC",
  SigneesStddevPopulationSigneeEmailAsc = "SIGNEES_STDDEV_POPULATION_SIGNEE_EMAIL_ASC",
  SigneesStddevPopulationSigneeEmailDesc = "SIGNEES_STDDEV_POPULATION_SIGNEE_EMAIL_DESC",
  SigneesStddevPopulationSigneeIdAsc = "SIGNEES_STDDEV_POPULATION_SIGNEE_ID_ASC",
  SigneesStddevPopulationSigneeIdDesc = "SIGNEES_STDDEV_POPULATION_SIGNEE_ID_DESC",
  SigneesStddevPopulationUpdatedAtAsc = "SIGNEES_STDDEV_POPULATION_UPDATED_AT_ASC",
  SigneesStddevPopulationUpdatedAtDesc = "SIGNEES_STDDEV_POPULATION_UPDATED_AT_DESC",
  SigneesStddevPopulationUpdatedByEmailAsc = "SIGNEES_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  SigneesStddevPopulationUpdatedByEmailDesc = "SIGNEES_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  SigneesStddevSampleCreatedAtAsc = "SIGNEES_STDDEV_SAMPLE_CREATED_AT_ASC",
  SigneesStddevSampleCreatedAtDesc = "SIGNEES_STDDEV_SAMPLE_CREATED_AT_DESC",
  SigneesStddevSampleCreatedByEmailAsc = "SIGNEES_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  SigneesStddevSampleCreatedByEmailDesc = "SIGNEES_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  SigneesStddevSampleDatasetsAsc = "SIGNEES_STDDEV_SAMPLE_DATASETS_ASC",
  SigneesStddevSampleDatasetsDesc = "SIGNEES_STDDEV_SAMPLE_DATASETS_DESC",
  SigneesStddevSampleRequestIdAsc = "SIGNEES_STDDEV_SAMPLE_REQUEST_ID_ASC",
  SigneesStddevSampleRequestIdDesc = "SIGNEES_STDDEV_SAMPLE_REQUEST_ID_DESC",
  SigneesStddevSampleSigneeEmailAsc = "SIGNEES_STDDEV_SAMPLE_SIGNEE_EMAIL_ASC",
  SigneesStddevSampleSigneeEmailDesc = "SIGNEES_STDDEV_SAMPLE_SIGNEE_EMAIL_DESC",
  SigneesStddevSampleSigneeIdAsc = "SIGNEES_STDDEV_SAMPLE_SIGNEE_ID_ASC",
  SigneesStddevSampleSigneeIdDesc = "SIGNEES_STDDEV_SAMPLE_SIGNEE_ID_DESC",
  SigneesStddevSampleUpdatedAtAsc = "SIGNEES_STDDEV_SAMPLE_UPDATED_AT_ASC",
  SigneesStddevSampleUpdatedAtDesc = "SIGNEES_STDDEV_SAMPLE_UPDATED_AT_DESC",
  SigneesStddevSampleUpdatedByEmailAsc = "SIGNEES_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  SigneesStddevSampleUpdatedByEmailDesc = "SIGNEES_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  SigneesSumCreatedAtAsc = "SIGNEES_SUM_CREATED_AT_ASC",
  SigneesSumCreatedAtDesc = "SIGNEES_SUM_CREATED_AT_DESC",
  SigneesSumCreatedByEmailAsc = "SIGNEES_SUM_CREATED_BY_EMAIL_ASC",
  SigneesSumCreatedByEmailDesc = "SIGNEES_SUM_CREATED_BY_EMAIL_DESC",
  SigneesSumDatasetsAsc = "SIGNEES_SUM_DATASETS_ASC",
  SigneesSumDatasetsDesc = "SIGNEES_SUM_DATASETS_DESC",
  SigneesSumRequestIdAsc = "SIGNEES_SUM_REQUEST_ID_ASC",
  SigneesSumRequestIdDesc = "SIGNEES_SUM_REQUEST_ID_DESC",
  SigneesSumSigneeEmailAsc = "SIGNEES_SUM_SIGNEE_EMAIL_ASC",
  SigneesSumSigneeEmailDesc = "SIGNEES_SUM_SIGNEE_EMAIL_DESC",
  SigneesSumSigneeIdAsc = "SIGNEES_SUM_SIGNEE_ID_ASC",
  SigneesSumSigneeIdDesc = "SIGNEES_SUM_SIGNEE_ID_DESC",
  SigneesSumUpdatedAtAsc = "SIGNEES_SUM_UPDATED_AT_ASC",
  SigneesSumUpdatedAtDesc = "SIGNEES_SUM_UPDATED_AT_DESC",
  SigneesSumUpdatedByEmailAsc = "SIGNEES_SUM_UPDATED_BY_EMAIL_ASC",
  SigneesSumUpdatedByEmailDesc = "SIGNEES_SUM_UPDATED_BY_EMAIL_DESC",
  SigneesVariancePopulationCreatedAtAsc = "SIGNEES_VARIANCE_POPULATION_CREATED_AT_ASC",
  SigneesVariancePopulationCreatedAtDesc = "SIGNEES_VARIANCE_POPULATION_CREATED_AT_DESC",
  SigneesVariancePopulationCreatedByEmailAsc = "SIGNEES_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  SigneesVariancePopulationCreatedByEmailDesc = "SIGNEES_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  SigneesVariancePopulationDatasetsAsc = "SIGNEES_VARIANCE_POPULATION_DATASETS_ASC",
  SigneesVariancePopulationDatasetsDesc = "SIGNEES_VARIANCE_POPULATION_DATASETS_DESC",
  SigneesVariancePopulationRequestIdAsc = "SIGNEES_VARIANCE_POPULATION_REQUEST_ID_ASC",
  SigneesVariancePopulationRequestIdDesc = "SIGNEES_VARIANCE_POPULATION_REQUEST_ID_DESC",
  SigneesVariancePopulationSigneeEmailAsc = "SIGNEES_VARIANCE_POPULATION_SIGNEE_EMAIL_ASC",
  SigneesVariancePopulationSigneeEmailDesc = "SIGNEES_VARIANCE_POPULATION_SIGNEE_EMAIL_DESC",
  SigneesVariancePopulationSigneeIdAsc = "SIGNEES_VARIANCE_POPULATION_SIGNEE_ID_ASC",
  SigneesVariancePopulationSigneeIdDesc = "SIGNEES_VARIANCE_POPULATION_SIGNEE_ID_DESC",
  SigneesVariancePopulationUpdatedAtAsc = "SIGNEES_VARIANCE_POPULATION_UPDATED_AT_ASC",
  SigneesVariancePopulationUpdatedAtDesc = "SIGNEES_VARIANCE_POPULATION_UPDATED_AT_DESC",
  SigneesVariancePopulationUpdatedByEmailAsc = "SIGNEES_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  SigneesVariancePopulationUpdatedByEmailDesc = "SIGNEES_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  SigneesVarianceSampleCreatedAtAsc = "SIGNEES_VARIANCE_SAMPLE_CREATED_AT_ASC",
  SigneesVarianceSampleCreatedAtDesc = "SIGNEES_VARIANCE_SAMPLE_CREATED_AT_DESC",
  SigneesVarianceSampleCreatedByEmailAsc = "SIGNEES_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  SigneesVarianceSampleCreatedByEmailDesc = "SIGNEES_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  SigneesVarianceSampleDatasetsAsc = "SIGNEES_VARIANCE_SAMPLE_DATASETS_ASC",
  SigneesVarianceSampleDatasetsDesc = "SIGNEES_VARIANCE_SAMPLE_DATASETS_DESC",
  SigneesVarianceSampleRequestIdAsc = "SIGNEES_VARIANCE_SAMPLE_REQUEST_ID_ASC",
  SigneesVarianceSampleRequestIdDesc = "SIGNEES_VARIANCE_SAMPLE_REQUEST_ID_DESC",
  SigneesVarianceSampleSigneeEmailAsc = "SIGNEES_VARIANCE_SAMPLE_SIGNEE_EMAIL_ASC",
  SigneesVarianceSampleSigneeEmailDesc = "SIGNEES_VARIANCE_SAMPLE_SIGNEE_EMAIL_DESC",
  SigneesVarianceSampleSigneeIdAsc = "SIGNEES_VARIANCE_SAMPLE_SIGNEE_ID_ASC",
  SigneesVarianceSampleSigneeIdDesc = "SIGNEES_VARIANCE_SAMPLE_SIGNEE_ID_DESC",
  SigneesVarianceSampleUpdatedAtAsc = "SIGNEES_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  SigneesVarianceSampleUpdatedAtDesc = "SIGNEES_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  SigneesVarianceSampleUpdatedByEmailAsc = "SIGNEES_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  SigneesVarianceSampleUpdatedByEmailDesc = "SIGNEES_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

export type Resource = Node & {
  __typename?: "Resource";
  createdAt: Scalars["Datetime"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  ownerEmail: Scalars["String"];
  resourceId: Scalars["String"];
  updatedAt: Scalars["Datetime"];
};

export type ResourceAggregates = {
  __typename?: "ResourceAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<ResourceDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/**
 * A condition to be used against `Resource` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type ResourceCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `ownerEmail` field. */
  ownerEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `resourceId` field. */
  resourceId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
};

export type ResourceDistinct = {
  __typename?: "ResourceDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `ownerEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  ownerEmail: Array<Scalars["String"]>;
  /** Distinct `resourceId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  resourceId: Array<Scalars["String"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
};

export type ResourceDistinctCountAggregates = {
  __typename?: "ResourceDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of ownerEmail across the matching connection */
  ownerEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of resourceId across the matching connection */
  resourceId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `Resource` object types. All fields are combined with a logical ‘and.’ */
export type ResourceFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<ResourceFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Negates the expression. */
  not?: InputMaybe<ResourceFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<ResourceFilter>>;
  /** Filter by the object’s `ownerEmail` field. */
  ownerEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `resourceId` field. */
  resourceId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
};

export type ResourceGroup = {
  __typename?: "ResourceGroup";
  /** `ResourceFilter` that was used to implicitly filter `resources`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /**
   * Reads and enables pagination through a set of `Resource` of this `ResourceGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `ResourceGroup`. Overriding these may result in the wrong set of `Resource` returned.
   */
  resources?: Maybe<ResourcesConnection>;
  /** The count of _all_ `Resource` you could get from the `ResourceGroup`. */
  totalCount: Scalars["Int"];
};

export type ResourceGroupResourcesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ResourceCondition>;
  filter?: InputMaybe<ResourceFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ResourcesOrderBy>>;
};

/** Grouping methods for `Resource` for usage during aggregation. */
export enum ResourceGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  OwnerEmail = "OWNER_EMAIL",
  ResourceId = "RESOURCE_ID",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
}

export type ResourceHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ResourceHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Resource` aggregates. */
export type ResourceHavingInput = {
  AND?: InputMaybe<Array<ResourceHavingInput>>;
  OR?: InputMaybe<Array<ResourceHavingInput>>;
  average?: InputMaybe<ResourceHavingAverageInput>;
  distinctCount?: InputMaybe<ResourceHavingDistinctCountInput>;
  max?: InputMaybe<ResourceHavingMaxInput>;
  min?: InputMaybe<ResourceHavingMinInput>;
  stddevPopulation?: InputMaybe<ResourceHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<ResourceHavingStddevSampleInput>;
  sum?: InputMaybe<ResourceHavingSumInput>;
  variancePopulation?: InputMaybe<ResourceHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<ResourceHavingVarianceSampleInput>;
};

export type ResourceHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ResourceHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ResourceHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ResourceHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ResourceHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ResourceHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type ResourceHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Resource` */
export type ResourceInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  ownerEmail: Scalars["String"];
  resourceId: Scalars["String"];
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
};

/** Represents an update to a `Resource`. Fields that are set will be updated. */
export type ResourcePatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  resourceId?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
};

/** A connection to a list of `Resource` values. */
export type ResourcesConnection = {
  __typename?: "ResourcesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<ResourceAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<ResourceDistinct>;
  /** A list of edges which contains the `Resource` and cursor to aid in pagination. */
  edges: Array<ResourcesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<ResourceAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<ResourceGroup>>;
  /** A list of `Resource` objects. */
  nodes: Array<Maybe<Resource>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `Resource` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `Resource` values. */
export type ResourcesConnectionGroupedAggregatesArgs = {
  groupBy: Array<ResourceGroupBy>;
  having?: InputMaybe<ResourceHavingInput>;
};

/** A connection to a list of `Resource` values. */
export type ResourcesConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<ResourceGroupBy>;
  having?: InputMaybe<ResourceHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `Resource` edge in the connection. */
export type ResourcesEdge = {
  __typename?: "ResourcesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `Resource` at the end of the edge. */
  node?: Maybe<Resource>;
};

/** Methods to use when ordering `Resource`. */
export enum ResourcesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  Natural = "NATURAL",
  OwnerEmailAsc = "OWNER_EMAIL_ASC",
  OwnerEmailDesc = "OWNER_EMAIL_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  ResourceIdAsc = "RESOURCE_ID_ASC",
  ResourceIdDesc = "RESOURCE_ID_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
}

export type ResultAsFile = {
  __typename?: "ResultAsFile";
  fileBits?: Maybe<Scalars["String"]>;
  fileName?: Maybe<Scalars["String"]>;
  fileType?: Maybe<Scalars["String"]>;
};

export type RunComputeNodeByIdInput = {
  id: Scalars["ID"];
};

export type RunComputeNodeByIdPayload = {
  __typename?: "RunComputeNodeByIdPayload";
  computeNode?: Maybe<ComputeNode>;
};

export type Signature = Node & {
  __typename?: "Signature";
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  isSaving?: Maybe<Scalars["Boolean"]>;
  signatureHash: Scalars["String"];
  /** Reads a single `Signee` that is related to this `Signature`. */
  signee?: Maybe<Signee>;
  signeeId: Scalars["UUID"];
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type SignatureAggregates = {
  __typename?: "SignatureAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SignatureDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/**
 * A condition to be used against `Signature` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type SignatureCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `signatureHash` field. */
  signatureHash?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `signeeId` field. */
  signeeId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type SignatureDistinct = {
  __typename?: "SignatureDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `signatureHash` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  signatureHash: Array<Scalars["String"]>;
  /** Distinct `signeeId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  signeeId: Array<Scalars["UUID"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type SignatureDistinctCountAggregates = {
  __typename?: "SignatureDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of signatureHash across the matching connection */
  signatureHash?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of signeeId across the matching connection */
  signeeId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `Signature` object types. All fields are combined with a logical ‘and.’ */
export type SignatureFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SignatureFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SignatureFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SignatureFilter>>;
  /** Filter by the object’s `signee` relation. */
  signee?: InputMaybe<SigneeFilter>;
  /** Filter by the object’s `signeeId` field. */
  signeeId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type SignatureGroup = {
  __typename?: "SignatureGroup";
  /** `SignatureFilter` that was used to implicitly filter `signatures`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /**
   * Reads and enables pagination through a set of `Signature` of this `SignatureGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `SignatureGroup`. Overriding these may result in the wrong set of `Signature` returned.
   */
  signatures?: Maybe<SignaturesConnection>;
  /** A list of _all_ `Signature` primary keys that belong to the `SignatureGroup`. */
  signeeIds?: Maybe<Array<Scalars["UUID"]>>;
  /** The count of _all_ `Signature` you could get from the `SignatureGroup`. */
  totalCount: Scalars["Int"];
};

export type SignatureGroupSignaturesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<SignatureCondition>;
  filter?: InputMaybe<SignatureFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SignaturesOrderBy>>;
};

/** Grouping methods for `Signature` for usage during aggregation. */
export enum SignatureGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  SigneeBySigneeIdCreatedAt = "SIGNEE_BY_SIGNEE_ID_CREATED_AT",
  SigneeBySigneeIdCreatedByEmail = "SIGNEE_BY_SIGNEE_ID_CREATED_BY_EMAIL",
  SigneeBySigneeIdDatasets = "SIGNEE_BY_SIGNEE_ID_DATASETS",
  SigneeBySigneeIdRequestId = "SIGNEE_BY_SIGNEE_ID_REQUEST_ID",
  SigneeBySigneeIdSigneeEmail = "SIGNEE_BY_SIGNEE_ID_SIGNEE_EMAIL",
  SigneeBySigneeIdSigneeId = "SIGNEE_BY_SIGNEE_ID_SIGNEE_ID",
  SigneeBySigneeIdUpdatedAt = "SIGNEE_BY_SIGNEE_ID_UPDATED_AT",
  SigneeBySigneeIdUpdatedByEmail = "SIGNEE_BY_SIGNEE_ID_UPDATED_BY_EMAIL",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type SignatureHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SignatureHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Signature` aggregates. */
export type SignatureHavingInput = {
  AND?: InputMaybe<Array<SignatureHavingInput>>;
  OR?: InputMaybe<Array<SignatureHavingInput>>;
  average?: InputMaybe<SignatureHavingAverageInput>;
  distinctCount?: InputMaybe<SignatureHavingDistinctCountInput>;
  max?: InputMaybe<SignatureHavingMaxInput>;
  min?: InputMaybe<SignatureHavingMinInput>;
  stddevPopulation?: InputMaybe<SignatureHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SignatureHavingStddevSampleInput>;
  sum?: InputMaybe<SignatureHavingSumInput>;
  variancePopulation?: InputMaybe<SignatureHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SignatureHavingVarianceSampleInput>;
};

export type SignatureHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SignatureHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SignatureHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SignatureHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SignatureHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SignatureHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SignatureHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Signature` */
export type SignatureInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  signatureHash: Scalars["String"];
  signee?: InputMaybe<SignatureSigneeIdFkeyInput>;
  signeeId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type SignatureNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `signature` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type SignatureNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `signature` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type SignatureOnSignatureForSignatureSigneeIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `signee` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `signee` being updated. */
  patch: SigneePatch;
};

/** The fields on `signature` to look up the row to update. */
export type SignatureOnSignatureForSignatureSigneeIdFkeyUsingSignaturePkeyUpdate =
  {
    /** An object where the defined keys will be set on the `signature` being updated. */
    patch: UpdateSignatureOnSignatureForSignatureSigneeIdFkeyPatch;
    signeeId: Scalars["UUID"];
  };

/** The fields on `signature` to look up the row to update. */
export type SignatureOnSignatureForSignatureSigneeIdFkeyUsingSignatureSignatureHashKeyUpdate =
  {
    /** An object where the defined keys will be set on the `signature` being updated. */
    patch: UpdateSignatureOnSignatureForSignatureSigneeIdFkeyPatch;
    signatureHash: Scalars["String"];
  };

/** Represents an update to a `Signature`. Fields that are set will be updated. */
export type SignaturePatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  signatureHash?: InputMaybe<Scalars["String"]>;
  signee?: InputMaybe<SignatureSigneeIdFkeyInput>;
  signeeId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The fields on `signature` to look up the row to connect. */
export type SignatureSignaturePkeyConnect = {
  signeeId: Scalars["UUID"];
};

/** The fields on `signature` to look up the row to delete. */
export type SignatureSignaturePkeyDelete = {
  signeeId: Scalars["UUID"];
};

/** The fields on `signature` to look up the row to connect. */
export type SignatureSignatureSignatureHashKeyConnect = {
  signatureHash: Scalars["String"];
};

/** The fields on `signature` to look up the row to delete. */
export type SignatureSignatureSignatureHashKeyDelete = {
  signatureHash: Scalars["String"];
};

/** Input for the nested mutation of `signee` in the `SignatureInput` mutation. */
export type SignatureSigneeIdFkeyInput = {
  /** The primary key(s) for `signee` for the far side of the relationship. */
  connectById?: InputMaybe<SigneeNodeIdConnect>;
  /** The primary key(s) for `signee` for the far side of the relationship. */
  connectByRequestIdAndSigneeEmail?: InputMaybe<SigneeSigneeRequestIdSigneeEmailKeyConnect>;
  /** The primary key(s) for `signee` for the far side of the relationship. */
  connectBySigneeId?: InputMaybe<SigneeSigneePkeyConnect>;
  /** A `SigneeInput` object that will be created and connected to this object. */
  create?: InputMaybe<SignatureSigneeIdFkeySigneeCreateInput>;
  /** The primary key(s) for `signee` for the far side of the relationship. */
  deleteById?: InputMaybe<SigneeNodeIdDelete>;
  /** The primary key(s) for `signee` for the far side of the relationship. */
  deleteByRequestIdAndSigneeEmail?: InputMaybe<SigneeSigneeRequestIdSigneeEmailKeyDelete>;
  /** The primary key(s) for `signee` for the far side of the relationship. */
  deleteBySigneeId?: InputMaybe<SigneeSigneePkeyDelete>;
  /** The primary key(s) and patch data for `signee` for the far side of the relationship. */
  updateById?: InputMaybe<SignatureOnSignatureForSignatureSigneeIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `signee` for the far side of the relationship. */
  updateByRequestIdAndSigneeEmail?: InputMaybe<SigneeOnSignatureForSignatureSigneeIdFkeyUsingSigneeRequestIdSigneeEmailKeyUpdate>;
  /** The primary key(s) and patch data for `signee` for the far side of the relationship. */
  updateBySigneeId?: InputMaybe<SigneeOnSignatureForSignatureSigneeIdFkeyUsingSigneePkeyUpdate>;
};

/** Input for the nested mutation of `signature` in the `SigneeInput` mutation. */
export type SignatureSigneeIdFkeyInverseInput = {
  /** The primary key(s) for `signature` for the far side of the relationship. */
  connectById?: InputMaybe<SignatureNodeIdConnect>;
  /** The primary key(s) for `signature` for the far side of the relationship. */
  connectBySignatureHash?: InputMaybe<SignatureSignatureSignatureHashKeyConnect>;
  /** The primary key(s) for `signature` for the far side of the relationship. */
  connectBySigneeId?: InputMaybe<SignatureSignaturePkeyConnect>;
  /** A `SignatureInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<SignatureSigneeIdFkeySignatureCreateInput>>;
  /** The primary key(s) for `signature` for the far side of the relationship. */
  deleteById?: InputMaybe<SignatureNodeIdDelete>;
  /** The primary key(s) for `signature` for the far side of the relationship. */
  deleteBySignatureHash?: InputMaybe<SignatureSignatureSignatureHashKeyDelete>;
  /** The primary key(s) for `signature` for the far side of the relationship. */
  deleteBySigneeId?: InputMaybe<SignatureSignaturePkeyDelete>;
  /** Flag indicating whether all other `signature` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `signature` for the far side of the relationship. */
  updateById?: InputMaybe<SigneeOnSignatureForSignatureSigneeIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `signature` for the far side of the relationship. */
  updateBySignatureHash?: InputMaybe<SignatureOnSignatureForSignatureSigneeIdFkeyUsingSignatureSignatureHashKeyUpdate>;
  /** The primary key(s) and patch data for `signature` for the far side of the relationship. */
  updateBySigneeId?: InputMaybe<SignatureOnSignatureForSignatureSigneeIdFkeyUsingSignaturePkeyUpdate>;
};

/** The `signature` to be created by this mutation. */
export type SignatureSigneeIdFkeySignatureCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  signatureHash: Scalars["String"];
  signee?: InputMaybe<SignatureSigneeIdFkeyInput>;
  signeeId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The `signee` to be created by this mutation. */
export type SignatureSigneeIdFkeySigneeCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  datasets: Scalars["JSON"];
  request?: InputMaybe<SigneeRequestIdFkeyInput>;
  requestId?: InputMaybe<Scalars["UUID"]>;
  signature?: InputMaybe<SignatureSigneeIdFkeyInverseInput>;
  signeeEmail: Scalars["String"];
  signeeId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** A connection to a list of `Signature` values. */
export type SignaturesConnection = {
  __typename?: "SignaturesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SignatureAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<SignatureDistinct>;
  /** A list of edges which contains the `Signature` and cursor to aid in pagination. */
  edges: Array<SignaturesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SignatureAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<SignatureGroup>>;
  /** A list of `Signature` objects. */
  nodes: Array<Maybe<Signature>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of _all_ `Signature` primary keys. */
  signeeIds?: Maybe<Array<Scalars["UUID"]>>;
  /** The count of *all* `Signature` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `Signature` values. */
export type SignaturesConnectionGroupedAggregatesArgs = {
  groupBy: Array<SignatureGroupBy>;
  having?: InputMaybe<SignatureHavingInput>;
};

/** A connection to a list of `Signature` values. */
export type SignaturesConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<SignatureGroupBy>;
  having?: InputMaybe<SignatureHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `Signature` edge in the connection. */
export type SignaturesEdge = {
  __typename?: "SignaturesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `Signature` at the end of the edge. */
  node?: Maybe<Signature>;
};

/** Methods to use when ordering `Signature`. */
export enum SignaturesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  SignatureHashAsc = "SIGNATURE_HASH_ASC",
  SignatureHashDesc = "SIGNATURE_HASH_DESC",
  SigneeBySigneeIdCreatedAtAsc = "SIGNEE_BY_SIGNEE_ID_CREATED_AT_ASC",
  SigneeBySigneeIdCreatedAtDesc = "SIGNEE_BY_SIGNEE_ID_CREATED_AT_DESC",
  SigneeBySigneeIdCreatedByEmailAsc = "SIGNEE_BY_SIGNEE_ID_CREATED_BY_EMAIL_ASC",
  SigneeBySigneeIdCreatedByEmailDesc = "SIGNEE_BY_SIGNEE_ID_CREATED_BY_EMAIL_DESC",
  SigneeBySigneeIdDatasetsAsc = "SIGNEE_BY_SIGNEE_ID_DATASETS_ASC",
  SigneeBySigneeIdDatasetsDesc = "SIGNEE_BY_SIGNEE_ID_DATASETS_DESC",
  SigneeBySigneeIdRequestIdAsc = "SIGNEE_BY_SIGNEE_ID_REQUEST_ID_ASC",
  SigneeBySigneeIdRequestIdDesc = "SIGNEE_BY_SIGNEE_ID_REQUEST_ID_DESC",
  SigneeBySigneeIdSigneeEmailAsc = "SIGNEE_BY_SIGNEE_ID_SIGNEE_EMAIL_ASC",
  SigneeBySigneeIdSigneeEmailDesc = "SIGNEE_BY_SIGNEE_ID_SIGNEE_EMAIL_DESC",
  SigneeBySigneeIdSigneeIdAsc = "SIGNEE_BY_SIGNEE_ID_SIGNEE_ID_ASC",
  SigneeBySigneeIdSigneeIdDesc = "SIGNEE_BY_SIGNEE_ID_SIGNEE_ID_DESC",
  SigneeBySigneeIdUpdatedAtAsc = "SIGNEE_BY_SIGNEE_ID_UPDATED_AT_ASC",
  SigneeBySigneeIdUpdatedAtDesc = "SIGNEE_BY_SIGNEE_ID_UPDATED_AT_DESC",
  SigneeBySigneeIdUpdatedByEmailAsc = "SIGNEE_BY_SIGNEE_ID_UPDATED_BY_EMAIL_ASC",
  SigneeBySigneeIdUpdatedByEmailDesc = "SIGNEE_BY_SIGNEE_ID_UPDATED_BY_EMAIL_DESC",
  SigneeIdAsc = "SIGNEE_ID_ASC",
  SigneeIdDesc = "SIGNEE_ID_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

export type Signee = Node & {
  __typename?: "Signee";
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  datasets: Scalars["JSON"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  /** Reads a single `Request` that is related to this `Signee`. */
  request?: Maybe<Request>;
  requestId: Scalars["UUID"];
  /** Reads a single `Signature` that is related to this `Signee`. */
  signature?: Maybe<Signature>;
  signeeEmail: Scalars["String"];
  signeeId: Scalars["UUID"];
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type SigneeAggregates = {
  __typename?: "SigneeAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<SigneeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A filter to be used against aggregates of `Signee` object types. */
export type SigneeAggregatesFilter = {
  /** Distinct count aggregate over matching `Signee` objects. */
  distinctCount?: InputMaybe<SigneeDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `Signee` object to be included within the aggregate. */
  filter?: InputMaybe<SigneeFilter>;
};

/** A condition to be used against `Signee` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type SigneeCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `datasets` field. */
  datasets?: InputMaybe<Scalars["JSON"]>;
  /** Checks for equality with the object’s `requestId` field. */
  requestId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `signeeEmail` field. */
  signeeEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `signeeId` field. */
  signeeId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type SigneeDistinct = {
  __typename?: "SigneeDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `datasets` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  datasets: Array<Scalars["JSON"]>;
  /** Distinct `requestId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  requestId: Array<Scalars["UUID"]>;
  /** Distinct `signeeEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  signeeEmail: Array<Scalars["String"]>;
  /** Distinct `signeeId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  signeeId: Array<Scalars["UUID"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type SigneeDistinctCountAggregateFilter = {
  createdAt?: InputMaybe<BigIntFilter>;
  createdByEmail?: InputMaybe<BigIntFilter>;
  datasets?: InputMaybe<BigIntFilter>;
  requestId?: InputMaybe<BigIntFilter>;
  signeeEmail?: InputMaybe<BigIntFilter>;
  signeeId?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  updatedByEmail?: InputMaybe<BigIntFilter>;
};

export type SigneeDistinctCountAggregates = {
  __typename?: "SigneeDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of datasets across the matching connection */
  datasets?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of requestId across the matching connection */
  requestId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of signeeEmail across the matching connection */
  signeeEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of signeeId across the matching connection */
  signeeId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `Signee` object types. All fields are combined with a logical ‘and.’ */
export type SigneeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<SigneeFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `datasets` field. */
  datasets?: InputMaybe<JsonFilter>;
  /** Negates the expression. */
  not?: InputMaybe<SigneeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<SigneeFilter>>;
  /** Filter by the object’s `request` relation. */
  request?: InputMaybe<RequestFilter>;
  /** Filter by the object’s `requestId` field. */
  requestId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `signature` relation. */
  signature?: InputMaybe<SignatureFilter>;
  /** A related `signature` exists. */
  signatureExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `signeeEmail` field. */
  signeeEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `signeeId` field. */
  signeeId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type SigneeGroup = {
  __typename?: "SigneeGroup";
  /** `SigneeFilter` that was used to implicitly filter `signees`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** A list of _all_ `Signee` primary keys that belong to the `SigneeGroup`. */
  signeeIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `Signee` of this `SigneeGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `SigneeGroup`. Overriding these may result in the wrong set of `Signee` returned.
   */
  signees?: Maybe<SigneesConnection>;
  /** The count of _all_ `Signee` you could get from the `SigneeGroup`. */
  totalCount: Scalars["Int"];
};

export type SigneeGroupSigneesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<SigneeCondition>;
  filter?: InputMaybe<SigneeFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<SigneesOrderBy>>;
};

/** Grouping methods for `Signee` for usage during aggregation. */
export enum SigneeGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  Datasets = "DATASETS",
  RequestByRequestIdConfigurationId = "REQUEST_BY_REQUEST_ID_CONFIGURATION_ID",
  RequestByRequestIdCreatedAt = "REQUEST_BY_REQUEST_ID_CREATED_AT",
  RequestByRequestIdCreatedByEmail = "REQUEST_BY_REQUEST_ID_CREATED_BY_EMAIL",
  RequestByRequestIdMessage = "REQUEST_BY_REQUEST_ID_MESSAGE",
  RequestByRequestIdRequestId = "REQUEST_BY_REQUEST_ID_REQUEST_ID",
  RequestByRequestIdUpdatedAt = "REQUEST_BY_REQUEST_ID_UPDATED_AT",
  RequestByRequestIdUpdatedByEmail = "REQUEST_BY_REQUEST_ID_UPDATED_BY_EMAIL",
  RequestId = "REQUEST_ID",
  SignatureBySigneeIdCreatedAt = "SIGNATURE_BY_SIGNEE_ID_CREATED_AT",
  SignatureBySigneeIdCreatedByEmail = "SIGNATURE_BY_SIGNEE_ID_CREATED_BY_EMAIL",
  SignatureBySigneeIdSignatureHash = "SIGNATURE_BY_SIGNEE_ID_SIGNATURE_HASH",
  SignatureBySigneeIdSigneeId = "SIGNATURE_BY_SIGNEE_ID_SIGNEE_ID",
  SignatureBySigneeIdUpdatedAt = "SIGNATURE_BY_SIGNEE_ID_UPDATED_AT",
  SignatureBySigneeIdUpdatedByEmail = "SIGNATURE_BY_SIGNEE_ID_UPDATED_BY_EMAIL",
  SigneeEmail = "SIGNEE_EMAIL",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type SigneeHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SigneeHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `Signee` aggregates. */
export type SigneeHavingInput = {
  AND?: InputMaybe<Array<SigneeHavingInput>>;
  OR?: InputMaybe<Array<SigneeHavingInput>>;
  average?: InputMaybe<SigneeHavingAverageInput>;
  distinctCount?: InputMaybe<SigneeHavingDistinctCountInput>;
  max?: InputMaybe<SigneeHavingMaxInput>;
  min?: InputMaybe<SigneeHavingMinInput>;
  stddevPopulation?: InputMaybe<SigneeHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<SigneeHavingStddevSampleInput>;
  sum?: InputMaybe<SigneeHavingSumInput>;
  variancePopulation?: InputMaybe<SigneeHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<SigneeHavingVarianceSampleInput>;
};

export type SigneeHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SigneeHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SigneeHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SigneeHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SigneeHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SigneeHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type SigneeHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `Signee` */
export type SigneeInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  datasets: Scalars["JSON"];
  request?: InputMaybe<SigneeRequestIdFkeyInput>;
  requestId?: InputMaybe<Scalars["UUID"]>;
  signature?: InputMaybe<SignatureSigneeIdFkeyInverseInput>;
  signeeEmail: Scalars["String"];
  signeeId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type SigneeNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `signee` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type SigneeNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `signee` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type SigneeOnSignatureForSignatureSigneeIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `signature` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `signature` being updated. */
  patch: SignaturePatch;
};

/** The fields on `signee` to look up the row to update. */
export type SigneeOnSignatureForSignatureSigneeIdFkeyUsingSigneePkeyUpdate = {
  /** An object where the defined keys will be set on the `signee` being updated. */
  patch: UpdateSigneeOnSignatureForSignatureSigneeIdFkeyPatch;
  signeeId: Scalars["UUID"];
};

/** The fields on `signee` to look up the row to update. */
export type SigneeOnSignatureForSignatureSigneeIdFkeyUsingSigneeRequestIdSigneeEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `signee` being updated. */
    patch: UpdateSigneeOnSignatureForSignatureSigneeIdFkeyPatch;
    requestId: Scalars["UUID"];
    signeeEmail: Scalars["String"];
  };

/** The globally unique `ID` look up for the row to update. */
export type SigneeOnSigneeForSigneeRequestIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `request` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `request` being updated. */
  patch: RequestPatch;
};

/** The fields on `signee` to look up the row to update. */
export type SigneeOnSigneeForSigneeRequestIdFkeyUsingSigneePkeyUpdate = {
  /** An object where the defined keys will be set on the `signee` being updated. */
  patch: UpdateSigneeOnSigneeForSigneeRequestIdFkeyPatch;
  signeeId: Scalars["UUID"];
};

/** The fields on `signee` to look up the row to update. */
export type SigneeOnSigneeForSigneeRequestIdFkeyUsingSigneeRequestIdSigneeEmailKeyUpdate =
  {
    /** An object where the defined keys will be set on the `signee` being updated. */
    patch: UpdateSigneeOnSigneeForSigneeRequestIdFkeyPatch;
    requestId: Scalars["UUID"];
    signeeEmail: Scalars["String"];
  };

/** Represents an update to a `Signee`. Fields that are set will be updated. */
export type SigneePatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  datasets?: InputMaybe<Scalars["JSON"]>;
  request?: InputMaybe<SigneeRequestIdFkeyInput>;
  requestId?: InputMaybe<Scalars["UUID"]>;
  signature?: InputMaybe<SignatureSigneeIdFkeyInverseInput>;
  signeeEmail?: InputMaybe<Scalars["String"]>;
  signeeId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** Input for the nested mutation of `request` in the `SigneeInput` mutation. */
export type SigneeRequestIdFkeyInput = {
  /** The primary key(s) for `request` for the far side of the relationship. */
  connectByConfigurationId?: InputMaybe<RequestRequestConfigurationIdKeyConnect>;
  /** The primary key(s) for `request` for the far side of the relationship. */
  connectById?: InputMaybe<RequestNodeIdConnect>;
  /** The primary key(s) for `request` for the far side of the relationship. */
  connectByRequestId?: InputMaybe<RequestRequestPkeyConnect>;
  /** A `RequestInput` object that will be created and connected to this object. */
  create?: InputMaybe<SigneeRequestIdFkeyRequestCreateInput>;
  /** The primary key(s) for `request` for the far side of the relationship. */
  deleteByConfigurationId?: InputMaybe<RequestRequestConfigurationIdKeyDelete>;
  /** The primary key(s) for `request` for the far side of the relationship. */
  deleteById?: InputMaybe<RequestNodeIdDelete>;
  /** The primary key(s) for `request` for the far side of the relationship. */
  deleteByRequestId?: InputMaybe<RequestRequestPkeyDelete>;
  /** The primary key(s) and patch data for `request` for the far side of the relationship. */
  updateByConfigurationId?: InputMaybe<RequestOnSigneeForSigneeRequestIdFkeyUsingRequestConfigurationIdKeyUpdate>;
  /** The primary key(s) and patch data for `request` for the far side of the relationship. */
  updateById?: InputMaybe<SigneeOnSigneeForSigneeRequestIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `request` for the far side of the relationship. */
  updateByRequestId?: InputMaybe<RequestOnSigneeForSigneeRequestIdFkeyUsingRequestPkeyUpdate>;
};

/** Input for the nested mutation of `signee` in the `RequestInput` mutation. */
export type SigneeRequestIdFkeyInverseInput = {
  /** The primary key(s) for `signee` for the far side of the relationship. */
  connectById?: InputMaybe<Array<SigneeNodeIdConnect>>;
  /** The primary key(s) for `signee` for the far side of the relationship. */
  connectByRequestIdAndSigneeEmail?: InputMaybe<
    Array<SigneeSigneeRequestIdSigneeEmailKeyConnect>
  >;
  /** The primary key(s) for `signee` for the far side of the relationship. */
  connectBySigneeId?: InputMaybe<Array<SigneeSigneePkeyConnect>>;
  /** A `SigneeInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<SigneeRequestIdFkeySigneeCreateInput>>;
  /** The primary key(s) for `signee` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<SigneeNodeIdDelete>>;
  /** The primary key(s) for `signee` for the far side of the relationship. */
  deleteByRequestIdAndSigneeEmail?: InputMaybe<
    Array<SigneeSigneeRequestIdSigneeEmailKeyDelete>
  >;
  /** The primary key(s) for `signee` for the far side of the relationship. */
  deleteBySigneeId?: InputMaybe<Array<SigneeSigneePkeyDelete>>;
  /** Flag indicating whether all other `signee` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `signee` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<RequestOnSigneeForSigneeRequestIdFkeyNodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `signee` for the far side of the relationship. */
  updateByRequestIdAndSigneeEmail?: InputMaybe<
    Array<SigneeOnSigneeForSigneeRequestIdFkeyUsingSigneeRequestIdSigneeEmailKeyUpdate>
  >;
  /** The primary key(s) and patch data for `signee` for the far side of the relationship. */
  updateBySigneeId?: InputMaybe<
    Array<SigneeOnSigneeForSigneeRequestIdFkeyUsingSigneePkeyUpdate>
  >;
};

/** The `request` to be created by this mutation. */
export type SigneeRequestIdFkeyRequestCreateInput = {
  configuration?: InputMaybe<RequestConfigurationIdFkeyInput>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  message: Scalars["String"];
  requestId?: InputMaybe<Scalars["UUID"]>;
  signees?: InputMaybe<SigneeRequestIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The `signee` to be created by this mutation. */
export type SigneeRequestIdFkeySigneeCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  datasets: Scalars["JSON"];
  request?: InputMaybe<SigneeRequestIdFkeyInput>;
  requestId?: InputMaybe<Scalars["UUID"]>;
  signature?: InputMaybe<SignatureSigneeIdFkeyInverseInput>;
  signeeEmail: Scalars["String"];
  signeeId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The fields on `signee` to look up the row to connect. */
export type SigneeSigneePkeyConnect = {
  signeeId: Scalars["UUID"];
};

/** The fields on `signee` to look up the row to delete. */
export type SigneeSigneePkeyDelete = {
  signeeId: Scalars["UUID"];
};

/** The fields on `signee` to look up the row to connect. */
export type SigneeSigneeRequestIdSigneeEmailKeyConnect = {
  requestId: Scalars["UUID"];
  signeeEmail: Scalars["String"];
};

/** The fields on `signee` to look up the row to delete. */
export type SigneeSigneeRequestIdSigneeEmailKeyDelete = {
  requestId: Scalars["UUID"];
  signeeEmail: Scalars["String"];
};

/** A connection to a list of `Signee` values. */
export type SigneesConnection = {
  __typename?: "SigneesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<SigneeAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<SigneeDistinct>;
  /** A list of edges which contains the `Signee` and cursor to aid in pagination. */
  edges: Array<SigneesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<SigneeAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<SigneeGroup>>;
  /** A list of `Signee` objects. */
  nodes: Array<Maybe<Signee>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** A list of _all_ `Signee` primary keys. */
  signeeIds?: Maybe<Array<Scalars["UUID"]>>;
  /** The count of *all* `Signee` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `Signee` values. */
export type SigneesConnectionGroupedAggregatesArgs = {
  groupBy: Array<SigneeGroupBy>;
  having?: InputMaybe<SigneeHavingInput>;
};

/** A connection to a list of `Signee` values. */
export type SigneesConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<SigneeGroupBy>;
  having?: InputMaybe<SigneeHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `Signee` edge in the connection. */
export type SigneesEdge = {
  __typename?: "SigneesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `Signee` at the end of the edge. */
  node?: Maybe<Signee>;
};

/** Methods to use when ordering `Signee`. */
export enum SigneesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  DatasetsAsc = "DATASETS_ASC",
  DatasetsDesc = "DATASETS_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  RequestByRequestIdConfigurationIdAsc = "REQUEST_BY_REQUEST_ID_CONFIGURATION_ID_ASC",
  RequestByRequestIdConfigurationIdDesc = "REQUEST_BY_REQUEST_ID_CONFIGURATION_ID_DESC",
  RequestByRequestIdCreatedAtAsc = "REQUEST_BY_REQUEST_ID_CREATED_AT_ASC",
  RequestByRequestIdCreatedAtDesc = "REQUEST_BY_REQUEST_ID_CREATED_AT_DESC",
  RequestByRequestIdCreatedByEmailAsc = "REQUEST_BY_REQUEST_ID_CREATED_BY_EMAIL_ASC",
  RequestByRequestIdCreatedByEmailDesc = "REQUEST_BY_REQUEST_ID_CREATED_BY_EMAIL_DESC",
  RequestByRequestIdMessageAsc = "REQUEST_BY_REQUEST_ID_MESSAGE_ASC",
  RequestByRequestIdMessageDesc = "REQUEST_BY_REQUEST_ID_MESSAGE_DESC",
  RequestByRequestIdRequestIdAsc = "REQUEST_BY_REQUEST_ID_REQUEST_ID_ASC",
  RequestByRequestIdRequestIdDesc = "REQUEST_BY_REQUEST_ID_REQUEST_ID_DESC",
  RequestByRequestIdUpdatedAtAsc = "REQUEST_BY_REQUEST_ID_UPDATED_AT_ASC",
  RequestByRequestIdUpdatedAtDesc = "REQUEST_BY_REQUEST_ID_UPDATED_AT_DESC",
  RequestByRequestIdUpdatedByEmailAsc = "REQUEST_BY_REQUEST_ID_UPDATED_BY_EMAIL_ASC",
  RequestByRequestIdUpdatedByEmailDesc = "REQUEST_BY_REQUEST_ID_UPDATED_BY_EMAIL_DESC",
  RequestIdAsc = "REQUEST_ID_ASC",
  RequestIdDesc = "REQUEST_ID_DESC",
  SignatureBySigneeIdCreatedAtAsc = "SIGNATURE_BY_SIGNEE_ID_CREATED_AT_ASC",
  SignatureBySigneeIdCreatedAtDesc = "SIGNATURE_BY_SIGNEE_ID_CREATED_AT_DESC",
  SignatureBySigneeIdCreatedByEmailAsc = "SIGNATURE_BY_SIGNEE_ID_CREATED_BY_EMAIL_ASC",
  SignatureBySigneeIdCreatedByEmailDesc = "SIGNATURE_BY_SIGNEE_ID_CREATED_BY_EMAIL_DESC",
  SignatureBySigneeIdSignatureHashAsc = "SIGNATURE_BY_SIGNEE_ID_SIGNATURE_HASH_ASC",
  SignatureBySigneeIdSignatureHashDesc = "SIGNATURE_BY_SIGNEE_ID_SIGNATURE_HASH_DESC",
  SignatureBySigneeIdSigneeIdAsc = "SIGNATURE_BY_SIGNEE_ID_SIGNEE_ID_ASC",
  SignatureBySigneeIdSigneeIdDesc = "SIGNATURE_BY_SIGNEE_ID_SIGNEE_ID_DESC",
  SignatureBySigneeIdUpdatedAtAsc = "SIGNATURE_BY_SIGNEE_ID_UPDATED_AT_ASC",
  SignatureBySigneeIdUpdatedAtDesc = "SIGNATURE_BY_SIGNEE_ID_UPDATED_AT_DESC",
  SignatureBySigneeIdUpdatedByEmailAsc = "SIGNATURE_BY_SIGNEE_ID_UPDATED_BY_EMAIL_ASC",
  SignatureBySigneeIdUpdatedByEmailDesc = "SIGNATURE_BY_SIGNEE_ID_UPDATED_BY_EMAIL_DESC",
  SigneeEmailAsc = "SIGNEE_EMAIL_ASC",
  SigneeEmailDesc = "SIGNEE_EMAIL_DESC",
  SigneeIdAsc = "SIGNEE_ID_ASC",
  SigneeIdDesc = "SIGNEE_ID_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

export enum SourceType {
  Js = "JS",
  Python = "PYTHON",
  Web = "WEB",
}

/** A filter to be used against SourceType fields. All fields are combined with a logical ‘and.’ */
export type SourceTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<SourceType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<SourceType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<SourceType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<SourceType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<SourceType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<SourceType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<SourceType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<SourceType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<SourceType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<SourceType>>;
};

export type SqlNodePrivacyFilter = {
  __typename?: "SqlNodePrivacyFilter";
  minimumRow: Scalars["Int"];
};

export type State = Node & {
  __typename?: "State";
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** Reads a single `DataRoom` that is related to this `State`. */
  dataRoom?: Maybe<DataRoom>;
  dataRoomId: Scalars["UUID"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  isStopped?: Maybe<Scalars["Boolean"]>;
  status?: Maybe<StatusType>;
  statusUpdatedAt?: Maybe<Scalars["Datetime"]>;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
};

export type StateAggregates = {
  __typename?: "StateAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<StateDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A condition to be used against `State` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type StateCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `status` field. */
  status?: InputMaybe<StatusType>;
  /** Checks for equality with the object’s `statusUpdatedAt` field. */
  statusUpdatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The `dataRoom` to be created by this mutation. */
export type StateDataRoomIdFkeyDataRoomCreateInput = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** Input for the nested mutation of `dataRoom` in the `StateInput` mutation. */
export type StateDataRoomIdFkeyInput = {
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomHash?: InputMaybe<DataRoomDataRoomDataRoomHashKeyConnect>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomId?: InputMaybe<DataRoomDataRoomPkeyConnect>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectById?: InputMaybe<DataRoomNodeIdConnect>;
  /** A `DataRoomInput` object that will be created and connected to this object. */
  create?: InputMaybe<StateDataRoomIdFkeyDataRoomCreateInput>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomHash?: InputMaybe<DataRoomDataRoomDataRoomHashKeyDelete>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomId?: InputMaybe<DataRoomDataRoomPkeyDelete>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteById?: InputMaybe<DataRoomNodeIdDelete>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomHash?: InputMaybe<DataRoomOnStateForStateDataRoomIdFkeyUsingDataRoomDataRoomHashKeyUpdate>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomId?: InputMaybe<DataRoomOnStateForStateDataRoomIdFkeyUsingDataRoomPkeyUpdate>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateById?: InputMaybe<StateOnStateForStateDataRoomIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `state` in the `DataRoomInput` mutation. */
export type StateDataRoomIdFkeyInverseInput = {
  /** The primary key(s) for `state` for the far side of the relationship. */
  connectByDataRoomId?: InputMaybe<StateStatePkeyConnect>;
  /** The primary key(s) for `state` for the far side of the relationship. */
  connectById?: InputMaybe<StateNodeIdConnect>;
  /** A `StateInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<StateDataRoomIdFkeyStateCreateInput>>;
  /** The primary key(s) for `state` for the far side of the relationship. */
  deleteByDataRoomId?: InputMaybe<StateStatePkeyDelete>;
  /** The primary key(s) for `state` for the far side of the relationship. */
  deleteById?: InputMaybe<StateNodeIdDelete>;
  /** Flag indicating whether all other `state` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `state` for the far side of the relationship. */
  updateByDataRoomId?: InputMaybe<StateOnStateForStateDataRoomIdFkeyUsingStatePkeyUpdate>;
  /** The primary key(s) and patch data for `state` for the far side of the relationship. */
  updateById?: InputMaybe<DataRoomOnStateForStateDataRoomIdFkeyNodeIdUpdate>;
};

/** The `state` to be created by this mutation. */
export type StateDataRoomIdFkeyStateCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<StateDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  status?: InputMaybe<StatusType>;
  statusUpdatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type StateDistinct = {
  __typename?: "StateDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `dataRoomId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  dataRoomId: Array<Scalars["UUID"]>;
  /** Distinct `status` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  status: Array<Maybe<StatusType>>;
  /** Distinct `statusUpdatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  statusUpdatedAt: Array<Maybe<Scalars["Datetime"]>>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type StateDistinctCountAggregates = {
  __typename?: "StateDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of dataRoomId across the matching connection */
  dataRoomId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of status across the matching connection */
  status?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of statusUpdatedAt across the matching connection */
  statusUpdatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `State` object types. All fields are combined with a logical ‘and.’ */
export type StateFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<StateFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dataRoom` relation. */
  dataRoom?: InputMaybe<DataRoomFilter>;
  /** Filter by the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<UuidFilter>;
  /** Negates the expression. */
  not?: InputMaybe<StateFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<StateFilter>>;
  /** Filter by the object’s `status` field. */
  status?: InputMaybe<StatusTypeFilter>;
  /** Filter by the object’s `statusUpdatedAt` field. */
  statusUpdatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
};

export type StateGroup = {
  __typename?: "StateGroup";
  /** A list of _all_ `State` primary keys that belong to the `StateGroup`. */
  dataRoomIds?: Maybe<Array<Scalars["UUID"]>>;
  /** `StateFilter` that was used to implicitly filter `states`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /**
   * Reads and enables pagination through a set of `State` of this `StateGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `StateGroup`. Overriding these may result in the wrong set of `State` returned.
   */
  states?: Maybe<StatesConnection>;
  /** The count of _all_ `State` you could get from the `StateGroup`. */
  totalCount: Scalars["Int"];
};

export type StateGroupStatesArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<StateCondition>;
  filter?: InputMaybe<StateFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

/** Grouping methods for `State` for usage during aggregation. */
export enum StateGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  DataRoomByDataRoomIdCreatedAt = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT",
  DataRoomByDataRoomIdCreatedByEmail = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL",
  DataRoomByDataRoomIdDataRoomHash = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH",
  DataRoomByDataRoomIdDataRoomId = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID",
  DataRoomByDataRoomIdDescription = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION",
  DataRoomByDataRoomIdEnableDevelopmentTab = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB",
  DataRoomByDataRoomIdGovernanceProtocol = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL",
  DataRoomByDataRoomIdMrenclave = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE",
  DataRoomByDataRoomIdName = "DATA_ROOM_BY_DATA_ROOM_ID_NAME",
  DataRoomByDataRoomIdOwnerEmail = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL",
  DataRoomByDataRoomIdSource = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE",
  DataRoomByDataRoomIdUpdatedAt = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT",
  DataRoomByDataRoomIdUpdatedByEmail = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL",
  Status = "STATUS",
  StatusUpdatedAt = "STATUS_UPDATED_AT",
  StatusUpdatedAtTruncatedToDay = "STATUS_UPDATED_AT_TRUNCATED_TO_DAY",
  StatusUpdatedAtTruncatedToHour = "STATUS_UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
}

export type StateHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  statusUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type StateHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  statusUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `State` aggregates. */
export type StateHavingInput = {
  AND?: InputMaybe<Array<StateHavingInput>>;
  OR?: InputMaybe<Array<StateHavingInput>>;
  average?: InputMaybe<StateHavingAverageInput>;
  distinctCount?: InputMaybe<StateHavingDistinctCountInput>;
  max?: InputMaybe<StateHavingMaxInput>;
  min?: InputMaybe<StateHavingMinInput>;
  stddevPopulation?: InputMaybe<StateHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<StateHavingStddevSampleInput>;
  sum?: InputMaybe<StateHavingSumInput>;
  variancePopulation?: InputMaybe<StateHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<StateHavingVarianceSampleInput>;
};

export type StateHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  statusUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type StateHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  statusUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type StateHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  statusUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type StateHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  statusUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type StateHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  statusUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type StateHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  statusUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type StateHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  statusUpdatedAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `State` */
export type StateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<StateDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  status?: InputMaybe<StatusType>;
  statusUpdatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type StateNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `state` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type StateNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `state` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type StateOnStateForStateDataRoomIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `dataRoom` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `dataRoom` being updated. */
  patch: DataRoomPatch;
};

/** The fields on `state` to look up the row to update. */
export type StateOnStateForStateDataRoomIdFkeyUsingStatePkeyUpdate = {
  dataRoomId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `state` being updated. */
  patch: UpdateStateOnStateForStateDataRoomIdFkeyPatch;
};

/** Represents an update to a `State`. Fields that are set will be updated. */
export type StatePatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<StateDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  status?: InputMaybe<StatusType>;
  statusUpdatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** The fields on `state` to look up the row to connect. */
export type StateStatePkeyConnect = {
  dataRoomId: Scalars["UUID"];
};

/** The fields on `state` to look up the row to delete. */
export type StateStatePkeyDelete = {
  dataRoomId: Scalars["UUID"];
};

/** A connection to a list of `State` values. */
export type StatesConnection = {
  __typename?: "StatesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<StateAggregates>;
  /** A list of _all_ `State` primary keys. */
  dataRoomIds?: Maybe<Array<Scalars["UUID"]>>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<StateDistinct>;
  /** A list of edges which contains the `State` and cursor to aid in pagination. */
  edges: Array<StatesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<StateAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<StateGroup>>;
  /** A list of `State` objects. */
  nodes: Array<Maybe<State>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `State` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `State` values. */
export type StatesConnectionGroupedAggregatesArgs = {
  groupBy: Array<StateGroupBy>;
  having?: InputMaybe<StateHavingInput>;
};

/** A connection to a list of `State` values. */
export type StatesConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<StateGroupBy>;
  having?: InputMaybe<StateHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `State` edge in the connection. */
export type StatesEdge = {
  __typename?: "StatesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `State` at the end of the edge. */
  node?: Maybe<State>;
};

/** Methods to use when ordering `State`. */
export enum StatesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  DataRoomByDataRoomIdCreatedAtAsc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT_ASC",
  DataRoomByDataRoomIdCreatedAtDesc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT_DESC",
  DataRoomByDataRoomIdCreatedByEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_ASC",
  DataRoomByDataRoomIdCreatedByEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_DESC",
  DataRoomByDataRoomIdDataRoomHashAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH_ASC",
  DataRoomByDataRoomIdDataRoomHashDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH_DESC",
  DataRoomByDataRoomIdDataRoomIdAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID_ASC",
  DataRoomByDataRoomIdDataRoomIdDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID_DESC",
  DataRoomByDataRoomIdDescriptionAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION_ASC",
  DataRoomByDataRoomIdDescriptionDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION_DESC",
  DataRoomByDataRoomIdEnableDevelopmentTabAsc = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomByDataRoomIdEnableDevelopmentTabDesc = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomByDataRoomIdGovernanceProtocolAsc = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL_ASC",
  DataRoomByDataRoomIdGovernanceProtocolDesc = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL_DESC",
  DataRoomByDataRoomIdMrenclaveAsc = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE_ASC",
  DataRoomByDataRoomIdMrenclaveDesc = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE_DESC",
  DataRoomByDataRoomIdNameAsc = "DATA_ROOM_BY_DATA_ROOM_ID_NAME_ASC",
  DataRoomByDataRoomIdNameDesc = "DATA_ROOM_BY_DATA_ROOM_ID_NAME_DESC",
  DataRoomByDataRoomIdOwnerEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL_ASC",
  DataRoomByDataRoomIdOwnerEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL_DESC",
  DataRoomByDataRoomIdSourceAsc = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE_ASC",
  DataRoomByDataRoomIdSourceDesc = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE_DESC",
  DataRoomByDataRoomIdUpdatedAtAsc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT_ASC",
  DataRoomByDataRoomIdUpdatedAtDesc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT_DESC",
  DataRoomByDataRoomIdUpdatedByEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_ASC",
  DataRoomByDataRoomIdUpdatedByEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_DESC",
  DataRoomIdAsc = "DATA_ROOM_ID_ASC",
  DataRoomIdDesc = "DATA_ROOM_ID_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  StatusAsc = "STATUS_ASC",
  StatusDesc = "STATUS_DESC",
  StatusUpdatedAtAsc = "STATUS_UPDATED_AT_ASC",
  StatusUpdatedAtDesc = "STATUS_UPDATED_AT_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
}

export enum StatusType {
  Stopped = "STOPPED",
}

/** A filter to be used against StatusType fields. All fields are combined with a logical ‘and.’ */
export type StatusTypeFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<StatusType>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<StatusType>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<StatusType>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<StatusType>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<StatusType>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<StatusType>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<StatusType>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<StatusType>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<StatusType>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<StatusType>>;
};

/** A filter to be used against String fields. All fields are combined with a logical ‘and.’ */
export type StringFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["String"]>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars["String"]>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars["String"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["String"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["String"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["String"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["String"]>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars["String"]>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars["String"]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["String"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["String"]>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars["String"]>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars["String"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["String"]>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars["String"]>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars["String"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["String"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["String"]>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars["String"]>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars["String"]>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars["String"]>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars["String"]>;
  /** Does not match the specified pattern using the SQL standard's definition of a regular expression. */
  notSimilarTo?: InputMaybe<Scalars["String"]>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars["String"]>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars["String"]>;
  /** Matches the specified pattern using the SQL standard's definition of a regular expression. */
  similarTo?: InputMaybe<Scalars["String"]>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars["String"]>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars["String"]>;
};

/** A filter to be used against String List fields. All fields are combined with a logical ‘and.’ */
export type StringListFilter = {
  /** Any array item is equal to the specified value. */
  anyEqualTo?: InputMaybe<Scalars["String"]>;
  /** Any array item is greater than the specified value. */
  anyGreaterThan?: InputMaybe<Scalars["String"]>;
  /** Any array item is greater than or equal to the specified value. */
  anyGreaterThanOrEqualTo?: InputMaybe<Scalars["String"]>;
  /** Any array item is less than the specified value. */
  anyLessThan?: InputMaybe<Scalars["String"]>;
  /** Any array item is less than or equal to the specified value. */
  anyLessThanOrEqualTo?: InputMaybe<Scalars["String"]>;
  /** Any array item is not equal to the specified value. */
  anyNotEqualTo?: InputMaybe<Scalars["String"]>;
  /** Contained by the specified list of values. */
  containedBy?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Contains the specified list of values. */
  contains?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  /** Overlaps the specified list of values. */
  overlaps?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
};

/** The root subscription type: contains realtime events you can subscribe to with the `subscription` operation. */
export type Subscription = {
  __typename?: "Subscription";
  /** Reads all events. */
  events?: Maybe<EventsSubscriptionPayload>;
};

export enum TableLeafNodeColumDataType {
  Float = "FLOAT",
  Int = "INT",
  String = "STRING",
}

export type TableLeafNodeColumn = {
  __typename?: "TableLeafNodeColumn";
  dataType: TableLeafNodeColumDataType;
  id: Scalars["ID"];
  isNullable: Scalars["Boolean"];
  name: Scalars["String"];
};

/** A filter to be used against UUID fields. All fields are combined with a logical ‘and.’ */
export type UuidFilter = {
  /** Not equal to the specified value, treating null like an ordinary value. */
  distinctFrom?: InputMaybe<Scalars["UUID"]>;
  /** Ends with the specified string (case-sensitive). */
  endsWith?: InputMaybe<Scalars["String"]>;
  /** Ends with the specified string (case-insensitive). */
  endsWithInsensitive?: InputMaybe<Scalars["String"]>;
  /** Equal to the specified value. */
  equalTo?: InputMaybe<Scalars["UUID"]>;
  /** Greater than the specified value. */
  greaterThan?: InputMaybe<Scalars["UUID"]>;
  /** Greater than or equal to the specified value. */
  greaterThanOrEqualTo?: InputMaybe<Scalars["UUID"]>;
  /** Included in the specified list. */
  in?: InputMaybe<Array<Scalars["UUID"]>>;
  /** Contains the specified string (case-sensitive). */
  includes?: InputMaybe<Scalars["String"]>;
  /** Contains the specified string (case-insensitive). */
  includesInsensitive?: InputMaybe<Scalars["String"]>;
  /** Is null (if `true` is specified) or is not null (if `false` is specified). */
  isNull?: InputMaybe<Scalars["Boolean"]>;
  /** Less than the specified value. */
  lessThan?: InputMaybe<Scalars["UUID"]>;
  /** Less than or equal to the specified value. */
  lessThanOrEqualTo?: InputMaybe<Scalars["UUID"]>;
  /** Matches the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  like?: InputMaybe<Scalars["String"]>;
  /** Matches the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  likeInsensitive?: InputMaybe<Scalars["String"]>;
  /** Equal to the specified value, treating null like an ordinary value. */
  notDistinctFrom?: InputMaybe<Scalars["UUID"]>;
  /** Does not end with the specified string (case-sensitive). */
  notEndsWith?: InputMaybe<Scalars["String"]>;
  /** Does not end with the specified string (case-insensitive). */
  notEndsWithInsensitive?: InputMaybe<Scalars["String"]>;
  /** Not equal to the specified value. */
  notEqualTo?: InputMaybe<Scalars["UUID"]>;
  /** Not included in the specified list. */
  notIn?: InputMaybe<Array<Scalars["UUID"]>>;
  /** Does not contain the specified string (case-sensitive). */
  notIncludes?: InputMaybe<Scalars["UUID"]>;
  /** Does not contain the specified string (case-insensitive). */
  notIncludesInsensitive?: InputMaybe<Scalars["String"]>;
  /** Does not match the specified pattern (case-sensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLike?: InputMaybe<Scalars["String"]>;
  /** Does not match the specified pattern (case-insensitive). An underscore (_) matches any single character; a percent sign (%) matches any sequence of zero or more characters. */
  notLikeInsensitive?: InputMaybe<Scalars["String"]>;
  /** Does not start with the specified string (case-sensitive). */
  notStartsWith?: InputMaybe<Scalars["String"]>;
  /** Does not start with the specified string (case-insensitive). */
  notStartsWithInsensitive?: InputMaybe<Scalars["String"]>;
  /** Starts with the specified string (case-sensitive). */
  startsWith?: InputMaybe<Scalars["String"]>;
  /** Starts with the specified string (case-insensitive). */
  startsWithInsensitive?: InputMaybe<Scalars["String"]>;
};

/** All input for the `updateAttestationSpecificationAsElementById` mutation. */
export type UpdateAttestationSpecificationAsElementByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `AttestationSpecificationAsElement` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `AttestationSpecificationAsElement` being updated. */
  patch: AttestationSpecificationAsElementPatch;
};

/** All input for the `updateAttestationSpecificationAsElement` mutation. */
export type UpdateAttestationSpecificationAsElementInput = {
  attestationSpecificationId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** An object where the defined keys will be set on the `AttestationSpecificationAsElement` being updated. */
  patch: AttestationSpecificationAsElementPatch;
};

/** The output of our update `AttestationSpecificationAsElement` mutation. */
export type UpdateAttestationSpecificationAsElementPayload = {
  __typename?: "UpdateAttestationSpecificationAsElementPayload";
  /** The `AttestationSpecificationAsElement` that was updated by this mutation. */
  attestationSpecificationAsElement?: Maybe<AttestationSpecificationAsElement>;
  /** An edge for our `AttestationSpecificationAsElement`. May be used by Relay 1. */
  attestationSpecificationAsElementEdge?: Maybe<AttestationSpecificationAsElementsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Modification` that is related to this `AttestationSpecificationAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `AttestationSpecificationAsElement` mutation. */
export type UpdateAttestationSpecificationAsElementPayloadAttestationSpecificationAsElementEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AttestationSpecificationAsElementsOrderBy>>;
  };

/** All input for the `AttestationSpecificationAsElement` item in `updateAttestationSpecificationAsElements` mutation. */
export type UpdateAttestationSpecificationAsElementsAttestationSpecificationAsElementInput =
  {
    attestationSpecificationId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `AttestationSpecificationAsElement` being updated. */
    patch: AttestationSpecificationAsElementPatch;
  };

/** All input for the `updateAttestationSpecificationAsElements` mutation. */
export type UpdateAttestationSpecificationAsElementsInput = {
  /** A list of `AttestationSpecificationAsElement` to be updated by this mutation. */
  attestationSpecificationAsElements?: InputMaybe<
    Array<
      InputMaybe<UpdateAttestationSpecificationAsElementsAttestationSpecificationAsElementInput>
    >
  >;
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of updating `AttestationSpecificationAsElement` list mutation. */
export type UpdateAttestationSpecificationAsElementsPayload = {
  __typename?: "UpdateAttestationSpecificationAsElementsPayload";
  /** An edge for our `AttestationSpecificationAsElement`. May be used by Relay 1. */
  attestationSpecificationAsElementEdge?: Maybe<AttestationSpecificationAsElementsEdge>;
  /** The list of `AttestationSpecificationAsElement` that was updated by this mutation. */
  attestationSpecificationAsElements?: Maybe<
    Array<Maybe<AttestationSpecificationAsElement>>
  >;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Modification` that is related to this `AttestationSpecificationAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of updating `AttestationSpecificationAsElement` list mutation. */
export type UpdateAttestationSpecificationAsElementsPayloadAttestationSpecificationAsElementEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AttestationSpecificationAsElementsOrderBy>>;
  };

/** All input for the `updateAuthenticationMethodAsElementById` mutation. */
export type UpdateAuthenticationMethodAsElementByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `AuthenticationMethodAsElement` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `AuthenticationMethodAsElement` being updated. */
  patch: AuthenticationMethodAsElementPatch;
};

/** All input for the `updateAuthenticationMethodAsElement` mutation. */
export type UpdateAuthenticationMethodAsElementInput = {
  authenticationMethodId: Scalars["UUID"];
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** An object where the defined keys will be set on the `AuthenticationMethodAsElement` being updated. */
  patch: AuthenticationMethodAsElementPatch;
};

/** The output of our update `AuthenticationMethodAsElement` mutation. */
export type UpdateAuthenticationMethodAsElementPayload = {
  __typename?: "UpdateAuthenticationMethodAsElementPayload";
  /** The `AuthenticationMethodAsElement` that was updated by this mutation. */
  authenticationMethodAsElement?: Maybe<AuthenticationMethodAsElement>;
  /** An edge for our `AuthenticationMethodAsElement`. May be used by Relay 1. */
  authenticationMethodAsElementEdge?: Maybe<AuthenticationMethodAsElementsEdge>;
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Modification` that is related to this `AuthenticationMethodAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `AuthenticationMethodAsElement` mutation. */
export type UpdateAuthenticationMethodAsElementPayloadAuthenticationMethodAsElementEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AuthenticationMethodAsElementsOrderBy>>;
  };

/** All input for the `AuthenticationMethodAsElement` item in `updateAuthenticationMethodAsElements` mutation. */
export type UpdateAuthenticationMethodAsElementsAuthenticationMethodAsElementInput =
  {
    authenticationMethodId: Scalars["UUID"];
    /** An object where the defined keys will be set on the `AuthenticationMethodAsElement` being updated. */
    patch: AuthenticationMethodAsElementPatch;
  };

/** All input for the `updateAuthenticationMethodAsElements` mutation. */
export type UpdateAuthenticationMethodAsElementsInput = {
  /** A list of `AuthenticationMethodAsElement` to be updated by this mutation. */
  authenticationMethodAsElements?: InputMaybe<
    Array<
      InputMaybe<UpdateAuthenticationMethodAsElementsAuthenticationMethodAsElementInput>
    >
  >;
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of updating `AuthenticationMethodAsElement` list mutation. */
export type UpdateAuthenticationMethodAsElementsPayload = {
  __typename?: "UpdateAuthenticationMethodAsElementsPayload";
  /** An edge for our `AuthenticationMethodAsElement`. May be used by Relay 1. */
  authenticationMethodAsElementEdge?: Maybe<AuthenticationMethodAsElementsEdge>;
  /** The list of `AuthenticationMethodAsElement` that was updated by this mutation. */
  authenticationMethodAsElements?: Maybe<
    Array<Maybe<AuthenticationMethodAsElement>>
  >;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Modification` that is related to this `AuthenticationMethodAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of updating `AuthenticationMethodAsElement` list mutation. */
export type UpdateAuthenticationMethodAsElementsPayloadAuthenticationMethodAsElementEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AuthenticationMethodAsElementsOrderBy>>;
  };

/** All input for the `updateComputeNodeAsElementById` mutation. */
export type UpdateComputeNodeAsElementByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `ComputeNodeAsElement` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `ComputeNodeAsElement` being updated. */
  patch: ComputeNodeAsElementPatch;
};

/** All input for the `updateComputeNodeAsElement` mutation. */
export type UpdateComputeNodeAsElementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  computeNodeId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `ComputeNodeAsElement` being updated. */
  patch: ComputeNodeAsElementPatch;
};

/** The output of our update `ComputeNodeAsElement` mutation. */
export type UpdateComputeNodeAsElementPayload = {
  __typename?: "UpdateComputeNodeAsElementPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `ComputeNodeAsElement` that was updated by this mutation. */
  computeNodeAsElement?: Maybe<ComputeNodeAsElement>;
  /** An edge for our `ComputeNodeAsElement`. May be used by Relay 1. */
  computeNodeAsElementEdge?: Maybe<ComputeNodeAsElementsEdge>;
  /** Reads a single `Modification` that is related to this `ComputeNodeAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `ComputeNodeAsElement` mutation. */
export type UpdateComputeNodeAsElementPayloadComputeNodeAsElementEdgeArgs = {
  orderBy?: InputMaybe<Array<ComputeNodeAsElementsOrderBy>>;
};

/** All input for the `ComputeNodeAsElement` item in `updateComputeNodeAsElements` mutation. */
export type UpdateComputeNodeAsElementsComputeNodeAsElementInput = {
  computeNodeId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `ComputeNodeAsElement` being updated. */
  patch: ComputeNodeAsElementPatch;
};

/** All input for the `updateComputeNodeAsElements` mutation. */
export type UpdateComputeNodeAsElementsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `ComputeNodeAsElement` to be updated by this mutation. */
  computeNodeAsElements?: InputMaybe<
    Array<InputMaybe<UpdateComputeNodeAsElementsComputeNodeAsElementInput>>
  >;
};

/** The output of updating `ComputeNodeAsElement` list mutation. */
export type UpdateComputeNodeAsElementsPayload = {
  __typename?: "UpdateComputeNodeAsElementsPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** An edge for our `ComputeNodeAsElement`. May be used by Relay 1. */
  computeNodeAsElementEdge?: Maybe<ComputeNodeAsElementsEdge>;
  /** The list of `ComputeNodeAsElement` that was updated by this mutation. */
  computeNodeAsElements?: Maybe<Array<Maybe<ComputeNodeAsElement>>>;
  /** Reads a single `Modification` that is related to this `ComputeNodeAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of updating `ComputeNodeAsElement` list mutation. */
export type UpdateComputeNodeAsElementsPayloadComputeNodeAsElementEdgeArgs = {
  orderBy?: InputMaybe<Array<ComputeNodeAsElementsOrderBy>>;
};

/** All input for the `updateComputeNodeById` mutation. */
export type UpdateComputeNodeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `ComputeNode` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `ComputeNode` being updated. */
  patch: ComputeNodePatch;
};

/** All input for the `updateComputeNode` mutation. */
export type UpdateComputeNodeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  computeNodeId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `ComputeNode` being updated. */
  patch: ComputeNodePatch;
};

/** The output of our update `ComputeNode` mutation. */
export type UpdateComputeNodePayload = {
  __typename?: "UpdateComputeNodePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `ComputeNode` that was updated by this mutation. */
  computeNode?: Maybe<ComputeNode>;
  /** An edge for our `ComputeNode`. May be used by Relay 1. */
  computeNodeEdge?: Maybe<ComputeNodesEdge>;
  /** Reads a single `DataRoom` that is related to this `ComputeNode`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `ComputeNode` mutation. */
export type UpdateComputeNodePayloadComputeNodeEdgeArgs = {
  orderBy?: InputMaybe<Array<ComputeNodesOrderBy>>;
};

/** All input for the `ComputeNode` item in `updateComputeNodes` mutation. */
export type UpdateComputeNodesComputeNodeInput = {
  computeNodeId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `ComputeNode` being updated. */
  patch: ComputeNodePatch;
};

/** All input for the `updateComputeNodes` mutation. */
export type UpdateComputeNodesInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `ComputeNode` to be updated by this mutation. */
  computeNodes?: InputMaybe<
    Array<InputMaybe<UpdateComputeNodesComputeNodeInput>>
  >;
};

/** The output of updating `ComputeNode` list mutation. */
export type UpdateComputeNodesPayload = {
  __typename?: "UpdateComputeNodesPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** An edge for our `ComputeNode`. May be used by Relay 1. */
  computeNodeEdge?: Maybe<ComputeNodesEdge>;
  /** The list of `ComputeNode` that was updated by this mutation. */
  computeNodes?: Maybe<Array<Maybe<ComputeNode>>>;
  /** Reads a single `DataRoom` that is related to this `ComputeNode`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of updating `ComputeNode` list mutation. */
export type UpdateComputeNodesPayloadComputeNodeEdgeArgs = {
  orderBy?: InputMaybe<Array<ComputeNodesOrderBy>>;
};

/** All input for the `updateConfigById` mutation. */
export type UpdateConfigByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Config` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `Config` being updated. */
  patch: ConfigPatch;
};

/** All input for the `updateConfig` mutation. */
export type UpdateConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  dataRoomId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `Config` being updated. */
  patch: ConfigPatch;
};

/** The output of our update `Config` mutation. */
export type UpdateConfigPayload = {
  __typename?: "UpdateConfigPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Config` that was updated by this mutation. */
  config?: Maybe<Config>;
  /** An edge for our `Config`. May be used by Relay 1. */
  configEdge?: Maybe<ConfigsEdge>;
  /** Reads a single `DataRoom` that is related to this `Config`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `Config` mutation. */
export type UpdateConfigPayloadConfigEdgeArgs = {
  orderBy?: InputMaybe<Array<ConfigsOrderBy>>;
};

/** All input for the `Config` item in `updateConfigs` mutation. */
export type UpdateConfigsConfigInput = {
  dataRoomId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `Config` being updated. */
  patch: ConfigPatch;
};

/** All input for the `updateConfigs` mutation. */
export type UpdateConfigsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `Config` to be updated by this mutation. */
  configs?: InputMaybe<Array<InputMaybe<UpdateConfigsConfigInput>>>;
};

/** The output of updating `Config` list mutation. */
export type UpdateConfigsPayload = {
  __typename?: "UpdateConfigsPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** An edge for our `Config`. May be used by Relay 1. */
  configEdge?: Maybe<ConfigsEdge>;
  /** The list of `Config` that was updated by this mutation. */
  configs?: Maybe<Array<Maybe<Config>>>;
  /** Reads a single `DataRoom` that is related to this `Config`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of updating `Config` list mutation. */
export type UpdateConfigsPayloadConfigEdgeArgs = {
  orderBy?: InputMaybe<Array<ConfigsOrderBy>>;
};

/** All input for the `updateConfigurationByCommitId` mutation. */
export type UpdateConfigurationByCommitIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  commitId: Scalars["String"];
  /** An object where the defined keys will be set on the `Configuration` being updated. */
  patch: ConfigurationPatch;
};

/** All input for the `updateConfigurationById` mutation. */
export type UpdateConfigurationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Configuration` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `Configuration` being updated. */
  patch: ConfigurationPatch;
};

/** All input for the `updateConfiguration` mutation. */
export type UpdateConfigurationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  configurationId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `Configuration` being updated. */
  patch: ConfigurationPatch;
};

/** The output of our update `Configuration` mutation. */
export type UpdateConfigurationPayload = {
  __typename?: "UpdateConfigurationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Configuration` that was updated by this mutation. */
  configuration?: Maybe<Configuration>;
  /** An edge for our `Configuration`. May be used by Relay 1. */
  configurationEdge?: Maybe<ConfigurationsEdge>;
  /** Reads a single `DataRoom` that is related to this `Configuration`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `Configuration` mutation. */
export type UpdateConfigurationPayloadConfigurationEdgeArgs = {
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy>>;
};

/** All input for the `Configuration` item in `updateConfigurations` mutation. */
export type UpdateConfigurationsConfigurationInput = {
  configurationId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `Configuration` being updated. */
  patch: ConfigurationPatch;
};

/** All input for the `updateConfigurations` mutation. */
export type UpdateConfigurationsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `Configuration` to be updated by this mutation. */
  configurations?: InputMaybe<
    Array<InputMaybe<UpdateConfigurationsConfigurationInput>>
  >;
};

/** The output of updating `Configuration` list mutation. */
export type UpdateConfigurationsPayload = {
  __typename?: "UpdateConfigurationsPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** An edge for our `Configuration`. May be used by Relay 1. */
  configurationEdge?: Maybe<ConfigurationsEdge>;
  /** The list of `Configuration` that was updated by this mutation. */
  configurations?: Maybe<Array<Maybe<Configuration>>>;
  /** Reads a single `DataRoom` that is related to this `Configuration`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of updating `Configuration` list mutation. */
export type UpdateConfigurationsPayloadConfigurationEdgeArgs = {
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy>>;
};

/** All input for the `updateDataRoomByDataRoomHash` mutation. */
export type UpdateDataRoomByDataRoomHashInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  dataRoomHash: Scalars["String"];
  /** An object where the defined keys will be set on the `DataRoom` being updated. */
  patch: DataRoomPatch;
};

/** All input for the `updateDataRoomById` mutation. */
export type UpdateDataRoomByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `DataRoom` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `DataRoom` being updated. */
  patch: DataRoomPatch;
};

/** All input for the `updateDataRoom` mutation. */
export type UpdateDataRoomInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  dataRoomId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `DataRoom` being updated. */
  patch: DataRoomPatch;
};

/** The output of our update `DataRoom` mutation. */
export type UpdateDataRoomPayload = {
  __typename?: "UpdateDataRoomPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `DataRoom` that was updated by this mutation. */
  dataRoom?: Maybe<DataRoom>;
  /** An edge for our `DataRoom`. May be used by Relay 1. */
  dataRoomEdge?: Maybe<DataRoomsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `DataRoom`. */
  userByOwnerEmail?: Maybe<User>;
};

/** The output of our update `DataRoom` mutation. */
export type UpdateDataRoomPayloadDataRoomEdgeArgs = {
  orderBy?: InputMaybe<Array<DataRoomsOrderBy>>;
};

/** All input for the `DataRoom` item in `updateDataRooms` mutation. */
export type UpdateDataRoomsDataRoomInput = {
  dataRoomId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `DataRoom` being updated. */
  patch: DataRoomPatch;
};

/** All input for the `updateDataRooms` mutation. */
export type UpdateDataRoomsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `DataRoom` to be updated by this mutation. */
  dataRooms?: InputMaybe<Array<InputMaybe<UpdateDataRoomsDataRoomInput>>>;
};

/** The output of updating `DataRoom` list mutation. */
export type UpdateDataRoomsPayload = {
  __typename?: "UpdateDataRoomsPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** An edge for our `DataRoom`. May be used by Relay 1. */
  dataRoomEdge?: Maybe<DataRoomsEdge>;
  /** The list of `DataRoom` that was updated by this mutation. */
  dataRooms?: Maybe<Array<Maybe<DataRoom>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `DataRoom`. */
  userByOwnerEmail?: Maybe<User>;
};

/** The output of updating `DataRoom` list mutation. */
export type UpdateDataRoomsPayloadDataRoomEdgeArgs = {
  orderBy?: InputMaybe<Array<DataRoomsOrderBy>>;
};

/** All input for the `updateDatasetLinkById` mutation. */
export type UpdateDatasetLinkByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `DatasetLink` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `DatasetLink` being updated. */
  patch: DatasetLinkPatch;
};

/** All input for the `updateDatasetLink` mutation. */
export type UpdateDatasetLinkInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  datasetLinkId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `DatasetLink` being updated. */
  patch: DatasetLinkPatch;
};

/** The output of our update `DatasetLink` mutation. */
export type UpdateDatasetLinkPayload = {
  __typename?: "UpdateDatasetLinkPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `ComputeNode` that is related to this `DatasetLink`. */
  computeNode?: Maybe<ComputeNode>;
  /** The `DatasetLink` that was updated by this mutation. */
  datasetLink?: Maybe<DatasetLink>;
  /** An edge for our `DatasetLink`. May be used by Relay 1. */
  datasetLinkEdge?: Maybe<DatasetLinksEdge>;
  /** Reads a single `DatasetMeta` that is related to this `DatasetLink`. */
  datasetMeta?: Maybe<DatasetMeta>;
  /** Reads a single `DatasetStat` that is related to this `DatasetLink`. */
  datasetStats?: Maybe<DatasetStat>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `DatasetLink` mutation. */
export type UpdateDatasetLinkPayloadDatasetLinkEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetLinksOrderBy>>;
};

/** All input for the `DatasetLink` item in `updateDatasetLinks` mutation. */
export type UpdateDatasetLinksDatasetLinkInput = {
  datasetLinkId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `DatasetLink` being updated. */
  patch: DatasetLinkPatch;
};

/** All input for the `updateDatasetLinks` mutation. */
export type UpdateDatasetLinksInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `DatasetLink` to be updated by this mutation. */
  datasetLinks?: InputMaybe<
    Array<InputMaybe<UpdateDatasetLinksDatasetLinkInput>>
  >;
};

/** The output of updating `DatasetLink` list mutation. */
export type UpdateDatasetLinksPayload = {
  __typename?: "UpdateDatasetLinksPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `ComputeNode` that is related to this `DatasetLink`. */
  computeNode?: Maybe<ComputeNode>;
  /** An edge for our `DatasetLink`. May be used by Relay 1. */
  datasetLinkEdge?: Maybe<DatasetLinksEdge>;
  /** The list of `DatasetLink` that was updated by this mutation. */
  datasetLinks?: Maybe<Array<Maybe<DatasetLink>>>;
  /** Reads a single `DatasetMeta` that is related to this `DatasetLink`. */
  datasetMeta?: Maybe<DatasetMeta>;
  /** Reads a single `DatasetStat` that is related to this `DatasetLink`. */
  datasetStats?: Maybe<DatasetStat>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of updating `DatasetLink` list mutation. */
export type UpdateDatasetLinksPayloadDatasetLinkEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetLinksOrderBy>>;
};

/** All input for the `updateDatasetMetaById` mutation. */
export type UpdateDatasetMetaByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `DatasetMeta` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `DatasetMeta` being updated. */
  patch: DatasetMetaPatch;
};

/** All input for the `updateDatasetMeta` mutation. */
export type UpdateDatasetMetaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  datasetHash: Scalars["String"];
  /** An object where the defined keys will be set on the `DatasetMeta` being updated. */
  patch: DatasetMetaPatch;
};

/** The output of our update `DatasetMeta` mutation. */
export type UpdateDatasetMetaPayload = {
  __typename?: "UpdateDatasetMetaPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `DatasetMeta` that was updated by this mutation. */
  datasetMeta?: Maybe<DatasetMeta>;
  /** An edge for our `DatasetMeta`. May be used by Relay 1. */
  datasetMetaEdge?: Maybe<DatasetMetasEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `DatasetMeta` mutation. */
export type UpdateDatasetMetaPayloadDatasetMetaEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetMetasOrderBy>>;
};

/** All input for the `DatasetMeta` item in `updateDatasetMetas` mutation. */
export type UpdateDatasetMetasDatasetMetaInput = {
  datasetHash: Scalars["String"];
  /** An object where the defined keys will be set on the `DatasetMeta` being updated. */
  patch: DatasetMetaPatch;
};

/** All input for the `updateDatasetMetas` mutation. */
export type UpdateDatasetMetasInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `DatasetMeta` to be updated by this mutation. */
  datasetMetas?: InputMaybe<
    Array<InputMaybe<UpdateDatasetMetasDatasetMetaInput>>
  >;
};

/** The output of updating `DatasetMeta` list mutation. */
export type UpdateDatasetMetasPayload = {
  __typename?: "UpdateDatasetMetasPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** An edge for our `DatasetMeta`. May be used by Relay 1. */
  datasetMetaEdge?: Maybe<DatasetMetasEdge>;
  /** The list of `DatasetMeta` that was updated by this mutation. */
  datasetMetas?: Maybe<Array<Maybe<DatasetMeta>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of updating `DatasetMeta` list mutation. */
export type UpdateDatasetMetasPayloadDatasetMetaEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetMetasOrderBy>>;
};

/** All input for the `updateDatasetStatById` mutation. */
export type UpdateDatasetStatByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `DatasetStat` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `DatasetStat` being updated. */
  patch: DatasetStatPatch;
};

/** All input for the `updateDatasetStat` mutation. */
export type UpdateDatasetStatInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  datasetHash: Scalars["String"];
  /** An object where the defined keys will be set on the `DatasetStat` being updated. */
  patch: DatasetStatPatch;
};

/** The output of our update `DatasetStat` mutation. */
export type UpdateDatasetStatPayload = {
  __typename?: "UpdateDatasetStatPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DatasetMeta` that is related to this `DatasetStat`. */
  datasetMeta?: Maybe<DatasetMeta>;
  /** The `DatasetStat` that was updated by this mutation. */
  datasetStat?: Maybe<DatasetStat>;
  /** An edge for our `DatasetStat`. May be used by Relay 1. */
  datasetStatEdge?: Maybe<DatasetStatsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `DatasetStat` mutation. */
export type UpdateDatasetStatPayloadDatasetStatEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetStatsOrderBy>>;
};

/** All input for the `updateDomainByDomain` mutation. */
export type UpdateDomainByDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  domain: Scalars["String"];
  /** An object where the defined keys will be set on the `Domain` being updated. */
  patch: DomainPatch;
};

/** All input for the `updateDomainById` mutation. */
export type UpdateDomainByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Domain` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `Domain` being updated. */
  patch: DomainPatch;
};

/** All input for the `updateDomain` mutation. */
export type UpdateDomainInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  domainId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `Domain` being updated. */
  patch: DomainPatch;
};

/** The output of our update `Domain` mutation. */
export type UpdateDomainPayload = {
  __typename?: "UpdateDomainPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Domain` that was updated by this mutation. */
  domain?: Maybe<Domain>;
  /** An edge for our `Domain`. May be used by Relay 1. */
  domainEdge?: Maybe<DomainsEdge>;
  /** Reads a single `Organization` that is related to this `Domain`. */
  organization?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Domain`. */
  user?: Maybe<User>;
};

/** The output of our update `Domain` mutation. */
export type UpdateDomainPayloadDomainEdgeArgs = {
  orderBy?: InputMaybe<Array<DomainsOrderBy>>;
};

/** All input for the `Domain` item in `updateDomains` mutation. */
export type UpdateDomainsDomainInput = {
  domainId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `Domain` being updated. */
  patch: DomainPatch;
};

/** All input for the `updateDomains` mutation. */
export type UpdateDomainsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `Domain` to be updated by this mutation. */
  domains?: InputMaybe<Array<InputMaybe<UpdateDomainsDomainInput>>>;
};

/** The output of updating `Domain` list mutation. */
export type UpdateDomainsPayload = {
  __typename?: "UpdateDomainsPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** An edge for our `Domain`. May be used by Relay 1. */
  domainEdge?: Maybe<DomainsEdge>;
  /** The list of `Domain` that was updated by this mutation. */
  domains?: Maybe<Array<Maybe<Domain>>>;
  /** Reads a single `Organization` that is related to this `Domain`. */
  organization?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Domain`. */
  user?: Maybe<User>;
};

/** The output of updating `Domain` list mutation. */
export type UpdateDomainsPayloadDomainEdgeArgs = {
  orderBy?: InputMaybe<Array<DomainsOrderBy>>;
};

/** All input for the `updateExecutionTimeById` mutation. */
export type UpdateExecutionTimeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `ExecutionTime` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `ExecutionTime` being updated. */
  patch: ExecutionTimePatch;
};

/** All input for the `updateExecutionTime` mutation. */
export type UpdateExecutionTimeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** An object where the defined keys will be set on the `ExecutionTime` being updated. */
  patch: ExecutionTimePatch;
  taskId: Scalars["UUID"];
};

/** The output of our update `ExecutionTime` mutation. */
export type UpdateExecutionTimePayload = {
  __typename?: "UpdateExecutionTimePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `ExecutionTime` that was updated by this mutation. */
  executionTime?: Maybe<ExecutionTime>;
  /** An edge for our `ExecutionTime`. May be used by Relay 1. */
  executionTimeEdge?: Maybe<ExecutionTimesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `ExecutionTime` mutation. */
export type UpdateExecutionTimePayloadExecutionTimeEdgeArgs = {
  orderBy?: InputMaybe<Array<ExecutionTimesOrderBy>>;
};

/** All input for the `updateJobById` mutation. */
export type UpdateJobByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Job` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `Job` being updated. */
  patch: JobPatch;
};

/** All input for the `updateJob` mutation. */
export type UpdateJobInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  computeNodeId: Scalars["UUID"];
  ownerEmail: Scalars["String"];
  /** An object where the defined keys will be set on the `Job` being updated. */
  patch: JobPatch;
};

/** The output of our update `Job` mutation. */
export type UpdateJobPayload = {
  __typename?: "UpdateJobPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `ComputeNode` that is related to this `Job`. */
  computeNode?: Maybe<ComputeNode>;
  /** The `Job` that was updated by this mutation. */
  job?: Maybe<Job>;
  /** An edge for our `Job`. May be used by Relay 1. */
  jobEdge?: Maybe<JobsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `Job` mutation. */
export type UpdateJobPayloadJobEdgeArgs = {
  orderBy?: InputMaybe<Array<JobsOrderBy>>;
};

/** All input for the `updateJobs` mutation. */
export type UpdateJobsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `Job` to be updated by this mutation. */
  jobs?: InputMaybe<Array<InputMaybe<UpdateJobsJobInput>>>;
};

/** All input for the `Job` item in `updateJobs` mutation. */
export type UpdateJobsJobInput = {
  computeNodeId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `Job` being updated. */
  patch: JobPatch;
};

/** The output of updating `Job` list mutation. */
export type UpdateJobsPayload = {
  __typename?: "UpdateJobsPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `ComputeNode` that is related to this `Job`. */
  computeNode?: Maybe<ComputeNode>;
  /** An edge for our `Job`. May be used by Relay 1. */
  jobEdge?: Maybe<JobsEdge>;
  /** The list of `Job` that was updated by this mutation. */
  jobs?: Maybe<Array<Maybe<Job>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of updating `Job` list mutation. */
export type UpdateJobsPayloadJobEdgeArgs = {
  orderBy?: InputMaybe<Array<JobsOrderBy>>;
};

/** All input for the `updateLockById` mutation. */
export type UpdateLockByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Lock` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `Lock` being updated. */
  patch: LockPatch;
};

/** All input for the `updateLock` mutation. */
export type UpdateLockInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  dataRoomId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `Lock` being updated. */
  patch: LockPatch;
};

/** The output of our update `Lock` mutation. */
export type UpdateLockPayload = {
  __typename?: "UpdateLockPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `Lock`. */
  dataRoom?: Maybe<DataRoom>;
  /** The `Lock` that was updated by this mutation. */
  lock?: Maybe<Lock>;
  /** An edge for our `Lock`. May be used by Relay 1. */
  lockEdge?: Maybe<LocksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `Lock` mutation. */
export type UpdateLockPayloadLockEdgeArgs = {
  orderBy?: InputMaybe<Array<LocksOrderBy>>;
};

/** All input for the `updateLocks` mutation. */
export type UpdateLocksInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `Lock` to be updated by this mutation. */
  locks?: InputMaybe<Array<InputMaybe<UpdateLocksLockInput>>>;
};

/** All input for the `Lock` item in `updateLocks` mutation. */
export type UpdateLocksLockInput = {
  dataRoomId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `Lock` being updated. */
  patch: LockPatch;
};

/** The output of updating `Lock` list mutation. */
export type UpdateLocksPayload = {
  __typename?: "UpdateLocksPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `Lock`. */
  dataRoom?: Maybe<DataRoom>;
  /** An edge for our `Lock`. May be used by Relay 1. */
  lockEdge?: Maybe<LocksEdge>;
  /** The list of `Lock` that was updated by this mutation. */
  locks?: Maybe<Array<Maybe<Lock>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of updating `Lock` list mutation. */
export type UpdateLocksPayloadLockEdgeArgs = {
  orderBy?: InputMaybe<Array<LocksOrderBy>>;
};

/** All input for the `updateModificationByElementId` mutation. */
export type UpdateModificationByElementIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  elementId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `Modification` being updated. */
  patch: ModificationPatch;
};

/** All input for the `updateModificationById` mutation. */
export type UpdateModificationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Modification` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `Modification` being updated. */
  patch: ModificationPatch;
};

/** All input for the `updateModification` mutation. */
export type UpdateModificationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  modificationId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `Modification` being updated. */
  patch: ModificationPatch;
};

/** The output of our update `Modification` mutation. */
export type UpdateModificationPayload = {
  __typename?: "UpdateModificationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Configuration` that is related to this `Modification`. */
  configuration?: Maybe<Configuration>;
  /** The `Modification` that was updated by this mutation. */
  modification?: Maybe<Modification>;
  /** An edge for our `Modification`. May be used by Relay 1. */
  modificationEdge?: Maybe<ModificationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `Modification` mutation. */
export type UpdateModificationPayloadModificationEdgeArgs = {
  orderBy?: InputMaybe<Array<ModificationsOrderBy>>;
};

/** All input for the `updateModifications` mutation. */
export type UpdateModificationsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `Modification` to be updated by this mutation. */
  modifications?: InputMaybe<
    Array<InputMaybe<UpdateModificationsModificationInput>>
  >;
};

/** All input for the `Modification` item in `updateModifications` mutation. */
export type UpdateModificationsModificationInput = {
  modificationId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `Modification` being updated. */
  patch: ModificationPatch;
};

/** The output of updating `Modification` list mutation. */
export type UpdateModificationsPayload = {
  __typename?: "UpdateModificationsPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Configuration` that is related to this `Modification`. */
  configuration?: Maybe<Configuration>;
  /** An edge for our `Modification`. May be used by Relay 1. */
  modificationEdge?: Maybe<ModificationsEdge>;
  /** The list of `Modification` that was updated by this mutation. */
  modifications?: Maybe<Array<Maybe<Modification>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of updating `Modification` list mutation. */
export type UpdateModificationsPayloadModificationEdgeArgs = {
  orderBy?: InputMaybe<Array<ModificationsOrderBy>>;
};

/** All input for the `updateOrganizationAttributeById` mutation. */
export type UpdateOrganizationAttributeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `OrganizationAttribute` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `OrganizationAttribute` being updated. */
  patch: OrganizationAttributePatch;
};

/** All input for the `updateOrganizationAttribute` mutation. */
export type UpdateOrganizationAttributeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `OrganizationAttribute` being updated. */
  patch: OrganizationAttributePatch;
};

/** The output of our update `OrganizationAttribute` mutation. */
export type UpdateOrganizationAttributePayload = {
  __typename?: "UpdateOrganizationAttributePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `OrganizationAttribute`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationAttribute` that was updated by this mutation. */
  organizationAttribute?: Maybe<OrganizationAttribute>;
  /** An edge for our `OrganizationAttribute`. May be used by Relay 1. */
  organizationAttributeEdge?: Maybe<OrganizationAttributesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `OrganizationAttribute` mutation. */
export type UpdateOrganizationAttributePayloadOrganizationAttributeEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationAttributesOrderBy>>;
};

/** All input for the `updateOrganizationById` mutation. */
export type UpdateOrganizationByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Organization` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `Organization` being updated. */
  patch: OrganizationPatch;
};

/** All input for the `updateOrganization` mutation. */
export type UpdateOrganizationInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `Organization` being updated. */
  patch: OrganizationPatch;
};

/** All input for the `updateOrganizationLogoById` mutation. */
export type UpdateOrganizationLogoByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `OrganizationLogo` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `OrganizationLogo` being updated. */
  patch: OrganizationLogoPatch;
};

/** All input for the `updateOrganizationLogo` mutation. */
export type UpdateOrganizationLogoInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `OrganizationLogo` being updated. */
  patch: OrganizationLogoPatch;
};

/** The output of our update `OrganizationLogo` mutation. */
export type UpdateOrganizationLogoPayload = {
  __typename?: "UpdateOrganizationLogoPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `OrganizationLogo`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationLogo` that was updated by this mutation. */
  organizationLogo?: Maybe<OrganizationLogo>;
  /** An edge for our `OrganizationLogo`. May be used by Relay 1. */
  organizationLogoEdge?: Maybe<OrganizationLogosEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `OrganizationLogo` mutation. */
export type UpdateOrganizationLogoPayloadOrganizationLogoEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationLogosOrderBy>>;
};

/** All input for the `updateOrganizationLogos` mutation. */
export type UpdateOrganizationLogosInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `OrganizationLogo` to be updated by this mutation. */
  organizationLogos?: InputMaybe<
    Array<InputMaybe<UpdateOrganizationLogosOrganizationLogoInput>>
  >;
};

/** All input for the `OrganizationLogo` item in `updateOrganizationLogos` mutation. */
export type UpdateOrganizationLogosOrganizationLogoInput = {
  organizationId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `OrganizationLogo` being updated. */
  patch: OrganizationLogoPatch;
};

/** The output of updating `OrganizationLogo` list mutation. */
export type UpdateOrganizationLogosPayload = {
  __typename?: "UpdateOrganizationLogosPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `OrganizationLogo`. */
  organization?: Maybe<Organization>;
  /** An edge for our `OrganizationLogo`. May be used by Relay 1. */
  organizationLogoEdge?: Maybe<OrganizationLogosEdge>;
  /** The list of `OrganizationLogo` that was updated by this mutation. */
  organizationLogos?: Maybe<Array<Maybe<OrganizationLogo>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of updating `OrganizationLogo` list mutation. */
export type UpdateOrganizationLogosPayloadOrganizationLogoEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationLogosOrderBy>>;
};

/** All input for the `updateOrganizationMembershipById` mutation. */
export type UpdateOrganizationMembershipByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `OrganizationMembership` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `OrganizationMembership` being updated. */
  patch: OrganizationMembershipPatch;
};

/** All input for the `updateOrganizationMembership` mutation. */
export type UpdateOrganizationMembershipInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  organizationMembershipId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `OrganizationMembership` being updated. */
  patch: OrganizationMembershipPatch;
};

/** The output of our update `OrganizationMembership` mutation. */
export type UpdateOrganizationMembershipPayload = {
  __typename?: "UpdateOrganizationMembershipPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `OrganizationMembership`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationMembership` that was updated by this mutation. */
  organizationMembership?: Maybe<OrganizationMembership>;
  /** An edge for our `OrganizationMembership`. May be used by Relay 1. */
  organizationMembershipEdge?: Maybe<OrganizationMembershipsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `OrganizationMembership`. */
  user?: Maybe<User>;
};

/** The output of our update `OrganizationMembership` mutation. */
export type UpdateOrganizationMembershipPayloadOrganizationMembershipEdgeArgs =
  {
    orderBy?: InputMaybe<Array<OrganizationMembershipsOrderBy>>;
  };

/** All input for the `updateOrganizationMemberships` mutation. */
export type UpdateOrganizationMembershipsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `OrganizationMembership` to be updated by this mutation. */
  organizationMemberships?: InputMaybe<
    Array<InputMaybe<UpdateOrganizationMembershipsOrganizationMembershipInput>>
  >;
};

/** All input for the `OrganizationMembership` item in `updateOrganizationMemberships` mutation. */
export type UpdateOrganizationMembershipsOrganizationMembershipInput = {
  organizationMembershipId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `OrganizationMembership` being updated. */
  patch: OrganizationMembershipPatch;
};

/** The output of updating `OrganizationMembership` list mutation. */
export type UpdateOrganizationMembershipsPayload = {
  __typename?: "UpdateOrganizationMembershipsPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `OrganizationMembership`. */
  organization?: Maybe<Organization>;
  /** An edge for our `OrganizationMembership`. May be used by Relay 1. */
  organizationMembershipEdge?: Maybe<OrganizationMembershipsEdge>;
  /** The list of `OrganizationMembership` that was updated by this mutation. */
  organizationMemberships?: Maybe<Array<Maybe<OrganizationMembership>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `OrganizationMembership`. */
  user?: Maybe<User>;
};

/** The output of updating `OrganizationMembership` list mutation. */
export type UpdateOrganizationMembershipsPayloadOrganizationMembershipEdgeArgs =
  {
    orderBy?: InputMaybe<Array<OrganizationMembershipsOrderBy>>;
  };

/** The output of our update `Organization` mutation. */
export type UpdateOrganizationPayload = {
  __typename?: "UpdateOrganizationPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Organization` that was updated by this mutation. */
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `Organization` mutation. */
export type UpdateOrganizationPayloadOrganizationEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

/** All input for the `updateOrganizationQuotaById` mutation. */
export type UpdateOrganizationQuotaByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `OrganizationQuota` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `OrganizationQuota` being updated. */
  patch: OrganizationQuotaPatch;
};

/** All input for the `updateOrganizationQuota` mutation. */
export type UpdateOrganizationQuotaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  organizationId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `OrganizationQuota` being updated. */
  patch: OrganizationQuotaPatch;
};

/** The output of our update `OrganizationQuota` mutation. */
export type UpdateOrganizationQuotaPayload = {
  __typename?: "UpdateOrganizationQuotaPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `OrganizationQuota`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationQuota` that was updated by this mutation. */
  organizationQuota?: Maybe<OrganizationQuota>;
  /** An edge for our `OrganizationQuota`. May be used by Relay 1. */
  organizationQuotaEdge?: Maybe<OrganizationQuotasEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our update `OrganizationQuota` mutation. */
export type UpdateOrganizationQuotaPayloadOrganizationQuotaEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationQuotasOrderBy>>;
};

/** All input for the `updateOrganizationQuotas` mutation. */
export type UpdateOrganizationQuotasInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `OrganizationQuota` to be updated by this mutation. */
  organizationQuotas?: InputMaybe<
    Array<InputMaybe<UpdateOrganizationQuotasOrganizationQuotaInput>>
  >;
};

/** All input for the `OrganizationQuota` item in `updateOrganizationQuotas` mutation. */
export type UpdateOrganizationQuotasOrganizationQuotaInput = {
  organizationId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `OrganizationQuota` being updated. */
  patch: OrganizationQuotaPatch;
};

/** The output of updating `OrganizationQuota` list mutation. */
export type UpdateOrganizationQuotasPayload = {
  __typename?: "UpdateOrganizationQuotasPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `OrganizationQuota`. */
  organization?: Maybe<Organization>;
  /** An edge for our `OrganizationQuota`. May be used by Relay 1. */
  organizationQuotaEdge?: Maybe<OrganizationQuotasEdge>;
  /** The list of `OrganizationQuota` that was updated by this mutation. */
  organizationQuotas?: Maybe<Array<Maybe<OrganizationQuota>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of updating `OrganizationQuota` list mutation. */
export type UpdateOrganizationQuotasPayloadOrganizationQuotaEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationQuotasOrderBy>>;
};

/** All input for the `updateOrganizations` mutation. */
export type UpdateOrganizationsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `Organization` to be updated by this mutation. */
  organizations?: InputMaybe<
    Array<InputMaybe<UpdateOrganizationsOrganizationInput>>
  >;
};

/** All input for the `Organization` item in `updateOrganizations` mutation. */
export type UpdateOrganizationsOrganizationInput = {
  organizationId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `Organization` being updated. */
  patch: OrganizationPatch;
};

/** The output of updating `Organization` list mutation. */
export type UpdateOrganizationsPayload = {
  __typename?: "UpdateOrganizationsPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** The list of `Organization` that was updated by this mutation. */
  organizations?: Maybe<Array<Maybe<Organization>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of updating `Organization` list mutation. */
export type UpdateOrganizationsPayloadOrganizationEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

/** All input for the `updatePermissionAsElementById` mutation. */
export type UpdatePermissionAsElementByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `PermissionAsElement` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `PermissionAsElement` being updated. */
  patch: PermissionAsElementPatch;
};

/** All input for the `updatePermissionAsElement` mutation. */
export type UpdatePermissionAsElementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** An object where the defined keys will be set on the `PermissionAsElement` being updated. */
  patch: PermissionAsElementPatch;
  permissionId: Scalars["UUID"];
};

/** The output of our update `PermissionAsElement` mutation. */
export type UpdatePermissionAsElementPayload = {
  __typename?: "UpdatePermissionAsElementPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `PermissionAsElement` that was updated by this mutation. */
  permissionAsElement?: Maybe<PermissionAsElement>;
  /** An edge for our `PermissionAsElement`. May be used by Relay 1. */
  permissionAsElementEdge?: Maybe<PermissionAsElementsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UserPermissionAsElement` that is related to this `PermissionAsElement`. */
  userPermission?: Maybe<UserPermissionAsElement>;
};

/** The output of our update `PermissionAsElement` mutation. */
export type UpdatePermissionAsElementPayloadPermissionAsElementEdgeArgs = {
  orderBy?: InputMaybe<Array<PermissionAsElementsOrderBy>>;
};

/** All input for the `updatePermissionAsElements` mutation. */
export type UpdatePermissionAsElementsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `PermissionAsElement` to be updated by this mutation. */
  permissionAsElements?: InputMaybe<
    Array<InputMaybe<UpdatePermissionAsElementsPermissionAsElementInput>>
  >;
};

/** The output of updating `PermissionAsElement` list mutation. */
export type UpdatePermissionAsElementsPayload = {
  __typename?: "UpdatePermissionAsElementsPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** An edge for our `PermissionAsElement`. May be used by Relay 1. */
  permissionAsElementEdge?: Maybe<PermissionAsElementsEdge>;
  /** The list of `PermissionAsElement` that was updated by this mutation. */
  permissionAsElements?: Maybe<Array<Maybe<PermissionAsElement>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UserPermissionAsElement` that is related to this `PermissionAsElement`. */
  userPermission?: Maybe<UserPermissionAsElement>;
};

/** The output of updating `PermissionAsElement` list mutation. */
export type UpdatePermissionAsElementsPayloadPermissionAsElementEdgeArgs = {
  orderBy?: InputMaybe<Array<PermissionAsElementsOrderBy>>;
};

/** All input for the `PermissionAsElement` item in `updatePermissionAsElements` mutation. */
export type UpdatePermissionAsElementsPermissionAsElementInput = {
  /** An object where the defined keys will be set on the `PermissionAsElement` being updated. */
  patch: PermissionAsElementPatch;
  permissionId: Scalars["UUID"];
};

/** All input for the `updatePermissionById` mutation. */
export type UpdatePermissionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Permission` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `Permission` being updated. */
  patch: PermissionPatch;
};

/** All input for the `updatePermission` mutation. */
export type UpdatePermissionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** An object where the defined keys will be set on the `Permission` being updated. */
  patch: PermissionPatch;
  permissionId: Scalars["UUID"];
};

/** The output of our update `Permission` mutation. */
export type UpdatePermissionPayload = {
  __typename?: "UpdatePermissionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Permission` that was updated by this mutation. */
  permission?: Maybe<Permission>;
  /** An edge for our `Permission`. May be used by Relay 1. */
  permissionEdge?: Maybe<PermissionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UserPermission` that is related to this `Permission`. */
  userPermission?: Maybe<UserPermission>;
};

/** The output of our update `Permission` mutation. */
export type UpdatePermissionPayloadPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<PermissionsOrderBy>>;
};

/** All input for the `updatePermissions` mutation. */
export type UpdatePermissionsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `Permission` to be updated by this mutation. */
  permissions?: InputMaybe<Array<InputMaybe<UpdatePermissionsPermissionInput>>>;
};

/** The output of updating `Permission` list mutation. */
export type UpdatePermissionsPayload = {
  __typename?: "UpdatePermissionsPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** An edge for our `Permission`. May be used by Relay 1. */
  permissionEdge?: Maybe<PermissionsEdge>;
  /** The list of `Permission` that was updated by this mutation. */
  permissions?: Maybe<Array<Maybe<Permission>>>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UserPermission` that is related to this `Permission`. */
  userPermission?: Maybe<UserPermission>;
};

/** The output of updating `Permission` list mutation. */
export type UpdatePermissionsPayloadPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<PermissionsOrderBy>>;
};

/** All input for the `Permission` item in `updatePermissions` mutation. */
export type UpdatePermissionsPermissionInput = {
  /** An object where the defined keys will be set on the `Permission` being updated. */
  patch: PermissionPatch;
  permissionId: Scalars["UUID"];
};

/** All input for the `updateRequestByConfigurationId` mutation. */
export type UpdateRequestByConfigurationIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  configurationId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `Request` being updated. */
  patch: RequestPatch;
};

/** All input for the `updateRequestById` mutation. */
export type UpdateRequestByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Request` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `Request` being updated. */
  patch: RequestPatch;
};

/** All input for the `updateRequest` mutation. */
export type UpdateRequestInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** An object where the defined keys will be set on the `Request` being updated. */
  patch: RequestPatch;
  requestId: Scalars["UUID"];
};

/** The output of our update `Request` mutation. */
export type UpdateRequestPayload = {
  __typename?: "UpdateRequestPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Configuration` that is related to this `Request`. */
  configuration?: Maybe<Configuration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Request` that was updated by this mutation. */
  request?: Maybe<Request>;
  /** An edge for our `Request`. May be used by Relay 1. */
  requestEdge?: Maybe<RequestsEdge>;
};

/** The output of our update `Request` mutation. */
export type UpdateRequestPayloadRequestEdgeArgs = {
  orderBy?: InputMaybe<Array<RequestsOrderBy>>;
};

/** All input for the `updateRequests` mutation. */
export type UpdateRequestsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `Request` to be updated by this mutation. */
  requests?: InputMaybe<Array<InputMaybe<UpdateRequestsRequestInput>>>;
};

/** The output of updating `Request` list mutation. */
export type UpdateRequestsPayload = {
  __typename?: "UpdateRequestsPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Configuration` that is related to this `Request`. */
  configuration?: Maybe<Configuration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Request`. May be used by Relay 1. */
  requestEdge?: Maybe<RequestsEdge>;
  /** The list of `Request` that was updated by this mutation. */
  requests?: Maybe<Array<Maybe<Request>>>;
};

/** The output of updating `Request` list mutation. */
export type UpdateRequestsPayloadRequestEdgeArgs = {
  orderBy?: InputMaybe<Array<RequestsOrderBy>>;
};

/** All input for the `Request` item in `updateRequests` mutation. */
export type UpdateRequestsRequestInput = {
  /** An object where the defined keys will be set on the `Request` being updated. */
  patch: RequestPatch;
  requestId: Scalars["UUID"];
};

/** All input for the `updateResourceById` mutation. */
export type UpdateResourceByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Resource` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `Resource` being updated. */
  patch: ResourcePatch;
};

/** All input for the `updateResource` mutation. */
export type UpdateResourceInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  ownerEmail: Scalars["String"];
  /** An object where the defined keys will be set on the `Resource` being updated. */
  patch: ResourcePatch;
  resourceId: Scalars["String"];
};

/** The output of our update `Resource` mutation. */
export type UpdateResourcePayload = {
  __typename?: "UpdateResourcePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Resource` that was updated by this mutation. */
  resource?: Maybe<Resource>;
  /** An edge for our `Resource`. May be used by Relay 1. */
  resourceEdge?: Maybe<ResourcesEdge>;
};

/** The output of our update `Resource` mutation. */
export type UpdateResourcePayloadResourceEdgeArgs = {
  orderBy?: InputMaybe<Array<ResourcesOrderBy>>;
};

/** All input for the `updateResources` mutation. */
export type UpdateResourcesInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `Resource` to be updated by this mutation. */
  resources?: InputMaybe<Array<InputMaybe<UpdateResourcesResourceInput>>>;
};

/** The output of updating `Resource` list mutation. */
export type UpdateResourcesPayload = {
  __typename?: "UpdateResourcesPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Resource`. May be used by Relay 1. */
  resourceEdge?: Maybe<ResourcesEdge>;
  /** The list of `Resource` that was updated by this mutation. */
  resources?: Maybe<Array<Maybe<Resource>>>;
};

/** The output of updating `Resource` list mutation. */
export type UpdateResourcesPayloadResourceEdgeArgs = {
  orderBy?: InputMaybe<Array<ResourcesOrderBy>>;
};

/** All input for the `Resource` item in `updateResources` mutation. */
export type UpdateResourcesResourceInput = {
  /** An object where the defined keys will be set on the `Resource` being updated. */
  patch: ResourcePatch;
  resourceId: Scalars["String"];
};

/** All input for the `updateSignatureById` mutation. */
export type UpdateSignatureByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Signature` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `Signature` being updated. */
  patch: SignaturePatch;
};

/** All input for the `updateSignatureBySignatureHash` mutation. */
export type UpdateSignatureBySignatureHashInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** An object where the defined keys will be set on the `Signature` being updated. */
  patch: SignaturePatch;
  signatureHash: Scalars["String"];
};

/** All input for the `updateSignature` mutation. */
export type UpdateSignatureInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** An object where the defined keys will be set on the `Signature` being updated. */
  patch: SignaturePatch;
  signeeId: Scalars["UUID"];
};

/** The output of our update `Signature` mutation. */
export type UpdateSignaturePayload = {
  __typename?: "UpdateSignaturePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Signature` that was updated by this mutation. */
  signature?: Maybe<Signature>;
  /** An edge for our `Signature`. May be used by Relay 1. */
  signatureEdge?: Maybe<SignaturesEdge>;
  /** Reads a single `Signee` that is related to this `Signature`. */
  signee?: Maybe<Signee>;
};

/** The output of our update `Signature` mutation. */
export type UpdateSignaturePayloadSignatureEdgeArgs = {
  orderBy?: InputMaybe<Array<SignaturesOrderBy>>;
};

/** All input for the `updateSignatures` mutation. */
export type UpdateSignaturesInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `Signature` to be updated by this mutation. */
  signatures?: InputMaybe<Array<InputMaybe<UpdateSignaturesSignatureInput>>>;
};

/** The output of updating `Signature` list mutation. */
export type UpdateSignaturesPayload = {
  __typename?: "UpdateSignaturesPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `Signature`. May be used by Relay 1. */
  signatureEdge?: Maybe<SignaturesEdge>;
  /** The list of `Signature` that was updated by this mutation. */
  signatures?: Maybe<Array<Maybe<Signature>>>;
  /** Reads a single `Signee` that is related to this `Signature`. */
  signee?: Maybe<Signee>;
};

/** The output of updating `Signature` list mutation. */
export type UpdateSignaturesPayloadSignatureEdgeArgs = {
  orderBy?: InputMaybe<Array<SignaturesOrderBy>>;
};

/** All input for the `Signature` item in `updateSignatures` mutation. */
export type UpdateSignaturesSignatureInput = {
  /** An object where the defined keys will be set on the `Signature` being updated. */
  patch: SignaturePatch;
  signeeId: Scalars["UUID"];
};

/** All input for the `updateSigneeById` mutation. */
export type UpdateSigneeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `Signee` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `Signee` being updated. */
  patch: SigneePatch;
};

/** All input for the `updateSigneeByRequestIdAndSigneeEmail` mutation. */
export type UpdateSigneeByRequestIdAndSigneeEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** An object where the defined keys will be set on the `Signee` being updated. */
  patch: SigneePatch;
  requestId: Scalars["UUID"];
  signeeEmail: Scalars["String"];
};

/** All input for the `updateSignee` mutation. */
export type UpdateSigneeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** An object where the defined keys will be set on the `Signee` being updated. */
  patch: SigneePatch;
  signeeId: Scalars["UUID"];
};

/** The output of our update `Signee` mutation. */
export type UpdateSigneePayload = {
  __typename?: "UpdateSigneePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Request` that is related to this `Signee`. */
  request?: Maybe<Request>;
  /** The `Signee` that was updated by this mutation. */
  signee?: Maybe<Signee>;
  /** An edge for our `Signee`. May be used by Relay 1. */
  signeeEdge?: Maybe<SigneesEdge>;
};

/** The output of our update `Signee` mutation. */
export type UpdateSigneePayloadSigneeEdgeArgs = {
  orderBy?: InputMaybe<Array<SigneesOrderBy>>;
};

/** All input for the `updateSignees` mutation. */
export type UpdateSigneesInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `Signee` to be updated by this mutation. */
  signees?: InputMaybe<Array<InputMaybe<UpdateSigneesSigneeInput>>>;
};

/** The output of updating `Signee` list mutation. */
export type UpdateSigneesPayload = {
  __typename?: "UpdateSigneesPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Request` that is related to this `Signee`. */
  request?: Maybe<Request>;
  /** An edge for our `Signee`. May be used by Relay 1. */
  signeeEdge?: Maybe<SigneesEdge>;
  /** The list of `Signee` that was updated by this mutation. */
  signees?: Maybe<Array<Maybe<Signee>>>;
};

/** The output of updating `Signee` list mutation. */
export type UpdateSigneesPayloadSigneeEdgeArgs = {
  orderBy?: InputMaybe<Array<SigneesOrderBy>>;
};

/** All input for the `Signee` item in `updateSignees` mutation. */
export type UpdateSigneesSigneeInput = {
  /** An object where the defined keys will be set on the `Signee` being updated. */
  patch: SigneePatch;
  signeeId: Scalars["UUID"];
};

/** All input for the `updateStateById` mutation. */
export type UpdateStateByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `State` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `State` being updated. */
  patch: StatePatch;
};

/** All input for the `updateState` mutation. */
export type UpdateStateInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  dataRoomId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `State` being updated. */
  patch: StatePatch;
};

/** The output of our update `State` mutation. */
export type UpdateStatePayload = {
  __typename?: "UpdateStatePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `State`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `State` that was updated by this mutation. */
  state?: Maybe<State>;
  /** An edge for our `State`. May be used by Relay 1. */
  stateEdge?: Maybe<StatesEdge>;
};

/** The output of our update `State` mutation. */
export type UpdateStatePayloadStateEdgeArgs = {
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

/** All input for the `updateStates` mutation. */
export type UpdateStatesInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `State` to be updated by this mutation. */
  states?: InputMaybe<Array<InputMaybe<UpdateStatesStateInput>>>;
};

/** The output of updating `State` list mutation. */
export type UpdateStatesPayload = {
  __typename?: "UpdateStatesPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `State`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `State`. May be used by Relay 1. */
  stateEdge?: Maybe<StatesEdge>;
  /** The list of `State` that was updated by this mutation. */
  states?: Maybe<Array<Maybe<State>>>;
};

/** The output of updating `State` list mutation. */
export type UpdateStatesPayloadStateEdgeArgs = {
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

/** All input for the `State` item in `updateStates` mutation. */
export type UpdateStatesStateInput = {
  dataRoomId: Scalars["UUID"];
  /** An object where the defined keys will be set on the `State` being updated. */
  patch: StatePatch;
};

/** All input for the `updateUserAttributeById` mutation. */
export type UpdateUserAttributeByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `UserAttribute` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `UserAttribute` being updated. */
  patch: UserAttributePatch;
};

/** All input for the `updateUserAttribute` mutation. */
export type UpdateUserAttributeInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** An object where the defined keys will be set on the `UserAttribute` being updated. */
  patch: UserAttributePatch;
  userId: Scalars["UUID"];
};

/** The output of our update `UserAttribute` mutation. */
export type UpdateUserAttributePayload = {
  __typename?: "UpdateUserAttributePayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserAttribute`. */
  user?: Maybe<User>;
  /** The `UserAttribute` that was updated by this mutation. */
  userAttribute?: Maybe<UserAttribute>;
  /** An edge for our `UserAttribute`. May be used by Relay 1. */
  userAttributeEdge?: Maybe<UserAttributesEdge>;
};

/** The output of our update `UserAttribute` mutation. */
export type UpdateUserAttributePayloadUserAttributeEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAttributesOrderBy>>;
};

/** All input for the `updateUserByEmail` mutation. */
export type UpdateUserByEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUserById` mutation. */
export type UpdateUserByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `User` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
};

/** All input for the `updateUserConfigByDataRoomIdAndEmail` mutation. */
export type UpdateUserConfigByDataRoomIdAndEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  dataRoomId: Scalars["UUID"];
  email: Scalars["String"];
  /** An object where the defined keys will be set on the `UserConfig` being updated. */
  patch: UserConfigPatch;
};

/** All input for the `updateUserConfigById` mutation. */
export type UpdateUserConfigByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `UserConfig` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `UserConfig` being updated. */
  patch: UserConfigPatch;
};

/** All input for the `updateUserConfig` mutation. */
export type UpdateUserConfigInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** An object where the defined keys will be set on the `UserConfig` being updated. */
  patch: UserConfigPatch;
  userConfigId: Scalars["UUID"];
};

/** The output of our update `UserConfig` mutation. */
export type UpdateUserConfigPayload = {
  __typename?: "UpdateUserConfigPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `UserConfig`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserConfig`. */
  userByEmail?: Maybe<User>;
  /** The `UserConfig` that was updated by this mutation. */
  userConfig?: Maybe<UserConfig>;
  /** An edge for our `UserConfig`. May be used by Relay 1. */
  userConfigEdge?: Maybe<UserConfigsEdge>;
};

/** The output of our update `UserConfig` mutation. */
export type UpdateUserConfigPayloadUserConfigEdgeArgs = {
  orderBy?: InputMaybe<Array<UserConfigsOrderBy>>;
};

/** All input for the `updateUserConfigs` mutation. */
export type UpdateUserConfigsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `UserConfig` to be updated by this mutation. */
  userConfigs?: InputMaybe<Array<InputMaybe<UpdateUserConfigsUserConfigInput>>>;
};

/** The output of updating `UserConfig` list mutation. */
export type UpdateUserConfigsPayload = {
  __typename?: "UpdateUserConfigsPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `UserConfig`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserConfig`. */
  userByEmail?: Maybe<User>;
  /** An edge for our `UserConfig`. May be used by Relay 1. */
  userConfigEdge?: Maybe<UserConfigsEdge>;
  /** The list of `UserConfig` that was updated by this mutation. */
  userConfigs?: Maybe<Array<Maybe<UserConfig>>>;
};

/** The output of updating `UserConfig` list mutation. */
export type UpdateUserConfigsPayloadUserConfigEdgeArgs = {
  orderBy?: InputMaybe<Array<UserConfigsOrderBy>>;
};

/** All input for the `UserConfig` item in `updateUserConfigs` mutation. */
export type UpdateUserConfigsUserConfigInput = {
  /** An object where the defined keys will be set on the `UserConfig` being updated. */
  patch: UserConfigPatch;
  userConfigId: Scalars["UUID"];
};

/** All input for the `updateUser` mutation. */
export type UpdateUserInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
  userId: Scalars["UUID"];
};

/** The output of our update `User` mutation. */
export type UpdateUserPayload = {
  __typename?: "UpdateUserPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was updated by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};

/** The output of our update `User` mutation. */
export type UpdateUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `updateUserPermissionAsElementById` mutation. */
export type UpdateUserPermissionAsElementByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `UserPermissionAsElement` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `UserPermissionAsElement` being updated. */
  patch: UserPermissionAsElementPatch;
};

/** All input for the `updateUserPermissionAsElement` mutation. */
export type UpdateUserPermissionAsElementInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** An object where the defined keys will be set on the `UserPermissionAsElement` being updated. */
  patch: UserPermissionAsElementPatch;
  userPermissionId: Scalars["UUID"];
};

/** The output of our update `UserPermissionAsElement` mutation. */
export type UpdateUserPermissionAsElementPayload = {
  __typename?: "UpdateUserPermissionAsElementPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Modification` that is related to this `UserPermissionAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UserPermissionAsElement` that was updated by this mutation. */
  userPermissionAsElement?: Maybe<UserPermissionAsElement>;
  /** An edge for our `UserPermissionAsElement`. May be used by Relay 1. */
  userPermissionAsElementEdge?: Maybe<UserPermissionAsElementsEdge>;
};

/** All input for the `updateUserPermissionAsElements` mutation. */
export type UpdateUserPermissionAsElementsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `UserPermissionAsElement` to be updated by this mutation. */
  userPermissionAsElements?: InputMaybe<
    Array<
      InputMaybe<UpdateUserPermissionAsElementsUserPermissionAsElementInput>
    >
  >;
};

/** The output of updating `UserPermissionAsElement` list mutation. */
export type UpdateUserPermissionAsElementsPayload = {
  __typename?: "UpdateUserPermissionAsElementsPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Modification` that is related to this `UserPermissionAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `UserPermissionAsElement`. May be used by Relay 1. */
  userPermissionAsElementEdge?: Maybe<UserPermissionAsElementsEdge>;
  /** The list of `UserPermissionAsElement` that was updated by this mutation. */
  userPermissionAsElements?: Maybe<Array<Maybe<UserPermissionAsElement>>>;
};

/** All input for the `UserPermissionAsElement` item in `updateUserPermissionAsElements` mutation. */
export type UpdateUserPermissionAsElementsUserPermissionAsElementInput = {
  /** An object where the defined keys will be set on the `UserPermissionAsElement` being updated. */
  patch: UserPermissionAsElementPatch;
  userPermissionId: Scalars["UUID"];
};

/** All input for the `updateUserPermissionByDataRoomIdAndEmail` mutation. */
export type UpdateUserPermissionByDataRoomIdAndEmailInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  dataRoomId: Scalars["UUID"];
  email: Scalars["String"];
  /** An object where the defined keys will be set on the `UserPermission` being updated. */
  patch: UserPermissionPatch;
};

/** All input for the `updateUserPermissionById` mutation. */
export type UpdateUserPermissionByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `UserPermission` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `UserPermission` being updated. */
  patch: UserPermissionPatch;
};

/** All input for the `updateUserPermission` mutation. */
export type UpdateUserPermissionInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** An object where the defined keys will be set on the `UserPermission` being updated. */
  patch: UserPermissionPatch;
  userPermissionId: Scalars["UUID"];
};

/** The output of our update `UserPermission` mutation. */
export type UpdateUserPermissionPayload = {
  __typename?: "UpdateUserPermissionPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `UserPermission`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserPermission`. */
  userByEmail?: Maybe<User>;
  /** The `UserPermission` that was updated by this mutation. */
  userPermission?: Maybe<UserPermission>;
  /** An edge for our `UserPermission`. May be used by Relay 1. */
  userPermissionEdge?: Maybe<UserPermissionsEdge>;
};

/** The output of our update `UserPermission` mutation. */
export type UpdateUserPermissionPayloadUserPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<UserPermissionsOrderBy>>;
};

/** All input for the `updateUserPermissions` mutation. */
export type UpdateUserPermissionsInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `UserPermission` to be updated by this mutation. */
  userPermissions?: InputMaybe<
    Array<InputMaybe<UpdateUserPermissionsUserPermissionInput>>
  >;
};

/** The output of updating `UserPermission` list mutation. */
export type UpdateUserPermissionsPayload = {
  __typename?: "UpdateUserPermissionsPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `UserPermission`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserPermission`. */
  userByEmail?: Maybe<User>;
  /** An edge for our `UserPermission`. May be used by Relay 1. */
  userPermissionEdge?: Maybe<UserPermissionsEdge>;
  /** The list of `UserPermission` that was updated by this mutation. */
  userPermissions?: Maybe<Array<Maybe<UserPermission>>>;
};

/** The output of updating `UserPermission` list mutation. */
export type UpdateUserPermissionsPayloadUserPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<UserPermissionsOrderBy>>;
};

/** All input for the `UserPermission` item in `updateUserPermissions` mutation. */
export type UpdateUserPermissionsUserPermissionInput = {
  /** An object where the defined keys will be set on the `UserPermission` being updated. */
  patch: UserPermissionPatch;
  userPermissionId: Scalars["UUID"];
};

/** All input for the `updateUserQuotaById` mutation. */
export type UpdateUserQuotaByIdInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The globally unique `ID` which will identify a single `UserQuota` to be updated. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `UserQuota` being updated. */
  patch: UserQuotaPatch;
};

/** All input for the `updateUserQuota` mutation. */
export type UpdateUserQuotaInput = {
  /**
   * An arbitrary string value with no semantic meaning. Will be included in the
   * payload verbatim. May be used to track mutations by the client.
   */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  /** An object where the defined keys will be set on the `UserQuota` being updated. */
  patch: UserQuotaPatch;
};

/** The output of our update `UserQuota` mutation. */
export type UpdateUserQuotaPayload = {
  __typename?: "UpdateUserQuotaPayload";
  /**
   * The exact same `clientMutationId` that was provided in the mutation input,
   * unchanged and unused. May be used by a client to track mutations.
   */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserQuota`. */
  userByEmail?: Maybe<User>;
  /** The `UserQuota` that was updated by this mutation. */
  userQuota?: Maybe<UserQuota>;
  /** An edge for our `UserQuota`. May be used by Relay 1. */
  userQuotaEdge?: Maybe<UserQuotasEdge>;
};

/** The output of our update `UserQuota` mutation. */
export type UpdateUserQuotaPayloadUserQuotaEdgeArgs = {
  orderBy?: InputMaybe<Array<UserQuotasOrderBy>>;
};

/** All input for the `updateUserQuotas` mutation. */
export type UpdateUserQuotasInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `UserQuota` to be updated by this mutation. */
  userQuotas?: InputMaybe<Array<InputMaybe<UpdateUserQuotasUserQuotaInput>>>;
};

/** The output of updating `UserQuota` list mutation. */
export type UpdateUserQuotasPayload = {
  __typename?: "UpdateUserQuotasPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserQuota`. */
  userByEmail?: Maybe<User>;
  /** An edge for our `UserQuota`. May be used by Relay 1. */
  userQuotaEdge?: Maybe<UserQuotasEdge>;
  /** The list of `UserQuota` that was updated by this mutation. */
  userQuotas?: Maybe<Array<Maybe<UserQuota>>>;
};

/** The output of updating `UserQuota` list mutation. */
export type UpdateUserQuotasPayloadUserQuotaEdgeArgs = {
  orderBy?: InputMaybe<Array<UserQuotasOrderBy>>;
};

/** All input for the `UserQuota` item in `updateUserQuotas` mutation. */
export type UpdateUserQuotasUserQuotaInput = {
  email: Scalars["String"];
  /** An object where the defined keys will be set on the `UserQuota` being updated. */
  patch: UserQuotaPatch;
};

/** All input for the `updateUsers` mutation. */
export type UpdateUsersInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** A list of `User` to be updated by this mutation. */
  users?: InputMaybe<Array<InputMaybe<UpdateUsersUserInput>>>;
};

/** The output of updating `User` list mutation. */
export type UpdateUsersPayload = {
  __typename?: "UpdateUsersPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
  /** The list of `User` that was updated by this mutation. */
  users?: Maybe<Array<Maybe<User>>>;
};

/** The output of updating `User` list mutation. */
export type UpdateUsersPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the `User` item in `updateUsers` mutation. */
export type UpdateUsersUserInput = {
  /** An object where the defined keys will be set on the `User` being updated. */
  patch: UserPatch;
  userId: Scalars["UUID"];
};

/** All input for the upsert `AttestationSpecificationAsElement` mutation. */
export type UpsertAttestationSpecificationAsElementInput = {
  /** The `AttestationSpecificationAsElement` to be upserted by this mutation. */
  attestationSpecificationAsElement: AttestationSpecificationAsElementInput;
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our upsert `AttestationSpecificationAsElement` mutation. */
export type UpsertAttestationSpecificationAsElementPayload = {
  __typename?: "UpsertAttestationSpecificationAsElementPayload";
  /** The `AttestationSpecificationAsElement` that was upserted by this mutation. */
  attestationSpecificationAsElement?: Maybe<AttestationSpecificationAsElement>;
  /** An edge for our `AttestationSpecificationAsElement`. May be used by Relay 1. */
  attestationSpecificationAsElementEdge?: Maybe<AttestationSpecificationAsElementsEdge>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Modification` that is related to this `AttestationSpecificationAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `AttestationSpecificationAsElement` mutation. */
export type UpsertAttestationSpecificationAsElementPayloadAttestationSpecificationAsElementEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AttestationSpecificationAsElementsOrderBy>>;
  };

/** All input for the upsert `AuthenticationMethodAsElement` mutation. */
export type UpsertAuthenticationMethodAsElementInput = {
  /** The `AuthenticationMethodAsElement` to be upserted by this mutation. */
  authenticationMethodAsElement: AuthenticationMethodAsElementInput;
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
};

/** The output of our upsert `AuthenticationMethodAsElement` mutation. */
export type UpsertAuthenticationMethodAsElementPayload = {
  __typename?: "UpsertAuthenticationMethodAsElementPayload";
  /** The `AuthenticationMethodAsElement` that was upserted by this mutation. */
  authenticationMethodAsElement?: Maybe<AuthenticationMethodAsElement>;
  /** An edge for our `AuthenticationMethodAsElement`. May be used by Relay 1. */
  authenticationMethodAsElementEdge?: Maybe<AuthenticationMethodAsElementsEdge>;
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Modification` that is related to this `AuthenticationMethodAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `AuthenticationMethodAsElement` mutation. */
export type UpsertAuthenticationMethodAsElementPayloadAuthenticationMethodAsElementEdgeArgs =
  {
    orderBy?: InputMaybe<Array<AuthenticationMethodAsElementsOrderBy>>;
  };

/** All input for the upsert `ComputeNodeAsElement` mutation. */
export type UpsertComputeNodeAsElementInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `ComputeNodeAsElement` to be upserted by this mutation. */
  computeNodeAsElement: ComputeNodeAsElementInput;
};

/** The output of our upsert `ComputeNodeAsElement` mutation. */
export type UpsertComputeNodeAsElementPayload = {
  __typename?: "UpsertComputeNodeAsElementPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `ComputeNodeAsElement` that was upserted by this mutation. */
  computeNodeAsElement?: Maybe<ComputeNodeAsElement>;
  /** An edge for our `ComputeNodeAsElement`. May be used by Relay 1. */
  computeNodeAsElementEdge?: Maybe<ComputeNodeAsElementsEdge>;
  /** Reads a single `Modification` that is related to this `ComputeNodeAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `ComputeNodeAsElement` mutation. */
export type UpsertComputeNodeAsElementPayloadComputeNodeAsElementEdgeArgs = {
  orderBy?: InputMaybe<Array<ComputeNodeAsElementsOrderBy>>;
};

/** All input for the upsert `ComputeNode` mutation. */
export type UpsertComputeNodeInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `ComputeNode` to be upserted by this mutation. */
  computeNode: ComputeNodeInput;
};

/** The output of our upsert `ComputeNode` mutation. */
export type UpsertComputeNodePayload = {
  __typename?: "UpsertComputeNodePayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `ComputeNode` that was upserted by this mutation. */
  computeNode?: Maybe<ComputeNode>;
  /** An edge for our `ComputeNode`. May be used by Relay 1. */
  computeNodeEdge?: Maybe<ComputeNodesEdge>;
  /** Reads a single `DataRoom` that is related to this `ComputeNode`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `ComputeNode` mutation. */
export type UpsertComputeNodePayloadComputeNodeEdgeArgs = {
  orderBy?: InputMaybe<Array<ComputeNodesOrderBy>>;
};

/** All input for the upsert `Config` mutation. */
export type UpsertConfigInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Config` to be upserted by this mutation. */
  config: ConfigInput;
};

/** The output of our upsert `Config` mutation. */
export type UpsertConfigPayload = {
  __typename?: "UpsertConfigPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Config` that was upserted by this mutation. */
  config?: Maybe<Config>;
  /** An edge for our `Config`. May be used by Relay 1. */
  configEdge?: Maybe<ConfigsEdge>;
  /** Reads a single `DataRoom` that is related to this `Config`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `Config` mutation. */
export type UpsertConfigPayloadConfigEdgeArgs = {
  orderBy?: InputMaybe<Array<ConfigsOrderBy>>;
};

/** All input for the upsert `Configuration` mutation. */
export type UpsertConfigurationInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Configuration` to be upserted by this mutation. */
  configuration: ConfigurationInput;
};

/** The output of our upsert `Configuration` mutation. */
export type UpsertConfigurationPayload = {
  __typename?: "UpsertConfigurationPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Configuration` that was upserted by this mutation. */
  configuration?: Maybe<Configuration>;
  /** An edge for our `Configuration`. May be used by Relay 1. */
  configurationEdge?: Maybe<ConfigurationsEdge>;
  /** Reads a single `DataRoom` that is related to this `Configuration`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `Configuration` mutation. */
export type UpsertConfigurationPayloadConfigurationEdgeArgs = {
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy>>;
};

/** All input for the upsert `DataRoom` mutation. */
export type UpsertDataRoomInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `DataRoom` to be upserted by this mutation. */
  dataRoom: DataRoomInput;
};

/** The output of our upsert `DataRoom` mutation. */
export type UpsertDataRoomPayload = {
  __typename?: "UpsertDataRoomPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `DataRoom` that was upserted by this mutation. */
  dataRoom?: Maybe<DataRoom>;
  /** An edge for our `DataRoom`. May be used by Relay 1. */
  dataRoomEdge?: Maybe<DataRoomsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `DataRoom`. */
  userByOwnerEmail?: Maybe<User>;
};

/** The output of our upsert `DataRoom` mutation. */
export type UpsertDataRoomPayloadDataRoomEdgeArgs = {
  orderBy?: InputMaybe<Array<DataRoomsOrderBy>>;
};

/** All input for the upsert `DatasetLink` mutation. */
export type UpsertDatasetLinkInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `DatasetLink` to be upserted by this mutation. */
  datasetLink: DatasetLinkInput;
};

/** The output of our upsert `DatasetLink` mutation. */
export type UpsertDatasetLinkPayload = {
  __typename?: "UpsertDatasetLinkPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `ComputeNode` that is related to this `DatasetLink`. */
  computeNode?: Maybe<ComputeNode>;
  /** The `DatasetLink` that was upserted by this mutation. */
  datasetLink?: Maybe<DatasetLink>;
  /** An edge for our `DatasetLink`. May be used by Relay 1. */
  datasetLinkEdge?: Maybe<DatasetLinksEdge>;
  /** Reads a single `DatasetMeta` that is related to this `DatasetLink`. */
  datasetMeta?: Maybe<DatasetMeta>;
  /** Reads a single `DatasetStat` that is related to this `DatasetLink`. */
  datasetStats?: Maybe<DatasetStat>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `DatasetLink` mutation. */
export type UpsertDatasetLinkPayloadDatasetLinkEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetLinksOrderBy>>;
};

/** All input for the upsert `DatasetMeta` mutation. */
export type UpsertDatasetMetaInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `DatasetMeta` to be upserted by this mutation. */
  datasetMeta: DatasetMetaInput;
};

/** The output of our upsert `DatasetMeta` mutation. */
export type UpsertDatasetMetaPayload = {
  __typename?: "UpsertDatasetMetaPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `DatasetMeta` that was upserted by this mutation. */
  datasetMeta?: Maybe<DatasetMeta>;
  /** An edge for our `DatasetMeta`. May be used by Relay 1. */
  datasetMetaEdge?: Maybe<DatasetMetasEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `DatasetMeta` mutation. */
export type UpsertDatasetMetaPayloadDatasetMetaEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetMetasOrderBy>>;
};

/** All input for the upsert `DatasetStat` mutation. */
export type UpsertDatasetStatInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `DatasetStat` to be upserted by this mutation. */
  datasetStat: DatasetStatInput;
};

/** The output of our upsert `DatasetStat` mutation. */
export type UpsertDatasetStatPayload = {
  __typename?: "UpsertDatasetStatPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DatasetMeta` that is related to this `DatasetStat`. */
  datasetMeta?: Maybe<DatasetMeta>;
  /** The `DatasetStat` that was upserted by this mutation. */
  datasetStat?: Maybe<DatasetStat>;
  /** An edge for our `DatasetStat`. May be used by Relay 1. */
  datasetStatEdge?: Maybe<DatasetStatsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `DatasetStat` mutation. */
export type UpsertDatasetStatPayloadDatasetStatEdgeArgs = {
  orderBy?: InputMaybe<Array<DatasetStatsOrderBy>>;
};

/** All input for the upsert `Domain` mutation. */
export type UpsertDomainInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Domain` to be upserted by this mutation. */
  domain: DomainInput;
};

/** The output of our upsert `Domain` mutation. */
export type UpsertDomainPayload = {
  __typename?: "UpsertDomainPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Domain` that was upserted by this mutation. */
  domain?: Maybe<Domain>;
  /** An edge for our `Domain`. May be used by Relay 1. */
  domainEdge?: Maybe<DomainsEdge>;
  /** Reads a single `Organization` that is related to this `Domain`. */
  organization?: Maybe<Organization>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `Domain`. */
  user?: Maybe<User>;
};

/** The output of our upsert `Domain` mutation. */
export type UpsertDomainPayloadDomainEdgeArgs = {
  orderBy?: InputMaybe<Array<DomainsOrderBy>>;
};

/** All input for the upsert `ExecutionTime` mutation. */
export type UpsertExecutionTimeInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `ExecutionTime` to be upserted by this mutation. */
  executionTime: ExecutionTimeInput;
};

/** The output of our upsert `ExecutionTime` mutation. */
export type UpsertExecutionTimePayload = {
  __typename?: "UpsertExecutionTimePayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `ExecutionTime` that was upserted by this mutation. */
  executionTime?: Maybe<ExecutionTime>;
  /** An edge for our `ExecutionTime`. May be used by Relay 1. */
  executionTimeEdge?: Maybe<ExecutionTimesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `ExecutionTime` mutation. */
export type UpsertExecutionTimePayloadExecutionTimeEdgeArgs = {
  orderBy?: InputMaybe<Array<ExecutionTimesOrderBy>>;
};

/** All input for the upsert `Job` mutation. */
export type UpsertJobInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Job` to be upserted by this mutation. */
  job: JobInput;
};

/** The output of our upsert `Job` mutation. */
export type UpsertJobPayload = {
  __typename?: "UpsertJobPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `ComputeNode` that is related to this `Job`. */
  computeNode?: Maybe<ComputeNode>;
  /** The `Job` that was upserted by this mutation. */
  job?: Maybe<Job>;
  /** An edge for our `Job`. May be used by Relay 1. */
  jobEdge?: Maybe<JobsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `Job` mutation. */
export type UpsertJobPayloadJobEdgeArgs = {
  orderBy?: InputMaybe<Array<JobsOrderBy>>;
};

/** All input for the upsert `Lock` mutation. */
export type UpsertLockInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Lock` to be upserted by this mutation. */
  lock: LockInput;
};

/** The output of our upsert `Lock` mutation. */
export type UpsertLockPayload = {
  __typename?: "UpsertLockPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `Lock`. */
  dataRoom?: Maybe<DataRoom>;
  /** The `Lock` that was upserted by this mutation. */
  lock?: Maybe<Lock>;
  /** An edge for our `Lock`. May be used by Relay 1. */
  lockEdge?: Maybe<LocksEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `Lock` mutation. */
export type UpsertLockPayloadLockEdgeArgs = {
  orderBy?: InputMaybe<Array<LocksOrderBy>>;
};

/** All input for the upsert `Modification` mutation. */
export type UpsertModificationInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Modification` to be upserted by this mutation. */
  modification: ModificationInput;
};

/** The output of our upsert `Modification` mutation. */
export type UpsertModificationPayload = {
  __typename?: "UpsertModificationPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Configuration` that is related to this `Modification`. */
  configuration?: Maybe<Configuration>;
  /** The `Modification` that was upserted by this mutation. */
  modification?: Maybe<Modification>;
  /** An edge for our `Modification`. May be used by Relay 1. */
  modificationEdge?: Maybe<ModificationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `Modification` mutation. */
export type UpsertModificationPayloadModificationEdgeArgs = {
  orderBy?: InputMaybe<Array<ModificationsOrderBy>>;
};

/** All input for the upsert `OrganizationAttribute` mutation. */
export type UpsertOrganizationAttributeInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `OrganizationAttribute` to be upserted by this mutation. */
  organizationAttribute: OrganizationAttributeInput;
};

/** The output of our upsert `OrganizationAttribute` mutation. */
export type UpsertOrganizationAttributePayload = {
  __typename?: "UpsertOrganizationAttributePayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `OrganizationAttribute`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationAttribute` that was upserted by this mutation. */
  organizationAttribute?: Maybe<OrganizationAttribute>;
  /** An edge for our `OrganizationAttribute`. May be used by Relay 1. */
  organizationAttributeEdge?: Maybe<OrganizationAttributesEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `OrganizationAttribute` mutation. */
export type UpsertOrganizationAttributePayloadOrganizationAttributeEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationAttributesOrderBy>>;
};

/** All input for the upsert `Organization` mutation. */
export type UpsertOrganizationInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Organization` to be upserted by this mutation. */
  organization: OrganizationInput;
};

/** All input for the upsert `OrganizationLogo` mutation. */
export type UpsertOrganizationLogoInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `OrganizationLogo` to be upserted by this mutation. */
  organizationLogo: OrganizationLogoInput;
};

/** The output of our upsert `OrganizationLogo` mutation. */
export type UpsertOrganizationLogoPayload = {
  __typename?: "UpsertOrganizationLogoPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `OrganizationLogo`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationLogo` that was upserted by this mutation. */
  organizationLogo?: Maybe<OrganizationLogo>;
  /** An edge for our `OrganizationLogo`. May be used by Relay 1. */
  organizationLogoEdge?: Maybe<OrganizationLogosEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `OrganizationLogo` mutation. */
export type UpsertOrganizationLogoPayloadOrganizationLogoEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationLogosOrderBy>>;
};

/** All input for the upsert `OrganizationMembership` mutation. */
export type UpsertOrganizationMembershipInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `OrganizationMembership` to be upserted by this mutation. */
  organizationMembership: OrganizationMembershipInput;
};

/** The output of our upsert `OrganizationMembership` mutation. */
export type UpsertOrganizationMembershipPayload = {
  __typename?: "UpsertOrganizationMembershipPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `OrganizationMembership`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationMembership` that was upserted by this mutation. */
  organizationMembership?: Maybe<OrganizationMembership>;
  /** An edge for our `OrganizationMembership`. May be used by Relay 1. */
  organizationMembershipEdge?: Maybe<OrganizationMembershipsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `OrganizationMembership`. */
  user?: Maybe<User>;
};

/** The output of our upsert `OrganizationMembership` mutation. */
export type UpsertOrganizationMembershipPayloadOrganizationMembershipEdgeArgs =
  {
    orderBy?: InputMaybe<Array<OrganizationMembershipsOrderBy>>;
  };

/** The output of our upsert `Organization` mutation. */
export type UpsertOrganizationPayload = {
  __typename?: "UpsertOrganizationPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Organization` that was upserted by this mutation. */
  organization?: Maybe<Organization>;
  /** An edge for our `Organization`. May be used by Relay 1. */
  organizationEdge?: Maybe<OrganizationsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `Organization` mutation. */
export type UpsertOrganizationPayloadOrganizationEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationsOrderBy>>;
};

/** All input for the upsert `OrganizationQuota` mutation. */
export type UpsertOrganizationQuotaInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `OrganizationQuota` to be upserted by this mutation. */
  organizationQuota: OrganizationQuotaInput;
};

/** The output of our upsert `OrganizationQuota` mutation. */
export type UpsertOrganizationQuotaPayload = {
  __typename?: "UpsertOrganizationQuotaPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `OrganizationQuota`. */
  organization?: Maybe<Organization>;
  /** The `OrganizationQuota` that was upserted by this mutation. */
  organizationQuota?: Maybe<OrganizationQuota>;
  /** An edge for our `OrganizationQuota`. May be used by Relay 1. */
  organizationQuotaEdge?: Maybe<OrganizationQuotasEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
};

/** The output of our upsert `OrganizationQuota` mutation. */
export type UpsertOrganizationQuotaPayloadOrganizationQuotaEdgeArgs = {
  orderBy?: InputMaybe<Array<OrganizationQuotasOrderBy>>;
};

/** All input for the upsert `PermissionAsElement` mutation. */
export type UpsertPermissionAsElementInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `PermissionAsElement` to be upserted by this mutation. */
  permissionAsElement: PermissionAsElementInput;
};

/** The output of our upsert `PermissionAsElement` mutation. */
export type UpsertPermissionAsElementPayload = {
  __typename?: "UpsertPermissionAsElementPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `PermissionAsElement` that was upserted by this mutation. */
  permissionAsElement?: Maybe<PermissionAsElement>;
  /** An edge for our `PermissionAsElement`. May be used by Relay 1. */
  permissionAsElementEdge?: Maybe<PermissionAsElementsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UserPermissionAsElement` that is related to this `PermissionAsElement`. */
  userPermission?: Maybe<UserPermissionAsElement>;
};

/** The output of our upsert `PermissionAsElement` mutation. */
export type UpsertPermissionAsElementPayloadPermissionAsElementEdgeArgs = {
  orderBy?: InputMaybe<Array<PermissionAsElementsOrderBy>>;
};

/** All input for the upsert `Permission` mutation. */
export type UpsertPermissionInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Permission` to be upserted by this mutation. */
  permission: PermissionInput;
};

/** The output of our upsert `Permission` mutation. */
export type UpsertPermissionPayload = {
  __typename?: "UpsertPermissionPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** The `Permission` that was upserted by this mutation. */
  permission?: Maybe<Permission>;
  /** An edge for our `Permission`. May be used by Relay 1. */
  permissionEdge?: Maybe<PermissionsEdge>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `UserPermission` that is related to this `Permission`. */
  userPermission?: Maybe<UserPermission>;
};

/** The output of our upsert `Permission` mutation. */
export type UpsertPermissionPayloadPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<PermissionsOrderBy>>;
};

/** All input for the upsert `Request` mutation. */
export type UpsertRequestInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Request` to be upserted by this mutation. */
  request: RequestInput;
};

/** The output of our upsert `Request` mutation. */
export type UpsertRequestPayload = {
  __typename?: "UpsertRequestPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Configuration` that is related to this `Request`. */
  configuration?: Maybe<Configuration>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Request` that was upserted by this mutation. */
  request?: Maybe<Request>;
  /** An edge for our `Request`. May be used by Relay 1. */
  requestEdge?: Maybe<RequestsEdge>;
};

/** The output of our upsert `Request` mutation. */
export type UpsertRequestPayloadRequestEdgeArgs = {
  orderBy?: InputMaybe<Array<RequestsOrderBy>>;
};

/** All input for the upsert `Resource` mutation. */
export type UpsertResourceInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Resource` to be upserted by this mutation. */
  resource: ResourceInput;
};

/** The output of our upsert `Resource` mutation. */
export type UpsertResourcePayload = {
  __typename?: "UpsertResourcePayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Resource` that was upserted by this mutation. */
  resource?: Maybe<Resource>;
  /** An edge for our `Resource`. May be used by Relay 1. */
  resourceEdge?: Maybe<ResourcesEdge>;
};

/** The output of our upsert `Resource` mutation. */
export type UpsertResourcePayloadResourceEdgeArgs = {
  orderBy?: InputMaybe<Array<ResourcesOrderBy>>;
};

/** All input for the upsert `Signature` mutation. */
export type UpsertSignatureInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Signature` to be upserted by this mutation. */
  signature: SignatureInput;
};

/** The output of our upsert `Signature` mutation. */
export type UpsertSignaturePayload = {
  __typename?: "UpsertSignaturePayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `Signature` that was upserted by this mutation. */
  signature?: Maybe<Signature>;
  /** An edge for our `Signature`. May be used by Relay 1. */
  signatureEdge?: Maybe<SignaturesEdge>;
  /** Reads a single `Signee` that is related to this `Signature`. */
  signee?: Maybe<Signee>;
};

/** The output of our upsert `Signature` mutation. */
export type UpsertSignaturePayloadSignatureEdgeArgs = {
  orderBy?: InputMaybe<Array<SignaturesOrderBy>>;
};

/** All input for the upsert `Signee` mutation. */
export type UpsertSigneeInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `Signee` to be upserted by this mutation. */
  signee: SigneeInput;
};

/** The output of our upsert `Signee` mutation. */
export type UpsertSigneePayload = {
  __typename?: "UpsertSigneePayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `Request` that is related to this `Signee`. */
  request?: Maybe<Request>;
  /** The `Signee` that was upserted by this mutation. */
  signee?: Maybe<Signee>;
  /** An edge for our `Signee`. May be used by Relay 1. */
  signeeEdge?: Maybe<SigneesEdge>;
};

/** The output of our upsert `Signee` mutation. */
export type UpsertSigneePayloadSigneeEdgeArgs = {
  orderBy?: InputMaybe<Array<SigneesOrderBy>>;
};

/** All input for the upsert `State` mutation. */
export type UpsertStateInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `State` to be upserted by this mutation. */
  state: StateInput;
};

/** The output of our upsert `State` mutation. */
export type UpsertStatePayload = {
  __typename?: "UpsertStatePayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `State`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `State` that was upserted by this mutation. */
  state?: Maybe<State>;
  /** An edge for our `State`. May be used by Relay 1. */
  stateEdge?: Maybe<StatesEdge>;
};

/** The output of our upsert `State` mutation. */
export type UpsertStatePayloadStateEdgeArgs = {
  orderBy?: InputMaybe<Array<StatesOrderBy>>;
};

/** All input for the upsert `UserAttribute` mutation. */
export type UpsertUserAttributeInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `UserAttribute` to be upserted by this mutation. */
  userAttribute: UserAttributeInput;
};

/** The output of our upsert `UserAttribute` mutation. */
export type UpsertUserAttributePayload = {
  __typename?: "UpsertUserAttributePayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserAttribute`. */
  user?: Maybe<User>;
  /** The `UserAttribute` that was upserted by this mutation. */
  userAttribute?: Maybe<UserAttribute>;
  /** An edge for our `UserAttribute`. May be used by Relay 1. */
  userAttributeEdge?: Maybe<UserAttributesEdge>;
};

/** The output of our upsert `UserAttribute` mutation. */
export type UpsertUserAttributePayloadUserAttributeEdgeArgs = {
  orderBy?: InputMaybe<Array<UserAttributesOrderBy>>;
};

/** All input for the upsert `UserConfig` mutation. */
export type UpsertUserConfigInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `UserConfig` to be upserted by this mutation. */
  userConfig: UserConfigInput;
};

/** The output of our upsert `UserConfig` mutation. */
export type UpsertUserConfigPayload = {
  __typename?: "UpsertUserConfigPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `UserConfig`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserConfig`. */
  userByEmail?: Maybe<User>;
  /** The `UserConfig` that was upserted by this mutation. */
  userConfig?: Maybe<UserConfig>;
  /** An edge for our `UserConfig`. May be used by Relay 1. */
  userConfigEdge?: Maybe<UserConfigsEdge>;
};

/** The output of our upsert `UserConfig` mutation. */
export type UpsertUserConfigPayloadUserConfigEdgeArgs = {
  orderBy?: InputMaybe<Array<UserConfigsOrderBy>>;
};

/** All input for the upsert `User` mutation. */
export type UpsertUserInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `User` to be upserted by this mutation. */
  user: UserInput;
};

/** The output of our upsert `User` mutation. */
export type UpsertUserPayload = {
  __typename?: "UpsertUserPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `User` that was upserted by this mutation. */
  user?: Maybe<User>;
  /** An edge for our `User`. May be used by Relay 1. */
  userEdge?: Maybe<UsersEdge>;
};

/** The output of our upsert `User` mutation. */
export type UpsertUserPayloadUserEdgeArgs = {
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** All input for the upsert `UserPermissionAsElement` mutation. */
export type UpsertUserPermissionAsElementInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `UserPermissionAsElement` to be upserted by this mutation. */
  userPermissionAsElement: UserPermissionAsElementInput;
};

/** The output of our upsert `UserPermissionAsElement` mutation. */
export type UpsertUserPermissionAsElementPayload = {
  __typename?: "UpsertUserPermissionAsElementPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `Modification` that is related to this `UserPermissionAsElement`. */
  modification?: Maybe<Modification>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** The `UserPermissionAsElement` that was upserted by this mutation. */
  userPermissionAsElement?: Maybe<UserPermissionAsElement>;
  /** An edge for our `UserPermissionAsElement`. May be used by Relay 1. */
  userPermissionAsElementEdge?: Maybe<UserPermissionAsElementsEdge>;
};

/** All input for the upsert `UserPermission` mutation. */
export type UpsertUserPermissionInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `UserPermission` to be upserted by this mutation. */
  userPermission: UserPermissionInput;
};

/** The output of our upsert `UserPermission` mutation. */
export type UpsertUserPermissionPayload = {
  __typename?: "UpsertUserPermissionPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Reads a single `DataRoom` that is related to this `UserPermission`. */
  dataRoom?: Maybe<DataRoom>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserPermission`. */
  userByEmail?: Maybe<User>;
  /** The `UserPermission` that was upserted by this mutation. */
  userPermission?: Maybe<UserPermission>;
  /** An edge for our `UserPermission`. May be used by Relay 1. */
  userPermissionEdge?: Maybe<UserPermissionsEdge>;
};

/** The output of our upsert `UserPermission` mutation. */
export type UpsertUserPermissionPayloadUserPermissionEdgeArgs = {
  orderBy?: InputMaybe<Array<UserPermissionsOrderBy>>;
};

/** All input for the upsert `UserQuota` mutation. */
export type UpsertUserQuotaInput = {
  /** An arbitrary string value with no semantic meaning. Will be included in the payload verbatim. May be used to track mutations by the client. */
  clientMutationId?: InputMaybe<Scalars["String"]>;
  /** The `UserQuota` to be upserted by this mutation. */
  userQuota: UserQuotaInput;
};

/** The output of our upsert `UserQuota` mutation. */
export type UpsertUserQuotaPayload = {
  __typename?: "UpsertUserQuotaPayload";
  /** The exact same `clientMutationId` that was provided in the mutation input, unchanged and unused. May be used by a client to track mutations. */
  clientMutationId?: Maybe<Scalars["String"]>;
  /** Our root query field type. Allows us to run any query from our mutation payload. */
  query?: Maybe<Query>;
  /** Reads a single `User` that is related to this `UserQuota`. */
  userByEmail?: Maybe<User>;
  /** The `UserQuota` that was upserted by this mutation. */
  userQuota?: Maybe<UserQuota>;
  /** An edge for our `UserQuota`. May be used by Relay 1. */
  userQuotaEdge?: Maybe<UserQuotasEdge>;
};

/** The output of our upsert `UserQuota` mutation. */
export type UpsertUserQuotaPayloadUserQuotaEdgeArgs = {
  orderBy?: InputMaybe<Array<UserQuotasOrderBy>>;
};

export type User = Node & {
  __typename?: "User";
  createdAt: Scalars["Datetime"];
  createdByEmail?: Maybe<Scalars["String"]>;
  /** Reads and enables pagination through a set of `DataRoom`. */
  dataRooms: DataRoomsConnection;
  datasets: Array<Dataset>;
  domain: Scalars["String"];
  /** Reads a single `Domain` that is related to this `User`. */
  domainByDomain?: Maybe<Domain>;
  email?: Maybe<Scalars["String"]>;
  emailVerified?: Maybe<Scalars["Boolean"]>;
  familyName?: Maybe<Scalars["String"]>;
  givenName?: Maybe<Scalars["String"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  name?: Maybe<Scalars["String"]>;
  nickname?: Maybe<Scalars["String"]>;
  /** Reads a single `Organization` that is related to this `User`. */
  organizationByDomain?: Maybe<Organization>;
  /** Reads and enables pagination through a set of `OrganizationMembership`. */
  organizationMemberships: OrganizationMembershipsConnection;
  picture?: Maybe<Scalars["String"]>;
  publishedDataRooms: Array<PublishedDataRoom>;
  updatedAt: Scalars["Datetime"];
  updatedByEmail?: Maybe<Scalars["String"]>;
  upstreamUserId?: Maybe<Scalars["String"]>;
  /** Reads a single `UserAttribute` that is related to this `User`. */
  userAttribute?: Maybe<UserAttribute>;
  /** Reads and enables pagination through a set of `UserConfig`. */
  userConfigs: UserConfigsConnection;
  userId: Scalars["UUID"];
  /** Reads and enables pagination through a set of `UserPermission`. */
  userPermissions: UserPermissionsConnection;
  /** Reads a single `UserQuota` that is related to this `User`. */
  userQuota?: Maybe<UserQuota>;
};

export type UserDataRoomsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DataRoomCondition>;
  filter?: InputMaybe<DataRoomFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DataRoomsOrderBy>>;
};

export type UserOrganizationMembershipsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<OrganizationMembershipCondition>;
  filter?: InputMaybe<OrganizationMembershipFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrganizationMembershipsOrderBy>>;
};

export type UserUserConfigsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<UserConfigCondition>;
  filter?: InputMaybe<UserConfigFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserConfigsOrderBy>>;
};

export type UserUserPermissionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<UserPermissionCondition>;
  filter?: InputMaybe<UserPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserPermissionsOrderBy>>;
};

export type UserAggregates = {
  __typename?: "UserAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UserDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

export type UserAttribute = Node & {
  __typename?: "UserAttribute";
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  isDemoUser?: Maybe<Scalars["Boolean"]>;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
  /** Reads a single `User` that is related to this `UserAttribute`. */
  user?: Maybe<User>;
  userId: Scalars["UUID"];
};

export type UserAttributeAggregates = {
  __typename?: "UserAttributeAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UserAttributeDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/**
 * A condition to be used against `UserAttribute` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserAttributeCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `isDemoUser` field. */
  isDemoUser?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]>;
};

export type UserAttributeDistinct = {
  __typename?: "UserAttributeDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `isDemoUser` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  isDemoUser: Array<Maybe<Scalars["Boolean"]>>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
  /** Distinct `userId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  userId: Array<Scalars["UUID"]>;
};

export type UserAttributeDistinctCountAggregates = {
  __typename?: "UserAttributeDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of isDemoUser across the matching connection */
  isDemoUser?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `UserAttribute` object types. All fields are combined with a logical ‘and.’ */
export type UserAttributeFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserAttributeFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isDemoUser` field. */
  isDemoUser?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserAttributeFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserAttributeFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `user` relation. */
  user?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
};

/** An input for mutations affecting `UserAttribute` */
export type UserAttributeInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  isDemoUser?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<UserAttributesUserIdFkeyInput>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type UserAttributeNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `userAttribute` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type UserAttributeNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `userAttribute` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type UserAttributeOnUserAttributeForUserAttributesUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `user` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UserPatch;
  };

/** The fields on `userAttribute` to look up the row to update. */
export type UserAttributeOnUserAttributeForUserAttributesUserIdFkeyUsingUserAttributesPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `userAttribute` being updated. */
    patch: UpdateUserAttributeOnUserAttributeForUserAttributesUserIdFkeyPatch;
    userId: Scalars["UUID"];
  };

/** Represents an update to a `UserAttribute`. Fields that are set will be updated. */
export type UserAttributePatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  isDemoUser?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<UserAttributesUserIdFkeyInput>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** The fields on `userAttribute` to look up the row to connect. */
export type UserAttributeUserAttributesPkeyConnect = {
  userId: Scalars["UUID"];
};

/** The fields on `userAttribute` to look up the row to delete. */
export type UserAttributeUserAttributesPkeyDelete = {
  userId: Scalars["UUID"];
};

/** A connection to a list of `UserAttribute` values. */
export type UserAttributesConnection = {
  __typename?: "UserAttributesConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserAttributeAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<UserAttributeDistinct>;
  /** A list of edges which contains the `UserAttribute` and cursor to aid in pagination. */
  edges: Array<UserAttributesEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserAttributeAggregates>>;
  /** A list of `UserAttribute` objects. */
  nodes: Array<Maybe<UserAttribute>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserAttribute` you could get from the connection. */
  totalCount: Scalars["Int"];
  /** A list of _all_ `UserAttribute` primary keys. */
  userIds?: Maybe<Array<Scalars["UUID"]>>;
};

/** A connection to a list of `UserAttribute` values. */
export type UserAttributesConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserAttributesGroupBy>;
  having?: InputMaybe<UserAttributesHavingInput>;
};

/** A `UserAttribute` edge in the connection. */
export type UserAttributesEdge = {
  __typename?: "UserAttributesEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `UserAttribute` at the end of the edge. */
  node?: Maybe<UserAttribute>;
};

/** Grouping methods for `UserAttribute` for usage during aggregation. */
export enum UserAttributesGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  IsDemoUser = "IS_DEMO_USER",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
  UserByUserIdCreatedAt = "USER_BY_USER_ID_CREATED_AT",
  UserByUserIdCreatedByEmail = "USER_BY_USER_ID_CREATED_BY_EMAIL",
  UserByUserIdDomain = "USER_BY_USER_ID_DOMAIN",
  UserByUserIdEmail = "USER_BY_USER_ID_EMAIL",
  UserByUserIdEmailVerified = "USER_BY_USER_ID_EMAIL_VERIFIED",
  UserByUserIdFamilyName = "USER_BY_USER_ID_FAMILY_NAME",
  UserByUserIdGivenName = "USER_BY_USER_ID_GIVEN_NAME",
  UserByUserIdName = "USER_BY_USER_ID_NAME",
  UserByUserIdNickname = "USER_BY_USER_ID_NICKNAME",
  UserByUserIdPicture = "USER_BY_USER_ID_PICTURE",
  UserByUserIdUpdatedAt = "USER_BY_USER_ID_UPDATED_AT",
  UserByUserIdUpdatedByEmail = "USER_BY_USER_ID_UPDATED_BY_EMAIL",
  UserByUserIdUpstreamUserId = "USER_BY_USER_ID_UPSTREAM_USER_ID",
  UserByUserIdUserId = "USER_BY_USER_ID_USER_ID",
}

export type UserAttributesHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserAttributesHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `UserAttribute` aggregates. */
export type UserAttributesHavingInput = {
  AND?: InputMaybe<Array<UserAttributesHavingInput>>;
  OR?: InputMaybe<Array<UserAttributesHavingInput>>;
  average?: InputMaybe<UserAttributesHavingAverageInput>;
  distinctCount?: InputMaybe<UserAttributesHavingDistinctCountInput>;
  max?: InputMaybe<UserAttributesHavingMaxInput>;
  min?: InputMaybe<UserAttributesHavingMinInput>;
  stddevPopulation?: InputMaybe<UserAttributesHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UserAttributesHavingStddevSampleInput>;
  sum?: InputMaybe<UserAttributesHavingSumInput>;
  variancePopulation?: InputMaybe<UserAttributesHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UserAttributesHavingVarianceSampleInput>;
};

export type UserAttributesHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserAttributesHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserAttributesHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserAttributesHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserAttributesHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserAttributesHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserAttributesHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Methods to use when ordering `UserAttribute`. */
export enum UserAttributesOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  IsDemoUserAsc = "IS_DEMO_USER_ASC",
  IsDemoUserDesc = "IS_DEMO_USER_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
  UserByUserIdCreatedAtAsc = "USER_BY_USER_ID_CREATED_AT_ASC",
  UserByUserIdCreatedAtDesc = "USER_BY_USER_ID_CREATED_AT_DESC",
  UserByUserIdCreatedByEmailAsc = "USER_BY_USER_ID_CREATED_BY_EMAIL_ASC",
  UserByUserIdCreatedByEmailDesc = "USER_BY_USER_ID_CREATED_BY_EMAIL_DESC",
  UserByUserIdDomainAsc = "USER_BY_USER_ID_DOMAIN_ASC",
  UserByUserIdDomainDesc = "USER_BY_USER_ID_DOMAIN_DESC",
  UserByUserIdEmailAsc = "USER_BY_USER_ID_EMAIL_ASC",
  UserByUserIdEmailDesc = "USER_BY_USER_ID_EMAIL_DESC",
  UserByUserIdEmailVerifiedAsc = "USER_BY_USER_ID_EMAIL_VERIFIED_ASC",
  UserByUserIdEmailVerifiedDesc = "USER_BY_USER_ID_EMAIL_VERIFIED_DESC",
  UserByUserIdFamilyNameAsc = "USER_BY_USER_ID_FAMILY_NAME_ASC",
  UserByUserIdFamilyNameDesc = "USER_BY_USER_ID_FAMILY_NAME_DESC",
  UserByUserIdGivenNameAsc = "USER_BY_USER_ID_GIVEN_NAME_ASC",
  UserByUserIdGivenNameDesc = "USER_BY_USER_ID_GIVEN_NAME_DESC",
  UserByUserIdNameAsc = "USER_BY_USER_ID_NAME_ASC",
  UserByUserIdNameDesc = "USER_BY_USER_ID_NAME_DESC",
  UserByUserIdNicknameAsc = "USER_BY_USER_ID_NICKNAME_ASC",
  UserByUserIdNicknameDesc = "USER_BY_USER_ID_NICKNAME_DESC",
  UserByUserIdPictureAsc = "USER_BY_USER_ID_PICTURE_ASC",
  UserByUserIdPictureDesc = "USER_BY_USER_ID_PICTURE_DESC",
  UserByUserIdUpdatedAtAsc = "USER_BY_USER_ID_UPDATED_AT_ASC",
  UserByUserIdUpdatedAtDesc = "USER_BY_USER_ID_UPDATED_AT_DESC",
  UserByUserIdUpdatedByEmailAsc = "USER_BY_USER_ID_UPDATED_BY_EMAIL_ASC",
  UserByUserIdUpdatedByEmailDesc = "USER_BY_USER_ID_UPDATED_BY_EMAIL_DESC",
  UserByUserIdUpstreamUserIdAsc = "USER_BY_USER_ID_UPSTREAM_USER_ID_ASC",
  UserByUserIdUpstreamUserIdDesc = "USER_BY_USER_ID_UPSTREAM_USER_ID_DESC",
  UserByUserIdUserIdAsc = "USER_BY_USER_ID_USER_ID_ASC",
  UserByUserIdUserIdDesc = "USER_BY_USER_ID_USER_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
}

/** Input for the nested mutation of `user` in the `UserAttributeInput` mutation. */
export type UserAttributesUserIdFkeyInput = {
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByEmail?: InputMaybe<UserUserEmailKeyConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectById?: InputMaybe<UserNodeIdConnect>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  connectByUserId?: InputMaybe<UserUserPkeyConnect>;
  /** A `UserInput` object that will be created and connected to this object. */
  create?: InputMaybe<UserAttributesUserIdFkeyUserCreateInput>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByEmail?: InputMaybe<UserUserEmailKeyDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteById?: InputMaybe<UserNodeIdDelete>;
  /** The primary key(s) for `user` for the far side of the relationship. */
  deleteByUserId?: InputMaybe<UserUserPkeyDelete>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByEmail?: InputMaybe<UserOnUserAttributeForUserAttributesUserIdFkeyUsingUserEmailKeyUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateById?: InputMaybe<UserAttributeOnUserAttributeForUserAttributesUserIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `user` for the far side of the relationship. */
  updateByUserId?: InputMaybe<UserOnUserAttributeForUserAttributesUserIdFkeyUsingUserPkeyUpdate>;
};

/** Input for the nested mutation of `userAttribute` in the `UserInput` mutation. */
export type UserAttributesUserIdFkeyInverseInput = {
  /** The primary key(s) for `userAttribute` for the far side of the relationship. */
  connectById?: InputMaybe<UserAttributeNodeIdConnect>;
  /** The primary key(s) for `userAttribute` for the far side of the relationship. */
  connectByUserId?: InputMaybe<UserAttributeUserAttributesPkeyConnect>;
  /** A `UserAttributeInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<UserAttributesUserIdFkeyUserAttributesCreateInput>>;
  /** The primary key(s) for `userAttribute` for the far side of the relationship. */
  deleteById?: InputMaybe<UserAttributeNodeIdDelete>;
  /** The primary key(s) for `userAttribute` for the far side of the relationship. */
  deleteByUserId?: InputMaybe<UserAttributeUserAttributesPkeyDelete>;
  /** Flag indicating whether all other `userAttribute` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `userAttribute` for the far side of the relationship. */
  updateById?: InputMaybe<UserOnUserAttributeForUserAttributesUserIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `userAttribute` for the far side of the relationship. */
  updateByUserId?: InputMaybe<UserAttributeOnUserAttributeForUserAttributesUserIdFkeyUsingUserAttributesPkeyUpdate>;
};

/** The `userAttribute` to be created by this mutation. */
export type UserAttributesUserIdFkeyUserAttributesCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  isDemoUser?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<UserAttributesUserIdFkeyInput>;
  userId?: InputMaybe<Scalars["UUID"]>;
};

/** The `user` to be created by this mutation. */
export type UserAttributesUserIdFkeyUserCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
  domain?: InputMaybe<Scalars["String"]>;
  domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
  email?: InputMaybe<Scalars["String"]>;
  emailVerified?: InputMaybe<Scalars["Boolean"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
  password?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  upstreamUserId?: InputMaybe<Scalars["String"]>;
  userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
  userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
  userId?: InputMaybe<Scalars["UUID"]>;
  userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
  userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
};

/** A condition to be used against `User` object types. All fields are tested for equality and combined with a logical ‘and.’ */
export type UserCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `domain` field. */
  domain?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `emailVerified` field. */
  emailVerified?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `familyName` field. */
  familyName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `givenName` field. */
  givenName?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `name` field. */
  name?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `nickname` field. */
  nickname?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `picture` field. */
  picture?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `upstreamUserId` field. */
  upstreamUserId?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `userId` field. */
  userId?: InputMaybe<Scalars["UUID"]>;
};

export type UserConfig = Node & {
  __typename?: "UserConfig";
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** Reads a single `DataRoom` that is related to this `UserConfig`. */
  dataRoom?: Maybe<DataRoom>;
  dataRoomId: Scalars["UUID"];
  email?: Maybe<Scalars["String"]>;
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  isArchived: Scalars["Boolean"];
  isFavorite: Scalars["Boolean"];
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
  /** Reads a single `User` that is related to this `UserConfig`. */
  userByEmail?: Maybe<User>;
  userConfigId: Scalars["UUID"];
};

export type UserConfigAggregates = {
  __typename?: "UserConfigAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UserConfigDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A filter to be used against aggregates of `UserConfig` object types. */
export type UserConfigAggregatesFilter = {
  /** Distinct count aggregate over matching `UserConfig` objects. */
  distinctCount?: InputMaybe<UserConfigDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `UserConfig` object to be included within the aggregate. */
  filter?: InputMaybe<UserConfigFilter>;
};

/**
 * A condition to be used against `UserConfig` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserConfigCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `isArchived` field. */
  isArchived?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `isFavorite` field. */
  isFavorite?: InputMaybe<Scalars["Boolean"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `userConfigId` field. */
  userConfigId?: InputMaybe<Scalars["UUID"]>;
};

/** The `dataRoom` to be created by this mutation. */
export type UserConfigDataRoomIdFkeyDataRoomCreateInput = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** Input for the nested mutation of `dataRoom` in the `UserConfigInput` mutation. */
export type UserConfigDataRoomIdFkeyInput = {
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomHash?: InputMaybe<DataRoomDataRoomDataRoomHashKeyConnect>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomId?: InputMaybe<DataRoomDataRoomPkeyConnect>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectById?: InputMaybe<DataRoomNodeIdConnect>;
  /** A `DataRoomInput` object that will be created and connected to this object. */
  create?: InputMaybe<UserConfigDataRoomIdFkeyDataRoomCreateInput>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomHash?: InputMaybe<DataRoomDataRoomDataRoomHashKeyDelete>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomId?: InputMaybe<DataRoomDataRoomPkeyDelete>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteById?: InputMaybe<DataRoomNodeIdDelete>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomHash?: InputMaybe<DataRoomOnUserConfigForUserConfigDataRoomIdFkeyUsingDataRoomDataRoomHashKeyUpdate>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomId?: InputMaybe<DataRoomOnUserConfigForUserConfigDataRoomIdFkeyUsingDataRoomPkeyUpdate>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateById?: InputMaybe<UserConfigOnUserConfigForUserConfigDataRoomIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `userConfig` in the `DataRoomInput` mutation. */
export type UserConfigDataRoomIdFkeyInverseInput = {
  /** The primary key(s) for `userConfig` for the far side of the relationship. */
  connectByDataRoomIdAndEmail?: InputMaybe<
    Array<UserConfigUserConfigDataRoomIdEmailKeyConnect>
  >;
  /** The primary key(s) for `userConfig` for the far side of the relationship. */
  connectById?: InputMaybe<Array<UserConfigNodeIdConnect>>;
  /** The primary key(s) for `userConfig` for the far side of the relationship. */
  connectByUserConfigId?: InputMaybe<Array<UserConfigUserConfigPkeyConnect>>;
  /** A `UserConfigInput` object that will be created and connected to this object. */
  create?: InputMaybe<Array<UserConfigDataRoomIdFkeyUserConfigCreateInput>>;
  /** The primary key(s) for `userConfig` for the far side of the relationship. */
  deleteByDataRoomIdAndEmail?: InputMaybe<
    Array<UserConfigUserConfigDataRoomIdEmailKeyDelete>
  >;
  /** The primary key(s) for `userConfig` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<UserConfigNodeIdDelete>>;
  /** The primary key(s) for `userConfig` for the far side of the relationship. */
  deleteByUserConfigId?: InputMaybe<Array<UserConfigUserConfigPkeyDelete>>;
  /** Flag indicating whether all other `userConfig` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `userConfig` for the far side of the relationship. */
  updateByDataRoomIdAndEmail?: InputMaybe<
    Array<UserConfigOnUserConfigForUserConfigDataRoomIdFkeyUsingUserConfigDataRoomIdEmailKeyUpdate>
  >;
  /** The primary key(s) and patch data for `userConfig` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DataRoomOnUserConfigForUserConfigDataRoomIdFkeyNodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `userConfig` for the far side of the relationship. */
  updateByUserConfigId?: InputMaybe<
    Array<UserConfigOnUserConfigForUserConfigDataRoomIdFkeyUsingUserConfigPkeyUpdate>
  >;
};

/** The `userConfig` to be created by this mutation. */
export type UserConfigDataRoomIdFkeyUserConfigCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<UserConfigDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  email?: InputMaybe<Scalars["String"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]>;
  isFavorite?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByEmail?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0Input>;
  userConfigId?: InputMaybe<Scalars["UUID"]>;
};

export type UserConfigDistinct = {
  __typename?: "UserConfigDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `dataRoomId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  dataRoomId: Array<Scalars["UUID"]>;
  /** Distinct `email` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  email: Array<Scalars["String"]>;
  /** Distinct `isArchived` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  isArchived: Array<Scalars["Boolean"]>;
  /** Distinct `isFavorite` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  isFavorite: Array<Scalars["Boolean"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
  /** Distinct `userConfigId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  userConfigId: Array<Scalars["UUID"]>;
};

export type UserConfigDistinctCountAggregateFilter = {
  createdAt?: InputMaybe<BigIntFilter>;
  createdByEmail?: InputMaybe<BigIntFilter>;
  dataRoomId?: InputMaybe<BigIntFilter>;
  email?: InputMaybe<BigIntFilter>;
  isArchived?: InputMaybe<BigIntFilter>;
  isFavorite?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  updatedByEmail?: InputMaybe<BigIntFilter>;
  userConfigId?: InputMaybe<BigIntFilter>;
};

export type UserConfigDistinctCountAggregates = {
  __typename?: "UserConfigDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of dataRoomId across the matching connection */
  dataRoomId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of isArchived across the matching connection */
  isArchived?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of isFavorite across the matching connection */
  isFavorite?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of userConfigId across the matching connection */
  userConfigId?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `UserConfig` object types. All fields are combined with a logical ‘and.’ */
export type UserConfigFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserConfigFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dataRoom` relation. */
  dataRoom?: InputMaybe<DataRoomFilter>;
  /** Filter by the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `isArchived` field. */
  isArchived?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `isFavorite` field. */
  isFavorite?: InputMaybe<BooleanFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserConfigFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserConfigFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userByEmail` relation. */
  userByEmail?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userConfigId` field. */
  userConfigId?: InputMaybe<UuidFilter>;
};

export type UserConfigGroup = {
  __typename?: "UserConfigGroup";
  /** `UserConfigFilter` that was used to implicitly filter `userConfigs`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** The count of _all_ `UserConfig` you could get from the `UserConfigGroup`. */
  totalCount: Scalars["Int"];
  /** A list of _all_ `UserConfig` primary keys that belong to the `UserConfigGroup`. */
  userConfigIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `UserConfig` of this `UserConfigGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `UserConfigGroup`. Overriding these may result in the wrong set of `UserConfig` returned.
   */
  userConfigs?: Maybe<UserConfigsConnection>;
};

export type UserConfigGroupUserConfigsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<UserConfigCondition>;
  filter?: InputMaybe<UserConfigFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserConfigsOrderBy>>;
};

/** Grouping methods for `UserConfig` for usage during aggregation. */
export enum UserConfigGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  DataRoomByDataRoomIdCreatedAt = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT",
  DataRoomByDataRoomIdCreatedByEmail = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL",
  DataRoomByDataRoomIdDataRoomHash = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH",
  DataRoomByDataRoomIdDataRoomId = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID",
  DataRoomByDataRoomIdDescription = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION",
  DataRoomByDataRoomIdEnableDevelopmentTab = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB",
  DataRoomByDataRoomIdGovernanceProtocol = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL",
  DataRoomByDataRoomIdMrenclave = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE",
  DataRoomByDataRoomIdName = "DATA_ROOM_BY_DATA_ROOM_ID_NAME",
  DataRoomByDataRoomIdOwnerEmail = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL",
  DataRoomByDataRoomIdSource = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE",
  DataRoomByDataRoomIdUpdatedAt = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT",
  DataRoomByDataRoomIdUpdatedByEmail = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL",
  DataRoomId = "DATA_ROOM_ID",
  Email = "EMAIL",
  IsArchived = "IS_ARCHIVED",
  IsFavorite = "IS_FAVORITE",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
  UserByEmailCreatedAt = "USER_BY_EMAIL_CREATED_AT",
  UserByEmailCreatedByEmail = "USER_BY_EMAIL_CREATED_BY_EMAIL",
  UserByEmailDomain = "USER_BY_EMAIL_DOMAIN",
  UserByEmailEmail = "USER_BY_EMAIL_EMAIL",
  UserByEmailEmailVerified = "USER_BY_EMAIL_EMAIL_VERIFIED",
  UserByEmailFamilyName = "USER_BY_EMAIL_FAMILY_NAME",
  UserByEmailGivenName = "USER_BY_EMAIL_GIVEN_NAME",
  UserByEmailName = "USER_BY_EMAIL_NAME",
  UserByEmailNickname = "USER_BY_EMAIL_NICKNAME",
  UserByEmailPicture = "USER_BY_EMAIL_PICTURE",
  UserByEmailUpdatedAt = "USER_BY_EMAIL_UPDATED_AT",
  UserByEmailUpdatedByEmail = "USER_BY_EMAIL_UPDATED_BY_EMAIL",
  UserByEmailUpstreamUserId = "USER_BY_EMAIL_UPSTREAM_USER_ID",
  UserByEmailUserId = "USER_BY_EMAIL_USER_ID",
}

export type UserConfigHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserConfigHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `UserConfig` aggregates. */
export type UserConfigHavingInput = {
  AND?: InputMaybe<Array<UserConfigHavingInput>>;
  OR?: InputMaybe<Array<UserConfigHavingInput>>;
  average?: InputMaybe<UserConfigHavingAverageInput>;
  distinctCount?: InputMaybe<UserConfigHavingDistinctCountInput>;
  max?: InputMaybe<UserConfigHavingMaxInput>;
  min?: InputMaybe<UserConfigHavingMinInput>;
  stddevPopulation?: InputMaybe<UserConfigHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UserConfigHavingStddevSampleInput>;
  sum?: InputMaybe<UserConfigHavingSumInput>;
  variancePopulation?: InputMaybe<UserConfigHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UserConfigHavingVarianceSampleInput>;
};

export type UserConfigHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserConfigHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserConfigHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserConfigHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserConfigHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserConfigHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserConfigHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `UserConfig` */
export type UserConfigInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<UserConfigDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  email?: InputMaybe<Scalars["String"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]>;
  isFavorite?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByEmail?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0Input>;
  userConfigId?: InputMaybe<Scalars["UUID"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type UserConfigNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `userConfig` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type UserConfigNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `userConfig` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type UserConfigOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `user` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UserPatch;
  };

/** The fields on `userConfig` to look up the row to update. */
export type UserConfigOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0UsingUserConfigDataRoomIdEmailKeyUpdate =
  {
    dataRoomId: Scalars["UUID"];
    email: Scalars["String"];
    /** An object where the defined keys will be set on the `userConfig` being updated. */
    patch: UpdateUserConfigOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0Patch;
  };

/** The fields on `userConfig` to look up the row to update. */
export type UserConfigOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0UsingUserConfigPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `userConfig` being updated. */
    patch: UpdateUserConfigOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0Patch;
    userConfigId: Scalars["UUID"];
  };

/** The globally unique `ID` look up for the row to update. */
export type UserConfigOnUserConfigForUserConfigDataRoomIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `dataRoom` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `dataRoom` being updated. */
  patch: DataRoomPatch;
};

/** The fields on `userConfig` to look up the row to update. */
export type UserConfigOnUserConfigForUserConfigDataRoomIdFkeyUsingUserConfigDataRoomIdEmailKeyUpdate =
  {
    dataRoomId: Scalars["UUID"];
    email: Scalars["String"];
    /** An object where the defined keys will be set on the `userConfig` being updated. */
    patch: UpdateUserConfigOnUserConfigForUserConfigDataRoomIdFkeyPatch;
  };

/** The fields on `userConfig` to look up the row to update. */
export type UserConfigOnUserConfigForUserConfigDataRoomIdFkeyUsingUserConfigPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `userConfig` being updated. */
    patch: UpdateUserConfigOnUserConfigForUserConfigDataRoomIdFkeyPatch;
    userConfigId: Scalars["UUID"];
  };

/** Represents an update to a `UserConfig`. Fields that are set will be updated. */
export type UserConfigPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<UserConfigDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  email?: InputMaybe<Scalars["String"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]>;
  isFavorite?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByEmail?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0Input>;
  userConfigId?: InputMaybe<Scalars["UUID"]>;
};

/** The fields on `userConfig` to look up the row to connect. */
export type UserConfigUserConfigDataRoomIdEmailKeyConnect = {
  dataRoomId: Scalars["UUID"];
  email: Scalars["String"];
};

/** The fields on `userConfig` to look up the row to delete. */
export type UserConfigUserConfigDataRoomIdEmailKeyDelete = {
  dataRoomId: Scalars["UUID"];
  email: Scalars["String"];
};

/** The fields on `userConfig` to look up the row to connect. */
export type UserConfigUserConfigPkeyConnect = {
  userConfigId: Scalars["UUID"];
};

/** The fields on `userConfig` to look up the row to delete. */
export type UserConfigUserConfigPkeyDelete = {
  userConfigId: Scalars["UUID"];
};

/** A connection to a list of `UserConfig` values. */
export type UserConfigsConnection = {
  __typename?: "UserConfigsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserConfigAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<UserConfigDistinct>;
  /** A list of edges which contains the `UserConfig` and cursor to aid in pagination. */
  edges: Array<UserConfigsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserConfigAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<UserConfigGroup>>;
  /** A list of `UserConfig` objects. */
  nodes: Array<Maybe<UserConfig>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserConfig` you could get from the connection. */
  totalCount: Scalars["Int"];
  /** A list of _all_ `UserConfig` primary keys. */
  userConfigIds?: Maybe<Array<Scalars["UUID"]>>;
};

/** A connection to a list of `UserConfig` values. */
export type UserConfigsConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserConfigGroupBy>;
  having?: InputMaybe<UserConfigHavingInput>;
};

/** A connection to a list of `UserConfig` values. */
export type UserConfigsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<UserConfigGroupBy>;
  having?: InputMaybe<UserConfigHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `UserConfig` edge in the connection. */
export type UserConfigsEdge = {
  __typename?: "UserConfigsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `UserConfig` at the end of the edge. */
  node?: Maybe<UserConfig>;
};

/** Methods to use when ordering `UserConfig`. */
export enum UserConfigsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  DataRoomByDataRoomIdCreatedAtAsc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT_ASC",
  DataRoomByDataRoomIdCreatedAtDesc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT_DESC",
  DataRoomByDataRoomIdCreatedByEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_ASC",
  DataRoomByDataRoomIdCreatedByEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_DESC",
  DataRoomByDataRoomIdDataRoomHashAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH_ASC",
  DataRoomByDataRoomIdDataRoomHashDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH_DESC",
  DataRoomByDataRoomIdDataRoomIdAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID_ASC",
  DataRoomByDataRoomIdDataRoomIdDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID_DESC",
  DataRoomByDataRoomIdDescriptionAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION_ASC",
  DataRoomByDataRoomIdDescriptionDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION_DESC",
  DataRoomByDataRoomIdEnableDevelopmentTabAsc = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomByDataRoomIdEnableDevelopmentTabDesc = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomByDataRoomIdGovernanceProtocolAsc = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL_ASC",
  DataRoomByDataRoomIdGovernanceProtocolDesc = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL_DESC",
  DataRoomByDataRoomIdMrenclaveAsc = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE_ASC",
  DataRoomByDataRoomIdMrenclaveDesc = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE_DESC",
  DataRoomByDataRoomIdNameAsc = "DATA_ROOM_BY_DATA_ROOM_ID_NAME_ASC",
  DataRoomByDataRoomIdNameDesc = "DATA_ROOM_BY_DATA_ROOM_ID_NAME_DESC",
  DataRoomByDataRoomIdOwnerEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL_ASC",
  DataRoomByDataRoomIdOwnerEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL_DESC",
  DataRoomByDataRoomIdSourceAsc = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE_ASC",
  DataRoomByDataRoomIdSourceDesc = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE_DESC",
  DataRoomByDataRoomIdUpdatedAtAsc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT_ASC",
  DataRoomByDataRoomIdUpdatedAtDesc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT_DESC",
  DataRoomByDataRoomIdUpdatedByEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_ASC",
  DataRoomByDataRoomIdUpdatedByEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_DESC",
  DataRoomIdAsc = "DATA_ROOM_ID_ASC",
  DataRoomIdDesc = "DATA_ROOM_ID_DESC",
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  IsArchivedAsc = "IS_ARCHIVED_ASC",
  IsArchivedDesc = "IS_ARCHIVED_DESC",
  IsFavoriteAsc = "IS_FAVORITE_ASC",
  IsFavoriteDesc = "IS_FAVORITE_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
  UserByEmailCreatedAtAsc = "USER_BY_EMAIL_CREATED_AT_ASC",
  UserByEmailCreatedAtDesc = "USER_BY_EMAIL_CREATED_AT_DESC",
  UserByEmailCreatedByEmailAsc = "USER_BY_EMAIL_CREATED_BY_EMAIL_ASC",
  UserByEmailCreatedByEmailDesc = "USER_BY_EMAIL_CREATED_BY_EMAIL_DESC",
  UserByEmailDomainAsc = "USER_BY_EMAIL_DOMAIN_ASC",
  UserByEmailDomainDesc = "USER_BY_EMAIL_DOMAIN_DESC",
  UserByEmailEmailAsc = "USER_BY_EMAIL_EMAIL_ASC",
  UserByEmailEmailDesc = "USER_BY_EMAIL_EMAIL_DESC",
  UserByEmailEmailVerifiedAsc = "USER_BY_EMAIL_EMAIL_VERIFIED_ASC",
  UserByEmailEmailVerifiedDesc = "USER_BY_EMAIL_EMAIL_VERIFIED_DESC",
  UserByEmailFamilyNameAsc = "USER_BY_EMAIL_FAMILY_NAME_ASC",
  UserByEmailFamilyNameDesc = "USER_BY_EMAIL_FAMILY_NAME_DESC",
  UserByEmailGivenNameAsc = "USER_BY_EMAIL_GIVEN_NAME_ASC",
  UserByEmailGivenNameDesc = "USER_BY_EMAIL_GIVEN_NAME_DESC",
  UserByEmailNameAsc = "USER_BY_EMAIL_NAME_ASC",
  UserByEmailNameDesc = "USER_BY_EMAIL_NAME_DESC",
  UserByEmailNicknameAsc = "USER_BY_EMAIL_NICKNAME_ASC",
  UserByEmailNicknameDesc = "USER_BY_EMAIL_NICKNAME_DESC",
  UserByEmailPictureAsc = "USER_BY_EMAIL_PICTURE_ASC",
  UserByEmailPictureDesc = "USER_BY_EMAIL_PICTURE_DESC",
  UserByEmailUpdatedAtAsc = "USER_BY_EMAIL_UPDATED_AT_ASC",
  UserByEmailUpdatedAtDesc = "USER_BY_EMAIL_UPDATED_AT_DESC",
  UserByEmailUpdatedByEmailAsc = "USER_BY_EMAIL_UPDATED_BY_EMAIL_ASC",
  UserByEmailUpdatedByEmailDesc = "USER_BY_EMAIL_UPDATED_BY_EMAIL_DESC",
  UserByEmailUpstreamUserIdAsc = "USER_BY_EMAIL_UPSTREAM_USER_ID_ASC",
  UserByEmailUpstreamUserIdDesc = "USER_BY_EMAIL_UPSTREAM_USER_ID_DESC",
  UserByEmailUserIdAsc = "USER_BY_EMAIL_USER_ID_ASC",
  UserByEmailUserIdDesc = "USER_BY_EMAIL_USER_ID_DESC",
  UserConfigIdAsc = "USER_CONFIG_ID_ASC",
  UserConfigIdDesc = "USER_CONFIG_ID_DESC",
}

export type UserDistinct = {
  __typename?: "UserDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Maybe<Scalars["String"]>>;
  /** Distinct `domain` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  domain: Array<Scalars["String"]>;
  /** Distinct `email` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  email: Array<Maybe<Scalars["String"]>>;
  /** Distinct `emailVerified` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  emailVerified: Array<Maybe<Scalars["Boolean"]>>;
  /** Distinct `familyName` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  familyName: Array<Maybe<Scalars["String"]>>;
  /** Distinct `givenName` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  givenName: Array<Maybe<Scalars["String"]>>;
  /** Distinct `name` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  name: Array<Maybe<Scalars["String"]>>;
  /** Distinct `nickname` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  nickname: Array<Maybe<Scalars["String"]>>;
  /** Distinct `picture` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  picture: Array<Maybe<Scalars["String"]>>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Maybe<Scalars["String"]>>;
  /** Distinct `upstreamUserId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  upstreamUserId: Array<Maybe<Scalars["String"]>>;
  /** Distinct `userId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  userId: Array<Scalars["UUID"]>;
};

export type UserDistinctCountAggregates = {
  __typename?: "UserDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of domain across the matching connection */
  domain?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of emailVerified across the matching connection */
  emailVerified?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of familyName across the matching connection */
  familyName?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of givenName across the matching connection */
  givenName?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of name across the matching connection */
  name?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of nickname across the matching connection */
  nickname?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of picture across the matching connection */
  picture?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of upstreamUserId across the matching connection */
  upstreamUserId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of userId across the matching connection */
  userId?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `User` object types. All fields are combined with a logical ‘and.’ */
export type UserFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dataRooms` relation. */
  dataRooms?: InputMaybe<UserToManyDataRoomFilter>;
  /** Some related `dataRooms` exist. */
  dataRoomsExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `domain` field. */
  domain?: InputMaybe<StringFilter>;
  /** Filter by the object’s `domainByDomain` relation. */
  domainByDomain?: InputMaybe<DomainFilter>;
  /** A related `domainByDomain` exists. */
  domainByDomainExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `emailVerified` field. */
  emailVerified?: InputMaybe<BooleanFilter>;
  /** Filter by the object’s `familyName` field. */
  familyName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `givenName` field. */
  givenName?: InputMaybe<StringFilter>;
  /** Filter by the object’s `name` field. */
  name?: InputMaybe<StringFilter>;
  /** Filter by the object’s `nickname` field. */
  nickname?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserFilter>>;
  /** Filter by the object’s `organizationMemberships` relation. */
  organizationMemberships?: InputMaybe<UserToManyOrganizationMembershipFilter>;
  /** Some related `organizationMemberships` exist. */
  organizationMembershipsExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `picture` field. */
  picture?: InputMaybe<StringFilter>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `upstreamUserId` field. */
  upstreamUserId?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userAttribute` relation. */
  userAttribute?: InputMaybe<UserAttributeFilter>;
  /** A related `userAttribute` exists. */
  userAttributeExists?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `userConfigs` relation. */
  userConfigs?: InputMaybe<UserToManyUserConfigFilter>;
  /** Some related `userConfigs` exist. */
  userConfigsExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `userId` field. */
  userId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `userPermissions` relation. */
  userPermissions?: InputMaybe<UserToManyUserPermissionFilter>;
  /** Some related `userPermissions` exist. */
  userPermissionsExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `userQuota` relation. */
  userQuota?: InputMaybe<UserQuotaFilter>;
  /** A related `userQuota` exists. */
  userQuotaExists?: InputMaybe<Scalars["Boolean"]>;
};

export type UserGroup = {
  __typename?: "UserGroup";
  /** `UserFilter` that was used to implicitly filter `users`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** The count of _all_ `User` you could get from the `UserGroup`. */
  totalCount: Scalars["Int"];
  /** A list of _all_ `User` primary keys that belong to the `UserGroup`. */
  userIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `User` of this `UserGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `UserGroup`. Overriding these may result in the wrong set of `User` returned.
   */
  users?: Maybe<UsersConnection>;
};

export type UserGroupUsersArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UsersOrderBy>>;
};

/** Grouping methods for `User` for usage during aggregation. */
export enum UserGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  Domain = "DOMAIN",
  DomainByDomainCreatedAt = "DOMAIN_BY_DOMAIN_CREATED_AT",
  DomainByDomainCreatedByEmail = "DOMAIN_BY_DOMAIN_CREATED_BY_EMAIL",
  DomainByDomainDomain = "DOMAIN_BY_DOMAIN_DOMAIN",
  DomainByDomainDomainId = "DOMAIN_BY_DOMAIN_DOMAIN_ID",
  DomainByDomainOrganizationId = "DOMAIN_BY_DOMAIN_ORGANIZATION_ID",
  DomainByDomainUpdatedAt = "DOMAIN_BY_DOMAIN_UPDATED_AT",
  DomainByDomainUpdatedByEmail = "DOMAIN_BY_DOMAIN_UPDATED_BY_EMAIL",
  EmailVerified = "EMAIL_VERIFIED",
  FamilyName = "FAMILY_NAME",
  GivenName = "GIVEN_NAME",
  Name = "NAME",
  Nickname = "NICKNAME",
  Picture = "PICTURE",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
  UpstreamUserId = "UPSTREAM_USER_ID",
  UserAttributeByUserIdCreatedAt = "USER_ATTRIBUTE_BY_USER_ID_CREATED_AT",
  UserAttributeByUserIdCreatedByEmail = "USER_ATTRIBUTE_BY_USER_ID_CREATED_BY_EMAIL",
  UserAttributeByUserIdIsDemoUser = "USER_ATTRIBUTE_BY_USER_ID_IS_DEMO_USER",
  UserAttributeByUserIdUpdatedAt = "USER_ATTRIBUTE_BY_USER_ID_UPDATED_AT",
  UserAttributeByUserIdUpdatedByEmail = "USER_ATTRIBUTE_BY_USER_ID_UPDATED_BY_EMAIL",
  UserAttributeByUserIdUserId = "USER_ATTRIBUTE_BY_USER_ID_USER_ID",
  UserQuotaByEmailAllowed = "USER_QUOTA_BY_EMAIL_ALLOWED",
  UserQuotaByEmailCreatedAt = "USER_QUOTA_BY_EMAIL_CREATED_AT",
  UserQuotaByEmailCreatedByEmail = "USER_QUOTA_BY_EMAIL_CREATED_BY_EMAIL",
  UserQuotaByEmailEmail = "USER_QUOTA_BY_EMAIL_EMAIL",
  UserQuotaByEmailUpdatedAt = "USER_QUOTA_BY_EMAIL_UPDATED_AT",
  UserQuotaByEmailUpdatedByEmail = "USER_QUOTA_BY_EMAIL_UPDATED_BY_EMAIL",
}

export type UserHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `User` aggregates. */
export type UserHavingInput = {
  AND?: InputMaybe<Array<UserHavingInput>>;
  OR?: InputMaybe<Array<UserHavingInput>>;
  average?: InputMaybe<UserHavingAverageInput>;
  distinctCount?: InputMaybe<UserHavingDistinctCountInput>;
  max?: InputMaybe<UserHavingMaxInput>;
  min?: InputMaybe<UserHavingMinInput>;
  stddevPopulation?: InputMaybe<UserHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UserHavingStddevSampleInput>;
  sum?: InputMaybe<UserHavingSumInput>;
  variancePopulation?: InputMaybe<UserHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UserHavingVarianceSampleInput>;
};

export type UserHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `User` */
export type UserInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
  domain?: InputMaybe<Scalars["String"]>;
  domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
  email?: InputMaybe<Scalars["String"]>;
  emailVerified?: InputMaybe<Scalars["Boolean"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
  password?: InputMaybe<Scalars["String"]>;
  picture?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  upstreamUserId?: InputMaybe<Scalars["String"]>;
  userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
  userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
  userId?: InputMaybe<Scalars["UUID"]>;
  userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
  userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
};

/** The globally unique `ID` look up for the row to connect. */
export type UserNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `user` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type UserNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `user` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type UserOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `dataRoom` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: DataRoomPatch;
  };

/** The fields on `user` to look up the row to update. */
export type UserOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0UsingUserEmailKeyUpdate =
  {
    email: Scalars["String"];
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UpdateUserOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0Patch;
  };

/** The fields on `user` to look up the row to update. */
export type UserOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0UsingUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UpdateUserOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0Patch;
    userId: Scalars["UUID"];
  };

/** The globally unique `ID` look up for the row to update. */
export type UserOnDomainForFakeDecentriqPlatformDomainForeignKey0NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `domain` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `domain` being updated. */
    patch: DomainPatch;
  };

/** The fields on `user` to look up the row to update. */
export type UserOnDomainForFakeDecentriqPlatformDomainForeignKey0UsingUserEmailKeyUpdate =
  {
    email: Scalars["String"];
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UpdateUserOnDomainForFakeDecentriqPlatformDomainForeignKey0Patch;
  };

/** The fields on `user` to look up the row to update. */
export type UserOnDomainForFakeDecentriqPlatformDomainForeignKey0UsingUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UpdateUserOnDomainForFakeDecentriqPlatformDomainForeignKey0Patch;
    userId: Scalars["UUID"];
  };

/** The globally unique `ID` look up for the row to update. */
export type UserOnOrganizationMembershipForOrganizationMembershipUserIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `organizationMembership` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `organizationMembership` being updated. */
    patch: OrganizationMembershipPatch;
  };

/** The fields on `user` to look up the row to update. */
export type UserOnOrganizationMembershipForOrganizationMembershipUserIdFkeyUsingUserEmailKeyUpdate =
  {
    email: Scalars["String"];
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UpdateUserOnOrganizationMembershipForOrganizationMembershipUserIdFkeyPatch;
  };

/** The fields on `user` to look up the row to update. */
export type UserOnOrganizationMembershipForOrganizationMembershipUserIdFkeyUsingUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UpdateUserOnOrganizationMembershipForOrganizationMembershipUserIdFkeyPatch;
    userId: Scalars["UUID"];
  };

/** The globally unique `ID` look up for the row to update. */
export type UserOnUserAttributeForUserAttributesUserIdFkeyNodeIdUpdate = {
  /** The globally unique `ID` which identifies a single `userAttribute` to be connected. */
  id: Scalars["ID"];
  /** An object where the defined keys will be set on the `userAttribute` being updated. */
  patch: UserAttributePatch;
};

/** The fields on `user` to look up the row to update. */
export type UserOnUserAttributeForUserAttributesUserIdFkeyUsingUserEmailKeyUpdate =
  {
    email: Scalars["String"];
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UpdateUserOnUserAttributeForUserAttributesUserIdFkeyPatch;
  };

/** The fields on `user` to look up the row to update. */
export type UserOnUserAttributeForUserAttributesUserIdFkeyUsingUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UpdateUserOnUserAttributeForUserAttributesUserIdFkeyPatch;
    userId: Scalars["UUID"];
  };

/** The globally unique `ID` look up for the row to update. */
export type UserOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `userConfig` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `userConfig` being updated. */
    patch: UserConfigPatch;
  };

/** The fields on `user` to look up the row to update. */
export type UserOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0UsingUserEmailKeyUpdate =
  {
    email: Scalars["String"];
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UpdateUserOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0Patch;
  };

/** The fields on `user` to look up the row to update. */
export type UserOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0UsingUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UpdateUserOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0Patch;
    userId: Scalars["UUID"];
  };

/** The globally unique `ID` look up for the row to update. */
export type UserOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `userPermission` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `userPermission` being updated. */
    patch: UserPermissionPatch;
  };

/** The fields on `user` to look up the row to update. */
export type UserOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0UsingUserEmailKeyUpdate =
  {
    email: Scalars["String"];
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UpdateUserOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0Patch;
  };

/** The fields on `user` to look up the row to update. */
export type UserOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0UsingUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UpdateUserOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0Patch;
    userId: Scalars["UUID"];
  };

/** The globally unique `ID` look up for the row to update. */
export type UserOnUserQuotaForFakeDecentriqPlatformUserQuotaForeignKey0NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `userQuota` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `userQuota` being updated. */
    patch: UserQuotaPatch;
  };

/** The fields on `user` to look up the row to update. */
export type UserOnUserQuotaForFakeDecentriqPlatformUserQuotaForeignKey0UsingUserEmailKeyUpdate =
  {
    email: Scalars["String"];
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UpdateUserOnUserQuotaForFakeDecentriqPlatformUserQuotaForeignKey0Patch;
  };

/** The fields on `user` to look up the row to update. */
export type UserOnUserQuotaForFakeDecentriqPlatformUserQuotaForeignKey0UsingUserPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UpdateUserOnUserQuotaForFakeDecentriqPlatformUserQuotaForeignKey0Patch;
    userId: Scalars["UUID"];
  };

/** Represents an update to a `User`. Fields that are set will be updated. */
export type UserPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
  domain?: InputMaybe<Scalars["String"]>;
  domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
  email?: InputMaybe<Scalars["String"]>;
  emailVerified?: InputMaybe<Scalars["Boolean"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
  picture?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  upstreamUserId?: InputMaybe<Scalars["String"]>;
  userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
  userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
  userId?: InputMaybe<Scalars["UUID"]>;
  userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
  userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
};

export type UserPermission = Node & {
  __typename?: "UserPermission";
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  /** Reads a single `DataRoom` that is related to this `UserPermission`. */
  dataRoom?: Maybe<DataRoom>;
  dataRoomId: Scalars["UUID"];
  email: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  /** Reads and enables pagination through a set of `Permission`. */
  permissions: PermissionsConnection;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
  /** Reads a single `User` that is related to this `UserPermission`. */
  userByEmail?: Maybe<User>;
  userPermissionId: Scalars["UUID"];
};

export type UserPermissionPermissionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<PermissionCondition>;
  filter?: InputMaybe<PermissionFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<PermissionsOrderBy>>;
};

export type UserPermissionAggregates = {
  __typename?: "UserPermissionAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UserPermissionDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/** A filter to be used against aggregates of `UserPermission` object types. */
export type UserPermissionAggregatesFilter = {
  /** Distinct count aggregate over matching `UserPermission` objects. */
  distinctCount?: InputMaybe<UserPermissionDistinctCountAggregateFilter>;
  /** A filter that must pass for the relevant `UserPermission` object to be included within the aggregate. */
  filter?: InputMaybe<UserPermissionFilter>;
};

export type UserPermissionAsElement = Node & {
  __typename?: "UserPermissionAsElement";
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  email: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  /** Reads a single `Modification` that is related to this `UserPermissionAsElement`. */
  modification?: Maybe<Modification>;
  /** Reads and enables pagination through a set of `PermissionAsElement`. */
  permissionAsElementsByUserPermissionId: PermissionAsElementsConnection;
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
  userPermissionId: Scalars["UUID"];
};

export type UserPermissionAsElementPermissionAsElementsByUserPermissionIdArgs =
  {
    after?: InputMaybe<Scalars["Cursor"]>;
    before?: InputMaybe<Scalars["Cursor"]>;
    condition?: InputMaybe<PermissionAsElementCondition>;
    filter?: InputMaybe<PermissionAsElementFilter>;
    first?: InputMaybe<Scalars["Int"]>;
    last?: InputMaybe<Scalars["Int"]>;
    offset?: InputMaybe<Scalars["Int"]>;
    orderBy?: InputMaybe<Array<PermissionAsElementsOrderBy>>;
  };

export type UserPermissionAsElementAggregates = {
  __typename?: "UserPermissionAsElementAggregates";
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UserPermissionAsElementDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
};

/**
 * A condition to be used against `UserPermissionAsElement` object types. All
 * fields are tested for equality and combined with a logical ‘and.’
 */
export type UserPermissionAsElementCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `userPermissionId` field. */
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

export type UserPermissionAsElementDistinct = {
  __typename?: "UserPermissionAsElementDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `email` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  email: Array<Scalars["String"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
  /** Distinct `userPermissionId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  userPermissionId: Array<Scalars["UUID"]>;
};

export type UserPermissionAsElementDistinctCountAggregates = {
  __typename?: "UserPermissionAsElementDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of userPermissionId across the matching connection */
  userPermissionId?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `UserPermissionAsElement` object types. All fields are combined with a logical ‘and.’ */
export type UserPermissionAsElementFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserPermissionAsElementFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Filter by the object’s `modification` relation. */
  modification?: InputMaybe<ModificationFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserPermissionAsElementFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserPermissionAsElementFilter>>;
  /** Filter by the object’s `permissionAsElementsByUserPermissionId` relation. */
  permissionAsElementsByUserPermissionId?: InputMaybe<UserPermissionAsElementToManyPermissionAsElementFilter>;
  /** Some related `permissionAsElementsByUserPermissionId` exist. */
  permissionAsElementsByUserPermissionIdExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userPermissionId` field. */
  userPermissionId?: InputMaybe<UuidFilter>;
};

/** Input for the nested mutation of `modification` in the `UserPermissionAsElementInput` mutation. */
export type UserPermissionAsElementIdFkeyInput = {
  /** The primary key(s) for `modification` for the far side of the relationship. */
  connectByElementId?: InputMaybe<ModificationModificationElementIdKeyConnect>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  connectById?: InputMaybe<ModificationNodeIdConnect>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  connectByModificationId?: InputMaybe<ModificationModificationPkeyConnect>;
  /** A `ModificationInput` object that will be created and connected to this object. */
  create?: InputMaybe<UserPermissionAsElementIdFkeyModificationCreateInput>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  deleteByElementId?: InputMaybe<ModificationModificationElementIdKeyDelete>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  deleteById?: InputMaybe<ModificationNodeIdDelete>;
  /** The primary key(s) for `modification` for the far side of the relationship. */
  deleteByModificationId?: InputMaybe<ModificationModificationPkeyDelete>;
  /** The primary key(s) and patch data for `modification` for the far side of the relationship. */
  updateByElementId?: InputMaybe<ModificationOnUserPermissionAsElementForUserPermissionAsElementIdFkeyUsingModificationElementIdKeyUpdate>;
  /** The primary key(s) and patch data for `modification` for the far side of the relationship. */
  updateById?: InputMaybe<UserPermissionAsElementOnUserPermissionAsElementForUserPermissionAsElementIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `modification` for the far side of the relationship. */
  updateByModificationId?: InputMaybe<ModificationOnUserPermissionAsElementForUserPermissionAsElementIdFkeyUsingModificationPkeyUpdate>;
};

/** Input for the nested mutation of `userPermissionAsElement` in the `ModificationInput` mutation. */
export type UserPermissionAsElementIdFkeyInverseInput = {
  /** The primary key(s) for `userPermissionAsElement` for the far side of the relationship. */
  connectById?: InputMaybe<UserPermissionAsElementNodeIdConnect>;
  /** The primary key(s) for `userPermissionAsElement` for the far side of the relationship. */
  connectByUserPermissionId?: InputMaybe<UserPermissionAsElementUserPermissionAsElementPkeyConnect>;
  /** A `UserPermissionAsElementInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<UserPermissionAsElementIdFkeyUserPermissionAsElementCreateInput>
  >;
  /** The primary key(s) for `userPermissionAsElement` for the far side of the relationship. */
  deleteById?: InputMaybe<UserPermissionAsElementNodeIdDelete>;
  /** The primary key(s) for `userPermissionAsElement` for the far side of the relationship. */
  deleteByUserPermissionId?: InputMaybe<UserPermissionAsElementUserPermissionAsElementPkeyDelete>;
  /** Flag indicating whether all other `userPermissionAsElement` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `userPermissionAsElement` for the far side of the relationship. */
  updateById?: InputMaybe<ModificationOnUserPermissionAsElementForUserPermissionAsElementIdFkeyNodeIdUpdate>;
  /** The primary key(s) and patch data for `userPermissionAsElement` for the far side of the relationship. */
  updateByUserPermissionId?: InputMaybe<UserPermissionAsElementOnUserPermissionAsElementForUserPermissionAsElementIdFkeyUsingUserPermissionAsElementPkeyUpdate>;
};

/** The `modification` to be created by this mutation. */
export type UserPermissionAsElementIdFkeyModificationCreateInput = {
  attestationSpecificationAsElement?: InputMaybe<AttestationSpecificationAsElementIdFkeyInverseInput>;
  authenticationMethodAsElement?: InputMaybe<AuthenticationMethodAsElementIdFkeyInverseInput>;
  computeNodeAsElement?: InputMaybe<ComputeNodeAsElementIdFkeyInverseInput>;
  config?: InputMaybe<Scalars["JSON"]>;
  configuration?: InputMaybe<ModificationConfigurationIdFkeyInput>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  elementId: Scalars["UUID"];
  elementType: ElementTypeType;
  modification: ModificationType;
  modificationId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userPermissionAsElement?: InputMaybe<UserPermissionAsElementIdFkeyInverseInput>;
};

/** The `userPermissionAsElement` to be created by this mutation. */
export type UserPermissionAsElementIdFkeyUserPermissionAsElementCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  modification?: InputMaybe<UserPermissionAsElementIdFkeyInput>;
  permissionAsElements?: InputMaybe<PermissionAsElementUserPermissionIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

/** An input for mutations affecting `UserPermissionAsElement` */
export type UserPermissionAsElementInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  email: Scalars["String"];
  modification?: InputMaybe<UserPermissionAsElementIdFkeyInput>;
  permissionAsElements?: InputMaybe<PermissionAsElementUserPermissionIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type UserPermissionAsElementNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `userPermissionAsElement` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type UserPermissionAsElementNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `userPermissionAsElement` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type UserPermissionAsElementOnPermissionAsElementForPermissionAsElementUserPermissionIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `permissionAsElement` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `permissionAsElement` being updated. */
    patch: PermissionAsElementPatch;
  };

/** The fields on `userPermissionAsElement` to look up the row to update. */
export type UserPermissionAsElementOnPermissionAsElementForPermissionAsElementUserPermissionIdFkeyUsingUserPermissionAsElementPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `userPermissionAsElement` being updated. */
    patch: UpdateUserPermissionAsElementOnPermissionAsElementForPermissionAsElementUserPermissionIdFkeyPatch;
    userPermissionId: Scalars["UUID"];
  };

/** The globally unique `ID` look up for the row to update. */
export type UserPermissionAsElementOnUserPermissionAsElementForUserPermissionAsElementIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `modification` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `modification` being updated. */
    patch: ModificationPatch;
  };

/** The fields on `userPermissionAsElement` to look up the row to update. */
export type UserPermissionAsElementOnUserPermissionAsElementForUserPermissionAsElementIdFkeyUsingUserPermissionAsElementPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `userPermissionAsElement` being updated. */
    patch: UpdateUserPermissionAsElementOnUserPermissionAsElementForUserPermissionAsElementIdFkeyPatch;
    userPermissionId: Scalars["UUID"];
  };

/** Represents an update to a `UserPermissionAsElement`. Fields that are set will be updated. */
export type UserPermissionAsElementPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  modification?: InputMaybe<UserPermissionAsElementIdFkeyInput>;
  permissionAsElements?: InputMaybe<PermissionAsElementUserPermissionIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against many `PermissionAsElement` object types. All fields are combined with a logical ‘and.’ */
export type UserPermissionAsElementToManyPermissionAsElementFilter = {
  /** Aggregates across related `PermissionAsElement` match the filter criteria. */
  aggregates?: InputMaybe<PermissionAsElementAggregatesFilter>;
  /** Every related `PermissionAsElement` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PermissionAsElementFilter>;
  /** No related `PermissionAsElement` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PermissionAsElementFilter>;
  /** Some related `PermissionAsElement` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PermissionAsElementFilter>;
};

/** The fields on `userPermissionAsElement` to look up the row to connect. */
export type UserPermissionAsElementUserPermissionAsElementPkeyConnect = {
  userPermissionId: Scalars["UUID"];
};

/** The fields on `userPermissionAsElement` to look up the row to delete. */
export type UserPermissionAsElementUserPermissionAsElementPkeyDelete = {
  userPermissionId: Scalars["UUID"];
};

/** A connection to a list of `UserPermissionAsElement` values. */
export type UserPermissionAsElementsConnection = {
  __typename?: "UserPermissionAsElementsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserPermissionAsElementAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<UserPermissionAsElementDistinct>;
  /** A list of edges which contains the `UserPermissionAsElement` and cursor to aid in pagination. */
  edges: Array<UserPermissionAsElementsEdge>;
  /** A list of `UserPermissionAsElement` objects. */
  nodes: Array<Maybe<UserPermissionAsElement>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserPermissionAsElement` you could get from the connection. */
  totalCount: Scalars["Int"];
  /** A list of _all_ `UserPermissionAsElement` primary keys. */
  userPermissionIds?: Maybe<Array<Scalars["UUID"]>>;
};

/** A `UserPermissionAsElement` edge in the connection. */
export type UserPermissionAsElementsEdge = {
  __typename?: "UserPermissionAsElementsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `UserPermissionAsElement` at the end of the edge. */
  node?: Maybe<UserPermissionAsElement>;
};

/**
 * A condition to be used against `UserPermission` object types. All fields are
 * tested for equality and combined with a logical ‘and.’
 */
export type UserPermissionCondition = {
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `userPermissionId` field. */
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

/** The `dataRoom` to be created by this mutation. */
export type UserPermissionDataRoomIdFkeyDataRoomCreateInput = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name: Scalars["String"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** Input for the nested mutation of `dataRoom` in the `UserPermissionInput` mutation. */
export type UserPermissionDataRoomIdFkeyInput = {
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomHash?: InputMaybe<DataRoomDataRoomDataRoomHashKeyConnect>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectByDataRoomId?: InputMaybe<DataRoomDataRoomPkeyConnect>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  connectById?: InputMaybe<DataRoomNodeIdConnect>;
  /** A `DataRoomInput` object that will be created and connected to this object. */
  create?: InputMaybe<UserPermissionDataRoomIdFkeyDataRoomCreateInput>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomHash?: InputMaybe<DataRoomDataRoomDataRoomHashKeyDelete>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteByDataRoomId?: InputMaybe<DataRoomDataRoomPkeyDelete>;
  /** The primary key(s) for `dataRoom` for the far side of the relationship. */
  deleteById?: InputMaybe<DataRoomNodeIdDelete>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomHash?: InputMaybe<DataRoomOnUserPermissionForUserPermissionDataRoomIdFkeyUsingDataRoomDataRoomHashKeyUpdate>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateByDataRoomId?: InputMaybe<DataRoomOnUserPermissionForUserPermissionDataRoomIdFkeyUsingDataRoomPkeyUpdate>;
  /** The primary key(s) and patch data for `dataRoom` for the far side of the relationship. */
  updateById?: InputMaybe<UserPermissionOnUserPermissionForUserPermissionDataRoomIdFkeyNodeIdUpdate>;
};

/** Input for the nested mutation of `userPermission` in the `DataRoomInput` mutation. */
export type UserPermissionDataRoomIdFkeyInverseInput = {
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  connectByDataRoomIdAndEmail?: InputMaybe<
    Array<UserPermissionUserPermissionDataRoomIdEmailKeyConnect>
  >;
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  connectById?: InputMaybe<Array<UserPermissionNodeIdConnect>>;
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  connectByUserPermissionId?: InputMaybe<
    Array<UserPermissionUserPermissionPkeyConnect>
  >;
  /** A `UserPermissionInput` object that will be created and connected to this object. */
  create?: InputMaybe<
    Array<UserPermissionDataRoomIdFkeyUserPermissionCreateInput>
  >;
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  deleteByDataRoomIdAndEmail?: InputMaybe<
    Array<UserPermissionUserPermissionDataRoomIdEmailKeyDelete>
  >;
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  deleteById?: InputMaybe<Array<UserPermissionNodeIdDelete>>;
  /** The primary key(s) for `userPermission` for the far side of the relationship. */
  deleteByUserPermissionId?: InputMaybe<
    Array<UserPermissionUserPermissionPkeyDelete>
  >;
  /** Flag indicating whether all other `userPermission` records that match this relationship should be removed. */
  deleteOthers?: InputMaybe<Scalars["Boolean"]>;
  /** The primary key(s) and patch data for `userPermission` for the far side of the relationship. */
  updateByDataRoomIdAndEmail?: InputMaybe<
    Array<UserPermissionOnUserPermissionForUserPermissionDataRoomIdFkeyUsingUserPermissionDataRoomIdEmailKeyUpdate>
  >;
  /** The primary key(s) and patch data for `userPermission` for the far side of the relationship. */
  updateById?: InputMaybe<
    Array<DataRoomOnUserPermissionForUserPermissionDataRoomIdFkeyNodeIdUpdate>
  >;
  /** The primary key(s) and patch data for `userPermission` for the far side of the relationship. */
  updateByUserPermissionId?: InputMaybe<
    Array<UserPermissionOnUserPermissionForUserPermissionDataRoomIdFkeyUsingUserPermissionPkeyUpdate>
  >;
};

/** The `userPermission` to be created by this mutation. */
export type UserPermissionDataRoomIdFkeyUserPermissionCreateInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<UserPermissionDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  email?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<PermissionUserPermissionIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByEmail?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0Input>;
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

export type UserPermissionDistinct = {
  __typename?: "UserPermissionDistinct";
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `dataRoomId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  dataRoomId: Array<Scalars["UUID"]>;
  /** Distinct `email` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  email: Array<Scalars["String"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
  /** Distinct `userPermissionId` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  userPermissionId: Array<Scalars["UUID"]>;
};

export type UserPermissionDistinctCountAggregateFilter = {
  createdAt?: InputMaybe<BigIntFilter>;
  createdByEmail?: InputMaybe<BigIntFilter>;
  dataRoomId?: InputMaybe<BigIntFilter>;
  email?: InputMaybe<BigIntFilter>;
  updatedAt?: InputMaybe<BigIntFilter>;
  updatedByEmail?: InputMaybe<BigIntFilter>;
  userPermissionId?: InputMaybe<BigIntFilter>;
};

export type UserPermissionDistinctCountAggregates = {
  __typename?: "UserPermissionDistinctCountAggregates";
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of dataRoomId across the matching connection */
  dataRoomId?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of userPermissionId across the matching connection */
  userPermissionId?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `UserPermission` object types. All fields are combined with a logical ‘and.’ */
export type UserPermissionFilter = {
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserPermissionFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `dataRoom` relation. */
  dataRoom?: InputMaybe<DataRoomFilter>;
  /** Filter by the object’s `dataRoomId` field. */
  dataRoomId?: InputMaybe<UuidFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserPermissionFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserPermissionFilter>>;
  /** Filter by the object’s `permissions` relation. */
  permissions?: InputMaybe<UserPermissionToManyPermissionFilter>;
  /** Some related `permissions` exist. */
  permissionsExist?: InputMaybe<Scalars["Boolean"]>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userByEmail` relation. */
  userByEmail?: InputMaybe<UserFilter>;
  /** Filter by the object’s `userPermissionId` field. */
  userPermissionId?: InputMaybe<UuidFilter>;
};

export type UserPermissionGroup = {
  __typename?: "UserPermissionGroup";
  /** `UserPermissionFilter` that was used to implicitly filter `userPermissions`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** The count of _all_ `UserPermission` you could get from the `UserPermissionGroup`. */
  totalCount: Scalars["Int"];
  /** A list of _all_ `UserPermission` primary keys that belong to the `UserPermissionGroup`. */
  userPermissionIds?: Maybe<Array<Scalars["UUID"]>>;
  /**
   * Reads and enables pagination through a set of `UserPermission` of this `UserPermissionGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `UserPermissionGroup`. Overriding these may result in the wrong set of `UserPermission` returned.
   */
  userPermissions?: Maybe<UserPermissionsConnection>;
};

export type UserPermissionGroupUserPermissionsArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<UserPermissionCondition>;
  filter?: InputMaybe<UserPermissionFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserPermissionsOrderBy>>;
};

/** Grouping methods for `UserPermission` for usage during aggregation. */
export enum UserPermissionGroupBy {
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  DataRoomByDataRoomIdCreatedAt = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT",
  DataRoomByDataRoomIdCreatedByEmail = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL",
  DataRoomByDataRoomIdDataRoomHash = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH",
  DataRoomByDataRoomIdDataRoomId = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID",
  DataRoomByDataRoomIdDescription = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION",
  DataRoomByDataRoomIdEnableDevelopmentTab = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB",
  DataRoomByDataRoomIdGovernanceProtocol = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL",
  DataRoomByDataRoomIdMrenclave = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE",
  DataRoomByDataRoomIdName = "DATA_ROOM_BY_DATA_ROOM_ID_NAME",
  DataRoomByDataRoomIdOwnerEmail = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL",
  DataRoomByDataRoomIdSource = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE",
  DataRoomByDataRoomIdUpdatedAt = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT",
  DataRoomByDataRoomIdUpdatedByEmail = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL",
  DataRoomId = "DATA_ROOM_ID",
  Email = "EMAIL",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
  UserByEmailCreatedAt = "USER_BY_EMAIL_CREATED_AT",
  UserByEmailCreatedByEmail = "USER_BY_EMAIL_CREATED_BY_EMAIL",
  UserByEmailDomain = "USER_BY_EMAIL_DOMAIN",
  UserByEmailEmail = "USER_BY_EMAIL_EMAIL",
  UserByEmailEmailVerified = "USER_BY_EMAIL_EMAIL_VERIFIED",
  UserByEmailFamilyName = "USER_BY_EMAIL_FAMILY_NAME",
  UserByEmailGivenName = "USER_BY_EMAIL_GIVEN_NAME",
  UserByEmailName = "USER_BY_EMAIL_NAME",
  UserByEmailNickname = "USER_BY_EMAIL_NICKNAME",
  UserByEmailPicture = "USER_BY_EMAIL_PICTURE",
  UserByEmailUpdatedAt = "USER_BY_EMAIL_UPDATED_AT",
  UserByEmailUpdatedByEmail = "USER_BY_EMAIL_UPDATED_BY_EMAIL",
  UserByEmailUpstreamUserId = "USER_BY_EMAIL_UPSTREAM_USER_ID",
  UserByEmailUserId = "USER_BY_EMAIL_USER_ID",
}

export type UserPermissionHavingAverageInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserPermissionHavingDistinctCountInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `UserPermission` aggregates. */
export type UserPermissionHavingInput = {
  AND?: InputMaybe<Array<UserPermissionHavingInput>>;
  OR?: InputMaybe<Array<UserPermissionHavingInput>>;
  average?: InputMaybe<UserPermissionHavingAverageInput>;
  distinctCount?: InputMaybe<UserPermissionHavingDistinctCountInput>;
  max?: InputMaybe<UserPermissionHavingMaxInput>;
  min?: InputMaybe<UserPermissionHavingMinInput>;
  stddevPopulation?: InputMaybe<UserPermissionHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UserPermissionHavingStddevSampleInput>;
  sum?: InputMaybe<UserPermissionHavingSumInput>;
  variancePopulation?: InputMaybe<UserPermissionHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UserPermissionHavingVarianceSampleInput>;
};

export type UserPermissionHavingMaxInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserPermissionHavingMinInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserPermissionHavingStddevPopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserPermissionHavingStddevSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserPermissionHavingSumInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserPermissionHavingVariancePopulationInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserPermissionHavingVarianceSampleInput = {
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `UserPermission` */
export type UserPermissionInput = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<UserPermissionDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  email?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<PermissionUserPermissionIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByEmail?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0Input>;
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type UserPermissionNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `userPermission` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type UserPermissionNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `userPermission` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type UserPermissionOnPermissionForPermissionUserPermissionIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `permission` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `permission` being updated. */
    patch: PermissionPatch;
  };

/** The fields on `userPermission` to look up the row to update. */
export type UserPermissionOnPermissionForPermissionUserPermissionIdFkeyUsingUserPermissionDataRoomIdEmailKeyUpdate =
  {
    dataRoomId: Scalars["UUID"];
    email: Scalars["String"];
    /** An object where the defined keys will be set on the `userPermission` being updated. */
    patch: UpdateUserPermissionOnPermissionForPermissionUserPermissionIdFkeyPatch;
  };

/** The fields on `userPermission` to look up the row to update. */
export type UserPermissionOnPermissionForPermissionUserPermissionIdFkeyUsingUserPermissionPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `userPermission` being updated. */
    patch: UpdateUserPermissionOnPermissionForPermissionUserPermissionIdFkeyPatch;
    userPermissionId: Scalars["UUID"];
  };

/** The globally unique `ID` look up for the row to update. */
export type UserPermissionOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `user` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UserPatch;
  };

/** The fields on `userPermission` to look up the row to update. */
export type UserPermissionOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0UsingUserPermissionDataRoomIdEmailKeyUpdate =
  {
    dataRoomId: Scalars["UUID"];
    email: Scalars["String"];
    /** An object where the defined keys will be set on the `userPermission` being updated. */
    patch: UpdateUserPermissionOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0Patch;
  };

/** The fields on `userPermission` to look up the row to update. */
export type UserPermissionOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0UsingUserPermissionPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `userPermission` being updated. */
    patch: UpdateUserPermissionOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0Patch;
    userPermissionId: Scalars["UUID"];
  };

/** The globally unique `ID` look up for the row to update. */
export type UserPermissionOnUserPermissionForUserPermissionDataRoomIdFkeyNodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `dataRoom` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `dataRoom` being updated. */
    patch: DataRoomPatch;
  };

/** The fields on `userPermission` to look up the row to update. */
export type UserPermissionOnUserPermissionForUserPermissionDataRoomIdFkeyUsingUserPermissionDataRoomIdEmailKeyUpdate =
  {
    dataRoomId: Scalars["UUID"];
    email: Scalars["String"];
    /** An object where the defined keys will be set on the `userPermission` being updated. */
    patch: UpdateUserPermissionOnUserPermissionForUserPermissionDataRoomIdFkeyPatch;
  };

/** The fields on `userPermission` to look up the row to update. */
export type UserPermissionOnUserPermissionForUserPermissionDataRoomIdFkeyUsingUserPermissionPkeyUpdate =
  {
    /** An object where the defined keys will be set on the `userPermission` being updated. */
    patch: UpdateUserPermissionOnUserPermissionForUserPermissionDataRoomIdFkeyPatch;
    userPermissionId: Scalars["UUID"];
  };

/** Represents an update to a `UserPermission`. Fields that are set will be updated. */
export type UserPermissionPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<UserPermissionDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  email?: InputMaybe<Scalars["String"]>;
  permissions?: InputMaybe<PermissionUserPermissionIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByEmail?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0Input>;
  userPermissionId?: InputMaybe<Scalars["UUID"]>;
};

/** A filter to be used against many `Permission` object types. All fields are combined with a logical ‘and.’ */
export type UserPermissionToManyPermissionFilter = {
  /** Aggregates across related `Permission` match the filter criteria. */
  aggregates?: InputMaybe<PermissionAggregatesFilter>;
  /** Every related `Permission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<PermissionFilter>;
  /** No related `Permission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<PermissionFilter>;
  /** Some related `Permission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<PermissionFilter>;
};

/** The fields on `userPermission` to look up the row to connect. */
export type UserPermissionUserPermissionDataRoomIdEmailKeyConnect = {
  dataRoomId: Scalars["UUID"];
  email: Scalars["String"];
};

/** The fields on `userPermission` to look up the row to delete. */
export type UserPermissionUserPermissionDataRoomIdEmailKeyDelete = {
  dataRoomId: Scalars["UUID"];
  email: Scalars["String"];
};

/** The fields on `userPermission` to look up the row to connect. */
export type UserPermissionUserPermissionPkeyConnect = {
  userPermissionId: Scalars["UUID"];
};

/** The fields on `userPermission` to look up the row to delete. */
export type UserPermissionUserPermissionPkeyDelete = {
  userPermissionId: Scalars["UUID"];
};

/** A connection to a list of `UserPermission` values. */
export type UserPermissionsConnection = {
  __typename?: "UserPermissionsConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserPermissionAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<UserPermissionDistinct>;
  /** A list of edges which contains the `UserPermission` and cursor to aid in pagination. */
  edges: Array<UserPermissionsEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserPermissionAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<UserPermissionGroup>>;
  /** A list of `UserPermission` objects. */
  nodes: Array<Maybe<UserPermission>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserPermission` you could get from the connection. */
  totalCount: Scalars["Int"];
  /** A list of _all_ `UserPermission` primary keys. */
  userPermissionIds?: Maybe<Array<Scalars["UUID"]>>;
};

/** A connection to a list of `UserPermission` values. */
export type UserPermissionsConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserPermissionGroupBy>;
  having?: InputMaybe<UserPermissionHavingInput>;
};

/** A connection to a list of `UserPermission` values. */
export type UserPermissionsConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<UserPermissionGroupBy>;
  having?: InputMaybe<UserPermissionHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `UserPermission` edge in the connection. */
export type UserPermissionsEdge = {
  __typename?: "UserPermissionsEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `UserPermission` at the end of the edge. */
  node?: Maybe<UserPermission>;
};

/** Methods to use when ordering `UserPermission`. */
export enum UserPermissionsOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  DataRoomByDataRoomIdCreatedAtAsc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT_ASC",
  DataRoomByDataRoomIdCreatedAtDesc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_AT_DESC",
  DataRoomByDataRoomIdCreatedByEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_ASC",
  DataRoomByDataRoomIdCreatedByEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_CREATED_BY_EMAIL_DESC",
  DataRoomByDataRoomIdDataRoomHashAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH_ASC",
  DataRoomByDataRoomIdDataRoomHashDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_HASH_DESC",
  DataRoomByDataRoomIdDataRoomIdAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID_ASC",
  DataRoomByDataRoomIdDataRoomIdDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DATA_ROOM_ID_DESC",
  DataRoomByDataRoomIdDescriptionAsc = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION_ASC",
  DataRoomByDataRoomIdDescriptionDesc = "DATA_ROOM_BY_DATA_ROOM_ID_DESCRIPTION_DESC",
  DataRoomByDataRoomIdEnableDevelopmentTabAsc = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomByDataRoomIdEnableDevelopmentTabDesc = "DATA_ROOM_BY_DATA_ROOM_ID_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomByDataRoomIdGovernanceProtocolAsc = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL_ASC",
  DataRoomByDataRoomIdGovernanceProtocolDesc = "DATA_ROOM_BY_DATA_ROOM_ID_GOVERNANCE_PROTOCOL_DESC",
  DataRoomByDataRoomIdMrenclaveAsc = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE_ASC",
  DataRoomByDataRoomIdMrenclaveDesc = "DATA_ROOM_BY_DATA_ROOM_ID_MRENCLAVE_DESC",
  DataRoomByDataRoomIdNameAsc = "DATA_ROOM_BY_DATA_ROOM_ID_NAME_ASC",
  DataRoomByDataRoomIdNameDesc = "DATA_ROOM_BY_DATA_ROOM_ID_NAME_DESC",
  DataRoomByDataRoomIdOwnerEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL_ASC",
  DataRoomByDataRoomIdOwnerEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_OWNER_EMAIL_DESC",
  DataRoomByDataRoomIdSourceAsc = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE_ASC",
  DataRoomByDataRoomIdSourceDesc = "DATA_ROOM_BY_DATA_ROOM_ID_SOURCE_DESC",
  DataRoomByDataRoomIdUpdatedAtAsc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT_ASC",
  DataRoomByDataRoomIdUpdatedAtDesc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_AT_DESC",
  DataRoomByDataRoomIdUpdatedByEmailAsc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_ASC",
  DataRoomByDataRoomIdUpdatedByEmailDesc = "DATA_ROOM_BY_DATA_ROOM_ID_UPDATED_BY_EMAIL_DESC",
  DataRoomIdAsc = "DATA_ROOM_ID_ASC",
  DataRoomIdDesc = "DATA_ROOM_ID_DESC",
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  Natural = "NATURAL",
  PermissionsAverageConfigAsc = "PERMISSIONS_AVERAGE_CONFIG_ASC",
  PermissionsAverageConfigDesc = "PERMISSIONS_AVERAGE_CONFIG_DESC",
  PermissionsAverageCreatedAtAsc = "PERMISSIONS_AVERAGE_CREATED_AT_ASC",
  PermissionsAverageCreatedAtDesc = "PERMISSIONS_AVERAGE_CREATED_AT_DESC",
  PermissionsAverageCreatedByEmailAsc = "PERMISSIONS_AVERAGE_CREATED_BY_EMAIL_ASC",
  PermissionsAverageCreatedByEmailDesc = "PERMISSIONS_AVERAGE_CREATED_BY_EMAIL_DESC",
  PermissionsAveragePermissionIdAsc = "PERMISSIONS_AVERAGE_PERMISSION_ID_ASC",
  PermissionsAveragePermissionIdDesc = "PERMISSIONS_AVERAGE_PERMISSION_ID_DESC",
  PermissionsAveragePermissionTypeAsc = "PERMISSIONS_AVERAGE_PERMISSION_TYPE_ASC",
  PermissionsAveragePermissionTypeDesc = "PERMISSIONS_AVERAGE_PERMISSION_TYPE_DESC",
  PermissionsAverageUpdatedAtAsc = "PERMISSIONS_AVERAGE_UPDATED_AT_ASC",
  PermissionsAverageUpdatedAtDesc = "PERMISSIONS_AVERAGE_UPDATED_AT_DESC",
  PermissionsAverageUpdatedByEmailAsc = "PERMISSIONS_AVERAGE_UPDATED_BY_EMAIL_ASC",
  PermissionsAverageUpdatedByEmailDesc = "PERMISSIONS_AVERAGE_UPDATED_BY_EMAIL_DESC",
  PermissionsAverageUserPermissionIdAsc = "PERMISSIONS_AVERAGE_USER_PERMISSION_ID_ASC",
  PermissionsAverageUserPermissionIdDesc = "PERMISSIONS_AVERAGE_USER_PERMISSION_ID_DESC",
  PermissionsByUserPermissionIdCountAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_COUNT_ASC",
  PermissionsByUserPermissionIdCountDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_COUNT_DESC",
  PermissionsByUserPermissionIdMaxConfigAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_CONFIG_ASC",
  PermissionsByUserPermissionIdMaxConfigDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_CONFIG_DESC",
  PermissionsByUserPermissionIdMaxCreatedAtAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_CREATED_AT_ASC",
  PermissionsByUserPermissionIdMaxCreatedAtDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_CREATED_AT_DESC",
  PermissionsByUserPermissionIdMaxCreatedByEmailAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_CREATED_BY_EMAIL_ASC",
  PermissionsByUserPermissionIdMaxCreatedByEmailDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_CREATED_BY_EMAIL_DESC",
  PermissionsByUserPermissionIdMaxPermissionIdAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_PERMISSION_ID_ASC",
  PermissionsByUserPermissionIdMaxPermissionIdDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_PERMISSION_ID_DESC",
  PermissionsByUserPermissionIdMaxPermissionTypeAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_PERMISSION_TYPE_ASC",
  PermissionsByUserPermissionIdMaxPermissionTypeDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_PERMISSION_TYPE_DESC",
  PermissionsByUserPermissionIdMaxUpdatedAtAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_UPDATED_AT_ASC",
  PermissionsByUserPermissionIdMaxUpdatedAtDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_UPDATED_AT_DESC",
  PermissionsByUserPermissionIdMaxUpdatedByEmailAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_UPDATED_BY_EMAIL_ASC",
  PermissionsByUserPermissionIdMaxUpdatedByEmailDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_UPDATED_BY_EMAIL_DESC",
  PermissionsByUserPermissionIdMaxUserPermissionIdAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_USER_PERMISSION_ID_ASC",
  PermissionsByUserPermissionIdMaxUserPermissionIdDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MAX_USER_PERMISSION_ID_DESC",
  PermissionsByUserPermissionIdMinConfigAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_CONFIG_ASC",
  PermissionsByUserPermissionIdMinConfigDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_CONFIG_DESC",
  PermissionsByUserPermissionIdMinCreatedAtAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_CREATED_AT_ASC",
  PermissionsByUserPermissionIdMinCreatedAtDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_CREATED_AT_DESC",
  PermissionsByUserPermissionIdMinCreatedByEmailAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_CREATED_BY_EMAIL_ASC",
  PermissionsByUserPermissionIdMinCreatedByEmailDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_CREATED_BY_EMAIL_DESC",
  PermissionsByUserPermissionIdMinPermissionIdAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_PERMISSION_ID_ASC",
  PermissionsByUserPermissionIdMinPermissionIdDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_PERMISSION_ID_DESC",
  PermissionsByUserPermissionIdMinPermissionTypeAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_PERMISSION_TYPE_ASC",
  PermissionsByUserPermissionIdMinPermissionTypeDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_PERMISSION_TYPE_DESC",
  PermissionsByUserPermissionIdMinUpdatedAtAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_UPDATED_AT_ASC",
  PermissionsByUserPermissionIdMinUpdatedAtDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_UPDATED_AT_DESC",
  PermissionsByUserPermissionIdMinUpdatedByEmailAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_UPDATED_BY_EMAIL_ASC",
  PermissionsByUserPermissionIdMinUpdatedByEmailDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_UPDATED_BY_EMAIL_DESC",
  PermissionsByUserPermissionIdMinUserPermissionIdAsc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_USER_PERMISSION_ID_ASC",
  PermissionsByUserPermissionIdMinUserPermissionIdDesc = "PERMISSIONS_BY_USER_PERMISSION_ID_MIN_USER_PERMISSION_ID_DESC",
  PermissionsCountAsc = "PERMISSIONS_COUNT_ASC",
  PermissionsCountDesc = "PERMISSIONS_COUNT_DESC",
  PermissionsDistinctCountConfigAsc = "PERMISSIONS_DISTINCT_COUNT_CONFIG_ASC",
  PermissionsDistinctCountConfigDesc = "PERMISSIONS_DISTINCT_COUNT_CONFIG_DESC",
  PermissionsDistinctCountCreatedAtAsc = "PERMISSIONS_DISTINCT_COUNT_CREATED_AT_ASC",
  PermissionsDistinctCountCreatedAtDesc = "PERMISSIONS_DISTINCT_COUNT_CREATED_AT_DESC",
  PermissionsDistinctCountCreatedByEmailAsc = "PERMISSIONS_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  PermissionsDistinctCountCreatedByEmailDesc = "PERMISSIONS_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  PermissionsDistinctCountPermissionIdAsc = "PERMISSIONS_DISTINCT_COUNT_PERMISSION_ID_ASC",
  PermissionsDistinctCountPermissionIdDesc = "PERMISSIONS_DISTINCT_COUNT_PERMISSION_ID_DESC",
  PermissionsDistinctCountPermissionTypeAsc = "PERMISSIONS_DISTINCT_COUNT_PERMISSION_TYPE_ASC",
  PermissionsDistinctCountPermissionTypeDesc = "PERMISSIONS_DISTINCT_COUNT_PERMISSION_TYPE_DESC",
  PermissionsDistinctCountUpdatedAtAsc = "PERMISSIONS_DISTINCT_COUNT_UPDATED_AT_ASC",
  PermissionsDistinctCountUpdatedAtDesc = "PERMISSIONS_DISTINCT_COUNT_UPDATED_AT_DESC",
  PermissionsDistinctCountUpdatedByEmailAsc = "PERMISSIONS_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  PermissionsDistinctCountUpdatedByEmailDesc = "PERMISSIONS_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  PermissionsDistinctCountUserPermissionIdAsc = "PERMISSIONS_DISTINCT_COUNT_USER_PERMISSION_ID_ASC",
  PermissionsDistinctCountUserPermissionIdDesc = "PERMISSIONS_DISTINCT_COUNT_USER_PERMISSION_ID_DESC",
  PermissionsMaxConfigAsc = "PERMISSIONS_MAX_CONFIG_ASC",
  PermissionsMaxConfigDesc = "PERMISSIONS_MAX_CONFIG_DESC",
  PermissionsMaxCreatedAtAsc = "PERMISSIONS_MAX_CREATED_AT_ASC",
  PermissionsMaxCreatedAtDesc = "PERMISSIONS_MAX_CREATED_AT_DESC",
  PermissionsMaxCreatedByEmailAsc = "PERMISSIONS_MAX_CREATED_BY_EMAIL_ASC",
  PermissionsMaxCreatedByEmailDesc = "PERMISSIONS_MAX_CREATED_BY_EMAIL_DESC",
  PermissionsMaxPermissionIdAsc = "PERMISSIONS_MAX_PERMISSION_ID_ASC",
  PermissionsMaxPermissionIdDesc = "PERMISSIONS_MAX_PERMISSION_ID_DESC",
  PermissionsMaxPermissionTypeAsc = "PERMISSIONS_MAX_PERMISSION_TYPE_ASC",
  PermissionsMaxPermissionTypeDesc = "PERMISSIONS_MAX_PERMISSION_TYPE_DESC",
  PermissionsMaxUpdatedAtAsc = "PERMISSIONS_MAX_UPDATED_AT_ASC",
  PermissionsMaxUpdatedAtDesc = "PERMISSIONS_MAX_UPDATED_AT_DESC",
  PermissionsMaxUpdatedByEmailAsc = "PERMISSIONS_MAX_UPDATED_BY_EMAIL_ASC",
  PermissionsMaxUpdatedByEmailDesc = "PERMISSIONS_MAX_UPDATED_BY_EMAIL_DESC",
  PermissionsMaxUserPermissionIdAsc = "PERMISSIONS_MAX_USER_PERMISSION_ID_ASC",
  PermissionsMaxUserPermissionIdDesc = "PERMISSIONS_MAX_USER_PERMISSION_ID_DESC",
  PermissionsMinConfigAsc = "PERMISSIONS_MIN_CONFIG_ASC",
  PermissionsMinConfigDesc = "PERMISSIONS_MIN_CONFIG_DESC",
  PermissionsMinCreatedAtAsc = "PERMISSIONS_MIN_CREATED_AT_ASC",
  PermissionsMinCreatedAtDesc = "PERMISSIONS_MIN_CREATED_AT_DESC",
  PermissionsMinCreatedByEmailAsc = "PERMISSIONS_MIN_CREATED_BY_EMAIL_ASC",
  PermissionsMinCreatedByEmailDesc = "PERMISSIONS_MIN_CREATED_BY_EMAIL_DESC",
  PermissionsMinPermissionIdAsc = "PERMISSIONS_MIN_PERMISSION_ID_ASC",
  PermissionsMinPermissionIdDesc = "PERMISSIONS_MIN_PERMISSION_ID_DESC",
  PermissionsMinPermissionTypeAsc = "PERMISSIONS_MIN_PERMISSION_TYPE_ASC",
  PermissionsMinPermissionTypeDesc = "PERMISSIONS_MIN_PERMISSION_TYPE_DESC",
  PermissionsMinUpdatedAtAsc = "PERMISSIONS_MIN_UPDATED_AT_ASC",
  PermissionsMinUpdatedAtDesc = "PERMISSIONS_MIN_UPDATED_AT_DESC",
  PermissionsMinUpdatedByEmailAsc = "PERMISSIONS_MIN_UPDATED_BY_EMAIL_ASC",
  PermissionsMinUpdatedByEmailDesc = "PERMISSIONS_MIN_UPDATED_BY_EMAIL_DESC",
  PermissionsMinUserPermissionIdAsc = "PERMISSIONS_MIN_USER_PERMISSION_ID_ASC",
  PermissionsMinUserPermissionIdDesc = "PERMISSIONS_MIN_USER_PERMISSION_ID_DESC",
  PermissionsStddevPopulationConfigAsc = "PERMISSIONS_STDDEV_POPULATION_CONFIG_ASC",
  PermissionsStddevPopulationConfigDesc = "PERMISSIONS_STDDEV_POPULATION_CONFIG_DESC",
  PermissionsStddevPopulationCreatedAtAsc = "PERMISSIONS_STDDEV_POPULATION_CREATED_AT_ASC",
  PermissionsStddevPopulationCreatedAtDesc = "PERMISSIONS_STDDEV_POPULATION_CREATED_AT_DESC",
  PermissionsStddevPopulationCreatedByEmailAsc = "PERMISSIONS_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  PermissionsStddevPopulationCreatedByEmailDesc = "PERMISSIONS_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  PermissionsStddevPopulationPermissionIdAsc = "PERMISSIONS_STDDEV_POPULATION_PERMISSION_ID_ASC",
  PermissionsStddevPopulationPermissionIdDesc = "PERMISSIONS_STDDEV_POPULATION_PERMISSION_ID_DESC",
  PermissionsStddevPopulationPermissionTypeAsc = "PERMISSIONS_STDDEV_POPULATION_PERMISSION_TYPE_ASC",
  PermissionsStddevPopulationPermissionTypeDesc = "PERMISSIONS_STDDEV_POPULATION_PERMISSION_TYPE_DESC",
  PermissionsStddevPopulationUpdatedAtAsc = "PERMISSIONS_STDDEV_POPULATION_UPDATED_AT_ASC",
  PermissionsStddevPopulationUpdatedAtDesc = "PERMISSIONS_STDDEV_POPULATION_UPDATED_AT_DESC",
  PermissionsStddevPopulationUpdatedByEmailAsc = "PERMISSIONS_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  PermissionsStddevPopulationUpdatedByEmailDesc = "PERMISSIONS_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  PermissionsStddevPopulationUserPermissionIdAsc = "PERMISSIONS_STDDEV_POPULATION_USER_PERMISSION_ID_ASC",
  PermissionsStddevPopulationUserPermissionIdDesc = "PERMISSIONS_STDDEV_POPULATION_USER_PERMISSION_ID_DESC",
  PermissionsStddevSampleConfigAsc = "PERMISSIONS_STDDEV_SAMPLE_CONFIG_ASC",
  PermissionsStddevSampleConfigDesc = "PERMISSIONS_STDDEV_SAMPLE_CONFIG_DESC",
  PermissionsStddevSampleCreatedAtAsc = "PERMISSIONS_STDDEV_SAMPLE_CREATED_AT_ASC",
  PermissionsStddevSampleCreatedAtDesc = "PERMISSIONS_STDDEV_SAMPLE_CREATED_AT_DESC",
  PermissionsStddevSampleCreatedByEmailAsc = "PERMISSIONS_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  PermissionsStddevSampleCreatedByEmailDesc = "PERMISSIONS_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  PermissionsStddevSamplePermissionIdAsc = "PERMISSIONS_STDDEV_SAMPLE_PERMISSION_ID_ASC",
  PermissionsStddevSamplePermissionIdDesc = "PERMISSIONS_STDDEV_SAMPLE_PERMISSION_ID_DESC",
  PermissionsStddevSamplePermissionTypeAsc = "PERMISSIONS_STDDEV_SAMPLE_PERMISSION_TYPE_ASC",
  PermissionsStddevSamplePermissionTypeDesc = "PERMISSIONS_STDDEV_SAMPLE_PERMISSION_TYPE_DESC",
  PermissionsStddevSampleUpdatedAtAsc = "PERMISSIONS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  PermissionsStddevSampleUpdatedAtDesc = "PERMISSIONS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  PermissionsStddevSampleUpdatedByEmailAsc = "PERMISSIONS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  PermissionsStddevSampleUpdatedByEmailDesc = "PERMISSIONS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  PermissionsStddevSampleUserPermissionIdAsc = "PERMISSIONS_STDDEV_SAMPLE_USER_PERMISSION_ID_ASC",
  PermissionsStddevSampleUserPermissionIdDesc = "PERMISSIONS_STDDEV_SAMPLE_USER_PERMISSION_ID_DESC",
  PermissionsSumConfigAsc = "PERMISSIONS_SUM_CONFIG_ASC",
  PermissionsSumConfigDesc = "PERMISSIONS_SUM_CONFIG_DESC",
  PermissionsSumCreatedAtAsc = "PERMISSIONS_SUM_CREATED_AT_ASC",
  PermissionsSumCreatedAtDesc = "PERMISSIONS_SUM_CREATED_AT_DESC",
  PermissionsSumCreatedByEmailAsc = "PERMISSIONS_SUM_CREATED_BY_EMAIL_ASC",
  PermissionsSumCreatedByEmailDesc = "PERMISSIONS_SUM_CREATED_BY_EMAIL_DESC",
  PermissionsSumPermissionIdAsc = "PERMISSIONS_SUM_PERMISSION_ID_ASC",
  PermissionsSumPermissionIdDesc = "PERMISSIONS_SUM_PERMISSION_ID_DESC",
  PermissionsSumPermissionTypeAsc = "PERMISSIONS_SUM_PERMISSION_TYPE_ASC",
  PermissionsSumPermissionTypeDesc = "PERMISSIONS_SUM_PERMISSION_TYPE_DESC",
  PermissionsSumUpdatedAtAsc = "PERMISSIONS_SUM_UPDATED_AT_ASC",
  PermissionsSumUpdatedAtDesc = "PERMISSIONS_SUM_UPDATED_AT_DESC",
  PermissionsSumUpdatedByEmailAsc = "PERMISSIONS_SUM_UPDATED_BY_EMAIL_ASC",
  PermissionsSumUpdatedByEmailDesc = "PERMISSIONS_SUM_UPDATED_BY_EMAIL_DESC",
  PermissionsSumUserPermissionIdAsc = "PERMISSIONS_SUM_USER_PERMISSION_ID_ASC",
  PermissionsSumUserPermissionIdDesc = "PERMISSIONS_SUM_USER_PERMISSION_ID_DESC",
  PermissionsVariancePopulationConfigAsc = "PERMISSIONS_VARIANCE_POPULATION_CONFIG_ASC",
  PermissionsVariancePopulationConfigDesc = "PERMISSIONS_VARIANCE_POPULATION_CONFIG_DESC",
  PermissionsVariancePopulationCreatedAtAsc = "PERMISSIONS_VARIANCE_POPULATION_CREATED_AT_ASC",
  PermissionsVariancePopulationCreatedAtDesc = "PERMISSIONS_VARIANCE_POPULATION_CREATED_AT_DESC",
  PermissionsVariancePopulationCreatedByEmailAsc = "PERMISSIONS_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  PermissionsVariancePopulationCreatedByEmailDesc = "PERMISSIONS_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  PermissionsVariancePopulationPermissionIdAsc = "PERMISSIONS_VARIANCE_POPULATION_PERMISSION_ID_ASC",
  PermissionsVariancePopulationPermissionIdDesc = "PERMISSIONS_VARIANCE_POPULATION_PERMISSION_ID_DESC",
  PermissionsVariancePopulationPermissionTypeAsc = "PERMISSIONS_VARIANCE_POPULATION_PERMISSION_TYPE_ASC",
  PermissionsVariancePopulationPermissionTypeDesc = "PERMISSIONS_VARIANCE_POPULATION_PERMISSION_TYPE_DESC",
  PermissionsVariancePopulationUpdatedAtAsc = "PERMISSIONS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  PermissionsVariancePopulationUpdatedAtDesc = "PERMISSIONS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  PermissionsVariancePopulationUpdatedByEmailAsc = "PERMISSIONS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  PermissionsVariancePopulationUpdatedByEmailDesc = "PERMISSIONS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  PermissionsVariancePopulationUserPermissionIdAsc = "PERMISSIONS_VARIANCE_POPULATION_USER_PERMISSION_ID_ASC",
  PermissionsVariancePopulationUserPermissionIdDesc = "PERMISSIONS_VARIANCE_POPULATION_USER_PERMISSION_ID_DESC",
  PermissionsVarianceSampleConfigAsc = "PERMISSIONS_VARIANCE_SAMPLE_CONFIG_ASC",
  PermissionsVarianceSampleConfigDesc = "PERMISSIONS_VARIANCE_SAMPLE_CONFIG_DESC",
  PermissionsVarianceSampleCreatedAtAsc = "PERMISSIONS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  PermissionsVarianceSampleCreatedAtDesc = "PERMISSIONS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  PermissionsVarianceSampleCreatedByEmailAsc = "PERMISSIONS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  PermissionsVarianceSampleCreatedByEmailDesc = "PERMISSIONS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  PermissionsVarianceSamplePermissionIdAsc = "PERMISSIONS_VARIANCE_SAMPLE_PERMISSION_ID_ASC",
  PermissionsVarianceSamplePermissionIdDesc = "PERMISSIONS_VARIANCE_SAMPLE_PERMISSION_ID_DESC",
  PermissionsVarianceSamplePermissionTypeAsc = "PERMISSIONS_VARIANCE_SAMPLE_PERMISSION_TYPE_ASC",
  PermissionsVarianceSamplePermissionTypeDesc = "PERMISSIONS_VARIANCE_SAMPLE_PERMISSION_TYPE_DESC",
  PermissionsVarianceSampleUpdatedAtAsc = "PERMISSIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  PermissionsVarianceSampleUpdatedAtDesc = "PERMISSIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  PermissionsVarianceSampleUpdatedByEmailAsc = "PERMISSIONS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  PermissionsVarianceSampleUpdatedByEmailDesc = "PERMISSIONS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  PermissionsVarianceSampleUserPermissionIdAsc = "PERMISSIONS_VARIANCE_SAMPLE_USER_PERMISSION_ID_ASC",
  PermissionsVarianceSampleUserPermissionIdDesc = "PERMISSIONS_VARIANCE_SAMPLE_USER_PERMISSION_ID_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
  UserByEmailCreatedAtAsc = "USER_BY_EMAIL_CREATED_AT_ASC",
  UserByEmailCreatedAtDesc = "USER_BY_EMAIL_CREATED_AT_DESC",
  UserByEmailCreatedByEmailAsc = "USER_BY_EMAIL_CREATED_BY_EMAIL_ASC",
  UserByEmailCreatedByEmailDesc = "USER_BY_EMAIL_CREATED_BY_EMAIL_DESC",
  UserByEmailDomainAsc = "USER_BY_EMAIL_DOMAIN_ASC",
  UserByEmailDomainDesc = "USER_BY_EMAIL_DOMAIN_DESC",
  UserByEmailEmailAsc = "USER_BY_EMAIL_EMAIL_ASC",
  UserByEmailEmailDesc = "USER_BY_EMAIL_EMAIL_DESC",
  UserByEmailEmailVerifiedAsc = "USER_BY_EMAIL_EMAIL_VERIFIED_ASC",
  UserByEmailEmailVerifiedDesc = "USER_BY_EMAIL_EMAIL_VERIFIED_DESC",
  UserByEmailFamilyNameAsc = "USER_BY_EMAIL_FAMILY_NAME_ASC",
  UserByEmailFamilyNameDesc = "USER_BY_EMAIL_FAMILY_NAME_DESC",
  UserByEmailGivenNameAsc = "USER_BY_EMAIL_GIVEN_NAME_ASC",
  UserByEmailGivenNameDesc = "USER_BY_EMAIL_GIVEN_NAME_DESC",
  UserByEmailNameAsc = "USER_BY_EMAIL_NAME_ASC",
  UserByEmailNameDesc = "USER_BY_EMAIL_NAME_DESC",
  UserByEmailNicknameAsc = "USER_BY_EMAIL_NICKNAME_ASC",
  UserByEmailNicknameDesc = "USER_BY_EMAIL_NICKNAME_DESC",
  UserByEmailPictureAsc = "USER_BY_EMAIL_PICTURE_ASC",
  UserByEmailPictureDesc = "USER_BY_EMAIL_PICTURE_DESC",
  UserByEmailUpdatedAtAsc = "USER_BY_EMAIL_UPDATED_AT_ASC",
  UserByEmailUpdatedAtDesc = "USER_BY_EMAIL_UPDATED_AT_DESC",
  UserByEmailUpdatedByEmailAsc = "USER_BY_EMAIL_UPDATED_BY_EMAIL_ASC",
  UserByEmailUpdatedByEmailDesc = "USER_BY_EMAIL_UPDATED_BY_EMAIL_DESC",
  UserByEmailUpstreamUserIdAsc = "USER_BY_EMAIL_UPSTREAM_USER_ID_ASC",
  UserByEmailUpstreamUserIdDesc = "USER_BY_EMAIL_UPSTREAM_USER_ID_DESC",
  UserByEmailUserIdAsc = "USER_BY_EMAIL_USER_ID_ASC",
  UserByEmailUserIdDesc = "USER_BY_EMAIL_USER_ID_DESC",
  UserPermissionIdAsc = "USER_PERMISSION_ID_ASC",
  UserPermissionIdDesc = "USER_PERMISSION_ID_DESC",
}

export type UserQuota = Node & {
  __typename?: "UserQuota";
  allowed: Scalars["Int"];
  createdAt: Scalars["Datetime"];
  createdByEmail: Scalars["String"];
  email: Scalars["String"];
  /** A globally unique identifier. Can be used in various places throughout the system to identify this single value. */
  id: Scalars["ID"];
  updatedAt: Scalars["Datetime"];
  updatedByEmail: Scalars["String"];
  /** Reads a single `User` that is related to this `UserQuota`. */
  userByEmail?: Maybe<User>;
};

export type UserQuotaAggregates = {
  __typename?: "UserQuotaAggregates";
  /** Mean average aggregates across the matching connection (ignoring before/after/first/last/offset) */
  average?: Maybe<UserQuotaAverageAggregates>;
  /** Distinct count aggregates across the matching connection (ignoring before/after/first/last/offset) */
  distinctCount?: Maybe<UserQuotaDistinctCountAggregates>;
  keys?: Maybe<Array<Scalars["String"]>>;
  /** Maximum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  max?: Maybe<UserQuotaMaxAggregates>;
  /** Minimum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  min?: Maybe<UserQuotaMinAggregates>;
  /** Population standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevPopulation?: Maybe<UserQuotaStddevPopulationAggregates>;
  /** Sample standard deviation aggregates across the matching connection (ignoring before/after/first/last/offset) */
  stddevSample?: Maybe<UserQuotaStddevSampleAggregates>;
  /** Sum aggregates across the matching connection (ignoring before/after/first/last/offset) */
  sum?: Maybe<UserQuotaSumAggregates>;
  /** Population variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  variancePopulation?: Maybe<UserQuotaVariancePopulationAggregates>;
  /** Sample variance aggregates across the matching connection (ignoring before/after/first/last/offset) */
  varianceSample?: Maybe<UserQuotaVarianceSampleAggregates>;
};

export type UserQuotaAverageAggregates = {
  __typename?: "UserQuotaAverageAggregates";
  /** Mean average of allowed across the matching connection */
  allowed?: Maybe<Scalars["BigFloat"]>;
};

/**
 * A condition to be used against `UserQuota` object types. All fields are tested
 * for equality and combined with a logical ‘and.’
 */
export type UserQuotaCondition = {
  /** Checks for equality with the object’s `allowed` field. */
  allowed?: InputMaybe<Scalars["Int"]>;
  /** Checks for equality with the object’s `createdAt` field. */
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `email` field. */
  email?: InputMaybe<Scalars["String"]>;
  /** Checks for equality with the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  /** Checks for equality with the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

export type UserQuotaDistinct = {
  __typename?: "UserQuotaDistinct";
  /** Distinct `allowed` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  allowed: Array<Scalars["Int"]>;
  /** Distinct `createdAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdAt: Array<Scalars["Datetime"]>;
  /** Distinct `createdByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  createdByEmail: Array<Scalars["String"]>;
  /** Distinct `email` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  email: Array<Scalars["String"]>;
  /** Distinct `updatedAt` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedAt: Array<Scalars["Datetime"]>;
  /** Distinct `updatedByEmail` across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  updatedByEmail: Array<Scalars["String"]>;
};

export type UserQuotaDistinctCountAggregates = {
  __typename?: "UserQuotaDistinctCountAggregates";
  /** Distinct count of allowed across the matching connection */
  allowed?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdAt across the matching connection */
  createdAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of createdByEmail across the matching connection */
  createdByEmail?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of email across the matching connection */
  email?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedAt across the matching connection */
  updatedAt?: Maybe<Scalars["BigInt"]>;
  /** Distinct count of updatedByEmail across the matching connection */
  updatedByEmail?: Maybe<Scalars["BigInt"]>;
};

/** A filter to be used against `UserQuota` object types. All fields are combined with a logical ‘and.’ */
export type UserQuotaFilter = {
  /** Filter by the object’s `allowed` field. */
  allowed?: InputMaybe<IntFilter>;
  /** Checks for all expressions in this list. */
  and?: InputMaybe<Array<UserQuotaFilter>>;
  /** Filter by the object’s `createdAt` field. */
  createdAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `createdByEmail` field. */
  createdByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `email` field. */
  email?: InputMaybe<StringFilter>;
  /** Negates the expression. */
  not?: InputMaybe<UserQuotaFilter>;
  /** Checks for any expressions in this list. */
  or?: InputMaybe<Array<UserQuotaFilter>>;
  /** Filter by the object’s `updatedAt` field. */
  updatedAt?: InputMaybe<DatetimeFilter>;
  /** Filter by the object’s `updatedByEmail` field. */
  updatedByEmail?: InputMaybe<StringFilter>;
  /** Filter by the object’s `userByEmail` relation. */
  userByEmail?: InputMaybe<UserFilter>;
};

export type UserQuotaGroup = {
  __typename?: "UserQuotaGroup";
  /** A list of _all_ `UserQuota` primary keys that belong to the `UserQuotaGroup`. */
  emails?: Maybe<Array<Scalars["String"]>>;
  /** `UserQuotaFilter` that was used to implicitly filter `userQuotas`. */
  filter?: Maybe<Scalars["JSON"]>;
  /** Key-value pairs for the specified `groupBy` grouping methods. */
  keys?: Maybe<Scalars["JSON"]>;
  /** The count of _all_ `UserQuota` you could get from the `UserQuotaGroup`. */
  totalCount: Scalars["Int"];
  /**
   * Reads and enables pagination through a set of `UserQuota` of this `UserQuotaGroup`.
   *
   * ⚠️ **Heads up!** Every GraphQL node is meant to represent a node in a graph and the value of that node should not differ based on the route used to traverse the graph in order to find that node. The same node may be found through multiple different paths, and its value should always be the same. If this promise is broken then there's a risk that any GraphQL-client that does GraphQL-aware caching may return invalid results from the cache. This is why accessing the context of a parent resolver is explicitly not supported. The way to work around this is to have intermediary types that encompass the context _(e.g. the edges on a connection have both the cursor and a node)_. In this particular case either `filter` or `condition` args will be implicitly set to match `keys` of this `UserQuotaGroup`. Overriding these may result in the wrong set of `UserQuota` returned.
   */
  userQuotas?: Maybe<UserQuotasConnection>;
};

export type UserQuotaGroupUserQuotasArgs = {
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<UserQuotaCondition>;
  filter?: InputMaybe<UserQuotaFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UserQuotasOrderBy>>;
};

/** Grouping methods for `UserQuota` for usage during aggregation. */
export enum UserQuotaGroupBy {
  Allowed = "ALLOWED",
  CreatedAt = "CREATED_AT",
  CreatedAtTruncatedToDay = "CREATED_AT_TRUNCATED_TO_DAY",
  CreatedAtTruncatedToHour = "CREATED_AT_TRUNCATED_TO_HOUR",
  CreatedByEmail = "CREATED_BY_EMAIL",
  UpdatedAt = "UPDATED_AT",
  UpdatedAtTruncatedToDay = "UPDATED_AT_TRUNCATED_TO_DAY",
  UpdatedAtTruncatedToHour = "UPDATED_AT_TRUNCATED_TO_HOUR",
  UpdatedByEmail = "UPDATED_BY_EMAIL",
  UserByEmailCreatedAt = "USER_BY_EMAIL_CREATED_AT",
  UserByEmailCreatedByEmail = "USER_BY_EMAIL_CREATED_BY_EMAIL",
  UserByEmailDomain = "USER_BY_EMAIL_DOMAIN",
  UserByEmailEmail = "USER_BY_EMAIL_EMAIL",
  UserByEmailEmailVerified = "USER_BY_EMAIL_EMAIL_VERIFIED",
  UserByEmailFamilyName = "USER_BY_EMAIL_FAMILY_NAME",
  UserByEmailGivenName = "USER_BY_EMAIL_GIVEN_NAME",
  UserByEmailName = "USER_BY_EMAIL_NAME",
  UserByEmailNickname = "USER_BY_EMAIL_NICKNAME",
  UserByEmailPicture = "USER_BY_EMAIL_PICTURE",
  UserByEmailUpdatedAt = "USER_BY_EMAIL_UPDATED_AT",
  UserByEmailUpdatedByEmail = "USER_BY_EMAIL_UPDATED_BY_EMAIL",
  UserByEmailUpstreamUserId = "USER_BY_EMAIL_UPSTREAM_USER_ID",
  UserByEmailUserId = "USER_BY_EMAIL_USER_ID",
}

export type UserQuotaHavingAverageInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserQuotaHavingDistinctCountInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** Conditions for `UserQuota` aggregates. */
export type UserQuotaHavingInput = {
  AND?: InputMaybe<Array<UserQuotaHavingInput>>;
  OR?: InputMaybe<Array<UserQuotaHavingInput>>;
  average?: InputMaybe<UserQuotaHavingAverageInput>;
  distinctCount?: InputMaybe<UserQuotaHavingDistinctCountInput>;
  max?: InputMaybe<UserQuotaHavingMaxInput>;
  min?: InputMaybe<UserQuotaHavingMinInput>;
  stddevPopulation?: InputMaybe<UserQuotaHavingStddevPopulationInput>;
  stddevSample?: InputMaybe<UserQuotaHavingStddevSampleInput>;
  sum?: InputMaybe<UserQuotaHavingSumInput>;
  variancePopulation?: InputMaybe<UserQuotaHavingVariancePopulationInput>;
  varianceSample?: InputMaybe<UserQuotaHavingVarianceSampleInput>;
};

export type UserQuotaHavingMaxInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserQuotaHavingMinInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserQuotaHavingStddevPopulationInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserQuotaHavingStddevSampleInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserQuotaHavingSumInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserQuotaHavingVariancePopulationInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

export type UserQuotaHavingVarianceSampleInput = {
  allowed?: InputMaybe<HavingIntFilter>;
  createdAt?: InputMaybe<HavingDatetimeFilter>;
  updatedAt?: InputMaybe<HavingDatetimeFilter>;
};

/** An input for mutations affecting `UserQuota` */
export type UserQuotaInput = {
  allowed?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByEmail?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0Input>;
};

export type UserQuotaMaxAggregates = {
  __typename?: "UserQuotaMaxAggregates";
  /** Maximum of allowed across the matching connection */
  allowed?: Maybe<Scalars["Int"]>;
};

export type UserQuotaMinAggregates = {
  __typename?: "UserQuotaMinAggregates";
  /** Minimum of allowed across the matching connection */
  allowed?: Maybe<Scalars["Int"]>;
};

/** The globally unique `ID` look up for the row to connect. */
export type UserQuotaNodeIdConnect = {
  /** The globally unique `ID` which identifies a single `userQuota` to be connected. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to delete. */
export type UserQuotaNodeIdDelete = {
  /** The globally unique `ID` which identifies a single `userQuota` to be deleted. */
  id: Scalars["ID"];
};

/** The globally unique `ID` look up for the row to update. */
export type UserQuotaOnUserQuotaForFakeDecentriqPlatformUserQuotaForeignKey0NodeIdUpdate =
  {
    /** The globally unique `ID` which identifies a single `user` to be connected. */
    id: Scalars["ID"];
    /** An object where the defined keys will be set on the `user` being updated. */
    patch: UserPatch;
  };

/** The fields on `userQuota` to look up the row to update. */
export type UserQuotaOnUserQuotaForFakeDecentriqPlatformUserQuotaForeignKey0UsingUserQuotaPkeyUpdate =
  {
    email: Scalars["String"];
    /** An object where the defined keys will be set on the `userQuota` being updated. */
    patch: UpdateUserQuotaOnUserQuotaForFakeDecentriqPlatformUserQuotaForeignKey0Patch;
  };

/** Represents an update to a `UserQuota`. Fields that are set will be updated. */
export type UserQuotaPatch = {
  allowed?: InputMaybe<Scalars["Int"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  email?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByEmail?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0Input>;
};

export type UserQuotaStddevPopulationAggregates = {
  __typename?: "UserQuotaStddevPopulationAggregates";
  /** Population standard deviation of allowed across the matching connection */
  allowed?: Maybe<Scalars["BigFloat"]>;
};

export type UserQuotaStddevSampleAggregates = {
  __typename?: "UserQuotaStddevSampleAggregates";
  /** Sample standard deviation of allowed across the matching connection */
  allowed?: Maybe<Scalars["BigFloat"]>;
};

export type UserQuotaSumAggregates = {
  __typename?: "UserQuotaSumAggregates";
  /** Sum of allowed across the matching connection */
  allowed: Scalars["BigInt"];
};

/** The fields on `userQuota` to look up the row to connect. */
export type UserQuotaUserQuotaPkeyConnect = {
  email: Scalars["String"];
};

/** The fields on `userQuota` to look up the row to delete. */
export type UserQuotaUserQuotaPkeyDelete = {
  email: Scalars["String"];
};

export type UserQuotaVariancePopulationAggregates = {
  __typename?: "UserQuotaVariancePopulationAggregates";
  /** Population variance of allowed across the matching connection */
  allowed?: Maybe<Scalars["BigFloat"]>;
};

export type UserQuotaVarianceSampleAggregates = {
  __typename?: "UserQuotaVarianceSampleAggregates";
  /** Sample variance of allowed across the matching connection */
  allowed?: Maybe<Scalars["BigFloat"]>;
};

/** A connection to a list of `UserQuota` values. */
export type UserQuotasConnection = {
  __typename?: "UserQuotasConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserQuotaAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<UserQuotaDistinct>;
  /** A list of edges which contains the `UserQuota` and cursor to aid in pagination. */
  edges: Array<UserQuotasEdge>;
  /** A list of _all_ `UserQuota` primary keys. */
  emails?: Maybe<Array<Scalars["String"]>>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserQuotaAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<UserQuotaGroup>>;
  /** A list of `UserQuota` objects. */
  nodes: Array<Maybe<UserQuota>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `UserQuota` you could get from the connection. */
  totalCount: Scalars["Int"];
};

/** A connection to a list of `UserQuota` values. */
export type UserQuotasConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserQuotaGroupBy>;
  having?: InputMaybe<UserQuotaHavingInput>;
};

/** A connection to a list of `UserQuota` values. */
export type UserQuotasConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<UserQuotaGroupBy>;
  having?: InputMaybe<UserQuotaHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `UserQuota` edge in the connection. */
export type UserQuotasEdge = {
  __typename?: "UserQuotasEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `UserQuota` at the end of the edge. */
  node?: Maybe<UserQuota>;
};

/** Methods to use when ordering `UserQuota`. */
export enum UserQuotasOrderBy {
  AllowedAsc = "ALLOWED_ASC",
  AllowedDesc = "ALLOWED_DESC",
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  Natural = "NATURAL",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
  UserByEmailCreatedAtAsc = "USER_BY_EMAIL_CREATED_AT_ASC",
  UserByEmailCreatedAtDesc = "USER_BY_EMAIL_CREATED_AT_DESC",
  UserByEmailCreatedByEmailAsc = "USER_BY_EMAIL_CREATED_BY_EMAIL_ASC",
  UserByEmailCreatedByEmailDesc = "USER_BY_EMAIL_CREATED_BY_EMAIL_DESC",
  UserByEmailDomainAsc = "USER_BY_EMAIL_DOMAIN_ASC",
  UserByEmailDomainDesc = "USER_BY_EMAIL_DOMAIN_DESC",
  UserByEmailEmailAsc = "USER_BY_EMAIL_EMAIL_ASC",
  UserByEmailEmailDesc = "USER_BY_EMAIL_EMAIL_DESC",
  UserByEmailEmailVerifiedAsc = "USER_BY_EMAIL_EMAIL_VERIFIED_ASC",
  UserByEmailEmailVerifiedDesc = "USER_BY_EMAIL_EMAIL_VERIFIED_DESC",
  UserByEmailFamilyNameAsc = "USER_BY_EMAIL_FAMILY_NAME_ASC",
  UserByEmailFamilyNameDesc = "USER_BY_EMAIL_FAMILY_NAME_DESC",
  UserByEmailGivenNameAsc = "USER_BY_EMAIL_GIVEN_NAME_ASC",
  UserByEmailGivenNameDesc = "USER_BY_EMAIL_GIVEN_NAME_DESC",
  UserByEmailNameAsc = "USER_BY_EMAIL_NAME_ASC",
  UserByEmailNameDesc = "USER_BY_EMAIL_NAME_DESC",
  UserByEmailNicknameAsc = "USER_BY_EMAIL_NICKNAME_ASC",
  UserByEmailNicknameDesc = "USER_BY_EMAIL_NICKNAME_DESC",
  UserByEmailPictureAsc = "USER_BY_EMAIL_PICTURE_ASC",
  UserByEmailPictureDesc = "USER_BY_EMAIL_PICTURE_DESC",
  UserByEmailUpdatedAtAsc = "USER_BY_EMAIL_UPDATED_AT_ASC",
  UserByEmailUpdatedAtDesc = "USER_BY_EMAIL_UPDATED_AT_DESC",
  UserByEmailUpdatedByEmailAsc = "USER_BY_EMAIL_UPDATED_BY_EMAIL_ASC",
  UserByEmailUpdatedByEmailDesc = "USER_BY_EMAIL_UPDATED_BY_EMAIL_DESC",
  UserByEmailUpstreamUserIdAsc = "USER_BY_EMAIL_UPSTREAM_USER_ID_ASC",
  UserByEmailUpstreamUserIdDesc = "USER_BY_EMAIL_UPSTREAM_USER_ID_DESC",
  UserByEmailUserIdAsc = "USER_BY_EMAIL_USER_ID_ASC",
  UserByEmailUserIdDesc = "USER_BY_EMAIL_USER_ID_DESC",
}

/** A filter to be used against many `DataRoom` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyDataRoomFilter = {
  /** Aggregates across related `DataRoom` match the filter criteria. */
  aggregates?: InputMaybe<DataRoomAggregatesFilter>;
  /** Every related `DataRoom` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<DataRoomFilter>;
  /** No related `DataRoom` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<DataRoomFilter>;
  /** Some related `DataRoom` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<DataRoomFilter>;
};

/** A filter to be used against many `OrganizationMembership` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyOrganizationMembershipFilter = {
  /** Aggregates across related `OrganizationMembership` match the filter criteria. */
  aggregates?: InputMaybe<OrganizationMembershipAggregatesFilter>;
  /** Every related `OrganizationMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<OrganizationMembershipFilter>;
  /** No related `OrganizationMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<OrganizationMembershipFilter>;
  /** Some related `OrganizationMembership` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<OrganizationMembershipFilter>;
};

/** A filter to be used against many `UserConfig` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyUserConfigFilter = {
  /** Aggregates across related `UserConfig` match the filter criteria. */
  aggregates?: InputMaybe<UserConfigAggregatesFilter>;
  /** Every related `UserConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserConfigFilter>;
  /** No related `UserConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserConfigFilter>;
  /** Some related `UserConfig` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserConfigFilter>;
};

/** A filter to be used against many `UserPermission` object types. All fields are combined with a logical ‘and.’ */
export type UserToManyUserPermissionFilter = {
  /** Aggregates across related `UserPermission` match the filter criteria. */
  aggregates?: InputMaybe<UserPermissionAggregatesFilter>;
  /** Every related `UserPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  every?: InputMaybe<UserPermissionFilter>;
  /** No related `UserPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  none?: InputMaybe<UserPermissionFilter>;
  /** Some related `UserPermission` matches the filter criteria. All fields are combined with a logical ‘and.’ */
  some?: InputMaybe<UserPermissionFilter>;
};

/** The fields on `user` to look up the row to connect. */
export type UserUserEmailKeyConnect = {
  email: Scalars["String"];
};

/** The fields on `user` to look up the row to delete. */
export type UserUserEmailKeyDelete = {
  email: Scalars["String"];
};

/** The fields on `user` to look up the row to connect. */
export type UserUserPkeyConnect = {
  userId: Scalars["UUID"];
};

/** The fields on `user` to look up the row to delete. */
export type UserUserPkeyDelete = {
  userId: Scalars["UUID"];
};

/** A connection to a list of `User` values. */
export type UsersConnection = {
  __typename?: "UsersConnection";
  /** Aggregates across the matching connection (ignoring before/after/first/last/offset) */
  aggregates?: Maybe<UserAggregates>;
  /** Distinct values across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  distinct?: Maybe<UserDistinct>;
  /** A list of edges which contains the `User` and cursor to aid in pagination. */
  edges: Array<UsersEdge>;
  /** Grouped aggregates across the matching connection (ignoring before/after/first/last/offset) */
  groupedAggregates?: Maybe<Array<UserAggregates>>;
  /** Creates groups across the matching connection (ignoring `before`, `after`, `first`, `last`, `offset`) */
  groups?: Maybe<Array<UserGroup>>;
  /** A list of `User` objects. */
  nodes: Array<Maybe<User>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
  /** The count of *all* `User` you could get from the connection. */
  totalCount: Scalars["Int"];
  /** A list of _all_ `User` primary keys. */
  userIds?: Maybe<Array<Scalars["UUID"]>>;
};

/** A connection to a list of `User` values. */
export type UsersConnectionGroupedAggregatesArgs = {
  groupBy: Array<UserGroupBy>;
  having?: InputMaybe<UserHavingInput>;
};

/** A connection to a list of `User` values. */
export type UsersConnectionGroupsArgs = {
  first?: InputMaybe<Scalars["Int"]>;
  groupBy: Array<UserGroupBy>;
  having?: InputMaybe<UserHavingInput>;
  offset?: InputMaybe<Scalars["Int"]>;
};

/** A `User` edge in the connection. */
export type UsersEdge = {
  __typename?: "UsersEdge";
  /** A cursor for use in pagination. */
  cursor?: Maybe<Scalars["Cursor"]>;
  /** The `User` at the end of the edge. */
  node?: Maybe<User>;
};

/** Methods to use when ordering `User`. */
export enum UsersOrderBy {
  CreatedAtAsc = "CREATED_AT_ASC",
  CreatedAtDesc = "CREATED_AT_DESC",
  CreatedByEmailAsc = "CREATED_BY_EMAIL_ASC",
  CreatedByEmailDesc = "CREATED_BY_EMAIL_DESC",
  DataRoomsAverageCreatedAtAsc = "DATA_ROOMS_AVERAGE_CREATED_AT_ASC",
  DataRoomsAverageCreatedAtDesc = "DATA_ROOMS_AVERAGE_CREATED_AT_DESC",
  DataRoomsAverageCreatedByEmailAsc = "DATA_ROOMS_AVERAGE_CREATED_BY_EMAIL_ASC",
  DataRoomsAverageCreatedByEmailDesc = "DATA_ROOMS_AVERAGE_CREATED_BY_EMAIL_DESC",
  DataRoomsAverageDataRoomHashAsc = "DATA_ROOMS_AVERAGE_DATA_ROOM_HASH_ASC",
  DataRoomsAverageDataRoomHashDesc = "DATA_ROOMS_AVERAGE_DATA_ROOM_HASH_DESC",
  DataRoomsAverageDataRoomIdAsc = "DATA_ROOMS_AVERAGE_DATA_ROOM_ID_ASC",
  DataRoomsAverageDataRoomIdDesc = "DATA_ROOMS_AVERAGE_DATA_ROOM_ID_DESC",
  DataRoomsAverageDescriptionAsc = "DATA_ROOMS_AVERAGE_DESCRIPTION_ASC",
  DataRoomsAverageDescriptionDesc = "DATA_ROOMS_AVERAGE_DESCRIPTION_DESC",
  DataRoomsAverageEnableDevelopmentTabAsc = "DATA_ROOMS_AVERAGE_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomsAverageEnableDevelopmentTabDesc = "DATA_ROOMS_AVERAGE_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomsAverageGovernanceProtocolAsc = "DATA_ROOMS_AVERAGE_GOVERNANCE_PROTOCOL_ASC",
  DataRoomsAverageGovernanceProtocolDesc = "DATA_ROOMS_AVERAGE_GOVERNANCE_PROTOCOL_DESC",
  DataRoomsAverageMrenclaveAsc = "DATA_ROOMS_AVERAGE_MRENCLAVE_ASC",
  DataRoomsAverageMrenclaveDesc = "DATA_ROOMS_AVERAGE_MRENCLAVE_DESC",
  DataRoomsAverageNameAsc = "DATA_ROOMS_AVERAGE_NAME_ASC",
  DataRoomsAverageNameDesc = "DATA_ROOMS_AVERAGE_NAME_DESC",
  DataRoomsAverageOwnerEmailAsc = "DATA_ROOMS_AVERAGE_OWNER_EMAIL_ASC",
  DataRoomsAverageOwnerEmailDesc = "DATA_ROOMS_AVERAGE_OWNER_EMAIL_DESC",
  DataRoomsAverageSourceAsc = "DATA_ROOMS_AVERAGE_SOURCE_ASC",
  DataRoomsAverageSourceDesc = "DATA_ROOMS_AVERAGE_SOURCE_DESC",
  DataRoomsAverageUpdatedAtAsc = "DATA_ROOMS_AVERAGE_UPDATED_AT_ASC",
  DataRoomsAverageUpdatedAtDesc = "DATA_ROOMS_AVERAGE_UPDATED_AT_DESC",
  DataRoomsAverageUpdatedByEmailAsc = "DATA_ROOMS_AVERAGE_UPDATED_BY_EMAIL_ASC",
  DataRoomsAverageUpdatedByEmailDesc = "DATA_ROOMS_AVERAGE_UPDATED_BY_EMAIL_DESC",
  DataRoomsByOwnerEmailCountAsc = "DATA_ROOMS_BY_OWNER_EMAIL_COUNT_ASC",
  DataRoomsByOwnerEmailCountDesc = "DATA_ROOMS_BY_OWNER_EMAIL_COUNT_DESC",
  DataRoomsByOwnerEmailMaxCreatedAtAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_CREATED_AT_ASC",
  DataRoomsByOwnerEmailMaxCreatedAtDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_CREATED_AT_DESC",
  DataRoomsByOwnerEmailMaxCreatedByEmailAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_CREATED_BY_EMAIL_ASC",
  DataRoomsByOwnerEmailMaxCreatedByEmailDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_CREATED_BY_EMAIL_DESC",
  DataRoomsByOwnerEmailMaxDataRoomHashAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_DATA_ROOM_HASH_ASC",
  DataRoomsByOwnerEmailMaxDataRoomHashDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_DATA_ROOM_HASH_DESC",
  DataRoomsByOwnerEmailMaxDataRoomIdAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_DATA_ROOM_ID_ASC",
  DataRoomsByOwnerEmailMaxDataRoomIdDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_DATA_ROOM_ID_DESC",
  DataRoomsByOwnerEmailMaxDescriptionAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_DESCRIPTION_ASC",
  DataRoomsByOwnerEmailMaxDescriptionDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_DESCRIPTION_DESC",
  DataRoomsByOwnerEmailMaxEnableDevelopmentTabAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomsByOwnerEmailMaxEnableDevelopmentTabDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomsByOwnerEmailMaxGovernanceProtocolAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_GOVERNANCE_PROTOCOL_ASC",
  DataRoomsByOwnerEmailMaxGovernanceProtocolDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_GOVERNANCE_PROTOCOL_DESC",
  DataRoomsByOwnerEmailMaxMrenclaveAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_MRENCLAVE_ASC",
  DataRoomsByOwnerEmailMaxMrenclaveDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_MRENCLAVE_DESC",
  DataRoomsByOwnerEmailMaxNameAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_NAME_ASC",
  DataRoomsByOwnerEmailMaxNameDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_NAME_DESC",
  DataRoomsByOwnerEmailMaxOwnerEmailAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_OWNER_EMAIL_ASC",
  DataRoomsByOwnerEmailMaxOwnerEmailDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_OWNER_EMAIL_DESC",
  DataRoomsByOwnerEmailMaxSourceAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_SOURCE_ASC",
  DataRoomsByOwnerEmailMaxSourceDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_SOURCE_DESC",
  DataRoomsByOwnerEmailMaxUpdatedAtAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_UPDATED_AT_ASC",
  DataRoomsByOwnerEmailMaxUpdatedAtDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_UPDATED_AT_DESC",
  DataRoomsByOwnerEmailMaxUpdatedByEmailAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_UPDATED_BY_EMAIL_ASC",
  DataRoomsByOwnerEmailMaxUpdatedByEmailDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MAX_UPDATED_BY_EMAIL_DESC",
  DataRoomsByOwnerEmailMinCreatedAtAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_CREATED_AT_ASC",
  DataRoomsByOwnerEmailMinCreatedAtDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_CREATED_AT_DESC",
  DataRoomsByOwnerEmailMinCreatedByEmailAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_CREATED_BY_EMAIL_ASC",
  DataRoomsByOwnerEmailMinCreatedByEmailDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_CREATED_BY_EMAIL_DESC",
  DataRoomsByOwnerEmailMinDataRoomHashAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_DATA_ROOM_HASH_ASC",
  DataRoomsByOwnerEmailMinDataRoomHashDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_DATA_ROOM_HASH_DESC",
  DataRoomsByOwnerEmailMinDataRoomIdAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_DATA_ROOM_ID_ASC",
  DataRoomsByOwnerEmailMinDataRoomIdDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_DATA_ROOM_ID_DESC",
  DataRoomsByOwnerEmailMinDescriptionAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_DESCRIPTION_ASC",
  DataRoomsByOwnerEmailMinDescriptionDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_DESCRIPTION_DESC",
  DataRoomsByOwnerEmailMinEnableDevelopmentTabAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomsByOwnerEmailMinEnableDevelopmentTabDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomsByOwnerEmailMinGovernanceProtocolAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_GOVERNANCE_PROTOCOL_ASC",
  DataRoomsByOwnerEmailMinGovernanceProtocolDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_GOVERNANCE_PROTOCOL_DESC",
  DataRoomsByOwnerEmailMinMrenclaveAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_MRENCLAVE_ASC",
  DataRoomsByOwnerEmailMinMrenclaveDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_MRENCLAVE_DESC",
  DataRoomsByOwnerEmailMinNameAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_NAME_ASC",
  DataRoomsByOwnerEmailMinNameDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_NAME_DESC",
  DataRoomsByOwnerEmailMinOwnerEmailAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_OWNER_EMAIL_ASC",
  DataRoomsByOwnerEmailMinOwnerEmailDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_OWNER_EMAIL_DESC",
  DataRoomsByOwnerEmailMinSourceAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_SOURCE_ASC",
  DataRoomsByOwnerEmailMinSourceDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_SOURCE_DESC",
  DataRoomsByOwnerEmailMinUpdatedAtAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_UPDATED_AT_ASC",
  DataRoomsByOwnerEmailMinUpdatedAtDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_UPDATED_AT_DESC",
  DataRoomsByOwnerEmailMinUpdatedByEmailAsc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_UPDATED_BY_EMAIL_ASC",
  DataRoomsByOwnerEmailMinUpdatedByEmailDesc = "DATA_ROOMS_BY_OWNER_EMAIL_MIN_UPDATED_BY_EMAIL_DESC",
  DataRoomsCountAsc = "DATA_ROOMS_COUNT_ASC",
  DataRoomsCountDesc = "DATA_ROOMS_COUNT_DESC",
  DataRoomsDistinctCountCreatedAtAsc = "DATA_ROOMS_DISTINCT_COUNT_CREATED_AT_ASC",
  DataRoomsDistinctCountCreatedAtDesc = "DATA_ROOMS_DISTINCT_COUNT_CREATED_AT_DESC",
  DataRoomsDistinctCountCreatedByEmailAsc = "DATA_ROOMS_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  DataRoomsDistinctCountCreatedByEmailDesc = "DATA_ROOMS_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  DataRoomsDistinctCountDataRoomHashAsc = "DATA_ROOMS_DISTINCT_COUNT_DATA_ROOM_HASH_ASC",
  DataRoomsDistinctCountDataRoomHashDesc = "DATA_ROOMS_DISTINCT_COUNT_DATA_ROOM_HASH_DESC",
  DataRoomsDistinctCountDataRoomIdAsc = "DATA_ROOMS_DISTINCT_COUNT_DATA_ROOM_ID_ASC",
  DataRoomsDistinctCountDataRoomIdDesc = "DATA_ROOMS_DISTINCT_COUNT_DATA_ROOM_ID_DESC",
  DataRoomsDistinctCountDescriptionAsc = "DATA_ROOMS_DISTINCT_COUNT_DESCRIPTION_ASC",
  DataRoomsDistinctCountDescriptionDesc = "DATA_ROOMS_DISTINCT_COUNT_DESCRIPTION_DESC",
  DataRoomsDistinctCountEnableDevelopmentTabAsc = "DATA_ROOMS_DISTINCT_COUNT_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomsDistinctCountEnableDevelopmentTabDesc = "DATA_ROOMS_DISTINCT_COUNT_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomsDistinctCountGovernanceProtocolAsc = "DATA_ROOMS_DISTINCT_COUNT_GOVERNANCE_PROTOCOL_ASC",
  DataRoomsDistinctCountGovernanceProtocolDesc = "DATA_ROOMS_DISTINCT_COUNT_GOVERNANCE_PROTOCOL_DESC",
  DataRoomsDistinctCountMrenclaveAsc = "DATA_ROOMS_DISTINCT_COUNT_MRENCLAVE_ASC",
  DataRoomsDistinctCountMrenclaveDesc = "DATA_ROOMS_DISTINCT_COUNT_MRENCLAVE_DESC",
  DataRoomsDistinctCountNameAsc = "DATA_ROOMS_DISTINCT_COUNT_NAME_ASC",
  DataRoomsDistinctCountNameDesc = "DATA_ROOMS_DISTINCT_COUNT_NAME_DESC",
  DataRoomsDistinctCountOwnerEmailAsc = "DATA_ROOMS_DISTINCT_COUNT_OWNER_EMAIL_ASC",
  DataRoomsDistinctCountOwnerEmailDesc = "DATA_ROOMS_DISTINCT_COUNT_OWNER_EMAIL_DESC",
  DataRoomsDistinctCountSourceAsc = "DATA_ROOMS_DISTINCT_COUNT_SOURCE_ASC",
  DataRoomsDistinctCountSourceDesc = "DATA_ROOMS_DISTINCT_COUNT_SOURCE_DESC",
  DataRoomsDistinctCountUpdatedAtAsc = "DATA_ROOMS_DISTINCT_COUNT_UPDATED_AT_ASC",
  DataRoomsDistinctCountUpdatedAtDesc = "DATA_ROOMS_DISTINCT_COUNT_UPDATED_AT_DESC",
  DataRoomsDistinctCountUpdatedByEmailAsc = "DATA_ROOMS_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  DataRoomsDistinctCountUpdatedByEmailDesc = "DATA_ROOMS_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  DataRoomsMaxCreatedAtAsc = "DATA_ROOMS_MAX_CREATED_AT_ASC",
  DataRoomsMaxCreatedAtDesc = "DATA_ROOMS_MAX_CREATED_AT_DESC",
  DataRoomsMaxCreatedByEmailAsc = "DATA_ROOMS_MAX_CREATED_BY_EMAIL_ASC",
  DataRoomsMaxCreatedByEmailDesc = "DATA_ROOMS_MAX_CREATED_BY_EMAIL_DESC",
  DataRoomsMaxDataRoomHashAsc = "DATA_ROOMS_MAX_DATA_ROOM_HASH_ASC",
  DataRoomsMaxDataRoomHashDesc = "DATA_ROOMS_MAX_DATA_ROOM_HASH_DESC",
  DataRoomsMaxDataRoomIdAsc = "DATA_ROOMS_MAX_DATA_ROOM_ID_ASC",
  DataRoomsMaxDataRoomIdDesc = "DATA_ROOMS_MAX_DATA_ROOM_ID_DESC",
  DataRoomsMaxDescriptionAsc = "DATA_ROOMS_MAX_DESCRIPTION_ASC",
  DataRoomsMaxDescriptionDesc = "DATA_ROOMS_MAX_DESCRIPTION_DESC",
  DataRoomsMaxEnableDevelopmentTabAsc = "DATA_ROOMS_MAX_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomsMaxEnableDevelopmentTabDesc = "DATA_ROOMS_MAX_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomsMaxGovernanceProtocolAsc = "DATA_ROOMS_MAX_GOVERNANCE_PROTOCOL_ASC",
  DataRoomsMaxGovernanceProtocolDesc = "DATA_ROOMS_MAX_GOVERNANCE_PROTOCOL_DESC",
  DataRoomsMaxMrenclaveAsc = "DATA_ROOMS_MAX_MRENCLAVE_ASC",
  DataRoomsMaxMrenclaveDesc = "DATA_ROOMS_MAX_MRENCLAVE_DESC",
  DataRoomsMaxNameAsc = "DATA_ROOMS_MAX_NAME_ASC",
  DataRoomsMaxNameDesc = "DATA_ROOMS_MAX_NAME_DESC",
  DataRoomsMaxOwnerEmailAsc = "DATA_ROOMS_MAX_OWNER_EMAIL_ASC",
  DataRoomsMaxOwnerEmailDesc = "DATA_ROOMS_MAX_OWNER_EMAIL_DESC",
  DataRoomsMaxSourceAsc = "DATA_ROOMS_MAX_SOURCE_ASC",
  DataRoomsMaxSourceDesc = "DATA_ROOMS_MAX_SOURCE_DESC",
  DataRoomsMaxUpdatedAtAsc = "DATA_ROOMS_MAX_UPDATED_AT_ASC",
  DataRoomsMaxUpdatedAtDesc = "DATA_ROOMS_MAX_UPDATED_AT_DESC",
  DataRoomsMaxUpdatedByEmailAsc = "DATA_ROOMS_MAX_UPDATED_BY_EMAIL_ASC",
  DataRoomsMaxUpdatedByEmailDesc = "DATA_ROOMS_MAX_UPDATED_BY_EMAIL_DESC",
  DataRoomsMinCreatedAtAsc = "DATA_ROOMS_MIN_CREATED_AT_ASC",
  DataRoomsMinCreatedAtDesc = "DATA_ROOMS_MIN_CREATED_AT_DESC",
  DataRoomsMinCreatedByEmailAsc = "DATA_ROOMS_MIN_CREATED_BY_EMAIL_ASC",
  DataRoomsMinCreatedByEmailDesc = "DATA_ROOMS_MIN_CREATED_BY_EMAIL_DESC",
  DataRoomsMinDataRoomHashAsc = "DATA_ROOMS_MIN_DATA_ROOM_HASH_ASC",
  DataRoomsMinDataRoomHashDesc = "DATA_ROOMS_MIN_DATA_ROOM_HASH_DESC",
  DataRoomsMinDataRoomIdAsc = "DATA_ROOMS_MIN_DATA_ROOM_ID_ASC",
  DataRoomsMinDataRoomIdDesc = "DATA_ROOMS_MIN_DATA_ROOM_ID_DESC",
  DataRoomsMinDescriptionAsc = "DATA_ROOMS_MIN_DESCRIPTION_ASC",
  DataRoomsMinDescriptionDesc = "DATA_ROOMS_MIN_DESCRIPTION_DESC",
  DataRoomsMinEnableDevelopmentTabAsc = "DATA_ROOMS_MIN_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomsMinEnableDevelopmentTabDesc = "DATA_ROOMS_MIN_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomsMinGovernanceProtocolAsc = "DATA_ROOMS_MIN_GOVERNANCE_PROTOCOL_ASC",
  DataRoomsMinGovernanceProtocolDesc = "DATA_ROOMS_MIN_GOVERNANCE_PROTOCOL_DESC",
  DataRoomsMinMrenclaveAsc = "DATA_ROOMS_MIN_MRENCLAVE_ASC",
  DataRoomsMinMrenclaveDesc = "DATA_ROOMS_MIN_MRENCLAVE_DESC",
  DataRoomsMinNameAsc = "DATA_ROOMS_MIN_NAME_ASC",
  DataRoomsMinNameDesc = "DATA_ROOMS_MIN_NAME_DESC",
  DataRoomsMinOwnerEmailAsc = "DATA_ROOMS_MIN_OWNER_EMAIL_ASC",
  DataRoomsMinOwnerEmailDesc = "DATA_ROOMS_MIN_OWNER_EMAIL_DESC",
  DataRoomsMinSourceAsc = "DATA_ROOMS_MIN_SOURCE_ASC",
  DataRoomsMinSourceDesc = "DATA_ROOMS_MIN_SOURCE_DESC",
  DataRoomsMinUpdatedAtAsc = "DATA_ROOMS_MIN_UPDATED_AT_ASC",
  DataRoomsMinUpdatedAtDesc = "DATA_ROOMS_MIN_UPDATED_AT_DESC",
  DataRoomsMinUpdatedByEmailAsc = "DATA_ROOMS_MIN_UPDATED_BY_EMAIL_ASC",
  DataRoomsMinUpdatedByEmailDesc = "DATA_ROOMS_MIN_UPDATED_BY_EMAIL_DESC",
  DataRoomsStddevPopulationCreatedAtAsc = "DATA_ROOMS_STDDEV_POPULATION_CREATED_AT_ASC",
  DataRoomsStddevPopulationCreatedAtDesc = "DATA_ROOMS_STDDEV_POPULATION_CREATED_AT_DESC",
  DataRoomsStddevPopulationCreatedByEmailAsc = "DATA_ROOMS_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  DataRoomsStddevPopulationCreatedByEmailDesc = "DATA_ROOMS_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  DataRoomsStddevPopulationDataRoomHashAsc = "DATA_ROOMS_STDDEV_POPULATION_DATA_ROOM_HASH_ASC",
  DataRoomsStddevPopulationDataRoomHashDesc = "DATA_ROOMS_STDDEV_POPULATION_DATA_ROOM_HASH_DESC",
  DataRoomsStddevPopulationDataRoomIdAsc = "DATA_ROOMS_STDDEV_POPULATION_DATA_ROOM_ID_ASC",
  DataRoomsStddevPopulationDataRoomIdDesc = "DATA_ROOMS_STDDEV_POPULATION_DATA_ROOM_ID_DESC",
  DataRoomsStddevPopulationDescriptionAsc = "DATA_ROOMS_STDDEV_POPULATION_DESCRIPTION_ASC",
  DataRoomsStddevPopulationDescriptionDesc = "DATA_ROOMS_STDDEV_POPULATION_DESCRIPTION_DESC",
  DataRoomsStddevPopulationEnableDevelopmentTabAsc = "DATA_ROOMS_STDDEV_POPULATION_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomsStddevPopulationEnableDevelopmentTabDesc = "DATA_ROOMS_STDDEV_POPULATION_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomsStddevPopulationGovernanceProtocolAsc = "DATA_ROOMS_STDDEV_POPULATION_GOVERNANCE_PROTOCOL_ASC",
  DataRoomsStddevPopulationGovernanceProtocolDesc = "DATA_ROOMS_STDDEV_POPULATION_GOVERNANCE_PROTOCOL_DESC",
  DataRoomsStddevPopulationMrenclaveAsc = "DATA_ROOMS_STDDEV_POPULATION_MRENCLAVE_ASC",
  DataRoomsStddevPopulationMrenclaveDesc = "DATA_ROOMS_STDDEV_POPULATION_MRENCLAVE_DESC",
  DataRoomsStddevPopulationNameAsc = "DATA_ROOMS_STDDEV_POPULATION_NAME_ASC",
  DataRoomsStddevPopulationNameDesc = "DATA_ROOMS_STDDEV_POPULATION_NAME_DESC",
  DataRoomsStddevPopulationOwnerEmailAsc = "DATA_ROOMS_STDDEV_POPULATION_OWNER_EMAIL_ASC",
  DataRoomsStddevPopulationOwnerEmailDesc = "DATA_ROOMS_STDDEV_POPULATION_OWNER_EMAIL_DESC",
  DataRoomsStddevPopulationSourceAsc = "DATA_ROOMS_STDDEV_POPULATION_SOURCE_ASC",
  DataRoomsStddevPopulationSourceDesc = "DATA_ROOMS_STDDEV_POPULATION_SOURCE_DESC",
  DataRoomsStddevPopulationUpdatedAtAsc = "DATA_ROOMS_STDDEV_POPULATION_UPDATED_AT_ASC",
  DataRoomsStddevPopulationUpdatedAtDesc = "DATA_ROOMS_STDDEV_POPULATION_UPDATED_AT_DESC",
  DataRoomsStddevPopulationUpdatedByEmailAsc = "DATA_ROOMS_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  DataRoomsStddevPopulationUpdatedByEmailDesc = "DATA_ROOMS_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  DataRoomsStddevSampleCreatedAtAsc = "DATA_ROOMS_STDDEV_SAMPLE_CREATED_AT_ASC",
  DataRoomsStddevSampleCreatedAtDesc = "DATA_ROOMS_STDDEV_SAMPLE_CREATED_AT_DESC",
  DataRoomsStddevSampleCreatedByEmailAsc = "DATA_ROOMS_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  DataRoomsStddevSampleCreatedByEmailDesc = "DATA_ROOMS_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  DataRoomsStddevSampleDataRoomHashAsc = "DATA_ROOMS_STDDEV_SAMPLE_DATA_ROOM_HASH_ASC",
  DataRoomsStddevSampleDataRoomHashDesc = "DATA_ROOMS_STDDEV_SAMPLE_DATA_ROOM_HASH_DESC",
  DataRoomsStddevSampleDataRoomIdAsc = "DATA_ROOMS_STDDEV_SAMPLE_DATA_ROOM_ID_ASC",
  DataRoomsStddevSampleDataRoomIdDesc = "DATA_ROOMS_STDDEV_SAMPLE_DATA_ROOM_ID_DESC",
  DataRoomsStddevSampleDescriptionAsc = "DATA_ROOMS_STDDEV_SAMPLE_DESCRIPTION_ASC",
  DataRoomsStddevSampleDescriptionDesc = "DATA_ROOMS_STDDEV_SAMPLE_DESCRIPTION_DESC",
  DataRoomsStddevSampleEnableDevelopmentTabAsc = "DATA_ROOMS_STDDEV_SAMPLE_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomsStddevSampleEnableDevelopmentTabDesc = "DATA_ROOMS_STDDEV_SAMPLE_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomsStddevSampleGovernanceProtocolAsc = "DATA_ROOMS_STDDEV_SAMPLE_GOVERNANCE_PROTOCOL_ASC",
  DataRoomsStddevSampleGovernanceProtocolDesc = "DATA_ROOMS_STDDEV_SAMPLE_GOVERNANCE_PROTOCOL_DESC",
  DataRoomsStddevSampleMrenclaveAsc = "DATA_ROOMS_STDDEV_SAMPLE_MRENCLAVE_ASC",
  DataRoomsStddevSampleMrenclaveDesc = "DATA_ROOMS_STDDEV_SAMPLE_MRENCLAVE_DESC",
  DataRoomsStddevSampleNameAsc = "DATA_ROOMS_STDDEV_SAMPLE_NAME_ASC",
  DataRoomsStddevSampleNameDesc = "DATA_ROOMS_STDDEV_SAMPLE_NAME_DESC",
  DataRoomsStddevSampleOwnerEmailAsc = "DATA_ROOMS_STDDEV_SAMPLE_OWNER_EMAIL_ASC",
  DataRoomsStddevSampleOwnerEmailDesc = "DATA_ROOMS_STDDEV_SAMPLE_OWNER_EMAIL_DESC",
  DataRoomsStddevSampleSourceAsc = "DATA_ROOMS_STDDEV_SAMPLE_SOURCE_ASC",
  DataRoomsStddevSampleSourceDesc = "DATA_ROOMS_STDDEV_SAMPLE_SOURCE_DESC",
  DataRoomsStddevSampleUpdatedAtAsc = "DATA_ROOMS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  DataRoomsStddevSampleUpdatedAtDesc = "DATA_ROOMS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  DataRoomsStddevSampleUpdatedByEmailAsc = "DATA_ROOMS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  DataRoomsStddevSampleUpdatedByEmailDesc = "DATA_ROOMS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  DataRoomsSumCreatedAtAsc = "DATA_ROOMS_SUM_CREATED_AT_ASC",
  DataRoomsSumCreatedAtDesc = "DATA_ROOMS_SUM_CREATED_AT_DESC",
  DataRoomsSumCreatedByEmailAsc = "DATA_ROOMS_SUM_CREATED_BY_EMAIL_ASC",
  DataRoomsSumCreatedByEmailDesc = "DATA_ROOMS_SUM_CREATED_BY_EMAIL_DESC",
  DataRoomsSumDataRoomHashAsc = "DATA_ROOMS_SUM_DATA_ROOM_HASH_ASC",
  DataRoomsSumDataRoomHashDesc = "DATA_ROOMS_SUM_DATA_ROOM_HASH_DESC",
  DataRoomsSumDataRoomIdAsc = "DATA_ROOMS_SUM_DATA_ROOM_ID_ASC",
  DataRoomsSumDataRoomIdDesc = "DATA_ROOMS_SUM_DATA_ROOM_ID_DESC",
  DataRoomsSumDescriptionAsc = "DATA_ROOMS_SUM_DESCRIPTION_ASC",
  DataRoomsSumDescriptionDesc = "DATA_ROOMS_SUM_DESCRIPTION_DESC",
  DataRoomsSumEnableDevelopmentTabAsc = "DATA_ROOMS_SUM_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomsSumEnableDevelopmentTabDesc = "DATA_ROOMS_SUM_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomsSumGovernanceProtocolAsc = "DATA_ROOMS_SUM_GOVERNANCE_PROTOCOL_ASC",
  DataRoomsSumGovernanceProtocolDesc = "DATA_ROOMS_SUM_GOVERNANCE_PROTOCOL_DESC",
  DataRoomsSumMrenclaveAsc = "DATA_ROOMS_SUM_MRENCLAVE_ASC",
  DataRoomsSumMrenclaveDesc = "DATA_ROOMS_SUM_MRENCLAVE_DESC",
  DataRoomsSumNameAsc = "DATA_ROOMS_SUM_NAME_ASC",
  DataRoomsSumNameDesc = "DATA_ROOMS_SUM_NAME_DESC",
  DataRoomsSumOwnerEmailAsc = "DATA_ROOMS_SUM_OWNER_EMAIL_ASC",
  DataRoomsSumOwnerEmailDesc = "DATA_ROOMS_SUM_OWNER_EMAIL_DESC",
  DataRoomsSumSourceAsc = "DATA_ROOMS_SUM_SOURCE_ASC",
  DataRoomsSumSourceDesc = "DATA_ROOMS_SUM_SOURCE_DESC",
  DataRoomsSumUpdatedAtAsc = "DATA_ROOMS_SUM_UPDATED_AT_ASC",
  DataRoomsSumUpdatedAtDesc = "DATA_ROOMS_SUM_UPDATED_AT_DESC",
  DataRoomsSumUpdatedByEmailAsc = "DATA_ROOMS_SUM_UPDATED_BY_EMAIL_ASC",
  DataRoomsSumUpdatedByEmailDesc = "DATA_ROOMS_SUM_UPDATED_BY_EMAIL_DESC",
  DataRoomsVariancePopulationCreatedAtAsc = "DATA_ROOMS_VARIANCE_POPULATION_CREATED_AT_ASC",
  DataRoomsVariancePopulationCreatedAtDesc = "DATA_ROOMS_VARIANCE_POPULATION_CREATED_AT_DESC",
  DataRoomsVariancePopulationCreatedByEmailAsc = "DATA_ROOMS_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  DataRoomsVariancePopulationCreatedByEmailDesc = "DATA_ROOMS_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  DataRoomsVariancePopulationDataRoomHashAsc = "DATA_ROOMS_VARIANCE_POPULATION_DATA_ROOM_HASH_ASC",
  DataRoomsVariancePopulationDataRoomHashDesc = "DATA_ROOMS_VARIANCE_POPULATION_DATA_ROOM_HASH_DESC",
  DataRoomsVariancePopulationDataRoomIdAsc = "DATA_ROOMS_VARIANCE_POPULATION_DATA_ROOM_ID_ASC",
  DataRoomsVariancePopulationDataRoomIdDesc = "DATA_ROOMS_VARIANCE_POPULATION_DATA_ROOM_ID_DESC",
  DataRoomsVariancePopulationDescriptionAsc = "DATA_ROOMS_VARIANCE_POPULATION_DESCRIPTION_ASC",
  DataRoomsVariancePopulationDescriptionDesc = "DATA_ROOMS_VARIANCE_POPULATION_DESCRIPTION_DESC",
  DataRoomsVariancePopulationEnableDevelopmentTabAsc = "DATA_ROOMS_VARIANCE_POPULATION_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomsVariancePopulationEnableDevelopmentTabDesc = "DATA_ROOMS_VARIANCE_POPULATION_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomsVariancePopulationGovernanceProtocolAsc = "DATA_ROOMS_VARIANCE_POPULATION_GOVERNANCE_PROTOCOL_ASC",
  DataRoomsVariancePopulationGovernanceProtocolDesc = "DATA_ROOMS_VARIANCE_POPULATION_GOVERNANCE_PROTOCOL_DESC",
  DataRoomsVariancePopulationMrenclaveAsc = "DATA_ROOMS_VARIANCE_POPULATION_MRENCLAVE_ASC",
  DataRoomsVariancePopulationMrenclaveDesc = "DATA_ROOMS_VARIANCE_POPULATION_MRENCLAVE_DESC",
  DataRoomsVariancePopulationNameAsc = "DATA_ROOMS_VARIANCE_POPULATION_NAME_ASC",
  DataRoomsVariancePopulationNameDesc = "DATA_ROOMS_VARIANCE_POPULATION_NAME_DESC",
  DataRoomsVariancePopulationOwnerEmailAsc = "DATA_ROOMS_VARIANCE_POPULATION_OWNER_EMAIL_ASC",
  DataRoomsVariancePopulationOwnerEmailDesc = "DATA_ROOMS_VARIANCE_POPULATION_OWNER_EMAIL_DESC",
  DataRoomsVariancePopulationSourceAsc = "DATA_ROOMS_VARIANCE_POPULATION_SOURCE_ASC",
  DataRoomsVariancePopulationSourceDesc = "DATA_ROOMS_VARIANCE_POPULATION_SOURCE_DESC",
  DataRoomsVariancePopulationUpdatedAtAsc = "DATA_ROOMS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  DataRoomsVariancePopulationUpdatedAtDesc = "DATA_ROOMS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  DataRoomsVariancePopulationUpdatedByEmailAsc = "DATA_ROOMS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  DataRoomsVariancePopulationUpdatedByEmailDesc = "DATA_ROOMS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  DataRoomsVarianceSampleCreatedAtAsc = "DATA_ROOMS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  DataRoomsVarianceSampleCreatedAtDesc = "DATA_ROOMS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  DataRoomsVarianceSampleCreatedByEmailAsc = "DATA_ROOMS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  DataRoomsVarianceSampleCreatedByEmailDesc = "DATA_ROOMS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  DataRoomsVarianceSampleDataRoomHashAsc = "DATA_ROOMS_VARIANCE_SAMPLE_DATA_ROOM_HASH_ASC",
  DataRoomsVarianceSampleDataRoomHashDesc = "DATA_ROOMS_VARIANCE_SAMPLE_DATA_ROOM_HASH_DESC",
  DataRoomsVarianceSampleDataRoomIdAsc = "DATA_ROOMS_VARIANCE_SAMPLE_DATA_ROOM_ID_ASC",
  DataRoomsVarianceSampleDataRoomIdDesc = "DATA_ROOMS_VARIANCE_SAMPLE_DATA_ROOM_ID_DESC",
  DataRoomsVarianceSampleDescriptionAsc = "DATA_ROOMS_VARIANCE_SAMPLE_DESCRIPTION_ASC",
  DataRoomsVarianceSampleDescriptionDesc = "DATA_ROOMS_VARIANCE_SAMPLE_DESCRIPTION_DESC",
  DataRoomsVarianceSampleEnableDevelopmentTabAsc = "DATA_ROOMS_VARIANCE_SAMPLE_ENABLE_DEVELOPMENT_TAB_ASC",
  DataRoomsVarianceSampleEnableDevelopmentTabDesc = "DATA_ROOMS_VARIANCE_SAMPLE_ENABLE_DEVELOPMENT_TAB_DESC",
  DataRoomsVarianceSampleGovernanceProtocolAsc = "DATA_ROOMS_VARIANCE_SAMPLE_GOVERNANCE_PROTOCOL_ASC",
  DataRoomsVarianceSampleGovernanceProtocolDesc = "DATA_ROOMS_VARIANCE_SAMPLE_GOVERNANCE_PROTOCOL_DESC",
  DataRoomsVarianceSampleMrenclaveAsc = "DATA_ROOMS_VARIANCE_SAMPLE_MRENCLAVE_ASC",
  DataRoomsVarianceSampleMrenclaveDesc = "DATA_ROOMS_VARIANCE_SAMPLE_MRENCLAVE_DESC",
  DataRoomsVarianceSampleNameAsc = "DATA_ROOMS_VARIANCE_SAMPLE_NAME_ASC",
  DataRoomsVarianceSampleNameDesc = "DATA_ROOMS_VARIANCE_SAMPLE_NAME_DESC",
  DataRoomsVarianceSampleOwnerEmailAsc = "DATA_ROOMS_VARIANCE_SAMPLE_OWNER_EMAIL_ASC",
  DataRoomsVarianceSampleOwnerEmailDesc = "DATA_ROOMS_VARIANCE_SAMPLE_OWNER_EMAIL_DESC",
  DataRoomsVarianceSampleSourceAsc = "DATA_ROOMS_VARIANCE_SAMPLE_SOURCE_ASC",
  DataRoomsVarianceSampleSourceDesc = "DATA_ROOMS_VARIANCE_SAMPLE_SOURCE_DESC",
  DataRoomsVarianceSampleUpdatedAtAsc = "DATA_ROOMS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  DataRoomsVarianceSampleUpdatedAtDesc = "DATA_ROOMS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  DataRoomsVarianceSampleUpdatedByEmailAsc = "DATA_ROOMS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  DataRoomsVarianceSampleUpdatedByEmailDesc = "DATA_ROOMS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  DomainAsc = "DOMAIN_ASC",
  DomainByDomainCreatedAtAsc = "DOMAIN_BY_DOMAIN_CREATED_AT_ASC",
  DomainByDomainCreatedAtDesc = "DOMAIN_BY_DOMAIN_CREATED_AT_DESC",
  DomainByDomainCreatedByEmailAsc = "DOMAIN_BY_DOMAIN_CREATED_BY_EMAIL_ASC",
  DomainByDomainCreatedByEmailDesc = "DOMAIN_BY_DOMAIN_CREATED_BY_EMAIL_DESC",
  DomainByDomainDomainAsc = "DOMAIN_BY_DOMAIN_DOMAIN_ASC",
  DomainByDomainDomainDesc = "DOMAIN_BY_DOMAIN_DOMAIN_DESC",
  DomainByDomainDomainIdAsc = "DOMAIN_BY_DOMAIN_DOMAIN_ID_ASC",
  DomainByDomainDomainIdDesc = "DOMAIN_BY_DOMAIN_DOMAIN_ID_DESC",
  DomainByDomainOrganizationIdAsc = "DOMAIN_BY_DOMAIN_ORGANIZATION_ID_ASC",
  DomainByDomainOrganizationIdDesc = "DOMAIN_BY_DOMAIN_ORGANIZATION_ID_DESC",
  DomainByDomainUpdatedAtAsc = "DOMAIN_BY_DOMAIN_UPDATED_AT_ASC",
  DomainByDomainUpdatedAtDesc = "DOMAIN_BY_DOMAIN_UPDATED_AT_DESC",
  DomainByDomainUpdatedByEmailAsc = "DOMAIN_BY_DOMAIN_UPDATED_BY_EMAIL_ASC",
  DomainByDomainUpdatedByEmailDesc = "DOMAIN_BY_DOMAIN_UPDATED_BY_EMAIL_DESC",
  DomainDesc = "DOMAIN_DESC",
  EmailAsc = "EMAIL_ASC",
  EmailDesc = "EMAIL_DESC",
  EmailVerifiedAsc = "EMAIL_VERIFIED_ASC",
  EmailVerifiedDesc = "EMAIL_VERIFIED_DESC",
  FamilyNameAsc = "FAMILY_NAME_ASC",
  FamilyNameDesc = "FAMILY_NAME_DESC",
  GivenNameAsc = "GIVEN_NAME_ASC",
  GivenNameDesc = "GIVEN_NAME_DESC",
  NameAsc = "NAME_ASC",
  NameDesc = "NAME_DESC",
  Natural = "NATURAL",
  NicknameAsc = "NICKNAME_ASC",
  NicknameDesc = "NICKNAME_DESC",
  OrganizationMembershipsAverageCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_CREATED_AT_ASC",
  OrganizationMembershipsAverageCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_CREATED_AT_DESC",
  OrganizationMembershipsAverageCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsAverageCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsAverageOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_ORGANIZATION_ID_ASC",
  OrganizationMembershipsAverageOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_ORGANIZATION_ID_DESC",
  OrganizationMembershipsAverageOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsAverageOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsAverageUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_UPDATED_AT_ASC",
  OrganizationMembershipsAverageUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_UPDATED_AT_DESC",
  OrganizationMembershipsAverageUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsAverageUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsAverageUserIdAsc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_USER_ID_ASC",
  OrganizationMembershipsAverageUserIdDesc = "ORGANIZATION_MEMBERSHIPS_AVERAGE_USER_ID_DESC",
  OrganizationMembershipsByUserIdCountAsc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_COUNT_ASC",
  OrganizationMembershipsByUserIdCountDesc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_COUNT_DESC",
  OrganizationMembershipsByUserIdMaxCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MAX_CREATED_AT_ASC",
  OrganizationMembershipsByUserIdMaxCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MAX_CREATED_AT_DESC",
  OrganizationMembershipsByUserIdMaxCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MAX_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsByUserIdMaxCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MAX_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsByUserIdMaxOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MAX_ORGANIZATION_ID_ASC",
  OrganizationMembershipsByUserIdMaxOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MAX_ORGANIZATION_ID_DESC",
  OrganizationMembershipsByUserIdMaxOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MAX_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsByUserIdMaxOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MAX_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsByUserIdMaxUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MAX_UPDATED_AT_ASC",
  OrganizationMembershipsByUserIdMaxUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MAX_UPDATED_AT_DESC",
  OrganizationMembershipsByUserIdMaxUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MAX_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsByUserIdMaxUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MAX_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsByUserIdMaxUserIdAsc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MAX_USER_ID_ASC",
  OrganizationMembershipsByUserIdMaxUserIdDesc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MAX_USER_ID_DESC",
  OrganizationMembershipsByUserIdMinCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MIN_CREATED_AT_ASC",
  OrganizationMembershipsByUserIdMinCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MIN_CREATED_AT_DESC",
  OrganizationMembershipsByUserIdMinCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MIN_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsByUserIdMinCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MIN_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsByUserIdMinOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MIN_ORGANIZATION_ID_ASC",
  OrganizationMembershipsByUserIdMinOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MIN_ORGANIZATION_ID_DESC",
  OrganizationMembershipsByUserIdMinOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MIN_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsByUserIdMinOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MIN_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsByUserIdMinUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MIN_UPDATED_AT_ASC",
  OrganizationMembershipsByUserIdMinUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MIN_UPDATED_AT_DESC",
  OrganizationMembershipsByUserIdMinUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MIN_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsByUserIdMinUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MIN_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsByUserIdMinUserIdAsc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MIN_USER_ID_ASC",
  OrganizationMembershipsByUserIdMinUserIdDesc = "ORGANIZATION_MEMBERSHIPS_BY_USER_ID_MIN_USER_ID_DESC",
  OrganizationMembershipsCountAsc = "ORGANIZATION_MEMBERSHIPS_COUNT_ASC",
  OrganizationMembershipsCountDesc = "ORGANIZATION_MEMBERSHIPS_COUNT_DESC",
  OrganizationMembershipsDistinctCountCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_CREATED_AT_ASC",
  OrganizationMembershipsDistinctCountCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_CREATED_AT_DESC",
  OrganizationMembershipsDistinctCountCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsDistinctCountCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsDistinctCountOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_ORGANIZATION_ID_ASC",
  OrganizationMembershipsDistinctCountOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_ORGANIZATION_ID_DESC",
  OrganizationMembershipsDistinctCountOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsDistinctCountOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsDistinctCountUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_UPDATED_AT_ASC",
  OrganizationMembershipsDistinctCountUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_UPDATED_AT_DESC",
  OrganizationMembershipsDistinctCountUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsDistinctCountUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsDistinctCountUserIdAsc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_USER_ID_ASC",
  OrganizationMembershipsDistinctCountUserIdDesc = "ORGANIZATION_MEMBERSHIPS_DISTINCT_COUNT_USER_ID_DESC",
  OrganizationMembershipsMaxCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_MAX_CREATED_AT_ASC",
  OrganizationMembershipsMaxCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_MAX_CREATED_AT_DESC",
  OrganizationMembershipsMaxCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_MAX_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsMaxCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_MAX_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsMaxOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_MAX_ORGANIZATION_ID_ASC",
  OrganizationMembershipsMaxOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_MAX_ORGANIZATION_ID_DESC",
  OrganizationMembershipsMaxOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_MAX_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsMaxOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_MAX_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsMaxUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_MAX_UPDATED_AT_ASC",
  OrganizationMembershipsMaxUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_MAX_UPDATED_AT_DESC",
  OrganizationMembershipsMaxUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_MAX_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsMaxUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_MAX_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsMaxUserIdAsc = "ORGANIZATION_MEMBERSHIPS_MAX_USER_ID_ASC",
  OrganizationMembershipsMaxUserIdDesc = "ORGANIZATION_MEMBERSHIPS_MAX_USER_ID_DESC",
  OrganizationMembershipsMinCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_MIN_CREATED_AT_ASC",
  OrganizationMembershipsMinCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_MIN_CREATED_AT_DESC",
  OrganizationMembershipsMinCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_MIN_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsMinCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_MIN_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsMinOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_MIN_ORGANIZATION_ID_ASC",
  OrganizationMembershipsMinOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_MIN_ORGANIZATION_ID_DESC",
  OrganizationMembershipsMinOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_MIN_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsMinOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_MIN_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsMinUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_MIN_UPDATED_AT_ASC",
  OrganizationMembershipsMinUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_MIN_UPDATED_AT_DESC",
  OrganizationMembershipsMinUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_MIN_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsMinUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_MIN_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsMinUserIdAsc = "ORGANIZATION_MEMBERSHIPS_MIN_USER_ID_ASC",
  OrganizationMembershipsMinUserIdDesc = "ORGANIZATION_MEMBERSHIPS_MIN_USER_ID_DESC",
  OrganizationMembershipsStddevPopulationCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_CREATED_AT_ASC",
  OrganizationMembershipsStddevPopulationCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_CREATED_AT_DESC",
  OrganizationMembershipsStddevPopulationCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsStddevPopulationCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsStddevPopulationOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_ORGANIZATION_ID_ASC",
  OrganizationMembershipsStddevPopulationOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_ORGANIZATION_ID_DESC",
  OrganizationMembershipsStddevPopulationOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsStddevPopulationOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsStddevPopulationUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_UPDATED_AT_ASC",
  OrganizationMembershipsStddevPopulationUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_UPDATED_AT_DESC",
  OrganizationMembershipsStddevPopulationUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsStddevPopulationUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsStddevPopulationUserIdAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_USER_ID_ASC",
  OrganizationMembershipsStddevPopulationUserIdDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_POPULATION_USER_ID_DESC",
  OrganizationMembershipsStddevSampleCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_CREATED_AT_ASC",
  OrganizationMembershipsStddevSampleCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_CREATED_AT_DESC",
  OrganizationMembershipsStddevSampleCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsStddevSampleCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsStddevSampleOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_ORGANIZATION_ID_ASC",
  OrganizationMembershipsStddevSampleOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_ORGANIZATION_ID_DESC",
  OrganizationMembershipsStddevSampleOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsStddevSampleOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsStddevSampleUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  OrganizationMembershipsStddevSampleUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  OrganizationMembershipsStddevSampleUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsStddevSampleUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsStddevSampleUserIdAsc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_USER_ID_ASC",
  OrganizationMembershipsStddevSampleUserIdDesc = "ORGANIZATION_MEMBERSHIPS_STDDEV_SAMPLE_USER_ID_DESC",
  OrganizationMembershipsSumCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_SUM_CREATED_AT_ASC",
  OrganizationMembershipsSumCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_SUM_CREATED_AT_DESC",
  OrganizationMembershipsSumCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_SUM_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsSumCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_SUM_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsSumOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_SUM_ORGANIZATION_ID_ASC",
  OrganizationMembershipsSumOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_SUM_ORGANIZATION_ID_DESC",
  OrganizationMembershipsSumOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_SUM_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsSumOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_SUM_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsSumUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_SUM_UPDATED_AT_ASC",
  OrganizationMembershipsSumUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_SUM_UPDATED_AT_DESC",
  OrganizationMembershipsSumUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_SUM_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsSumUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_SUM_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsSumUserIdAsc = "ORGANIZATION_MEMBERSHIPS_SUM_USER_ID_ASC",
  OrganizationMembershipsSumUserIdDesc = "ORGANIZATION_MEMBERSHIPS_SUM_USER_ID_DESC",
  OrganizationMembershipsVariancePopulationCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_CREATED_AT_ASC",
  OrganizationMembershipsVariancePopulationCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_CREATED_AT_DESC",
  OrganizationMembershipsVariancePopulationCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsVariancePopulationCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsVariancePopulationOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_ORGANIZATION_ID_ASC",
  OrganizationMembershipsVariancePopulationOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_ORGANIZATION_ID_DESC",
  OrganizationMembershipsVariancePopulationOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsVariancePopulationOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsVariancePopulationUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  OrganizationMembershipsVariancePopulationUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  OrganizationMembershipsVariancePopulationUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsVariancePopulationUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsVariancePopulationUserIdAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_USER_ID_ASC",
  OrganizationMembershipsVariancePopulationUserIdDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_POPULATION_USER_ID_DESC",
  OrganizationMembershipsVarianceSampleCreatedAtAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  OrganizationMembershipsVarianceSampleCreatedAtDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  OrganizationMembershipsVarianceSampleCreatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  OrganizationMembershipsVarianceSampleCreatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  OrganizationMembershipsVarianceSampleOrganizationIdAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_ORGANIZATION_ID_ASC",
  OrganizationMembershipsVarianceSampleOrganizationIdDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_ORGANIZATION_ID_DESC",
  OrganizationMembershipsVarianceSampleOrganizationMembershipIdAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_ORGANIZATION_MEMBERSHIP_ID_ASC",
  OrganizationMembershipsVarianceSampleOrganizationMembershipIdDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_ORGANIZATION_MEMBERSHIP_ID_DESC",
  OrganizationMembershipsVarianceSampleUpdatedAtAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  OrganizationMembershipsVarianceSampleUpdatedAtDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  OrganizationMembershipsVarianceSampleUpdatedByEmailAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  OrganizationMembershipsVarianceSampleUpdatedByEmailDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  OrganizationMembershipsVarianceSampleUserIdAsc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_USER_ID_ASC",
  OrganizationMembershipsVarianceSampleUserIdDesc = "ORGANIZATION_MEMBERSHIPS_VARIANCE_SAMPLE_USER_ID_DESC",
  PictureAsc = "PICTURE_ASC",
  PictureDesc = "PICTURE_DESC",
  PrimaryKeyAsc = "PRIMARY_KEY_ASC",
  PrimaryKeyDesc = "PRIMARY_KEY_DESC",
  UpdatedAtAsc = "UPDATED_AT_ASC",
  UpdatedAtDesc = "UPDATED_AT_DESC",
  UpdatedByEmailAsc = "UPDATED_BY_EMAIL_ASC",
  UpdatedByEmailDesc = "UPDATED_BY_EMAIL_DESC",
  UpstreamUserIdAsc = "UPSTREAM_USER_ID_ASC",
  UpstreamUserIdDesc = "UPSTREAM_USER_ID_DESC",
  UserAttributeByUserIdCreatedAtAsc = "USER_ATTRIBUTE_BY_USER_ID_CREATED_AT_ASC",
  UserAttributeByUserIdCreatedAtDesc = "USER_ATTRIBUTE_BY_USER_ID_CREATED_AT_DESC",
  UserAttributeByUserIdCreatedByEmailAsc = "USER_ATTRIBUTE_BY_USER_ID_CREATED_BY_EMAIL_ASC",
  UserAttributeByUserIdCreatedByEmailDesc = "USER_ATTRIBUTE_BY_USER_ID_CREATED_BY_EMAIL_DESC",
  UserAttributeByUserIdIsDemoUserAsc = "USER_ATTRIBUTE_BY_USER_ID_IS_DEMO_USER_ASC",
  UserAttributeByUserIdIsDemoUserDesc = "USER_ATTRIBUTE_BY_USER_ID_IS_DEMO_USER_DESC",
  UserAttributeByUserIdUpdatedAtAsc = "USER_ATTRIBUTE_BY_USER_ID_UPDATED_AT_ASC",
  UserAttributeByUserIdUpdatedAtDesc = "USER_ATTRIBUTE_BY_USER_ID_UPDATED_AT_DESC",
  UserAttributeByUserIdUpdatedByEmailAsc = "USER_ATTRIBUTE_BY_USER_ID_UPDATED_BY_EMAIL_ASC",
  UserAttributeByUserIdUpdatedByEmailDesc = "USER_ATTRIBUTE_BY_USER_ID_UPDATED_BY_EMAIL_DESC",
  UserAttributeByUserIdUserIdAsc = "USER_ATTRIBUTE_BY_USER_ID_USER_ID_ASC",
  UserAttributeByUserIdUserIdDesc = "USER_ATTRIBUTE_BY_USER_ID_USER_ID_DESC",
  UserConfigsAverageCreatedAtAsc = "USER_CONFIGS_AVERAGE_CREATED_AT_ASC",
  UserConfigsAverageCreatedAtDesc = "USER_CONFIGS_AVERAGE_CREATED_AT_DESC",
  UserConfigsAverageCreatedByEmailAsc = "USER_CONFIGS_AVERAGE_CREATED_BY_EMAIL_ASC",
  UserConfigsAverageCreatedByEmailDesc = "USER_CONFIGS_AVERAGE_CREATED_BY_EMAIL_DESC",
  UserConfigsAverageDataRoomIdAsc = "USER_CONFIGS_AVERAGE_DATA_ROOM_ID_ASC",
  UserConfigsAverageDataRoomIdDesc = "USER_CONFIGS_AVERAGE_DATA_ROOM_ID_DESC",
  UserConfigsAverageEmailAsc = "USER_CONFIGS_AVERAGE_EMAIL_ASC",
  UserConfigsAverageEmailDesc = "USER_CONFIGS_AVERAGE_EMAIL_DESC",
  UserConfigsAverageIsArchivedAsc = "USER_CONFIGS_AVERAGE_IS_ARCHIVED_ASC",
  UserConfigsAverageIsArchivedDesc = "USER_CONFIGS_AVERAGE_IS_ARCHIVED_DESC",
  UserConfigsAverageIsFavoriteAsc = "USER_CONFIGS_AVERAGE_IS_FAVORITE_ASC",
  UserConfigsAverageIsFavoriteDesc = "USER_CONFIGS_AVERAGE_IS_FAVORITE_DESC",
  UserConfigsAverageUpdatedAtAsc = "USER_CONFIGS_AVERAGE_UPDATED_AT_ASC",
  UserConfigsAverageUpdatedAtDesc = "USER_CONFIGS_AVERAGE_UPDATED_AT_DESC",
  UserConfigsAverageUpdatedByEmailAsc = "USER_CONFIGS_AVERAGE_UPDATED_BY_EMAIL_ASC",
  UserConfigsAverageUpdatedByEmailDesc = "USER_CONFIGS_AVERAGE_UPDATED_BY_EMAIL_DESC",
  UserConfigsAverageUserConfigIdAsc = "USER_CONFIGS_AVERAGE_USER_CONFIG_ID_ASC",
  UserConfigsAverageUserConfigIdDesc = "USER_CONFIGS_AVERAGE_USER_CONFIG_ID_DESC",
  UserConfigsByEmailCountAsc = "USER_CONFIGS_BY_EMAIL_COUNT_ASC",
  UserConfigsByEmailCountDesc = "USER_CONFIGS_BY_EMAIL_COUNT_DESC",
  UserConfigsByEmailMaxCreatedAtAsc = "USER_CONFIGS_BY_EMAIL_MAX_CREATED_AT_ASC",
  UserConfigsByEmailMaxCreatedAtDesc = "USER_CONFIGS_BY_EMAIL_MAX_CREATED_AT_DESC",
  UserConfigsByEmailMaxCreatedByEmailAsc = "USER_CONFIGS_BY_EMAIL_MAX_CREATED_BY_EMAIL_ASC",
  UserConfigsByEmailMaxCreatedByEmailDesc = "USER_CONFIGS_BY_EMAIL_MAX_CREATED_BY_EMAIL_DESC",
  UserConfigsByEmailMaxDataRoomIdAsc = "USER_CONFIGS_BY_EMAIL_MAX_DATA_ROOM_ID_ASC",
  UserConfigsByEmailMaxDataRoomIdDesc = "USER_CONFIGS_BY_EMAIL_MAX_DATA_ROOM_ID_DESC",
  UserConfigsByEmailMaxEmailAsc = "USER_CONFIGS_BY_EMAIL_MAX_EMAIL_ASC",
  UserConfigsByEmailMaxEmailDesc = "USER_CONFIGS_BY_EMAIL_MAX_EMAIL_DESC",
  UserConfigsByEmailMaxIsArchivedAsc = "USER_CONFIGS_BY_EMAIL_MAX_IS_ARCHIVED_ASC",
  UserConfigsByEmailMaxIsArchivedDesc = "USER_CONFIGS_BY_EMAIL_MAX_IS_ARCHIVED_DESC",
  UserConfigsByEmailMaxIsFavoriteAsc = "USER_CONFIGS_BY_EMAIL_MAX_IS_FAVORITE_ASC",
  UserConfigsByEmailMaxIsFavoriteDesc = "USER_CONFIGS_BY_EMAIL_MAX_IS_FAVORITE_DESC",
  UserConfigsByEmailMaxUpdatedAtAsc = "USER_CONFIGS_BY_EMAIL_MAX_UPDATED_AT_ASC",
  UserConfigsByEmailMaxUpdatedAtDesc = "USER_CONFIGS_BY_EMAIL_MAX_UPDATED_AT_DESC",
  UserConfigsByEmailMaxUpdatedByEmailAsc = "USER_CONFIGS_BY_EMAIL_MAX_UPDATED_BY_EMAIL_ASC",
  UserConfigsByEmailMaxUpdatedByEmailDesc = "USER_CONFIGS_BY_EMAIL_MAX_UPDATED_BY_EMAIL_DESC",
  UserConfigsByEmailMaxUserConfigIdAsc = "USER_CONFIGS_BY_EMAIL_MAX_USER_CONFIG_ID_ASC",
  UserConfigsByEmailMaxUserConfigIdDesc = "USER_CONFIGS_BY_EMAIL_MAX_USER_CONFIG_ID_DESC",
  UserConfigsByEmailMinCreatedAtAsc = "USER_CONFIGS_BY_EMAIL_MIN_CREATED_AT_ASC",
  UserConfigsByEmailMinCreatedAtDesc = "USER_CONFIGS_BY_EMAIL_MIN_CREATED_AT_DESC",
  UserConfigsByEmailMinCreatedByEmailAsc = "USER_CONFIGS_BY_EMAIL_MIN_CREATED_BY_EMAIL_ASC",
  UserConfigsByEmailMinCreatedByEmailDesc = "USER_CONFIGS_BY_EMAIL_MIN_CREATED_BY_EMAIL_DESC",
  UserConfigsByEmailMinDataRoomIdAsc = "USER_CONFIGS_BY_EMAIL_MIN_DATA_ROOM_ID_ASC",
  UserConfigsByEmailMinDataRoomIdDesc = "USER_CONFIGS_BY_EMAIL_MIN_DATA_ROOM_ID_DESC",
  UserConfigsByEmailMinEmailAsc = "USER_CONFIGS_BY_EMAIL_MIN_EMAIL_ASC",
  UserConfigsByEmailMinEmailDesc = "USER_CONFIGS_BY_EMAIL_MIN_EMAIL_DESC",
  UserConfigsByEmailMinIsArchivedAsc = "USER_CONFIGS_BY_EMAIL_MIN_IS_ARCHIVED_ASC",
  UserConfigsByEmailMinIsArchivedDesc = "USER_CONFIGS_BY_EMAIL_MIN_IS_ARCHIVED_DESC",
  UserConfigsByEmailMinIsFavoriteAsc = "USER_CONFIGS_BY_EMAIL_MIN_IS_FAVORITE_ASC",
  UserConfigsByEmailMinIsFavoriteDesc = "USER_CONFIGS_BY_EMAIL_MIN_IS_FAVORITE_DESC",
  UserConfigsByEmailMinUpdatedAtAsc = "USER_CONFIGS_BY_EMAIL_MIN_UPDATED_AT_ASC",
  UserConfigsByEmailMinUpdatedAtDesc = "USER_CONFIGS_BY_EMAIL_MIN_UPDATED_AT_DESC",
  UserConfigsByEmailMinUpdatedByEmailAsc = "USER_CONFIGS_BY_EMAIL_MIN_UPDATED_BY_EMAIL_ASC",
  UserConfigsByEmailMinUpdatedByEmailDesc = "USER_CONFIGS_BY_EMAIL_MIN_UPDATED_BY_EMAIL_DESC",
  UserConfigsByEmailMinUserConfigIdAsc = "USER_CONFIGS_BY_EMAIL_MIN_USER_CONFIG_ID_ASC",
  UserConfigsByEmailMinUserConfigIdDesc = "USER_CONFIGS_BY_EMAIL_MIN_USER_CONFIG_ID_DESC",
  UserConfigsCountAsc = "USER_CONFIGS_COUNT_ASC",
  UserConfigsCountDesc = "USER_CONFIGS_COUNT_DESC",
  UserConfigsDistinctCountCreatedAtAsc = "USER_CONFIGS_DISTINCT_COUNT_CREATED_AT_ASC",
  UserConfigsDistinctCountCreatedAtDesc = "USER_CONFIGS_DISTINCT_COUNT_CREATED_AT_DESC",
  UserConfigsDistinctCountCreatedByEmailAsc = "USER_CONFIGS_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  UserConfigsDistinctCountCreatedByEmailDesc = "USER_CONFIGS_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  UserConfigsDistinctCountDataRoomIdAsc = "USER_CONFIGS_DISTINCT_COUNT_DATA_ROOM_ID_ASC",
  UserConfigsDistinctCountDataRoomIdDesc = "USER_CONFIGS_DISTINCT_COUNT_DATA_ROOM_ID_DESC",
  UserConfigsDistinctCountEmailAsc = "USER_CONFIGS_DISTINCT_COUNT_EMAIL_ASC",
  UserConfigsDistinctCountEmailDesc = "USER_CONFIGS_DISTINCT_COUNT_EMAIL_DESC",
  UserConfigsDistinctCountIsArchivedAsc = "USER_CONFIGS_DISTINCT_COUNT_IS_ARCHIVED_ASC",
  UserConfigsDistinctCountIsArchivedDesc = "USER_CONFIGS_DISTINCT_COUNT_IS_ARCHIVED_DESC",
  UserConfigsDistinctCountIsFavoriteAsc = "USER_CONFIGS_DISTINCT_COUNT_IS_FAVORITE_ASC",
  UserConfigsDistinctCountIsFavoriteDesc = "USER_CONFIGS_DISTINCT_COUNT_IS_FAVORITE_DESC",
  UserConfigsDistinctCountUpdatedAtAsc = "USER_CONFIGS_DISTINCT_COUNT_UPDATED_AT_ASC",
  UserConfigsDistinctCountUpdatedAtDesc = "USER_CONFIGS_DISTINCT_COUNT_UPDATED_AT_DESC",
  UserConfigsDistinctCountUpdatedByEmailAsc = "USER_CONFIGS_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  UserConfigsDistinctCountUpdatedByEmailDesc = "USER_CONFIGS_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  UserConfigsDistinctCountUserConfigIdAsc = "USER_CONFIGS_DISTINCT_COUNT_USER_CONFIG_ID_ASC",
  UserConfigsDistinctCountUserConfigIdDesc = "USER_CONFIGS_DISTINCT_COUNT_USER_CONFIG_ID_DESC",
  UserConfigsMaxCreatedAtAsc = "USER_CONFIGS_MAX_CREATED_AT_ASC",
  UserConfigsMaxCreatedAtDesc = "USER_CONFIGS_MAX_CREATED_AT_DESC",
  UserConfigsMaxCreatedByEmailAsc = "USER_CONFIGS_MAX_CREATED_BY_EMAIL_ASC",
  UserConfigsMaxCreatedByEmailDesc = "USER_CONFIGS_MAX_CREATED_BY_EMAIL_DESC",
  UserConfigsMaxDataRoomIdAsc = "USER_CONFIGS_MAX_DATA_ROOM_ID_ASC",
  UserConfigsMaxDataRoomIdDesc = "USER_CONFIGS_MAX_DATA_ROOM_ID_DESC",
  UserConfigsMaxEmailAsc = "USER_CONFIGS_MAX_EMAIL_ASC",
  UserConfigsMaxEmailDesc = "USER_CONFIGS_MAX_EMAIL_DESC",
  UserConfigsMaxIsArchivedAsc = "USER_CONFIGS_MAX_IS_ARCHIVED_ASC",
  UserConfigsMaxIsArchivedDesc = "USER_CONFIGS_MAX_IS_ARCHIVED_DESC",
  UserConfigsMaxIsFavoriteAsc = "USER_CONFIGS_MAX_IS_FAVORITE_ASC",
  UserConfigsMaxIsFavoriteDesc = "USER_CONFIGS_MAX_IS_FAVORITE_DESC",
  UserConfigsMaxUpdatedAtAsc = "USER_CONFIGS_MAX_UPDATED_AT_ASC",
  UserConfigsMaxUpdatedAtDesc = "USER_CONFIGS_MAX_UPDATED_AT_DESC",
  UserConfigsMaxUpdatedByEmailAsc = "USER_CONFIGS_MAX_UPDATED_BY_EMAIL_ASC",
  UserConfigsMaxUpdatedByEmailDesc = "USER_CONFIGS_MAX_UPDATED_BY_EMAIL_DESC",
  UserConfigsMaxUserConfigIdAsc = "USER_CONFIGS_MAX_USER_CONFIG_ID_ASC",
  UserConfigsMaxUserConfigIdDesc = "USER_CONFIGS_MAX_USER_CONFIG_ID_DESC",
  UserConfigsMinCreatedAtAsc = "USER_CONFIGS_MIN_CREATED_AT_ASC",
  UserConfigsMinCreatedAtDesc = "USER_CONFIGS_MIN_CREATED_AT_DESC",
  UserConfigsMinCreatedByEmailAsc = "USER_CONFIGS_MIN_CREATED_BY_EMAIL_ASC",
  UserConfigsMinCreatedByEmailDesc = "USER_CONFIGS_MIN_CREATED_BY_EMAIL_DESC",
  UserConfigsMinDataRoomIdAsc = "USER_CONFIGS_MIN_DATA_ROOM_ID_ASC",
  UserConfigsMinDataRoomIdDesc = "USER_CONFIGS_MIN_DATA_ROOM_ID_DESC",
  UserConfigsMinEmailAsc = "USER_CONFIGS_MIN_EMAIL_ASC",
  UserConfigsMinEmailDesc = "USER_CONFIGS_MIN_EMAIL_DESC",
  UserConfigsMinIsArchivedAsc = "USER_CONFIGS_MIN_IS_ARCHIVED_ASC",
  UserConfigsMinIsArchivedDesc = "USER_CONFIGS_MIN_IS_ARCHIVED_DESC",
  UserConfigsMinIsFavoriteAsc = "USER_CONFIGS_MIN_IS_FAVORITE_ASC",
  UserConfigsMinIsFavoriteDesc = "USER_CONFIGS_MIN_IS_FAVORITE_DESC",
  UserConfigsMinUpdatedAtAsc = "USER_CONFIGS_MIN_UPDATED_AT_ASC",
  UserConfigsMinUpdatedAtDesc = "USER_CONFIGS_MIN_UPDATED_AT_DESC",
  UserConfigsMinUpdatedByEmailAsc = "USER_CONFIGS_MIN_UPDATED_BY_EMAIL_ASC",
  UserConfigsMinUpdatedByEmailDesc = "USER_CONFIGS_MIN_UPDATED_BY_EMAIL_DESC",
  UserConfigsMinUserConfigIdAsc = "USER_CONFIGS_MIN_USER_CONFIG_ID_ASC",
  UserConfigsMinUserConfigIdDesc = "USER_CONFIGS_MIN_USER_CONFIG_ID_DESC",
  UserConfigsStddevPopulationCreatedAtAsc = "USER_CONFIGS_STDDEV_POPULATION_CREATED_AT_ASC",
  UserConfigsStddevPopulationCreatedAtDesc = "USER_CONFIGS_STDDEV_POPULATION_CREATED_AT_DESC",
  UserConfigsStddevPopulationCreatedByEmailAsc = "USER_CONFIGS_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  UserConfigsStddevPopulationCreatedByEmailDesc = "USER_CONFIGS_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  UserConfigsStddevPopulationDataRoomIdAsc = "USER_CONFIGS_STDDEV_POPULATION_DATA_ROOM_ID_ASC",
  UserConfigsStddevPopulationDataRoomIdDesc = "USER_CONFIGS_STDDEV_POPULATION_DATA_ROOM_ID_DESC",
  UserConfigsStddevPopulationEmailAsc = "USER_CONFIGS_STDDEV_POPULATION_EMAIL_ASC",
  UserConfigsStddevPopulationEmailDesc = "USER_CONFIGS_STDDEV_POPULATION_EMAIL_DESC",
  UserConfigsStddevPopulationIsArchivedAsc = "USER_CONFIGS_STDDEV_POPULATION_IS_ARCHIVED_ASC",
  UserConfigsStddevPopulationIsArchivedDesc = "USER_CONFIGS_STDDEV_POPULATION_IS_ARCHIVED_DESC",
  UserConfigsStddevPopulationIsFavoriteAsc = "USER_CONFIGS_STDDEV_POPULATION_IS_FAVORITE_ASC",
  UserConfigsStddevPopulationIsFavoriteDesc = "USER_CONFIGS_STDDEV_POPULATION_IS_FAVORITE_DESC",
  UserConfigsStddevPopulationUpdatedAtAsc = "USER_CONFIGS_STDDEV_POPULATION_UPDATED_AT_ASC",
  UserConfigsStddevPopulationUpdatedAtDesc = "USER_CONFIGS_STDDEV_POPULATION_UPDATED_AT_DESC",
  UserConfigsStddevPopulationUpdatedByEmailAsc = "USER_CONFIGS_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  UserConfigsStddevPopulationUpdatedByEmailDesc = "USER_CONFIGS_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  UserConfigsStddevPopulationUserConfigIdAsc = "USER_CONFIGS_STDDEV_POPULATION_USER_CONFIG_ID_ASC",
  UserConfigsStddevPopulationUserConfigIdDesc = "USER_CONFIGS_STDDEV_POPULATION_USER_CONFIG_ID_DESC",
  UserConfigsStddevSampleCreatedAtAsc = "USER_CONFIGS_STDDEV_SAMPLE_CREATED_AT_ASC",
  UserConfigsStddevSampleCreatedAtDesc = "USER_CONFIGS_STDDEV_SAMPLE_CREATED_AT_DESC",
  UserConfigsStddevSampleCreatedByEmailAsc = "USER_CONFIGS_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  UserConfigsStddevSampleCreatedByEmailDesc = "USER_CONFIGS_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  UserConfigsStddevSampleDataRoomIdAsc = "USER_CONFIGS_STDDEV_SAMPLE_DATA_ROOM_ID_ASC",
  UserConfigsStddevSampleDataRoomIdDesc = "USER_CONFIGS_STDDEV_SAMPLE_DATA_ROOM_ID_DESC",
  UserConfigsStddevSampleEmailAsc = "USER_CONFIGS_STDDEV_SAMPLE_EMAIL_ASC",
  UserConfigsStddevSampleEmailDesc = "USER_CONFIGS_STDDEV_SAMPLE_EMAIL_DESC",
  UserConfigsStddevSampleIsArchivedAsc = "USER_CONFIGS_STDDEV_SAMPLE_IS_ARCHIVED_ASC",
  UserConfigsStddevSampleIsArchivedDesc = "USER_CONFIGS_STDDEV_SAMPLE_IS_ARCHIVED_DESC",
  UserConfigsStddevSampleIsFavoriteAsc = "USER_CONFIGS_STDDEV_SAMPLE_IS_FAVORITE_ASC",
  UserConfigsStddevSampleIsFavoriteDesc = "USER_CONFIGS_STDDEV_SAMPLE_IS_FAVORITE_DESC",
  UserConfigsStddevSampleUpdatedAtAsc = "USER_CONFIGS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  UserConfigsStddevSampleUpdatedAtDesc = "USER_CONFIGS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  UserConfigsStddevSampleUpdatedByEmailAsc = "USER_CONFIGS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  UserConfigsStddevSampleUpdatedByEmailDesc = "USER_CONFIGS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  UserConfigsStddevSampleUserConfigIdAsc = "USER_CONFIGS_STDDEV_SAMPLE_USER_CONFIG_ID_ASC",
  UserConfigsStddevSampleUserConfigIdDesc = "USER_CONFIGS_STDDEV_SAMPLE_USER_CONFIG_ID_DESC",
  UserConfigsSumCreatedAtAsc = "USER_CONFIGS_SUM_CREATED_AT_ASC",
  UserConfigsSumCreatedAtDesc = "USER_CONFIGS_SUM_CREATED_AT_DESC",
  UserConfigsSumCreatedByEmailAsc = "USER_CONFIGS_SUM_CREATED_BY_EMAIL_ASC",
  UserConfigsSumCreatedByEmailDesc = "USER_CONFIGS_SUM_CREATED_BY_EMAIL_DESC",
  UserConfigsSumDataRoomIdAsc = "USER_CONFIGS_SUM_DATA_ROOM_ID_ASC",
  UserConfigsSumDataRoomIdDesc = "USER_CONFIGS_SUM_DATA_ROOM_ID_DESC",
  UserConfigsSumEmailAsc = "USER_CONFIGS_SUM_EMAIL_ASC",
  UserConfigsSumEmailDesc = "USER_CONFIGS_SUM_EMAIL_DESC",
  UserConfigsSumIsArchivedAsc = "USER_CONFIGS_SUM_IS_ARCHIVED_ASC",
  UserConfigsSumIsArchivedDesc = "USER_CONFIGS_SUM_IS_ARCHIVED_DESC",
  UserConfigsSumIsFavoriteAsc = "USER_CONFIGS_SUM_IS_FAVORITE_ASC",
  UserConfigsSumIsFavoriteDesc = "USER_CONFIGS_SUM_IS_FAVORITE_DESC",
  UserConfigsSumUpdatedAtAsc = "USER_CONFIGS_SUM_UPDATED_AT_ASC",
  UserConfigsSumUpdatedAtDesc = "USER_CONFIGS_SUM_UPDATED_AT_DESC",
  UserConfigsSumUpdatedByEmailAsc = "USER_CONFIGS_SUM_UPDATED_BY_EMAIL_ASC",
  UserConfigsSumUpdatedByEmailDesc = "USER_CONFIGS_SUM_UPDATED_BY_EMAIL_DESC",
  UserConfigsSumUserConfigIdAsc = "USER_CONFIGS_SUM_USER_CONFIG_ID_ASC",
  UserConfigsSumUserConfigIdDesc = "USER_CONFIGS_SUM_USER_CONFIG_ID_DESC",
  UserConfigsVariancePopulationCreatedAtAsc = "USER_CONFIGS_VARIANCE_POPULATION_CREATED_AT_ASC",
  UserConfigsVariancePopulationCreatedAtDesc = "USER_CONFIGS_VARIANCE_POPULATION_CREATED_AT_DESC",
  UserConfigsVariancePopulationCreatedByEmailAsc = "USER_CONFIGS_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  UserConfigsVariancePopulationCreatedByEmailDesc = "USER_CONFIGS_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  UserConfigsVariancePopulationDataRoomIdAsc = "USER_CONFIGS_VARIANCE_POPULATION_DATA_ROOM_ID_ASC",
  UserConfigsVariancePopulationDataRoomIdDesc = "USER_CONFIGS_VARIANCE_POPULATION_DATA_ROOM_ID_DESC",
  UserConfigsVariancePopulationEmailAsc = "USER_CONFIGS_VARIANCE_POPULATION_EMAIL_ASC",
  UserConfigsVariancePopulationEmailDesc = "USER_CONFIGS_VARIANCE_POPULATION_EMAIL_DESC",
  UserConfigsVariancePopulationIsArchivedAsc = "USER_CONFIGS_VARIANCE_POPULATION_IS_ARCHIVED_ASC",
  UserConfigsVariancePopulationIsArchivedDesc = "USER_CONFIGS_VARIANCE_POPULATION_IS_ARCHIVED_DESC",
  UserConfigsVariancePopulationIsFavoriteAsc = "USER_CONFIGS_VARIANCE_POPULATION_IS_FAVORITE_ASC",
  UserConfigsVariancePopulationIsFavoriteDesc = "USER_CONFIGS_VARIANCE_POPULATION_IS_FAVORITE_DESC",
  UserConfigsVariancePopulationUpdatedAtAsc = "USER_CONFIGS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  UserConfigsVariancePopulationUpdatedAtDesc = "USER_CONFIGS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  UserConfigsVariancePopulationUpdatedByEmailAsc = "USER_CONFIGS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  UserConfigsVariancePopulationUpdatedByEmailDesc = "USER_CONFIGS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  UserConfigsVariancePopulationUserConfigIdAsc = "USER_CONFIGS_VARIANCE_POPULATION_USER_CONFIG_ID_ASC",
  UserConfigsVariancePopulationUserConfigIdDesc = "USER_CONFIGS_VARIANCE_POPULATION_USER_CONFIG_ID_DESC",
  UserConfigsVarianceSampleCreatedAtAsc = "USER_CONFIGS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  UserConfigsVarianceSampleCreatedAtDesc = "USER_CONFIGS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  UserConfigsVarianceSampleCreatedByEmailAsc = "USER_CONFIGS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  UserConfigsVarianceSampleCreatedByEmailDesc = "USER_CONFIGS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  UserConfigsVarianceSampleDataRoomIdAsc = "USER_CONFIGS_VARIANCE_SAMPLE_DATA_ROOM_ID_ASC",
  UserConfigsVarianceSampleDataRoomIdDesc = "USER_CONFIGS_VARIANCE_SAMPLE_DATA_ROOM_ID_DESC",
  UserConfigsVarianceSampleEmailAsc = "USER_CONFIGS_VARIANCE_SAMPLE_EMAIL_ASC",
  UserConfigsVarianceSampleEmailDesc = "USER_CONFIGS_VARIANCE_SAMPLE_EMAIL_DESC",
  UserConfigsVarianceSampleIsArchivedAsc = "USER_CONFIGS_VARIANCE_SAMPLE_IS_ARCHIVED_ASC",
  UserConfigsVarianceSampleIsArchivedDesc = "USER_CONFIGS_VARIANCE_SAMPLE_IS_ARCHIVED_DESC",
  UserConfigsVarianceSampleIsFavoriteAsc = "USER_CONFIGS_VARIANCE_SAMPLE_IS_FAVORITE_ASC",
  UserConfigsVarianceSampleIsFavoriteDesc = "USER_CONFIGS_VARIANCE_SAMPLE_IS_FAVORITE_DESC",
  UserConfigsVarianceSampleUpdatedAtAsc = "USER_CONFIGS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  UserConfigsVarianceSampleUpdatedAtDesc = "USER_CONFIGS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  UserConfigsVarianceSampleUpdatedByEmailAsc = "USER_CONFIGS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  UserConfigsVarianceSampleUpdatedByEmailDesc = "USER_CONFIGS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  UserConfigsVarianceSampleUserConfigIdAsc = "USER_CONFIGS_VARIANCE_SAMPLE_USER_CONFIG_ID_ASC",
  UserConfigsVarianceSampleUserConfigIdDesc = "USER_CONFIGS_VARIANCE_SAMPLE_USER_CONFIG_ID_DESC",
  UserIdAsc = "USER_ID_ASC",
  UserIdDesc = "USER_ID_DESC",
  UserPermissionsAverageCreatedAtAsc = "USER_PERMISSIONS_AVERAGE_CREATED_AT_ASC",
  UserPermissionsAverageCreatedAtDesc = "USER_PERMISSIONS_AVERAGE_CREATED_AT_DESC",
  UserPermissionsAverageCreatedByEmailAsc = "USER_PERMISSIONS_AVERAGE_CREATED_BY_EMAIL_ASC",
  UserPermissionsAverageCreatedByEmailDesc = "USER_PERMISSIONS_AVERAGE_CREATED_BY_EMAIL_DESC",
  UserPermissionsAverageDataRoomIdAsc = "USER_PERMISSIONS_AVERAGE_DATA_ROOM_ID_ASC",
  UserPermissionsAverageDataRoomIdDesc = "USER_PERMISSIONS_AVERAGE_DATA_ROOM_ID_DESC",
  UserPermissionsAverageEmailAsc = "USER_PERMISSIONS_AVERAGE_EMAIL_ASC",
  UserPermissionsAverageEmailDesc = "USER_PERMISSIONS_AVERAGE_EMAIL_DESC",
  UserPermissionsAverageUpdatedAtAsc = "USER_PERMISSIONS_AVERAGE_UPDATED_AT_ASC",
  UserPermissionsAverageUpdatedAtDesc = "USER_PERMISSIONS_AVERAGE_UPDATED_AT_DESC",
  UserPermissionsAverageUpdatedByEmailAsc = "USER_PERMISSIONS_AVERAGE_UPDATED_BY_EMAIL_ASC",
  UserPermissionsAverageUpdatedByEmailDesc = "USER_PERMISSIONS_AVERAGE_UPDATED_BY_EMAIL_DESC",
  UserPermissionsAverageUserPermissionIdAsc = "USER_PERMISSIONS_AVERAGE_USER_PERMISSION_ID_ASC",
  UserPermissionsAverageUserPermissionIdDesc = "USER_PERMISSIONS_AVERAGE_USER_PERMISSION_ID_DESC",
  UserPermissionsByEmailCountAsc = "USER_PERMISSIONS_BY_EMAIL_COUNT_ASC",
  UserPermissionsByEmailCountDesc = "USER_PERMISSIONS_BY_EMAIL_COUNT_DESC",
  UserPermissionsByEmailMaxCreatedAtAsc = "USER_PERMISSIONS_BY_EMAIL_MAX_CREATED_AT_ASC",
  UserPermissionsByEmailMaxCreatedAtDesc = "USER_PERMISSIONS_BY_EMAIL_MAX_CREATED_AT_DESC",
  UserPermissionsByEmailMaxCreatedByEmailAsc = "USER_PERMISSIONS_BY_EMAIL_MAX_CREATED_BY_EMAIL_ASC",
  UserPermissionsByEmailMaxCreatedByEmailDesc = "USER_PERMISSIONS_BY_EMAIL_MAX_CREATED_BY_EMAIL_DESC",
  UserPermissionsByEmailMaxDataRoomIdAsc = "USER_PERMISSIONS_BY_EMAIL_MAX_DATA_ROOM_ID_ASC",
  UserPermissionsByEmailMaxDataRoomIdDesc = "USER_PERMISSIONS_BY_EMAIL_MAX_DATA_ROOM_ID_DESC",
  UserPermissionsByEmailMaxEmailAsc = "USER_PERMISSIONS_BY_EMAIL_MAX_EMAIL_ASC",
  UserPermissionsByEmailMaxEmailDesc = "USER_PERMISSIONS_BY_EMAIL_MAX_EMAIL_DESC",
  UserPermissionsByEmailMaxUpdatedAtAsc = "USER_PERMISSIONS_BY_EMAIL_MAX_UPDATED_AT_ASC",
  UserPermissionsByEmailMaxUpdatedAtDesc = "USER_PERMISSIONS_BY_EMAIL_MAX_UPDATED_AT_DESC",
  UserPermissionsByEmailMaxUpdatedByEmailAsc = "USER_PERMISSIONS_BY_EMAIL_MAX_UPDATED_BY_EMAIL_ASC",
  UserPermissionsByEmailMaxUpdatedByEmailDesc = "USER_PERMISSIONS_BY_EMAIL_MAX_UPDATED_BY_EMAIL_DESC",
  UserPermissionsByEmailMaxUserPermissionIdAsc = "USER_PERMISSIONS_BY_EMAIL_MAX_USER_PERMISSION_ID_ASC",
  UserPermissionsByEmailMaxUserPermissionIdDesc = "USER_PERMISSIONS_BY_EMAIL_MAX_USER_PERMISSION_ID_DESC",
  UserPermissionsByEmailMinCreatedAtAsc = "USER_PERMISSIONS_BY_EMAIL_MIN_CREATED_AT_ASC",
  UserPermissionsByEmailMinCreatedAtDesc = "USER_PERMISSIONS_BY_EMAIL_MIN_CREATED_AT_DESC",
  UserPermissionsByEmailMinCreatedByEmailAsc = "USER_PERMISSIONS_BY_EMAIL_MIN_CREATED_BY_EMAIL_ASC",
  UserPermissionsByEmailMinCreatedByEmailDesc = "USER_PERMISSIONS_BY_EMAIL_MIN_CREATED_BY_EMAIL_DESC",
  UserPermissionsByEmailMinDataRoomIdAsc = "USER_PERMISSIONS_BY_EMAIL_MIN_DATA_ROOM_ID_ASC",
  UserPermissionsByEmailMinDataRoomIdDesc = "USER_PERMISSIONS_BY_EMAIL_MIN_DATA_ROOM_ID_DESC",
  UserPermissionsByEmailMinEmailAsc = "USER_PERMISSIONS_BY_EMAIL_MIN_EMAIL_ASC",
  UserPermissionsByEmailMinEmailDesc = "USER_PERMISSIONS_BY_EMAIL_MIN_EMAIL_DESC",
  UserPermissionsByEmailMinUpdatedAtAsc = "USER_PERMISSIONS_BY_EMAIL_MIN_UPDATED_AT_ASC",
  UserPermissionsByEmailMinUpdatedAtDesc = "USER_PERMISSIONS_BY_EMAIL_MIN_UPDATED_AT_DESC",
  UserPermissionsByEmailMinUpdatedByEmailAsc = "USER_PERMISSIONS_BY_EMAIL_MIN_UPDATED_BY_EMAIL_ASC",
  UserPermissionsByEmailMinUpdatedByEmailDesc = "USER_PERMISSIONS_BY_EMAIL_MIN_UPDATED_BY_EMAIL_DESC",
  UserPermissionsByEmailMinUserPermissionIdAsc = "USER_PERMISSIONS_BY_EMAIL_MIN_USER_PERMISSION_ID_ASC",
  UserPermissionsByEmailMinUserPermissionIdDesc = "USER_PERMISSIONS_BY_EMAIL_MIN_USER_PERMISSION_ID_DESC",
  UserPermissionsCountAsc = "USER_PERMISSIONS_COUNT_ASC",
  UserPermissionsCountDesc = "USER_PERMISSIONS_COUNT_DESC",
  UserPermissionsDistinctCountCreatedAtAsc = "USER_PERMISSIONS_DISTINCT_COUNT_CREATED_AT_ASC",
  UserPermissionsDistinctCountCreatedAtDesc = "USER_PERMISSIONS_DISTINCT_COUNT_CREATED_AT_DESC",
  UserPermissionsDistinctCountCreatedByEmailAsc = "USER_PERMISSIONS_DISTINCT_COUNT_CREATED_BY_EMAIL_ASC",
  UserPermissionsDistinctCountCreatedByEmailDesc = "USER_PERMISSIONS_DISTINCT_COUNT_CREATED_BY_EMAIL_DESC",
  UserPermissionsDistinctCountDataRoomIdAsc = "USER_PERMISSIONS_DISTINCT_COUNT_DATA_ROOM_ID_ASC",
  UserPermissionsDistinctCountDataRoomIdDesc = "USER_PERMISSIONS_DISTINCT_COUNT_DATA_ROOM_ID_DESC",
  UserPermissionsDistinctCountEmailAsc = "USER_PERMISSIONS_DISTINCT_COUNT_EMAIL_ASC",
  UserPermissionsDistinctCountEmailDesc = "USER_PERMISSIONS_DISTINCT_COUNT_EMAIL_DESC",
  UserPermissionsDistinctCountUpdatedAtAsc = "USER_PERMISSIONS_DISTINCT_COUNT_UPDATED_AT_ASC",
  UserPermissionsDistinctCountUpdatedAtDesc = "USER_PERMISSIONS_DISTINCT_COUNT_UPDATED_AT_DESC",
  UserPermissionsDistinctCountUpdatedByEmailAsc = "USER_PERMISSIONS_DISTINCT_COUNT_UPDATED_BY_EMAIL_ASC",
  UserPermissionsDistinctCountUpdatedByEmailDesc = "USER_PERMISSIONS_DISTINCT_COUNT_UPDATED_BY_EMAIL_DESC",
  UserPermissionsDistinctCountUserPermissionIdAsc = "USER_PERMISSIONS_DISTINCT_COUNT_USER_PERMISSION_ID_ASC",
  UserPermissionsDistinctCountUserPermissionIdDesc = "USER_PERMISSIONS_DISTINCT_COUNT_USER_PERMISSION_ID_DESC",
  UserPermissionsMaxCreatedAtAsc = "USER_PERMISSIONS_MAX_CREATED_AT_ASC",
  UserPermissionsMaxCreatedAtDesc = "USER_PERMISSIONS_MAX_CREATED_AT_DESC",
  UserPermissionsMaxCreatedByEmailAsc = "USER_PERMISSIONS_MAX_CREATED_BY_EMAIL_ASC",
  UserPermissionsMaxCreatedByEmailDesc = "USER_PERMISSIONS_MAX_CREATED_BY_EMAIL_DESC",
  UserPermissionsMaxDataRoomIdAsc = "USER_PERMISSIONS_MAX_DATA_ROOM_ID_ASC",
  UserPermissionsMaxDataRoomIdDesc = "USER_PERMISSIONS_MAX_DATA_ROOM_ID_DESC",
  UserPermissionsMaxEmailAsc = "USER_PERMISSIONS_MAX_EMAIL_ASC",
  UserPermissionsMaxEmailDesc = "USER_PERMISSIONS_MAX_EMAIL_DESC",
  UserPermissionsMaxUpdatedAtAsc = "USER_PERMISSIONS_MAX_UPDATED_AT_ASC",
  UserPermissionsMaxUpdatedAtDesc = "USER_PERMISSIONS_MAX_UPDATED_AT_DESC",
  UserPermissionsMaxUpdatedByEmailAsc = "USER_PERMISSIONS_MAX_UPDATED_BY_EMAIL_ASC",
  UserPermissionsMaxUpdatedByEmailDesc = "USER_PERMISSIONS_MAX_UPDATED_BY_EMAIL_DESC",
  UserPermissionsMaxUserPermissionIdAsc = "USER_PERMISSIONS_MAX_USER_PERMISSION_ID_ASC",
  UserPermissionsMaxUserPermissionIdDesc = "USER_PERMISSIONS_MAX_USER_PERMISSION_ID_DESC",
  UserPermissionsMinCreatedAtAsc = "USER_PERMISSIONS_MIN_CREATED_AT_ASC",
  UserPermissionsMinCreatedAtDesc = "USER_PERMISSIONS_MIN_CREATED_AT_DESC",
  UserPermissionsMinCreatedByEmailAsc = "USER_PERMISSIONS_MIN_CREATED_BY_EMAIL_ASC",
  UserPermissionsMinCreatedByEmailDesc = "USER_PERMISSIONS_MIN_CREATED_BY_EMAIL_DESC",
  UserPermissionsMinDataRoomIdAsc = "USER_PERMISSIONS_MIN_DATA_ROOM_ID_ASC",
  UserPermissionsMinDataRoomIdDesc = "USER_PERMISSIONS_MIN_DATA_ROOM_ID_DESC",
  UserPermissionsMinEmailAsc = "USER_PERMISSIONS_MIN_EMAIL_ASC",
  UserPermissionsMinEmailDesc = "USER_PERMISSIONS_MIN_EMAIL_DESC",
  UserPermissionsMinUpdatedAtAsc = "USER_PERMISSIONS_MIN_UPDATED_AT_ASC",
  UserPermissionsMinUpdatedAtDesc = "USER_PERMISSIONS_MIN_UPDATED_AT_DESC",
  UserPermissionsMinUpdatedByEmailAsc = "USER_PERMISSIONS_MIN_UPDATED_BY_EMAIL_ASC",
  UserPermissionsMinUpdatedByEmailDesc = "USER_PERMISSIONS_MIN_UPDATED_BY_EMAIL_DESC",
  UserPermissionsMinUserPermissionIdAsc = "USER_PERMISSIONS_MIN_USER_PERMISSION_ID_ASC",
  UserPermissionsMinUserPermissionIdDesc = "USER_PERMISSIONS_MIN_USER_PERMISSION_ID_DESC",
  UserPermissionsStddevPopulationCreatedAtAsc = "USER_PERMISSIONS_STDDEV_POPULATION_CREATED_AT_ASC",
  UserPermissionsStddevPopulationCreatedAtDesc = "USER_PERMISSIONS_STDDEV_POPULATION_CREATED_AT_DESC",
  UserPermissionsStddevPopulationCreatedByEmailAsc = "USER_PERMISSIONS_STDDEV_POPULATION_CREATED_BY_EMAIL_ASC",
  UserPermissionsStddevPopulationCreatedByEmailDesc = "USER_PERMISSIONS_STDDEV_POPULATION_CREATED_BY_EMAIL_DESC",
  UserPermissionsStddevPopulationDataRoomIdAsc = "USER_PERMISSIONS_STDDEV_POPULATION_DATA_ROOM_ID_ASC",
  UserPermissionsStddevPopulationDataRoomIdDesc = "USER_PERMISSIONS_STDDEV_POPULATION_DATA_ROOM_ID_DESC",
  UserPermissionsStddevPopulationEmailAsc = "USER_PERMISSIONS_STDDEV_POPULATION_EMAIL_ASC",
  UserPermissionsStddevPopulationEmailDesc = "USER_PERMISSIONS_STDDEV_POPULATION_EMAIL_DESC",
  UserPermissionsStddevPopulationUpdatedAtAsc = "USER_PERMISSIONS_STDDEV_POPULATION_UPDATED_AT_ASC",
  UserPermissionsStddevPopulationUpdatedAtDesc = "USER_PERMISSIONS_STDDEV_POPULATION_UPDATED_AT_DESC",
  UserPermissionsStddevPopulationUpdatedByEmailAsc = "USER_PERMISSIONS_STDDEV_POPULATION_UPDATED_BY_EMAIL_ASC",
  UserPermissionsStddevPopulationUpdatedByEmailDesc = "USER_PERMISSIONS_STDDEV_POPULATION_UPDATED_BY_EMAIL_DESC",
  UserPermissionsStddevPopulationUserPermissionIdAsc = "USER_PERMISSIONS_STDDEV_POPULATION_USER_PERMISSION_ID_ASC",
  UserPermissionsStddevPopulationUserPermissionIdDesc = "USER_PERMISSIONS_STDDEV_POPULATION_USER_PERMISSION_ID_DESC",
  UserPermissionsStddevSampleCreatedAtAsc = "USER_PERMISSIONS_STDDEV_SAMPLE_CREATED_AT_ASC",
  UserPermissionsStddevSampleCreatedAtDesc = "USER_PERMISSIONS_STDDEV_SAMPLE_CREATED_AT_DESC",
  UserPermissionsStddevSampleCreatedByEmailAsc = "USER_PERMISSIONS_STDDEV_SAMPLE_CREATED_BY_EMAIL_ASC",
  UserPermissionsStddevSampleCreatedByEmailDesc = "USER_PERMISSIONS_STDDEV_SAMPLE_CREATED_BY_EMAIL_DESC",
  UserPermissionsStddevSampleDataRoomIdAsc = "USER_PERMISSIONS_STDDEV_SAMPLE_DATA_ROOM_ID_ASC",
  UserPermissionsStddevSampleDataRoomIdDesc = "USER_PERMISSIONS_STDDEV_SAMPLE_DATA_ROOM_ID_DESC",
  UserPermissionsStddevSampleEmailAsc = "USER_PERMISSIONS_STDDEV_SAMPLE_EMAIL_ASC",
  UserPermissionsStddevSampleEmailDesc = "USER_PERMISSIONS_STDDEV_SAMPLE_EMAIL_DESC",
  UserPermissionsStddevSampleUpdatedAtAsc = "USER_PERMISSIONS_STDDEV_SAMPLE_UPDATED_AT_ASC",
  UserPermissionsStddevSampleUpdatedAtDesc = "USER_PERMISSIONS_STDDEV_SAMPLE_UPDATED_AT_DESC",
  UserPermissionsStddevSampleUpdatedByEmailAsc = "USER_PERMISSIONS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_ASC",
  UserPermissionsStddevSampleUpdatedByEmailDesc = "USER_PERMISSIONS_STDDEV_SAMPLE_UPDATED_BY_EMAIL_DESC",
  UserPermissionsStddevSampleUserPermissionIdAsc = "USER_PERMISSIONS_STDDEV_SAMPLE_USER_PERMISSION_ID_ASC",
  UserPermissionsStddevSampleUserPermissionIdDesc = "USER_PERMISSIONS_STDDEV_SAMPLE_USER_PERMISSION_ID_DESC",
  UserPermissionsSumCreatedAtAsc = "USER_PERMISSIONS_SUM_CREATED_AT_ASC",
  UserPermissionsSumCreatedAtDesc = "USER_PERMISSIONS_SUM_CREATED_AT_DESC",
  UserPermissionsSumCreatedByEmailAsc = "USER_PERMISSIONS_SUM_CREATED_BY_EMAIL_ASC",
  UserPermissionsSumCreatedByEmailDesc = "USER_PERMISSIONS_SUM_CREATED_BY_EMAIL_DESC",
  UserPermissionsSumDataRoomIdAsc = "USER_PERMISSIONS_SUM_DATA_ROOM_ID_ASC",
  UserPermissionsSumDataRoomIdDesc = "USER_PERMISSIONS_SUM_DATA_ROOM_ID_DESC",
  UserPermissionsSumEmailAsc = "USER_PERMISSIONS_SUM_EMAIL_ASC",
  UserPermissionsSumEmailDesc = "USER_PERMISSIONS_SUM_EMAIL_DESC",
  UserPermissionsSumUpdatedAtAsc = "USER_PERMISSIONS_SUM_UPDATED_AT_ASC",
  UserPermissionsSumUpdatedAtDesc = "USER_PERMISSIONS_SUM_UPDATED_AT_DESC",
  UserPermissionsSumUpdatedByEmailAsc = "USER_PERMISSIONS_SUM_UPDATED_BY_EMAIL_ASC",
  UserPermissionsSumUpdatedByEmailDesc = "USER_PERMISSIONS_SUM_UPDATED_BY_EMAIL_DESC",
  UserPermissionsSumUserPermissionIdAsc = "USER_PERMISSIONS_SUM_USER_PERMISSION_ID_ASC",
  UserPermissionsSumUserPermissionIdDesc = "USER_PERMISSIONS_SUM_USER_PERMISSION_ID_DESC",
  UserPermissionsVariancePopulationCreatedAtAsc = "USER_PERMISSIONS_VARIANCE_POPULATION_CREATED_AT_ASC",
  UserPermissionsVariancePopulationCreatedAtDesc = "USER_PERMISSIONS_VARIANCE_POPULATION_CREATED_AT_DESC",
  UserPermissionsVariancePopulationCreatedByEmailAsc = "USER_PERMISSIONS_VARIANCE_POPULATION_CREATED_BY_EMAIL_ASC",
  UserPermissionsVariancePopulationCreatedByEmailDesc = "USER_PERMISSIONS_VARIANCE_POPULATION_CREATED_BY_EMAIL_DESC",
  UserPermissionsVariancePopulationDataRoomIdAsc = "USER_PERMISSIONS_VARIANCE_POPULATION_DATA_ROOM_ID_ASC",
  UserPermissionsVariancePopulationDataRoomIdDesc = "USER_PERMISSIONS_VARIANCE_POPULATION_DATA_ROOM_ID_DESC",
  UserPermissionsVariancePopulationEmailAsc = "USER_PERMISSIONS_VARIANCE_POPULATION_EMAIL_ASC",
  UserPermissionsVariancePopulationEmailDesc = "USER_PERMISSIONS_VARIANCE_POPULATION_EMAIL_DESC",
  UserPermissionsVariancePopulationUpdatedAtAsc = "USER_PERMISSIONS_VARIANCE_POPULATION_UPDATED_AT_ASC",
  UserPermissionsVariancePopulationUpdatedAtDesc = "USER_PERMISSIONS_VARIANCE_POPULATION_UPDATED_AT_DESC",
  UserPermissionsVariancePopulationUpdatedByEmailAsc = "USER_PERMISSIONS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_ASC",
  UserPermissionsVariancePopulationUpdatedByEmailDesc = "USER_PERMISSIONS_VARIANCE_POPULATION_UPDATED_BY_EMAIL_DESC",
  UserPermissionsVariancePopulationUserPermissionIdAsc = "USER_PERMISSIONS_VARIANCE_POPULATION_USER_PERMISSION_ID_ASC",
  UserPermissionsVariancePopulationUserPermissionIdDesc = "USER_PERMISSIONS_VARIANCE_POPULATION_USER_PERMISSION_ID_DESC",
  UserPermissionsVarianceSampleCreatedAtAsc = "USER_PERMISSIONS_VARIANCE_SAMPLE_CREATED_AT_ASC",
  UserPermissionsVarianceSampleCreatedAtDesc = "USER_PERMISSIONS_VARIANCE_SAMPLE_CREATED_AT_DESC",
  UserPermissionsVarianceSampleCreatedByEmailAsc = "USER_PERMISSIONS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_ASC",
  UserPermissionsVarianceSampleCreatedByEmailDesc = "USER_PERMISSIONS_VARIANCE_SAMPLE_CREATED_BY_EMAIL_DESC",
  UserPermissionsVarianceSampleDataRoomIdAsc = "USER_PERMISSIONS_VARIANCE_SAMPLE_DATA_ROOM_ID_ASC",
  UserPermissionsVarianceSampleDataRoomIdDesc = "USER_PERMISSIONS_VARIANCE_SAMPLE_DATA_ROOM_ID_DESC",
  UserPermissionsVarianceSampleEmailAsc = "USER_PERMISSIONS_VARIANCE_SAMPLE_EMAIL_ASC",
  UserPermissionsVarianceSampleEmailDesc = "USER_PERMISSIONS_VARIANCE_SAMPLE_EMAIL_DESC",
  UserPermissionsVarianceSampleUpdatedAtAsc = "USER_PERMISSIONS_VARIANCE_SAMPLE_UPDATED_AT_ASC",
  UserPermissionsVarianceSampleUpdatedAtDesc = "USER_PERMISSIONS_VARIANCE_SAMPLE_UPDATED_AT_DESC",
  UserPermissionsVarianceSampleUpdatedByEmailAsc = "USER_PERMISSIONS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_ASC",
  UserPermissionsVarianceSampleUpdatedByEmailDesc = "USER_PERMISSIONS_VARIANCE_SAMPLE_UPDATED_BY_EMAIL_DESC",
  UserPermissionsVarianceSampleUserPermissionIdAsc = "USER_PERMISSIONS_VARIANCE_SAMPLE_USER_PERMISSION_ID_ASC",
  UserPermissionsVarianceSampleUserPermissionIdDesc = "USER_PERMISSIONS_VARIANCE_SAMPLE_USER_PERMISSION_ID_DESC",
  UserQuotaByEmailAllowedAsc = "USER_QUOTA_BY_EMAIL_ALLOWED_ASC",
  UserQuotaByEmailAllowedDesc = "USER_QUOTA_BY_EMAIL_ALLOWED_DESC",
  UserQuotaByEmailCreatedAtAsc = "USER_QUOTA_BY_EMAIL_CREATED_AT_ASC",
  UserQuotaByEmailCreatedAtDesc = "USER_QUOTA_BY_EMAIL_CREATED_AT_DESC",
  UserQuotaByEmailCreatedByEmailAsc = "USER_QUOTA_BY_EMAIL_CREATED_BY_EMAIL_ASC",
  UserQuotaByEmailCreatedByEmailDesc = "USER_QUOTA_BY_EMAIL_CREATED_BY_EMAIL_DESC",
  UserQuotaByEmailEmailAsc = "USER_QUOTA_BY_EMAIL_EMAIL_ASC",
  UserQuotaByEmailEmailDesc = "USER_QUOTA_BY_EMAIL_EMAIL_DESC",
  UserQuotaByEmailUpdatedAtAsc = "USER_QUOTA_BY_EMAIL_UPDATED_AT_ASC",
  UserQuotaByEmailUpdatedAtDesc = "USER_QUOTA_BY_EMAIL_UPDATED_AT_DESC",
  UserQuotaByEmailUpdatedByEmailAsc = "USER_QUOTA_BY_EMAIL_UPDATED_BY_EMAIL_ASC",
  UserQuotaByEmailUpdatedByEmailDesc = "USER_QUOTA_BY_EMAIL_UPDATED_BY_EMAIL_DESC",
}

/** An object where the defined keys will be set on the `attestationSpecificationAsElement` being updated. */
export type UpdateAttestationSpecificationAsElementOnAttestationSpecificationAsElementForAttestationSpecificationAsElementIdFkeyPatch =
  {
    config?: InputMaybe<Scalars["JSON"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    modification?: InputMaybe<AttestationSpecificationAsElementIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `authenticationMethodAsElement` being updated. */
export type UpdateAuthenticationMethodAsElementOnAuthenticationMethodAsElementForAuthenticationMethodAsElementIdFkeyPatch =
  {
    config?: InputMaybe<Scalars["JSON"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    modification?: InputMaybe<AuthenticationMethodAsElementIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `computeNodeAsElement` being updated. */
export type UpdateComputeNodeAsElementOnComputeNodeAsElementForComputeNodeAsElementIdFkeyPatch =
  {
    computeNodeType?: InputMaybe<ComputeNodeTypeType>;
    config?: InputMaybe<Scalars["JSON"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    modification?: InputMaybe<ComputeNodeAsElementIdFkeyInput>;
    nodeName?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `computeNode` being updated. */
export type UpdateComputeNodeOnComputeNodeForComputeNodeDataRoomIdFkeyPatch = {
  computeNodeId?: InputMaybe<Scalars["UUID"]>;
  computeNodeType?: InputMaybe<ComputeNodeTypeType>;
  config?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<ComputeNodeDataRoomIdFkeyInput>;
  datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0InverseInput>;
  index?: InputMaybe<Scalars["Int"]>;
  jobs?: InputMaybe<JobComputeNodeIdFkeyInverseInput>;
  nodeName?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** An object where the defined keys will be set on the `computeNode` being updated. */
export type UpdateComputeNodeOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey0Patch =
  {
    computeNodeType?: InputMaybe<ComputeNodeTypeType>;
    config?: InputMaybe<Scalars["JSON"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRoom?: InputMaybe<ComputeNodeDataRoomIdFkeyInput>;
    dataRoomId?: InputMaybe<Scalars["UUID"]>;
    datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0InverseInput>;
    index?: InputMaybe<Scalars["Int"]>;
    jobs?: InputMaybe<JobComputeNodeIdFkeyInverseInput>;
    nodeName?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `computeNode` being updated. */
export type UpdateComputeNodeOnJobForJobComputeNodeIdFkeyPatch = {
  computeNodeType?: InputMaybe<ComputeNodeTypeType>;
  config?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<ComputeNodeDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0InverseInput>;
  index?: InputMaybe<Scalars["Int"]>;
  jobs?: InputMaybe<JobComputeNodeIdFkeyInverseInput>;
  nodeName?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** An object where the defined keys will be set on the `config` being updated. */
export type UpdateConfigOnConfigForConfigDataRoomIdFkeyPatch = {
  computeNodesOrder?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataNodesOrder?: InputMaybe<Array<InputMaybe<Scalars["String"]>>>;
  dataRoom?: InputMaybe<ConfigDataRoomIdFkeyInput>;
  showOrganizationLogo?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** An object where the defined keys will be set on the `configuration` being updated. */
export type UpdateConfigurationOnConfigurationForConfigurationDataRoomIdFkeyPatch =
  {
    commitId?: InputMaybe<Scalars["String"]>;
    configurationId?: InputMaybe<Scalars["UUID"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRoom?: InputMaybe<ConfigurationDataRoomIdFkeyInput>;
    isDev?: InputMaybe<Scalars["Boolean"]>;
    modifications?: InputMaybe<ModificationConfigurationIdFkeyInverseInput>;
    ownerEmail?: InputMaybe<Scalars["String"]>;
    request?: InputMaybe<RequestConfigurationIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `configuration` being updated. */
export type UpdateConfigurationOnModificationForModificationConfigurationIdFkeyPatch =
  {
    commitId?: InputMaybe<Scalars["String"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRoom?: InputMaybe<ConfigurationDataRoomIdFkeyInput>;
    dataRoomId?: InputMaybe<Scalars["UUID"]>;
    isDev?: InputMaybe<Scalars["Boolean"]>;
    modifications?: InputMaybe<ModificationConfigurationIdFkeyInverseInput>;
    ownerEmail?: InputMaybe<Scalars["String"]>;
    request?: InputMaybe<RequestConfigurationIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `configuration` being updated. */
export type UpdateConfigurationOnRequestForRequestConfigurationIdFkeyPatch = {
  commitId?: InputMaybe<Scalars["String"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<ConfigurationDataRoomIdFkeyInput>;
  dataRoomId?: InputMaybe<Scalars["UUID"]>;
  isDev?: InputMaybe<Scalars["Boolean"]>;
  modifications?: InputMaybe<ModificationConfigurationIdFkeyInverseInput>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  request?: InputMaybe<RequestConfigurationIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** An object where the defined keys will be set on the `dataRoom` being updated. */
export type UpdateDataRoomOnComputeNodeForComputeNodeDataRoomIdFkeyPatch = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `dataRoom` being updated. */
export type UpdateDataRoomOnConfigForConfigDataRoomIdFkeyPatch = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `dataRoom` being updated. */
export type UpdateDataRoomOnConfigurationForConfigurationDataRoomIdFkeyPatch = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `dataRoom` being updated. */
export type UpdateDataRoomOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0Patch =
  {
    computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
    config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
    configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRoomHash?: InputMaybe<Scalars["String"]>;
    dataRoomId?: InputMaybe<Scalars["UUID"]>;
    description?: InputMaybe<Scalars["String"]>;
    enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
    governanceProtocol?: InputMaybe<Scalars["JSON"]>;
    lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
    mrenclave?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    source?: InputMaybe<SourceType>;
    state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
    userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
    userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `dataRoom` being updated. */
export type UpdateDataRoomOnLockForLockDataRoomIdFkeyPatch = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `dataRoom` being updated. */
export type UpdateDataRoomOnStateForStateDataRoomIdFkeyPatch = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `dataRoom` being updated. */
export type UpdateDataRoomOnUserConfigForUserConfigDataRoomIdFkeyPatch = {
  computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
  config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
  configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
  governanceProtocol?: InputMaybe<Scalars["JSON"]>;
  lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
  mrenclave?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  source?: InputMaybe<SourceType>;
  state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
  userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
  userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
};

/** An object where the defined keys will be set on the `dataRoom` being updated. */
export type UpdateDataRoomOnUserPermissionForUserPermissionDataRoomIdFkeyPatch =
  {
    computeNodes?: InputMaybe<ComputeNodeDataRoomIdFkeyInverseInput>;
    config?: InputMaybe<ConfigDataRoomIdFkeyInverseInput>;
    configurations?: InputMaybe<ConfigurationDataRoomIdFkeyInverseInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRoomHash?: InputMaybe<Scalars["String"]>;
    description?: InputMaybe<Scalars["String"]>;
    enableDevelopmentTab?: InputMaybe<Scalars["Boolean"]>;
    governanceProtocol?: InputMaybe<Scalars["JSON"]>;
    lock?: InputMaybe<LockDataRoomIdFkeyInverseInput>;
    mrenclave?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    ownerEmail?: InputMaybe<Scalars["String"]>;
    source?: InputMaybe<SourceType>;
    state?: InputMaybe<StateDataRoomIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userByOwnerEmail?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0Input>;
    userConfigs?: InputMaybe<UserConfigDataRoomIdFkeyInverseInput>;
    userPermissions?: InputMaybe<UserPermissionDataRoomIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `datasetLink` being updated. */
export type UpdateDatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey0Patch =
  {
    allowStatisticsSharing?: InputMaybe<Scalars["Boolean"]>;
    computeNode?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0Input>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    datasetHash?: InputMaybe<Scalars["String"]>;
    datasetLinkId?: InputMaybe<Scalars["UUID"]>;
    datasetMeta?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey1Input>;
    datasetStats?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey2Input>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `datasetLink` being updated. */
export type UpdateDatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey1Patch =
  {
    allowStatisticsSharing?: InputMaybe<Scalars["Boolean"]>;
    computeNode?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0Input>;
    computeNodeId?: InputMaybe<Scalars["UUID"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    datasetLinkId?: InputMaybe<Scalars["UUID"]>;
    datasetMeta?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey1Input>;
    datasetStats?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey2Input>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `datasetLink` being updated. */
export type UpdateDatasetLinkOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey2Patch =
  {
    allowStatisticsSharing?: InputMaybe<Scalars["Boolean"]>;
    computeNode?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey0Input>;
    computeNodeId?: InputMaybe<Scalars["UUID"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    datasetLinkId?: InputMaybe<Scalars["UUID"]>;
    datasetMeta?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey1Input>;
    datasetStats?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey2Input>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `datasetMeta` being updated. */
export type UpdateDatasetMetaOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey1Patch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey1InverseInput>;
    datasetStats?: InputMaybe<FakeDecentriqPlatformDatasetStatsForeignKey0InverseInput>;
    description?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    ownerEmail?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `datasetMeta` being updated. */
export type UpdateDatasetMetaOnDatasetStatForFakeDecentriqPlatformDatasetStatsForeignKey0Patch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey1InverseInput>;
    datasetStats?: InputMaybe<FakeDecentriqPlatformDatasetStatsForeignKey0InverseInput>;
    description?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    ownerEmail?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `datasetStat` being updated. */
export type UpdateDatasetStatOnDatasetLinkForFakeDecentriqPlatformDatasetLinkForeignKey2Patch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey2InverseInput>;
    datasetMeta?: InputMaybe<FakeDecentriqPlatformDatasetStatsForeignKey0Input>;
    fileSize?: InputMaybe<Scalars["Int"]>;
    fillRatio?: InputMaybe<Scalars["Float"]>;
    numberOfDuplicateRows?: InputMaybe<Scalars["Int"]>;
    numberOfNotNullableRows?: InputMaybe<Scalars["Int"]>;
    numberOfRows?: InputMaybe<Scalars["Int"]>;
    ownerEmail?: InputMaybe<Scalars["String"]>;
    statistics?: InputMaybe<Scalars["JSON"]>;
    statisticsVersion?: InputMaybe<Scalars["Int"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `datasetStat` being updated. */
export type UpdateDatasetStatOnDatasetStatForFakeDecentriqPlatformDatasetStatsForeignKey0Patch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    datasetLinks?: InputMaybe<FakeDecentriqPlatformDatasetLinkForeignKey2InverseInput>;
    datasetMeta?: InputMaybe<FakeDecentriqPlatformDatasetStatsForeignKey0Input>;
    fileSize?: InputMaybe<Scalars["Int"]>;
    fillRatio?: InputMaybe<Scalars["Float"]>;
    numberOfDuplicateRows?: InputMaybe<Scalars["Int"]>;
    numberOfNotNullableRows?: InputMaybe<Scalars["Int"]>;
    numberOfRows?: InputMaybe<Scalars["Int"]>;
    ownerEmail?: InputMaybe<Scalars["String"]>;
    statistics?: InputMaybe<Scalars["JSON"]>;
    statisticsVersion?: InputMaybe<Scalars["Int"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `domain` being updated. */
export type UpdateDomainOnDomainForDomainOrganizationIdFkeyPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  domain?: InputMaybe<Scalars["String"]>;
  domainId?: InputMaybe<Scalars["UUID"]>;
  organization?: InputMaybe<DomainOrganizationIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  user?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0Input>;
};

/** An object where the defined keys will be set on the `domain` being updated. */
export type UpdateDomainOnDomainForFakeDecentriqPlatformDomainForeignKey0Patch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    domainId?: InputMaybe<Scalars["UUID"]>;
    organization?: InputMaybe<DomainOrganizationIdFkeyInput>;
    organizationId?: InputMaybe<Scalars["UUID"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    user?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0Input>;
  };

/** An object where the defined keys will be set on the `job` being updated. */
export type UpdateJobOnJobForJobComputeNodeIdFkeyPatch = {
  computeNode?: InputMaybe<JobComputeNodeIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  jobId?: InputMaybe<Scalars["String"]>;
  jobStartedAt?: InputMaybe<Scalars["Datetime"]>;
  ownerEmail?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** An object where the defined keys will be set on the `lock` being updated. */
export type UpdateLockOnLockForLockDataRoomIdFkeyPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<LockDataRoomIdFkeyInput>;
  isLocked?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** An object where the defined keys will be set on the `modification` being updated. */
export type UpdateModificationOnAttestationSpecificationAsElementForAttestationSpecificationAsElementIdFkeyPatch =
  {
    attestationSpecificationAsElement?: InputMaybe<AttestationSpecificationAsElementIdFkeyInverseInput>;
    authenticationMethodAsElement?: InputMaybe<AuthenticationMethodAsElementIdFkeyInverseInput>;
    computeNodeAsElement?: InputMaybe<ComputeNodeAsElementIdFkeyInverseInput>;
    config?: InputMaybe<Scalars["JSON"]>;
    configuration?: InputMaybe<ModificationConfigurationIdFkeyInput>;
    configurationId?: InputMaybe<Scalars["UUID"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    elementId?: InputMaybe<Scalars["UUID"]>;
    elementType?: InputMaybe<ElementTypeType>;
    modification?: InputMaybe<ModificationType>;
    modificationId?: InputMaybe<Scalars["UUID"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userPermissionAsElement?: InputMaybe<UserPermissionAsElementIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `modification` being updated. */
export type UpdateModificationOnAuthenticationMethodAsElementForAuthenticationMethodAsElementIdFkeyPatch =
  {
    attestationSpecificationAsElement?: InputMaybe<AttestationSpecificationAsElementIdFkeyInverseInput>;
    authenticationMethodAsElement?: InputMaybe<AuthenticationMethodAsElementIdFkeyInverseInput>;
    computeNodeAsElement?: InputMaybe<ComputeNodeAsElementIdFkeyInverseInput>;
    config?: InputMaybe<Scalars["JSON"]>;
    configuration?: InputMaybe<ModificationConfigurationIdFkeyInput>;
    configurationId?: InputMaybe<Scalars["UUID"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    elementId?: InputMaybe<Scalars["UUID"]>;
    elementType?: InputMaybe<ElementTypeType>;
    modification?: InputMaybe<ModificationType>;
    modificationId?: InputMaybe<Scalars["UUID"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userPermissionAsElement?: InputMaybe<UserPermissionAsElementIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `modification` being updated. */
export type UpdateModificationOnComputeNodeAsElementForComputeNodeAsElementIdFkeyPatch =
  {
    attestationSpecificationAsElement?: InputMaybe<AttestationSpecificationAsElementIdFkeyInverseInput>;
    authenticationMethodAsElement?: InputMaybe<AuthenticationMethodAsElementIdFkeyInverseInput>;
    computeNodeAsElement?: InputMaybe<ComputeNodeAsElementIdFkeyInverseInput>;
    config?: InputMaybe<Scalars["JSON"]>;
    configuration?: InputMaybe<ModificationConfigurationIdFkeyInput>;
    configurationId?: InputMaybe<Scalars["UUID"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    elementId?: InputMaybe<Scalars["UUID"]>;
    elementType?: InputMaybe<ElementTypeType>;
    modification?: InputMaybe<ModificationType>;
    modificationId?: InputMaybe<Scalars["UUID"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userPermissionAsElement?: InputMaybe<UserPermissionAsElementIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `modification` being updated. */
export type UpdateModificationOnModificationForModificationConfigurationIdFkeyPatch =
  {
    attestationSpecificationAsElement?: InputMaybe<AttestationSpecificationAsElementIdFkeyInverseInput>;
    authenticationMethodAsElement?: InputMaybe<AuthenticationMethodAsElementIdFkeyInverseInput>;
    computeNodeAsElement?: InputMaybe<ComputeNodeAsElementIdFkeyInverseInput>;
    config?: InputMaybe<Scalars["JSON"]>;
    configuration?: InputMaybe<ModificationConfigurationIdFkeyInput>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    elementId?: InputMaybe<Scalars["UUID"]>;
    elementType?: InputMaybe<ElementTypeType>;
    modification?: InputMaybe<ModificationType>;
    modificationId?: InputMaybe<Scalars["UUID"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userPermissionAsElement?: InputMaybe<UserPermissionAsElementIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `modification` being updated. */
export type UpdateModificationOnUserPermissionAsElementForUserPermissionAsElementIdFkeyPatch =
  {
    attestationSpecificationAsElement?: InputMaybe<AttestationSpecificationAsElementIdFkeyInverseInput>;
    authenticationMethodAsElement?: InputMaybe<AuthenticationMethodAsElementIdFkeyInverseInput>;
    computeNodeAsElement?: InputMaybe<ComputeNodeAsElementIdFkeyInverseInput>;
    config?: InputMaybe<Scalars["JSON"]>;
    configuration?: InputMaybe<ModificationConfigurationIdFkeyInput>;
    configurationId?: InputMaybe<Scalars["UUID"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    elementId?: InputMaybe<Scalars["UUID"]>;
    elementType?: InputMaybe<ElementTypeType>;
    modification?: InputMaybe<ModificationType>;
    modificationId?: InputMaybe<Scalars["UUID"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userPermissionAsElement?: InputMaybe<UserPermissionAsElementIdFkeyInverseInput>;
  };

/** An object where the defined keys will be set on the `organizationAttribute` being updated. */
export type UpdateOrganizationAttributeOnOrganizationAttributeForOrganizationAttributesOrganizationIdFkeyPatch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    isPostComputeEnabled?: InputMaybe<Scalars["Boolean"]>;
    organization?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `organizationLogo` being updated. */
export type UpdateOrganizationLogoOnOrganizationLogoForOrganizationLogoOrganizationIdFkeyPatch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    logo?: InputMaybe<Scalars["Upload"]>;
    organization?: InputMaybe<OrganizationLogoOrganizationIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `organizationMembership` being updated. */
export type UpdateOrganizationMembershipOnOrganizationMembershipForOrganizationMembershipOrganizationIdFkeyPatch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    organization?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInput>;
    organizationMembershipId?: InputMaybe<Scalars["UUID"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    user?: InputMaybe<OrganizationMembershipUserIdFkeyInput>;
    userId?: InputMaybe<Scalars["UUID"]>;
  };

/** An object where the defined keys will be set on the `organizationMembership` being updated. */
export type UpdateOrganizationMembershipOnOrganizationMembershipForOrganizationMembershipUserIdFkeyPatch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    organization?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInput>;
    organizationId?: InputMaybe<Scalars["UUID"]>;
    organizationMembershipId?: InputMaybe<Scalars["UUID"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    user?: InputMaybe<OrganizationMembershipUserIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `organization` being updated. */
export type UpdateOrganizationOnDomainForDomainOrganizationIdFkeyPatch = {
  containerMetadata?: InputMaybe<Scalars["JSON"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  description?: InputMaybe<Scalars["String"]>;
  domains?: InputMaybe<DomainOrganizationIdFkeyInverseInput>;
  name?: InputMaybe<Scalars["String"]>;
  organizationAttribute?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInverseInput>;
  organizationLogo?: InputMaybe<OrganizationLogoOrganizationIdFkeyInverseInput>;
  organizationMemberships?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInverseInput>;
  organizationQuota?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** An object where the defined keys will be set on the `organization` being updated. */
export type UpdateOrganizationOnOrganizationAttributeForOrganizationAttributesOrganizationIdFkeyPatch =
  {
    containerMetadata?: InputMaybe<Scalars["JSON"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    description?: InputMaybe<Scalars["String"]>;
    domains?: InputMaybe<DomainOrganizationIdFkeyInverseInput>;
    name?: InputMaybe<Scalars["String"]>;
    organizationAttribute?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInverseInput>;
    organizationLogo?: InputMaybe<OrganizationLogoOrganizationIdFkeyInverseInput>;
    organizationMemberships?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInverseInput>;
    organizationQuota?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `organization` being updated. */
export type UpdateOrganizationOnOrganizationLogoForOrganizationLogoOrganizationIdFkeyPatch =
  {
    containerMetadata?: InputMaybe<Scalars["JSON"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    description?: InputMaybe<Scalars["String"]>;
    domains?: InputMaybe<DomainOrganizationIdFkeyInverseInput>;
    name?: InputMaybe<Scalars["String"]>;
    organizationAttribute?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInverseInput>;
    organizationLogo?: InputMaybe<OrganizationLogoOrganizationIdFkeyInverseInput>;
    organizationMemberships?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInverseInput>;
    organizationQuota?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `organization` being updated. */
export type UpdateOrganizationOnOrganizationMembershipForOrganizationMembershipOrganizationIdFkeyPatch =
  {
    containerMetadata?: InputMaybe<Scalars["JSON"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    description?: InputMaybe<Scalars["String"]>;
    domains?: InputMaybe<DomainOrganizationIdFkeyInverseInput>;
    name?: InputMaybe<Scalars["String"]>;
    organizationAttribute?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInverseInput>;
    organizationLogo?: InputMaybe<OrganizationLogoOrganizationIdFkeyInverseInput>;
    organizationMemberships?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInverseInput>;
    organizationQuota?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `organization` being updated. */
export type UpdateOrganizationOnOrganizationQuotaForOrganizationQuotaOrganizationIdFkeyPatch =
  {
    containerMetadata?: InputMaybe<Scalars["JSON"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    description?: InputMaybe<Scalars["String"]>;
    domains?: InputMaybe<DomainOrganizationIdFkeyInverseInput>;
    name?: InputMaybe<Scalars["String"]>;
    organizationAttribute?: InputMaybe<OrganizationAttributesOrganizationIdFkeyInverseInput>;
    organizationLogo?: InputMaybe<OrganizationLogoOrganizationIdFkeyInverseInput>;
    organizationMemberships?: InputMaybe<OrganizationMembershipOrganizationIdFkeyInverseInput>;
    organizationQuota?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `organizationQuota` being updated. */
export type UpdateOrganizationQuotaOnOrganizationQuotaForOrganizationQuotaOrganizationIdFkeyPatch =
  {
    allowed?: InputMaybe<Scalars["Int"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    organization?: InputMaybe<OrganizationQuotaOrganizationIdFkeyInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `permissionAsElement` being updated. */
export type UpdatePermissionAsElementOnPermissionAsElementForPermissionAsElementUserPermissionIdFkeyPatch =
  {
    config?: InputMaybe<Scalars["JSON"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    permissionId?: InputMaybe<Scalars["UUID"]>;
    permissionType?: InputMaybe<PermissionTypeType>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userPermissionAsElement?: InputMaybe<PermissionAsElementUserPermissionIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `permission` being updated. */
export type UpdatePermissionOnPermissionForPermissionUserPermissionIdFkeyPatch =
  {
    config?: InputMaybe<Scalars["JSON"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    permissionId?: InputMaybe<Scalars["UUID"]>;
    permissionType?: InputMaybe<PermissionTypeType>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userPermission?: InputMaybe<PermissionUserPermissionIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `request` being updated. */
export type UpdateRequestOnRequestForRequestConfigurationIdFkeyPatch = {
  configuration?: InputMaybe<RequestConfigurationIdFkeyInput>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<Scalars["String"]>;
  requestId?: InputMaybe<Scalars["UUID"]>;
  signees?: InputMaybe<SigneeRequestIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** An object where the defined keys will be set on the `request` being updated. */
export type UpdateRequestOnSigneeForSigneeRequestIdFkeyPatch = {
  configuration?: InputMaybe<RequestConfigurationIdFkeyInput>;
  configurationId?: InputMaybe<Scalars["UUID"]>;
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  message?: InputMaybe<Scalars["String"]>;
  signees?: InputMaybe<SigneeRequestIdFkeyInverseInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** An object where the defined keys will be set on the `signature` being updated. */
export type UpdateSignatureOnSignatureForSignatureSigneeIdFkeyPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  signatureHash?: InputMaybe<Scalars["String"]>;
  signee?: InputMaybe<SignatureSigneeIdFkeyInput>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** An object where the defined keys will be set on the `signee` being updated. */
export type UpdateSigneeOnSignatureForSignatureSigneeIdFkeyPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  datasets?: InputMaybe<Scalars["JSON"]>;
  request?: InputMaybe<SigneeRequestIdFkeyInput>;
  requestId?: InputMaybe<Scalars["UUID"]>;
  signature?: InputMaybe<SignatureSigneeIdFkeyInverseInput>;
  signeeEmail?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** An object where the defined keys will be set on the `signee` being updated. */
export type UpdateSigneeOnSigneeForSigneeRequestIdFkeyPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  datasets?: InputMaybe<Scalars["JSON"]>;
  request?: InputMaybe<SigneeRequestIdFkeyInput>;
  signature?: InputMaybe<SignatureSigneeIdFkeyInverseInput>;
  signeeEmail?: InputMaybe<Scalars["String"]>;
  signeeId?: InputMaybe<Scalars["UUID"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** An object where the defined keys will be set on the `state` being updated. */
export type UpdateStateOnStateForStateDataRoomIdFkeyPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<StateDataRoomIdFkeyInput>;
  status?: InputMaybe<StatusType>;
  statusUpdatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
};

/** An object where the defined keys will be set on the `userAttribute` being updated. */
export type UpdateUserAttributeOnUserAttributeForUserAttributesUserIdFkeyPatch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    isDemoUser?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    user?: InputMaybe<UserAttributesUserIdFkeyInput>;
  };

/** An object where the defined keys will be set on the `userConfig` being updated. */
export type UpdateUserConfigOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0Patch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRoom?: InputMaybe<UserConfigDataRoomIdFkeyInput>;
    dataRoomId?: InputMaybe<Scalars["UUID"]>;
    isArchived?: InputMaybe<Scalars["Boolean"]>;
    isFavorite?: InputMaybe<Scalars["Boolean"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userByEmail?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0Input>;
    userConfigId?: InputMaybe<Scalars["UUID"]>;
  };

/** An object where the defined keys will be set on the `userConfig` being updated. */
export type UpdateUserConfigOnUserConfigForUserConfigDataRoomIdFkeyPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRoom?: InputMaybe<UserConfigDataRoomIdFkeyInput>;
  email?: InputMaybe<Scalars["String"]>;
  isArchived?: InputMaybe<Scalars["Boolean"]>;
  isFavorite?: InputMaybe<Scalars["Boolean"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  userByEmail?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0Input>;
  userConfigId?: InputMaybe<Scalars["UUID"]>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnDataRoomForFakeDecentriqPlatformDataRoomForeignKey0Patch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
    domain?: InputMaybe<Scalars["String"]>;
    domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
    email?: InputMaybe<Scalars["String"]>;
    emailVerified?: InputMaybe<Scalars["Boolean"]>;
    familyName?: InputMaybe<Scalars["String"]>;
    givenName?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    nickname?: InputMaybe<Scalars["String"]>;
    organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
    picture?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    upstreamUserId?: InputMaybe<Scalars["String"]>;
    userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
    userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
    userId?: InputMaybe<Scalars["UUID"]>;
    userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
    userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
  };

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnDomainForFakeDecentriqPlatformDomainForeignKey0Patch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
  domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
  email?: InputMaybe<Scalars["String"]>;
  emailVerified?: InputMaybe<Scalars["Boolean"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
  picture?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  upstreamUserId?: InputMaybe<Scalars["String"]>;
  userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
  userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
  userId?: InputMaybe<Scalars["UUID"]>;
  userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
  userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnOrganizationMembershipForOrganizationMembershipUserIdFkeyPatch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
    domain?: InputMaybe<Scalars["String"]>;
    domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
    email?: InputMaybe<Scalars["String"]>;
    emailVerified?: InputMaybe<Scalars["Boolean"]>;
    familyName?: InputMaybe<Scalars["String"]>;
    givenName?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    nickname?: InputMaybe<Scalars["String"]>;
    organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
    picture?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    upstreamUserId?: InputMaybe<Scalars["String"]>;
    userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
    userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
    userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
    userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
  };

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnUserAttributeForUserAttributesUserIdFkeyPatch = {
  createdAt?: InputMaybe<Scalars["Datetime"]>;
  createdByEmail?: InputMaybe<Scalars["String"]>;
  dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
  domain?: InputMaybe<Scalars["String"]>;
  domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
  email?: InputMaybe<Scalars["String"]>;
  emailVerified?: InputMaybe<Scalars["Boolean"]>;
  familyName?: InputMaybe<Scalars["String"]>;
  givenName?: InputMaybe<Scalars["String"]>;
  name?: InputMaybe<Scalars["String"]>;
  nickname?: InputMaybe<Scalars["String"]>;
  organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
  picture?: InputMaybe<Scalars["String"]>;
  updatedAt?: InputMaybe<Scalars["Datetime"]>;
  updatedByEmail?: InputMaybe<Scalars["String"]>;
  upstreamUserId?: InputMaybe<Scalars["String"]>;
  userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
  userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
  userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
  userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
};

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnUserConfigForFakeDecentriqPlatformUserConfigForeignKey0Patch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
    domain?: InputMaybe<Scalars["String"]>;
    domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
    emailVerified?: InputMaybe<Scalars["Boolean"]>;
    familyName?: InputMaybe<Scalars["String"]>;
    givenName?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    nickname?: InputMaybe<Scalars["String"]>;
    organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
    picture?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    upstreamUserId?: InputMaybe<Scalars["String"]>;
    userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
    userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
    userId?: InputMaybe<Scalars["UUID"]>;
    userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
    userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
  };

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0Patch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
    domain?: InputMaybe<Scalars["String"]>;
    domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
    emailVerified?: InputMaybe<Scalars["Boolean"]>;
    familyName?: InputMaybe<Scalars["String"]>;
    givenName?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    nickname?: InputMaybe<Scalars["String"]>;
    organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
    picture?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    upstreamUserId?: InputMaybe<Scalars["String"]>;
    userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
    userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
    userId?: InputMaybe<Scalars["UUID"]>;
    userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
    userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
  };

/** An object where the defined keys will be set on the `user` being updated. */
export type UpdateUserOnUserQuotaForFakeDecentriqPlatformUserQuotaForeignKey0Patch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRooms?: InputMaybe<FakeDecentriqPlatformDataRoomForeignKey0InverseInput>;
    domain?: InputMaybe<Scalars["String"]>;
    domainByDomain?: InputMaybe<FakeDecentriqPlatformDomainForeignKey0InverseInput>;
    emailVerified?: InputMaybe<Scalars["Boolean"]>;
    familyName?: InputMaybe<Scalars["String"]>;
    givenName?: InputMaybe<Scalars["String"]>;
    name?: InputMaybe<Scalars["String"]>;
    nickname?: InputMaybe<Scalars["String"]>;
    organizationMemberships?: InputMaybe<OrganizationMembershipUserIdFkeyInverseInput>;
    picture?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    upstreamUserId?: InputMaybe<Scalars["String"]>;
    userAttribute?: InputMaybe<UserAttributesUserIdFkeyInverseInput>;
    userConfigs?: InputMaybe<FakeDecentriqPlatformUserConfigForeignKey0InverseInput>;
    userId?: InputMaybe<Scalars["UUID"]>;
    userPermissions?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0InverseInput>;
    userQuota?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0InverseInput>;
  };

/** An object where the defined keys will be set on the `userPermissionAsElement` being updated. */
export type UpdateUserPermissionAsElementOnPermissionAsElementForPermissionAsElementUserPermissionIdFkeyPatch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    email?: InputMaybe<Scalars["String"]>;
    modification?: InputMaybe<UserPermissionAsElementIdFkeyInput>;
    permissionAsElements?: InputMaybe<PermissionAsElementUserPermissionIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `userPermissionAsElement` being updated. */
export type UpdateUserPermissionAsElementOnUserPermissionAsElementForUserPermissionAsElementIdFkeyPatch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    email?: InputMaybe<Scalars["String"]>;
    modification?: InputMaybe<UserPermissionAsElementIdFkeyInput>;
    permissionAsElements?: InputMaybe<PermissionAsElementUserPermissionIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
  };

/** An object where the defined keys will be set on the `userPermission` being updated. */
export type UpdateUserPermissionOnPermissionForPermissionUserPermissionIdFkeyPatch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRoom?: InputMaybe<UserPermissionDataRoomIdFkeyInput>;
    dataRoomId?: InputMaybe<Scalars["UUID"]>;
    email?: InputMaybe<Scalars["String"]>;
    permissions?: InputMaybe<PermissionUserPermissionIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userByEmail?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0Input>;
  };

/** An object where the defined keys will be set on the `userPermission` being updated. */
export type UpdateUserPermissionOnUserPermissionForFakeDecentriqPlatformUserPermissionForeignKey0Patch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRoom?: InputMaybe<UserPermissionDataRoomIdFkeyInput>;
    dataRoomId?: InputMaybe<Scalars["UUID"]>;
    permissions?: InputMaybe<PermissionUserPermissionIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userByEmail?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0Input>;
    userPermissionId?: InputMaybe<Scalars["UUID"]>;
  };

/** An object where the defined keys will be set on the `userPermission` being updated. */
export type UpdateUserPermissionOnUserPermissionForUserPermissionDataRoomIdFkeyPatch =
  {
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    dataRoom?: InputMaybe<UserPermissionDataRoomIdFkeyInput>;
    email?: InputMaybe<Scalars["String"]>;
    permissions?: InputMaybe<PermissionUserPermissionIdFkeyInverseInput>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userByEmail?: InputMaybe<FakeDecentriqPlatformUserPermissionForeignKey0Input>;
    userPermissionId?: InputMaybe<Scalars["UUID"]>;
  };

/** An object where the defined keys will be set on the `userQuota` being updated. */
export type UpdateUserQuotaOnUserQuotaForFakeDecentriqPlatformUserQuotaForeignKey0Patch =
  {
    allowed?: InputMaybe<Scalars["Int"]>;
    createdAt?: InputMaybe<Scalars["Datetime"]>;
    createdByEmail?: InputMaybe<Scalars["String"]>;
    updatedAt?: InputMaybe<Scalars["Datetime"]>;
    updatedByEmail?: InputMaybe<Scalars["String"]>;
    userByEmail?: InputMaybe<FakeDecentriqPlatformUserQuotaForeignKey0Input>;
  };

export type BranchLeavesOrganizationsQueryVariables = Exact<{
  condition?: InputMaybe<OrganizationCondition>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrganizationsOrderBy> | OrganizationsOrderBy>;
}>;

export type BranchLeavesOrganizationsQuery = {
  __typename?: "Query";
  organizations?: {
    __typename?: "OrganizationsConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Organization";
      id: string;
      organizationId: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      createdAt: any;
      organizationLogo?: {
        __typename?: "OrganizationLogo";
        id: string;
        logo: string;
      } | null;
      domains: {
        __typename?: "DomainsConnection";
        nodes: Array<{
          __typename?: "Domain";
          id: string;
          domainId: any;
          domain: string;
        } | null>;
      };
    } | null>;
  } | null;
};

export type BranchLeavesUsersQueryVariables = Exact<{
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UsersOrderBy> | UsersOrderBy>;
}>;

export type BranchLeavesUsersQuery = {
  __typename?: "Query";
  users?: {
    __typename?: "UsersConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "User";
      id: string;
      userId: any;
      name?: string | null;
      email?: string | null;
      userAttribute?: {
        __typename?: "UserAttribute";
        id: string;
        isDemoUser?: boolean | null;
      } | null;
      organization?: {
        __typename?: "Organization";
        id: string;
        organizationId: any;
        name: string;
      } | null;
    } | null>;
  } | null;
};

export type DatasetsTotalCountQueryVariables = Exact<{
  ownerEmail?: InputMaybe<Scalars["String"]>;
}>;

export type DatasetsTotalCountQuery = {
  __typename?: "Query";
  datasets?: { __typename?: "Datasets"; totalCount?: number | null } | null;
};

export type LeavesOrganizationsQueryVariables = Exact<{
  condition?: InputMaybe<OrganizationCondition>;
  filter?: InputMaybe<OrganizationFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<OrganizationsOrderBy> | OrganizationsOrderBy>;
}>;

export type LeavesOrganizationsQuery = {
  __typename?: "Query";
  organizations?: {
    __typename?: "OrganizationsConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "Organization";
      id: string;
      organizationId: any;
      name: string;
      description?: string | null;
      updatedAt: any;
      createdAt: any;
      organizationLogo?: {
        __typename?: "OrganizationLogo";
        id: string;
        logo: string;
      } | null;
      domains: {
        __typename?: "DomainsConnection";
        nodes: Array<{
          __typename?: "Domain";
          id: string;
          domainId: any;
          domain: string;
        } | null>;
      };
    } | null>;
  } | null;
};

export type LeavesUsersQueryVariables = Exact<{
  condition?: InputMaybe<UserCondition>;
  filter?: InputMaybe<UserFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<UsersOrderBy> | UsersOrderBy>;
}>;

export type LeavesUsersQuery = {
  __typename?: "Query";
  users?: {
    __typename?: "UsersConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "User";
      id: string;
      userId: any;
      name?: string | null;
      email?: string | null;
      userAttribute?: {
        __typename?: "UserAttribute";
        id: string;
        isDemoUser?: boolean | null;
      } | null;
      organization?: {
        __typename?: "Organization";
        id: string;
        organizationId: any;
        name: string;
      } | null;
    } | null>;
  } | null;
};

export type ArchivedDataRoomsQueryVariables = Exact<{
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type ArchivedDataRoomsQuery = {
  __typename?: "Query";
  dataRooms?: {
    __typename?: "DataRoomsConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "DataRoom";
      id: string;
      name: string;
      dataRoomId: any;
      dataRoomHash?: string | null;
      mrenclave?: string | null;
      isOwner?: boolean | null;
      updatedAt: any;
      updatedByEmail: string;
      createdAt: any;
      createdByEmail: string;
      governanceProtocol: any;
      enableDevelopmentTab: boolean;
      ownerEmail: string;
      source: SourceType;
      isExpertViewEnabled?: boolean | null;
      config?: {
        __typename?: "Config";
        id: string;
        showOrganizationLogo: boolean;
      } | null;
      organizationLogo?: {
        __typename?: "OrganizationLogo";
        id: string;
        logo: string;
      } | null;
      userConfig?: {
        __typename?: "UserConfig";
        id: string;
        userConfigId: any;
        isArchived: boolean;
        isFavorite: boolean;
      } | null;
      state?: {
        __typename?: "State";
        id: string;
        isStopped?: boolean | null;
      } | null;
      lock?: {
        __typename?: "Lock";
        id: string;
        isLocked?: boolean | null;
      } | null;
    } | null>;
  } | null;
};

export type ArchivedDataRoomsTotalCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type ArchivedDataRoomsTotalCountQuery = {
  __typename?: "Query";
  dataRooms?: { __typename?: "DataRoomsConnection"; totalCount: number } | null;
};

export type ChangePasswordMutationVariables = Exact<{ [key: string]: never }>;

export type ChangePasswordMutation = {
  __typename?: "Mutation";
  changePassword: {
    __typename?: "ChangePasswordPayload";
    clientMutationId?: string | null;
  };
};

export type DeleteAccountMutationVariables = Exact<{ [key: string]: never }>;

export type DeleteAccountMutation = {
  __typename?: "Mutation";
  deleteAccount: {
    __typename?: "DeleteAccountPayload";
    clientMutationId?: string | null;
  };
};

export type DomainByDomainQueryVariables = Exact<{
  domain: Scalars["String"];
}>;

export type DomainByDomainQuery = {
  __typename?: "Query";
  domainByDomain?: {
    __typename?: "Domain";
    id: string;
    organization?: {
      __typename?: "Organization";
      id: string;
      organizationLogo?: {
        __typename?: "OrganizationLogo";
        id: string;
        logo: string;
      } | null;
    } | null;
  } | null;
};

export type FavoriteDataRoomsQueryVariables = Exact<{ [key: string]: never }>;

export type FavoriteDataRoomsQuery = {
  __typename?: "Query";
  dataRooms?: {
    __typename?: "DataRoomsConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "DataRoom";
      id: string;
      name: string;
      dataRoomId: any;
      dataRoomHash?: string | null;
      mrenclave?: string | null;
      isOwner?: boolean | null;
      updatedAt: any;
      updatedByEmail: string;
      createdAt: any;
      createdByEmail: string;
      governanceProtocol: any;
      enableDevelopmentTab: boolean;
      ownerEmail: string;
      source: SourceType;
      isExpertViewEnabled?: boolean | null;
      config?: {
        __typename?: "Config";
        id: string;
        showOrganizationLogo: boolean;
      } | null;
      organizationLogo?: {
        __typename?: "OrganizationLogo";
        id: string;
        logo: string;
      } | null;
      userConfig?: {
        __typename?: "UserConfig";
        id: string;
        userConfigId: any;
        isArchived: boolean;
        isFavorite: boolean;
      } | null;
      state?: {
        __typename?: "State";
        id: string;
        isStopped?: boolean | null;
      } | null;
      lock?: {
        __typename?: "Lock";
        id: string;
        isLocked?: boolean | null;
      } | null;
    } | null>;
  } | null;
};

export type FavoriteDataRoomsTotalCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type FavoriteDataRoomsTotalCountQuery = {
  __typename?: "Query";
  dataRooms?: { __typename?: "DataRoomsConnection"; totalCount: number } | null;
};

export type MockedPublishedDataRoomQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type MockedPublishedDataRoomQuery = {
  __typename?: "Query";
  publishedDataRoom?: {
    __typename?: "PublishedDataRoom";
    attestationHash: string;
    computeNodesOrder: Array<string>;
    creationDate: any;
    dataNodesOrder: Array<string>;
    description?: string | null;
    enableDevelopment: boolean;
    enableInteractivity: boolean;
    enclaveConfigurationPin: string;
    enclaveId: string;
    id: string;
    isArchived: boolean;
    isStopped: boolean;
    logo?: string | null;
    title: string;
    updateDate: any;
    computeJobs: Array<{
      __typename?: "ComputeJob";
      createdAt: any;
      enclaveJobId: string;
      id: string;
      computeNode:
        | { __typename?: "PublishedRawLeafNode"; id: string }
        | { __typename?: "PublishedSqlNode"; id: string }
        | { __typename?: "PublishedTableLeafNode"; id: string };
      user: { __typename?: "User"; id: string };
    }>;
    nodes: Array<
      | {
          __typename?: "PublishedRawLeafNode";
          id: string;
          name: string;
          isRequired: boolean;
        }
      | {
          __typename?: "PublishedSqlNode";
          id: string;
          name: string;
          statement: string;
          privacyFilter?: {
            __typename?: "SqlNodePrivacyFilter";
            minimumRow: number;
          } | null;
          dependencies: Array<{
            __typename?: "PublishedTableLeafNode";
            id: string;
          }>;
        }
      | {
          __typename?: "PublishedTableLeafNode";
          id: string;
          name: string;
          isRequired: boolean;
          columnsOrder: Array<string>;
          columns: Array<{
            __typename?: "TableLeafNodeColumn";
            id: string;
            name: string;
            dataType: TableLeafNodeColumDataType;
            isNullable: boolean;
          }>;
          dataset?: {
            __typename?: "Dataset";
            id: string;
            manifestHash: string;
            manifest?: any | null;
            name: string;
            uploadDate: any;
            size?: number | null;
            statistics?: any | null;
            chunks: Array<string>;
            owner: { __typename?: "User"; id: string };
            publications: Array<{
              __typename?: "DatasetPublication";
              dataRoom: { __typename?: "PublishedDataRoom"; id: string };
              leaf: { __typename?: "PublishedTableLeafNode"; id: string };
            }>;
          } | null;
        }
    >;
    participants: Array<{
      __typename?: "User";
      id: string;
      publishedDataRooms: Array<{
        __typename?: "PublishedDataRoom";
        id: string;
      }>;
      datasets: Array<{ __typename?: "Dataset"; id: string }>;
    }>;
  } | null;
};

export type MyDataRoomsQueryVariables = Exact<{
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type MyDataRoomsQuery = {
  __typename?: "Query";
  dataRooms?: {
    __typename?: "DataRoomsConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "DataRoom";
      id: string;
      name: string;
      dataRoomId: any;
      dataRoomHash?: string | null;
      mrenclave?: string | null;
      isOwner?: boolean | null;
      updatedAt: any;
      updatedByEmail: string;
      createdAt: any;
      createdByEmail: string;
      governanceProtocol: any;
      enableDevelopmentTab: boolean;
      ownerEmail: string;
      source: SourceType;
      isExpertViewEnabled?: boolean | null;
      config?: {
        __typename?: "Config";
        id: string;
        showOrganizationLogo: boolean;
      } | null;
      organizationLogo?: {
        __typename?: "OrganizationLogo";
        id: string;
        logo: string;
      } | null;
      userConfig?: {
        __typename?: "UserConfig";
        id: string;
        userConfigId: any;
        isArchived: boolean;
        isFavorite: boolean;
      } | null;
      state?: {
        __typename?: "State";
        id: string;
        isStopped?: boolean | null;
      } | null;
      lock?: {
        __typename?: "Lock";
        id: string;
        isLocked?: boolean | null;
      } | null;
    } | null>;
  } | null;
};

export type MyDataRoomsTotalCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type MyDataRoomsTotalCountQuery = {
  __typename?: "Query";
  dataRooms?: { __typename?: "DataRoomsConnection"; totalCount: number } | null;
};

export type NavigationDrawerDataRoomFragment = {
  __typename?: "DataRoom";
  id: string;
  name: string;
  dataRoomId: any;
  dataRoomHash?: string | null;
  mrenclave?: string | null;
  isOwner?: boolean | null;
  updatedAt: any;
  updatedByEmail: string;
  createdAt: any;
  createdByEmail: string;
  governanceProtocol: any;
  enableDevelopmentTab: boolean;
  ownerEmail: string;
  source: SourceType;
  isExpertViewEnabled?: boolean | null;
  config?: {
    __typename?: "Config";
    id: string;
    showOrganizationLogo: boolean;
  } | null;
  organizationLogo?: {
    __typename?: "OrganizationLogo";
    id: string;
    logo: string;
  } | null;
  userConfig?: {
    __typename?: "UserConfig";
    id: string;
    userConfigId: any;
    isArchived: boolean;
    isFavorite: boolean;
  } | null;
  state?: {
    __typename?: "State";
    id: string;
    isStopped?: boolean | null;
  } | null;
  lock?: { __typename?: "Lock"; id: string; isLocked?: boolean | null } | null;
};

export type SharedWithMeDataRoomsQueryVariables = Exact<{
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
}>;

export type SharedWithMeDataRoomsQuery = {
  __typename?: "Query";
  dataRooms?: {
    __typename?: "DataRoomsConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "DataRoom";
      id: string;
      name: string;
      dataRoomId: any;
      dataRoomHash?: string | null;
      mrenclave?: string | null;
      isOwner?: boolean | null;
      updatedAt: any;
      updatedByEmail: string;
      createdAt: any;
      createdByEmail: string;
      governanceProtocol: any;
      enableDevelopmentTab: boolean;
      ownerEmail: string;
      source: SourceType;
      isExpertViewEnabled?: boolean | null;
      config?: {
        __typename?: "Config";
        id: string;
        showOrganizationLogo: boolean;
      } | null;
      organizationLogo?: {
        __typename?: "OrganizationLogo";
        id: string;
        logo: string;
      } | null;
      userConfig?: {
        __typename?: "UserConfig";
        id: string;
        userConfigId: any;
        isArchived: boolean;
        isFavorite: boolean;
      } | null;
      state?: {
        __typename?: "State";
        id: string;
        isStopped?: boolean | null;
      } | null;
      lock?: {
        __typename?: "Lock";
        id: string;
        isLocked?: boolean | null;
      } | null;
    } | null>;
  } | null;
};

export type SharedWithMeDataRoomsTotalCountQueryVariables = Exact<{
  [key: string]: never;
}>;

export type SharedWithMeDataRoomsTotalCountQuery = {
  __typename?: "Query";
  dataRooms?: { __typename?: "DataRoomsConnection"; totalCount: number } | null;
};

export type DataRoomActionsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DataRoomActionsQuery = {
  __typename?: "Query";
  dataRoomById?: {
    __typename?: "DataRoom";
    id: string;
    name: string;
    description?: string | null;
    dataRoomId: any;
    dataRoomHash?: string | null;
    mrenclave?: string | null;
    isOwner?: boolean | null;
    updatedAt: any;
    updatedByEmail: string;
    createdAt: any;
    createdByEmail: string;
    governanceProtocol: any;
    enableDevelopmentTab: boolean;
    ownerEmail: string;
    source: SourceType;
    isExpertViewEnabled?: boolean | null;
    config?: {
      __typename?: "Config";
      id: string;
      showOrganizationLogo: boolean;
    } | null;
    organizationLogo?: {
      __typename?: "OrganizationLogo";
      id: string;
      logo: string;
    } | null;
    userConfig?: {
      __typename?: "UserConfig";
      id: string;
      userConfigId: any;
      isArchived: boolean;
      isFavorite: boolean;
    } | null;
    state?: {
      __typename?: "State";
      id: string;
      isStopped?: boolean | null;
    } | null;
    lock?: {
      __typename?: "Lock";
      id: string;
      isLocked?: boolean | null;
    } | null;
  } | null;
};

export type DataRoomActionsDataRoomQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DataRoomActionsDataRoomQuery = {
  __typename?: "Query";
  dataRoomById?: {
    __typename?: "DataRoom";
    id: string;
    dataRoomId: any;
    name: string;
    description?: string | null;
    mrenclave?: string | null;
    dataRoomHash?: string | null;
    governanceProtocol: any;
    enableDevelopmentTab: boolean;
    source: SourceType;
    ownerEmail: string;
    isOwner?: boolean | null;
    isExpertViewEnabled?: boolean | null;
    state?: {
      __typename?: "State";
      id: string;
      status?: StatusType | null;
      statusUpdatedAt?: any | null;
      isStopped?: boolean | null;
      updatedAt: any;
    } | null;
    config?: {
      __typename?: "Config";
      id: string;
      showOrganizationLogo: boolean;
      dataNodesOrder?: Array<string | null> | null;
      computeNodesOrder?: Array<string | null> | null;
    } | null;
    userConfig?: {
      __typename?: "UserConfig";
      id: string;
      userConfigId: any;
      isArchived: boolean;
      isFavorite: boolean;
    } | null;
    organizationLogo?: {
      __typename?: "OrganizationLogo";
      id: string;
      logo: string;
    } | null;
    dataRoomDataNodes: {
      __typename?: "ComputeNodesConnection";
      nodes: Array<{
        __typename?: "ComputeNode";
        id: string;
        computeNodeId: any;
        computeNodeType: ComputeNodeTypeType;
        nodeName: string;
        config: any;
        index: number;
      } | null>;
    };
    dataRoomComputeNodes: {
      __typename?: "ComputeNodesConnection";
      nodes: Array<{
        __typename?: "ComputeNode";
        id: string;
        computeNodeId: any;
        computeNodeType: ComputeNodeTypeType;
        nodeName: string;
        config: any;
        index: number;
      } | null>;
    };
    userPermissions: {
      __typename?: "UserPermissionsConnection";
      nodes: Array<{
        __typename?: "UserPermission";
        id: string;
        email: string;
        permissions: {
          __typename?: "PermissionsConnection";
          nodes: Array<{
            __typename?: "Permission";
            id: string;
            permissionType: PermissionTypeType;
            config: any;
          } | null>;
        };
      } | null>;
    };
    lock?: {
      __typename?: "Lock";
      id: string;
      isLocked?: boolean | null;
    } | null;
  } | null;
};

export type DataRoomActionsUserConfigQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DataRoomActionsUserConfigQuery = {
  __typename?: "Query";
  dataRoomById?: {
    __typename?: "DataRoom";
    id: string;
    dataRoomId: any;
    userConfig?: {
      __typename?: "UserConfig";
      id: string;
      userConfigId: any;
      isArchived: boolean;
      isFavorite: boolean;
    } | null;
  } | null;
};

export type DataRoomIsExpertViewEnabledFragment = {
  __typename?: "DataRoom";
  isExpertViewEnabled?: boolean | null;
};

export type PublishDataRoomMutationVariables = Exact<{
  input: UpdateDataRoomByIdInput;
}>;

export type PublishDataRoomMutation = {
  __typename?: "Mutation";
  updateDataRoomById?: {
    __typename?: "UpdateDataRoomPayload";
    dataRoom?: {
      __typename?: "DataRoom";
      id: string;
      dataRoomId: any;
      name: string;
      description?: string | null;
      isOwner?: boolean | null;
      lastEditedAt?: any | null;
      updatedAt: any;
      updatedByEmail: string;
      createdAt: any;
      createdByEmail: string;
      mrenclave?: string | null;
      ownerEmail: string;
      dataRoomHash?: string | null;
      governanceProtocol: any;
      enableDevelopmentTab: boolean;
      source: SourceType;
      isSaving?: boolean | null;
      isExpertViewEnabled?: boolean | null;
      userPermissions: {
        __typename?: "UserPermissionsConnection";
        nodes: Array<{
          __typename?: "UserPermission";
          id: string;
          userPermissionId: any;
          email: string;
          updatedAt: any;
          createdAt: any;
          createdByEmail: string;
          dataRoomId: any;
          permissions: {
            __typename?: "PermissionsConnection";
            nodes: Array<{
              __typename?: "Permission";
              id: string;
              permissionId: any;
              permissionType: PermissionTypeType;
              config: any;
              updatedAt: any;
              updatedByEmail: string;
              createdAt: any;
              createdByEmail: string;
            } | null>;
          };
        } | null>;
      };
      dataRoomDataNodes: {
        __typename?: "ComputeNodesConnection";
        nodes: Array<{
          __typename?: "ComputeNode";
          id: string;
          dataRoomId: any;
          computeNodeId: any;
          computeNodeType: ComputeNodeTypeType;
          nodeName: string;
          config: any;
          updatedAt: any;
          updatedByEmail: string;
          createdAt: any;
          createdByEmail: string;
          index: number;
          isSaving?: boolean | null;
          name: string;
          dataRoom?: {
            __typename?: "DataRoom";
            id: string;
            dataRoomId: any;
            userPermissions: {
              __typename?: "UserPermissionsConnection";
              nodes: Array<{
                __typename?: "UserPermission";
                id: string;
                userPermissionId: any;
                email: string;
                updatedAt: any;
                createdAt: any;
                createdByEmail: string;
                dataRoomId: any;
                permissions: {
                  __typename?: "PermissionsConnection";
                  nodes: Array<{
                    __typename?: "Permission";
                    id: string;
                    permissionId: any;
                    permissionType: PermissionTypeType;
                    config: any;
                    updatedAt: any;
                    updatedByEmail: string;
                    createdAt: any;
                    createdByEmail: string;
                  } | null>;
                };
              } | null>;
            };
          } | null;
          datasetLinks: {
            __typename?: "DatasetLinksConnection";
            totalCount: number;
            nodes: Array<{
              __typename?: "DatasetLink";
              id: string;
              datasetLinkId: any;
              datasetHash: string;
              allowStatisticsSharing: boolean;
              datasetId: string;
              dataset?: {
                __typename?: "Dataset";
                id: string;
                datasetHash: string;
                name: string;
                description?: string | null;
                updatedAt?: any | null;
                createdAt?: any | null;
                ownerEmail?: string | null;
                datasetId: string;
                datasetLinks?: {
                  __typename?: "DatasetLinksConnection";
                  nodes: Array<{
                    __typename?: "DatasetLink";
                    id: string;
                    allowStatisticsSharing: boolean;
                    computeNode?: {
                      __typename?: "ComputeNode";
                      id: string;
                      computeNodeId: any;
                      nodeName: string;
                      config: any;
                      dataRoom?: {
                        __typename?: "DataRoom";
                        dataRoomId: any;
                        dataRoomHash?: string | null;
                        mrenclave?: string | null;
                        name: string;
                      } | null;
                    } | null;
                  } | null>;
                } | null;
              } | null;
              datasetStats?: {
                __typename?: "DatasetStat";
                id: string;
                datasetHash: string;
                fileSize: number;
                fillRatio: number;
                numberOfRows: number;
                numberOfDuplicateRows: number;
                numberOfNotNullableRows: number;
                statistics?: any | null;
                statisticsVersion: number;
                updatedAt: any;
                createdAt: any;
                ownerEmail: string;
                datasetId: string;
              } | null;
              datasetMeta?: {
                __typename?: "DatasetMeta";
                id: string;
                datasetHash: string;
                name?: string | null;
                description?: string | null;
                updatedAt: any;
                createdAt: any;
                ownerEmail: string;
                datasetId: string;
              } | null;
              computeNode?: {
                __typename?: "ComputeNode";
                id: string;
                computeNodeId: any;
                computeNodeType: ComputeNodeTypeType;
                nodeName: string;
                config: any;
                dataRoom?: {
                  __typename?: "DataRoom";
                  id: string;
                  dataRoomId: any;
                  dataRoomHash?: string | null;
                  mrenclave?: string | null;
                  name: string;
                } | null;
              } | null;
            } | null>;
          };
        } | null>;
      };
      dataRoomComputeNodes: {
        __typename?: "ComputeNodesConnection";
        nodes: Array<{
          __typename?: "ComputeNode";
          id: string;
          dataRoomId: any;
          computeNodeId: any;
          computeNodeType: ComputeNodeTypeType;
          nodeName: string;
          config: any;
          updatedAt: any;
          updatedByEmail: string;
          createdAt: any;
          createdByEmail: string;
          index: number;
          isSaving?: boolean | null;
          name: string;
          dataRoom?: {
            __typename?: "DataRoom";
            id: string;
            dataRoomId: any;
            userPermissions: {
              __typename?: "UserPermissionsConnection";
              nodes: Array<{
                __typename?: "UserPermission";
                id: string;
                userPermissionId: any;
                email: string;
                updatedAt: any;
                createdAt: any;
                createdByEmail: string;
                dataRoomId: any;
                permissions: {
                  __typename?: "PermissionsConnection";
                  nodes: Array<{
                    __typename?: "Permission";
                    id: string;
                    permissionId: any;
                    permissionType: PermissionTypeType;
                    config: any;
                    updatedAt: any;
                    updatedByEmail: string;
                    createdAt: any;
                    createdByEmail: string;
                  } | null>;
                };
              } | null>;
            };
          } | null;
          jobs: {
            __typename?: "JobsConnection";
            nodes: Array<{
              __typename?: "Job";
              id: string;
              computeNodeId: any;
              jobId?: string | null;
              jobStartedAt?: any | null;
              ownerEmail: string;
            } | null>;
          };
        } | null>;
      };
      state?: {
        __typename?: "State";
        id: string;
        updatedAt: any;
        status?: StatusType | null;
        statusUpdatedAt?: any | null;
        isStopped?: boolean | null;
      } | null;
      config?: {
        __typename?: "Config";
        id: string;
        dataRoomId: any;
        showOrganizationLogo: boolean;
        dataNodesOrder?: Array<string | null> | null;
        computeNodesOrder?: Array<string | null> | null;
      } | null;
      userConfig?: {
        __typename?: "UserConfig";
        id: string;
        userConfigId: any;
        isArchived: boolean;
        isFavorite: boolean;
      } | null;
      organizationLogo?: {
        __typename?: "OrganizationLogo";
        id: string;
        logo: string;
      } | null;
      lock?: {
        __typename?: "Lock";
        id: string;
        isLocked?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type SetDataRoomIsArchivedMutationVariables = Exact<{
  userConfigId?: InputMaybe<Scalars["UUID"]>;
  dataRoomId: Scalars["UUID"];
  isArchived: Scalars["Boolean"];
  email: Scalars["String"];
}>;

export type SetDataRoomIsArchivedMutation = {
  __typename?: "Mutation";
  upsertUserConfig?: {
    __typename?: "UpsertUserConfigPayload";
    userConfig?: {
      __typename?: "UserConfig";
      id: string;
      userConfigId: any;
      dataRoomId: any;
      isArchived: boolean;
      dataRoom?: {
        __typename?: "DataRoom";
        id: string;
        userConfig?: { __typename?: "UserConfig"; id: string } | null;
      } | null;
    } | null;
  } | null;
};

export type SetDataRoomIsFavoriteMutationVariables = Exact<{
  userConfigId?: InputMaybe<Scalars["UUID"]>;
  dataRoomId: Scalars["UUID"];
  email: Scalars["String"];
  isFavorite: Scalars["Boolean"];
}>;

export type SetDataRoomIsFavoriteMutation = {
  __typename?: "Mutation";
  upsertUserConfig?: {
    __typename?: "UpsertUserConfigPayload";
    userConfig?: {
      __typename?: "UserConfig";
      id: string;
      userConfigId: any;
      dataRoomId: any;
      isFavorite: boolean;
      dataRoom?: {
        __typename?: "DataRoom";
        id: string;
        userConfig?: { __typename?: "UserConfig"; id: string } | null;
      } | null;
    } | null;
  } | null;
};

export type SetDataRoomShowOrganizationLogoMutationVariables = Exact<{
  dataRoomId: Scalars["UUID"];
  showOrganizationLogo: Scalars["Boolean"];
}>;

export type SetDataRoomShowOrganizationLogoMutation = {
  __typename?: "Mutation";
  upsertConfig?: {
    __typename?: "UpsertConfigPayload";
    config?: {
      __typename?: "Config";
      id: string;
      dataRoomId: any;
      showOrganizationLogo: boolean;
      dataRoom?: {
        __typename?: "DataRoom";
        id: string;
        config?: { __typename?: "Config"; id: string } | null;
      } | null;
    } | null;
  } | null;
};

export type SetDataRoomStatusMutationVariables = Exact<{
  dataRoomId: Scalars["UUID"];
  status?: InputMaybe<StatusType>;
  statusUpdatedAt?: InputMaybe<Scalars["Datetime"]>;
}>;

export type SetDataRoomStatusMutation = {
  __typename?: "Mutation";
  upsertState?: {
    __typename?: "UpsertStatePayload";
    state?: {
      __typename?: "State";
      id: string;
      dataRoomId: any;
      status?: StatusType | null;
      statusUpdatedAt?: any | null;
      dataRoom?: {
        __typename?: "DataRoom";
        id: string;
        state?: { __typename?: "State"; id: string } | null;
      } | null;
    } | null;
  } | null;
};

export type DataRoomBrandingQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type DataRoomBrandingQuery = {
  __typename?: "Query";
  dataRoomById?: {
    __typename?: "DataRoom";
    id: string;
    ownerEmail: string;
    config?: {
      __typename?: "Config";
      id: string;
      showOrganizationLogo: boolean;
    } | null;
    organizationLogo?: {
      __typename?: "OrganizationLogo";
      id: string;
      logo: string;
    } | null;
  } | null;
};

export type DataRoomsQueryVariables = Exact<{
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<DataRoomCondition>;
  filter?: InputMaybe<DataRoomFilter>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<DataRoomsOrderBy> | DataRoomsOrderBy>;
}>;

export type DataRoomsQuery = {
  __typename?: "Query";
  dataRooms?: {
    __typename?: "DataRoomsConnection";
    nodes: Array<{
      __typename?: "DataRoom";
      id: string;
      dataRoomId: any;
      name: string;
      description?: string | null;
      isOwner?: boolean | null;
      lastEditedAt?: any | null;
      updatedAt: any;
      updatedByEmail: string;
      createdAt: any;
      createdByEmail: string;
      mrenclave?: string | null;
      ownerEmail: string;
      dataRoomHash?: string | null;
      governanceProtocol: any;
      enableDevelopmentTab: boolean;
      source: SourceType;
      isSaving?: boolean | null;
      isExpertViewEnabled?: boolean | null;
      userPermissions: {
        __typename?: "UserPermissionsConnection";
        nodes: Array<{
          __typename?: "UserPermission";
          id: string;
          userPermissionId: any;
          email: string;
          updatedAt: any;
          createdAt: any;
          createdByEmail: string;
          dataRoomId: any;
          permissions: {
            __typename?: "PermissionsConnection";
            nodes: Array<{
              __typename?: "Permission";
              id: string;
              permissionId: any;
              permissionType: PermissionTypeType;
              config: any;
              updatedAt: any;
              updatedByEmail: string;
              createdAt: any;
              createdByEmail: string;
            } | null>;
          };
        } | null>;
      };
      dataRoomDataNodes: {
        __typename?: "ComputeNodesConnection";
        nodes: Array<{
          __typename?: "ComputeNode";
          id: string;
          dataRoomId: any;
          computeNodeId: any;
          computeNodeType: ComputeNodeTypeType;
          nodeName: string;
          config: any;
          updatedAt: any;
          updatedByEmail: string;
          createdAt: any;
          createdByEmail: string;
          index: number;
          isSaving?: boolean | null;
          name: string;
          dataRoom?: {
            __typename?: "DataRoom";
            id: string;
            dataRoomId: any;
            userPermissions: {
              __typename?: "UserPermissionsConnection";
              nodes: Array<{
                __typename?: "UserPermission";
                id: string;
                userPermissionId: any;
                email: string;
                updatedAt: any;
                createdAt: any;
                createdByEmail: string;
                dataRoomId: any;
                permissions: {
                  __typename?: "PermissionsConnection";
                  nodes: Array<{
                    __typename?: "Permission";
                    id: string;
                    permissionId: any;
                    permissionType: PermissionTypeType;
                    config: any;
                    updatedAt: any;
                    updatedByEmail: string;
                    createdAt: any;
                    createdByEmail: string;
                  } | null>;
                };
              } | null>;
            };
          } | null;
          datasetLinks: {
            __typename?: "DatasetLinksConnection";
            totalCount: number;
            nodes: Array<{
              __typename?: "DatasetLink";
              id: string;
              datasetLinkId: any;
              datasetHash: string;
              allowStatisticsSharing: boolean;
              datasetId: string;
              dataset?: {
                __typename?: "Dataset";
                id: string;
                datasetHash: string;
                name: string;
                description?: string | null;
                updatedAt?: any | null;
                createdAt?: any | null;
                ownerEmail?: string | null;
                datasetId: string;
                datasetLinks?: {
                  __typename?: "DatasetLinksConnection";
                  nodes: Array<{
                    __typename?: "DatasetLink";
                    id: string;
                    allowStatisticsSharing: boolean;
                    computeNode?: {
                      __typename?: "ComputeNode";
                      id: string;
                      computeNodeId: any;
                      nodeName: string;
                      config: any;
                      dataRoom?: {
                        __typename?: "DataRoom";
                        dataRoomId: any;
                        dataRoomHash?: string | null;
                        mrenclave?: string | null;
                        name: string;
                      } | null;
                    } | null;
                  } | null>;
                } | null;
              } | null;
              datasetStats?: {
                __typename?: "DatasetStat";
                id: string;
                datasetHash: string;
                fileSize: number;
                fillRatio: number;
                numberOfRows: number;
                numberOfDuplicateRows: number;
                numberOfNotNullableRows: number;
                statistics?: any | null;
                statisticsVersion: number;
                updatedAt: any;
                createdAt: any;
                ownerEmail: string;
                datasetId: string;
              } | null;
              datasetMeta?: {
                __typename?: "DatasetMeta";
                id: string;
                datasetHash: string;
                name?: string | null;
                description?: string | null;
                updatedAt: any;
                createdAt: any;
                ownerEmail: string;
                datasetId: string;
              } | null;
              computeNode?: {
                __typename?: "ComputeNode";
                id: string;
                computeNodeId: any;
                computeNodeType: ComputeNodeTypeType;
                nodeName: string;
                config: any;
                dataRoom?: {
                  __typename?: "DataRoom";
                  id: string;
                  dataRoomId: any;
                  dataRoomHash?: string | null;
                  mrenclave?: string | null;
                  name: string;
                } | null;
              } | null;
            } | null>;
          };
        } | null>;
      };
      dataRoomComputeNodes: {
        __typename?: "ComputeNodesConnection";
        nodes: Array<{
          __typename?: "ComputeNode";
          id: string;
          dataRoomId: any;
          computeNodeId: any;
          computeNodeType: ComputeNodeTypeType;
          nodeName: string;
          config: any;
          updatedAt: any;
          updatedByEmail: string;
          createdAt: any;
          createdByEmail: string;
          index: number;
          isSaving?: boolean | null;
          name: string;
          dataRoom?: {
            __typename?: "DataRoom";
            id: string;
            dataRoomId: any;
            userPermissions: {
              __typename?: "UserPermissionsConnection";
              nodes: Array<{
                __typename?: "UserPermission";
                id: string;
                userPermissionId: any;
                email: string;
                updatedAt: any;
                createdAt: any;
                createdByEmail: string;
                dataRoomId: any;
                permissions: {
                  __typename?: "PermissionsConnection";
                  nodes: Array<{
                    __typename?: "Permission";
                    id: string;
                    permissionId: any;
                    permissionType: PermissionTypeType;
                    config: any;
                    updatedAt: any;
                    updatedByEmail: string;
                    createdAt: any;
                    createdByEmail: string;
                  } | null>;
                };
              } | null>;
            };
          } | null;
          jobs: {
            __typename?: "JobsConnection";
            nodes: Array<{
              __typename?: "Job";
              id: string;
              computeNodeId: any;
              jobId?: string | null;
              jobStartedAt?: any | null;
              ownerEmail: string;
            } | null>;
          };
        } | null>;
      };
      state?: {
        __typename?: "State";
        id: string;
        updatedAt: any;
        status?: StatusType | null;
        statusUpdatedAt?: any | null;
        isStopped?: boolean | null;
      } | null;
      config?: {
        __typename?: "Config";
        id: string;
        dataRoomId: any;
        showOrganizationLogo: boolean;
        dataNodesOrder?: Array<string | null> | null;
        computeNodesOrder?: Array<string | null> | null;
      } | null;
      userConfig?: {
        __typename?: "UserConfig";
        id: string;
        userConfigId: any;
        isArchived: boolean;
        isFavorite: boolean;
      } | null;
      organizationLogo?: {
        __typename?: "OrganizationLogo";
        id: string;
        logo: string;
      } | null;
      lock?: {
        __typename?: "Lock";
        id: string;
        isLocked?: boolean | null;
      } | null;
    } | null>;
  } | null;
};

export type DatasetsQueryVariables = Exact<{
  condition?: InputMaybe<Scalars["JSON"]>;
  filter?: InputMaybe<Scalars["JSON"]>;
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Scalars["JSON"]>;
}>;

export type DatasetsQuery = {
  __typename?: "Query";
  datasets?: {
    __typename?: "Datasets";
    totalCount?: number | null;
    nodes?: Array<{
      __typename?: "Dataset";
      id: string;
      datasetHash: string;
      name: string;
      description?: string | null;
      updatedAt?: any | null;
      createdAt?: any | null;
      ownerEmail?: string | null;
      datasetId: string;
      datasetLinks?: {
        __typename?: "DatasetLinksConnection";
        nodes: Array<{
          __typename?: "DatasetLink";
          id: string;
          datasetLinkId: any;
          datasetHash: string;
          allowStatisticsSharing: boolean;
          datasetId: string;
          dataset?: {
            __typename?: "Dataset";
            id: string;
            datasetHash: string;
            name: string;
            description?: string | null;
            updatedAt?: any | null;
            createdAt?: any | null;
            ownerEmail?: string | null;
            datasetId: string;
            datasetLinks?: {
              __typename?: "DatasetLinksConnection";
              nodes: Array<{
                __typename?: "DatasetLink";
                id: string;
                allowStatisticsSharing: boolean;
                computeNode?: {
                  __typename?: "ComputeNode";
                  id: string;
                  computeNodeId: any;
                  nodeName: string;
                  config: any;
                  dataRoom?: {
                    __typename?: "DataRoom";
                    id: string;
                    dataRoomId: any;
                    dataRoomHash?: string | null;
                    mrenclave?: string | null;
                    name: string;
                  } | null;
                } | null;
              } | null>;
            } | null;
          } | null;
          datasetStats?: {
            __typename?: "DatasetStat";
            id: string;
            datasetHash: string;
            fileSize: number;
            fillRatio: number;
            numberOfRows: number;
            numberOfDuplicateRows: number;
            numberOfNotNullableRows: number;
            statistics?: any | null;
            statisticsVersion: number;
            updatedAt: any;
            createdAt: any;
            ownerEmail: string;
            datasetId: string;
          } | null;
          datasetMeta?: {
            __typename?: "DatasetMeta";
            id: string;
            datasetHash: string;
            name?: string | null;
            description?: string | null;
            updatedAt: any;
            createdAt: any;
            ownerEmail: string;
            datasetId: string;
          } | null;
          computeNode?: {
            __typename?: "ComputeNode";
            id: string;
            computeNodeId: any;
            computeNodeType: ComputeNodeTypeType;
            nodeName: string;
            config: any;
            dataRoom?: {
              __typename?: "DataRoom";
              id: string;
              dataRoomId: any;
              dataRoomHash?: string | null;
              mrenclave?: string | null;
              name: string;
            } | null;
          } | null;
        } | null>;
      } | null;
      datasetStats?: {
        __typename?: "DatasetStat";
        id: string;
        datasetHash: string;
        fileSize: number;
        fillRatio: number;
        numberOfRows: number;
        numberOfDuplicateRows: number;
        numberOfNotNullableRows: number;
        statistics?: any | null;
        statisticsVersion: number;
        updatedAt: any;
        createdAt: any;
        ownerEmail: string;
        datasetId: string;
      } | null;
      datasetMeta?: {
        __typename?: "DatasetMeta";
        id: string;
        datasetHash: string;
        name?: string | null;
        description?: string | null;
        updatedAt: any;
        createdAt: any;
        ownerEmail: string;
        datasetId: string;
      } | null;
    } | null> | null;
  } | null;
};

export type DraftDataRoomQueryVariables = Exact<{
  dataRoomId: Scalars["UUID"];
}>;

export type DraftDataRoomQuery = {
  __typename?: "Query";
  dataRoom?: {
    __typename?: "DataRoom";
    id: string;
    dataRoomId: any;
    name: string;
    description?: string | null;
    isOwner?: boolean | null;
    lastEditedAt?: any | null;
    updatedAt: any;
    updatedByEmail: string;
    createdAt: any;
    createdByEmail: string;
    mrenclave?: string | null;
    ownerEmail: string;
    dataRoomHash?: string | null;
    governanceProtocol: any;
    enableDevelopmentTab: boolean;
    source: SourceType;
    isSaving?: boolean | null;
    isExpertViewEnabled?: boolean | null;
    userPermissions: {
      __typename?: "UserPermissionsConnection";
      nodes: Array<{
        __typename?: "UserPermission";
        id: string;
        userPermissionId: any;
        email: string;
        updatedAt: any;
        createdAt: any;
        createdByEmail: string;
        dataRoomId: any;
        permissions: {
          __typename?: "PermissionsConnection";
          nodes: Array<{
            __typename?: "Permission";
            id: string;
            permissionId: any;
            permissionType: PermissionTypeType;
            config: any;
            updatedAt: any;
            updatedByEmail: string;
            createdAt: any;
            createdByEmail: string;
          } | null>;
        };
      } | null>;
    };
    dataRoomDataNodes: {
      __typename?: "ComputeNodesConnection";
      nodes: Array<{
        __typename?: "ComputeNode";
        id: string;
        dataRoomId: any;
        computeNodeId: any;
        computeNodeType: ComputeNodeTypeType;
        nodeName: string;
        config: any;
        updatedAt: any;
        updatedByEmail: string;
        createdAt: any;
        createdByEmail: string;
        index: number;
        isSaving?: boolean | null;
        name: string;
        dataRoom?: {
          __typename?: "DataRoom";
          id: string;
          dataRoomId: any;
          userPermissions: {
            __typename?: "UserPermissionsConnection";
            nodes: Array<{
              __typename?: "UserPermission";
              id: string;
              userPermissionId: any;
              email: string;
              updatedAt: any;
              createdAt: any;
              createdByEmail: string;
              dataRoomId: any;
              permissions: {
                __typename?: "PermissionsConnection";
                nodes: Array<{
                  __typename?: "Permission";
                  id: string;
                  permissionId: any;
                  permissionType: PermissionTypeType;
                  config: any;
                  updatedAt: any;
                  updatedByEmail: string;
                  createdAt: any;
                  createdByEmail: string;
                } | null>;
              };
            } | null>;
          };
        } | null;
        datasetLinks: {
          __typename?: "DatasetLinksConnection";
          totalCount: number;
          nodes: Array<{
            __typename?: "DatasetLink";
            id: string;
            datasetLinkId: any;
            datasetHash: string;
            allowStatisticsSharing: boolean;
            datasetId: string;
            dataset?: {
              __typename?: "Dataset";
              id: string;
              datasetHash: string;
              name: string;
              description?: string | null;
              updatedAt?: any | null;
              createdAt?: any | null;
              ownerEmail?: string | null;
              datasetId: string;
              datasetLinks?: {
                __typename?: "DatasetLinksConnection";
                nodes: Array<{
                  __typename?: "DatasetLink";
                  id: string;
                  allowStatisticsSharing: boolean;
                  computeNode?: {
                    __typename?: "ComputeNode";
                    id: string;
                    computeNodeId: any;
                    nodeName: string;
                    config: any;
                    dataRoom?: {
                      __typename?: "DataRoom";
                      dataRoomId: any;
                      dataRoomHash?: string | null;
                      mrenclave?: string | null;
                      name: string;
                    } | null;
                  } | null;
                } | null>;
              } | null;
            } | null;
            datasetStats?: {
              __typename?: "DatasetStat";
              id: string;
              datasetHash: string;
              fileSize: number;
              fillRatio: number;
              numberOfRows: number;
              numberOfDuplicateRows: number;
              numberOfNotNullableRows: number;
              statistics?: any | null;
              statisticsVersion: number;
              updatedAt: any;
              createdAt: any;
              ownerEmail: string;
              datasetId: string;
            } | null;
            datasetMeta?: {
              __typename?: "DatasetMeta";
              id: string;
              datasetHash: string;
              name?: string | null;
              description?: string | null;
              updatedAt: any;
              createdAt: any;
              ownerEmail: string;
              datasetId: string;
            } | null;
            computeNode?: {
              __typename?: "ComputeNode";
              id: string;
              computeNodeId: any;
              computeNodeType: ComputeNodeTypeType;
              nodeName: string;
              config: any;
              dataRoom?: {
                __typename?: "DataRoom";
                id: string;
                dataRoomId: any;
                dataRoomHash?: string | null;
                mrenclave?: string | null;
                name: string;
              } | null;
            } | null;
          } | null>;
        };
      } | null>;
    };
    dataRoomComputeNodes: {
      __typename?: "ComputeNodesConnection";
      nodes: Array<{
        __typename?: "ComputeNode";
        id: string;
        dataRoomId: any;
        computeNodeId: any;
        computeNodeType: ComputeNodeTypeType;
        nodeName: string;
        updatedAt: any;
        updatedByEmail: string;
        createdAt: any;
        createdByEmail: string;
        index: number;
        isSaving?: boolean | null;
        name: string;
        dataRoom?: {
          __typename?: "DataRoom";
          id: string;
          dataRoomId: any;
          userPermissions: {
            __typename?: "UserPermissionsConnection";
            nodes: Array<{
              __typename?: "UserPermission";
              id: string;
              userPermissionId: any;
              email: string;
              updatedAt: any;
              createdAt: any;
              createdByEmail: string;
              dataRoomId: any;
              permissions: {
                __typename?: "PermissionsConnection";
                nodes: Array<{
                  __typename?: "Permission";
                  id: string;
                  permissionId: any;
                  permissionType: PermissionTypeType;
                  config: any;
                  updatedAt: any;
                  updatedByEmail: string;
                  createdAt: any;
                  createdByEmail: string;
                } | null>;
              };
            } | null>;
          };
        } | null;
        jobs: {
          __typename?: "JobsConnection";
          nodes: Array<{
            __typename?: "Job";
            id: string;
            computeNodeId: any;
            jobId?: string | null;
            jobStartedAt?: any | null;
            ownerEmail: string;
          } | null>;
        };
      } | null>;
    };
    state?: {
      __typename?: "State";
      id: string;
      updatedAt: any;
      status?: StatusType | null;
      statusUpdatedAt?: any | null;
      isStopped?: boolean | null;
    } | null;
    config?: {
      __typename?: "Config";
      id: string;
      dataRoomId: any;
      showOrganizationLogo: boolean;
      dataNodesOrder?: Array<string | null> | null;
      computeNodesOrder?: Array<string | null> | null;
    } | null;
    userConfig?: {
      __typename?: "UserConfig";
      id: string;
      userConfigId: any;
      isArchived: boolean;
      isFavorite: boolean;
    } | null;
    organizationLogo?: {
      __typename?: "OrganizationLogo";
      id: string;
      logo: string;
    } | null;
    lock?: {
      __typename?: "Lock";
      id: string;
      isLocked?: boolean | null;
    } | null;
  } | null;
};

export type OrganizationDomainIsSavingFragment = {
  __typename?: "Organization";
  isSaving?: boolean | null;
};

export type OrganizationDomainsQueryVariables = Exact<{
  organizationId: Scalars["UUID"];
}>;

export type OrganizationDomainsQuery = {
  __typename?: "Query";
  organization?: {
    __typename?: "Organization";
    id: string;
    organizationId: any;
    domains: {
      __typename?: "DomainsConnection";
      nodes: Array<{
        __typename?: "Domain";
        id: string;
        domainId: any;
        domain: string;
      } | null>;
    };
  } | null;
};

export type UpdateOrganizationDomainMutationVariables = Exact<{
  input: UpdateOrganizationByIdInput;
}>;

export type UpdateOrganizationDomainMutation = {
  __typename?: "Mutation";
  updateOrganizationById?: {
    __typename?: "UpdateOrganizationPayload";
    clientMutationId?: string | null;
    organization?: {
      __typename?: "Organization";
      id: string;
      domains: {
        __typename?: "DomainsConnection";
        nodes: Array<{
          __typename?: "Domain";
          id: string;
          domainId: any;
          domain: string;
        } | null>;
      };
    } | null;
  } | null;
};

export type PublishedDataRoomQueryVariables = Exact<{
  dataRoomId: Scalars["UUID"];
}>;

export type PublishedDataRoomQuery = {
  __typename?: "Query";
  dataRoom?: {
    __typename?: "DataRoom";
    id: string;
    dataRoomId: any;
    name: string;
    description?: string | null;
    isOwner?: boolean | null;
    lastEditedAt?: any | null;
    updatedAt: any;
    updatedByEmail: string;
    createdAt: any;
    createdByEmail: string;
    mrenclave?: string | null;
    ownerEmail: string;
    dataRoomHash?: string | null;
    governanceProtocol: any;
    enableDevelopmentTab: boolean;
    source: SourceType;
    isSaving?: boolean | null;
    isExpertViewEnabled?: boolean | null;
    userPermissions: {
      __typename?: "UserPermissionsConnection";
      nodes: Array<{
        __typename?: "UserPermission";
        id: string;
        userPermissionId: any;
        email: string;
        updatedAt: any;
        createdAt: any;
        createdByEmail: string;
        dataRoomId: any;
        permissions: {
          __typename?: "PermissionsConnection";
          nodes: Array<{
            __typename?: "Permission";
            id: string;
            permissionId: any;
            permissionType: PermissionTypeType;
            config: any;
            updatedAt: any;
            updatedByEmail: string;
            createdAt: any;
            createdByEmail: string;
          } | null>;
        };
      } | null>;
    };
    dataRoomDataNodes: {
      __typename?: "ComputeNodesConnection";
      nodes: Array<{
        __typename?: "ComputeNode";
        id: string;
        dataRoomId: any;
        computeNodeId: any;
        computeNodeType: ComputeNodeTypeType;
        nodeName: string;
        config: any;
        updatedAt: any;
        updatedByEmail: string;
        createdAt: any;
        createdByEmail: string;
        index: number;
        isSaving?: boolean | null;
        name: string;
        dataRoom?: {
          __typename?: "DataRoom";
          id: string;
          dataRoomId: any;
          userPermissions: {
            __typename?: "UserPermissionsConnection";
            nodes: Array<{
              __typename?: "UserPermission";
              id: string;
              userPermissionId: any;
              email: string;
              updatedAt: any;
              createdAt: any;
              createdByEmail: string;
              dataRoomId: any;
              permissions: {
                __typename?: "PermissionsConnection";
                nodes: Array<{
                  __typename?: "Permission";
                  id: string;
                  permissionId: any;
                  permissionType: PermissionTypeType;
                  config: any;
                  updatedAt: any;
                  updatedByEmail: string;
                  createdAt: any;
                  createdByEmail: string;
                } | null>;
              };
            } | null>;
          };
        } | null;
        datasetLinks: {
          __typename?: "DatasetLinksConnection";
          totalCount: number;
          nodes: Array<{
            __typename?: "DatasetLink";
            id: string;
            datasetLinkId: any;
            datasetHash: string;
            allowStatisticsSharing: boolean;
            datasetId: string;
            dataset?: {
              __typename?: "Dataset";
              id: string;
              datasetHash: string;
              name: string;
              description?: string | null;
              updatedAt?: any | null;
              createdAt?: any | null;
              ownerEmail?: string | null;
              datasetId: string;
              datasetLinks?: {
                __typename?: "DatasetLinksConnection";
                nodes: Array<{
                  __typename?: "DatasetLink";
                  id: string;
                  allowStatisticsSharing: boolean;
                  computeNode?: {
                    __typename?: "ComputeNode";
                    id: string;
                    computeNodeId: any;
                    nodeName: string;
                    config: any;
                    dataRoom?: {
                      __typename?: "DataRoom";
                      dataRoomId: any;
                      dataRoomHash?: string | null;
                      mrenclave?: string | null;
                      name: string;
                    } | null;
                  } | null;
                } | null>;
              } | null;
            } | null;
            datasetStats?: {
              __typename?: "DatasetStat";
              id: string;
              datasetHash: string;
              fileSize: number;
              fillRatio: number;
              numberOfRows: number;
              numberOfDuplicateRows: number;
              numberOfNotNullableRows: number;
              statistics?: any | null;
              statisticsVersion: number;
              updatedAt: any;
              createdAt: any;
              ownerEmail: string;
              datasetId: string;
            } | null;
            datasetMeta?: {
              __typename?: "DatasetMeta";
              id: string;
              datasetHash: string;
              name?: string | null;
              description?: string | null;
              updatedAt: any;
              createdAt: any;
              ownerEmail: string;
              datasetId: string;
            } | null;
            computeNode?: {
              __typename?: "ComputeNode";
              id: string;
              computeNodeId: any;
              computeNodeType: ComputeNodeTypeType;
              nodeName: string;
              config: any;
              dataRoom?: {
                __typename?: "DataRoom";
                id: string;
                dataRoomId: any;
                dataRoomHash?: string | null;
                mrenclave?: string | null;
                name: string;
              } | null;
            } | null;
          } | null>;
        };
      } | null>;
    };
    dataRoomComputeNodes: {
      __typename?: "ComputeNodesConnection";
      nodes: Array<{
        __typename?: "ComputeNode";
        id: string;
        dataRoomId: any;
        computeNodeId: any;
        computeNodeType: ComputeNodeTypeType;
        nodeName: string;
        config: any;
        updatedAt: any;
        updatedByEmail: string;
        createdAt: any;
        createdByEmail: string;
        index: number;
        isSaving?: boolean | null;
        name: string;
        dataRoom?: {
          __typename?: "DataRoom";
          id: string;
          dataRoomId: any;
          userPermissions: {
            __typename?: "UserPermissionsConnection";
            nodes: Array<{
              __typename?: "UserPermission";
              id: string;
              userPermissionId: any;
              email: string;
              updatedAt: any;
              createdAt: any;
              createdByEmail: string;
              dataRoomId: any;
              permissions: {
                __typename?: "PermissionsConnection";
                nodes: Array<{
                  __typename?: "Permission";
                  id: string;
                  permissionId: any;
                  permissionType: PermissionTypeType;
                  config: any;
                  updatedAt: any;
                  updatedByEmail: string;
                  createdAt: any;
                  createdByEmail: string;
                } | null>;
              };
            } | null>;
          };
        } | null;
        jobs: {
          __typename?: "JobsConnection";
          nodes: Array<{
            __typename?: "Job";
            id: string;
            computeNodeId: any;
            jobId?: string | null;
            jobStartedAt?: any | null;
            ownerEmail: string;
          } | null>;
        };
      } | null>;
    };
    state?: {
      __typename?: "State";
      id: string;
      updatedAt: any;
      status?: StatusType | null;
      statusUpdatedAt?: any | null;
      isStopped?: boolean | null;
    } | null;
    config?: {
      __typename?: "Config";
      id: string;
      dataRoomId: any;
      showOrganizationLogo: boolean;
      dataNodesOrder?: Array<string | null> | null;
      computeNodesOrder?: Array<string | null> | null;
    } | null;
    userConfig?: {
      __typename?: "UserConfig";
      id: string;
      userConfigId: any;
      isArchived: boolean;
      isFavorite: boolean;
    } | null;
    organizationLogo?: {
      __typename?: "OrganizationLogo";
      id: string;
      logo: string;
    } | null;
    lock?: {
      __typename?: "Lock";
      id: string;
      isLocked?: boolean | null;
    } | null;
  } | null;
};

export type ComputeNodeActionsQueryVariables = Exact<{
  id: Scalars["ID"];
}>;

export type ComputeNodeActionsQuery = {
  __typename?: "Query";
  computeNodeById?: {
    __typename?: "ComputeNode";
    id: string;
    computationType?: string | null;
    job?: {
      __typename?: "Job";
      id: string;
      jobId?: string | null;
      jobStartedAt?: any | null;
      result?: string | null;
      status?: JobStatus | null;
      isSending?: boolean | null;
      resultAsFile?: {
        __typename?: "ResultAsFile";
        fileBits?: string | null;
        fileName?: string | null;
        fileType?: string | null;
      } | null;
    } | null;
  } | null;
};

export type RunComputeNodeMutationVariables = Exact<{
  id: Scalars["ID"];
}>;

export type RunComputeNodeMutation = {
  __typename?: "Mutation";
  runComputeNodeById?: {
    __typename?: "RunComputeNodeByIdPayload";
    computeNode?: { __typename?: "ComputeNode"; id: string } | null;
  } | null;
};

export type UpdateSyntheticComputeNodeMutationVariables = Exact<{
  id: Scalars["ID"];
  config: Scalars["JSON"];
}>;

export type UpdateSyntheticComputeNodeMutation = {
  __typename?: "Mutation";
  updateComputeNodeById?: {
    __typename?: "UpdateComputeNodePayload";
    computeNode?: {
      __typename?: "ComputeNode";
      id: string;
      config: any;
    } | null;
  } | null;
};

export type CreateDataRoomDataNodeMutationVariables = Exact<{
  input: CreateComputeNodeInput;
}>;

export type CreateDataRoomDataNodeMutation = {
  __typename?: "Mutation";
  createComputeNode?: {
    __typename?: "CreateComputeNodePayload";
    dataRoomDataNode?: {
      __typename?: "ComputeNode";
      id: string;
      dataRoomId: any;
      computeNodeId: any;
      computeNodeType: ComputeNodeTypeType;
      nodeName: string;
      config: any;
      updatedAt: any;
      updatedByEmail: string;
      createdAt: any;
      createdByEmail: string;
      index: number;
      isSaving?: boolean | null;
      name: string;
      dataRoom?: {
        __typename?: "DataRoom";
        id: string;
        dataRoomId: any;
        userPermissions: {
          __typename?: "UserPermissionsConnection";
          nodes: Array<{
            __typename?: "UserPermission";
            id: string;
            userPermissionId: any;
            email: string;
            updatedAt: any;
            createdAt: any;
            createdByEmail: string;
            dataRoomId: any;
            permissions: {
              __typename?: "PermissionsConnection";
              nodes: Array<{
                __typename?: "Permission";
                id: string;
                permissionId: any;
                permissionType: PermissionTypeType;
                config: any;
                updatedAt: any;
                updatedByEmail: string;
                createdAt: any;
                createdByEmail: string;
              } | null>;
            };
          } | null>;
        };
      } | null;
      datasetLinks: {
        __typename?: "DatasetLinksConnection";
        totalCount: number;
        nodes: Array<{
          __typename?: "DatasetLink";
          id: string;
          datasetLinkId: any;
          datasetHash: string;
          allowStatisticsSharing: boolean;
          datasetId: string;
          dataset?: {
            __typename?: "Dataset";
            id: string;
            datasetHash: string;
            name: string;
            description?: string | null;
            updatedAt?: any | null;
            createdAt?: any | null;
            ownerEmail?: string | null;
            datasetId: string;
            datasetLinks?: {
              __typename?: "DatasetLinksConnection";
              nodes: Array<{
                __typename?: "DatasetLink";
                id: string;
                allowStatisticsSharing: boolean;
                computeNode?: {
                  __typename?: "ComputeNode";
                  id: string;
                  computeNodeId: any;
                  nodeName: string;
                  config: any;
                  dataRoom?: {
                    __typename?: "DataRoom";
                    id: string;
                    dataRoomId: any;
                    dataRoomHash?: string | null;
                    mrenclave?: string | null;
                    name: string;
                  } | null;
                } | null;
              } | null>;
            } | null;
          } | null;
          datasetStats?: {
            __typename?: "DatasetStat";
            id: string;
            datasetHash: string;
            fileSize: number;
            fillRatio: number;
            numberOfRows: number;
            numberOfDuplicateRows: number;
            numberOfNotNullableRows: number;
            statistics?: any | null;
            statisticsVersion: number;
            updatedAt: any;
            createdAt: any;
            ownerEmail: string;
            datasetId: string;
          } | null;
          datasetMeta?: {
            __typename?: "DatasetMeta";
            id: string;
            datasetHash: string;
            name?: string | null;
            description?: string | null;
            updatedAt: any;
            createdAt: any;
            ownerEmail: string;
            datasetId: string;
          } | null;
          computeNode?: {
            __typename?: "ComputeNode";
            id: string;
            computeNodeId: any;
            computeNodeType: ComputeNodeTypeType;
            nodeName: string;
            config: any;
            dataRoom?: {
              __typename?: "DataRoom";
              id: string;
              dataRoomId: any;
              dataRoomHash?: string | null;
              mrenclave?: string | null;
              name: string;
            } | null;
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DataRoomDataNodesQueryVariables = Exact<{
  dataRoomId: Scalars["UUID"];
  first?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ComputeNodesOrderBy> | ComputeNodesOrderBy>;
}>;

export type DataRoomDataNodesQuery = {
  __typename?: "Query";
  dataRoom?: { __typename?: "DataRoom"; id: string } | null;
  config?: {
    __typename?: "Config";
    id: string;
    dataNodesOrder?: Array<string | null> | null;
  } | null;
  dataRoomDataNodes?: {
    __typename?: "ComputeNodesConnection";
    totalCount: number;
    nodes: Array<{
      __typename?: "ComputeNode";
      id: string;
      dataRoomId: any;
      computeNodeId: any;
      computeNodeType: ComputeNodeTypeType;
      nodeName: string;
      config: any;
      updatedAt: any;
      updatedByEmail: string;
      createdAt: any;
      createdByEmail: string;
      index: number;
      isSaving?: boolean | null;
      name: string;
      dataRoom?: {
        __typename?: "DataRoom";
        id: string;
        dataRoomId: any;
        userPermissions: {
          __typename?: "UserPermissionsConnection";
          nodes: Array<{
            __typename?: "UserPermission";
            id: string;
            userPermissionId: any;
            email: string;
            updatedAt: any;
            createdAt: any;
            createdByEmail: string;
            dataRoomId: any;
            permissions: {
              __typename?: "PermissionsConnection";
              nodes: Array<{
                __typename?: "Permission";
                id: string;
                permissionId: any;
                permissionType: PermissionTypeType;
                config: any;
                updatedAt: any;
                updatedByEmail: string;
                createdAt: any;
                createdByEmail: string;
              } | null>;
            };
          } | null>;
        };
      } | null;
      datasetLinks: {
        __typename?: "DatasetLinksConnection";
        totalCount: number;
        nodes: Array<{
          __typename?: "DatasetLink";
          id: string;
          datasetLinkId: any;
          datasetHash: string;
          allowStatisticsSharing: boolean;
          datasetId: string;
          dataset?: {
            __typename?: "Dataset";
            id: string;
            datasetHash: string;
            name: string;
            description?: string | null;
            updatedAt?: any | null;
            createdAt?: any | null;
            ownerEmail?: string | null;
            datasetId: string;
            datasetLinks?: {
              __typename?: "DatasetLinksConnection";
              nodes: Array<{
                __typename?: "DatasetLink";
                id: string;
                allowStatisticsSharing: boolean;
                computeNode?: {
                  __typename?: "ComputeNode";
                  id: string;
                  computeNodeId: any;
                  nodeName: string;
                  config: any;
                  dataRoom?: {
                    __typename?: "DataRoom";
                    id: string;
                    dataRoomId: any;
                    dataRoomHash?: string | null;
                    mrenclave?: string | null;
                    name: string;
                  } | null;
                } | null;
              } | null>;
            } | null;
          } | null;
          datasetStats?: {
            __typename?: "DatasetStat";
            id: string;
            datasetHash: string;
            fileSize: number;
            fillRatio: number;
            numberOfRows: number;
            numberOfDuplicateRows: number;
            numberOfNotNullableRows: number;
            statistics?: any | null;
            statisticsVersion: number;
            updatedAt: any;
            createdAt: any;
            ownerEmail: string;
            datasetId: string;
          } | null;
          datasetMeta?: {
            __typename?: "DatasetMeta";
            id: string;
            datasetHash: string;
            name?: string | null;
            description?: string | null;
            updatedAt: any;
            createdAt: any;
            ownerEmail: string;
            datasetId: string;
          } | null;
          computeNode?: {
            __typename?: "ComputeNode";
            id: string;
            computeNodeId: any;
            computeNodeType: ComputeNodeTypeType;
            nodeName: string;
            config: any;
            dataRoom?: {
              __typename?: "DataRoom";
              id: string;
              dataRoomId: any;
              dataRoomHash?: string | null;
              mrenclave?: string | null;
              name: string;
            } | null;
          } | null;
        } | null>;
      };
    } | null>;
  } | null;
};

export type DeleteDataRoomDataNodeMutationVariables = Exact<{
  input: DeleteComputeNodeInput;
}>;

export type DeleteDataRoomDataNodeMutation = {
  __typename?: "Mutation";
  deleteComputeNode?: {
    __typename?: "DeleteComputeNodePayload";
    dataRoomDataNode?: { __typename?: "ComputeNode"; id: string } | null;
  } | null;
};

export type SetDataRoomDataNodesOrderMutationVariables = Exact<{
  dataRoomId: Scalars["UUID"];
  dataNodesOrder: Array<Scalars["String"]> | Scalars["String"];
}>;

export type SetDataRoomDataNodesOrderMutation = {
  __typename?: "Mutation";
  upsertConfig?: {
    __typename?: "UpsertConfigPayload";
    config?: {
      __typename?: "Config";
      id: string;
      dataRoomId: any;
      dataNodesOrder?: Array<string | null> | null;
      dataRoom?: {
        __typename?: "DataRoom";
        id: string;
        config?: { __typename?: "Config"; id: string } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateDataRoomDataNodeMutationVariables = Exact<{
  input: UpdateComputeNodeInput;
}>;

export type UpdateDataRoomDataNodeMutation = {
  __typename?: "Mutation";
  updateComputeNode?: {
    __typename?: "UpdateComputeNodePayload";
    computeNode?: {
      __typename?: "ComputeNode";
      id: string;
      nodeName: string;
      config: any;
    } | null;
  } | null;
};

export type CreateDatasetLinkMutationVariables = Exact<{
  input: CreateDatasetLinkInput;
  ownerEmail?: InputMaybe<Scalars["String"]>;
}>;

export type CreateDatasetLinkMutation = {
  __typename?: "Mutation";
  createDatasetLink?: {
    __typename?: "CreateDatasetLinkPayload";
    clientMutationId?: string | null;
    datasetLink?: {
      __typename?: "DatasetLink";
      datasetHash: string;
      computeNodeId?: any | null;
    } | null;
    query?: {
      __typename?: "Query";
      datasets?: { __typename?: "Datasets"; totalCount?: number | null } | null;
    } | null;
  } | null;
};

export type CreateDatasetMetaAndStatsMutationVariables = Exact<{
  inputMeta: CreateDatasetMetaInput;
  inputStats: CreateDatasetStatInput;
}>;

export type CreateDatasetMetaAndStatsMutation = {
  __typename?: "Mutation";
  createDatasetMeta?: {
    __typename?: "CreateDatasetMetaPayload";
    datasetMeta?: {
      __typename?: "DatasetMeta";
      id: string;
      datasetHash: string;
      name?: string | null;
      description?: string | null;
      updatedAt: any;
      createdAt: any;
      ownerEmail: string;
      datasetId: string;
    } | null;
  } | null;
  createDatasetStat?: {
    __typename?: "CreateDatasetStatPayload";
    datasetStat?: {
      __typename?: "DatasetStat";
      id: string;
      datasetHash: string;
      fileSize: number;
      fillRatio: number;
      numberOfRows: number;
      numberOfDuplicateRows: number;
      numberOfNotNullableRows: number;
      statistics?: any | null;
      statisticsVersion: number;
      updatedAt: any;
      createdAt: any;
      ownerEmail: string;
      datasetId: string;
    } | null;
  } | null;
};

export type DeleteDatasetLinkMutationVariables = Exact<{
  input: DeleteDatasetLinkInput;
}>;

export type DeleteDatasetLinkMutation = {
  __typename?: "Mutation";
  deleteDatasetLink?: {
    __typename?: "DeleteDatasetLinkPayload";
    clientMutationId?: string | null;
  } | null;
};

export type DeleteDatasetMetaAndStatsMutationVariables = Exact<{
  datasetHash: Scalars["String"];
}>;

export type DeleteDatasetMetaAndStatsMutation = {
  __typename?: "Mutation";
  deleteDatasetMeta?: {
    __typename?: "DeleteDatasetMetaPayload";
    clientMutationId?: string | null;
  } | null;
  deleteDatasetStat?: {
    __typename?: "DeleteDatasetStatPayload";
    clientMutationId?: string | null;
  } | null;
};

export type CreateMergeableSignatureMutationVariables = Exact<{
  input: CreateSignatureInput;
  requestsOrderBy?: InputMaybe<Array<RequestsOrderBy> | RequestsOrderBy>;
  dataRoomId: Scalars["UUID"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
}>;

export type CreateMergeableSignatureMutation = {
  __typename?: "Mutation";
  createSignature?: {
    __typename?: "CreateSignaturePayload";
    clientMutationId?: string | null;
    signature?: {
      __typename?: "Signature";
      id: string;
      signee?: {
        __typename?: "Signee";
        id: string;
        request?: {
          __typename?: "Request";
          id: string;
          signees: {
            __typename?: "SigneesConnection";
            nodes: Array<{
              __typename?: "Signee";
              id: string;
              signeeEmail: string;
              signeeId: any;
              datasets: any;
              signature?: {
                __typename?: "Signature";
                id: string;
                createdAt: any;
                createdByEmail: string;
                signatureHash: string;
              } | null;
            } | null>;
          };
        } | null;
      } | null;
    } | null;
    query?: {
      __typename?: "Query";
      requests?: {
        __typename?: "RequestsConnection";
        nodes: Array<{
          __typename?: "Request";
          id: string;
          isSigned?: boolean | null;
          createdAt: any;
          createdByEmail: string;
          message: string;
          requestId: any;
          updatedAt: any;
          updatedByEmail: string;
          signees: {
            __typename?: "SigneesConnection";
            nodes: Array<{
              __typename?: "Signee";
              id: string;
              createdAt: any;
              createdByEmail: string;
              requestId: any;
              signeeEmail: string;
              signeeId: any;
              datasets: any;
              updatedAt: any;
              updatedByEmail: string;
              signature?: {
                __typename?: "Signature";
                createdAt: any;
                createdByEmail: string;
                id: string;
                signatureHash: string;
                signeeId: any;
                updatedAt: any;
                updatedByEmail: string;
              } | null;
            } | null>;
          };
          configuration?: {
            __typename?: "Configuration";
            configurationId: any;
            commitId?: string | null;
            createdAt: any;
            createdByEmail: string;
            dataRoomId: any;
            id: string;
            ownerEmail: string;
            updatedAt: any;
            updatedByEmail: string;
            modifications: {
              __typename?: "ModificationsConnection";
              nodes: Array<{
                __typename?: "Modification";
                id: string;
                config?: any | null;
                configurationId: any;
                createdAt: any;
                createdByEmail: string;
                elementId: any;
                elementType: ElementTypeType;
                modification: ModificationType;
                modificationId: any;
                updatedAt: any;
                updatedByEmail: string;
                computeNodeAsElement?: {
                  __typename?: "ComputeNodeAsElement";
                  id: string;
                  computeNodeId: any;
                  computeNodeType: ComputeNodeTypeType;
                  config: any;
                  createdAt: any;
                  createdByEmail: string;
                  nodeName: string;
                  updatedAt: any;
                  updatedByEmail: string;
                } | null;
                userPermissionAsElement?: {
                  __typename?: "UserPermissionAsElement";
                  id: string;
                  email: string;
                  userPermissionId: any;
                  updatedAt: any;
                  updatedByEmail: string;
                  permissionAsElementsByUserPermissionId: {
                    __typename?: "PermissionAsElementsConnection";
                    nodes: Array<{
                      __typename?: "PermissionAsElement";
                      id: string;
                      permissionType: PermissionTypeType;
                      config: any;
                      permissionId: any;
                    } | null>;
                  };
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type CreateSignatureMutationVariables = Exact<{
  input: CreateSignatureInput;
  requestsOrderBy?: InputMaybe<Array<RequestsOrderBy> | RequestsOrderBy>;
  configurationsOrderBy?: InputMaybe<
    Array<ConfigurationsOrderBy> | ConfigurationsOrderBy
  >;
  dataRoomId: Scalars["UUID"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
}>;

export type CreateSignatureMutation = {
  __typename?: "Mutation";
  createSignature?: {
    __typename?: "CreateSignaturePayload";
    clientMutationId?: string | null;
    signature?: {
      __typename?: "Signature";
      id: string;
      signee?: {
        __typename?: "Signee";
        id: string;
        request?: {
          __typename?: "Request";
          id: string;
          signees: {
            __typename?: "SigneesConnection";
            nodes: Array<{
              __typename?: "Signee";
              id: string;
              signeeEmail: string;
              signeeId: any;
              datasets: any;
              signature?: {
                __typename?: "Signature";
                id: string;
                createdAt: any;
                createdByEmail: string;
                signatureHash: string;
              } | null;
            } | null>;
          };
        } | null;
      } | null;
    } | null;
    query?: {
      __typename?: "Query";
      requests?: {
        __typename?: "RequestsConnection";
        nodes: Array<{
          __typename?: "Request";
          id: string;
          isSigned?: boolean | null;
          createdAt: any;
          createdByEmail: string;
          message: string;
          requestId: any;
          updatedAt: any;
          updatedByEmail: string;
          signees: {
            __typename?: "SigneesConnection";
            nodes: Array<{
              __typename?: "Signee";
              id: string;
              createdAt: any;
              createdByEmail: string;
              requestId: any;
              signeeEmail: string;
              signeeId: any;
              datasets: any;
              updatedAt: any;
              updatedByEmail: string;
              signature?: {
                __typename?: "Signature";
                createdAt: any;
                createdByEmail: string;
                id: string;
                signatureHash: string;
                signeeId: any;
                updatedAt: any;
                updatedByEmail: string;
              } | null;
            } | null>;
          };
          configuration?: {
            __typename?: "Configuration";
            configurationId: any;
            commitId?: string | null;
            createdAt: any;
            createdByEmail: string;
            dataRoomId: any;
            id: string;
            ownerEmail: string;
            updatedAt: any;
            updatedByEmail: string;
            modifications: {
              __typename?: "ModificationsConnection";
              nodes: Array<{
                __typename?: "Modification";
                id: string;
                config?: any | null;
                configurationId: any;
                createdAt: any;
                createdByEmail: string;
                elementId: any;
                elementType: ElementTypeType;
                modification: ModificationType;
                modificationId: any;
                updatedAt: any;
                updatedByEmail: string;
                computeNodeAsElement?: {
                  __typename?: "ComputeNodeAsElement";
                  id: string;
                  computeNodeId: any;
                  computeNodeType: ComputeNodeTypeType;
                  config: any;
                  createdAt: any;
                  createdByEmail: string;
                  nodeName: string;
                  updatedAt: any;
                  updatedByEmail: string;
                } | null;
                userPermissionAsElement?: {
                  __typename?: "UserPermissionAsElement";
                  id: string;
                  email: string;
                  userPermissionId: any;
                  updatedAt: any;
                  updatedByEmail: string;
                  permissionAsElementsByUserPermissionId: {
                    __typename?: "PermissionAsElementsConnection";
                    nodes: Array<{
                      __typename?: "PermissionAsElement";
                      id: string;
                      permissionType: PermissionTypeType;
                      config: any;
                      permissionId: any;
                    } | null>;
                  };
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      } | null;
      configurations?: {
        __typename?: "ConfigurationsConnection";
        nodes: Array<{
          __typename?: "Configuration";
          id: string;
          dataRoomId: any;
          configurationId: any;
          commitId?: string | null;
          isDev: boolean;
          ownerEmail: string;
          updatedAt: any;
          updatedByEmail: string;
          createdAt: any;
          createdByEmail: string;
          request?: {
            __typename?: "Request";
            id: string;
            requestId: any;
            isSigned?: boolean | null;
            signees: {
              __typename?: "SigneesConnection";
              nodes: Array<{
                __typename?: "Signee";
                id: string;
                signeeId: any;
                signeeEmail: string;
                datasets: any;
                signature?: {
                  __typename?: "Signature";
                  id: string;
                  signatureHash: string;
                  createdAt: any;
                } | null;
              } | null>;
            };
          } | null;
          modifications: {
            __typename?: "ModificationsConnection";
            nodes: Array<{
              __typename?: "Modification";
              id: string;
              configurationId: any;
              modificationId: any;
              modification: ModificationType;
              config?: any | null;
              elementId: any;
              elementType: ElementTypeType;
              computeNodeAsElement?: {
                __typename?: "ComputeNodeAsElement";
                id: string;
                computeNodeId: any;
                computeNodeType: ComputeNodeTypeType;
                nodeName: string;
                config: any;
                updatedAt: any;
                updatedByEmail: string;
                createdAt: any;
                createdByEmail: string;
              } | null;
              userPermissionAsElement?: {
                __typename?: "UserPermissionAsElement";
                id: string;
                email: string;
                userPermissionId: any;
                updatedAt: any;
                updatedByEmail: string;
                permissionAsElementsByUserPermissionId: {
                  __typename?: "PermissionAsElementsConnection";
                  nodes: Array<{
                    __typename?: "PermissionAsElement";
                    id: string;
                    permissionType: PermissionTypeType;
                    config: any;
                    permissionId: any;
                  } | null>;
                };
              } | null;
            } | null>;
          };
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type DeleteRequestMutationVariables = Exact<{
  input: DeleteRequestInput;
}>;

export type DeleteRequestMutation = {
  __typename?: "Mutation";
  deleteRequest?: {
    __typename?: "DeleteRequestPayload";
    clientMutationId?: string | null;
  } | null;
};

export type RequestByConfigurationIdQueryVariables = Exact<{
  configurationId: Scalars["UUID"];
}>;

export type RequestByConfigurationIdQuery = {
  __typename?: "Query";
  requestByConfigurationId?: {
    __typename?: "Request";
    id: string;
    isSigned?: boolean | null;
    createdAt: any;
    createdByEmail: string;
    message: string;
    requestId: any;
    updatedAt: any;
    updatedByEmail: string;
    signees: {
      __typename?: "SigneesConnection";
      nodes: Array<{
        __typename?: "Signee";
        id: string;
        createdAt: any;
        createdByEmail: string;
        requestId: any;
        signeeEmail: string;
        signeeId: any;
        datasets: any;
        updatedAt: any;
        updatedByEmail: string;
        signature?: {
          __typename?: "Signature";
          createdAt: any;
          createdByEmail: string;
          id: string;
          signatureHash: string;
          signeeId: any;
          updatedAt: any;
          updatedByEmail: string;
        } | null;
      } | null>;
    };
    configuration?: {
      __typename?: "Configuration";
      id: string;
      configurationId: any;
      commitId?: string | null;
      createdAt: any;
      createdByEmail: string;
      dataRoomId: any;
      ownerEmail: string;
      updatedAt: any;
      updatedByEmail: string;
      modifications: {
        __typename?: "ModificationsConnection";
        nodes: Array<{
          __typename?: "Modification";
          id: string;
          config?: any | null;
          configurationId: any;
          createdAt: any;
          createdByEmail: string;
          elementId: any;
          elementType: ElementTypeType;
          modification: ModificationType;
          modificationId: any;
          updatedAt: any;
          updatedByEmail: string;
          computeNodeAsElement?: {
            __typename?: "ComputeNodeAsElement";
            id: string;
            computeNodeId: any;
            computeNodeType: ComputeNodeTypeType;
            config: any;
            createdAt: any;
            createdByEmail: string;
            nodeName: string;
            updatedAt: any;
            updatedByEmail: string;
          } | null;
          userPermissionAsElement?: {
            __typename?: "UserPermissionAsElement";
            id: string;
            email: string;
            userPermissionId: any;
            updatedAt: any;
            updatedByEmail: string;
            permissionAsElementsByUserPermissionId: {
              __typename?: "PermissionAsElementsConnection";
              nodes: Array<{
                __typename?: "PermissionAsElement";
                id: string;
                permissionType: PermissionTypeType;
                config: any;
                permissionId: any;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null;
  } | null;
};

export type DataRoomRequestsQueryVariables = Exact<{
  dataRoomId: Scalars["UUID"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<RequestCondition>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<RequestsOrderBy> | RequestsOrderBy>;
}>;

export type DataRoomRequestsQuery = {
  __typename?: "Query";
  requests?: {
    __typename?: "RequestsConnection";
    nodes: Array<{
      __typename?: "Request";
      id: string;
      isSigned?: boolean | null;
      createdAt: any;
      createdByEmail: string;
      message: string;
      requestId: any;
      updatedAt: any;
      updatedByEmail: string;
      signees: {
        __typename?: "SigneesConnection";
        nodes: Array<{
          __typename?: "Signee";
          id: string;
          createdAt: any;
          createdByEmail: string;
          requestId: any;
          signeeEmail: string;
          signeeId: any;
          datasets: any;
          updatedAt: any;
          updatedByEmail: string;
          signature?: {
            __typename?: "Signature";
            createdAt: any;
            createdByEmail: string;
            id: string;
            signatureHash: string;
            signeeId: any;
            updatedAt: any;
            updatedByEmail: string;
          } | null;
        } | null>;
      };
      configuration?: {
        __typename?: "Configuration";
        configurationId: any;
        commitId?: string | null;
        createdAt: any;
        createdByEmail: string;
        dataRoomId: any;
        id: string;
        ownerEmail: string;
        updatedAt: any;
        updatedByEmail: string;
        modifications: {
          __typename?: "ModificationsConnection";
          nodes: Array<{
            __typename?: "Modification";
            id: string;
            config?: any | null;
            configurationId: any;
            createdAt: any;
            createdByEmail: string;
            elementId: any;
            elementType: ElementTypeType;
            modification: ModificationType;
            modificationId: any;
            updatedAt: any;
            updatedByEmail: string;
            computeNodeAsElement?: {
              __typename?: "ComputeNodeAsElement";
              id: string;
              computeNodeId: any;
              computeNodeType: ComputeNodeTypeType;
              config: any;
              createdAt: any;
              createdByEmail: string;
              nodeName: string;
              updatedAt: any;
              updatedByEmail: string;
            } | null;
            userPermissionAsElement?: {
              __typename?: "UserPermissionAsElement";
              id: string;
              email: string;
              userPermissionId: any;
              updatedAt: any;
              updatedByEmail: string;
              permissionAsElementsByUserPermissionId: {
                __typename?: "PermissionAsElementsConnection";
                nodes: Array<{
                  __typename?: "PermissionAsElement";
                  id: string;
                  permissionType: PermissionTypeType;
                  config: any;
                  permissionId: any;
                } | null>;
              };
            } | null;
          } | null>;
        };
      } | null;
    } | null>;
  } | null;
};

export type DataRoomRequestsTotalCountQueryVariables = Exact<{
  dataRoomId: Scalars["UUID"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<RequestCondition>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<RequestsOrderBy> | RequestsOrderBy>;
}>;

export type DataRoomRequestsTotalCountQuery = {
  __typename?: "Query";
  requests?: { __typename?: "RequestsConnection"; totalCount: number } | null;
};

export type DataRoomQueryVariables = Exact<{
  dataRoomId: Scalars["UUID"];
}>;

export type DataRoomQuery = {
  __typename?: "Query";
  dataRoom?: {
    __typename?: "DataRoom";
    id: string;
    dataRoomId: any;
    name: string;
    description?: string | null;
    isOwner?: boolean | null;
    lastEditedAt?: any | null;
    updatedAt: any;
    updatedByEmail: string;
    createdAt: any;
    createdByEmail: string;
    mrenclave?: string | null;
    ownerEmail: string;
    dataRoomHash?: string | null;
    governanceProtocol: any;
    enableDevelopmentTab: boolean;
    source: SourceType;
    isSaving?: boolean | null;
    isExpertViewEnabled?: boolean | null;
    userPermissions: {
      __typename?: "UserPermissionsConnection";
      nodes: Array<{
        __typename?: "UserPermission";
        id: string;
        userPermissionId: any;
        email: string;
        updatedAt: any;
        createdAt: any;
        createdByEmail: string;
        dataRoomId: any;
        permissions: {
          __typename?: "PermissionsConnection";
          nodes: Array<{
            __typename?: "Permission";
            id: string;
            permissionId: any;
            permissionType: PermissionTypeType;
            config: any;
            updatedAt: any;
            updatedByEmail: string;
            createdAt: any;
            createdByEmail: string;
          } | null>;
        };
      } | null>;
    };
    dataRoomDataNodes: {
      __typename?: "ComputeNodesConnection";
      nodes: Array<{
        __typename?: "ComputeNode";
        id: string;
        dataRoomId: any;
        computeNodeId: any;
        computeNodeType: ComputeNodeTypeType;
        nodeName: string;
        config: any;
        updatedAt: any;
        updatedByEmail: string;
        createdAt: any;
        createdByEmail: string;
        index: number;
        isSaving?: boolean | null;
        name: string;
        dataRoom?: {
          __typename?: "DataRoom";
          id: string;
          dataRoomId: any;
          userPermissions: {
            __typename?: "UserPermissionsConnection";
            nodes: Array<{
              __typename?: "UserPermission";
              id: string;
              userPermissionId: any;
              email: string;
              updatedAt: any;
              createdAt: any;
              createdByEmail: string;
              dataRoomId: any;
              permissions: {
                __typename?: "PermissionsConnection";
                nodes: Array<{
                  __typename?: "Permission";
                  id: string;
                  permissionId: any;
                  permissionType: PermissionTypeType;
                  config: any;
                  updatedAt: any;
                  updatedByEmail: string;
                  createdAt: any;
                  createdByEmail: string;
                } | null>;
              };
            } | null>;
          };
        } | null;
        datasetLinks: {
          __typename?: "DatasetLinksConnection";
          totalCount: number;
          nodes: Array<{
            __typename?: "DatasetLink";
            id: string;
            datasetLinkId: any;
            datasetHash: string;
            allowStatisticsSharing: boolean;
            datasetId: string;
            dataset?: {
              __typename?: "Dataset";
              id: string;
              datasetHash: string;
              name: string;
              description?: string | null;
              updatedAt?: any | null;
              createdAt?: any | null;
              ownerEmail?: string | null;
              datasetId: string;
              datasetLinks?: {
                __typename?: "DatasetLinksConnection";
                nodes: Array<{
                  __typename?: "DatasetLink";
                  id: string;
                  allowStatisticsSharing: boolean;
                  computeNode?: {
                    __typename?: "ComputeNode";
                    id: string;
                    computeNodeId: any;
                    nodeName: string;
                    config: any;
                    dataRoom?: {
                      __typename?: "DataRoom";
                      dataRoomId: any;
                      dataRoomHash?: string | null;
                      mrenclave?: string | null;
                      name: string;
                    } | null;
                  } | null;
                } | null>;
              } | null;
            } | null;
            datasetStats?: {
              __typename?: "DatasetStat";
              id: string;
              datasetHash: string;
              fileSize: number;
              fillRatio: number;
              numberOfRows: number;
              numberOfDuplicateRows: number;
              numberOfNotNullableRows: number;
              statistics?: any | null;
              statisticsVersion: number;
              updatedAt: any;
              createdAt: any;
              ownerEmail: string;
              datasetId: string;
            } | null;
            datasetMeta?: {
              __typename?: "DatasetMeta";
              id: string;
              datasetHash: string;
              name?: string | null;
              description?: string | null;
              updatedAt: any;
              createdAt: any;
              ownerEmail: string;
              datasetId: string;
            } | null;
            computeNode?: {
              __typename?: "ComputeNode";
              id: string;
              computeNodeId: any;
              computeNodeType: ComputeNodeTypeType;
              nodeName: string;
              config: any;
              dataRoom?: {
                __typename?: "DataRoom";
                id: string;
                dataRoomId: any;
                dataRoomHash?: string | null;
                mrenclave?: string | null;
                name: string;
              } | null;
            } | null;
          } | null>;
        };
      } | null>;
    };
    dataRoomComputeNodes: {
      __typename?: "ComputeNodesConnection";
      nodes: Array<{
        __typename?: "ComputeNode";
        id: string;
        dataRoomId: any;
        computeNodeId: any;
        computeNodeType: ComputeNodeTypeType;
        nodeName: string;
        config: any;
        updatedAt: any;
        updatedByEmail: string;
        createdAt: any;
        createdByEmail: string;
        index: number;
        isSaving?: boolean | null;
        name: string;
        dataRoom?: {
          __typename?: "DataRoom";
          id: string;
          dataRoomId: any;
          userPermissions: {
            __typename?: "UserPermissionsConnection";
            nodes: Array<{
              __typename?: "UserPermission";
              id: string;
              userPermissionId: any;
              email: string;
              updatedAt: any;
              createdAt: any;
              createdByEmail: string;
              dataRoomId: any;
              permissions: {
                __typename?: "PermissionsConnection";
                nodes: Array<{
                  __typename?: "Permission";
                  id: string;
                  permissionId: any;
                  permissionType: PermissionTypeType;
                  config: any;
                  updatedAt: any;
                  updatedByEmail: string;
                  createdAt: any;
                  createdByEmail: string;
                } | null>;
              };
            } | null>;
          };
        } | null;
        jobs: {
          __typename?: "JobsConnection";
          nodes: Array<{
            __typename?: "Job";
            id: string;
            computeNodeId: any;
            jobId?: string | null;
            jobStartedAt?: any | null;
            ownerEmail: string;
          } | null>;
        };
      } | null>;
    };
    state?: {
      __typename?: "State";
      id: string;
      updatedAt: any;
      status?: StatusType | null;
      statusUpdatedAt?: any | null;
      isStopped?: boolean | null;
    } | null;
    config?: {
      __typename?: "Config";
      id: string;
      dataRoomId: any;
      showOrganizationLogo: boolean;
      dataNodesOrder?: Array<string | null> | null;
      computeNodesOrder?: Array<string | null> | null;
    } | null;
    userConfig?: {
      __typename?: "UserConfig";
      id: string;
      userConfigId: any;
      isArchived: boolean;
      isFavorite: boolean;
    } | null;
    organizationLogo?: {
      __typename?: "OrganizationLogo";
      id: string;
      logo: string;
    } | null;
    lock?: {
      __typename?: "Lock";
      id: string;
      isLocked?: boolean | null;
    } | null;
  } | null;
};

export type DataRoomConfigurationsQueryVariables = Exact<{
  dataRoomId: Scalars["UUID"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ConfigurationCondition>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy> | ConfigurationsOrderBy>;
}>;

export type DataRoomConfigurationsQuery = {
  __typename?: "Query";
  configurations?: {
    __typename?: "ConfigurationsConnection";
    nodes: Array<{
      __typename?: "Configuration";
      id: string;
      dataRoomId: any;
      configurationId: any;
      commitId?: string | null;
      isDev: boolean;
      ownerEmail: string;
      updatedAt: any;
      updatedByEmail: string;
      createdAt: any;
      createdByEmail: string;
      request?: {
        __typename?: "Request";
        id: string;
        requestId: any;
        isSigned?: boolean | null;
        signees: {
          __typename?: "SigneesConnection";
          nodes: Array<{
            __typename?: "Signee";
            id: string;
            signeeId: any;
            signeeEmail: string;
            datasets: any;
            signature?: {
              __typename?: "Signature";
              id: string;
              signatureHash: string;
              createdAt: any;
            } | null;
          } | null>;
        };
      } | null;
      modifications: {
        __typename?: "ModificationsConnection";
        nodes: Array<{
          __typename?: "Modification";
          id: string;
          configurationId: any;
          modificationId: any;
          modification: ModificationType;
          config?: any | null;
          elementId: any;
          elementType: ElementTypeType;
          computeNodeAsElement?: {
            __typename?: "ComputeNodeAsElement";
            id: string;
            computeNodeId: any;
            computeNodeType: ComputeNodeTypeType;
            nodeName: string;
            config: any;
            updatedAt: any;
            updatedByEmail: string;
            createdAt: any;
            createdByEmail: string;
          } | null;
          userPermissionAsElement?: {
            __typename?: "UserPermissionAsElement";
            id: string;
            email: string;
            userPermissionId: any;
            updatedAt: any;
            updatedByEmail: string;
            permissionAsElementsByUserPermissionId: {
              __typename?: "PermissionAsElementsConnection";
              nodes: Array<{
                __typename?: "PermissionAsElement";
                id: string;
                permissionType: PermissionTypeType;
                config: any;
                permissionId: any;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null>;
  } | null;
};

export type CreateConfigurationMutationVariables = Exact<{
  input: CreateConfigurationInput;
  requestsOrderBy?: InputMaybe<Array<RequestsOrderBy> | RequestsOrderBy>;
  configurationsOrderBy?: InputMaybe<
    Array<ConfigurationsOrderBy> | ConfigurationsOrderBy
  >;
  dataRoomId: Scalars["UUID"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
}>;

export type CreateConfigurationMutation = {
  __typename?: "Mutation";
  createConfiguration?: {
    __typename?: "CreateConfigurationPayload";
    clientMutationId?: string | null;
    configuration?: {
      __typename?: "Configuration";
      id: string;
      dataRoomId: any;
      configurationId: any;
      commitId?: string | null;
      isDev: boolean;
      ownerEmail: string;
      updatedAt: any;
      updatedByEmail: string;
      createdAt: any;
      createdByEmail: string;
      request?: {
        __typename?: "Request";
        id: string;
        requestId: any;
        isSigned?: boolean | null;
        signees: {
          __typename?: "SigneesConnection";
          nodes: Array<{
            __typename?: "Signee";
            id: string;
            signeeId: any;
            signeeEmail: string;
            datasets: any;
            signature?: {
              __typename?: "Signature";
              id: string;
              signatureHash: string;
              createdAt: any;
            } | null;
          } | null>;
        };
      } | null;
      modifications: {
        __typename?: "ModificationsConnection";
        nodes: Array<{
          __typename?: "Modification";
          id: string;
          configurationId: any;
          modificationId: any;
          modification: ModificationType;
          config?: any | null;
          elementId: any;
          elementType: ElementTypeType;
          computeNodeAsElement?: {
            __typename?: "ComputeNodeAsElement";
            id: string;
            computeNodeId: any;
            computeNodeType: ComputeNodeTypeType;
            nodeName: string;
            config: any;
            updatedAt: any;
            updatedByEmail: string;
            createdAt: any;
            createdByEmail: string;
          } | null;
          userPermissionAsElement?: {
            __typename?: "UserPermissionAsElement";
            id: string;
            email: string;
            userPermissionId: any;
            updatedAt: any;
            updatedByEmail: string;
            permissionAsElementsByUserPermissionId: {
              __typename?: "PermissionAsElementsConnection";
              nodes: Array<{
                __typename?: "PermissionAsElement";
                id: string;
                permissionType: PermissionTypeType;
                config: any;
                permissionId: any;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null;
    query?: {
      __typename?: "Query";
      requests?: {
        __typename?: "RequestsConnection";
        nodes: Array<{
          __typename?: "Request";
          id: string;
          isSigned?: boolean | null;
          createdAt: any;
          createdByEmail: string;
          message: string;
          requestId: any;
          updatedAt: any;
          updatedByEmail: string;
          signees: {
            __typename?: "SigneesConnection";
            nodes: Array<{
              __typename?: "Signee";
              id: string;
              createdAt: any;
              createdByEmail: string;
              requestId: any;
              signeeEmail: string;
              signeeId: any;
              datasets: any;
              updatedAt: any;
              updatedByEmail: string;
              signature?: {
                __typename?: "Signature";
                createdAt: any;
                createdByEmail: string;
                id: string;
                signatureHash: string;
                signeeId: any;
                updatedAt: any;
                updatedByEmail: string;
              } | null;
            } | null>;
          };
          configuration?: {
            __typename?: "Configuration";
            configurationId: any;
            commitId?: string | null;
            createdAt: any;
            createdByEmail: string;
            dataRoomId: any;
            id: string;
            ownerEmail: string;
            updatedAt: any;
            updatedByEmail: string;
            modifications: {
              __typename?: "ModificationsConnection";
              nodes: Array<{
                __typename?: "Modification";
                id: string;
                config?: any | null;
                configurationId: any;
                createdAt: any;
                createdByEmail: string;
                elementId: any;
                elementType: ElementTypeType;
                modification: ModificationType;
                modificationId: any;
                updatedAt: any;
                updatedByEmail: string;
                computeNodeAsElement?: {
                  __typename?: "ComputeNodeAsElement";
                  id: string;
                  computeNodeId: any;
                  computeNodeType: ComputeNodeTypeType;
                  config: any;
                  createdAt: any;
                  createdByEmail: string;
                  nodeName: string;
                  updatedAt: any;
                  updatedByEmail: string;
                } | null;
                userPermissionAsElement?: {
                  __typename?: "UserPermissionAsElement";
                  id: string;
                  email: string;
                  userPermissionId: any;
                  updatedAt: any;
                  updatedByEmail: string;
                  permissionAsElementsByUserPermissionId: {
                    __typename?: "PermissionAsElementsConnection";
                    nodes: Array<{
                      __typename?: "PermissionAsElement";
                      id: string;
                      permissionType: PermissionTypeType;
                      config: any;
                      permissionId: any;
                    } | null>;
                  };
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      } | null;
      configurations?: {
        __typename?: "ConfigurationsConnection";
        nodes: Array<{
          __typename?: "Configuration";
          id: string;
          dataRoomId: any;
          configurationId: any;
          commitId?: string | null;
          isDev: boolean;
          ownerEmail: string;
          updatedAt: any;
          updatedByEmail: string;
          createdAt: any;
          createdByEmail: string;
          request?: {
            __typename?: "Request";
            id: string;
            requestId: any;
            isSigned?: boolean | null;
            signees: {
              __typename?: "SigneesConnection";
              nodes: Array<{
                __typename?: "Signee";
                id: string;
                signeeId: any;
                signeeEmail: string;
                datasets: any;
                signature?: {
                  __typename?: "Signature";
                  id: string;
                  signatureHash: string;
                  createdAt: any;
                } | null;
              } | null>;
            };
          } | null;
          modifications: {
            __typename?: "ModificationsConnection";
            nodes: Array<{
              __typename?: "Modification";
              id: string;
              configurationId: any;
              modificationId: any;
              modification: ModificationType;
              config?: any | null;
              elementId: any;
              elementType: ElementTypeType;
              computeNodeAsElement?: {
                __typename?: "ComputeNodeAsElement";
                id: string;
                computeNodeId: any;
                computeNodeType: ComputeNodeTypeType;
                nodeName: string;
                config: any;
                updatedAt: any;
                updatedByEmail: string;
                createdAt: any;
                createdByEmail: string;
              } | null;
              userPermissionAsElement?: {
                __typename?: "UserPermissionAsElement";
                id: string;
                email: string;
                userPermissionId: any;
                updatedAt: any;
                updatedByEmail: string;
                permissionAsElementsByUserPermissionId: {
                  __typename?: "PermissionAsElementsConnection";
                  nodes: Array<{
                    __typename?: "PermissionAsElement";
                    id: string;
                    permissionType: PermissionTypeType;
                    config: any;
                    permissionId: any;
                  } | null>;
                };
              } | null;
            } | null>;
          };
        } | null>;
      } | null;
    } | null;
  } | null;
};

export type CreateModificationMutationVariables = Exact<{
  input: CreateModificationInput;
}>;

export type CreateModificationMutation = {
  __typename?: "Mutation";
  createModification?: {
    __typename?: "CreateModificationPayload";
    clientMutationId?: string | null;
  } | null;
};

export type CreateRequestMutationVariables = Exact<{
  input: CreateRequestInput;
}>;

export type CreateRequestMutation = {
  __typename?: "Mutation";
  createRequest?: {
    __typename?: "CreateRequestPayload";
    clientMutationId?: string | null;
    request?: {
      __typename?: "Request";
      id: string;
      signees: {
        __typename?: "SigneesConnection";
        nodes: Array<{
          __typename?: "Signee";
          id: string;
          signeeId: any;
          signeeEmail: string;
        } | null>;
      };
    } | null;
  } | null;
};

export type DeleteConfigurationAndElementsMutationVariables = Exact<{
  input: DeleteConfigurationAndElementsInput;
}>;

export type DeleteConfigurationAndElementsMutation = {
  __typename?: "Mutation";
  deleteConfigurationAndElements?: {
    __typename?: "DeleteConfigurationAndElementsPayload";
    clientMutationId?: string | null;
  } | null;
};

export type DeleteModificationAndElementsMutationVariables = Exact<{
  input: DeleteModificationAndElementsInput;
}>;

export type DeleteModificationAndElementsMutation = {
  __typename?: "Mutation";
  deleteModificationAndElements?: {
    __typename?: "DeleteModificationAndElementsPayload";
    clientMutationId?: string | null;
  } | null;
};

export type DataRoomDevConfigurationsQueryVariables = Exact<{
  dataRoomId: Scalars["UUID"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
  after?: InputMaybe<Scalars["Cursor"]>;
  before?: InputMaybe<Scalars["Cursor"]>;
  condition?: InputMaybe<ConfigurationCondition>;
  first?: InputMaybe<Scalars["Int"]>;
  last?: InputMaybe<Scalars["Int"]>;
  offset?: InputMaybe<Scalars["Int"]>;
  orderBy?: InputMaybe<Array<ConfigurationsOrderBy> | ConfigurationsOrderBy>;
}>;

export type DataRoomDevConfigurationsQuery = {
  __typename?: "Query";
  configurations?: {
    __typename?: "ConfigurationsConnection";
    nodes: Array<{
      __typename?: "Configuration";
      id: string;
      dataRoomId: any;
      configurationId: any;
      commitId?: string | null;
      isDev: boolean;
      ownerEmail: string;
      updatedAt: any;
      updatedByEmail: string;
      createdAt: any;
      createdByEmail: string;
      request?: {
        __typename?: "Request";
        id: string;
        requestId: any;
        isSigned?: boolean | null;
        signees: {
          __typename?: "SigneesConnection";
          nodes: Array<{
            __typename?: "Signee";
            id: string;
            signeeId: any;
            signeeEmail: string;
            datasets: any;
            signature?: {
              __typename?: "Signature";
              id: string;
              signatureHash: string;
              createdAt: any;
            } | null;
          } | null>;
        };
      } | null;
      modifications: {
        __typename?: "ModificationsConnection";
        nodes: Array<{
          __typename?: "Modification";
          id: string;
          configurationId: any;
          modificationId: any;
          modification: ModificationType;
          config?: any | null;
          elementId: any;
          elementType: ElementTypeType;
          computeNodeAsElement?: {
            __typename?: "ComputeNodeAsElement";
            id: string;
            computeNodeId: any;
            computeNodeType: ComputeNodeTypeType;
            nodeName: string;
            config: any;
            updatedAt: any;
            updatedByEmail: string;
            createdAt: any;
            createdByEmail: string;
          } | null;
          userPermissionAsElement?: {
            __typename?: "UserPermissionAsElement";
            id: string;
            email: string;
            userPermissionId: any;
            updatedAt: any;
            updatedByEmail: string;
            permissionAsElementsByUserPermissionId: {
              __typename?: "PermissionAsElementsConnection";
              nodes: Array<{
                __typename?: "PermissionAsElement";
                id: string;
                permissionType: PermissionTypeType;
                config: any;
                permissionId: any;
              } | null>;
            };
          } | null;
        } | null>;
      };
    } | null>;
  } | null;
};

export type MergeConfigurationMutationVariables = Exact<{
  input: MergeConfigurationInput;
  requestsOrderBy?: InputMaybe<Array<RequestsOrderBy> | RequestsOrderBy>;
  configurationsOrderBy?: InputMaybe<
    Array<ConfigurationsOrderBy> | ConfigurationsOrderBy
  >;
  dataRoomId: Scalars["UUID"];
  ownerEmail?: InputMaybe<Scalars["String"]>;
  dataRoomHash: Scalars["String"];
}>;

export type MergeConfigurationMutation = {
  __typename?: "Mutation";
  mergeConfiguration?: {
    __typename?: "MergeConfigurationPayload";
    clientMutationId?: string | null;
    query?: {
      __typename?: "Query";
      requests?: {
        __typename?: "RequestsConnection";
        totalCount: number;
        nodes: Array<{
          __typename?: "Request";
          id: string;
          isSigned?: boolean | null;
          createdAt: any;
          createdByEmail: string;
          message: string;
          requestId: any;
          updatedAt: any;
          updatedByEmail: string;
          signees: {
            __typename?: "SigneesConnection";
            nodes: Array<{
              __typename?: "Signee";
              id: string;
              createdAt: any;
              createdByEmail: string;
              requestId: any;
              signeeEmail: string;
              signeeId: any;
              datasets: any;
              updatedAt: any;
              updatedByEmail: string;
              signature?: {
                __typename?: "Signature";
                createdAt: any;
                createdByEmail: string;
                id: string;
                signatureHash: string;
                signeeId: any;
                updatedAt: any;
                updatedByEmail: string;
              } | null;
            } | null>;
          };
          configuration?: {
            __typename?: "Configuration";
            configurationId: any;
            commitId?: string | null;
            createdAt: any;
            createdByEmail: string;
            dataRoomId: any;
            id: string;
            ownerEmail: string;
            updatedAt: any;
            updatedByEmail: string;
            modifications: {
              __typename?: "ModificationsConnection";
              nodes: Array<{
                __typename?: "Modification";
                id: string;
                config?: any | null;
                configurationId: any;
                createdAt: any;
                createdByEmail: string;
                elementId: any;
                elementType: ElementTypeType;
                modification: ModificationType;
                modificationId: any;
                updatedAt: any;
                updatedByEmail: string;
                computeNodeAsElement?: {
                  __typename?: "ComputeNodeAsElement";
                  id: string;
                  computeNodeId: any;
                  computeNodeType: ComputeNodeTypeType;
                  config: any;
                  createdAt: any;
                  createdByEmail: string;
                  nodeName: string;
                  updatedAt: any;
                  updatedByEmail: string;
                } | null;
                userPermissionAsElement?: {
                  __typename?: "UserPermissionAsElement";
                  id: string;
                  email: string;
                  userPermissionId: any;
                  updatedAt: any;
                  updatedByEmail: string;
                  permissionAsElementsByUserPermissionId: {
                    __typename?: "PermissionAsElementsConnection";
                    nodes: Array<{
                      __typename?: "PermissionAsElement";
                      id: string;
                      permissionType: PermissionTypeType;
                      config: any;
                      permissionId: any;
                    } | null>;
                  };
                } | null;
              } | null>;
            };
          } | null;
        } | null>;
      } | null;
      configurations?: {
        __typename?: "ConfigurationsConnection";
        nodes: Array<{
          __typename?: "Configuration";
          id: string;
          dataRoomId: any;
          configurationId: any;
          commitId?: string | null;
          isDev: boolean;
          ownerEmail: string;
          updatedAt: any;
          updatedByEmail: string;
          createdAt: any;
          createdByEmail: string;
          request?: {
            __typename?: "Request";
            id: string;
            requestId: any;
            isSigned?: boolean | null;
            signees: {
              __typename?: "SigneesConnection";
              nodes: Array<{
                __typename?: "Signee";
                id: string;
                signeeId: any;
                signeeEmail: string;
                datasets: any;
                signature?: {
                  __typename?: "Signature";
                  id: string;
                  signatureHash: string;
                  createdAt: any;
                } | null;
              } | null>;
            };
          } | null;
          modifications: {
            __typename?: "ModificationsConnection";
            nodes: Array<{
              __typename?: "Modification";
              id: string;
              configurationId: any;
              modificationId: any;
              modification: ModificationType;
              config?: any | null;
              elementId: any;
              elementType: ElementTypeType;
              computeNodeAsElement?: {
                __typename?: "ComputeNodeAsElement";
                id: string;
                computeNodeId: any;
                computeNodeType: ComputeNodeTypeType;
                nodeName: string;
                config: any;
                updatedAt: any;
                updatedByEmail: string;
                createdAt: any;
                createdByEmail: string;
              } | null;
              userPermissionAsElement?: {
                __typename?: "UserPermissionAsElement";
                id: string;
                email: string;
                userPermissionId: any;
                updatedAt: any;
                updatedByEmail: string;
                permissionAsElementsByUserPermissionId: {
                  __typename?: "PermissionAsElementsConnection";
                  nodes: Array<{
                    __typename?: "PermissionAsElement";
                    id: string;
                    permissionType: PermissionTypeType;
                    config: any;
                    permissionId: any;
                  } | null>;
                };
              } | null;
            } | null>;
          };
        } | null>;
      } | null;
      dataRoom?: {
        __typename?: "DataRoom";
        id: string;
        dataRoomId: any;
        name: string;
        description?: string | null;
        isOwner?: boolean | null;
        lastEditedAt?: any | null;
        updatedAt: any;
        updatedByEmail: string;
        createdAt: any;
        createdByEmail: string;
        mrenclave?: string | null;
        ownerEmail: string;
        dataRoomHash?: string | null;
        governanceProtocol: any;
        enableDevelopmentTab: boolean;
        source: SourceType;
        isSaving?: boolean | null;
        isExpertViewEnabled?: boolean | null;
        userPermissions: {
          __typename?: "UserPermissionsConnection";
          nodes: Array<{
            __typename?: "UserPermission";
            id: string;
            userPermissionId: any;
            email: string;
            updatedAt: any;
            createdAt: any;
            createdByEmail: string;
            dataRoomId: any;
            permissions: {
              __typename?: "PermissionsConnection";
              nodes: Array<{
                __typename?: "Permission";
                id: string;
                permissionId: any;
                permissionType: PermissionTypeType;
                config: any;
                updatedAt: any;
                updatedByEmail: string;
                createdAt: any;
                createdByEmail: string;
              } | null>;
            };
          } | null>;
        };
        dataRoomDataNodes: {
          __typename?: "ComputeNodesConnection";
          nodes: Array<{
            __typename?: "ComputeNode";
            id: string;
            dataRoomId: any;
            computeNodeId: any;
            computeNodeType: ComputeNodeTypeType;
            nodeName: string;
            config: any;
            updatedAt: any;
            updatedByEmail: string;
            createdAt: any;
            createdByEmail: string;
            index: number;
            isSaving?: boolean | null;
            name: string;
            dataRoom?: {
              __typename?: "DataRoom";
              id: string;
              dataRoomId: any;
              userPermissions: {
                __typename?: "UserPermissionsConnection";
                nodes: Array<{
                  __typename?: "UserPermission";
                  id: string;
                  userPermissionId: any;
                  email: string;
                  updatedAt: any;
                  createdAt: any;
                  createdByEmail: string;
                  dataRoomId: any;
                  permissions: {
                    __typename?: "PermissionsConnection";
                    nodes: Array<{
                      __typename?: "Permission";
                      id: string;
                      permissionId: any;
                      permissionType: PermissionTypeType;
                      config: any;
                      updatedAt: any;
                      updatedByEmail: string;
                      createdAt: any;
                      createdByEmail: string;
                    } | null>;
                  };
                } | null>;
              };
            } | null;
            datasetLinks: {
              __typename?: "DatasetLinksConnection";
              totalCount: number;
              nodes: Array<{
                __typename?: "DatasetLink";
                id: string;
                datasetLinkId: any;
                datasetHash: string;
                allowStatisticsSharing: boolean;
                datasetId: string;
                dataset?: {
                  __typename?: "Dataset";
                  id: string;
                  datasetHash: string;
                  name: string;
                  description?: string | null;
                  updatedAt?: any | null;
                  createdAt?: any | null;
                  ownerEmail?: string | null;
                  datasetId: string;
                  datasetLinks?: {
                    __typename?: "DatasetLinksConnection";
                    nodes: Array<{
                      __typename?: "DatasetLink";
                      id: string;
                      allowStatisticsSharing: boolean;
                      computeNode?: {
                        __typename?: "ComputeNode";
                        id: string;
                        computeNodeId: any;
                        nodeName: string;
                        config: any;
                        dataRoom?: {
                          __typename?: "DataRoom";
                          dataRoomId: any;
                          dataRoomHash?: string | null;
                          mrenclave?: string | null;
                          name: string;
                        } | null;
                      } | null;
                    } | null>;
                  } | null;
                } | null;
                datasetStats?: {
                  __typename?: "DatasetStat";
                  id: string;
                  datasetHash: string;
                  fileSize: number;
                  fillRatio: number;
                  numberOfRows: number;
                  numberOfDuplicateRows: number;
                  numberOfNotNullableRows: number;
                  statistics?: any | null;
                  statisticsVersion: number;
                  updatedAt: any;
                  createdAt: any;
                  ownerEmail: string;
                  datasetId: string;
                } | null;
                datasetMeta?: {
                  __typename?: "DatasetMeta";
                  id: string;
                  datasetHash: string;
                  name?: string | null;
                  description?: string | null;
                  updatedAt: any;
                  createdAt: any;
                  ownerEmail: string;
                  datasetId: string;
                } | null;
                computeNode?: {
                  __typename?: "ComputeNode";
                  id: string;
                  computeNodeId: any;
                  computeNodeType: ComputeNodeTypeType;
                  nodeName: string;
                  config: any;
                  dataRoom?: {
                    __typename?: "DataRoom";
                    id: string;
                    dataRoomId: any;
                    dataRoomHash?: string | null;
                    mrenclave?: string | null;
                    name: string;
                  } | null;
                } | null;
              } | null>;
            };
          } | null>;
        };
        dataRoomComputeNodes: {
          __typename?: "ComputeNodesConnection";
          nodes: Array<{
            __typename?: "ComputeNode";
            id: string;
            dataRoomId: any;
            computeNodeId: any;
            computeNodeType: ComputeNodeTypeType;
            nodeName: string;
            config: any;
            updatedAt: any;
            updatedByEmail: string;
            createdAt: any;
            createdByEmail: string;
            index: number;
            isSaving?: boolean | null;
            name: string;
            dataRoom?: {
              __typename?: "DataRoom";
              id: string;
              dataRoomId: any;
              userPermissions: {
                __typename?: "UserPermissionsConnection";
                nodes: Array<{
                  __typename?: "UserPermission";
                  id: string;
                  userPermissionId: any;
                  email: string;
                  updatedAt: any;
                  createdAt: any;
                  createdByEmail: string;
                  dataRoomId: any;
                  permissions: {
                    __typename?: "PermissionsConnection";
                    nodes: Array<{
                      __typename?: "Permission";
                      id: string;
                      permissionId: any;
                      permissionType: PermissionTypeType;
                      config: any;
                      updatedAt: any;
                      updatedByEmail: string;
                      createdAt: any;
                      createdByEmail: string;
                    } | null>;
                  };
                } | null>;
              };
            } | null;
            jobs: {
              __typename?: "JobsConnection";
              nodes: Array<{
                __typename?: "Job";
                id: string;
                computeNodeId: any;
                jobId?: string | null;
                jobStartedAt?: any | null;
                ownerEmail: string;
              } | null>;
            };
          } | null>;
        };
        state?: {
          __typename?: "State";
          id: string;
          updatedAt: any;
          status?: StatusType | null;
          statusUpdatedAt?: any | null;
          isStopped?: boolean | null;
        } | null;
        config?: {
          __typename?: "Config";
          id: string;
          dataRoomId: any;
          showOrganizationLogo: boolean;
          dataNodesOrder?: Array<string | null> | null;
          computeNodesOrder?: Array<string | null> | null;
        } | null;
        userConfig?: {
          __typename?: "UserConfig";
          id: string;
          userConfigId: any;
          isArchived: boolean;
          isFavorite: boolean;
        } | null;
        organizationLogo?: {
          __typename?: "OrganizationLogo";
          id: string;
          logo: string;
        } | null;
        lock?: {
          __typename?: "Lock";
          id: string;
          isLocked?: boolean | null;
        } | null;
      } | null;
    } | null;
  } | null;
};

export type UpdateConfigurationMutationVariables = Exact<{
  input: UpdateConfigurationInput;
}>;

export type UpdateConfigurationMutation = {
  __typename?: "Mutation";
  updateConfiguration?: {
    __typename?: "UpdateConfigurationPayload";
    clientMutationId?: string | null;
  } | null;
};

export type UpdateDataRoomComputeNodeMutationVariables = Exact<{
  input: UpdateComputeNodeInput;
}>;

export type UpdateDataRoomComputeNodeMutation = {
  __typename?: "Mutation";
  updateComputeNode?: {
    __typename?: "UpdateComputeNodePayload";
    clientMutationId?: string | null;
    computeNode?: {
      __typename?: "ComputeNode";
      id: string;
      nodeName: string;
      config: any;
    } | null;
  } | null;
};

export type UpsertJobMutationVariables = Exact<{
  input: UpsertJobInput;
}>;

export type UpsertJobMutation = {
  __typename?: "Mutation";
  upsertJob?: {
    __typename?: "UpsertJobPayload";
    clientMutationId?: string | null;
    job?: {
      __typename?: "Job";
      id: string;
      computeNodeId: any;
      jobId?: string | null;
      jobStartedAt?: any | null;
      ownerEmail: string;
    } | null;
  } | null;
};

export type ComputeNodeIsSavingFragment = {
  __typename?: "ComputeNode";
  isSaving?: boolean | null;
};

export type CreateDataRoomFragment = {
  __typename?: "DataRoom";
  id: string;
  name: string;
  description?: string | null;
};

export type CreateDataRoomMutationVariables = Exact<{
  input: CreateDataRoomInput;
}>;

export type CreateDataRoomMutation = {
  __typename?: "Mutation";
  createDataRoom?: {
    __typename?: "CreateDataRoomPayload";
    dataRoom?: {
      __typename?: "DataRoom";
      id: string;
      dataRoomId: any;
      name: string;
      description?: string | null;
      isOwner?: boolean | null;
      lastEditedAt?: any | null;
      updatedAt: any;
      updatedByEmail: string;
      createdAt: any;
      createdByEmail: string;
      mrenclave?: string | null;
      ownerEmail: string;
      dataRoomHash?: string | null;
      governanceProtocol: any;
      enableDevelopmentTab: boolean;
      source: SourceType;
      isSaving?: boolean | null;
      isExpertViewEnabled?: boolean | null;
      userPermissions: {
        __typename?: "UserPermissionsConnection";
        nodes: Array<{
          __typename?: "UserPermission";
          id: string;
          userPermissionId: any;
          email: string;
          updatedAt: any;
          createdAt: any;
          createdByEmail: string;
          dataRoomId: any;
          permissions: {
            __typename?: "PermissionsConnection";
            nodes: Array<{
              __typename?: "Permission";
              id: string;
              permissionId: any;
              permissionType: PermissionTypeType;
              config: any;
              updatedAt: any;
              updatedByEmail: string;
              createdAt: any;
              createdByEmail: string;
            } | null>;
          };
        } | null>;
      };
      dataRoomDataNodes: {
        __typename?: "ComputeNodesConnection";
        nodes: Array<{
          __typename?: "ComputeNode";
          id: string;
          dataRoomId: any;
          computeNodeId: any;
          computeNodeType: ComputeNodeTypeType;
          nodeName: string;
          config: any;
          updatedAt: any;
          updatedByEmail: string;
          createdAt: any;
          createdByEmail: string;
          index: number;
          isSaving?: boolean | null;
          name: string;
          dataRoom?: {
            __typename?: "DataRoom";
            id: string;
            dataRoomId: any;
            userPermissions: {
              __typename?: "UserPermissionsConnection";
              nodes: Array<{
                __typename?: "UserPermission";
                id: string;
                userPermissionId: any;
                email: string;
                updatedAt: any;
                createdAt: any;
                createdByEmail: string;
                dataRoomId: any;
                permissions: {
                  __typename?: "PermissionsConnection";
                  nodes: Array<{
                    __typename?: "Permission";
                    id: string;
                    permissionId: any;
                    permissionType: PermissionTypeType;
                    config: any;
                    updatedAt: any;
                    updatedByEmail: string;
                    createdAt: any;
                    createdByEmail: string;
                  } | null>;
                };
              } | null>;
            };
          } | null;
          datasetLinks: {
            __typename?: "DatasetLinksConnection";
            totalCount: number;
            nodes: Array<{
              __typename?: "DatasetLink";
              id: string;
              datasetLinkId: any;
              datasetHash: string;
              allowStatisticsSharing: boolean;
              datasetId: string;
              dataset?: {
                __typename?: "Dataset";
                id: string;
                datasetHash: string;
                name: string;
                description?: string | null;
                updatedAt?: any | null;
                createdAt?: any | null;
                ownerEmail?: string | null;
                datasetId: string;
                datasetLinks?: {
                  __typename?: "DatasetLinksConnection";
                  nodes: Array<{
                    __typename?: "DatasetLink";
                    id: string;
                    allowStatisticsSharing: boolean;
                    computeNode?: {
                      __typename?: "ComputeNode";
                      id: string;
                      computeNodeId: any;
                      nodeName: string;
                      config: any;
                      dataRoom?: {
                        __typename?: "DataRoom";
                        dataRoomId: any;
                        dataRoomHash?: string | null;
                        mrenclave?: string | null;
                        name: string;
                      } | null;
                    } | null;
                  } | null>;
                } | null;
              } | null;
              datasetStats?: {
                __typename?: "DatasetStat";
                id: string;
                datasetHash: string;
                fileSize: number;
                fillRatio: number;
                numberOfRows: number;
                numberOfDuplicateRows: number;
                numberOfNotNullableRows: number;
                statistics?: any | null;
                statisticsVersion: number;
                updatedAt: any;
                createdAt: any;
                ownerEmail: string;
                datasetId: string;
              } | null;
              datasetMeta?: {
                __typename?: "DatasetMeta";
                id: string;
                datasetHash: string;
                name?: string | null;
                description?: string | null;
                updatedAt: any;
                createdAt: any;
                ownerEmail: string;
                datasetId: string;
              } | null;
              computeNode?: {
                __typename?: "ComputeNode";
                id: string;
                computeNodeId: any;
                computeNodeType: ComputeNodeTypeType;
                nodeName: string;
                config: any;
                dataRoom?: {
                  __typename?: "DataRoom";
                  id: string;
                  dataRoomId: any;
                  dataRoomHash?: string | null;
                  mrenclave?: string | null;
                  name: string;
                } | null;
              } | null;
            } | null>;
          };
        } | null>;
      };
      dataRoomComputeNodes: {
        __typename?: "ComputeNodesConnection";
        nodes: Array<{
          __typename?: "ComputeNode";
          id: string;
          dataRoomId: any;
          computeNodeId: any;
          computeNodeType: ComputeNodeTypeType;
          nodeName: string;
          config: any;
          updatedAt: any;
          updatedByEmail: string;
          createdAt: any;
          createdByEmail: string;
          index: number;
          isSaving?: boolean | null;
          name: string;
          dataRoom?: {
            __typename?: "DataRoom";
            id: string;
            dataRoomId: any;
            userPermissions: {
              __typename?: "UserPermissionsConnection";
              nodes: Array<{
                __typename?: "UserPermission";
                id: string;
                userPermissionId: any;
                email: string;
                updatedAt: any;
                createdAt: any;
                createdByEmail: string;
                dataRoomId: any;
                permissions: {
                  __typename?: "PermissionsConnection";
                  nodes: Array<{
                    __typename?: "Permission";
                    id: string;
                    permissionId: any;
                    permissionType: PermissionTypeType;
                    config: any;
                    updatedAt: any;
                    updatedByEmail: string;
                    createdAt: any;
                    createdByEmail: string;
                  } | null>;
                };
              } | null>;
            };
          } | null;
          jobs: {
            __typename?: "JobsConnection";
            nodes: Array<{
              __typename?: "Job";
              id: string;
              computeNodeId: any;
              jobId?: string | null;
              jobStartedAt?: any | null;
              ownerEmail: string;
            } | null>;
          };
        } | null>;
      };
      state?: {
        __typename?: "State";
        id: string;
        updatedAt: any;
        status?: StatusType | null;
        statusUpdatedAt?: any | null;
        isStopped?: boolean | null;
      } | null;
      config?: {
        __typename?: "Config";
        id: string;
        dataRoomId: any;
        showOrganizationLogo: boolean;
        dataNodesOrder?: Array<string | null> | null;
        computeNodesOrder?: Array<string | null> | null;
      } | null;
      userConfig?: {
        __typename?: "UserConfig";
        id: string;
        userConfigId: any;
        isArchived: boolean;
        isFavorite: boolean;
      } | null;
      organizationLogo?: {
        __typename?: "OrganizationLogo";
        id: string;
        logo: string;
      } | null;
      lock?: {
        __typename?: "Lock";
        id: string;
        isLocked?: boolean | null;
      } | null;
    } | null;
  } | null;
};

export type DataRoomDataNodeIsSavingFragment = {
  __typename?: "ComputeNode";
  isSaving?: boolean | null;
};

export type DataRoomIsSavingFragment = {
  __typename?: "DataRoom";
  isSaving?: boolean | null;
};

export type EventsQueryVariables = Exact<{ [key: string]: never }>;

export type EventsQuery = {
  __typename?: "Query";
  events?: {
    __typename?: "EventsPayload";
    nodes: Array<{
      __typename?: "Event";
      timestamp: any;
      eventLevel: number;
      eventType: string;
      eventMessage?: string | null;
    } | null>;
  } | null;
};

export type EventsSubscriptionSubscriptionVariables = Exact<{
  [key: string]: never;
}>;

export type EventsSubscriptionSubscription = {
  __typename?: "Subscription";
  events?: {
    __typename?: "EventsSubscriptionPayload";
    nodes: Array<{
      __typename?: "Event";
      timestamp: any;
      eventLevel: number;
      eventType: string;
      eventMessage?: string | null;
    } | null>;
  } | null;
};

export const NavigationDrawerDataRoomFragment = {
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "NavigationDrawerDataRoom" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "dataRoomId" } },
          { kind: "Field", name: { kind: "Name", value: "dataRoomHash" } },
          { kind: "Field", name: { kind: "Name", value: "mrenclave" } },
          {
            directives: [
              { kind: "Directive", name: { kind: "Name", value: "client" } },
            ],
            kind: "Field",
            name: { kind: "Name", value: "isOwner" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "config" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "showOrganizationLogo" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "organizationLogo" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "logo" } },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "userConfig" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userConfigId" },
                },
                { kind: "Field", name: { kind: "Name", value: "isArchived" } },
                { kind: "Field", name: { kind: "Name", value: "isFavorite" } },
              ],
            },
          },
          { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
          { kind: "Field", name: { kind: "Name", value: "updatedByEmail" } },
          { kind: "Field", name: { kind: "Name", value: "createdAt" } },
          { kind: "Field", name: { kind: "Name", value: "createdByEmail" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "governanceProtocol" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "enableDevelopmentTab" },
          },
          { kind: "Field", name: { kind: "Name", value: "ownerEmail" } },
          { kind: "Field", name: { kind: "Name", value: "source" } },
          {
            directives: [
              { kind: "Directive", name: { kind: "Name", value: "client" } },
            ],
            kind: "Field",
            name: { kind: "Name", value: "isExpertViewEnabled" },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "state" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "client" },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "isStopped" },
                },
              ],
            },
          },
          {
            kind: "Field",
            name: { kind: "Name", value: "lock" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "isLocked" } },
              ],
            },
          },
        ],
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "DataRoom" },
      },
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<NavigationDrawerDataRoomFragment, unknown>;
export const DataRoomIsExpertViewEnabledFragment = {
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DataRoomIsExpertViewEnabled" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            directives: [
              { kind: "Directive", name: { kind: "Name", value: "client" } },
            ],
            kind: "Field",
            name: { kind: "Name", value: "isExpertViewEnabled" },
          },
        ],
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "DataRoom" },
      },
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<DataRoomIsExpertViewEnabledFragment, unknown>;
export const OrganizationDomainIsSavingFragment = {
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "OrganizationDomainIsSaving" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            directives: [
              { kind: "Directive", name: { kind: "Name", value: "client" } },
            ],
            kind: "Field",
            name: { kind: "Name", value: "isSaving" },
          },
        ],
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Organization" },
      },
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<OrganizationDomainIsSavingFragment, unknown>;
export const ComputeNodeIsSavingFragment = {
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "ComputeNodeIsSaving" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            directives: [
              { kind: "Directive", name: { kind: "Name", value: "client" } },
            ],
            kind: "Field",
            name: { kind: "Name", value: "isSaving" },
          },
        ],
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ComputeNode" },
      },
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<ComputeNodeIsSavingFragment, unknown>;
export const CreateDataRoomFragment = {
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "CreateDataRoom" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "name" } },
          { kind: "Field", name: { kind: "Name", value: "description" } },
        ],
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "DataRoom" },
      },
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<CreateDataRoomFragment, unknown>;
export const DataRoomDataNodeIsSavingFragment = {
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DataRoomDataNodeIsSaving" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            directives: [
              { kind: "Directive", name: { kind: "Name", value: "client" } },
            ],
            kind: "Field",
            name: { kind: "Name", value: "isSaving" },
          },
        ],
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "ComputeNode" },
      },
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<DataRoomDataNodeIsSavingFragment, unknown>;
export const DataRoomIsSavingFragment = {
  definitions: [
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "DataRoomIsSaving" },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            directives: [
              { kind: "Directive", name: { kind: "Name", value: "client" } },
            ],
            kind: "Field",
            name: { kind: "Name", value: "isSaving" },
          },
        ],
      },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "DataRoom" },
      },
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<DataRoomIsSavingFragment, unknown>;
export const BranchLeavesOrganizationsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "BranchLeavesOrganizations" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "condition" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "organizations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationLogo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "logo" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "domains" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "domainId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "domain" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "OrganizationCondition" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "condition" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "OrganizationFilter" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "OrganizationsOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  BranchLeavesOrganizationsQuery,
  BranchLeavesOrganizationsQueryVariables
>;
export const BranchLeavesUsersDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "BranchLeavesUsers" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "condition" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "users" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userAttribute" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isDemoUser" },
                            },
                          ],
                        },
                      },
                      {
                        alias: { kind: "Name", value: "organization" },
                        kind: "Field",
                        name: { kind: "Name", value: "organizationByDomain" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "organizationId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "UserCondition" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "condition" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "UserFilter" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "UsersOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  BranchLeavesUsersQuery,
  BranchLeavesUsersQueryVariables
>;
export const DatasetsTotalCountDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DatasetsTotalCount" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "datasetLinksExist" },
                      value: { kind: "BooleanValue", value: true },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "ownerEmail" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "ownerEmail" },
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "ObjectValue",
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "datasets" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ownerEmail" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DatasetsTotalCountQuery,
  DatasetsTotalCountQueryVariables
>;
export const LeavesOrganizationsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "LeavesOrganizations" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "condition" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "organizations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationLogo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "logo" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "domains" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "domainId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "domain" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "OrganizationCondition" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "condition" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "OrganizationFilter" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "OrganizationsOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  LeavesOrganizationsQuery,
  LeavesOrganizationsQueryVariables
>;
export const LeavesUsersDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "LeavesUsers" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "condition" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "users" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      { kind: "Field", name: { kind: "Name", value: "email" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userAttribute" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isDemoUser" },
                            },
                          ],
                        },
                      },
                      {
                        alias: { kind: "Name", value: "organization" },
                        kind: "Field",
                        name: { kind: "Name", value: "organizationByDomain" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "organizationId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "UserCondition" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "condition" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "UserFilter" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "UsersOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<LeavesUsersQuery, LeavesUsersQueryVariables>;
export const ArchivedDataRoomsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "ArchivedDataRooms" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            alias: { kind: "Name", value: "dataRooms" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "after" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "before" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "before" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "last" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "last" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "ListValue",
                  values: [
                    {
                      kind: "EnumValue",
                      value: "USER_CONFIGS_MAX_UPDATED_AT_DESC",
                    },
                  ],
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "archivedDataRooms" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "NavigationDrawerDataRoom",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "before" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: { kind: "Variable", name: { kind: "Name", value: "last" } },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
        },
      ],
    },
    ...NavigationDrawerDataRoomFragment.definitions,
  ],
  kind: "Document",
} as unknown as DocumentNode<
  ArchivedDataRoomsQuery,
  ArchivedDataRoomsQueryVariables
>;
export const ArchivedDataRoomsTotalCountDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "ArchivedDataRoomsTotalCount" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            alias: { kind: "Name", value: "dataRooms" },
            kind: "Field",
            name: { kind: "Name", value: "archivedDataRooms" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
        ],
      },
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  ArchivedDataRoomsTotalCountQuery,
  ArchivedDataRoomsTotalCountQueryVariables
>;
export const ChangePasswordDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "ChangePassword" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "changePassword" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  ChangePasswordMutation,
  ChangePasswordMutationVariables
>;
export const DeleteAccountDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DeleteAccount" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deleteAccount" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
              ],
            },
          },
        ],
      },
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DeleteAccountMutation,
  DeleteAccountMutationVariables
>;
export const DomainByDomainDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DomainByDomain" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "domain" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "domain" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "domainByDomain" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationLogo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "logo" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "domain" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<DomainByDomainQuery, DomainByDomainQueryVariables>;
export const FavoriteDataRoomsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "FavoriteDataRooms" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            alias: { kind: "Name", value: "dataRooms" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "ListValue",
                  values: [{ kind: "EnumValue", value: "NAME_ASC" }],
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "favoriteDataRooms" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "NavigationDrawerDataRoom",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
    ...NavigationDrawerDataRoomFragment.definitions,
  ],
  kind: "Document",
} as unknown as DocumentNode<
  FavoriteDataRoomsQuery,
  FavoriteDataRoomsQueryVariables
>;
export const FavoriteDataRoomsTotalCountDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "FavoriteDataRoomsTotalCount" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            alias: { kind: "Name", value: "dataRooms" },
            kind: "Field",
            name: { kind: "Name", value: "favoriteDataRooms" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
        ],
      },
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  FavoriteDataRoomsTotalCountQuery,
  FavoriteDataRoomsTotalCountQueryVariables
>;
export const MockedPublishedDataRoomDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "MockedPublishedDataRoom" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            directives: [
              { kind: "Directive", name: { kind: "Name", value: "client" } },
            ],
            kind: "Field",
            name: { kind: "Name", value: "publishedDataRoom" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "attestationHash" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "computeJobs" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "computeNode" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "enclaveJobId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "user" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "computeNodesOrder" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "creationDate" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dataNodesOrder" },
                },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "enableDevelopment" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "enableInteractivity" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "enclaveConfigurationPin" },
                },
                { kind: "Field", name: { kind: "Name", value: "enclaveId" } },
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "isArchived" } },
                { kind: "Field", name: { kind: "Name", value: "isStopped" } },
                { kind: "Field", name: { kind: "Name", value: "logo" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "InlineFragment",
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isRequired" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "columns" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataType" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isNullable" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "columnsOrder" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataset" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "manifestHash",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "manifest" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "name" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "owner" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "uploadDate" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "size" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "statistics" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "chunks" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "publications",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoom",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "leaf" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                        typeCondition: {
                          kind: "NamedType",
                          name: {
                            kind: "Name",
                            value: "PublishedTableLeafNode",
                          },
                        },
                      },
                      {
                        kind: "InlineFragment",
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isRequired" },
                            },
                          ],
                        },
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "PublishedRawLeafNode" },
                        },
                      },
                      {
                        kind: "InlineFragment",
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "privacyFilter" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "minimumRow" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "statement" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dependencies" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                        typeCondition: {
                          kind: "NamedType",
                          name: { kind: "Name", value: "PublishedSqlNode" },
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "participants" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "publishedDataRooms" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "datasets" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "title" } },
                { kind: "Field", name: { kind: "Name", value: "updateDate" } },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  MockedPublishedDataRoomQuery,
  MockedPublishedDataRoomQueryVariables
>;
export const MyDataRoomsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "MyDataRooms" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            alias: { kind: "Name", value: "dataRooms" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "after" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "before" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "before" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "last" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "last" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "ListValue",
                  values: [{ kind: "EnumValue", value: "CREATED_AT_DESC" }],
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "myDataRooms" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "NavigationDrawerDataRoom",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "before" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: { kind: "Variable", name: { kind: "Name", value: "last" } },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
        },
      ],
    },
    ...NavigationDrawerDataRoomFragment.definitions,
  ],
  kind: "Document",
} as unknown as DocumentNode<MyDataRoomsQuery, MyDataRoomsQueryVariables>;
export const MyDataRoomsTotalCountDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "MyDataRoomsTotalCount" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            alias: { kind: "Name", value: "dataRooms" },
            kind: "Field",
            name: { kind: "Name", value: "myDataRooms" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
        ],
      },
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  MyDataRoomsTotalCountQuery,
  MyDataRoomsTotalCountQueryVariables
>;
export const SharedWithMeDataRoomsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "SharedWithMeDataRooms" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            alias: { kind: "Name", value: "dataRooms" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "after" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "before" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "before" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "last" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "last" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "ListValue",
                  values: [{ kind: "EnumValue", value: "CREATED_AT_DESC" }],
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "sharedWithMeDataRooms" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "FragmentSpread",
                        name: {
                          kind: "Name",
                          value: "NavigationDrawerDataRoom",
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "before" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: { kind: "Variable", name: { kind: "Name", value: "last" } },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
        },
      ],
    },
    ...NavigationDrawerDataRoomFragment.definitions,
  ],
  kind: "Document",
} as unknown as DocumentNode<
  SharedWithMeDataRoomsQuery,
  SharedWithMeDataRoomsQueryVariables
>;
export const SharedWithMeDataRoomsTotalCountDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "SharedWithMeDataRoomsTotalCount" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            alias: { kind: "Name", value: "dataRooms" },
            kind: "Field",
            name: { kind: "Name", value: "sharedWithMeDataRooms" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
        ],
      },
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  SharedWithMeDataRoomsTotalCountQuery,
  SharedWithMeDataRoomsTotalCountQueryVariables
>;
export const DataRoomActionsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DataRoomActions" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "dataRoomById" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "dataRoomId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dataRoomHash" },
                },
                { kind: "Field", name: { kind: "Name", value: "mrenclave" } },
                {
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "client" },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "isOwner" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "config" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "showOrganizationLogo" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationLogo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "logo" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userConfig" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userConfigId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isArchived" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isFavorite" },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedByEmail" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdByEmail" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "governanceProtocol" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "enableDevelopmentTab" },
                },
                { kind: "Field", name: { kind: "Name", value: "ownerEmail" } },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                {
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "client" },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "isExpertViewEnabled" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "state" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isStopped" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lock" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isLocked" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DataRoomActionsQuery,
  DataRoomActionsQueryVariables
>;
export const DataRoomActionsDataRoomDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DataRoomActionsDataRoom" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "dataRoomById" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "dataRoomId" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                { kind: "Field", name: { kind: "Name", value: "mrenclave" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dataRoomHash" },
                },
                { kind: "Field", name: { kind: "Name", value: "mrenclave" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "governanceProtocol" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "enableDevelopmentTab" },
                },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                { kind: "Field", name: { kind: "Name", value: "ownerEmail" } },
                {
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "client" },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "isOwner" },
                },
                {
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "client" },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "isExpertViewEnabled" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "state" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "statusUpdatedAt" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isStopped" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "config" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "showOrganizationLogo" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataNodesOrder" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodesOrder" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userConfig" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userConfigId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isArchived" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isFavorite" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationLogo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "logo" } },
                    ],
                  },
                },
                {
                  alias: { kind: "Name", value: "dataRoomDataNodes" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "config" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "containsJsonpath",
                                  },
                                  value: {
                                    block: false,
                                    kind: "StringValue",
                                    value: "$.config.validation",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "computeNodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodeName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "index" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  alias: { kind: "Name", value: "dataRoomComputeNodes" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "config" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "containsJsonpath",
                                  },
                                  value: {
                                    block: false,
                                    kind: "StringValue",
                                    value: "$.config.computation",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "computeNodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodeName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "index" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: {
                        kind: "ListValue",
                        values: [{ kind: "EnumValue", value: "EMAIL_ASC" }],
                      },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "userPermissions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "permissions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "permissionType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "config",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lock" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isLocked" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DataRoomActionsDataRoomQuery,
  DataRoomActionsDataRoomQueryVariables
>;
export const DataRoomActionsUserConfigDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DataRoomActionsUserConfig" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "dataRoomById" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "dataRoomId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userConfig" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userConfigId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isArchived" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isFavorite" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DataRoomActionsUserConfigQuery,
  DataRoomActionsUserConfigQueryVariables
>;
export const PublishDataRoomDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "PublishDataRoom" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "updateDataRoomById" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dataRoom" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isOwner" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastEditedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mrenclave" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ownerEmail" },
                      },
                      {
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "orderBy" },
                            value: {
                              kind: "ListValue",
                              values: [
                                { kind: "EnumValue", value: "EMAIL_ASC" },
                              ],
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "userPermissions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "userPermissionId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "permissions",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "permissionId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "permissionType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "config",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdByEmail",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        alias: { kind: "Name", value: "dataRoomDataNodes" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "config" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "containsJsonpath",
                                        },
                                        value: {
                                          block: false,
                                          kind: "StringValue",
                                          value: "$.config.validation",
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeType",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodeName" },
                                  },
                                  {
                                    alias: { kind: "Name", value: "name" },
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodeName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "config" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "index" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoom" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                        {
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: {
                                                kind: "Name",
                                                value: "filter",
                                              },
                                              value: {
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "permissions",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value: "some",
                                                          },
                                                          value: {
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: {
                                                                  kind: "Name",
                                                                  value:
                                                                    "permissionType",
                                                                },
                                                                value: {
                                                                  fields: [
                                                                    {
                                                                      kind: "ObjectField",
                                                                      name: {
                                                                        kind: "Name",
                                                                        value:
                                                                          "equalTo",
                                                                      },
                                                                      value: {
                                                                        kind: "EnumValue",
                                                                        value:
                                                                          "LEAF_CRUD_PERMISSION",
                                                                      },
                                                                    },
                                                                  ],
                                                                  kind: "ObjectValue",
                                                                },
                                                              },
                                                            ],
                                                            kind: "ObjectValue",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "ObjectValue",
                                              },
                                            },
                                          ],
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userPermissions",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "userPermissionId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataRoomId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissions",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionId",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionType",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "config",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "updatedAt",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "updatedByEmail",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "createdAt",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "createdByEmail",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetLinks",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalCount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetLinkId",
                                                },
                                              },
                                              {
                                                alias: {
                                                  kind: "Name",
                                                  value: "datasetId",
                                                },
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataset",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      alias: {
                                                        kind: "Name",
                                                        value: "datasetId",
                                                      },
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "description",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "ownerEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetLinks",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "computeNode",
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: "SelectionSet",
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "id",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "computeNodeId",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "nodeName",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "config",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "dataRoom",
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "dataRoomId",
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "dataRoomHash",
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "mrenclave",
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "name",
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "allowStatisticsSharing",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetStats",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      alias: {
                                                        kind: "Name",
                                                        value: "datasetId",
                                                      },
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "fileSize",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "fillRatio",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "numberOfRows",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "numberOfDuplicateRows",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "numberOfNotNullableRows",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "statistics",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "statisticsVersion",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "ownerEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetMeta",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      alias: {
                                                        kind: "Name",
                                                        value: "datasetId",
                                                      },
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "description",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "ownerEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "allowStatisticsSharing",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNode",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "computeNodeId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "computeNodeType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodeName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataRoom",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "dataRoomId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "dataRoomHash",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "mrenclave",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    directives: [
                                      {
                                        kind: "Directive",
                                        name: { kind: "Name", value: "client" },
                                      },
                                    ],
                                    kind: "Field",
                                    name: { kind: "Name", value: "isSaving" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        alias: { kind: "Name", value: "dataRoomComputeNodes" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "config" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "containsJsonpath",
                                        },
                                        value: {
                                          block: false,
                                          kind: "StringValue",
                                          value: "$.config.computation",
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeType",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodeName" },
                                  },
                                  {
                                    alias: { kind: "Name", value: "name" },
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodeName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "config" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "index" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoom" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                        {
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: {
                                                kind: "Name",
                                                value: "filter",
                                              },
                                              value: {
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "permissions",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value: "some",
                                                          },
                                                          value: {
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: {
                                                                  kind: "Name",
                                                                  value:
                                                                    "permissionType",
                                                                },
                                                                value: {
                                                                  fields: [
                                                                    {
                                                                      kind: "ObjectField",
                                                                      name: {
                                                                        kind: "Name",
                                                                        value:
                                                                          "equalTo",
                                                                      },
                                                                      value: {
                                                                        kind: "EnumValue",
                                                                        value:
                                                                          "EXECUTE_COMPUTE_PERMISSION",
                                                                      },
                                                                    },
                                                                  ],
                                                                  kind: "ObjectValue",
                                                                },
                                                              },
                                                            ],
                                                            kind: "ObjectValue",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "ObjectValue",
                                              },
                                            },
                                          ],
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userPermissions",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "userPermissionId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataRoomId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissions",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionId",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionType",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "config",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "updatedAt",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "updatedByEmail",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "createdAt",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "createdByEmail",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "jobs" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNodeId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "jobId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "jobStartedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    directives: [
                                      {
                                        kind: "Directive",
                                        name: { kind: "Name", value: "client" },
                                      },
                                    ],
                                    kind: "Field",
                                    name: { kind: "Name", value: "isSaving" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mrenclave" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "governanceProtocol" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "enableDevelopmentTab" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isSaving" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isExpertViewEnabled" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "state" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "statusUpdatedAt" },
                            },
                            {
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "client" },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "isStopped" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "config" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "showOrganizationLogo",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataNodesOrder" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "computeNodesOrder",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userConfigId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isArchived" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isFavorite" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationLogo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "logo" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lock" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isLocked" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateDataRoomByIdInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  PublishDataRoomMutation,
  PublishDataRoomMutationVariables
>;
export const SetDataRoomIsArchivedDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "SetDataRoomIsArchived" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "userConfig" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "userConfigId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "userConfigId" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "dataRoomId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "isArchived" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "isArchived" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "email" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "email" },
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "ObjectValue",
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "upsertUserConfig" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userConfig" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userConfigId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isArchived" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoom" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userConfig" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userConfigId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isArchived" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  SetDataRoomIsArchivedMutation,
  SetDataRoomIsArchivedMutationVariables
>;
export const SetDataRoomIsFavoriteDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "SetDataRoomIsFavorite" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "userConfig" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "userConfigId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "userConfigId" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "dataRoomId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "isFavorite" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "isFavorite" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "email" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "email" },
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "ObjectValue",
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "upsertUserConfig" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userConfig" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userConfigId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isFavorite" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoom" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userConfig" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "userConfigId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "email" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "isFavorite" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  SetDataRoomIsFavoriteMutation,
  SetDataRoomIsFavoriteMutationVariables
>;
export const SetDataRoomShowOrganizationLogoDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "SetDataRoomShowOrganizationLogo" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "config" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "dataRoomId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: {
                              kind: "Name",
                              value: "showOrganizationLogo",
                            },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "showOrganizationLogo",
                              },
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "ObjectValue",
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "upsertConfig" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "config" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "showOrganizationLogo" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoom" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Boolean" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "showOrganizationLogo" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  SetDataRoomShowOrganizationLogoMutation,
  SetDataRoomShowOrganizationLogoMutationVariables
>;
export const SetDataRoomStatusDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "SetDataRoomStatus" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "state" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "dataRoomId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "status" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "status" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "statusUpdatedAt" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "statusUpdatedAt" },
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "ObjectValue",
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "upsertState" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "state" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "statusUpdatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoom" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "StatusType" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "status" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "Datetime" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "statusUpdatedAt" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  SetDataRoomStatusMutation,
  SetDataRoomStatusMutationVariables
>;
export const DataRoomBrandingDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DataRoomBranding" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "dataRoomById" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "config" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "showOrganizationLogo" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationLogo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "logo" } },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "ownerEmail" } },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DataRoomBrandingQuery,
  DataRoomBrandingQueryVariables
>;
export const DataRoomsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DataRooms" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "dataRooms" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isOwner" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastEditedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mrenclave" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ownerEmail" },
                      },
                      {
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "orderBy" },
                            value: {
                              kind: "ListValue",
                              values: [
                                { kind: "EnumValue", value: "EMAIL_ASC" },
                              ],
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "userPermissions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "userPermissionId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "permissions",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "permissionId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "permissionType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "config",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdByEmail",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        alias: { kind: "Name", value: "dataRoomDataNodes" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "config" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "containsJsonpath",
                                        },
                                        value: {
                                          block: false,
                                          kind: "StringValue",
                                          value: "$.config.validation",
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeType",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodeName" },
                                  },
                                  {
                                    alias: { kind: "Name", value: "name" },
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodeName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "config" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "index" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoom" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                        {
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: {
                                                kind: "Name",
                                                value: "filter",
                                              },
                                              value: {
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "permissions",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value: "some",
                                                          },
                                                          value: {
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: {
                                                                  kind: "Name",
                                                                  value:
                                                                    "permissionType",
                                                                },
                                                                value: {
                                                                  fields: [
                                                                    {
                                                                      kind: "ObjectField",
                                                                      name: {
                                                                        kind: "Name",
                                                                        value:
                                                                          "equalTo",
                                                                      },
                                                                      value: {
                                                                        kind: "EnumValue",
                                                                        value:
                                                                          "LEAF_CRUD_PERMISSION",
                                                                      },
                                                                    },
                                                                  ],
                                                                  kind: "ObjectValue",
                                                                },
                                                              },
                                                            ],
                                                            kind: "ObjectValue",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "ObjectValue",
                                              },
                                            },
                                          ],
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userPermissions",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "userPermissionId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataRoomId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissions",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionId",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionType",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "config",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "updatedAt",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "updatedByEmail",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "createdAt",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "createdByEmail",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetLinks",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalCount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetLinkId",
                                                },
                                              },
                                              {
                                                alias: {
                                                  kind: "Name",
                                                  value: "datasetId",
                                                },
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataset",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      alias: {
                                                        kind: "Name",
                                                        value: "datasetId",
                                                      },
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "description",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "ownerEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetLinks",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "computeNode",
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: "SelectionSet",
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "id",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "computeNodeId",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "nodeName",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "config",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "dataRoom",
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "dataRoomId",
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "dataRoomHash",
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "mrenclave",
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "name",
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "allowStatisticsSharing",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetStats",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      alias: {
                                                        kind: "Name",
                                                        value: "datasetId",
                                                      },
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "fileSize",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "fillRatio",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "numberOfRows",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "numberOfDuplicateRows",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "numberOfNotNullableRows",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "statistics",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "statisticsVersion",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "ownerEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetMeta",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      alias: {
                                                        kind: "Name",
                                                        value: "datasetId",
                                                      },
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "description",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "ownerEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "allowStatisticsSharing",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNode",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "computeNodeId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "computeNodeType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodeName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataRoom",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "dataRoomId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "dataRoomHash",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "mrenclave",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    directives: [
                                      {
                                        kind: "Directive",
                                        name: { kind: "Name", value: "client" },
                                      },
                                    ],
                                    kind: "Field",
                                    name: { kind: "Name", value: "isSaving" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        alias: { kind: "Name", value: "dataRoomComputeNodes" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "config" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "containsJsonpath",
                                        },
                                        value: {
                                          block: false,
                                          kind: "StringValue",
                                          value: "$.config.computation",
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeType",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodeName" },
                                  },
                                  {
                                    alias: { kind: "Name", value: "name" },
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodeName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "config" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "index" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoom" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                        {
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: {
                                                kind: "Name",
                                                value: "filter",
                                              },
                                              value: {
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "permissions",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value: "some",
                                                          },
                                                          value: {
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: {
                                                                  kind: "Name",
                                                                  value:
                                                                    "permissionType",
                                                                },
                                                                value: {
                                                                  fields: [
                                                                    {
                                                                      kind: "ObjectField",
                                                                      name: {
                                                                        kind: "Name",
                                                                        value:
                                                                          "equalTo",
                                                                      },
                                                                      value: {
                                                                        kind: "EnumValue",
                                                                        value:
                                                                          "EXECUTE_COMPUTE_PERMISSION",
                                                                      },
                                                                    },
                                                                  ],
                                                                  kind: "ObjectValue",
                                                                },
                                                              },
                                                            ],
                                                            kind: "ObjectValue",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "ObjectValue",
                                              },
                                            },
                                          ],
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userPermissions",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "userPermissionId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataRoomId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissions",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionId",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionType",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "config",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "updatedAt",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "updatedByEmail",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "createdAt",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "createdByEmail",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "jobs" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNodeId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "jobId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "jobStartedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    directives: [
                                      {
                                        kind: "Directive",
                                        name: { kind: "Name", value: "client" },
                                      },
                                    ],
                                    kind: "Field",
                                    name: { kind: "Name", value: "isSaving" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mrenclave" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "governanceProtocol" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "enableDevelopmentTab" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isSaving" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isExpertViewEnabled" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "state" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "statusUpdatedAt" },
                            },
                            {
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "client" },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "isStopped" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "config" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "showOrganizationLogo",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataNodesOrder" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "computeNodesOrder",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userConfigId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isArchived" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isFavorite" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationLogo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "logo" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lock" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isLocked" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "before" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DataRoomCondition" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "condition" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "DataRoomFilter" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: { kind: "Variable", name: { kind: "Name", value: "last" } },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "DataRoomsOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<DataRoomsQuery, DataRoomsQueryVariables>;
export const DatasetsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "Datasets" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "condition" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "filter" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "datasets" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        alias: { kind: "Name", value: "datasetId" },
                        kind: "Field",
                        name: { kind: "Name", value: "datasetHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "datasetHash" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ownerEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "datasetLinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetLinkId",
                                    },
                                  },
                                  {
                                    alias: { kind: "Name", value: "datasetId" },
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetHash",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetHash",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataset" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          alias: {
                                            kind: "Name",
                                            value: "datasetId",
                                          },
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetLinks",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "computeNode",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNodeId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodeName",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "config",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "dataRoom",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "dataRoomId",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "dataRoomHash",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "mrenclave",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "name",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "allowStatisticsSharing",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetStats",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          alias: {
                                            kind: "Name",
                                            value: "datasetId",
                                          },
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fileSize",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fillRatio",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "numberOfRows",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "numberOfDuplicateRows",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "numberOfNotNullableRows",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "statistics",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "statisticsVersion",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetMeta",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          alias: {
                                            kind: "Name",
                                            value: "datasetId",
                                          },
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "allowStatisticsSharing",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNode",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodeName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "config",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoom",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "mrenclave",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "datasetStats" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              alias: { kind: "Name", value: "datasetId" },
                              kind: "Field",
                              name: { kind: "Name", value: "datasetHash" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "datasetHash" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fileSize" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fillRatio" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "numberOfRows" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "numberOfDuplicateRows",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "numberOfNotNullableRows",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "statistics" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "statisticsVersion",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ownerEmail" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "datasetMeta" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              alias: { kind: "Name", value: "datasetId" },
                              kind: "Field",
                              name: { kind: "Name", value: "datasetHash" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "datasetHash" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ownerEmail" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "JSON" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "condition" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "JSON" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "filter" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "JSON" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<DatasetsQuery, DatasetsQueryVariables>;
export const DraftDataRoomDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DraftDataRoom" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataRoomId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataRoomId" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "dataRoom" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "dataRoomId" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "client" },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "isOwner" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastEditedAt" },
                },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedByEmail" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdByEmail" },
                },
                { kind: "Field", name: { kind: "Name", value: "mrenclave" } },
                { kind: "Field", name: { kind: "Name", value: "ownerEmail" } },
                {
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: {
                        kind: "ListValue",
                        values: [{ kind: "EnumValue", value: "EMAIL_ASC" }],
                      },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "userPermissions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userPermissionId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "permissions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "permissionId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "permissionType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "config",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  alias: { kind: "Name", value: "dataRoomDataNodes" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "config" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "containsJsonpath",
                                  },
                                  value: {
                                    block: false,
                                    kind: "StringValue",
                                    value: "$.config.validation",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "computeNodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodeName" },
                            },
                            {
                              alias: { kind: "Name", value: "name" },
                              kind: "Field",
                              name: { kind: "Name", value: "nodeName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "index" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoom" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "filter" },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "permissions",
                                              },
                                              value: {
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "some",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value:
                                                              "permissionType",
                                                          },
                                                          value: {
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: {
                                                                  kind: "Name",
                                                                  value:
                                                                    "equalTo",
                                                                },
                                                                value: {
                                                                  kind: "EnumValue",
                                                                  value:
                                                                    "LEAF_CRUD_PERMISSION",
                                                                },
                                                              },
                                                            ],
                                                            kind: "ObjectValue",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "ObjectValue",
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                    ],
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "userPermissions",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "userPermissionId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "permissions",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodes",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionType",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "config",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdByEmail",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "datasetLinks" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalCount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetLinkId",
                                          },
                                        },
                                        {
                                          alias: {
                                            kind: "Name",
                                            value: "datasetId",
                                          },
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataset",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                alias: {
                                                  kind: "Name",
                                                  value: "datasetId",
                                                },
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "description",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetLinks",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodes",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNode",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "computeNodeId",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "nodeName",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "config",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "dataRoom",
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: "SelectionSet",
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "dataRoomId",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "dataRoomHash",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "mrenclave",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "name",
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "allowStatisticsSharing",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetStats",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                alias: {
                                                  kind: "Name",
                                                  value: "datasetId",
                                                },
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "fileSize",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "fillRatio",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "numberOfRows",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "numberOfDuplicateRows",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "numberOfNotNullableRows",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "statistics",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "statisticsVersion",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetMeta",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                alias: {
                                                  kind: "Name",
                                                  value: "datasetId",
                                                },
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "description",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "allowStatisticsSharing",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNode",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNodeId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNodeType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodeName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "config",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoom",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataRoomId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataRoomHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "mrenclave",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "client" },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "isSaving" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  alias: { kind: "Name", value: "dataRoomComputeNodes" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "config" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "containsJsonpath",
                                  },
                                  value: {
                                    block: false,
                                    kind: "StringValue",
                                    value: "$.config.computation",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "computeNodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodeName" },
                            },
                            {
                              alias: { kind: "Name", value: "name" },
                              kind: "Field",
                              name: { kind: "Name", value: "nodeName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "index" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoom" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "filter" },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "permissions",
                                              },
                                              value: {
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "some",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value:
                                                              "permissionType",
                                                          },
                                                          value: {
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: {
                                                                  kind: "Name",
                                                                  value:
                                                                    "equalTo",
                                                                },
                                                                value: {
                                                                  kind: "EnumValue",
                                                                  value:
                                                                    "EXECUTE_COMPUTE_PERMISSION",
                                                                },
                                                              },
                                                            ],
                                                            kind: "ObjectValue",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "ObjectValue",
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                    ],
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "userPermissions",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "userPermissionId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "permissions",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodes",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionType",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "config",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdByEmail",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jobs" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "jobId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "jobStartedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "client" },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "isSaving" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dataRoomHash" },
                },
                { kind: "Field", name: { kind: "Name", value: "mrenclave" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "governanceProtocol" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "enableDevelopmentTab" },
                },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                {
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "client" },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "isSaving" },
                },
                {
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "client" },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "isExpertViewEnabled" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "state" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "statusUpdatedAt" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isStopped" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "config" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "showOrganizationLogo" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataNodesOrder" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodesOrder" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userConfig" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userConfigId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isArchived" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isFavorite" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationLogo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "logo" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lock" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isLocked" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<DraftDataRoomQuery, DraftDataRoomQueryVariables>;
export const OrganizationDomainsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "OrganizationDomains" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "organizationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "organizationId" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "organization" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "domains" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "domainId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "domain" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "organizationId" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  OrganizationDomainsQuery,
  OrganizationDomainsQueryVariables
>;
export const UpdateOrganizationDomainDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "updateOrganizationDomain" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "updateOrganizationById" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organization" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "domains" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "domainId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "domain" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateOrganizationByIdInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  UpdateOrganizationDomainMutation,
  UpdateOrganizationDomainMutationVariables
>;
export const PublishedDataRoomDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "PublishedDataRoom" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataRoomId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataRoomId" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "dataRoom" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "dataRoomId" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "client" },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "isOwner" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastEditedAt" },
                },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedByEmail" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdByEmail" },
                },
                { kind: "Field", name: { kind: "Name", value: "mrenclave" } },
                { kind: "Field", name: { kind: "Name", value: "ownerEmail" } },
                {
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: {
                        kind: "ListValue",
                        values: [{ kind: "EnumValue", value: "EMAIL_ASC" }],
                      },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "userPermissions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userPermissionId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "permissions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "permissionId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "permissionType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "config",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  alias: { kind: "Name", value: "dataRoomDataNodes" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "config" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "containsJsonpath",
                                  },
                                  value: {
                                    block: false,
                                    kind: "StringValue",
                                    value: "$.config.validation",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "computeNodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodeName" },
                            },
                            {
                              alias: { kind: "Name", value: "name" },
                              kind: "Field",
                              name: { kind: "Name", value: "nodeName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "index" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoom" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "filter" },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "permissions",
                                              },
                                              value: {
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "some",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value:
                                                              "permissionType",
                                                          },
                                                          value: {
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: {
                                                                  kind: "Name",
                                                                  value:
                                                                    "equalTo",
                                                                },
                                                                value: {
                                                                  kind: "EnumValue",
                                                                  value:
                                                                    "LEAF_CRUD_PERMISSION",
                                                                },
                                                              },
                                                            ],
                                                            kind: "ObjectValue",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "ObjectValue",
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                    ],
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "userPermissions",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "userPermissionId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "permissions",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodes",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionType",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "config",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdByEmail",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "datasetLinks" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalCount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetLinkId",
                                          },
                                        },
                                        {
                                          alias: {
                                            kind: "Name",
                                            value: "datasetId",
                                          },
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataset",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                alias: {
                                                  kind: "Name",
                                                  value: "datasetId",
                                                },
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "description",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetLinks",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodes",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNode",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "computeNodeId",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "nodeName",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "config",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "dataRoom",
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: "SelectionSet",
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "dataRoomId",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "dataRoomHash",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "mrenclave",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "name",
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "allowStatisticsSharing",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetStats",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                alias: {
                                                  kind: "Name",
                                                  value: "datasetId",
                                                },
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "fileSize",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "fillRatio",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "numberOfRows",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "numberOfDuplicateRows",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "numberOfNotNullableRows",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "statistics",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "statisticsVersion",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetMeta",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                alias: {
                                                  kind: "Name",
                                                  value: "datasetId",
                                                },
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "description",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "allowStatisticsSharing",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNode",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNodeId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNodeType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodeName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "config",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoom",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataRoomId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataRoomHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "mrenclave",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "client" },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "isSaving" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  alias: { kind: "Name", value: "dataRoomComputeNodes" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "config" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "containsJsonpath",
                                  },
                                  value: {
                                    block: false,
                                    kind: "StringValue",
                                    value: "$.config.computation",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "computeNodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodeName" },
                            },
                            {
                              alias: { kind: "Name", value: "name" },
                              kind: "Field",
                              name: { kind: "Name", value: "nodeName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "index" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoom" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "filter" },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "permissions",
                                              },
                                              value: {
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "some",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value:
                                                              "permissionType",
                                                          },
                                                          value: {
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: {
                                                                  kind: "Name",
                                                                  value:
                                                                    "equalTo",
                                                                },
                                                                value: {
                                                                  kind: "EnumValue",
                                                                  value:
                                                                    "EXECUTE_COMPUTE_PERMISSION",
                                                                },
                                                              },
                                                            ],
                                                            kind: "ObjectValue",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "ObjectValue",
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                    ],
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "userPermissions",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "userPermissionId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "permissions",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodes",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionType",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "config",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdByEmail",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jobs" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "jobId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "jobStartedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "client" },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "isSaving" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dataRoomHash" },
                },
                { kind: "Field", name: { kind: "Name", value: "mrenclave" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "governanceProtocol" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "enableDevelopmentTab" },
                },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                {
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "client" },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "isSaving" },
                },
                {
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "client" },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "isExpertViewEnabled" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "state" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "statusUpdatedAt" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isStopped" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "config" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "showOrganizationLogo" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataNodesOrder" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodesOrder" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userConfig" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userConfigId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isArchived" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isFavorite" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationLogo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "logo" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lock" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isLocked" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  PublishedDataRoomQuery,
  PublishedDataRoomQueryVariables
>;
export const ComputeNodeActionsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "ComputeNodeActions" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "id" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "id" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "computeNodeById" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "computationType" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "job" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "jobId" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jobStartedAt" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "result" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "resultAsFile" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fileBits" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fileName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "fileType" },
                            },
                          ],
                        },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isSending" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  ComputeNodeActionsQuery,
  ComputeNodeActionsQueryVariables
>;
export const RunComputeNodeDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "RunComputeNode" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                  ],
                  kind: "ObjectValue",
                },
              },
            ],
            directives: [
              { kind: "Directive", name: { kind: "Name", value: "client" } },
            ],
            kind: "Field",
            name: { kind: "Name", value: "runComputeNodeById" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "computeNode" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  RunComputeNodeMutation,
  RunComputeNodeMutationVariables
>;
export const UpdateSyntheticComputeNodeDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "UpdateSyntheticComputeNode" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "id" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "id" },
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "patch" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "config" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "config" },
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "ObjectValue",
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "updateComputeNodeById" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "computeNode" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "config" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
          variable: { kind: "Variable", name: { kind: "Name", value: "id" } },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "JSON" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "config" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  UpdateSyntheticComputeNodeMutation,
  UpdateSyntheticComputeNodeMutationVariables
>;
export const CreateDataRoomDataNodeDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "CreateDataRoomDataNode" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "createComputeNode" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  alias: { kind: "Name", value: "dataRoomDataNode" },
                  kind: "Field",
                  name: { kind: "Name", value: "computeNode" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodeId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodeType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodeName" },
                      },
                      {
                        alias: { kind: "Name", value: "name" },
                        kind: "Field",
                        name: { kind: "Name", value: "nodeName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "config" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdByEmail" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "index" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoom" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "filter" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "permissions",
                                        },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "some",
                                              },
                                              value: {
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "permissionType",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value: "equalTo",
                                                          },
                                                          value: {
                                                            kind: "EnumValue",
                                                            value:
                                                              "LEAF_CRUD_PERMISSION",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "ObjectValue",
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "userPermissions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userPermissionId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "permissions",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissionId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissionType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "datasetLinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetLinkId",
                                    },
                                  },
                                  {
                                    alias: { kind: "Name", value: "datasetId" },
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetHash",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetHash",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataset" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          alias: {
                                            kind: "Name",
                                            value: "datasetId",
                                          },
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetLinks",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "computeNode",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNodeId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodeName",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "config",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "dataRoom",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "dataRoomId",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "dataRoomHash",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "mrenclave",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "name",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "allowStatisticsSharing",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetStats",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          alias: {
                                            kind: "Name",
                                            value: "datasetId",
                                          },
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fileSize",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fillRatio",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "numberOfRows",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "numberOfDuplicateRows",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "numberOfNotNullableRows",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "statistics",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "statisticsVersion",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetMeta",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          alias: {
                                            kind: "Name",
                                            value: "datasetId",
                                          },
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "allowStatisticsSharing",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNode",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodeName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "config",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoom",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "mrenclave",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isSaving" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateComputeNodeInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  CreateDataRoomDataNodeMutation,
  CreateDataRoomDataNodeMutationVariables
>;
export const DataRoomDataNodesDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DataRoomDataNodes" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataRoomId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataRoomId" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "dataRoom" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
              ],
            },
          },
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataRoomId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataRoomId" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "config" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dataNodesOrder" },
                },
              ],
            },
          },
          {
            alias: { kind: "Name", value: "dataRoomDataNodes" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dataRoomId" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "config" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "containsJsonpath" },
                            value: {
                              block: false,
                              kind: "StringValue",
                              value: "$.config.validation",
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "ObjectValue",
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "computeNodes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodeId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodeType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodeName" },
                      },
                      {
                        alias: { kind: "Name", value: "name" },
                        kind: "Field",
                        name: { kind: "Name", value: "nodeName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "config" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdByEmail" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "index" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoom" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "filter" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "permissions",
                                        },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "some",
                                              },
                                              value: {
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "permissionType",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value: "equalTo",
                                                          },
                                                          value: {
                                                            kind: "EnumValue",
                                                            value:
                                                              "LEAF_CRUD_PERMISSION",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "ObjectValue",
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "userPermissions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userPermissionId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "permissions",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissionId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissionType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "datasetLinks" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetLinkId",
                                    },
                                  },
                                  {
                                    alias: { kind: "Name", value: "datasetId" },
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetHash",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetHash",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataset" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          alias: {
                                            kind: "Name",
                                            value: "datasetId",
                                          },
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetLinks",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "computeNode",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNodeId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodeName",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "config",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "dataRoom",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "dataRoomId",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "dataRoomHash",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "mrenclave",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "name",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "allowStatisticsSharing",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetStats",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          alias: {
                                            kind: "Name",
                                            value: "datasetId",
                                          },
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fileSize",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "fillRatio",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "numberOfRows",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "numberOfDuplicateRows",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "numberOfNotNullableRows",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "statistics",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "statisticsVersion",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetMeta",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          alias: {
                                            kind: "Name",
                                            value: "datasetId",
                                          },
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "name" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "description",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "allowStatisticsSharing",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNode",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodeName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "config",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoom",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "mrenclave",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isSaving" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "ComputeNodesOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DataRoomDataNodesQuery,
  DataRoomDataNodesQueryVariables
>;
export const DeleteDataRoomDataNodeDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DeleteDataRoomDataNode" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "deleteComputeNode" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  alias: { kind: "Name", value: "dataRoomDataNode" },
                  kind: "Field",
                  name: { kind: "Name", value: "computeNode" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeleteComputeNodeInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DeleteDataRoomDataNodeMutation,
  DeleteDataRoomDataNodeMutationVariables
>;
export const SetDataRoomDataNodesOrderDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "SetDataRoomDataNodesOrder" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "config" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "dataRoomId" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "dataNodesOrder" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "dataNodesOrder" },
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "ObjectValue",
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "upsertConfig" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "config" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataNodesOrder" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoom" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataNodesOrder" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  SetDataRoomDataNodesOrderMutation,
  SetDataRoomDataNodesOrderMutationVariables
>;
export const UpdateDataRoomDataNodeDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "UpdateDataRoomDataNode" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "updateComputeNode" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "computeNode" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodeName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "config" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateComputeNodeInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  UpdateDataRoomDataNodeMutation,
  UpdateDataRoomDataNodeMutationVariables
>;
export const CreateDatasetLinkDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "CreateDatasetLink" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "createDatasetLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "datasetLink" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "datasetHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodeId" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "query" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "datasetLinksExist",
                                  },
                                  value: { kind: "BooleanValue", value: true },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "ownerEmail" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "equalTo",
                                        },
                                        value: {
                                          kind: "Variable",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "datasets" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateDatasetLinkInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ownerEmail" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  CreateDatasetLinkMutation,
  CreateDatasetLinkMutationVariables
>;
export const CreateDatasetMetaAndStatsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "CreateDatasetMetaAndStats" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "inputMeta" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "createDatasetMeta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "datasetMeta" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        alias: { kind: "Name", value: "datasetId" },
                        kind: "Field",
                        name: { kind: "Name", value: "datasetHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "datasetHash" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ownerEmail" },
                      },
                    ],
                  },
                },
              ],
            },
          },
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "inputStats" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "createDatasetStat" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "datasetStat" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        alias: { kind: "Name", value: "datasetId" },
                        kind: "Field",
                        name: { kind: "Name", value: "datasetHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "datasetHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fileSize" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "fillRatio" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "numberOfRows" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "numberOfDuplicateRows" },
                      },
                      {
                        kind: "Field",
                        name: {
                          kind: "Name",
                          value: "numberOfNotNullableRows",
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "statistics" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "statisticsVersion" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ownerEmail" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateDatasetMetaInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "inputMeta" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateDatasetStatInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "inputStats" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  CreateDatasetMetaAndStatsMutation,
  CreateDatasetMetaAndStatsMutationVariables
>;
export const DeleteDatasetLinkDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DeleteDatasetLink" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "deleteDatasetLink" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeleteDatasetLinkInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DeleteDatasetLinkMutation,
  DeleteDatasetLinkMutationVariables
>;
export const DeleteDatasetMetaAndStatsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DeleteDatasetMetaAndStats" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "datasetHash" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "datasetHash" },
                      },
                    },
                  ],
                  kind: "ObjectValue",
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "deleteDatasetMeta" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
              ],
            },
          },
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "datasetHash" },
                      value: {
                        kind: "Variable",
                        name: { kind: "Name", value: "datasetHash" },
                      },
                    },
                  ],
                  kind: "ObjectValue",
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "deleteDatasetStat" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "datasetHash" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DeleteDatasetMetaAndStatsMutation,
  DeleteDatasetMetaAndStatsMutationVariables
>;
export const CreateMergeableSignatureDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "CreateMergeableSignature" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "createSignature" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signature" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signee" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "request" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "signees" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signeeEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signeeId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasets",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signature",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signatureHash",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "query" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "configuration",
                                  },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "dataRoomId",
                                        },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "equalTo",
                                              },
                                              value: {
                                                kind: "Variable",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomId",
                                                },
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "ownerEmail",
                                        },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "notEqualTo",
                                              },
                                              value: {
                                                kind: "Variable",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "orderBy" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "requestsOrderBy" },
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "requests" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "signees" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "requestId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signeeEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signeeId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasets",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signature",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signatureHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signeeId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isSigned" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "requestId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "configuration",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "configurationId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "commitId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "modifications",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "configurationId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "elementId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "elementType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "modification",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "modificationId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "computeNodeAsElement",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNodeId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNodeType",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "config",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodeName",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedByEmail",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "userPermissionAsElement",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "userPermissionId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionAsElementsByUserPermissionId",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "nodes",
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: "SelectionSet",
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "id",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "permissionType",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "config",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "permissionId",
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateSignatureInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
        {
          defaultValue: {
            kind: "ListValue",
            values: [{ kind: "EnumValue", value: "CREATED_AT_ASC" }],
          },
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "RequestsOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "requestsOrderBy" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ownerEmail" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  CreateMergeableSignatureMutation,
  CreateMergeableSignatureMutationVariables
>;
export const CreateSignatureDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "CreateSignature" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "createSignature" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signature" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signee" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "request" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "signees" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signeeEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signeeId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasets",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signature",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signatureHash",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "query" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "configuration",
                                  },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "dataRoomId",
                                        },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "equalTo",
                                              },
                                              value: {
                                                kind: "Variable",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomId",
                                                },
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "ownerEmail",
                                        },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "notEqualTo",
                                              },
                                              value: {
                                                kind: "Variable",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "orderBy" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "requestsOrderBy" },
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "requests" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "signees" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "requestId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signeeEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signeeId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasets",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signature",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signatureHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signeeId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isSigned" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "requestId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "configuration",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "configurationId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "commitId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "modifications",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "configurationId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "elementId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "elementType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "modification",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "modificationId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "computeNodeAsElement",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNodeId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNodeType",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "config",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodeName",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedByEmail",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "userPermissionAsElement",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "userPermissionId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionAsElementsByUserPermissionId",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "nodes",
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: "SelectionSet",
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "id",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "permissionType",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "config",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "permissionId",
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "dataRoomId" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "equalTo",
                                        },
                                        value: {
                                          kind: "Variable",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "ownerEmail" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "equalTo",
                                        },
                                        value: {
                                          kind: "Variable",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "isDev" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "equalTo",
                                        },
                                        value: {
                                          kind: "BooleanValue",
                                          value: false,
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "orderBy" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "configurationsOrderBy",
                              },
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "configurations" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "configurationId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "commitId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "request" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "signees",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signeeId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signeeEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasets",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signature",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "signatureHash",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "isSigned",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isDev" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ownerEmail" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "modifications",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "configurationId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "modificationId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "modification",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "config",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "elementId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "elementType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNodeAsElement",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "computeNodeId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "computeNodeType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodeName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "userPermissionAsElement",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "userPermissionId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "permissionAsElementsByUserPermissionId",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionType",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "config",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionId",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateSignatureInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
        {
          defaultValue: {
            kind: "ListValue",
            values: [{ kind: "EnumValue", value: "CREATED_AT_ASC" }],
          },
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "RequestsOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "requestsOrderBy" },
          },
        },
        {
          defaultValue: {
            kind: "ListValue",
            values: [{ kind: "EnumValue", value: "CREATED_AT_ASC" }],
          },
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "ConfigurationsOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "configurationsOrderBy" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ownerEmail" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  CreateSignatureMutation,
  CreateSignatureMutationVariables
>;
export const DeleteRequestDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DeleteRequest" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "deleteRequest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "DeleteRequestInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DeleteRequestMutation,
  DeleteRequestMutationVariables
>;
export const RequestByConfigurationIdDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "RequestByConfigurationId" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "configurationId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "configurationId" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "requestByConfigurationId" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "signees" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "signeeEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "signeeId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "datasets" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "signature" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "signatureHash",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "signeeId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "isSigned" } },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdByEmail" },
                },
                { kind: "Field", name: { kind: "Name", value: "message" } },
                { kind: "Field", name: { kind: "Name", value: "requestId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "configuration" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "configurationId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "commitId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ownerEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modifications" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "config" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "configurationId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "elementId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "elementType",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "modification",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "modificationId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeAsElement",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "config",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodeName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "userPermissionAsElement",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userPermissionId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value:
                                              "permissionAsElementsByUserPermissionId",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissionType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissionId",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedByEmail" },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "configurationId" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  RequestByConfigurationIdQuery,
  RequestByConfigurationIdQueryVariables
>;
export const DataRoomRequestsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DataRoomRequests" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "after" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "before" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "before" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "condition" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "configuration" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "dataRoomId" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "ownerEmail" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "notEqualTo" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "ownerEmail" },
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "ObjectValue",
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "last" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "last" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "requests" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signees" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "requestId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "signeeEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "signeeId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "datasets" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "signature" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "signatureHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "signeeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isSigned" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "message" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "requestId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "configuration" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "configurationId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "commitId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ownerEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "modifications" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "config",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "configurationId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "elementId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "elementType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "modification",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "modificationId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeAsElement",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNodeId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNodeType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "config",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodeName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedByEmail",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userPermissionAsElement",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "userPermissionId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "permissionAsElementsByUserPermissionId",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodes",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionType",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "config",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionId",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedByEmail" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ownerEmail" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "before" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "RequestCondition" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "condition" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: { kind: "Variable", name: { kind: "Name", value: "last" } },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
        },
        {
          defaultValue: {
            kind: "ListValue",
            values: [{ kind: "EnumValue", value: "CREATED_AT_ASC" }],
          },
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "RequestsOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DataRoomRequestsQuery,
  DataRoomRequestsQueryVariables
>;
export const DataRoomRequestsTotalCountDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DataRoomRequestsTotalCount" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "after" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "before" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "before" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "condition" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "configuration" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "dataRoomId" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "equalTo" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "ownerEmail" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "notEqualTo" },
                                  value: {
                                    kind: "Variable",
                                    name: { kind: "Name", value: "ownerEmail" },
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "signees" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "some" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "signeeEmail" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "equalTo",
                                        },
                                        value: {
                                          kind: "Variable",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "ObjectValue",
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "last" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "last" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "requests" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "totalCount" } },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ownerEmail" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "before" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "RequestCondition" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "condition" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: { kind: "Variable", name: { kind: "Name", value: "last" } },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
        },
        {
          defaultValue: {
            kind: "ListValue",
            values: [{ kind: "EnumValue", value: "CREATED_AT_ASC" }],
          },
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "RequestsOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DataRoomRequestsTotalCountQuery,
  DataRoomRequestsTotalCountQueryVariables
>;
export const DataRoomDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DataRoom" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "dataRoomId" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "dataRoomId" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "dataRoom" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "id" } },
                { kind: "Field", name: { kind: "Name", value: "dataRoomId" } },
                { kind: "Field", name: { kind: "Name", value: "name" } },
                { kind: "Field", name: { kind: "Name", value: "description" } },
                {
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "client" },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "isOwner" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lastEditedAt" },
                },
                { kind: "Field", name: { kind: "Name", value: "updatedAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "updatedByEmail" },
                },
                { kind: "Field", name: { kind: "Name", value: "createdAt" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "createdByEmail" },
                },
                { kind: "Field", name: { kind: "Name", value: "mrenclave" } },
                { kind: "Field", name: { kind: "Name", value: "ownerEmail" } },
                {
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "orderBy" },
                      value: {
                        kind: "ListValue",
                        values: [{ kind: "EnumValue", value: "EMAIL_ASC" }],
                      },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "userPermissions" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userPermissionId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "email" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "permissions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "permissionId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "permissionType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "config",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  alias: { kind: "Name", value: "dataRoomDataNodes" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "config" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "containsJsonpath",
                                  },
                                  value: {
                                    block: false,
                                    kind: "StringValue",
                                    value: "$.config.validation",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "computeNodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodeName" },
                            },
                            {
                              alias: { kind: "Name", value: "name" },
                              kind: "Field",
                              name: { kind: "Name", value: "nodeName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "index" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoom" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "filter" },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "permissions",
                                              },
                                              value: {
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "some",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value:
                                                              "permissionType",
                                                          },
                                                          value: {
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: {
                                                                  kind: "Name",
                                                                  value:
                                                                    "equalTo",
                                                                },
                                                                value: {
                                                                  kind: "EnumValue",
                                                                  value:
                                                                    "LEAF_CRUD_PERMISSION",
                                                                },
                                                              },
                                                            ],
                                                            kind: "ObjectValue",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "ObjectValue",
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                    ],
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "userPermissions",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "userPermissionId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "permissions",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodes",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionType",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "config",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdByEmail",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "datasetLinks" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "totalCount" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetLinkId",
                                          },
                                        },
                                        {
                                          alias: {
                                            kind: "Name",
                                            value: "datasetId",
                                          },
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetHash",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataset",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                alias: {
                                                  kind: "Name",
                                                  value: "datasetId",
                                                },
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "description",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetLinks",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodes",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNode",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "computeNodeId",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "nodeName",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "config",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "dataRoom",
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: "SelectionSet",
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "dataRoomId",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "dataRoomHash",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "mrenclave",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "name",
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "allowStatisticsSharing",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetStats",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                alias: {
                                                  kind: "Name",
                                                  value: "datasetId",
                                                },
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "fileSize",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "fillRatio",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "numberOfRows",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "numberOfDuplicateRows",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "numberOfNotNullableRows",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "statistics",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "statisticsVersion",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetMeta",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                alias: {
                                                  kind: "Name",
                                                  value: "datasetId",
                                                },
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "name",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "description",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "allowStatisticsSharing",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNode",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNodeId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNodeType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodeName",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "config",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoom",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataRoomId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataRoomHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "mrenclave",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "client" },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "isSaving" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  alias: { kind: "Name", value: "dataRoomComputeNodes" },
                  arguments: [
                    {
                      kind: "Argument",
                      name: { kind: "Name", value: "filter" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "config" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "containsJsonpath",
                                  },
                                  value: {
                                    block: false,
                                    kind: "StringValue",
                                    value: "$.config.computation",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "computeNodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodeType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodeName" },
                            },
                            {
                              alias: { kind: "Name", value: "name" },
                              kind: "Field",
                              name: { kind: "Name", value: "nodeName" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "index" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoom" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    arguments: [
                                      {
                                        kind: "Argument",
                                        name: { kind: "Name", value: "filter" },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "permissions",
                                              },
                                              value: {
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "some",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value:
                                                              "permissionType",
                                                          },
                                                          value: {
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: {
                                                                  kind: "Name",
                                                                  value:
                                                                    "equalTo",
                                                                },
                                                                value: {
                                                                  kind: "EnumValue",
                                                                  value:
                                                                    "EXECUTE_COMPUTE_PERMISSION",
                                                                },
                                                              },
                                                            ],
                                                            kind: "ObjectValue",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "ObjectValue",
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                    ],
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "userPermissions",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "userPermissionId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "email",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "permissions",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodes",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionType",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "config",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdByEmail",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "jobs" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "jobId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "jobStartedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "client" },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "isSaving" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dataRoomHash" },
                },
                { kind: "Field", name: { kind: "Name", value: "mrenclave" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "governanceProtocol" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "enableDevelopmentTab" },
                },
                { kind: "Field", name: { kind: "Name", value: "source" } },
                {
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "client" },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "isSaving" },
                },
                {
                  directives: [
                    {
                      kind: "Directive",
                      name: { kind: "Name", value: "client" },
                    },
                  ],
                  kind: "Field",
                  name: { kind: "Name", value: "isExpertViewEnabled" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "state" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "status" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "statusUpdatedAt" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isStopped" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "config" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "showOrganizationLogo" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataNodesOrder" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodesOrder" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "userConfig" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userConfigId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isArchived" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isFavorite" },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "organizationLogo" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      { kind: "Field", name: { kind: "Name", value: "logo" } },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "lock" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "isLocked" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<DataRoomQuery, DataRoomQueryVariables>;
export const DataRoomConfigurationsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DataRoomConfigurations" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "after" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "before" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "before" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "condition" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dataRoomId" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "ownerEmail" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "ownerEmail" },
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "isDev" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "BooleanValue", value: false },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "ObjectValue",
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "last" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "last" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "configurations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "configurationId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "commitId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "request" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "signees" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "signeeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "signeeEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasets",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "signature",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signatureHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isSigned" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "isDev" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ownerEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modifications" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "configurationId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "modificationId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "modification",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "config" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "elementId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "elementType",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeAsElement",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodeName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "config",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "userPermissionAsElement",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userPermissionId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value:
                                              "permissionAsElementsByUserPermissionId",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissionType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissionId",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ownerEmail" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "before" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ConfigurationCondition" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "condition" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: { kind: "Variable", name: { kind: "Name", value: "last" } },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
        },
        {
          defaultValue: {
            kind: "ListValue",
            values: [{ kind: "EnumValue", value: "CREATED_AT_ASC" }],
          },
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "ConfigurationsOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DataRoomConfigurationsQuery,
  DataRoomConfigurationsQueryVariables
>;
export const CreateConfigurationDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "CreateConfiguration" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "createConfiguration" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "configuration" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "configurationId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "commitId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "request" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "signees" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "signeeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "signeeEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasets",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "signature",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signatureHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isSigned" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "isDev" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ownerEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modifications" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "configurationId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "modificationId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "modification",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "config" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "elementId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "elementType",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeAsElement",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodeName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "config",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "userPermissionAsElement",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userPermissionId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value:
                                              "permissionAsElementsByUserPermissionId",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissionType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissionId",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "query" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "configuration",
                                  },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "dataRoomId",
                                        },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "equalTo",
                                              },
                                              value: {
                                                kind: "Variable",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomId",
                                                },
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "ownerEmail",
                                        },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "notEqualTo",
                                              },
                                              value: {
                                                kind: "Variable",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "orderBy" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "requestsOrderBy" },
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "requests" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "signees" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "requestId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signeeEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signeeId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasets",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signature",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signatureHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signeeId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isSigned" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "requestId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "configuration",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "configurationId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "commitId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "modifications",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "configurationId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "elementId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "elementType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "modification",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "modificationId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "computeNodeAsElement",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNodeId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNodeType",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "config",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodeName",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedByEmail",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "userPermissionAsElement",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "userPermissionId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionAsElementsByUserPermissionId",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "nodes",
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: "SelectionSet",
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "id",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "permissionType",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "config",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "permissionId",
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "dataRoomId" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "equalTo",
                                        },
                                        value: {
                                          kind: "Variable",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "ownerEmail" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "equalTo",
                                        },
                                        value: {
                                          kind: "Variable",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "isDev" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "equalTo",
                                        },
                                        value: {
                                          kind: "BooleanValue",
                                          value: false,
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "orderBy" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "configurationsOrderBy",
                              },
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "configurations" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "configurationId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "commitId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "request" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "signees",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signeeId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signeeEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasets",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signature",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "signatureHash",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "isSigned",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isDev" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ownerEmail" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "modifications",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "configurationId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "modificationId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "modification",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "config",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "elementId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "elementType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNodeAsElement",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "computeNodeId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "computeNodeType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodeName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "userPermissionAsElement",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "userPermissionId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "permissionAsElementsByUserPermissionId",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionType",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "config",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionId",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateConfigurationInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
        {
          defaultValue: {
            kind: "ListValue",
            values: [{ kind: "EnumValue", value: "CREATED_AT_ASC" }],
          },
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "RequestsOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "requestsOrderBy" },
          },
        },
        {
          defaultValue: {
            kind: "ListValue",
            values: [{ kind: "EnumValue", value: "CREATED_AT_ASC" }],
          },
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "ConfigurationsOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "configurationsOrderBy" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ownerEmail" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  CreateConfigurationMutation,
  CreateConfigurationMutationVariables
>;
export const CreateModificationDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "CreateModification" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "createModification" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateModificationInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  CreateModificationMutation,
  CreateModificationMutationVariables
>;
export const CreateRequestDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "CreateRequest" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "createRequest" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "request" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "signees" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "signeeId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "signeeEmail",
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateRequestInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  CreateRequestMutation,
  CreateRequestMutationVariables
>;
export const DeleteConfigurationAndElementsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DeleteConfigurationAndElements" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "deleteConfigurationAndElements" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "DeleteConfigurationAndElementsInput",
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DeleteConfigurationAndElementsMutation,
  DeleteConfigurationAndElementsMutationVariables
>;
export const DeleteModificationAndElementsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DeleteModificationAndElements" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "deleteModificationAndElements" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: {
                kind: "Name",
                value: "DeleteModificationAndElementsInput",
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DeleteModificationAndElementsMutation,
  DeleteModificationAndElementsMutationVariables
>;
export const DataRoomDevConfigurationsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "DataRoomDevConfigurations" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "after" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "after" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "before" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "before" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "condition" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "condition" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "filter" },
                value: {
                  fields: [
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "dataRoomId" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "ownerEmail" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "ownerEmail" },
                            },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                    {
                      kind: "ObjectField",
                      name: { kind: "Name", value: "isDev" },
                      value: {
                        fields: [
                          {
                            kind: "ObjectField",
                            name: { kind: "Name", value: "equalTo" },
                            value: { kind: "BooleanValue", value: true },
                          },
                        ],
                        kind: "ObjectValue",
                      },
                    },
                  ],
                  kind: "ObjectValue",
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "first" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "first" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "last" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "last" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "offset" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "offset" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "orderBy" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "orderBy" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "configurations" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "configurationId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "commitId" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "request" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "requestId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "signees" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "signeeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "signeeEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasets",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "signature",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signatureHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isSigned" },
                            },
                          ],
                        },
                      },
                      { kind: "Field", name: { kind: "Name", value: "isDev" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ownerEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "modifications" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "configurationId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "modificationId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "modification",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "config" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "elementId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "elementType",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeAsElement",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodeName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "config",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "userPermissionAsElement",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userPermissionId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value:
                                              "permissionAsElementsByUserPermissionId",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissionType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissionId",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ownerEmail" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "after" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Cursor" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "before" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NamedType",
            name: { kind: "Name", value: "ConfigurationCondition" },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "condition" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "first" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: { kind: "Variable", name: { kind: "Name", value: "last" } },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "Int" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "offset" },
          },
        },
        {
          defaultValue: {
            kind: "ListValue",
            values: [{ kind: "EnumValue", value: "CREATED_AT_ASC" }],
          },
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "ConfigurationsOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "orderBy" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  DataRoomDevConfigurationsQuery,
  DataRoomDevConfigurationsQueryVariables
>;
export const MergeConfigurationDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "MergeConfiguration" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "mergeConfiguration" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "query" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: {
                                    kind: "Name",
                                    value: "configuration",
                                  },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "dataRoomId",
                                        },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "equalTo",
                                              },
                                              value: {
                                                kind: "Variable",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomId",
                                                },
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "ownerEmail",
                                        },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "notEqualTo",
                                              },
                                              value: {
                                                kind: "Variable",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "orderBy" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "requestsOrderBy" },
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "requests" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "signees" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "requestId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signeeEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signeeId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasets",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "signature",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signatureHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signeeId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isSigned" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "message" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "requestId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "configuration",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "configurationId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "commitId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "modifications",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "configurationId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "elementId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "elementType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "modification",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "modificationId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "computeNodeAsElement",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNodeId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNodeType",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "config",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodeName",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedByEmail",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "userPermissionAsElement",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "userPermissionId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissionAsElementsByUserPermissionId",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "nodes",
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: "SelectionSet",
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "id",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "permissionType",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "config",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "permissionId",
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "totalCount" },
                            },
                          ],
                        },
                      },
                      {
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "dataRoomId" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "equalTo",
                                        },
                                        value: {
                                          kind: "Variable",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "ownerEmail" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "equalTo",
                                        },
                                        value: {
                                          kind: "Variable",
                                          name: {
                                            kind: "Name",
                                            value: "ownerEmail",
                                          },
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "isDev" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "equalTo",
                                        },
                                        value: {
                                          kind: "BooleanValue",
                                          value: false,
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "orderBy" },
                            value: {
                              kind: "Variable",
                              name: {
                                kind: "Name",
                                value: "configurationsOrderBy",
                              },
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "configurations" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "configurationId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "commitId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "request" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "requestId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "signees",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signeeId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signeeEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasets",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "signature",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "signatureHash",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "isSigned",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isDev" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "ownerEmail" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "modifications",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "configurationId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "modificationId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "modification",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "config",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "elementId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "elementType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNodeAsElement",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "computeNodeId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "computeNodeType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodeName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "userPermissionAsElement",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "userPermissionId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "permissionAsElementsByUserPermissionId",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionType",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "config",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionId",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        alias: { kind: "Name", value: "dataRoom" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "dataRoomHash" },
                            value: {
                              kind: "Variable",
                              name: { kind: "Name", value: "dataRoomHash" },
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomByDataRoomHash" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "name" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "description" },
                            },
                            {
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "client" },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "isOwner" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lastEditedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "createdByEmail" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mrenclave" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ownerEmail" },
                            },
                            {
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "orderBy" },
                                  value: {
                                    kind: "ListValue",
                                    values: [
                                      { kind: "EnumValue", value: "EMAIL_ASC" },
                                    ],
                                  },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "userPermissions" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userPermissionId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "email",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "permissions",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissionId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissionType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              alias: {
                                kind: "Name",
                                value: "dataRoomDataNodes",
                              },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "filter" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "config" },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "containsJsonpath",
                                              },
                                              value: {
                                                block: false,
                                                kind: "StringValue",
                                                value: "$.config.validation",
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodeName",
                                          },
                                        },
                                        {
                                          alias: {
                                            kind: "Name",
                                            value: "name",
                                          },
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodeName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "config",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "index",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoom",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomId",
                                                },
                                              },
                                              {
                                                arguments: [
                                                  {
                                                    kind: "Argument",
                                                    name: {
                                                      kind: "Name",
                                                      value: "filter",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value:
                                                              "permissions",
                                                          },
                                                          value: {
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: {
                                                                  kind: "Name",
                                                                  value: "some",
                                                                },
                                                                value: {
                                                                  fields: [
                                                                    {
                                                                      kind: "ObjectField",
                                                                      name: {
                                                                        kind: "Name",
                                                                        value:
                                                                          "permissionType",
                                                                      },
                                                                      value: {
                                                                        fields:
                                                                          [
                                                                            {
                                                                              kind: "ObjectField",
                                                                              name: {
                                                                                kind: "Name",
                                                                                value:
                                                                                  "equalTo",
                                                                              },
                                                                              value:
                                                                                {
                                                                                  kind: "EnumValue",
                                                                                  value:
                                                                                    "LEAF_CRUD_PERMISSION",
                                                                                },
                                                                            },
                                                                          ],
                                                                        kind: "ObjectValue",
                                                                      },
                                                                    },
                                                                  ],
                                                                  kind: "ObjectValue",
                                                                },
                                                              },
                                                            ],
                                                            kind: "ObjectValue",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "userPermissions",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodes",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "userPermissionId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "dataRoomId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissions",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "nodes",
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: "SelectionSet",
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "id",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "permissionId",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "permissionType",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "config",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "updatedAt",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "updatedByEmail",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "createdAt",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "createdByEmail",
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "datasetLinks",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "totalCount",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetLinkId",
                                                      },
                                                    },
                                                    {
                                                      alias: {
                                                        kind: "Name",
                                                        value: "datasetId",
                                                      },
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataset",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            alias: {
                                                              kind: "Name",
                                                              value:
                                                                "datasetId",
                                                            },
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "datasetHash",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "datasetHash",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "description",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "ownerEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "datasetLinks",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "nodes",
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: "SelectionSet",
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "id",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "computeNode",
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "id",
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "computeNodeId",
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "nodeName",
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "config",
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "dataRoom",
                                                                                      },
                                                                                      selectionSet:
                                                                                        {
                                                                                          kind: "SelectionSet",
                                                                                          selections:
                                                                                            [
                                                                                              {
                                                                                                kind: "Field",
                                                                                                name: {
                                                                                                  kind: "Name",
                                                                                                  value:
                                                                                                    "dataRoomId",
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: "Field",
                                                                                                name: {
                                                                                                  kind: "Name",
                                                                                                  value:
                                                                                                    "dataRoomHash",
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: "Field",
                                                                                                name: {
                                                                                                  kind: "Name",
                                                                                                  value:
                                                                                                    "mrenclave",
                                                                                                },
                                                                                              },
                                                                                              {
                                                                                                kind: "Field",
                                                                                                name: {
                                                                                                  kind: "Name",
                                                                                                  value:
                                                                                                    "name",
                                                                                                },
                                                                                              },
                                                                                            ],
                                                                                        },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "allowStatisticsSharing",
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetStats",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            alias: {
                                                              kind: "Name",
                                                              value:
                                                                "datasetId",
                                                            },
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "datasetHash",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "datasetHash",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "fileSize",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "fillRatio",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "numberOfRows",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "numberOfDuplicateRows",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "numberOfNotNullableRows",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "statistics",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "statisticsVersion",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "ownerEmail",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetMeta",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            alias: {
                                                              kind: "Name",
                                                              value:
                                                                "datasetId",
                                                            },
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "datasetHash",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "datasetHash",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "description",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "ownerEmail",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "allowStatisticsSharing",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "computeNode",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNodeId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "computeNodeType",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodeName",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "config",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "dataRoom",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "dataRoomId",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "dataRoomHash",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "mrenclave",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "name",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          directives: [
                                            {
                                              kind: "Directive",
                                              name: {
                                                kind: "Name",
                                                value: "client",
                                              },
                                            },
                                          ],
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "isSaving",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              alias: {
                                kind: "Name",
                                value: "dataRoomComputeNodes",
                              },
                              arguments: [
                                {
                                  kind: "Argument",
                                  name: { kind: "Name", value: "filter" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: { kind: "Name", value: "config" },
                                        value: {
                                          fields: [
                                            {
                                              kind: "ObjectField",
                                              name: {
                                                kind: "Name",
                                                value: "containsJsonpath",
                                              },
                                              value: {
                                                block: false,
                                                kind: "StringValue",
                                                value: "$.config.computation",
                                              },
                                            },
                                          ],
                                          kind: "ObjectValue",
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "computeNodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodes" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeId",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "computeNodeType",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodeName",
                                          },
                                        },
                                        {
                                          alias: {
                                            kind: "Name",
                                            value: "name",
                                          },
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodeName",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "config",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "updatedByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdAt",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "createdByEmail",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "index",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoom",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataRoomId",
                                                },
                                              },
                                              {
                                                arguments: [
                                                  {
                                                    kind: "Argument",
                                                    name: {
                                                      kind: "Name",
                                                      value: "filter",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value:
                                                              "permissions",
                                                          },
                                                          value: {
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: {
                                                                  kind: "Name",
                                                                  value: "some",
                                                                },
                                                                value: {
                                                                  fields: [
                                                                    {
                                                                      kind: "ObjectField",
                                                                      name: {
                                                                        kind: "Name",
                                                                        value:
                                                                          "permissionType",
                                                                      },
                                                                      value: {
                                                                        fields:
                                                                          [
                                                                            {
                                                                              kind: "ObjectField",
                                                                              name: {
                                                                                kind: "Name",
                                                                                value:
                                                                                  "equalTo",
                                                                              },
                                                                              value:
                                                                                {
                                                                                  kind: "EnumValue",
                                                                                  value:
                                                                                    "EXECUTE_COMPUTE_PERMISSION",
                                                                                },
                                                                            },
                                                                          ],
                                                                        kind: "ObjectValue",
                                                                      },
                                                                    },
                                                                  ],
                                                                  kind: "ObjectValue",
                                                                },
                                                              },
                                                            ],
                                                            kind: "ObjectValue",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "userPermissions",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodes",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "userPermissionId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "email",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "updatedAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdAt",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "createdByEmail",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "dataRoomId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "permissions",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "nodes",
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: "SelectionSet",
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "id",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "permissionId",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "permissionType",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "config",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "updatedAt",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "updatedByEmail",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "createdAt",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "createdByEmail",
                                                                            },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "jobs" },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "computeNodeId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "jobId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "jobStartedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "ownerEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                        {
                                          directives: [
                                            {
                                              kind: "Directive",
                                              name: {
                                                kind: "Name",
                                                value: "client",
                                              },
                                            },
                                          ],
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "isSaving",
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomHash" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "mrenclave" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "governanceProtocol",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "enableDevelopmentTab",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "source" },
                            },
                            {
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "client" },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "isSaving" },
                            },
                            {
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "client" },
                                },
                              ],
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "isExpertViewEnabled",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "state" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "status" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "statusUpdatedAt",
                                    },
                                  },
                                  {
                                    directives: [
                                      {
                                        kind: "Directive",
                                        name: { kind: "Name", value: "client" },
                                      },
                                    ],
                                    kind: "Field",
                                    name: { kind: "Name", value: "isStopped" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "config" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "showOrganizationLogo",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "dataNodesOrder",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodesOrder",
                                    },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userConfig" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "userConfigId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isArchived" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isFavorite" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "organizationLogo" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "logo" },
                                  },
                                ],
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "lock" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "isLocked" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "MergeConfigurationInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
        {
          defaultValue: {
            kind: "ListValue",
            values: [{ kind: "EnumValue", value: "CREATED_AT_ASC" }],
          },
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "RequestsOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "requestsOrderBy" },
          },
        },
        {
          defaultValue: {
            kind: "ListValue",
            values: [{ kind: "EnumValue", value: "CREATED_AT_ASC" }],
          },
          kind: "VariableDefinition",
          type: {
            kind: "ListType",
            type: {
              kind: "NonNullType",
              type: {
                kind: "NamedType",
                name: { kind: "Name", value: "ConfigurationsOrderBy" },
              },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "configurationsOrderBy" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "UUID" } },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomId" },
          },
        },
        {
          kind: "VariableDefinition",
          type: { kind: "NamedType", name: { kind: "Name", value: "String" } },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "ownerEmail" },
          },
        },
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "dataRoomHash" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  MergeConfigurationMutation,
  MergeConfigurationMutationVariables
>;
export const UpdateConfigurationDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "UpdateConfiguration" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "updateConfiguration" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateConfigurationInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  UpdateConfigurationMutation,
  UpdateConfigurationMutationVariables
>;
export const UpdateDataRoomComputeNodeDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "UpdateDataRoomComputeNode" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "updateComputeNode" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "computeNode" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "nodeName" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "config" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpdateComputeNodeInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  UpdateDataRoomComputeNodeMutation,
  UpdateDataRoomComputeNodeMutationVariables
>;
export const UpsertJobDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "UpsertJob" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "upsertJob" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "clientMutationId" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "job" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodeId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "jobId" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "jobStartedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ownerEmail" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "UpsertJobInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<UpsertJobMutation, UpsertJobMutationVariables>;
export const CreateDataRoomDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "CreateDataRoom" },
      operation: "mutation",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "input" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "input" },
                },
              },
            ],
            kind: "Field",
            name: { kind: "Name", value: "createDataRoom" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "dataRoom" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "id" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomId" },
                      },
                      { kind: "Field", name: { kind: "Name", value: "name" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "description" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isOwner" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lastEditedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "updatedByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdAt" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "createdByEmail" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mrenclave" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ownerEmail" },
                      },
                      {
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "orderBy" },
                            value: {
                              kind: "ListValue",
                              values: [
                                { kind: "EnumValue", value: "EMAIL_ASC" },
                              ],
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "userPermissions" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "userPermissionId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "email" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "permissions",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "permissionId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "permissionType",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "config",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "updatedByEmail",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "createdByEmail",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        alias: { kind: "Name", value: "dataRoomDataNodes" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "config" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "containsJsonpath",
                                        },
                                        value: {
                                          block: false,
                                          kind: "StringValue",
                                          value: "$.config.validation",
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeType",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodeName" },
                                  },
                                  {
                                    alias: { kind: "Name", value: "name" },
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodeName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "config" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "index" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoom" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                        {
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: {
                                                kind: "Name",
                                                value: "filter",
                                              },
                                              value: {
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "permissions",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value: "some",
                                                          },
                                                          value: {
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: {
                                                                  kind: "Name",
                                                                  value:
                                                                    "permissionType",
                                                                },
                                                                value: {
                                                                  fields: [
                                                                    {
                                                                      kind: "ObjectField",
                                                                      name: {
                                                                        kind: "Name",
                                                                        value:
                                                                          "equalTo",
                                                                      },
                                                                      value: {
                                                                        kind: "EnumValue",
                                                                        value:
                                                                          "LEAF_CRUD_PERMISSION",
                                                                      },
                                                                    },
                                                                  ],
                                                                  kind: "ObjectValue",
                                                                },
                                                              },
                                                            ],
                                                            kind: "ObjectValue",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "ObjectValue",
                                              },
                                            },
                                          ],
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userPermissions",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "userPermissionId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataRoomId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissions",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionId",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionType",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "config",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "updatedAt",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "updatedByEmail",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "createdAt",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "createdByEmail",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "datasetLinks",
                                    },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "totalCount",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetLinkId",
                                                },
                                              },
                                              {
                                                alias: {
                                                  kind: "Name",
                                                  value: "datasetId",
                                                },
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetHash",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "dataset",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      alias: {
                                                        kind: "Name",
                                                        value: "datasetId",
                                                      },
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "description",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "ownerEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetLinks",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "computeNode",
                                                                  },
                                                                  selectionSet:
                                                                    {
                                                                      kind: "SelectionSet",
                                                                      selections:
                                                                        [
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "id",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "computeNodeId",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "nodeName",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "config",
                                                                            },
                                                                          },
                                                                          {
                                                                            kind: "Field",
                                                                            name: {
                                                                              kind: "Name",
                                                                              value:
                                                                                "dataRoom",
                                                                            },
                                                                            selectionSet:
                                                                              {
                                                                                kind: "SelectionSet",
                                                                                selections:
                                                                                  [
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "dataRoomId",
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "dataRoomHash",
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "mrenclave",
                                                                                      },
                                                                                    },
                                                                                    {
                                                                                      kind: "Field",
                                                                                      name: {
                                                                                        kind: "Name",
                                                                                        value:
                                                                                          "name",
                                                                                      },
                                                                                    },
                                                                                  ],
                                                                              },
                                                                          },
                                                                        ],
                                                                    },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "allowStatisticsSharing",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetStats",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      alias: {
                                                        kind: "Name",
                                                        value: "datasetId",
                                                      },
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "fileSize",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "fillRatio",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "numberOfRows",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "numberOfDuplicateRows",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "numberOfNotNullableRows",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "statistics",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "statisticsVersion",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "ownerEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "datasetMeta",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      alias: {
                                                        kind: "Name",
                                                        value: "datasetId",
                                                      },
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "datasetHash",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "name",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "description",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "ownerEmail",
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value:
                                                    "allowStatisticsSharing",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNode",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "computeNodeId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "computeNodeType",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "nodeName",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "config",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataRoom",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "id",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "dataRoomId",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "dataRoomHash",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value:
                                                                "mrenclave",
                                                            },
                                                          },
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "name",
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    directives: [
                                      {
                                        kind: "Directive",
                                        name: { kind: "Name", value: "client" },
                                      },
                                    ],
                                    kind: "Field",
                                    name: { kind: "Name", value: "isSaving" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        alias: { kind: "Name", value: "dataRoomComputeNodes" },
                        arguments: [
                          {
                            kind: "Argument",
                            name: { kind: "Name", value: "filter" },
                            value: {
                              fields: [
                                {
                                  kind: "ObjectField",
                                  name: { kind: "Name", value: "config" },
                                  value: {
                                    fields: [
                                      {
                                        kind: "ObjectField",
                                        name: {
                                          kind: "Name",
                                          value: "containsJsonpath",
                                        },
                                        value: {
                                          block: false,
                                          kind: "StringValue",
                                          value: "$.config.computation",
                                        },
                                      },
                                    ],
                                    kind: "ObjectValue",
                                  },
                                },
                              ],
                              kind: "ObjectValue",
                            },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "computeNodes" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "nodes" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "id" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoomId" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeId",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "computeNodeType",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodeName" },
                                  },
                                  {
                                    alias: { kind: "Name", value: "name" },
                                    kind: "Field",
                                    name: { kind: "Name", value: "nodeName" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "config" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "updatedAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "updatedByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "createdAt" },
                                  },
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "createdByEmail",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "index" },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "dataRoom" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "id" },
                                        },
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "dataRoomId",
                                          },
                                        },
                                        {
                                          arguments: [
                                            {
                                              kind: "Argument",
                                              name: {
                                                kind: "Name",
                                                value: "filter",
                                              },
                                              value: {
                                                fields: [
                                                  {
                                                    kind: "ObjectField",
                                                    name: {
                                                      kind: "Name",
                                                      value: "permissions",
                                                    },
                                                    value: {
                                                      fields: [
                                                        {
                                                          kind: "ObjectField",
                                                          name: {
                                                            kind: "Name",
                                                            value: "some",
                                                          },
                                                          value: {
                                                            fields: [
                                                              {
                                                                kind: "ObjectField",
                                                                name: {
                                                                  kind: "Name",
                                                                  value:
                                                                    "permissionType",
                                                                },
                                                                value: {
                                                                  fields: [
                                                                    {
                                                                      kind: "ObjectField",
                                                                      name: {
                                                                        kind: "Name",
                                                                        value:
                                                                          "equalTo",
                                                                      },
                                                                      value: {
                                                                        kind: "EnumValue",
                                                                        value:
                                                                          "EXECUTE_COMPUTE_PERMISSION",
                                                                      },
                                                                    },
                                                                  ],
                                                                  kind: "ObjectValue",
                                                                },
                                                              },
                                                            ],
                                                            kind: "ObjectValue",
                                                          },
                                                        },
                                                      ],
                                                      kind: "ObjectValue",
                                                    },
                                                  },
                                                ],
                                                kind: "ObjectValue",
                                              },
                                            },
                                          ],
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "userPermissions",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "nodes",
                                                },
                                                selectionSet: {
                                                  kind: "SelectionSet",
                                                  selections: [
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "id",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value:
                                                          "userPermissionId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "email",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "updatedAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdAt",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "createdByEmail",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "dataRoomId",
                                                      },
                                                    },
                                                    {
                                                      kind: "Field",
                                                      name: {
                                                        kind: "Name",
                                                        value: "permissions",
                                                      },
                                                      selectionSet: {
                                                        kind: "SelectionSet",
                                                        selections: [
                                                          {
                                                            kind: "Field",
                                                            name: {
                                                              kind: "Name",
                                                              value: "nodes",
                                                            },
                                                            selectionSet: {
                                                              kind: "SelectionSet",
                                                              selections: [
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value: "id",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionId",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "permissionType",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "config",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "updatedAt",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "updatedByEmail",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "createdAt",
                                                                  },
                                                                },
                                                                {
                                                                  kind: "Field",
                                                                  name: {
                                                                    kind: "Name",
                                                                    value:
                                                                      "createdByEmail",
                                                                  },
                                                                },
                                                              ],
                                                            },
                                                          },
                                                        ],
                                                      },
                                                    },
                                                  ],
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "jobs" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "nodes",
                                          },
                                          selectionSet: {
                                            kind: "SelectionSet",
                                            selections: [
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "id",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "computeNodeId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "jobId",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "jobStartedAt",
                                                },
                                              },
                                              {
                                                kind: "Field",
                                                name: {
                                                  kind: "Name",
                                                  value: "ownerEmail",
                                                },
                                              },
                                            ],
                                          },
                                        },
                                      ],
                                    },
                                  },
                                  {
                                    directives: [
                                      {
                                        kind: "Directive",
                                        name: { kind: "Name", value: "client" },
                                      },
                                    ],
                                    kind: "Field",
                                    name: { kind: "Name", value: "isSaving" },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "dataRoomHash" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "mrenclave" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "governanceProtocol" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "enableDevelopmentTab" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "source" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isSaving" },
                      },
                      {
                        directives: [
                          {
                            kind: "Directive",
                            name: { kind: "Name", value: "client" },
                          },
                        ],
                        kind: "Field",
                        name: { kind: "Name", value: "isExpertViewEnabled" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "state" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "updatedAt" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "status" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "statusUpdatedAt" },
                            },
                            {
                              directives: [
                                {
                                  kind: "Directive",
                                  name: { kind: "Name", value: "client" },
                                },
                              ],
                              kind: "Field",
                              name: { kind: "Name", value: "isStopped" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "config" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataRoomId" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "showOrganizationLogo",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "dataNodesOrder" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "computeNodesOrder",
                              },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "userConfig" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "userConfigId" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isArchived" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isFavorite" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "organizationLogo" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "logo" },
                            },
                          ],
                        },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "lock" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "id" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "isLocked" },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "CreateDataRoomInput" },
            },
          },
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "input" },
          },
        },
      ],
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  CreateDataRoomMutation,
  CreateDataRoomMutationVariables
>;
export const EventsDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "Events" },
      operation: "query",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "events" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "timestamp" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "eventLevel" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "eventType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "eventMessage" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<EventsQuery, EventsQueryVariables>;
export const EventsSubscriptionDocument = {
  definitions: [
    {
      kind: "OperationDefinition",
      name: { kind: "Name", value: "EventsSubscription" },
      operation: "subscription",
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "events" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "Field",
                  name: { kind: "Name", value: "nodes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "timestamp" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "eventLevel" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "eventType" },
                      },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "eventMessage" },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
  kind: "Document",
} as unknown as DocumentNode<
  EventsSubscriptionSubscription,
  EventsSubscriptionSubscriptionVariables
>;
