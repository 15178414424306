import {
  type IconProp,
  type SizeProp,
} from "@fortawesome/fontawesome-svg-core";
import {
  type LinkProps,
  ListItem,
  ListItemButton,
  ListItemContent,
  type ListItemContentProps,
  ListItemDecorator,
  type ListItemProps,
  styled,
  Tooltip,
  type TooltipProps,
} from "@mui/joy";
import { isEmpty } from "lodash";
import React, { memo, useCallback, useEffect, useState } from "react";
import {
  type NavLinkProps,
  useLocation,
  useMatch,
  useNavigate,
} from "react-router-dom";
import { useSidebar } from "features/sidebar";
import {
  type SidebarInnerMenuItem,
  SidebarMenuItemContent,
  SidebarMenuItemIcon,
} from "./components";

// Define a mapped type to filter allowed props based on component
type WrapperComponentPropsMap = {
  NavLink: NavLinkProps;
  ListItem: ListItemProps;
  Link: LinkProps;
};

type SidebarMenuItemProps<T extends keyof WrapperComponentPropsMap> = {
  label?: string | React.ReactNode;
  icon?: React.ReactNode | IconProp;
  iconSize?: SizeProp;
  children?: React.ReactNode;
  labelProps?: ListItemContentProps;
  onClick?: (event: React.MouseEvent) => void;
  tooltipTitle?: string | React.ReactNode;
  tooltipProps?: Omit<TooltipProps, "title">;
  disabled?: boolean;
  to?: string;
  dataTestId?: string;
  innerMenuItems?: SidebarInnerMenuItem[];
  wrapperComponent?: React.ElementType;
} & WrapperComponentPropsMap[T];

const SidebarListItemButton = styled(ListItemButton)(
  ({ theme: { spacing, radius } }) => ({
    alignItems: "flex-start",
    border: "none",
    borderRadius: radius.sm,
    color: "initial",
    margin: 0,
    padding: spacing(1.5),
  })
);

const SidebarMenuItem: React.FC<
  SidebarMenuItemProps<keyof WrapperComponentPropsMap>
> = ({
  icon,
  iconSize = "lg",
  label,
  labelProps = {},
  onClick = () => {},
  tooltipTitle,
  tooltipProps = {},
  disabled = false,
  children,
  dataTestId,
  innerMenuItems = [],
  wrapperComponent: WrapperComponent = ListItem,
  ...restProps
}) => {
  const [isInnerMenuExpanded, setInnerMenuExpanded] = useState<boolean>(false);
  const navigate = useNavigate();
  const currentPageMatch = useMatch({
    path: `${restProps?.to || ""}/*`,
  });
  const { pathname } = useLocation();
  const selected = restProps?.to ? !isEmpty(currentPageMatch) : false;
  const { isSidebarExpanded, collapseSidebar, expandSidebar } = useSidebar();
  const [tooltip, setTooltip] = useState<string | React.ReactNode>("");

  const onSidebarItemClick = useCallback(
    (event: React.MouseEvent) => {
      onClick(event);
      if (innerMenuItems.length) {
        expandSidebar();
        setInnerMenuExpanded(!isInnerMenuExpanded);
      } else if (restProps?.to) {
        navigate(restProps?.to);
      }
    },
    [
      onClick,
      innerMenuItems.length,
      restProps?.to,
      expandSidebar,
      isInnerMenuExpanded,
      navigate,
    ]
  );

  // Collapse Sidebar whenever a page is changed
  useEffect(() => {
    collapseSidebar();
  }, [pathname, collapseSidebar]);

  // Collapse inner menus if sidebar is closed and open by default if opened page matches sidebar item
  useEffect(() => {
    if (!isSidebarExpanded) {
      setInnerMenuExpanded(false);
    } else if (innerMenuItems.length && selected) {
      setInnerMenuExpanded(true);
    }
  }, [
    innerMenuItems.length,
    isSidebarExpanded,
    selected,
    setInnerMenuExpanded,
  ]);

  // Whenever sidebar gets closed, a tooltip appears immediately causing UI bugs such showing tooltips over the page
  // in random place rather then over SidebarMenuItem after hover only.
  // In order to bypass this issue, setting tooltip title after an animation of closing sidebar is fully finished
  useEffect(() => {
    if (isSidebarExpanded) {
      setTooltip("");
    } else {
      setTimeout(() => {
        setTooltip(tooltipTitle);
      }, 300);
    }
  }, [isSidebarExpanded, tooltipTitle]);

  return (
    <Tooltip placement="right" title={tooltip} {...tooltipProps}>
      <ListItem
        component={WrapperComponent as React.ElementType}
        {...restProps}
        sx={{
          padding: 0,
          textDecoration: "none !important",
          width: "100%",
        }}
      >
        <SidebarListItemButton
          data-testid={dataTestId}
          disabled={disabled}
          onClick={onSidebarItemClick}
          selected={selected}
        >
          {icon && (
            <ListItemDecorator
              sx={{
                margin: 0,
                minInlineSize: 0,
                minWidth: "20px",
              }}
            >
              <SidebarMenuItemIcon icon={icon} iconSize={iconSize} />
            </ListItemDecorator>
          )}
          {isSidebarExpanded && label && (
            <ListItemContent
              sx={{ fontWeight: 400, lineHeight: "20px", minWidth: "180px" }}
              {...labelProps}
            >
              <SidebarMenuItemContent
                innerMenuItems={innerMenuItems}
                isInnerMenuExpanded={isInnerMenuExpanded}
                menuItemLabel={label}
                setInnerMenuExpanded={setInnerMenuExpanded}
              />
            </ListItemContent>
          )}
        </SidebarListItemButton>
      </ListItem>
    </Tooltip>
  );
};

SidebarMenuItem.displayName = "SidebarMenuItem";

export default memo(SidebarMenuItem);
