import { useAuth0 } from "@auth0/auth0-react";
import { useCommittedComputeNodeActionsQuery } from "@decentriq/graphql/dist/hooks";
import {
  ComputeJobAutoFetching,
  ComputeJobPurpose,
  ComputeJobStatus,
  type PublishedAnalystPermission,
} from "@decentriq/graphql/dist/types";
import {
  faCode,
  faCodeSimple,
  faFileChartColumn,
  faFileMagnifyingGlass,
  faPlay,
} from "@fortawesome/pro-light-svg-icons";
import { CircularProgress } from "@mui/joy";
import { useCallback, useEffect } from "react";
import { Actions } from "components";
import { useComputeNodesVars, usePublishedDataRoom } from "contexts";
import { SaveComputationResultButton } from "features/computeNode/components";
import { useJobPolling } from "features/computeNode/hooks";
import { getConfigExpandActionLabel } from "features/computeNode/utils";
import {
  type ComputeNodeTypeNames,
  type PublishedComputeNodeTypes,
} from "models";
import { useComputeNodePreview, useComputeNodeRun } from "../hooks";
import JobLastRunLabel from "../JobLastRunLabel";

const ENABLE_CONFIG_TOGGLE = false;

interface CommittedComputeNodeActionsProps {
  computeNodeId: string;
}

const CommittedComputeNodeActions: React.FC<
  CommittedComputeNodeActionsProps
> = ({ computeNodeId }) => {
  const { user } = useAuth0();
  const {
    dataRoomId: dcrHash,
    driverAttestationHash,
    isStopped,
    isDeactivated,
    testing,
  } = usePublishedDataRoom();
  const { openSdgQualityReportDialog, toggleConfig, isExpandedConfig } =
    useComputeNodesVars();
  const { data: actionsData, loading: actionsDataLoading } =
    useCommittedComputeNodeActionsQuery({
      variables: {
        computeNodeId,
        dcrHash,
        driverAttestationHash,
      },
    });
  const isEditorHidden = !isExpandedConfig(computeNodeId);
  const toggleEditor = useCallback(
    () => toggleConfig(computeNodeId),
    [computeNodeId, toggleConfig]
  );
  const hasPermission = actionsData?.publishedNode?.permissions?.some(
    (p) =>
      (p as PublishedAnalystPermission)?.participant?.userEmail === user?.email
  );
  const { loading: computeNodeRunLoading, runComputeNode } = useComputeNodeRun({
    computeNodeId,
  });
  const nodeJob = (
    actionsData?.publishedNode as PublishedComputeNodeTypes | undefined
  )?.job;
  const job =
    (testing && nodeJob?.purpose === ComputeJobPurpose.Standard) ||
    (!testing && nodeJob?.purpose === ComputeJobPurpose.Test)
      ? undefined
      : nodeJob;
  const status = job?.status;
  const isJobRunning = status === ComputeJobStatus.Running;
  const isJobFailed = status === ComputeJobStatus.Failed;
  const isJobCompleted = status === ComputeJobStatus.Succeded;
  const isAutoFetchingJob =
    job?.autoFetching && job?.autoFetching !== ComputeJobAutoFetching.None;
  const hasSdgQualityReport =
    isJobCompleted &&
    actionsData?.publishedNode.__typename === "PublishedSyntheticNode";
  const isComputeNodeRunning = computeNodeRunLoading || isJobRunning;
  const { previewResult, loading: previewResultLoading } =
    useComputeNodePreview({
      jobId: job?.id || "",
    });
  const { startJobPolling } = useJobPolling({
    autoFetching: job?.autoFetching,
    computeNodeId,
    dataRoomHash: job?.dataRoomHash,
    driverAttestationHash: job?.driverAttestationHash,
    enclaveJobId: job?.enclaveComputeJobId,
    jobId: job?.id,
  });
  useEffect(() => {
    if (isJobRunning) {
      startJobPolling();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);
  if (
    (actionsDataLoading && !actionsData) ||
    isStopped ||
    isDeactivated ||
    (!hasPermission && !testing)
  ) {
    return <></>;
  }
  return (
    <>
      <JobLastRunLabel
        isJobCompleted={isJobCompleted && !isComputeNodeRunning}
        runAt={job?.createdAt}
      />
      <Actions
        actions={{
          buttons: [
            {
              color: testing ? "secondary" : "primary",
              disabled: isComputeNodeRunning,
              hidden:
                actionsData?.publishedNode?.__typename ===
                "PublishedPreviewNode",
              icon:
                isComputeNodeRunning && !isJobFailed ? (
                  <CircularProgress
                    sx={{ "--CircularProgress-size": "16px" }}
                  />
                ) : (
                  faPlay
                ),
              name: testing ? "Run test" : "Run",
              onClick: runComputeNode,
              tooltipPlacement: "top",
              tooltipTitle: isJobCompleted
                ? `Re-run${testing ? " test" : ""}`
                : `Run${testing ? " test" : ""}`,
              type: "Run",
              variant: "soft",
            },
            {
              disabled: previewResultLoading,
              hidden:
                isAutoFetchingJob || !isJobCompleted || isComputeNodeRunning,
              icon: faFileMagnifyingGlass,
              loading: previewResultLoading,
              onClick: previewResult,
              tooltipPlacement: "top",
              tooltipTitle: "Preview",
              type: "Preview",
            },
            {
              hidden:
                !isJobCompleted || !hasSdgQualityReport || isComputeNodeRunning,
              icon: faFileChartColumn,
              onClick: () => openSdgQualityReportDialog(computeNodeId),
              tooltipPlacement: "top",
              tooltipTitle: "Quality report",
              type: "Quality report",
            },
            {
              component: (
                <SaveComputationResultButton
                  computationType={
                    actionsData!.publishedNode
                      .__typename as ComputeNodeTypeNames
                  }
                  computeNodeId={computeNodeId}
                  computeNodeName={actionsData!.publishedNode.name}
                  job={job!}
                />
              ),
              hidden: !isJobCompleted || isComputeNodeRunning,
            },
            {
              hidden:
                !ENABLE_CONFIG_TOGGLE ||
                !isJobCompleted ||
                isComputeNodeRunning,
              icon: isEditorHidden ? faCode : faCodeSimple,
              onClick: toggleEditor,
              tooltipPlacement: "top",
              tooltipTitle: getConfigExpandActionLabel(
                isEditorHidden,
                (
                  actionsData?.publishedNode as
                    | PublishedComputeNodeTypes
                    | undefined
                )?.computationType
              ),
              type: "Editor",
            },
          ],
        }}
      />
    </>
  );
};

export default CommittedComputeNodeActions;
